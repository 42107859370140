import { FC, memo, useEffect, useMemo } from "react";
import { useStore } from "effector-react/compat";
import { colors } from "@chhjpackages/components";

import { $auth, $authorizedUser, $location } from "features/auth";
import { SidebarNav } from "widgets/sidebar-nav";
import { $permissionsStore } from "features/permissions";
import { useThemeColor } from "shared/hooks";

import { useStyles } from "./styles";
import { PageLayoutProps } from "./types";

export const PageLayout: FC<PageLayoutProps> = memo(
  ({ children, sidebar, isLoginPage, logout }) => {
    const styles = useStyles();

    const { isAuthorizing, isAuthorized } = useStore($auth);
    const { user } = useStore($authorizedUser);
    const { loading: locationLoading } = useStore($location);
    const { loading: permissionsLoading } = useStore($permissionsStore);

    const { changeThemeColor } = useThemeColor();

    const showSidebarNav = useMemo(
      () =>
        !isLoginPage &&
        isAuthorized &&
        !isAuthorizing &&
        !permissionsLoading &&
        !locationLoading,
      [
        isLoginPage,
        isAuthorized,
        isAuthorizing,
        permissionsLoading,
        locationLoading,
      ],
    );

    useEffect(() => {
      if (showSidebarNav) {
        changeThemeColor(colors.white);
      } else {
        changeThemeColor(colors.grey10);
      }
    }, [showSidebarNav, changeThemeColor]);

    return (
      <div className={styles.root}>
        {showSidebarNav && (
          <div className={styles.sidebarNavContainer}>
            <SidebarNav user={user} logout={logout} />
          </div>
        )}

        {sidebar && <div className={styles.sidebarContainer}>{sidebar}</div>}

        <div className={styles.content}>{children}</div>
      </div>
    );
  },
);
