import { Button, colors } from "@chhjpackages/components";
import { Box, Grid, Typography } from "@material-ui/core";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { memo, useEffect, useState } from "react";

import { FormTextArea } from "shared/ui";
import { BoldPencilIcon } from "shared/assets";

import { AddTermsFormProps, AddTermsFormValues } from "./model";

const addTermsSchema = yup.object({
  terms: yup.string().trim(),
});

const initialValues = {
  terms: "",
};

export const AddTermsForm = memo(
  ({ signatureText, onSubmit }: AddTermsFormProps) => {
    const { control, formState, watch, reset, handleSubmit } =
      useForm<AddTermsFormValues>({
        mode: "all",
        defaultValues: initialValues,
        resolver: yupResolver(addTermsSchema),
      });

    const termsWatch = watch("terms");

    const [showTermsInput, setShowTermsInput] = useState(false);

    useEffect(() => {
      if (!showTermsInput) {
        reset(initialValues);
      }
    }, [showTermsInput]);

    return (
      <div>
        <Box color={colors.grey180}>
          <Typography variant="h4" color="inherit">
            Signature text
          </Typography>
        </Box>
        <Box mt={1} color={colors.grey160}>
          <Typography
            variant="body1"
            color="inherit"
            dangerouslySetInnerHTML={{
              __html: signatureText,
            }}
          />
        </Box>
        <Box mt={showTermsInput ? 3 : 6}>
          {showTermsInput && (
            <Box mb={3}>
              <FormTextArea
                control={control}
                name="terms"
                label="Additional Terms"
              />
            </Box>
          )}
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Button
                buttonType="text"
                color="primary"
                fullWidth
                onClick={() => setShowTermsInput(showTermsInput ? false : true)}
              >
                {showTermsInput ? (
                  "Cancel"
                ) : (
                  <Box display="flex" alignItems="center">
                    <BoldPencilIcon fontSize={14} />
                    <Box ml={1}>Add terms</Box>
                  </Box>
                )}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                buttonType="twoTone"
                fullWidth
                isLoading={formState.isSubmitting}
                disabled={!formState.isValid}
                onClick={handleSubmit(onSubmit)}
              >
                {termsWatch ? "Save" : "Next"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </div>
    );
  },
);
