import moment from "moment";

export const paymentMethodsOptions = [
  {
    value: 1,
    label: "Cash",
  },
  {
    value: 2,
    label: "Check",
  },
  {
    value: 3,
    label: "Credit Card",
  },
  {
    value: 4,
    label: "eCheck",
  },
  {
    value: 6,
    label: "Other",
  },
];

export const cardTypesOptions = [
  {
    value: 1,
    label: "MasterCard",
  },
  {
    value: 2,
    label: "Visa",
  },
  {
    value: 3,
    label: "American Express",
  },
  {
    value: 4,
    label: "Discover Card",
  },
];

export const expMonthsOptions = [
  {
    value: 1,
    label: "January",
  },
  {
    value: 2,
    label: "February",
  },
  {
    value: 3,
    label: "March",
  },
  {
    value: 4,
    label: "April",
  },
  {
    value: 5,
    label: "May",
  },
  {
    value: 6,
    label: "June",
  },
  {
    value: 7,
    label: "July",
  },
  {
    value: 8,
    label: "August",
  },
  {
    value: 9,
    label: "September",
  },
  {
    value: 10,
    label: "October",
  },
  {
    value: 11,
    label: "November",
  },
  {
    value: 12,
    label: "December",
  },
];

export const expYearsOptions = Array.from(
  { length: 10 },
  (_, i) => i + moment().year(),
).map((expYear) => ({
  value: expYear,
  label: String(expYear),
}));
