import { makeStyles } from "@material-ui/core";

export const usePricingStyles = makeStyles(() => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: 16,
    gap: 16,
  },
  controlPanelRoot: {
    flexWrap: "nowrap !important" as "nowrap",
  },
  controlPanelActionsContainer: {
    overflow: "hidden",
  },
  controlPanelContentContainer: {
    overflow: "hidden",
  },
  title: {
    whiteSpace: "nowrap",
  },
  search: {
    marginTop: 12,
  },
  filtersContainer: {
    display: "flex",
    gap: 16,
  },
  pricingListNoPricingsWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  addendumMessageRoot: {
    borderRadius: 4,
  },
}));
