import { createEffect, createStore } from "effector/compat";

import { ValuationOption } from "../types";
import { getValuationOptions } from "../api";

export const $valuationOptions = createStore<{
  valuationOptions: ValuationOption[];
  locationId: number | null;
  loading: boolean;
}>({
  valuationOptions: [],
  locationId: null,
  loading: true,
});

export const getValuationOptionsFx = createEffect(getValuationOptions);
$valuationOptions
  .on(getValuationOptionsFx.done, (store, response) => ({
    ...store,
    valuationOptions: response.result.data.valuationOptions,
    locationId: response.params.locationId,
  }))
  .on(getValuationOptionsFx.fail, (store) => ({
    ...store,
    valuationOptions: [],
    locationId: null,
  }))
  .on(getValuationOptionsFx.pending, (store, loading) => ({
    ...store,
    loading,
  }));
