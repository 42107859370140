import { Paper } from "@chhjpackages/components";
import { Typography } from "@material-ui/core";
import { memo } from "react";
import { Skeleton } from "@material-ui/lab";

import { useStyles } from "./styles";

export const NotificationCardSkeleton = memo(() => {
  const styles = useStyles();

  return (
    <Paper elevation={16} roundingValue={4} className={styles.root}>
      <div className={styles.content}>
        <Typography variant="body1">
          <Skeleton variant="text" width="80%" />
        </Typography>

        <Typography variant="body2">
          <Skeleton variant="text" width={125} />
        </Typography>
      </div>

      <Skeleton variant="circle" width={24} height={24} />
    </Paper>
  );
});
