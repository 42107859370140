import { SvgIcon } from "@material-ui/core";

export const AwardIcon = () => (
  <SvgIcon
    style={{ width: 41, height: 41, fill: "none" }}
    width="41"
    height="41"
    viewBox="0 0 41 41"
    fill="none"
  >
    <path
      d="M40.4421 31.7081L34.2475 24.2094L35.0626 23.6388C35.7147 23.2313 35.9592 22.3347 35.7147 21.6011L34.4105 18.1778L35.7147 14.7545C35.9592 14.0209 35.7147 13.1243 35.0626 12.7168L31.9653 10.6791L30.9872 7.09273C30.7427 6.35916 30.0906 5.7886 29.2755 5.87011L25.6077 6.03313L22.7549 3.6694C22.1028 3.18035 21.2878 3.18035 20.6357 3.6694L17.7829 5.95162L14.1151 5.7886C13.3 5.7071 12.6479 6.27765 12.4034 7.01122L11.4253 10.5976L8.2465 12.6353C7.59444 13.0428 7.2684 13.9394 7.59444 14.673L8.89856 18.0963L7.59444 21.6011C7.34991 22.3347 7.59444 23.1498 8.2465 23.6388L9.06158 24.2094L2.86698 31.7081C2.78547 31.8711 2.70396 32.0341 2.78547 32.1157C2.86698 32.2787 2.94849 32.3602 3.1115 32.4417L8.98007 33.5828L11.3438 40.674C11.4253 40.837 11.5883 41 11.7513 41C11.7513 41 11.7513 41 11.8328 41C11.9959 41 12.1589 40.9185 12.2404 40.7555L18.5165 30.8115L20.6357 32.5232C20.9617 32.7677 21.2878 32.9307 21.6953 32.9307C22.1028 32.9307 22.4289 32.7677 22.7549 32.5232L24.8741 30.8115L31.1502 40.7555C31.2317 40.9185 31.3947 41 31.5578 41C31.5578 41 31.5578 41 31.6393 41C31.8023 41 31.9653 40.837 32.0468 40.674L34.4105 33.5828L40.2791 32.4417C40.4421 32.4417 40.6051 32.2787 40.6051 32.1157C40.6051 31.9526 40.5236 31.7896 40.4421 31.7081Z"
      fill="#F1CAA4"
    />
    <path
      d="M20.7173 8.07082L22.755 12.0647H26.2599C26.6674 12.0647 26.9935 12.3092 27.1565 12.6353C27.3195 12.9613 27.238 13.4503 26.9119 13.6949L23.7331 16.8737L25.5263 20.9491C25.6893 21.3566 25.6078 21.7641 25.2818 22.0087C24.9558 22.2532 24.5482 22.3347 24.1407 22.0902L19.8208 19.7264L15.5008 22.1717C15.1748 22.4162 14.6858 22.3347 14.3597 22.0902C14.0337 21.8457 13.9522 21.3566 14.1152 21.0306L15.9084 16.9552L12.7296 13.7764C12.4851 13.5318 12.322 13.1243 12.4851 12.7168C12.6481 12.3907 12.9741 12.1462 13.3816 12.1462H16.8865L18.9242 8.15232C19.0872 7.82629 19.4132 7.66328 19.8208 7.66328C20.2283 7.50026 20.5543 7.74478 20.7173 8.07082Z"
      stroke="#006A37"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.92053 21.2751L1.3999 29.1814L7.51299 30.404L9.95823 37.7397L16.4789 27.3882"
      stroke="#E87524"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.8022 21.2751L38.3229 29.1814L32.2098 30.404L29.7645 37.7397L23.2439 27.3882"
      stroke="#E87524"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.6359 1.30565L23.6517 3.75089L27.564 3.58788C28.1346 3.58788 28.6236 3.91391 28.7867 4.48446L29.7647 8.23383L33.0251 10.353C33.5141 10.6791 33.6771 11.2496 33.5141 11.8202L32.1285 15.4065L33.5141 18.9929C33.7586 19.5634 33.5141 20.134 33.0251 20.46L29.7647 22.5792L28.7867 26.3286C28.6236 26.8991 28.1346 27.2252 27.564 27.2252L23.6517 27.0622L20.6359 29.5074C20.1468 29.8334 19.5763 29.8334 19.0872 29.5074L16.0714 27.0622L12.159 27.3067C11.5885 27.3067 11.0994 26.9806 10.9364 26.4101L9.95833 22.6607L6.69801 20.5415C6.20896 20.2155 6.04595 19.6449 6.20896 19.0744L7.5946 15.488L6.20896 11.8202C5.96444 11.2496 6.20896 10.6791 6.69801 10.353L9.95833 8.23383L10.9364 4.48446C11.0994 3.91391 11.5885 3.58788 12.159 3.58788L16.0714 3.75089L19.0872 1.30565C19.4948 0.898115 20.1468 0.898115 20.6359 1.30565Z"
      stroke="#E87524"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
