import { colors } from "@chhjpackages/components";
import { SvgIcon } from "@material-ui/core";

export const ArrowRightSliderIcon = ({
  className,
  color = colors.grey140,
  fontSize = 12,
}: {
  className?: string;
  color?: string;
  fontSize?: number;
}) => (
  <SvgIcon
    className={className}
    style={{ fontSize }}
    width="6"
    height="12"
    viewBox="0 0 6 11"
    fill="none"
  >
    <path
      d="M0.853964 10.2C0.966824 10.2006 1.0787 10.1791 1.18319 10.1366C1.28767 10.0941 1.3827 10.0314 1.46283 9.95225L5.75063 5.68107C5.91035 5.52102 6 5.30451 6 5.07883C6 4.85315 5.91035 4.63664 5.75063 4.47659L1.46283 0.205407C1.29878 0.0654608 1.08775 -0.0076671 0.871927 0.000637074C0.656099 0.00894125 0.451366 0.0980655 0.29864 0.2502C0.145913 0.402335 0.0564418 0.606275 0.0481052 0.821266C0.0397687 1.03626 0.113182 1.24646 0.253672 1.40988L3.9326 5.07456L0.253672 8.73924C0.133253 8.85821 0.0509515 9.01008 0.0171766 9.17564C-0.0165987 9.3412 -0.000330925 9.51302 0.0639219 9.66937C0.128175 9.82573 0.237528 9.95959 0.378154 10.054C0.518779 10.1485 0.684362 10.1993 0.853964 10.2Z"
      fill={color}
    />
  </SvgIcon>
);
