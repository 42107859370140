import { fetcher } from "shared/libs";

import { GetAvailabilities } from "./types";

export const getAvailabilities = ({
  locationId,
  payload,
}: GetAvailabilities.Params) =>
  fetcher.post<GetAvailabilities.Response>(
    `locations/${locationId}/availability`,
    payload,
  );
