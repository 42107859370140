import { Grid } from "@material-ui/core";
import { colors } from "@chhjpackages/components";
import { memo } from "react";
import { Skeleton } from "@material-ui/lab";

export const FiltersMobileSkeleton = memo(() => {
  return (
    <div
      style={{
        backgroundColor: colors.basic.white,
        borderTop: `solid 1px ${colors.grey30}`,
        padding: 16,
      }}
    >
      <Grid
        container
        alignItems="center"
        justify="space-between"
        spacing={2}
        wrap="nowrap"
      >
        <Grid item xs={12}>
          <Skeleton
            variant="rect"
            width="100%"
            height={43}
            style={{ borderRadius: 16 }}
          />
        </Grid>
        <Grid item>
          <Skeleton variant="rect" width={20} height={20} />
        </Grid>
      </Grid>
    </div>
  );
});
