import { memo, useEffect, useMemo, useState } from "react";
import camelcase from "camelcase";
import { useMediaQuery, useTheme } from "@material-ui/core";

import { FiltersBarProps, useFilterItems } from "./model";
import { FiltersMobile } from "./mobile";
import { FiltersDesktop } from "./desktop";

export const FiltersBar = memo(
  ({
    isLoading,
    isSearch,
    isGridView,
    filters,
    onFilter,
    onSearch,
    handleSetView,
  }: FiltersBarProps<any>) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"), {
      noSsr: true,
    });

    const initialFiltersState = useMemo(
      () =>
        filters.reduce(
          (acc, current) => ({
            ...acc,
            [camelcase(current.name)]: current.initialValue,
          }),
          {},
        ),
      [filters],
    );

    const resetFiltersState = useMemo(
      () =>
        filters.reduce(
          (acc, current) => ({
            ...acc,
            [camelcase(current.name)]:
              current.resetValue ?? current.initialValue,
          }),
          {},
        ),
      [filters],
    );

    const [activeFiltersAmount, setActiveFiltersAmount] = useState(0);
    const { state, renderFilterItem, resetFilters } = useFilterItems({
      initialFiltersState,
      resetFiltersState,
    });

    const reset = () => {
      resetFilters();
      setActiveFiltersAmount(0);
    };

    useEffect(() => {
      onFilter(state);

      let filtersCount = 0;

      Object.keys(state).forEach((key) => {
        if (
          (state[key] as []).length > 0 &&
          !(state[key] as number[]).includes(-1)
        ) {
          filtersCount += (state[key] as []).length;
        }
      });

      if (!!state.assignments && state.assignments !== -1) {
        filtersCount += 1;
      }

      setActiveFiltersAmount(filtersCount);
    }, [state]);

    return isMobile ? (
      <FiltersMobile
        isLoading={isLoading}
        isSearch={isSearch}
        isGridView={isGridView}
        filters={filters}
        countFilters={activeFiltersAmount}
        handleSetView={handleSetView}
        renderFilterItem={renderFilterItem}
        resetFilters={reset}
        onSearch={onSearch}
      />
    ) : (
      <div style={{ paddingBottom: 16 }}>
        <FiltersDesktop
          isLoading={isLoading}
          isSearch={isSearch}
          isGridView={isGridView}
          countFilters={activeFiltersAmount}
          filters={filters}
          handleSetView={handleSetView}
          renderFilterItem={renderFilterItem}
          resetFilters={reset}
          onSearch={onSearch}
        />
      </div>
    );
  },
);
