import { makeStyles } from "@material-ui/styles";
import { Button } from "@chhjpackages/components";
import { withStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  link: {
    textDecoration: "none",
  },
});

export const ButtonStyled = withStyles({
  root: {
    padding: "4px 8px",
  },
})(Button);
