import { createEvent } from "effector";
import { createStore } from "effector/compat";

import { Product } from "shared/types";

export const $productUpdates = createStore<{
  updates: Product[];
}>({ updates: [] });

export const addProductUpdate = createEvent<Product>();
$productUpdates.on(addProductUpdate, (store, product) => ({
  ...store,
  updates: [...store.updates, product],
}));

export const editProductUpdate = createEvent<
  Partial<Product> & Required<Pick<Product, "productLineId">>
>();
$productUpdates.on(editProductUpdate, (store, product) => ({
  ...store,
  updates: store.updates.map((update) =>
    update.productLineId === product.productLineId
      ? { ...update, ...product }
      : update,
  ),
}));

export const deleteProductUpdate = createEvent<{ id: number }>();
$productUpdates.on(deleteProductUpdate, (store, { id: productLineId }) => ({
  ...store,
  updates: store.updates.filter(
    (update) => update.productLineId !== productLineId,
  ),
}));

export const setProductUpdate = createEvent<{
  product: Product;
  ignoreDelete?: boolean;
}>();
$productUpdates.on(setProductUpdate, (store, { product, ignoreDelete }) => {
  const isExist = store.updates.find(
    (update) => update.productLineId === product.productLineId,
  );

  if (isExist) {
    if (product.quantity === 0 && !ignoreDelete) {
      deleteProductUpdate({ id: product.productLineId });
    } else {
      editProductUpdate({ ...product });
    }
  } else {
    addProductUpdate({ ...product });
  }
});

export const setProductUpdates = createEvent<Product[]>();
$productUpdates.on(setProductUpdates, (store, updates) => ({
  ...store,
  updates,
}));
