import { UpdateAccount } from "../types/account-api";
import { fetcher } from "shared/libs";

export const updateAccount = ({
  locationId,
  accountId,
  payload,
}: UpdateAccount.Params) =>
  fetcher.put<UpdateAccount.Response>(
    `locations/${locationId}/accounts/${accountId}?`,
    payload,
  );
