import { createStore, createEvent } from "effector";

export const moveStepsCompletedCount = createEvent<number>();

const addToSteps = (steps: number[], newSlideIndex: number): number[] => [
  ...steps,
  newSlideIndex,
];

export const $moveJobsStore = createStore<{
  moveStepsCompleted: number[];
}>({
  moveStepsCompleted: [],
});

$moveJobsStore.on(moveStepsCompletedCount, (store, newStep) => ({
  ...store,
  moveStepsCompleted: addToSteps(store.moveStepsCompleted, newStep),
}));
