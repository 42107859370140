import { Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { memo } from "react";

import { useStyles } from "./styles";

export const PriceItemSkeleton = memo(() => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Typography variant="subtitle2" component="div" className={styles.label}>
        <Skeleton variant="text" width={100} />
      </Typography>
      <Typography variant="body2" component="div">
        <Skeleton variant="text" width={60} />
      </Typography>
    </div>
  );
});
