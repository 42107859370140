export const getCompletedBinderPresentations = () =>
  JSON.parse(
    localStorage.getItem("crew/job/completedBinderPresentations") ?? "[]",
  ) as number[];

export const markBinderPresentationAsCompleted = (appointmentId: number) => {
  const completedBinderPresentations = getCompletedBinderPresentations();
  const alreadyCompleted = completedBinderPresentations.includes(appointmentId);

  if (!alreadyCompleted) {
    localStorage.setItem(
      "crew/job/completedBinderPresentations",
      JSON.stringify([...completedBinderPresentations, appointmentId]),
    );
  }
};

export const getBinderPresentationStatus = (appointmentId: number) => {
  const completedBinderPresentations = getCompletedBinderPresentations();

  return !!completedBinderPresentations.includes(appointmentId);
};
