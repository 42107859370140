import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { useAlert, useConfirmationDialog } from "@chhjpackages/components";
import { useStore } from "effector-react/compat";

import { ActionsFooter, BackTitle, PdfViewer, SignatureArea } from "shared/ui";
import { useSignDialog, useSignPdf } from "features/signature";
import {
  $completedActions,
  getCompletedAction,
  useCompletedActions,
} from "features/completed-actions";
import { $auth } from "features/auth";
import { $appointmentStore, getAppointmentFx } from "features/appointment";
import { CompletedActionsJobEnum } from "shared/types";
import { useSideNavDispatch } from "features/sidenav";
import { fileToBase64, routePaths } from "shared/utils";
import { addAttachmentsFx, getAttachmentsFx } from "features/attachments";
import {
  $liabilityWaiver,
  generateLiablityWaiverFx,
  getCurrentLiablityWaiverFx,
  updateLiablityWaiverFx,
} from "entities/liability-waiver";

import { useSignatureStyles } from "./assets";

const signatureFieldOffset = {
  x: -45,
  y: -19,
};

export const LiabilityWaiverSignature = memo(() => {
  const styles = useSignatureStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), { noSsr: true });

  const location = useLocation();
  const { appointmentId } = useParams();
  const navigate = useNavigate();

  const { locationId } = useStore($auth);
  const { appointment } = useStore($appointmentStore);
  const { completedActions } = useStore($completedActions);
  const { liabilityWaiver } = useStore($liabilityWaiver);

  const { setPageName, setShowGoBackButton, setGoToBackUrl, setElevation } =
    useSideNavDispatch();

  const [isLiabilityWaiverGetting, setIsLiabilityWaiverGetting] =
    useState(true);
  const [isPdfRendering, setIsPdfRendering] = useState(true);
  const [isLiabilityWaiverSaving, setIsLiabilityWaiverSaving] = useState(false);
  const [signatureMode, setSignatureMode] = useState<
    "client1" | "client2" | "done"
  >("client1");

  const { showAlert } = useAlert();
  const { addCompletedAction } = useCompletedActions();

  const currentSignaturePdfField = useMemo(() => {
    if (signatureMode === "client1") {
      return "signature_1";
    } else if (signatureMode === "client2") {
      return "signature_2";
    }
  }, [signatureMode]);

  const {
    pdfRef,
    pdfWidth,
    blobPdf,
    pdfDoc,
    signs,
    countPages,
    pageNumberWithSignature,
    signatureFieldCoordinates,
    getPdfDoc,
    addSign,
    savePdf,
    renderSign,
  } = useSignPdf({
    width: 800,
    currentSignaturePdfField,
    signatureFieldOffset,
    isLoading: isPdfRendering,
  });

  const noticeMessage = useMemo(() => {
    if (signatureMode === "client1") {
      return "Please give the device to the client";
    }

    if (signatureMode === "done") {
      return "Please give the device back to the crew member";
    }
  }, [signatureMode]);

  const {
    openConfirmation: handleNoticeDialogOpen,
    closeConfirmation: handleNoticeDialogClose,
  } = useConfirmationDialog({
    title: "Notice",
    message: noticeMessage,
    confirmButtonText: "Continue",
    hideCancelButton: true,
    maxWidth: 488,
  });

  const locationState = useMemo<
    | {
        additionalText: string;
      }
    | undefined
  >(() => location.state, [location.state]);

  const isLiabilityWaiverCompleted = useMemo(() => {
    if (!appointment) {
      return null;
    }

    return !!getCompletedAction(
      completedActions,
      CompletedActionsJobEnum.FinalizeLiabilityWaiver,
    );
  }, [appointment, completedActions]);

  const backPath = useMemo(
    () =>
      isLiabilityWaiverCompleted
        ? routePaths.jobDetails(Number(appointmentId))
        : routePaths.jobDetailsLiabilityWaiver(Number(appointmentId)),
    [isLiabilityWaiverCompleted, appointmentId],
  );

  const isShouldDisplayFooter = useMemo(
    () => !isLiabilityWaiverCompleted && !isPdfRendering,
    [isLiabilityWaiverCompleted, isPdfRendering],
  );

  const handleBack = useCallback(
    () => navigate(backPath),
    [backPath, navigate],
  );

  const handleSubmitSignDialog = useCallback(
    (signBlob: Blob) => {
      if (!pageNumberWithSignature) {
        return;
      }

      addSign({ blob: signBlob, imageHeight: 27 }, pageNumberWithSignature, {
        ...signatureFieldCoordinates,
        width: {
          type: "percent",
          value: 38.1,
        },
      });

      if (signatureMode === "client1") {
        setSignatureMode("client2");
      } else if (signatureMode === "client2") {
        setSignatureMode("done");
      }

      if (signatureMode === "client2") {
        setTimeout(() => {
          handleNoticeDialogOpen(() => handleNoticeDialogClose());
        }, 0);
      }
    },
    [
      signatureMode,
      pageNumberWithSignature,
      signatureFieldCoordinates,
      addSign,
      handleNoticeDialogOpen,
      handleNoticeDialogClose,
    ],
  );

  const { handleSignDialogOpen } = useSignDialog({
    title: "Client signature",
    onSubmit: handleSubmitSignDialog,
  });

  const handleSaveLiablityWaiver = useCallback(async () => {
    if (!appointment || !liabilityWaiver?.id) {
      showAlert("Error! Something went wrong. Try again later.", {
        variant: "error",
      });
      return;
    }

    setIsLiabilityWaiverSaving(true);

    try {
      const newBlobPdf = await savePdf();

      if (!newBlobPdf) {
        throw new Error();
      }

      await Promise.all([
        await updateLiablityWaiverFx({
          locationId: appointment.location.id,
          appointmentId: appointment.id,
          liabilityWaiverId: liabilityWaiver?.id,
          payload: {
            content: await fileToBase64(newBlobPdf),
          },
        }),
        await addAttachmentsFx({
          locationId: appointment.location.id,
          appointmentId: appointment.id,
          description: `Liability Waiver - ${appointment.id} - Electronically Signed Document`,
          extension: "pdf",
          content: await fileToBase64(newBlobPdf),
          typeId: 2,
          emailClient: true,
        }).then(() =>
          getAttachmentsFx({
            locationId: appointment.location.id,
            appointmentId: appointment.id,
          }),
        ),
        await addCompletedAction(
          appointment.id,
          appointment.location.id,
          CompletedActionsJobEnum.FinalizeLiabilityWaiver,
        ),
      ]);

      showAlert("Success! Document has been uploaded.", { variant: "success" });

      navigate(routePaths.jobDetails(appointment.id));
    } catch {
      showAlert("Error! Failed to upload document.", { variant: "error" });
    }

    setIsLiabilityWaiverSaving(false);
  }, [
    appointment,
    liabilityWaiver?.id,
    showAlert,
    navigate,
    addCompletedAction,
    savePdf,
  ]);

  useEffect(() => {
    if (!isPdfRendering && !isLiabilityWaiverCompleted) {
      handleNoticeDialogOpen(() => handleNoticeDialogClose());
    }
  }, [isPdfRendering]);

  useEffect(() => {
    if (!isLiabilityWaiverGetting && liabilityWaiver?.url && !pdfDoc) {
      getPdfDoc(liabilityWaiver.url);
    }
  }, [isLiabilityWaiverGetting, liabilityWaiver?.url, pdfDoc, getPdfDoc]);

  useEffect(() => {
    const getLiabilityWaiver = async (locId: number, appId: number) => {
      try {
        if (isLiabilityWaiverCompleted) {
          await getCurrentLiablityWaiverFx({
            locationId: locId,
            appointmentId: appId,
          });
        } else if (locationState) {
          await generateLiablityWaiverFx({
            locationId: locId,
            appointmentId: appId,
            payload: {
              exclusions: locationState.additionalText,
            },
          });
        }
      } catch {
        showAlert("Error! Failed to get document. Try again later.", {
          variant: "error",
        });
      }

      setIsLiabilityWaiverGetting(false);
    };

    if (
      appointment?.id &&
      typeof isLiabilityWaiverCompleted === "boolean" &&
      isLiabilityWaiverGetting
    ) {
      getLiabilityWaiver(appointment.location.id, appointment.id);
    }
  }, [
    appointment?.id,
    appointment?.location.id,
    isLiabilityWaiverCompleted,
    isLiabilityWaiverGetting,
    locationState?.additionalText,
  ]);

  useEffect(() => {
    if (locationId && appointment?.id !== Number(appointmentId)) {
      getAppointmentFx({
        locationId: locationId,
        appointmentId: Number(appointmentId),
      }).catch(() => {
        showAlert("Error! Failed to get appointment. Try again later.", {
          variant: "error",
        });
      });
    }
  }, [locationId, appointment?.id, appointmentId]);

  useEffect(() => {
    setPageName("Liability waiver");
    setShowGoBackButton(true);
    setGoToBackUrl(backPath);
    setElevation(16);

    return () => {
      setPageName("");
      setShowGoBackButton(false);
      setGoToBackUrl("");
      setElevation(0);
    };
  }, [
    backPath,
    setPageName,
    setShowGoBackButton,
    setGoToBackUrl,
    setElevation,
  ]);

  return (
    <div className={styles.root}>
      {!isMobile && (
        <BackTitle
          title="Liability waiver"
          elevation={16}
          onBack={handleBack}
        />
      )}

      <div className={styles.pdfViewerContainer}>
        <div className={styles.pdfViewerWrapper}>
          <PdfViewer
            ref={pdfRef}
            width={pdfWidth}
            pagesCount={countPages}
            loading={isPdfRendering}
            documentProps={{
              file: blobPdf,
            }}
            pageProps={{
              onRenderSuccess: () => {
                setIsPdfRendering(false);
              },
            }}
            pageContent={(pageNumber) => {
              const shouldRenderSignatureArea =
                !isLiabilityWaiverCompleted &&
                signatureMode !== "done" &&
                pageNumber === pageNumberWithSignature &&
                signatureFieldCoordinates.x !== 0 &&
                signatureFieldCoordinates.y !== 0;

              const signatureAreaElement = shouldRenderSignatureArea ? (
                <SignatureArea
                  classes={{ root: styles.signatureArea }}
                  style={{
                    left: `${signatureFieldCoordinates.x}px`,
                    top: `${signatureFieldCoordinates.y}px`,
                  }}
                  onClick={handleSignDialogOpen}
                />
              ) : (
                <></>
              );

              const signElements = signs.map((sign) =>
                sign.pageNumber === pageNumber ? renderSign(sign) : <></>,
              );

              return (
                <>
                  {signatureAreaElement}
                  {signElements}
                </>
              );
            }}
          />
        </div>
      </div>

      <ActionsFooter
        show={isShouldDisplayFooter}
        actions={[
          {
            label: "Sign",
            hide: signatureMode === "done",
            buttonType: "filled",
            onClick: handleSignDialogOpen,
          },
          {
            label: "Save",
            hide: signatureMode !== "done",
            buttonType: "filled",
            isLoading: isLiabilityWaiverSaving,
            onClick: handleSaveLiablityWaiver,
          },
        ]}
      />
    </div>
  );
});
