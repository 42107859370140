import { colors } from "@chhjpackages/components";
import { SvgIcon } from "@material-ui/core";

export const PhoneIcon = ({
  className,
  color = colors.grey80,
  fontSize = 21,
}: {
  className?: string;
  color?: string;
  fontSize?: number;
}) => (
  <SvgIcon
    className={className}
    style={{ fontSize }}
    width="21"
    height="22"
    viewBox="0 0 21 22"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.50676 0.902147C3.45966 0.0326177 4.80195 0.0326177 5.75486 0.902147C5.76965 0.91564 5.7839 0.92976 5.79758 0.94447L8.49056 3.84063C9.07064 4.33118 9.1536 5.06869 9.1536 5.5547C9.1536 6.17375 8.92297 6.81685 8.51685 7.24037C8.20117 7.56959 8.20117 8.16118 8.51685 8.49039L12.9483 13.1118C13.0535 13.2215 13.289 13.3364 13.5476 13.3364C13.8062 13.3364 14.0417 13.2215 14.1469 13.1118C15.0243 12.1968 16.5023 12.1968 17.3797 13.1118L20.192 16.0445C21.0694 16.9595 21.0694 18.5009 20.192 19.4159L19.5102 20.1269C17.7313 21.982 14.8867 22.279 12.7354 20.8514C12.7315 20.8488 12.7276 20.8462 12.7238 20.8435C12.7199 20.8408 12.716 20.8381 12.7122 20.8353C8.20921 17.5842 4.22574 13.4304 1.10712 8.64229L1.10362 8.63691L1.10365 8.6369C-0.277891 6.47578 0.0137907 3.51018 1.86394 1.65821L2.4391 0.972701C2.46013 0.947643 2.48273 0.924073 2.50676 0.902147ZM3.48643 2.00234L2.92503 2.67144C2.91026 2.68905 2.8947 2.70593 2.87841 2.72202C1.51352 4.07049 1.3023 6.24073 2.29878 7.80227C5.31299 12.4293 9.16482 16.4475 13.519 19.5934C15.116 20.6455 17.2072 20.4073 18.4932 19.0662L19.1749 18.3552C19.4906 18.026 19.4906 17.4344 19.1749 17.1052L16.3627 14.1724C16.047 13.8432 15.4797 13.8432 15.164 14.1724C14.7579 14.5959 14.1412 14.8364 13.5476 14.8364C12.954 14.8364 12.3373 14.5959 11.9312 14.1724L7.49978 9.55105C6.62239 8.63605 6.62239 7.09471 7.49978 6.17971C7.60499 6.07 7.71524 5.82438 7.71524 5.5547C7.71524 5.37586 7.6972 5.24227 7.66693 5.14755C7.63959 5.06203 7.60792 5.0237 7.57681 4.99936C7.54663 4.97576 7.51828 4.94972 7.49202 4.92147L4.78781 2.01324C4.39086 1.66593 3.88565 1.66229 3.48643 2.00234Z"
      fill={color}
    />
  </SvgIcon>
);
