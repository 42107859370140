import { Chip, withStyles, createStyles, Theme } from "@material-ui/core";
import { colors } from "@chhjpackages/components";

export const ChipStyled = withStyles((theme: Theme) =>
  createStyles({
    root: {
      opacity: 1,
      backgroundColor: "#51C9AF29",
      fontWeight: 700,
      color: colors.complementary.green.shade01,

      [theme.breakpoints.down("sm")]: {
        minWidth: "fit-content",
        padding: "0",
        minHeight: "0px",
      },
    },
  }),
)(Chip);
