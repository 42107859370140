import LogoFoxBusiness from "../../assets/images/logo-fox-business.png";
import LogoAol from "../../assets/images/logo-aol.png";
import LogoDylanRatiganShow from "../../assets/images/logo-dylan-ratigan-show.png";
import LogoUsaToday from "../../assets/images/logo-usa-today.png";
import LogoTime from "../../assets/images/logo-time.png";
import LogoSouthWest from "../../assets/images/logo-southwest.png";
import LogoNewsWeek from "../../assets/images/logo-newsweek.png";
import LogoAandE from "../../assets/images/logo-a&e.png";
import LogoFortune from "../../assets/images/logo-fortune.png";
import LogoParenting from "../../assets/images/logo-parenting.png";
import LogoFastCompany from "../../assets/images/logo-fast-company.png";
import LogoYahooFinance from "../../assets/images/logo-yahoo-finance.png";
import LogoRandomHouse from "../../assets/images/logo-random-house.png";
import LogoTampaBayTimes from "../../assets/images/logo-tampa-bay-times.png";
import LogoTLC from "../../assets/images/logo-tlc.png";
import LogoEntrepreneurOrganization from "../../assets/images/logo-entrepreneurs-organization.png";
import LogoTheWashingtonPost from "../../assets/images/logo-the-washington-post.png";
import LogoTheWallStreetJournal from "../../assets/images/logo-the-wall-street-journal.png";
import LogoBravo from "../../assets/images/logo-bravo.png";
import LogoTheNewYorkTimes from "../../assets/images/logo-the-new-york-times.png";
import LogoHuffpost from "../../assets/images/logo-huffpost.png";
import LogoDelta from "../../assets/images/logo-delta.png";
import LogoCnn from "../../assets/images/logo-cnn.png";
import LogoMtv from "../../assets/images/logo-mtv.png";
import LogoAbc from "../../assets/images/logo-abc.png";
import LogoReadersDigest from "../../assets/images/logo-readers-digest.png";
import LogoForbes from "../../assets/images/logo-forbes.png";
import LogoAmazon from "../../assets/images/logo-amazon.png";
import LogoBusinessInsider from "../../assets/images/logo-business-insider.png";
import LogoEntrepreneur from "../../assets/images/logo-entrepreneur.png";
import LogoHoarders from "../../assets/images/logo-hoarders.png";
import LogoUsWeekly from "../../assets/images/logo-us-weekly.png";
import LogoCbs from "../../assets/images/logo-cbs.png";
import Logo30under30 from "../../assets/images/logo-30-under-30.png";
import LogoAirtran from "../../assets/images/logo-airtran.png";
import LogoCnbc from "../../assets/images/logo-cnbc.png";
import LogoBnet from "../../assets/images/logo-bnet.png";
import LogoSharkTank from "../../assets/images/logo-shark-tank.png";
import LogoTheMillionaire from "../../assets/images/logo-the-millionaire.png";

export const companies = [
  {
    name: "Fox business",
    logo: LogoFoxBusiness,
  },
  {
    name: "Aol",
    logo: LogoAol,
  },
  {
    name: "The Dylan Ratigan show",
    logo: LogoDylanRatiganShow,
  },
  {
    name: "USA today",
    logo: LogoUsaToday,
  },
  {
    name: "Time",
    logo: LogoTime,
  },
  {
    name: "South West",
    logo: LogoSouthWest,
  },
  {
    name: "News Week",
    logo: LogoNewsWeek,
  },
  {
    name: "A&E",
    logo: LogoAandE,
  },
  {
    name: "Fortune",
    logo: LogoFortune,
  },
  {
    name: "Parenting",
    logo: LogoParenting,
  },
  {
    name: "Fast company",
    logo: LogoFastCompany,
  },
  {
    name: "Yahoo Finance",
    logo: LogoYahooFinance,
  },
  {
    name: "Random House",
    logo: LogoRandomHouse,
  },
  {
    name: "Tampa Bay Times",
    logo: LogoTampaBayTimes,
  },
  {
    name: "TLC",
    logo: LogoTLC,
  },
  {
    name: "Entrepreneur Organization",
    logo: LogoEntrepreneurOrganization,
  },
  {
    name: "The Washington Post",
    logo: LogoTheWashingtonPost,
  },
  {
    name: "The Wall Street Journal",
    logo: LogoTheWallStreetJournal,
  },
  {
    name: "Bravo",
    logo: LogoBravo,
  },
  {
    name: "The New York Times",
    logo: LogoTheNewYorkTimes,
  },
  {
    name: "Huffpost",
    logo: LogoHuffpost,
  },
  {
    name: "Delta",
    logo: LogoDelta,
  },
  {
    name: "CNN",
    logo: LogoCnn,
  },
  {
    name: "MTV",
    logo: LogoMtv,
  },
  {
    name: "ABC",
    logo: LogoAbc,
  },
  {
    name: "Reader's Digest",
    logo: LogoReadersDigest,
  },
  {
    name: "Forbes",
    logo: LogoForbes,
  },
  {
    name: "Amazon",
    logo: LogoAmazon,
  },
  {
    name: "Business Insider",
    logo: LogoBusinessInsider,
  },
  {
    name: "Entrepreneur",
    logo: LogoEntrepreneur,
  },
  {
    name: "Hoarders",
    logo: LogoHoarders,
  },
  {
    name: "Us Weekly",
    logo: LogoUsWeekly,
  },
  {
    name: "CBS",
    logo: LogoCbs,
  },
  {
    name: "30 under 30",
    logo: Logo30under30,
  },
  {
    name: "Air Tran",
    logo: LogoAirtran,
  },
  {
    name: "CNBC",
    logo: LogoCnbc,
  },
  {
    name: "B NET",
    logo: LogoBnet,
  },
  {
    name: "Shark Tank",
    logo: LogoSharkTank,
  },
  {
    name: "The Millionaire",
    logo: LogoTheMillionaire,
  },
];
