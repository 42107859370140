import { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  Collapse,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useStore } from "effector-react/compat";
import { useNavigate, useParams } from "react-router-dom";

import { useSideNavDispatch } from "features/sidenav";
import { ActionsFooter, BackTitle } from "shared/ui";
import {
  $valuationOptions,
  $valuationPlans,
  ValuationOptionCard,
  ValuationOptionCardSkeleton,
  ValuationPlanCard,
  getValuationOptionsFx,
  getValuationPlansFx,
} from "entities/valuation";
import { $auth } from "features/auth";
import { routePaths } from "shared/utils";
import { $appointmentStore, getAppointmentFx } from "features/appointment";

import { useValuationStyles } from "./aseets";

export const Valuation = memo(() => {
  const styles = useValuationStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), { noSsr: true });

  const { appointmentId } = useParams();
  const navigate = useNavigate();

  const { locationId } = useStore($auth);
  const { appointment, loading: appointmentLoading } =
    useStore($appointmentStore);
  const {
    valuationOptions,
    loading: valuationOptionsLoading,
    locationId: valuationOptionsLocationId,
  } = useStore($valuationOptions);
  const {
    valuationPlans,
    loading: valuationPlansLoading,
    appointmentId: valuationPlansAppointmentId,
  } = useStore($valuationPlans);

  const { setPageName, setShowGoBackButton, setGoToBackUrl, setElevation } =
    useSideNavDispatch();

  const [selectedOption, setSelectedOption] = useState<number | string | null>(
    null,
  );
  const [selectedPlan, setSelectedPlan] = useState<number | string | null>(
    null,
  );

  const backPath = useMemo(
    () => routePaths.jobDetails(Number(appointmentId)),
    [appointmentId],
  );

  const isLoading = useMemo(
    () =>
      appointmentLoading || valuationOptionsLoading || valuationPlansLoading,
    [appointmentLoading, valuationOptionsLoading, valuationPlansLoading],
  );

  const sortedValuationOptions = useMemo(
    () => valuationOptions.sort((a, b) => Number(a.sort) - Number(b.sort)),
    [valuationOptions],
  );

  const sortedValuationPlans = useMemo(
    () => valuationPlans.sort((a, b) => Number(a.sort) - Number(b.sort)),
    [valuationPlans],
  );

  const isSecondOptionSelected = useMemo(
    () =>
      valuationOptions.findIndex((option) => option.id === selectedOption) ===
      1,
    [valuationOptions, selectedOption],
  );

  const disableContinueToSignature = useMemo(
    () =>
      isLoading ||
      selectedOption === null ||
      (isSecondOptionSelected && selectedPlan === null),
    [isLoading, selectedOption, isSecondOptionSelected, selectedPlan],
  );

  const showValuationPlans = useMemo(
    () => isSecondOptionSelected && !isLoading,
    [isSecondOptionSelected, isLoading],
  );

  const showFooter = useMemo(
    () => !disableContinueToSignature,
    [disableContinueToSignature],
  );

  const handleBack = useCallback(
    () => navigate(backPath),
    [backPath, navigate],
  );

  const continueToSignature = useCallback(
    () =>
      navigate(routePaths.jobDetailsValuationSignature(Number(appointmentId)), {
        state: {
          optionId: Number(selectedOption),
          planId: selectedPlan ? Number(selectedPlan) : 0,
        },
      }),
    [appointmentId, selectedOption, selectedPlan, navigate],
  );

  useEffect(() => {
    if (
      locationId &&
      Number(appointmentId) &&
      valuationOptionsLocationId !== locationId
    ) {
      getValuationOptionsFx({
        locationId,
        appointmentId: Number(appointmentId),
      });
    }
  }, [locationId, appointmentId]);

  useEffect(() => {
    if (
      locationId &&
      Number(appointmentId) &&
      valuationPlansAppointmentId !== Number(appointmentId)
    ) {
      getValuationPlansFx({ locationId, appointmentId: Number(appointmentId) });
    }
  }, [locationId, appointmentId]);

  useEffect(() => {
    if (locationId && appointment?.id !== Number(appointmentId)) {
      getAppointmentFx({
        locationId: locationId,
        appointmentId: Number(appointmentId),
      });
    }
  }, [locationId, appointment?.id, appointmentId]);

  useEffect(() => {
    setPageName("Valuation");
    setShowGoBackButton(true);
    setGoToBackUrl(backPath);
    setElevation(16);

    return () => {
      setPageName("");
      setShowGoBackButton(false);
      setGoToBackUrl("");
      setElevation(0);
    };
  }, [
    backPath,
    setPageName,
    setShowGoBackButton,
    setGoToBackUrl,
    setElevation,
  ]);

  return (
    <div className={styles.root}>
      {!isMobile && (
        <BackTitle title="Valuation" elevation={16} onBack={handleBack} />
      )}

      <div className={styles.content}>
        <Typography variant="h4" className={styles.title}>
          Choose declared valuation
        </Typography>

        <div className={styles.valuationOptionsList}>
          {!isLoading &&
            (!!sortedValuationOptions.length ? (
              sortedValuationOptions.map((option, index) => (
                <ValuationOptionCard
                  key={option.id}
                  option={option}
                  label={`Option ${index + 1}:`}
                  isSelected={option.id === selectedOption}
                  onSelect={() =>
                    setSelectedOption((prev) =>
                      prev === option.id ? null : option.id,
                    )
                  }
                />
              ))
            ) : (
              <Typography variant="body1">
                There are no options added yet.
              </Typography>
            ))}

          {isLoading && (
            <>
              <ValuationOptionCardSkeleton />
              <ValuationOptionCardSkeleton />
            </>
          )}
        </div>

        <Collapse
          in={showValuationPlans}
          classes={{ container: styles.valuationPlansListCollapseContainer }}
        >
          <div className={styles.valuationPlansList}>
            <Divider />

            <Typography variant="subtitle1" align="center">
              Choose your declared Valuation below:
            </Typography>

            {!!sortedValuationPlans.length ? (
              sortedValuationPlans.map((plan) => (
                <ValuationPlanCard
                  key={plan.id}
                  plan={plan}
                  isSelected={plan.id === selectedPlan}
                  onSelect={() =>
                    setSelectedPlan((prev) =>
                      prev === plan.id ? null : plan.id,
                    )
                  }
                />
              ))
            ) : (
              <Typography variant="body1">
                There are no plans added yet.
              </Typography>
            )}
          </div>
        </Collapse>
      </div>

      <ActionsFooter
        show={showFooter}
        actions={[
          {
            label: "Continue to customer signature",
            buttonType: "filled",
            disabled: disableContinueToSignature,
            onClick: continueToSignature,
          },
        ]}
      />
    </div>
  );
});
