import { memo } from "react";
import { Typography } from "@material-ui/core";

import { numberToCurrency, numberWithCommas } from "shared/utils";

import { useStyles } from "./assets";
import { AddendumFooterProps } from "./model";

export const AddendumFooter = memo(
  ({ originalEstimate, newEstimate }: AddendumFooterProps) => {
    const styles = useStyles();

    return (
      <div className={styles.root}>
        <div className={styles.item}>
          <Typography variant="h4" className={styles.colorGrey180}>
            Original estimate
          </Typography>

          <Typography variant="h4" className={styles.colorGrey180}>
            {numberToCurrency(numberWithCommas(originalEstimate, 2))}
          </Typography>
        </div>

        <div className={styles.item}>
          <Typography variant="body1" className={styles.newEstimateTitle}>
            New estimate:
          </Typography>

          <Typography
            variant="body1"
            color="secondary"
            className={styles.newEstimate}
          >
            {numberToCurrency(numberWithCommas(newEstimate, 2))}
          </Typography>
        </div>
      </div>
    );
  },
);
