import { Theme, makeStyles } from "@material-ui/core";

type Props = {
  maxWidth: number | string;
};

export const useStyles = makeStyles<Theme, Props>(() => ({
  root: {
    maxWidth: ({ maxWidth }) => maxWidth,
  },
  content: {
    maxWidth: "100%",
    width: "100vw",
  },
}));
