import { useMediaQuery, useTheme } from "@material-ui/core";
import { Scrollbars } from "@chhjpackages/components";
import { memo } from "react";
import { Skeleton } from "@material-ui/lab";

import {
  AppointmentGridViewSkeletonCard,
  AppointmentListViewSkeletonCard,
} from "features/appointment";

import { AppointmentListProps } from "./types";

export const AppointmentListSkeleton = memo(
  ({ isGridView }: Pick<AppointmentListProps, "isGridView">) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
      noSsr: true,
    });

    return (
      <>
        {[...Array(3)].map((_, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              width: "100%",
              maxWidth: "100%",
              height: "100%",
              flexDirection: "column",
            }}
          >
            <div style={{ marginTop: index === 0 ? 0 : 8 }}>
              <Skeleton variant="rect" width="100%" height={38} />
            </div>

            {isMobile ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                {[...Array(3)].map((mobileItem, innerListIndex) => (
                  <div style={{ marginTop: 8 }}>
                    {isGridView ? (
                      <AppointmentGridViewSkeletonCard key={innerListIndex} />
                    ) : (
                      <AppointmentListViewSkeletonCard key={innerListIndex} />
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <div style={{ margin: "16px 0px 8px" }}>
                <Scrollbars
                  autoHide
                  autoHeight
                  autoHeightMin="100%"
                  autoHeightMax="100%"
                  style={{ height: "100%" }}
                >
                  <div
                    style={{
                      display: "flex",
                      marginBottom: 20,
                    }}
                  >
                    {isGridView
                      ? [...Array(3)].map((desktopItem, desktopInnerIndex) => (
                          <AppointmentGridViewSkeletonCard
                            key={desktopInnerIndex}
                          />
                        ))
                      : [...Array(3)].map((desktopItem, desktopInnerIndex) => (
                          <AppointmentListViewSkeletonCard
                            key={desktopInnerIndex}
                          />
                        ))}
                  </div>
                </Scrollbars>
              </div>
            )}
          </div>
        ))}
      </>
    );
  },
);
