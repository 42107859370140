import { memo, useCallback, useMemo } from "react";
import { Typography } from "@material-ui/core";
import { Button } from "@chhjpackages/components";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";

import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { routePaths } from "shared/utils";
import { markBinderPresentationAsCompleted } from "features/training-details";

import { useStyles } from "./styles";

export const Page12 = memo(() => {
  const navigate = useNavigate();
  const styles = useStyles();
  const [searchParams] = useSearchParams();

  const appointmentId = useMemo(
    () => searchParams.get("appointmentId"),
    [searchParams],
  );

  const handleClosePresentation = useCallback(() => {
    if (appointmentId) {
      markBinderPresentationAsCompleted(Number(appointmentId));
    }

    navigate({
      pathname: routePaths.trainingBinderPresentation(),
      search: `?${createSearchParams(searchParams)}`,
    });
  }, [appointmentId, searchParams, navigate]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <Logo />

        <br />
        <br />

        <Typography variant="h1" color="secondary" component="div">
          Thank you
        </Typography>
        <Typography variant="h4" component="div">
          for watching our presentation
        </Typography>

        <br />
        <br />

        <Button
          buttonType="twoTone"
          size="large"
          onClick={handleClosePresentation}
        >
          Close presentation
        </Button>
      </div>
    </div>
  );
});
