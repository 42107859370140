import { useMediaQuery, useTheme } from "@material-ui/core";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAlert, useConfirmationDialog } from "@chhjpackages/components";
import { useStore } from "effector-react/compat";

import { ActionsFooter, BackTitle, PdfViewer, SignatureArea } from "shared/ui";
import { useSideNavDispatch } from "features/sidenav";
import { fileToBase64, routePaths } from "shared/utils";
import { useSignDialog, useSignPdf } from "features/signature";
import { $auth } from "features/auth";
import { $appointmentStore, getAppointmentFx } from "features/appointment";
import {
  $preExistingDamage,
  generatePreExistingDamageFx,
  getCurrentPreExistingDamageFx,
  updatePreExistingDamageFx,
} from "entities/pre-existing-damage";
import {
  $completedActions,
  getCompletedAction,
  useCompletedActions,
} from "features/completed-actions";
import { CompletedActionsJobEnum } from "shared/types";
import { addAttachmentsFx, getAttachmentsFx } from "features/attachments";

import { useSignatureStyles } from "./assets";

const signatureFieldOffset = {
  x: -49,
  y: -18,
};

export const PreExistingDamageSignature = memo(() => {
  const styles = useSignatureStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), { noSsr: true });

  const { appointmentId } = useParams();
  const navigate = useNavigate();

  const { locationId } = useStore($auth);
  const { appointment } = useStore($appointmentStore);
  const { preExistingDamage } = useStore($preExistingDamage);
  const { completedActions } = useStore($completedActions);

  const { setPageName, setShowGoBackButton, setGoToBackUrl, setElevation } =
    useSideNavDispatch();

  const [isPedGetting, setIsPedGetting] = useState(true);
  const [isPdfRendering, setIsPdfRendering] = useState(true);
  const [isSavingPed, setIsSavingPed] = useState(false);
  const [signatureMode, setSignatureMode] = useState<"client" | "done">(
    "client",
  );

  const { showAlert } = useAlert();
  const { addCompletedAction } = useCompletedActions();

  const noticeMessage = useMemo(() => {
    if (signatureMode === "client") {
      return "Please give the device to the client";
    }

    return "Please give the device back to the crew member";
  }, [signatureMode]);

  const {
    openConfirmation: handleNoticeDialogOpen,
    closeConfirmation: handleNoticeDialogClose,
  } = useConfirmationDialog({
    title: "Notice",
    message: noticeMessage,
    confirmButtonText: "Continue",
    hideCancelButton: true,
    maxWidth: 488,
  });

  const currentSignaturePdfField = useMemo(() => {
    if (signatureMode === "client") {
      return "signature_1";
    }
  }, [signatureMode]);

  const {
    pdfRef,
    pdfWidth,
    blobPdf,
    pdfDoc,
    signs,
    countPages,
    pageNumberWithSignature,
    signatureFieldCoordinates,
    getPdfDoc,
    addSign,
    savePdf,
    renderSign,
  } = useSignPdf({
    width: 800,
    currentSignaturePdfField,
    signatureFieldOffset,
    isLoading: isPdfRendering,
  });

  const isPedCompleted = useMemo(() => {
    if (!appointment) {
      return null;
    }

    return !!getCompletedAction(
      completedActions,
      CompletedActionsJobEnum.FinalizePreExistingDamage,
    );
  }, [appointment, completedActions]);

  const backPath = useMemo(
    () =>
      isPedCompleted
        ? routePaths.jobDetails(Number(appointmentId))
        : routePaths.jobDetailsPreExistingDamage(Number(appointmentId)),
    [isPedCompleted, appointmentId],
  );

  const isShouldDisplayFooter = useMemo(
    () => !isPedCompleted && !isPdfRendering,
    [isPedCompleted, isPdfRendering],
  );

  const handleBack = useCallback(
    () => navigate(backPath),
    [backPath, navigate],
  );

  const handleSubmitSignDialog = useCallback(
    (signBlob: Blob) => {
      if (!pageNumberWithSignature) {
        return;
      }

      addSign({ blob: signBlob, imageHeight: 27 }, pageNumberWithSignature, {
        ...signatureFieldCoordinates,
        width: {
          type: "percent",
          value: 38.1,
        },
      });

      setSignatureMode("done");

      setTimeout(() => {
        handleNoticeDialogOpen(() => handleNoticeDialogClose());
      }, 0);
    },
    [
      pageNumberWithSignature,
      signatureFieldCoordinates,
      addSign,
      handleNoticeDialogOpen,
      handleNoticeDialogClose,
    ],
  );

  const { handleSignDialogOpen } = useSignDialog({
    title: "Client signature",
    onSubmit: handleSubmitSignDialog,
  });

  const handleSavePed = useCallback(async () => {
    if (!appointment || !preExistingDamage) {
      showAlert("Error! Something went wrong. Try again later.", {
        variant: "error",
      });
      return;
    }

    setIsSavingPed(true);

    try {
      const newBlobPdf = await savePdf();

      if (!newBlobPdf) {
        throw new Error();
      }

      await Promise.all([
        await updatePreExistingDamageFx({
          locationId: appointment.location.id,
          appointmentId: appointment.id,
          preExistingDamageId: preExistingDamage?.id,
          payload: {
            content: await fileToBase64(newBlobPdf),
          },
        }),
        await addAttachmentsFx({
          locationId: appointment.location.id,
          appointmentId: appointment.id,
          description: `Pre-Existing Damage - ${appointment.id} - Electronically Signed Document`,
          extension: "pdf",
          content: await fileToBase64(newBlobPdf),
          typeId: 2,
          emailClient: true,
        }).then(() =>
          getAttachmentsFx({
            locationId: appointment.location.id,
            appointmentId: appointment.id,
          }),
        ),
        await addCompletedAction(
          appointment.id,
          appointment.location.id,
          CompletedActionsJobEnum.FinalizePreExistingDamage,
        ),
      ]);

      showAlert("Success! Document has been uploaded.", { variant: "success" });

      navigate(routePaths.jobDetails(appointment.id));
    } catch {
      showAlert("Error! Failed to upload document.", { variant: "error" });
    }

    setIsSavingPed(false);
  }, [
    appointment,
    preExistingDamage,
    showAlert,
    navigate,
    addCompletedAction,
    savePdf,
  ]);

  useEffect(() => {
    if (!isPdfRendering && !isPedCompleted) {
      handleNoticeDialogOpen(() => handleNoticeDialogClose());
    }
  }, [isPdfRendering]);

  useEffect(() => {
    if (!isPedGetting && preExistingDamage?.url && !pdfDoc) {
      getPdfDoc(preExistingDamage.url);
    }
  }, [isPedGetting, preExistingDamage?.url, pdfDoc, getPdfDoc]);

  useEffect(() => {
    const getPed = async (locId: number, appId: number) => {
      try {
        if (isPedCompleted) {
          await getCurrentPreExistingDamageFx({
            locationId: locId,
            appointmentId: appId,
          });
        } else {
          await generatePreExistingDamageFx({
            locationId: locId,
            appointmentId: appId,
          });
        }
      } catch {
        showAlert("Error! Failed to get document. Try again later.", {
          variant: "error",
        });
      }

      setIsPedGetting(false);
    };

    if (
      appointment?.id &&
      typeof isPedCompleted === "boolean" &&
      isPedGetting
    ) {
      getPed(appointment.location.id, appointment.id);
    }
  }, [appointment?.id, appointment?.location.id, isPedCompleted, isPedGetting]);

  useEffect(() => {
    if (locationId && appointment?.id !== Number(appointmentId)) {
      getAppointmentFx({
        locationId: locationId,
        appointmentId: Number(appointmentId),
      }).catch(() => {
        showAlert("Error! Failed to get appointment. Try again later.", {
          variant: "error",
        });
      });
    }
  }, [locationId, appointment?.id, appointmentId]);

  useEffect(() => {
    setPageName("Pre-existing damage");
    setShowGoBackButton(true);
    setGoToBackUrl(backPath);
    setElevation(16);

    return () => {
      setPageName("");
      setShowGoBackButton(false);
      setGoToBackUrl("");
      setElevation(0);
    };
  }, [
    backPath,
    setPageName,
    setShowGoBackButton,
    setGoToBackUrl,
    setElevation,
  ]);

  return (
    <div className={styles.root}>
      {!isMobile && (
        <BackTitle
          title="Pre-existing damage"
          elevation={16}
          onBack={handleBack}
        />
      )}

      <div className={styles.pdfViewerContainer}>
        <div className={styles.pdfViewerWrapper}>
          <PdfViewer
            ref={pdfRef}
            width={pdfWidth}
            pagesCount={countPages}
            loading={isPdfRendering}
            documentProps={{
              file: blobPdf,
            }}
            pageProps={{
              onRenderSuccess: () => {
                setIsPdfRendering(false);
              },
            }}
            pageContent={(pageNumber) => {
              const shouldRenderSignatureArea =
                !isPedCompleted &&
                signatureMode !== "done" &&
                pageNumber === pageNumberWithSignature &&
                signatureFieldCoordinates.x !== 0 &&
                signatureFieldCoordinates.y !== 0;

              const signatureAreaElement = shouldRenderSignatureArea ? (
                <SignatureArea
                  classes={{ root: styles.signatureArea }}
                  style={{
                    left: `${signatureFieldCoordinates.x}px`,
                    top: `${signatureFieldCoordinates.y}px`,
                  }}
                  onClick={handleSignDialogOpen}
                />
              ) : (
                <></>
              );

              const signElements = signs.map((sign) =>
                sign.pageNumber === pageNumber ? renderSign(sign) : <></>,
              );

              return (
                <>
                  {signatureAreaElement}
                  {signElements}
                </>
              );
            }}
          />
        </div>
      </div>

      <ActionsFooter
        show={isShouldDisplayFooter}
        actions={[
          {
            label: "Sign",
            hide: signatureMode === "done",
            buttonType: "filled",
            onClick: handleSignDialogOpen,
          },
          {
            label: "Save",
            hide: signatureMode !== "done",
            buttonType: "filled",
            isLoading: isSavingPed,
            onClick: handleSavePed,
          },
        ]}
      />
    </div>
  );
});
