import { validateEmail, validatePhone } from "@chhjpackages/components";

export const getLinkMethodBy = (value: string, method: string) => {
  if (validatePhone(value)) {
    return {
      method,
      phone: value.replace(/[^\d\+]/g, ""),
    };
  } else if (validateEmail(value)) {
    return {
      method,
      email: value,
    };
  }

  return {
    method,
    username: value,
  };
};
