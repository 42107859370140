import { colors } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  mainBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: "2rem",
    backgroundColor: colors.basic.white,
  },
  backButton: {
    width: "10px",
  },
  leftArrow: {
    margin: "-2px 77px 0 0",
  },
  hideArrow: {
    visibility: "hidden",
  },
}));
