import { colors } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  root: {
    padding: 16,
    paddingTop: 8,
    color: colors.grey190,
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },
}));
