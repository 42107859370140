import axios, { AxiosError } from "axios";
import camelCase from "camelcase-keys";
import snakeCase from "snakecase-keys";

import { $auth, authApi } from "features/auth";
import { errorsApi } from "features/error-boundary/model/store";
import { API_URL, TRAINING_API_URL } from "shared/config";
import { ApiResponseBase } from "shared/types";
import { networkCheck } from "shared/utils";
import { SettingItemsEnum, getSettingValue } from "features/settings";

export const fetcher = axios.create({
  baseURL: !!getSettingValue<boolean>(SettingItemsEnum.TrainingMode)
    ? TRAINING_API_URL
    : API_URL,
});

fetcher.interceptors.request.use((request) => {
  const authState = $auth.getState();

  if (authState.token) {
    request.headers = {
      ...request.headers,
      Authorization: `Basic ${authState.token}`,
    };
  }

  if (
    request.data &&
    !(request.data instanceof Blob || request.data instanceof FormData)
  ) {
    request.data = snakeCase(request.data, { deep: true });
  }

  return request;
});

fetcher.interceptors.response.use(
  (response) => {
    if (
      response.data &&
      !(response.data instanceof Blob) &&
      typeof response.data === "object"
    ) {
      response.data = camelCase(response.data, { deep: true });
    }

    return response;
  },

  (error: AxiosError<ApiResponseBase & unknown>) => {
    if (!networkCheck()) {
      return Promise.reject(error);
    }

    const metaStatus = error.response?.data.meta.status;
    const errorName = `${metaStatus?.code} (${metaStatus?.name})`;
    const isGetRequest = error.config.method?.toUpperCase() === "GET";

    if (metaStatus && isGetRequest) {
      if (metaStatus.code === 401 || metaStatus.code === 403) {
        authApi.setAuthorizationFailed(true);
        throw error;
      }

      if (metaStatus.code === 404) {
        return errorsApi.throw({
          name: errorName,
          message: "Oops. The page you were looking for Was not found.",
          cause: metaStatus.description,
        });
      }

      if (metaStatus && metaStatus.code >= 400) {
        return errorsApi.throw({
          name: errorName,
          message: "Oops! Something went wrong!",
          cause: metaStatus.description,
        });
      }
    }

    return Promise.reject(error);
  },
);
