import { colors } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/styles";

export const useEtaBannerStyles = makeStyles({
  container: {
    position: "sticky",
    bottom: 0,
    left: 0,
    zIndex: 1,
    width: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    padding: "8px 16px",
  },

  button: {
    borderRadius: 20,
    background: colors.primary.main,
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    color: "white",
    textAlign: "center",
    padding: "8px 16px",
    cursor: "pointer",

    "&:active": {
      boxShadow: "none",
    },
  },

  dueTime: {
    textDecoration: "underline",
  },
});
