import { SettingItemsIdEnum, SettingItemsEnum } from "./types";

export const settingsList = [
  {
    id: 1,
    name: "Job settings",
    settings: [
      {
        id: SettingItemsIdEnum.PreJobInspection,
        name: SettingItemsEnum.PreJobInspection,
      },
      {
        id: SettingItemsIdEnum.PostJobInspection,
        name: SettingItemsEnum.PostJobInspection,
      },
    ],
  },
  {
    id: 2,
    name: "Interface options",
    settings: [
      {
        id: SettingItemsIdEnum.AutoExpandJobSteps,
        name: SettingItemsEnum.AutoExpandJobSteps,
      },
    ],
  },
];

const getStoragePath = (settingName: SettingItemsEnum) =>
  `crew/settings/${settingName}`;

export const getSettingValue = <T>(settingName: SettingItemsEnum) => {
  const settingValue = localStorage.getItem(getStoragePath(settingName));
  const settingStatus = settingValue ? (JSON.parse(settingValue) as T) : null;

  return settingStatus;
};

export const setSettingValue = (
  settingName: SettingItemsEnum,
  value: unknown,
) => {
  localStorage.setItem(getStoragePath(settingName), JSON.stringify(value));
};

export const removeSettingValue = (settingName: SettingItemsEnum) => {
  localStorage.removeItem(getStoragePath(settingName));
};

export const removeAllSettingStatuses = () => {
  localStorage.removeItem(getStoragePath(SettingItemsEnum.PreJobInspection));
  localStorage.removeItem(getStoragePath(SettingItemsEnum.PostJobInspection));
  localStorage.removeItem(getStoragePath(SettingItemsEnum.TrainingMode));
  localStorage.removeItem(getStoragePath(SettingItemsEnum.AutoExpandJobSteps));
  localStorage.removeItem(getStoragePath(SettingItemsEnum.NavigationApp));
  localStorage.removeItem(getStoragePath(SettingItemsEnum.PaymentPlatform));
};
