import { Typography, Divider, IconButton } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import { colors } from "@chhjpackages/components";
import { Link } from "react-router-dom";
import { memo } from "react";

import { routePaths } from "shared/utils";
import { GuideStepCardProps } from "features/training-details";
import { ArrowRightSliderIcon } from "shared/assets";

import { useStyles } from "./assets";

export const GuideStepCard = memo(
  ({
    title,
    titleIndex,
    stepsValidate,
    onClickGuideStep,
  }: GuideStepCardProps) => {
    const styles = useStyles();

    return (
      <Link
        style={{ textDecoration: "none" }}
        to={
          typeof stepsValidate === "undefined"
            ? routePaths.trainingBinderSinglePresentation()
            : ""
        }
        onClick={(e) => {
          if (typeof stepsValidate !== "undefined") {
            e.preventDefault();
          }
        }}
      >
        <div
          className={styles.mainBox}
          onClick={() => {
            if (onClickGuideStep) {
              onClickGuideStep(titleIndex);
            }
          }}
        >
          <div className={styles.root}>
            {stepsValidate?.[titleIndex] ? (
              <div className={styles.checkIconBox}>
                <CheckIcon className={styles.checkIconStyle} />
              </div>
            ) : (
              <div className={styles.numberBox}>{titleIndex + 1}</div>
            )}

            <Typography
              variant="subtitle1"
              color="inherit"
              className={styles.title}
            >
              {title}
            </Typography>
          </div>
          <div className={styles.actionContainer}>
            <div className={styles.dividerWrapper}>
              <Divider orientation="vertical" />
            </div>
            <div className={styles.iconButtonWrapper}>
              <IconButton>
                <ArrowRightSliderIcon fontSize={12} color={colors.grey180} />
              </IconButton>
            </div>
          </div>
        </div>
      </Link>
    );
  },
);
