export const guideListWithTitle = [
  {
    category: "Travel & Arrival",
    titles: [
      { title: "Pre-Arrival", id: 0 },
      { title: "Arrival", id: 1 },
      { title: "The Approach", id: 2 },
      { title: "At the door", id: 3 },
      { title: "Pre-Entry", id: 4 },
    ],
  },
  {
    category: "WORK",
    titles: [
      { title: "Break the ice", id: 5 },
      { title: "The Walktrough", id: 6 },
      { title: "Take control", id: 7 },
      { title: "Build value", id: 8 },
      { title: "Perform", id: 9 },
    ],
  },
  {
    category: "CLOSING THE JOB",
    titles: [
      { title: "Complete the wow", id: 10 },
      { title: "Close the job", id: 11 },
      { title: "Reviews, Pictures", id: 12 },
      { title: "Goodbye", id: 13 },
    ],
  },
];
