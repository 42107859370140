import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  decriptionOfSituationContent: {
    marginTop: 16,
  },
  additionalNotesContent: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    marginTop: 16,
  },
}));
