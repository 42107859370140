import { createEffect, createStore } from "effector";

import { Category } from "shared/types";

import { getAppointmentCategories } from "./api";

export const getAppointmentCategoriesFx = createEffect(
  getAppointmentCategories,
);

export const $appointmentCategoriesStore = createStore<{
  categories: Category[];
  error: Error | null;
  loading: boolean;
}>({
  categories: [],
  error: null,
  loading: true,
});

$appointmentCategoriesStore
  .on(getAppointmentCategoriesFx.doneData, (store, response) => ({
    ...store,
    categories: response.data.categories,
    loading: false,
  }))
  .on(getAppointmentCategoriesFx.pending, (store, loading) => ({
    ...store,
    loading,
  }))
  .on(getAppointmentCategoriesFx.failData, (store, error) => ({
    ...store,
    error,
  }));
