import { colors } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  root: {
    position: "sticky",
    bottom: 0,
    backgroundColor: colors.white,
    zIndex: 100,
  },
}));
