import { FC, memo } from "react";
import clsx from "clsx";

import { ColorfulBoxProps } from "./types";
import { useStyles } from "./styles";

export const ColorfulBox: FC<ColorfulBoxProps> = memo(
  ({ color, children, ...props }) => {
    const styles = useStyles();

    return (
      <div
        className={clsx(
          styles.root,
          color === "success" && styles.successVariant,
          color === "error" && styles.errorVariant,
          color === "info" && styles.infoVariant,
          color === "warning" && styles.warningVariant,
        )}
        style={{ color: color, ...props }}
      >
        {children}
      </div>
    );
  },
);
