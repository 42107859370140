import { Paper } from "@chhjpackages/components";
import { Divider, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { memo } from "react";

export const SelectionCardSkeleton = memo(
  ({ hideSubTitle }: { hideSubTitle?: boolean }) => {
    return (
      <Paper elevation={16}>
        <div style={{ display: "flex" }}>
          <div
            style={{
              flex: 1,
              padding: 16,
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ marginRight: 16 }}>
              <Skeleton variant="circle" width={48} height={48} />
            </div>
            <div style={{ flex: 1 }}>
              <Typography variant="subtitle1">
                <Skeleton variant="text" width="70%" />
              </Typography>
              {!hideSubTitle && (
                <Typography variant="subtitle1">
                  <Skeleton variant="text" width={70} />
                </Typography>
              )}
            </div>
          </div>
          <div>
            <Divider orientation="vertical" />
          </div>
          <div
            style={{
              minWidth: 40,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Skeleton variant="rect" height="100%" width="100%" />
          </div>
        </div>
      </Paper>
    );
  },
);
