import { Button, colors, Paper } from "@chhjpackages/components";
import { Box, Divider, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { memo } from "react";

import { useStyles } from "./styles";

export const ProductCardSkeleton = memo(() => {
  const styles = useStyles();

  return (
    <Paper elevation={16}>
      <Box px={2} py={1}>
        <Box>
          <Typography variant="body1" className={styles.category}>
            <Skeleton variant="text" width="70%" />
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle1" className={styles.name}>
            <Skeleton variant="text" width="50%" />
          </Typography>
        </Box>
        <Box mt={1} display="flex">
          <Box mr={2}>
            <Typography variant="subtitle1">
              <Skeleton variant="text" width={91} />
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1">
              <Skeleton variant="text" width={75} />
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        <Divider style={{ backgroundColor: colors.grey20 }} />
      </Box>
      <Box display="flex">
        <Box flex={1}>
          <Button buttonType="text" fullWidth disabled>
            <Typography variant="h5">
              <Skeleton variant="text" width={72} />
            </Typography>
          </Button>
        </Box>
        <Box py={1}>
          <Divider
            orientation="vertical"
            style={{ backgroundColor: colors.grey20 }}
          />
        </Box>
        <Box flex={1}>
          <Button buttonType="text" fullWidth disabled>
            <Typography variant="h5" color="inherit">
              <Skeleton variant="text" width={50} />
            </Typography>
          </Button>
        </Box>
      </Box>
    </Paper>
  );
});
