import {
  CheckRestorePasswordTokenTime,
  Login,
  RequestResetPasswordLink,
  RestorePassword,
} from "../types";
import { fetcher } from "shared/libs";

export const login = ({ payload }: Login.Params) =>
  fetcher.post<Login.Response>("/login", { ...payload });

export const requestResetPasswordLink = ({
  payload,
}: RequestResetPasswordLink.Params) =>
  fetcher.post<RequestResetPasswordLink.Response>("/password/reset", {
    uiHandler: "hunkware-crewapp",
    ...payload,
  });

export const restorePassword = ({ payload }: RestorePassword.Params) =>
  fetcher.put<RestorePassword.Response>("/password/reset", {
    ...payload,
  });

export const checkRestorePasswordTokenTime = ({
  payload,
}: CheckRestorePasswordTokenTime.Params) =>
  fetcher.post<CheckRestorePasswordTokenTime.Response>(
    "/password/reset/check",
    {
      ...payload,
    },
  );
