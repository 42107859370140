import { Button, Paper } from "@chhjpackages/components";
import { memo } from "react";
import clsx from "clsx";
import { Divider, Typography } from "@material-ui/core";

import { numberToCurrency, numberWithCommas } from "shared/utils";

import { ValuationPlanCardProps } from "./types";
import { useStyles } from "./styles";

export const ValuationPlanCard = memo(
  ({ plan, isSelected, onSelect }: ValuationPlanCardProps) => {
    const styles = useStyles();

    return (
      <Paper elevation={16} className={clsx(isSelected && styles.selected)}>
        <div className={styles.section}>
          <div>
            <Typography variant="body2" className={styles.itemTitle}>
              Cost:
            </Typography>
            <Typography variant="body1" className={styles.itemDescription}>
              ${Number(plan.cost)}
            </Typography>
          </div>

          <div>
            <Typography variant="body2" className={styles.itemTitle}>
              Great for:
            </Typography>
            <Typography variant="body1" className={styles.itemDescription}>
              {plan.greatFor}
            </Typography>
          </div>
        </div>

        <Divider />

        <div className={styles.section}>
          <div>
            <Typography variant="body2" className={styles.itemTitle}>
              Goods valued at:
            </Typography>
            <Typography variant="body1" className={styles.itemDescription}>
              {numberToCurrency(numberWithCommas(plan.goodsValued, 2))}
            </Typography>
          </div>

          <div>
            <Typography variant="body2" className={styles.itemTitle}>
              Max liability:
            </Typography>
            <Typography variant="body1" className={styles.itemDescription}>
              ${Number(plan.maxLiability)}/lb per item
            </Typography>
          </div>
        </div>

        <Divider />

        <Button
          buttonType={isSelected ? "filled" : "text"}
          color="primary"
          fullWidth
          classes={{ root: styles.buttonRoot }}
          onClick={onSelect}
        >
          {isSelected ? "Plan selected" : "Select this plan"}
        </Button>
      </Paper>
    );
  },
);
