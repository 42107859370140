import { ClockTimepicker } from "@chhjpackages/components";
import { Box, Typography } from "@material-ui/core";

import { useStyles } from "./styles";
import { EditHoursElementProps } from "./types";

export const EditHoursElement = ({
  title,
  clockTimepickerProps,
}: EditHoursElementProps) => {
  const styles = useStyles();

  return (
    <Box display="flex" alignItems="center">
      <Box minWidth={66}>
        <Typography variant="caption" className={styles.title}>
          {title}
        </Typography>
      </Box>
      <Box ml={4}>
        <ClockTimepicker
          {...clockTimepickerProps}
          buttonClasses={{
            root: styles.clockTimePickerButtonRoot,
          }}
        />
      </Box>
    </Box>
  );
};
