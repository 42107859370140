import { memo } from "react";
import { Grid, Typography } from "@material-ui/core";
import { Paper } from "@chhjpackages/components";

import Before1 from "../../assets/images/1-before.png";
import After1 from "../../assets/images/1-after.png";
import Before2 from "../../assets/images/2-before.png";
import After2 from "../../assets/images/2-after.png";
import Before31 from "../../assets/images/3-before-1.png";
import Before32 from "../../assets/images/3-before-2.png";
import After3 from "../../assets/images/3-after.png";
import Before4 from "../../assets/images/4-before.png";
import After4 from "../../assets/images/4-after.png";
import Before5 from "../../assets/images/5-before.png";
import After5 from "../../assets/images/5-after.png";
import Before6 from "../../assets/images/6-before.png";
import After6 from "../../assets/images/6-after.png";

import { useStyles } from "./styles";

export const Page8 = memo(() => {
  const styles = useStyles();

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.leftSide}>
          <div className={styles.mainText}>
            <Typography variant="h3" component="div">
              before & after
            </Typography>
          </div>
        </div>

        <div className={styles.rightSide}>
          <div className={styles.cardWrapper}>
            <Paper roundingValue={0} elevation={16}>
              <div className={styles.card}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <img src={Before1} alt="Before" />
                  </Grid>
                  <Grid item xs={6}>
                    <img src={After1} alt="After" />
                  </Grid>
                </Grid>
              </div>
            </Paper>
          </div>

          <div className={styles.cardWrapper}>
            <Paper roundingValue={0} elevation={16}>
              <div className={styles.card}>
                <Grid container spacing={2} direction="column">
                  <Grid item xs={12}>
                    <img src={Before2} alt="Before" />
                  </Grid>
                  <Grid item xs={12}>
                    <img src={After2} alt="After" />
                  </Grid>
                </Grid>
              </div>
            </Paper>
          </div>

          <div className={styles.cardWrapper}>
            <Paper roundingValue={0} elevation={16}>
              <div className={styles.card}>
                <Grid container spacing={2} direction="column">
                  <Grid item xs={12}>
                    <img src={Before31} alt="Before" />
                  </Grid>
                  <Grid item xs={12}>
                    <img src={Before32} alt="After" />
                  </Grid>
                  <Grid item xs={12}>
                    <img src={After3} alt="After" />
                  </Grid>
                </Grid>
              </div>
            </Paper>
          </div>

          <div className={styles.cardWrapper}>
            <Paper roundingValue={0} elevation={16}>
              <div className={styles.card}>
                <Grid container spacing={2} direction="column">
                  <Grid item xs={12}>
                    <img src={Before4} alt="Before" />
                  </Grid>
                  <Grid item xs={12}>
                    <img src={After4} alt="After" />
                  </Grid>
                </Grid>
              </div>
            </Paper>
          </div>

          <div className={styles.cardWrapper}>
            <Paper roundingValue={0} elevation={16}>
              <div className={styles.card}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <img src={Before5} alt="Before" />
                  </Grid>
                  <Grid item xs={6}>
                    <img src={After5} alt="After" />
                  </Grid>
                </Grid>
              </div>
            </Paper>
          </div>

          <div className={styles.cardWrapper}>
            <Paper roundingValue={0} elevation={16}>
              <div className={styles.card}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <img src={Before6} alt="Before" />
                  </Grid>
                  <Grid item xs={6}>
                    <img src={After6} alt="After" />
                  </Grid>
                </Grid>
              </div>
            </Paper>
          </div>
        </div>
      </div>
    </div>
  );
});
