import { Box, Typography } from "@material-ui/core";
import { Button, colors } from "@chhjpackages/components";
import { memo } from "react";

import { FormInput } from "shared/ui";
import { FormInputWithEye } from "features/auth/ui";

import { LoginFormProps, useLoginForm } from "./model";

export const LoginForm = memo(({ onSubmit }: LoginFormProps) => {
  const {
    control,
    formState: { isSubmitting, isDirty, isValid, errors },
    onHandleSubmit,
    handleSubmit,
  } = useLoginForm({ onSubmit });

  return (
    <form onSubmit={handleSubmit(onHandleSubmit)}>
      <Box textAlign="center" color={colors.grey180}>
        <Typography variant="h4" color="inherit">
          Please log in
        </Typography>
      </Box>

      <Box mt={3}>
        <FormInput
          name="username"
          label="Username"
          control={control}
          fullWidth
        />
      </Box>

      <Box mt={3}>
        <FormInputWithEye
          name="password"
          label="Password"
          type="password"
          control={control}
          fullWidth
        />
      </Box>

      <Box display="flex" justifyContent="center" marginTop={{ xs: 3, sm: 6 }}>
        <Box width="100%">
          <Button
            fullWidth
            buttonType="twoTone"
            type="submit"
            size="large"
            isLoading={isSubmitting}
            disabled={!isDirty || !isValid || !!errors.password}
          >
            Login
          </Button>
        </Box>
      </Box>
    </form>
  );
});
