import { memo } from "react";
import { Grid } from "@material-ui/core";
import { colors } from "@chhjpackages/components";

import { ColorfulBox } from "shared/ui";

import { AppointmentTypeCategoryStatusViewProps } from "./types";

export const AppointmentTypeCategoryStatusView = memo(
  ({
    type,
    category,
    status,
    zone,
  }: AppointmentTypeCategoryStatusViewProps) => (
    <Grid container alignItems="center" spacing={1}>
      {type && (
        <Grid item>
          <ColorfulBox color={colors.black}>{type}</ColorfulBox>
        </Grid>
      )}
      {category && (
        <Grid item>
          <ColorfulBox color={colors.black}>{category}</ColorfulBox>
        </Grid>
      )}
      {status && (
        <Grid item>
          <ColorfulBox color={colors.black}>{status}</ColorfulBox>
        </Grid>
      )}
      {zone && (
        <Grid item>
          <ColorfulBox color={colors.black}>{zone}</ColorfulBox>
        </Grid>
      )}
    </Grid>
  ),
);
