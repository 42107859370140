import {
  Box,
  Typography,
  CardContent,
  CardActionArea,
  Card,
} from "@material-ui/core";
import { Loader } from "@chhjpackages/components";
import { memo } from "react";

import { QuickAddCardProps } from "./types";
import { useStyles } from "./styles";

export const QuickAddCard = memo(
  ({ title, subtitle, loading, onClick }: QuickAddCardProps) => {
    const styles = useStyles();

    return (
      <Card
        style={{
          height: "100px",
          width: "135px",
          boxShadow: "none",
        }}
        onClick={!loading ? onClick : undefined}
      >
        <CardActionArea style={{ height: "100%" }}>
          <CardContent style={{ position: "relative" }}>
            <Box
              style={{
                opacity: loading ? 0.3 : 1,
              }}
            >
              <Typography variant="h6" align="left">
                {title}
              </Typography>
              <Typography
                className={styles.sliderPrice}
                variant="body1"
                align="left"
              >
                {`$${subtitle}`}
              </Typography>
            </Box>
            {loading && (
              <Box
                position="absolute"
                top={0}
                left={0}
                height="100%"
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Loader />
              </Box>
            )}
          </CardContent>
        </CardActionArea>
      </Card>
    );
  },
);
