import { Paper } from "@chhjpackages/components";
import { Box, Typography } from "@material-ui/core";
import { memo } from "react";
import { Skeleton } from "@material-ui/lab";

export const PricingCardSkeleton = memo(() => {
  return (
    <Paper elevation={16}>
      <Box p={2}>
        <Box display="flex" alignItems="center">
          <Box mr={3}>
            <Skeleton variant="circle" width={22} height={22} />
          </Box>
          <Box flex={1}>
            <Typography variant="subtitle1">
              <Skeleton variant="text" width="80%" />
            </Typography>

            <Typography variant="subtitle1">
              <Skeleton variant="text" width={70} />
            </Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
});
