import { Product } from "shared/types";
import {
  PricingTypesEnum,
  PricingCategoriesEnum,
  PricingCategoriesNameEnum,
} from "features/pricings/model/types";
import { groupBy } from "shared/utils";

import { ProductsResponse } from "./types";

export const getProductsFromResponse = (
  products: ProductsResponse[],
): Product[] =>
  products.map((product) => ({
    ...product,
    productLineId: product.id,
    productId: product.product.id,
    productName: product.name,
    productSubcategoryName: product.subcategoryName,
    quantity: product.qty,
  }));

export const pricingsFilterOptions = [
  {
    value: PricingTypesEnum.Basic,
    label: "Basic",
  },
  {
    value: PricingTypesEnum.Surcharges,
    label: "Surcharges",
  },
  {
    value: PricingTypesEnum.FlatRate,
    label: "Flat rate",
  },
];

export const pricingsCategoryOptions = {
  [PricingCategoriesEnum.All]: PricingCategoriesNameEnum.All,
  [PricingCategoriesEnum.JunkRemoval]: PricingCategoriesNameEnum.JunkRemoval,
  [PricingCategoriesEnum.ExpressJunk]: PricingCategoriesNameEnum.ExpressJunk,
  [PricingCategoriesEnum.GeneralLabor]: PricingCategoriesNameEnum.GeneralLabor,
  [PricingCategoriesEnum.MoveLabor]: PricingCategoriesNameEnum.MoveLabor,
  [PricingCategoriesEnum.Moving]: PricingCategoriesNameEnum.Moving,
  [PricingCategoriesEnum.MovingAccessorial]:
    PricingCategoriesNameEnum.MovingAccessorial,
};

export const checkExistingProduct = (
  products: Product[],
  productForCheck: { id: number; price: number; taxId: number; notes: string },
) => {
  return products.find(
    (product) =>
      product.productId === productForCheck.id &&
      product.actualPrice === productForCheck.price &&
      product.salesTaxId === productForCheck.taxId &&
      product.notes === productForCheck.notes,
  );
};

export const getProductItems = (productsStore: Product[]) => {
  let products = [...productsStore];
  const productsById = groupBy(products, (product) => product.productId);

  Object.entries(productsById).forEach(([, productsByKey]) => {
    if (productsByKey.length > 1) {
      const [, ...restProducts] = productsByKey;

      restProducts.forEach((restProduct, index) => {
        const productIndex = products.findIndex(
          (p) => p.productLineId === restProduct.productLineId,
        );

        products[productIndex] = {
          ...restProduct,
          productName: `${restProduct.productName} ${index + 2}`,
        };
      });
    }
  });

  return products;
};
