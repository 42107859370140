import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  incrementButton: {
    [theme.breakpoints.down("xs")]: {
      width: 40,
      height: 40,
    },
  },
  incrementInputWrapper: {
    [theme.breakpoints.down("xs")]: {
      height: 40,
      width: 40,

      "& input": {
        padding: "8.5px 10.5px !important",
      },
    },
  },
}));
