import { memo } from "react";
import { Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

import { useStyles } from "./styles";

export const ValuationOptionCardSkeleton = memo(() => {
  const styles = useStyles();

  return (
    <div>
      <Skeleton variant="rect" width={140} height={28} />

      <div className={styles.content}>
        <Typography variant="h4" color="secondary">
          <Skeleton variant="text" width="100%" />
        </Typography>

        <Typography variant="body1">
          <Skeleton variant="text" width="100%" />
        </Typography>

        <Typography variant="body1">
          <Skeleton variant="text" width="100%" />
        </Typography>

        <Typography variant="body1">
          <Skeleton variant="text" width="100%" />
        </Typography>
      </div>
    </div>
  );
});
