import { fetcher } from "shared/libs";
import { Valuations } from "../types";

export const getCurrentValuation = ({
  locationId,
  appointmentId,
}: Valuations.GetOne) =>
  fetcher.get<Valuations.Response>(
    `/locations/${locationId}/appointments/${appointmentId}/sdo`,
  );

export const generateValuation = ({
  locationId,
  appointmentId,
  payload,
}: Valuations.Create) =>
  fetcher.post<Valuations.Response>(
    `/locations/${locationId}/appointments/${appointmentId}/sdo`,
    payload,
  );

export const updateValuation = ({
  locationId,
  appointmentId,
  valuationId,
  payload,
}: Valuations.Update) =>
  fetcher.put<Valuations.Response>(
    `/locations/${locationId}/appointments/${appointmentId}/sdo/${valuationId}`,
    payload,
  );
