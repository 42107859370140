export const getCompletedRequirements = () =>
  JSON.parse(
    localStorage.getItem("crew/job/completedRequirements") ?? "[]",
  ) as {
    appointmentId: number;
    requirements: number[];
  }[];

export const getJobCompletedRequirements = (appointmentId: number) => {
  const completedRequirements = getCompletedRequirements();

  return completedRequirements.find(
    (completedRequirement) =>
      completedRequirement.appointmentId === appointmentId,
  );
};

export const markRequirementAsCompleted = (
  appointmentId: number,
  requirementId: number,
) => {
  const completedRequirements = getCompletedRequirements();
  const alreadyExistingAppointment = getJobCompletedRequirements(appointmentId);
  const alreadyExistingRequirement =
    alreadyExistingAppointment?.requirements.includes(requirementId);

  const result = {
    appointmentId,
    requirements: alreadyExistingRequirement
      ? alreadyExistingAppointment?.requirements ?? []
      : [...(alreadyExistingAppointment?.requirements ?? []), requirementId],
  };

  const newCompletedRequirements: {
    appointmentId: number;
    requirements: number[];
  }[] = [];

  if (alreadyExistingAppointment) {
    newCompletedRequirements.push(
      ...completedRequirements.map((completedRequirement) =>
        completedRequirement.appointmentId === appointmentId
          ? result
          : completedRequirement,
      ),
    );
  } else {
    newCompletedRequirements.push(...[...completedRequirements, result]);
  }

  localStorage.setItem(
    "crew/job/completedRequirements",
    JSON.stringify(newCompletedRequirements),
  );
};

export const markRequirementAsUncompleted = (
  appointmentId: number,
  requirementId: number,
) => {
  const alreadyExistingAppointment = getJobCompletedRequirements(appointmentId);
  const alreadyExistingRequirement =
    alreadyExistingAppointment?.requirements.includes(requirementId);

  if (!!alreadyExistingAppointment && !!alreadyExistingRequirement) {
    const completedRequirements = getCompletedRequirements();
    const newCompletedRequirements = completedRequirements.map(
      (completedRequirement) =>
        completedRequirement.appointmentId === appointmentId
          ? {
              ...completedRequirement,
              requirements: completedRequirement.requirements.filter(
                (requirement) => requirement !== requirementId,
              ),
            }
          : completedRequirement,
    );

    localStorage.setItem(
      "crew/job/completedRequirements",
      JSON.stringify(newCompletedRequirements),
    );
  }
};
