import { useCallback, useMemo, useEffect, useState, memo } from "react";
import {
  Paper,
  Button,
  useAlert,
  useDialogDispatch,
} from "@chhjpackages/components";
import { useStore } from "effector-react/compat";
import { useNavigate } from "react-router-dom";

import { updateAccountFx } from "features/appointment/model/store/account";
import { ContactEditForm } from "features/appointment/ui/contacts-dialog/contact-edit";
import {
  $appointmentStore,
  markAppointmentLostFx,
  Appointments,
  getAppointmentFx,
  GeneralDetails,
  GeneralInformation,
  GeneralTimes,
} from "features/appointment";
import { $developments } from "features/developments";
import { openMapByType, routePaths } from "shared/utils";
import { $teamsStore } from "features/teams";
import { NasaRequirements } from "features/nasa-job-details";
import { $requirements } from "features/requirements";
import { $settings } from "features/settings";

export const GeneralTab = memo(() => {
  const navigate = useNavigate();

  const { showAlert } = useAlert();

  const { appointment } = useStore($appointmentStore);
  const {
    timeTravelled,
    timeWorked,
    billableTimeTravelled,
    billableTimeWorked,
  } = useStore($developments);
  const { userTeamsByDate } = useStore($teamsStore);
  const { filteredRequirements } = useStore($requirements);
  const { navigationApp } = useStore($settings);

  const setDialog = useDialogDispatch();

  const [editDialog, setEditDialog] = useState(false);
  const [isUserDataSubmiting, setIsUserDataSubmiting] = useState(false);

  const isNasa = useMemo(
    () => !!appointment?.subpartner.id,
    [appointment?.subpartner.id],
  );

  const requirementsList = useMemo(
    () =>
      filteredRequirements.map((requirement) => ({
        id: requirement.id,
        name: requirement.title,
      })),
    [filteredRequirements],
  );

  const canViewContacts = useMemo(
    () =>
      appointment &&
      !!userTeamsByDate?.find(
        (team) =>
          !!appointment.schedules.find(
            (schedule) => schedule.team.id === team.id,
          ),
      ),
    [appointment, userTeamsByDate],
  );

  const handleOpenEditContactsDialog = useCallback(() => {
    setEditDialog(true);
  }, []);

  const handleCloseEditContactsDialog = useCallback(() => {
    setEditDialog(false);
  }, []);

  const onDataSubmit = useCallback(
    async (data) => {
      if (!appointment) {
        return;
      }

      try {
        setIsUserDataSubmiting(true);
        await updateAccountFx({
          locationId: appointment.location.id,
          accountId: Number(appointment.account.id),
          payload: {
            firstName: data.firstName,
            lastName: data.lastName,
            phone: data.primaryPhone,
            cell: data.secondaryPhone,
            email: data.email,
          },
        });
        await getAppointmentFx({
          locationId: appointment.location.id,
          appointmentId: Number(appointment.id),
        });
        setIsUserDataSubmiting(false);

        showAlert("Success! Contact information has been updated.", {
          variant: "success",
        });

        handleCloseEditContactsDialog();
      } catch {
        showAlert("Error! Failed to update contact information.", {
          variant: "error",
        });
      }
    },
    [appointment, showAlert],
  );

  const onOpenContactsDialog = useCallback(
    (appId: number) => {
      navigate(routePaths.jobDetailsContacts(appId));
    },
    [navigate],
  );

  const onOpenNotesDialog = useCallback(
    (appId: number) => {
      navigate(routePaths.jobDetailsNotesModal(appId));
    },
    [navigate],
  );

  const onNavigate = useCallback(
    (destination: string) => {
      openMapByType(
        navigationApp.value as "apple" | "google" | "waze",
        "My Location",
        destination,
      );
    },
    [navigationApp.value],
  );

  const onMarkAppointmentLost = useCallback(async () => {
    if (appointment) {
      try {
        await markAppointmentLostFx({
          locationId: appointment.location.id,
          appointmentId: appointment.id,
          type: appointment.type,
        });

        showAlert("Success! Appointment has been marked as lost.", {
          variant: "success",
        });
      } catch {
        showAlert("Error! Failed to mark appointment as lost.", {
          variant: "success",
        });
      }
    }
  }, [appointment, showAlert]);

  useEffect(() => {
    setDialog({
      open: editDialog,
      variant: "basic",
      title: "Contact information",
      disableBackdropClick: true,
      dialogContent: (
        <div style={{ maxWidth: "488px" }}>
          <div style={{ maxWidth: "100%", width: "100vw" }}>
            <ContactEditForm
              appointment={appointment as Appointments | undefined}
              onDataSubmit={onDataSubmit}
              isUserDataSubmiting={isUserDataSubmiting}
              handleCloseEditContactsDialog={handleCloseEditContactsDialog}
            />
          </div>
        </div>
      ),
      onClose: handleCloseEditContactsDialog,
    });

    return () => setDialog({ open: false });
  }, [
    setDialog,
    editDialog,
    appointment,
    handleCloseEditContactsDialog,
    isUserDataSubmiting,
    onDataSubmit,
  ]);

  return (
    <div style={{ padding: 16 }}>
      {isNasa && <NasaRequirements requirements={requirementsList} />}

      {appointment && (
        <>
          <Paper elevation={16} style={{ marginTop: isNasa ? 16 : 0 }}>
            <GeneralDetails
              appointment={appointment}
              onMarkAsLost={onMarkAppointmentLost}
              handleOpenContactsDialog={onOpenContactsDialog}
              handleOpenNotesDialog={onOpenNotesDialog}
              handleNavigate={onNavigate}
            />
          </Paper>

          {!isNasa && (
            <div style={{ marginTop: 16 }}>
              <Paper elevation={16}>
                <GeneralTimes
                  timeTravelled={timeTravelled}
                  timeWorked={timeWorked}
                  billableTimeTravelled={billableTimeTravelled}
                  billableTimeWorked={billableTimeWorked}
                />
              </Paper>
            </div>
          )}

          {canViewContacts && (
            <>
              <div style={{ marginTop: 16 }}>
                <Paper elevation={16}>
                  <GeneralInformation
                    appointment={appointment}
                    clientInfoEdit={handleOpenEditContactsDialog}
                  />
                </Paper>
              </div>

              {!isNasa && (
                <div style={{ marginTop: 16 }}>
                  <Button
                    size="large"
                    buttonType="outlined"
                    fullWidth
                    onClick={() => handleOpenEditContactsDialog()}
                  >
                    EDIT CONTACT INFORMATION
                  </Button>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
});
