import { fetcher } from "shared/libs";
import { Payments } from "../types";

export const createPayment = ({
  locationId,
  appointmentId,
  payload,
}: Payments.Create) =>
  fetcher.post<Payments.Response>(
    `/locations/${locationId}/appointments/${appointmentId}/payments`,
    payload,
  );
