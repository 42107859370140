import { memo, ReactNode, useCallback, useMemo, useState } from "react";
import { NavigateOptions } from "react-router-dom";

import { SideNavDispatchContext, SideNavStateContext } from "./model";
import {
  HeaderActionButton,
  SideNavDispatchContextValue,
  SideNavStateContextValue,
} from "./model/types";

export const SideNavController = memo(({ children }) => {
  const [showGoBackButton, setShowGoBackButton] = useState(false);
  const [goBackToUrl, setGoBackUrl] = useState<string>("");
  const [goBackOptions, setGoBackOptions] = useState<NavigateOptions>();
  const [pageName, setPageName] = useState("");
  const [actionButton, setActionButton] = useState<HeaderActionButton | null>(
    null,
  );
  const [pageNameAddition, setPageNameAddition] = useState<ReactNode | null>(
    null,
  );
  const [elevation, setElevation] = useState(0);

  const handleSetPageName = useCallback((name) => {
    setPageName(name);
  }, []);

  const handleSetActionButton = useCallback((actionButtonProps) => {
    setActionButton(actionButtonProps);
  }, []);

  const handleShowGoBackButton = useCallback((value?: boolean) => {
    if (typeof value === "boolean") {
      setShowGoBackButton(value);
      return;
    }

    setShowGoBackButton((prevState) => !prevState);
  }, []);

  const handleSetGoBackUrl = useCallback((url: string) => {
    setGoBackUrl(url);
  }, []);

  const handleSetGoBackOptions = useCallback((options?: NavigateOptions) => {
    setGoBackOptions(options);
  }, []);

  const handleSetPageNameAddition = useCallback((content) => {
    setPageNameAddition(content);
  }, []);

  const handleClearActionButton = useCallback(() => {
    setActionButton(null);
  }, []);

  const handleSetElevation = useCallback((value: number) => {
    setElevation(value);
  }, []);

  const state: SideNavStateContextValue = useMemo(
    () => ({
      showGoBackButton,
      goBackToUrl,
      goBackOptions,
      pageName,
      actionButton,
      pageNameAddition,
      elevation,
    }),
    [
      goBackToUrl,
      goBackOptions,
      showGoBackButton,
      pageName,
      actionButton,
      pageNameAddition,
      elevation,
    ],
  );

  const dispatchValues: SideNavDispatchContextValue = useMemo(() => {
    return {
      setPageName: handleSetPageName,
      setActionButton: handleSetActionButton,
      setShowGoBackButton: handleShowGoBackButton,
      setGoToBackUrl: handleSetGoBackUrl,
      setGoToBackOptions: handleSetGoBackOptions,
      clearActionButton: handleClearActionButton,
      setPageNameAddition: handleSetPageNameAddition,
      setElevation: handleSetElevation,
    };
  }, [
    handleSetPageName,
    handleSetActionButton,
    handleShowGoBackButton,
    handleSetGoBackUrl,
    handleSetGoBackOptions,
    handleClearActionButton,
    handleSetPageNameAddition,
    handleSetElevation,
  ]);

  return (
    <SideNavStateContext.Provider value={state}>
      <SideNavDispatchContext.Provider value={dispatchValues}>
        {children}
      </SideNavDispatchContext.Provider>
    </SideNavStateContext.Provider>
  );
});
