import { Appointments } from "features/appointment";
import { Requirement } from "features/requirements";
import { CompletedAction } from "shared/types";

export type ProcessWorkProps = {
  appointment: Appointments | null;
  status: string;
  time: number;
  isPaused: boolean;
  completedActions: CompletedAction[];
  requirements: Requirement[];
  billOfLadingDenied: boolean;
  onStartWork: () => Promise<void>;
  onPauseWork: () => Promise<void>;
  onEndWork: () => Promise<void>;
  onEditTime: () => void;
};

export enum StepTwoLinksTypesEnum {
  BeforeWork = "Before work is started",
  DuringJob = "During job",
  AfterJob = "After job completion",
  None = "",
}
