import { colors } from "@chhjpackages/components";
import { makeStyles, withStyles } from "@material-ui/styles";

import { FormIncrementInput } from "shared/ui";

export const useStyles = makeStyles({
  input: {
    textAlign: "right",
    color: `${colors.grey140}!important`,
  },
  select: {
    color: colors.secondary.main,
    fontWeight: "bold",
  },
});

export const FormIncrementInputStyled = withStyles({
  button: {
    width: 28,
    height: 28,
    borderRadius: 3,
    "& svg": {
      fontSize: "12px!important",
    },
  },
  inputWrapper: {
    width: 58,
    height: 28,
    borderRadius: 4,
    "& input": {
      padding: "2px!important",
      color: `${colors.grey140}!important`,
    },
  },
})(FormIncrementInput);
