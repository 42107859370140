import { Typography } from "@material-ui/core";
import { memo } from "react";

import { TotalTimeRowProps } from "./types";
import { useStyles } from "./styles";

export const TotalTimeRow = memo(({ title, time }: TotalTimeRowProps) => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Typography variant="subtitle2" className={styles.title}>
        {title}
      </Typography>

      {time && (
        <Typography variant="body2" color="inherit" align="right">
          {time}
        </Typography>
      )}
    </div>
  );
});
