import { fetcher } from "shared/libs";
import { LiabilityWaivers } from "../types";

export const getCurrentLiablityWaiver = ({
  locationId,
  appointmentId,
}: LiabilityWaivers.GetOne) =>
  fetcher.get<LiabilityWaivers.Response>(
    `/locations/${locationId}/appointments/${appointmentId}/lw`,
  );

export const generateLiablityWaiver = ({
  locationId,
  appointmentId,
  payload,
}: LiabilityWaivers.Create) =>
  fetcher.post<LiabilityWaivers.Response>(
    `/locations/${locationId}/appointments/${appointmentId}/lw`,
    payload,
  );

export const updateLiablityWaiver = ({
  locationId,
  appointmentId,
  liabilityWaiverId,
  payload,
}: LiabilityWaivers.Update) =>
  fetcher.put<LiabilityWaivers.Response>(
    `/locations/${locationId}/appointments/${appointmentId}/lw/${liabilityWaiverId}`,
    payload,
  );
