import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Dialog, IconButton, Typography } from "@material-ui/core";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
  Close,
} from "@material-ui/icons";
import { useStore } from "effector-react";

import { routePaths } from "shared/utils";
import { $auth } from "features/auth";
import { $appointmentStore, getAppointmentFx } from "features/appointment";
import { markBinderPresentationAsCompleted } from "features/training-details";
import { getPricingsFx } from "features/pricings";

import { pages } from "./utils";
import { useStyles } from "./assets/styles";

export const BinderPresentationDetails = memo(() => {
  const navigate = useNavigate();
  const styles = useStyles();
  const { presentationId } = useParams<"presentationId">();
  const [searchParams] = useSearchParams();

  const { locationId } = useStore($auth);
  const { appointment } = useStore($appointmentStore);

  const appointmentId = useMemo(
    () => searchParams.get("appointmentId"),
    [searchParams],
  );

  useEffect(() => {
    if (
      locationId &&
      Number(appointmentId) &&
      Number(appointmentId) !== appointment?.id
    ) {
      getAppointmentFx({ locationId, appointmentId: Number(appointmentId) });
    }
  }, [appointment?.id, appointmentId, locationId]);

  useEffect(() => {
    if (appointment && Number(appointmentId) === appointment?.id) {
      getPricingsFx({
        locationId: appointment.location.id,
        appointmentId: appointment.id,
        postal: appointment.origin.postal,
        categoryId: appointment.category.id,
        jobDate: appointment.startDate,
      });
    }
  }, [appointment, appointmentId]);

  const index = useMemo(() => Number(presentationId) || 1, [presentationId]);

  const current = useMemo(() => pages[index - 1], [index]);

  const setPage = useCallback(
    (data: -1 | 1) =>
      navigate({
        pathname: routePaths.trainingBinderPresentationDetails(index + data),
        search: `?${createSearchParams(searchParams)}`,
      }),
    [index, navigate, searchParams],
  );

  const closePresentation = useCallback(() => {
    if (appointmentId) {
      markBinderPresentationAsCompleted(Number(appointmentId));
    }
    navigate({
      pathname: routePaths.trainingBinderPresentation(),
      search: `?${createSearchParams(searchParams)}`,
    });
  }, [navigate, searchParams, appointmentId]);

  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  const minSwipeDistance = 50;

  const onTouchStart = useCallback((e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  }, []);

  const onTouchMove = useCallback(
    (e) => setTouchEnd(e.targetTouches[0].clientX),
    [],
  );

  const onTouchEnd = useCallback(() => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;

    if (isLeftSwipe && index < pages.length) {
      setPage(1);
    }

    if (isRightSwipe && index > 1) {
      setPage(-1);
    }
  }, [index, setPage, touchEnd, touchStart]);

  return (
    <Dialog
      open={true}
      fullScreen={true}
      disableEnforceFocus={true}
      disableBackdropClick={true}
      maxWidth={"xl"}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
    >
      {current}

      <div className={styles.presentationButtons}>
        <div className={styles.presentationNav}>
          <IconButton
            size="small"
            color="secondary"
            onClick={() => setPage(-1)}
            disabled={index === 1}
          >
            <ArrowBackIosRounded fontSize="small" />
          </IconButton>

          <Typography
            color="primary"
            variant="h5"
            component="div"
            className={styles.presentationCounter}
            align="center"
          >
            {index}/{pages.length}
          </Typography>

          <IconButton
            size="small"
            color="secondary"
            onClick={() => setPage(1)}
            disabled={index === pages.length}
          >
            <ArrowForwardIosRounded fontSize="small" />
          </IconButton>
        </div>

        <div className={styles.presentationClose}>
          <IconButton
            size="small"
            color="inherit"
            className={styles.presentationCloseIconButton}
            onClick={closePresentation}
          >
            <Close fontSize="small" />
          </IconButton>
        </div>
      </div>
    </Dialog>
  );
});
