import { colors } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  root: {
    borderRadius: "60px",
    width: "fit-content",

    "& .MuiTypography-root": {
      fontWeight: "bold",
      lineHeight: "21px",
    },
  },

  disabled: {
    backgroundColor: colors.functionals.disabledLight,

    "& .MuiTypography-root": {
      color: colors.functionals.disabled,
    },
  },

  error: {
    backgroundColor: colors.functionals.alertLight,

    "& .MuiTypography-root": {
      color: colors.functionals.alert,
    },
  },

  info: {
    backgroundColor: colors.functionals.infoLight,

    "& .MuiTypography-root": {
      color: colors.functionals.info,
    },
  },

  success: {
    backgroundColor: colors.functionals.successLight,

    "& .MuiTypography-root": {
      color: colors.functionals.success,
    },
  },

  warning: {
    backgroundColor: colors.functionals.warningLight,

    "& .MuiTypography-root": {
      color: colors.functionals.warning,
    },
  },
}));
