import { SvgIcon } from "@material-ui/core";
import { CSSProperties } from "react";

export const MissingPropertyIcon = ({
  className,
  fontSize,
}: {
  className?: string;
  styles?: CSSProperties;
  fontSize?: number | string;
}) => {
  return (
    <SvgIcon
      className={className}
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      style={{ fill: "none", fontSize: fontSize || 16 }}
    >
      <path
        d="M31.7575 20.4519L29.5582 15.4497C29.5582 15.4281 29.5366 15.4281 29.5366 15.4066L29.5151 15.385C29.4935 15.3634 29.4935 15.3634 29.4719 15.3634L29.4504 15.3419C29.4288 15.3419 29.4073 15.3419 29.4073 15.3203C29.3857 15.3203 29.3857 15.3203 29.3641 15.3203C29.3426 15.3203 29.2995 15.3203 29.2779 15.3419L17.5054 19.6757L5.73287 15.3419C5.71131 15.3203 5.66818 15.3203 5.64662 15.3203C5.62506 15.3203 5.62506 15.3203 5.6035 15.3203C5.58194 15.3203 5.56038 15.3203 5.56038 15.3419C5.53881 15.3419 5.53881 15.3634 5.51725 15.3634C5.49569 15.3634 5.49569 15.385 5.47413 15.385L5.45257 15.4066C5.45257 15.4281 5.43101 15.4281 5.43101 15.4497L3.23175 20.4519C3.21019 20.5166 3.21019 20.5813 3.23175 20.646C3.25331 20.7107 3.29643 20.7538 3.36112 20.7753L5.40945 21.53V29.3136C5.40945 29.4215 5.47413 29.5077 5.56038 29.5293L17.4191 33.9062C17.4407 33.9062 17.4407 33.9062 17.4623 33.9062H17.4838H17.5054C17.5269 33.9062 17.5269 33.9062 17.5485 33.9062L29.4073 29.5293C29.4935 29.4861 29.5582 29.3999 29.5582 29.3136V21.53L31.6065 20.7753C31.6712 20.7538 31.7143 20.7107 31.7359 20.646C31.8006 20.5813 31.8006 20.5166 31.7575 20.4519Z"
        fill="#F0CBA7"
      />
      <path
        d="M5.44867 19.3997V27.3343L17.3074 31.7112V17.9551L5.44867 13.5781"
        stroke="#EA7200"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.24939 18.5804L15.1081 22.9573L17.3074 17.9551L5.44865 13.5781L3.24939 18.5804Z"
        stroke="#EA7200"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.1446 19.3997V27.3343L17.2858 31.7112V17.9551L29.1446 13.5781"
        stroke="#EA7200"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.3438 18.5804L19.5067 22.9573L17.2858 17.9551L29.1446 13.5781L31.3438 18.5804Z"
        stroke="#EA7200"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.6844 5.51409C21.6844 3.33639 19.9163 1.56836 17.7386 1.56836C15.5609 1.56836 13.7929 3.33639 13.7929 5.51409H16.0353C16.0353 4.58695 16.7899 3.8323 17.7171 3.8323C18.6442 3.8323 19.3989 4.58695 19.3989 5.51409C19.3989 6.44123 18.6442 7.19588 17.7171 7.19588H16.7253V7.84272V9.28733V10.7535H18.8598V9.26577C20.4985 8.81298 21.6844 7.30369 21.6844 5.51409Z"
        fill="#E8EFEA"
      />
      <path
        d="M18.9029 12.1768H16.7252V14.3545H18.9029V12.1768Z"
        fill="#E8EFEA"
      />
      <path
        d="M12.1974 11.2061L5.44867 13.5778"
        stroke="#EA7200"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.1446 13.578L22.245 11.1631"
        stroke="#EA7200"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.2532 5.03948C21.2532 2.86178 19.4851 1.09375 17.3074 1.09375C15.1297 1.09375 13.3617 2.86178 13.3617 5.03948H15.6041C15.6041 4.11234 16.3587 3.35769 17.2859 3.35769C18.213 3.35769 18.9677 4.11234 18.9677 5.03948C18.9677 5.96662 18.213 6.72127 17.2859 6.72127H16.2509V7.36811V8.81272V10.2789H18.3855V8.79116C20.0457 8.33837 21.2532 6.82908 21.2532 5.03948Z"
        stroke="#226937"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.4502 11.7021H16.2725V13.8798H18.4502V11.7021Z"
        stroke="#226937"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
