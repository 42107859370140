import { useStore } from "effector-react/compat";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { useAlert, useConfirmationDialog } from "@chhjpackages/components";
import { InfoOutlined } from "@material-ui/icons";

import { addAttachmentsFx, getAttachmentsFx } from "features/attachments";
import { $products } from "features/products";
import {
  $completedActions,
  getCompletedAction,
  useCompletedActions,
} from "features/completed-actions";
import {
  $billOfLading,
  generateBillOfLadingFx,
  getCurrentBillOfLadingFx,
  updateBillOfLadingFx,
} from "features/bill-of-lading";
import {
  $appointmentStore,
  getAppointmentFx,
  useNotesDialog,
} from "features/appointment";
import { $auth } from "features/auth";
import { BackTitle, SignatureArea, PdfViewer, ActionsFooter } from "shared/ui";
import { fileToBase64, routePaths } from "shared/utils";
import { useSideNavDispatch } from "features/sidenav";
import { CompletedActionsJobEnum } from "shared/types";
import { useSignDialog, useSignPdf } from "features/signature";

import { useSignatureStyles } from "./assets";

const signatureFieldOffset = {
  x: 73,
  y: 22,
};

export const BillOfLadingSignature = memo(() => {
  const styles = useSignatureStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: true,
  });

  const { appointmentId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const { locationId } = useStore($auth);
  const { appointment, loading: appointmentLoading } =
    useStore($appointmentStore);
  const { billOfLading } = useStore($billOfLading);
  const { completedActions } = useStore($completedActions);
  const { products } = useStore($products);

  const {
    setPageName,
    setShowGoBackButton,
    setGoToBackUrl,
    setGoToBackOptions,
    setActionButton,
    clearActionButton,
    setElevation,
  } = useSideNavDispatch();

  const [isBolGetting, setIsBolGetting] = useState(true);
  const [isPdfRendering, setIsPdfRendering] = useState(true);
  const [isSavingBillOfLading, setIsSavingBillOfLading] = useState(false);
  const [signatureMode, setSignatureMode] = useState<
    "crew" | "client" | "done"
  >("crew");

  const { showAlert } = useAlert();
  const { addCompletedAction } = useCompletedActions();
  const { handleNotesDialogOpen } = useNotesDialog({});

  const currentSignaturePdfField = useMemo(() => {
    if (signatureMode === "crew") {
      return "signature_2";
    } else if (signatureMode === "client") {
      return "signature_1";
    }
  }, [signatureMode]);

  const {
    pdfRef,
    pdfWidth,
    blobPdf,
    pdfDoc,
    signs,
    countPages,
    pageNumberWithSignature,
    signatureFieldCoordinates,
    getPdfDoc,
    addSign,
    savePdf,
    renderSign,
  } = useSignPdf({
    width: 800,
    currentSignaturePdfField,
    signatureFieldOffset,
    isLoading: isPdfRendering,
  });

  const noticeDialogContent = useMemo(() => {
    if (signatureMode === "client") {
      return "Please give the device to the client";
    } else if (signatureMode === "done") {
      return "Please give the device back to the crew member";
    }

    return "";
  }, [signatureMode]);

  const {
    openConfirmation: handleNoticeDialogOpen,
    closeConfirmation: handleNoticeDialogClose,
  } = useConfirmationDialog({
    title: "Notice",
    message: noticeDialogContent,
    confirmButtonText: "Continue",
    hideCancelButton: true,
    maxWidth: 488,
  });

  const locationState = useMemo<
    { cartBack?: string; signatureBack?: string } | undefined
  >(() => location.state, [location.state]);

  const isBillingCompleted = useMemo(() => {
    if (!appointment) {
      return null;
    }

    return !!getCompletedAction(
      completedActions,
      CompletedActionsJobEnum.FinalizeBilling,
    );
  }, [appointment, completedActions]);

  const { goToBackUrl, goToBackState } = useMemo(() => {
    const state = {
      ...(locationState ? locationState : {}),
      isBillOfLading: true,
      cartBack: locationState?.cartBack
        ? locationState.cartBack === location.pathname
          ? routePaths.jobDetails(Number(appointmentId))
          : locationState.cartBack
        : routePaths.jobDetails(Number(appointmentId)),
    };

    if (isBillingCompleted) {
      return {
        goToBackUrl: routePaths.jobDetails(Number(appointmentId)),
        goToBackState: state,
      };
    }

    if (locationState?.signatureBack) {
      return { goToBackUrl: locationState.signatureBack, goToBackState: state };
    }

    if (products.length > 0 && !isBillingCompleted) {
      return {
        goToBackUrl: routePaths.jobDetailsCart(Number(appointmentId)),
        goToBackState: state,
      };
    }

    return {
      goToBackUrl: routePaths.jobDetailsBillOfLading(Number(appointmentId)),
      goToBackState: state,
    };
  }, [
    appointmentId,
    isBillingCompleted,
    location.pathname,
    locationState,
    products.length,
  ]);

  const isShouldDisplayFooter = useMemo(
    () => !isBillingCompleted && !isPdfRendering,
    [isBillingCompleted, isPdfRendering],
  );

  const signDialogTitle = useMemo(
    () => `${signatureMode.includes("client") ? "Client" : "Crew"} signature`,
    [signatureMode],
  );

  const handleBack = useCallback(() => {
    navigate(goToBackUrl, { state: goToBackState });
  }, [goToBackUrl, goToBackState, navigate]);

  const handleEditCart = useCallback(() => {
    navigate(routePaths.jobDetailsCart(Number(appointmentId)), {
      state: {
        ...(locationState ?? {}),
        cartBack:
          products.length > 0
            ? locationState?.cartBack ?? location.pathname
            : location.pathname,
        isBillOfLading: true,
      },
    });
  }, [
    appointmentId,
    products.length,
    locationState,
    location.pathname,
    navigate,
  ]);

  const handleSubmitSignDialog = useCallback(
    (signBlob: Blob) => {
      if (!pageNumberWithSignature) {
        return;
      }

      addSign({ blob: signBlob, imageHeight: 21 }, pageNumberWithSignature, {
        ...signatureFieldCoordinates,
        width: {
          type: "percent",
          value: 20,
        },
      });

      if (signatureMode === "crew") {
        setSignatureMode("client");
      } else if (signatureMode === "client") {
        setSignatureMode("done");
      }

      setTimeout(() => {
        handleNoticeDialogOpen(() => handleNoticeDialogClose());
      }, 0);
    },
    [
      signatureMode,
      pageNumberWithSignature,
      signatureFieldCoordinates,
      addSign,
      handleNoticeDialogOpen,
      handleNoticeDialogClose,
    ],
  );

  const { handleSignDialogOpen } = useSignDialog({
    title: signDialogTitle,
    onSubmit: handleSubmitSignDialog,
  });

  const saveBillOfLading = useCallback(async () => {
    if (!appointment || !billOfLading) {
      showAlert("Error! Something went wrong. Try again later.", {
        variant: "error",
      });
      return;
    }

    setIsSavingBillOfLading(true);

    try {
      const newBlobPdf = await savePdf();

      if (!newBlobPdf) {
        throw new Error();
      }

      await Promise.all([
        await updateBillOfLadingFx({
          locationId: appointment.location.id,
          appointmentId: appointment.id,
          bolId: billOfLading?.id,
          payload: {
            content: await fileToBase64(newBlobPdf),
          },
        }),
        await addAttachmentsFx({
          locationId: appointment.location.id,
          appointmentId: appointment.id,
          description: `Bill Of Lading - ${appointment.id} - Electronically Signed Document`,
          extension: "pdf",
          content: await fileToBase64(newBlobPdf),
          typeId: 2,
          emailClient: true,
        }).then(() =>
          getAttachmentsFx({
            locationId: appointment.location.id,
            appointmentId: appointment.id,
          }),
        ),
        await addCompletedAction(
          appointment.id,
          appointment.location.id,
          CompletedActionsJobEnum.FinalizeBilling,
        ),
      ]);

      showAlert("Success! Document has been uploaded.", { variant: "success" });

      navigate(routePaths.jobDetails(appointment.id));
    } catch {
      showAlert("Error! Failed to upload document.", { variant: "error" });
    }

    setIsSavingBillOfLading(false);
  }, [
    appointment,
    billOfLading,
    showAlert,
    navigate,
    addCompletedAction,
    savePdf,
  ]);

  useEffect(() => {
    if (!isBolGetting && billOfLading?.url && !pdfDoc) {
      getPdfDoc(billOfLading.url);
    }
  }, [isBolGetting, billOfLading?.url, pdfDoc, getPdfDoc]);

  useEffect(() => {
    const getBillOfLading = async (locId: number, appId: number) => {
      try {
        if (isBillingCompleted) {
          await getCurrentBillOfLadingFx({
            locationId: locId,
            appointmentId: appId,
          });
        } else {
          await generateBillOfLadingFx({
            locationId: locId,
            appointmentId: appId,
          });
        }
      } catch {
        showAlert("Error! Failed to get document. Try again later.", {
          variant: "error",
        });
      }

      setIsBolGetting(false);
    };

    if (
      appointment?.id &&
      typeof isBillingCompleted === "boolean" &&
      isBolGetting
    ) {
      getBillOfLading(appointment.location.id, appointment.id);
    }
  }, [
    appointment?.id,
    appointment?.location.id,
    isBillingCompleted,
    isBolGetting,
  ]);

  useEffect(() => {
    if (locationId && appointment?.id !== Number(appointmentId)) {
      getAppointmentFx({
        locationId: locationId,
        appointmentId: Number(appointmentId),
      });
    }
  }, [locationId, appointment?.id, appointmentId]);

  useEffect(() => {
    setPageName("Bill of lading");
    setShowGoBackButton();
    setGoToBackUrl(goToBackUrl);
    setGoToBackOptions({ state: goToBackState });
    setActionButton({
      name: "Notes",
      icon: <InfoOutlined color="inherit" fontSize="small" />,
      disabled: appointmentLoading,
      onClick: () => handleNotesDialogOpen(),
    });
    setElevation(16);

    return () => {
      setPageName("");
      setGoToBackUrl("");
      setGoToBackOptions(undefined);
      setShowGoBackButton();
      clearActionButton();
      setElevation(0);
    };
  }, [
    goToBackUrl,
    goToBackState,
    appointmentLoading,
    setPageName,
    setGoToBackUrl,
    setShowGoBackButton,
    setActionButton,
    clearActionButton,
    handleNotesDialogOpen,
    setElevation,
    setGoToBackOptions,
  ]);

  return (
    <div className={styles.root}>
      {!isMobile && (
        <BackTitle
          title="Bill of lading"
          actionButtons={[
            {
              name: "Notes",
              icon: <InfoOutlined color="inherit" fontSize="small" />,
              disabled: appointmentLoading,
              onClick: handleNotesDialogOpen,
            },
          ]}
          elevation={16}
          onBack={handleBack}
        />
      )}

      <div className={styles.pdfViewerContainer}>
        <div className={styles.pdfViewerWrapper}>
          <PdfViewer
            ref={pdfRef}
            width={pdfWidth}
            pagesCount={countPages || 2}
            loading={isPdfRendering}
            documentProps={{
              file: blobPdf,
            }}
            pageProps={{
              onRenderSuccess: () => {
                setIsPdfRendering(false);
              },
            }}
            pageContent={(pageNumber) => {
              const shouldRenderSignatureArea =
                !isBillingCompleted &&
                signatureMode !== "done" &&
                pageNumber === pageNumberWithSignature &&
                signatureFieldCoordinates.x !== 0 &&
                signatureFieldCoordinates.y !== 0;

              const signatureAreaElement = shouldRenderSignatureArea ? (
                <SignatureArea
                  classes={{ root: styles.signatureArea }}
                  style={{
                    left: `${signatureFieldCoordinates.x}px`,
                    top: `${signatureFieldCoordinates.y}px`,
                  }}
                  onClick={handleSignDialogOpen}
                />
              ) : (
                <></>
              );

              const signElements = signs.map((sign) =>
                sign.pageNumber === pageNumber ? renderSign(sign) : <></>,
              );

              return (
                <>
                  {signatureAreaElement}
                  {signElements}
                </>
              );
            }}
          />
        </div>
      </div>

      <ActionsFooter
        show={isShouldDisplayFooter}
        actions={[
          {
            label: "Sign",
            hide: signatureMode === "done",
            buttonType: "filled",
            disabled: !blobPdf,
            onClick: handleSignDialogOpen,
          },
          {
            label: "Save",
            hide: signatureMode !== "done",
            buttonType: "filled",
            isLoading: isSavingBillOfLading,
            onClick: saveBillOfLading,
          },
          {
            label: "Edit cart",
            hideCollapse: signatureMode !== "crew",
            buttonType: "text",
            color: "primary",
            onClick: handleEditCart,
          },
        ]}
      />
    </div>
  );
});
