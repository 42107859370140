import { colors } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 16,
    padding: 16,
  },
  content: {
    flex: 1,
  },
  title: {
    fontWeight: "bold",
  },
  date: {
    color: colors.grey180,
  },
  dismissDisabled: {
    opacity: 0.5,
  },
}));
