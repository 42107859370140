import { DataItem } from "shared/types";

export type DataChange = {
  field: string;
  fieldPrefix: string;
  oldValue?: string;
  newValue?: string;
};

export type Communication = {
  account: {
    id: number;
  };
  created: {
    by: string;
    date: string;
  };
  date: string;
  details: string;
  duration: number;
  endDatetime: string;
  id: number;
  location: DataItem;

  startDatetime: string;
  type: DataItem;
  status: DataItem;
  recordingUrl?: string;
  dataChanges?: string;
  updated: {
    name: string;
    date: string;
  };
};

export enum CommunicationTypesEnum {
  Letter = 1,
  Phone = 2,
  Email = 3,
  Fax = 4,
  TextMessage = 5,
  ConversationInPerson = 6,
  Other = 7,
  FollowUp = 8,
}

enum LetterStatusesEnum {
  ReceivedCorrespondence = 8,
  SendCorrespondence = 9,
}

enum PhoneStatusesEnum {
  ReceivedInbound = 1,
  SuccessfulOutbound = 2,
  LeftMessage = 3,
  Busy = 4,
  NoAnswer = 5,
  RecordedMessage = 6,
  AbortCall = 7,
}

enum EmailStatusesEnum {
  Received = 10,
  Sent = 11,
}

enum FaxStatusesEnum {
  Sent = 12,
  Received = 13,
}

enum TextMessageStatusesEnum {
  Sent = 14,
  Received = 15,
}

enum ConversationInPersonStatusesEnum {
  Successful = 16,
}

enum OtherStatusesEnum {
  Successful = 17,
}

enum FollowUpStatusesEnum {
  Successful = 17,
}

export const CommunicationStatuses = {
  LetterStatusesEnum,
  PhoneStatusesEnum,
  EmailStatusesEnum,
  FaxStatusesEnum,
  TextMessageStatusesEnum,
  ConversationInPersonStatusesEnum,
  OtherStatusesEnum,
  FollowUpStatusesEnum,
};
