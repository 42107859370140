import { memo } from "react";
import { Link } from "react-router-dom";
import { Divider, IconButton, Typography } from "@material-ui/core";
import { colors } from "@chhjpackages/components";

import { GuideCardProps } from "features/training-details";
import { ArrowRightSliderIcon } from "shared/assets";

import { useStyles } from "./assets";

export const GuideCard = memo(
  ({ title, description, onClickData, guideNumber }: GuideCardProps) => {
    const styles = useStyles();

    return (
      <Link
        style={{ textDecoration: "none" }}
        to={guideNumber < 2 ? onClickData : ""}
        onClick={guideNumber === 2 ? onClickData : null}
      >
        <div className={styles.root}>
          <div>
            <Typography
              align="left"
              variant="subtitle1"
              className={styles.title}
            >
              {title}
            </Typography>
            <Typography variant="body2" className={styles.description}>
              {description}
            </Typography>
          </div>
          <div className={styles.actionSectionRoot}>
            <div className={styles.dividerWrapper}>
              <Divider orientation="vertical" />
            </div>
            <div className={styles.iconButtonWrapper}>
              <IconButton>
                <ArrowRightSliderIcon fontSize={12} color={colors.grey180} />
              </IconButton>
            </div>
          </div>
        </div>
      </Link>
    );
  },
);
