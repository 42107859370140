export const sortObject = <T>(
  object: Record<string, T>,
  sortBy?: { key: string; index: number }[],
  defaultIndex?: number,
): Record<string, T> => {
  const sortedKeys = sortBy
    ? Object.keys(object).sort(
        (a, b) =>
          (sortBy.find((item) => item.key === a)?.index ?? defaultIndex ?? 0) -
          (sortBy.find((item) => item.key === b)?.index ?? defaultIndex ?? 0),
      )
    : Object.keys(object).sort();

  return sortedKeys.reduce(
    (acc, currValue) => ({ ...acc, [currValue]: object[currValue] }),
    {},
  );
};
