import { makeStyles } from "@material-ui/core";
import { colors } from "@chhjpackages/components";

interface Props {
  fullWidth: boolean | undefined;
}

export const useStyles = makeStyles((theme) => ({
  select: {
    width: ({ fullWidth }: Props) => (fullWidth ? "100%" : 100),
    minWidth: 100,
    backgroundColor: colors.grey10,

    color: colors.grey140,
    fontWeight: "bold",
    borderRadius: 60,

    "& .MuiSelect-root": {
      color: colors.grey140,
      borderRadius: 60,

      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
        fontSize: "18px",
        lineHeight: "25.2px",
      },

      "& .MuiSelect-icon": {
        color: colors.grey140,
      },
    },

    "& .MuiSelect-icon": {
      color: colors.grey140,
    },

    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },

    "& $success": {
      backgroundColor: colors.functionals.successLight,

      "& .MuiSelect-root, & .MuiSelect-root:focus": {
        color: colors.functionals.success,
        backgroundColor: colors.functionals.successLight,
      },

      "& .MuiSelect-icon": {
        color: colors.functionals.success,
      },

      "& .MuiOutlinedInput-root:hover": {
        backgroundColor: colors.functionals.successLight,
      },
    },

    "& $empty": {
      backgroundColor: colors.grey10,

      "& .MuiSelect-root, & .MuiSelect-root:focus": {
        color: colors.grey60,
        backgroundColor: colors.grey10,
      },

      "& .MuiSelect-icon": {
        color: colors.grey60,
      },

      "& .MuiOutlinedInput-root:hover": {
        backgroundColor: colors.grey10,
      },
    },

    "& $info": {
      backgroundColor: colors.functionals.infoLight,

      "& .MuiSelect-root, & .MuiSelect-root:focus": {
        color: colors.functionals.info,
        backgroundColor: colors.functionals.infoLight,
      },

      "& .MuiSelect-icon": {
        color: colors.functionals.info,
      },

      "& .MuiOutlinedInput-root:hover": {
        backgroundColor: colors.functionals.infoLight,
      },
    },

    "& $error": {
      backgroundColor: colors.functionals.alertLight,

      "& .MuiSelect-root, & .MuiSelect-root:focus": {
        color: colors.functionals.alert,
        backgroundColor: colors.functionals.alertLight,
      },

      "& .MuiSelect-icon": {
        color: colors.functionals.alert,
      },

      "& .MuiOutlinedInput-root:hover": {
        backgroundColor: colors.functionals.alertLight,
      },
    },

    "& $warning": {
      backgroundColor: colors.functionals.warningLight,

      "& .MuiSelect-root, & .MuiSelect-root:focus": {
        color: colors.functionals.warning,
        backgroundColor: colors.functionals.warningLight,
      },

      "& .MuiSelect-icon": {
        color: colors.functionals.warning,
      },

      "& .MuiOutlinedInput-root:hover": {
        backgroundColor: colors.functionals.warningLight,
      },
    },

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  error: {},
  info: {},
  success: {},
  warning: {},
  empty: {},
}));
