import { Theme, makeStyles } from "@material-ui/core";
import { colors } from "@chhjpackages/components";

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    minHeight: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 64,

    [theme.breakpoints.down("xs")]: {
      minHeight: "auto",
      padding: 24,
    },
  },

  content: {
    display: "flex",
    maxWidth: 768,
    width: "100%",

    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },

  leftSide: {
    flex: 2,
    backgroundColor: colors.grey10,
    padding: 24,
  },

  rightSide: {
    flex: 4,
    padding: 40,
    paddingRight: 24,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",

    [theme.breakpoints.down("xs")]: {
      padding: 24,
    },
  },

  partners: {
    display: "flex",
    alignItems: "center",
  },

  partnersPlus: {
    margin: 16,
    color: colors.grey80,
    fontWeight: "normal",
  },

  mainText: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",

    [theme.breakpoints.down("xs")]: {
      margin: "40px 0",
    },
  },

  info: {
    display: "flex",
    alignItems: "center",
    marginTop: 16,
  },

  infoIcon: {
    marginRight: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
