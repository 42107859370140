import { makeStyles } from "@material-ui/styles";

export const useEtaBannerStyles = makeStyles({
  row: {
    width: "100%",
    padding: 16,
    minHeight: 44,
  },

  textWithIconContainer: {
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
});
