import { ListItem, Typography, Grid } from "@material-ui/core";
import clsx from "clsx";
import { memo } from "react";

import { useStyles } from "./PricingItem.styles";
import { PricingItemProps } from "./PricingItem.types";

export const PricingItem = memo(({ pricingItem }: PricingItemProps) => {
  const styles = useStyles();

  return (
    <ListItem style={{ padding: "0 16px", height: "33px" }}>
      <Grid container justify="space-between" alignItems="center" spacing={1}>
        <Grid item style={{ flex: "1" }}>
          <Typography
            variant="subtitle1"
            className={clsx(styles.listTypography, styles.title)}
          >
            {pricingItem.name}
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={clsx(styles.listTypography, styles.price)}>
            ${pricingItem.price.toFixed(2)}
          </Typography>
        </Grid>
      </Grid>
    </ListItem>
  );
});
