import { combine, createStore } from "effector/compat";
import { createEvent } from "effector";

import { PricingItem } from "features/add-product";
import { $products } from "features/products";
import { $appointmentStore } from "features/appointment";
import { getMatchedPricingWithProducts } from "../utils";

import { $pricings, setFavoritePricingEv } from "./pricings";

export const $pricingItems = createStore<{
  pricingItems: PricingItem[];
}>({ pricingItems: [] });

$pricingItems.on(
  combine({
    pricingsAppointmentId: $pricings.map(
      (state) => state.pricingsAppointmentId,
    ),
    products: $products.map((state) => state.products),
  }),
  (store) => {
    const { pricings } = $pricings.getState();
    const { products } = $products.getState();
    const { appointment } = $appointmentStore.getState();

    const pricingItems = getMatchedPricingWithProducts(
      pricings,
      products,
      appointment?.location.taxCodes ?? [],
    );

    return {
      ...store,
      pricingItems,
    };
  },
);

export const addPricingItem = createEvent<PricingItem>();
$pricingItems.on(addPricingItem, (store, addindPricing) => {
  const pricingsWithSameId = store.pricingItems.filter(
    (pricing) => pricing.id === addindPricing.id,
  );

  const filteredPricingsWithSameId = pricingsWithSameId.filter(
    (pricing) => !pricing.isHidden,
  );

  const originalPricing = pricingsWithSameId.find(
    (pricing) => pricing.isOriginal,
  );

  const name =
    (filteredPricingsWithSameId.length > 0
      ? `${originalPricing?.name} ${filteredPricingsWithSameId.length + 1}`
      : originalPricing?.name) ?? "";
  const hideFavorite = filteredPricingsWithSameId.length > 0;

  return {
    ...store,
    pricingItems: [
      ...store.pricingItems,
      { ...addindPricing, name, hideFavorite },
    ],
  };
});

export const updatePricingItem = createEvent<PricingItem>();
$pricingItems.on(updatePricingItem, (store, updatedPricing) => {
  let newPricingItems = store.pricingItems.map((item) =>
    item.key === updatedPricing.key ? updatedPricing : item,
  );

  const pricingsWithSameId = newPricingItems.filter(
    (pricing) => pricing.id === updatedPricing.id,
  );

  const filteredPricingsWithSameId = pricingsWithSameId.filter(
    (pricing) => !pricing.isHidden,
  );

  const originalPricing = pricingsWithSameId.find(
    (pricing) => pricing.isOriginal,
  );

  if (pricingsWithSameId.length > 1) {
    filteredPricingsWithSameId.forEach((pricing, index) => {
      const pricingIndex = newPricingItems.findIndex(
        (pricingItgem) => pricingItgem.key === pricing.key,
      );

      if (pricingIndex >= 0) {
        newPricingItems[pricingIndex] = {
          ...newPricingItems[pricingIndex],
          name: `${originalPricing?.name}${index ? ` ${index + 1}` : ""}`,
          hideFavorite: index > 0,
        };
      }
    });
  } else {
    const pricingIndex = newPricingItems.findIndex(
      (pricing) => pricing.key === originalPricing?.key,
    );

    if (pricingIndex >= 0) {
      newPricingItems[pricingIndex] = {
        ...newPricingItems[pricingIndex],
        isHidden: false,
      };
    }
  }

  return {
    ...store,
    pricingItems: newPricingItems,
  };
});

export const deletePricingItem = createEvent<{ key: number }>();
$pricingItems.on(deletePricingItem, (store, { key }) => {
  const deletedPricing = store.pricingItems.find((item) => item.key === key);

  if (!deletedPricing) {
    return store;
  }

  let newPricingItems = store.pricingItems.filter(
    (item) => item.key !== deletedPricing.key,
  );

  const pricingsWithSameId = newPricingItems.filter(
    (pricing) => pricing.id === deletedPricing.id,
  );

  const filteredPricingsWithSameId = pricingsWithSameId.filter(
    (pricing) => !pricing.isHidden,
  );

  const originalPricing = pricingsWithSameId.find(
    (pricing) => pricing.isOriginal,
  );

  if (pricingsWithSameId.length > 1) {
    filteredPricingsWithSameId.forEach((pricing, index) => {
      const pricingIndex = newPricingItems.findIndex(
        (pricingItgem) => pricingItgem.key === pricing.key,
      );

      if (pricingIndex >= 0) {
        newPricingItems[pricingIndex] = {
          ...newPricingItems[pricingIndex],
          name: `${originalPricing?.name}${index ? ` ${index + 1}` : ""}`,
          hideFavorite: index > 0,
        };
      }
    });
  } else {
    const pricingIndex = newPricingItems.findIndex(
      (pricing) => pricing.key === originalPricing?.key,
    );

    if (pricingIndex >= 0) {
      newPricingItems[pricingIndex] = {
        ...newPricingItems[pricingIndex],
        isHidden: false,
      };
    }
  }

  return {
    ...store,
    pricingItems: newPricingItems,
  };
});

export const triggerRecalculatePricingItems = createEvent();
$pricingItems.on(triggerRecalculatePricingItems, (store) => {
  const { pricings } = $pricings.getState();
  const { products } = $products.getState();
  const { appointment } = $appointmentStore.getState();

  const pricingItems = getMatchedPricingWithProducts(
    pricings,
    products,
    appointment?.location.taxCodes ?? [],
  );

  return {
    ...store,
    pricingItems,
  };
});

$pricingItems.on(setFavoritePricingEv, (store, { pricingId }) => ({
  ...store,
  pricingItems: store.pricingItems.map((pricing) =>
    pricing.id === pricingId
      ? { ...pricing, isFavorite: !pricing.isFavorite }
      : pricing,
  ),
}));
