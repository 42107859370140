import { Typography } from "@material-ui/core";
import { Button } from "@chhjpackages/components";
import { memo, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import moment from "moment";
import { Skeleton } from "@material-ui/lab";

import {
  ishasLowerCase,
  ishasNumbers,
  ishasSymbols,
  ishasUpperCase,
} from "shared/utils";
import { FormInputWithEye } from "features/auth/ui";

import { RestorePasswordFormProps, useRestorePasswordForm } from "./model";
import { useStyles } from "./assets";

export const RestorePasswordForm = memo(
  ({ tokenTime, isTokenLoading, onSubmit }: RestorePasswordFormProps) => {
    const styles = useStyles();

    const { control, password, formState, handleSubmit } =
      useRestorePasswordForm();

    const [timer, setTimer] = useState<number>(0);

    const time = useMemo(
      () => moment.utc(timer * 1000).format("mm:ss"),
      [timer],
    );

    const isPasswordPassedAllValidations = useMemo(
      () =>
        [
          ishasUpperCase(password),
          ishasLowerCase(password),
          ishasNumbers(password),
          ishasSymbols(password),
        ].filter((el) => el).length >= 3,
      [password],
    );

    const isPasswordValid = useMemo(
      () => isPasswordPassedAllValidations && password.length >= 8,
      [isPasswordPassedAllValidations, password],
    );

    const sibmitDisabled = useMemo(
      () =>
        !formState.isDirty || !formState.isValid || !isPasswordValid || !timer,
      [formState.isDirty, formState.isValid, isPasswordValid, timer],
    );

    useEffect(() => {
      const intervalId = setInterval(
        () => timer !== 0 && setTimer((oldTime) => oldTime - 1),
        1000,
      );

      return () => clearInterval(intervalId);
    }, [timer]);

    useEffect(() => {
      setTimer(tokenTime);
    }, [tokenTime]);

    return (
      <form className={styles.root} onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h3" className={styles.title}>
          Set a new password
        </Typography>

        <Typography variant="h5">
          Time left:{" "}
          {isTokenLoading ? (
            <Skeleton
              variant="text"
              width={34}
              className={styles.timeSkeleton}
            />
          ) : (
            <span className={styles.time}>{time}</span>
          )}
        </Typography>

        <FormInputWithEye
          name="password"
          label="New password"
          type="password"
          control={control}
          fullWidth
        />

        <FormInputWithEye
          name="confirmedPassword"
          label="Confirm new password"
          type="password"
          control={control}
        />

        <Typography variant="h4" className={styles.sectionTitle}>
          Password MUST:
        </Typography>

        <ul className={styles.ul}>
          <li
            className={clsx(
              styles.listItem,
              password.length >= 8 && styles.valid,
            )}
          >
            Be 8 characters minimum
          </li>
          <li
            className={clsx(
              styles.listItem,
              isPasswordPassedAllValidations && styles.valid,
            )}
          >
            At least 3 of these:
            <ul className={styles.list}>
              <li
                className={clsx(
                  styles.listItem,
                  ishasUpperCase(password) && styles.crossedValid,
                )}
              >
                Upper-Case letter
              </li>
              <li
                className={clsx(
                  styles.listItem,
                  ishasLowerCase(password) && styles.crossedValid,
                )}
              >
                Lower-Case Letters
              </li>
              <li
                className={clsx(
                  styles.listItem,
                  ishasNumbers(password) && styles.crossedValid,
                )}
              >
                Numbers
              </li>
              <li
                className={clsx(
                  styles.listItem,
                  ishasSymbols(password) && styles.crossedValid,
                )}
              >
                Symbols
              </li>
            </ul>
          </li>
        </ul>

        <Typography variant="h4" className={styles.sectionTitle}>
          Do not use:
        </Typography>

        <ul className={styles.ul}>
          <li className={styles.listItem}>Your username, first or last name</li>
          <li className={styles.listItem}>Chhj keywords (college, junk etc)</li>
          <li className={styles.listItem}>Previous password</li>
        </ul>

        <Button
          fullWidth
          buttonType="twoTone"
          type="submit"
          size="large"
          disabled={sibmitDisabled}
          isLoading={formState.isSubmitting}
        >
          Confirm
        </Button>
      </form>
    );
  },
);
