import { useForm } from "react-hook-form";
import { useCallback } from "react";
import { yupResolver } from "@hookform/resolvers/yup";

import { DescriptionOfChangesFormValues } from "../types";
import { DescriptionOfChangesForm } from "../../ui";
import { descriptionOfChangesFormValidation } from "../utils";

export const useDescriptionOfChangesForm = () => {
  const form = useForm<DescriptionOfChangesFormValues>({
    mode: "all",
    defaultValues: {
      changeOptions: [],
    },
    resolver: yupResolver(descriptionOfChangesFormValidation),
  });

  const render = useCallback(
    () => <DescriptionOfChangesForm form={{ ...form }} />,
    [form],
  );

  return { form, render };
};
