import { colors } from "@chhjpackages/components";
import { SvgIcon } from "@material-ui/core";

export const UserIcon = ({
  className,
  color = colors.grey80,
  fontSize = 24,
}: {
  className?: string;
  color?: string;
  fontSize?: number;
}) => (
  <SvgIcon
    className={className}
    style={{ fontSize }}
    width="12"
    height="22"
    viewBox="0 0 12 22"
    fill="none"
  >
    <path
      d="M5.99779 6.35714C7.51564 6.35714 8.67636 5.19643 8.67636 3.67857C8.67636 2.16071 7.51564 1 5.99779 1C4.47993 1 3.31921 2.16071 3.31921 3.67857C3.31921 5.19643 4.47993 6.35714 5.99779 6.35714Z"
      stroke={color}
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.6407 12.3394C10.6407 9.7501 8.49784 7.69653 5.99784 7.69653C3.40855 7.69653 1.35498 9.83939 1.35498 12.3394V14.3037H3.31927L4.03355 21.0001H8.05141L8.67641 14.393H10.6407V12.3394Z"
      stroke={color}
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
