import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "effector-react/compat";
import { memo, useCallback, useEffect, useMemo } from "react";
import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import { colors, useAlert } from "@chhjpackages/components";

import {
  editDevelopmentFx,
  EditDevelopmentsHoursForm,
  EditHoursFormValues,
} from "features/developments";
import { $developments, getDevelopmentsFx } from "features/developments";
import { $auth } from "features/auth";
import { BackTitle } from "shared/ui";
import { useSideNavDispatch } from "features/sidenav";
import { $appointmentStore, getAppointmentFx } from "features/appointment";
import { apiDateTimeFormat, routePaths } from "shared/utils";
import { useLocationTimezone } from "shared/hooks";

export const EditTravelDevelopments = memo(() => {
  const { showAlert } = useAlert();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: true,
  });

  const { appointmentId } = useParams();
  const navigate = useNavigate();
  const { setPageName, setShowGoBackButton, setGoToBackUrl, setElevation } =
    useSideNavDispatch();
  const { utcToZone, zoneToUTC } = useLocationTimezone("location");

  const { locationId } = useStore($auth);
  const { appointment } = useStore($appointmentStore);
  const {
    travelDevelopmentPairs,
    developmentsAppointmentId,
    loading: developmentsLoading,
  } = useStore($developments);

  const travelDevelopmentPairsLocal = useMemo(
    () =>
      travelDevelopmentPairs.map((travelDevelopmentPair) => ({
        ...travelDevelopmentPair,
        start: {
          ...travelDevelopmentPair.start,
          datetime: utcToZone(
            travelDevelopmentPair.start.datetime,
            apiDateTimeFormat,
          ),
        },
        end: travelDevelopmentPair.end
          ? {
              ...travelDevelopmentPair.end,
              datetime: utcToZone(
                travelDevelopmentPair.end.datetime,
                apiDateTimeFormat,
              ),
            }
          : undefined,
      })),
    [travelDevelopmentPairs, utcToZone],
  );

  const initialValues = useMemo(
    () => ({ developmentPairs: travelDevelopmentPairsLocal }),
    [travelDevelopmentPairsLocal],
  );

  const goToBackUrl = useMemo(
    () => routePaths.jobDetails(Number(appointmentId)),
    [appointmentId],
  );

  const onSubmit = useCallback(
    async (data: EditHoursFormValues) => {
      if (!appointment) {
        return;
      }

      try {
        await Promise.all(
          data.developmentPairs.map(async (developmentPair, index) => {
            if (
              developmentPair.start.datetime !==
              travelDevelopmentPairsLocal[index].start.datetime
            ) {
              await editDevelopmentFx({
                locationId: appointment.location.id,
                appointmentId: appointment.id,
                developmentId: developmentPair.start.id,
                payload: {
                  datetime: zoneToUTC(developmentPair.start.datetime),
                },
              });
            }

            if (
              developmentPair.end &&
              travelDevelopmentPairsLocal[index].end &&
              developmentPair.end.datetime !==
                travelDevelopmentPairsLocal[index].end?.datetime
            ) {
              await editDevelopmentFx({
                locationId: appointment.location.id,
                appointmentId: appointment.id,
                developmentId: developmentPair.end.id,
                payload: {
                  datetime: zoneToUTC(developmentPair.end.datetime),
                },
              });
            }
          }),
        );

        showAlert("Success! Travel hours has been updated.", {
          variant: "success",
        });
      } catch (error) {
        showAlert("Error! Failed to update travel hours", {
          variant: "error",
        });
      }
    },
    [appointment, travelDevelopmentPairsLocal, zoneToUTC, showAlert],
  );

  const handleGoBack = useCallback(
    () => navigate(goToBackUrl),
    [goToBackUrl, navigate],
  );

  useEffect(() => {
    if (Number(appointmentId) !== appointment?.id && locationId) {
      getAppointmentFx({
        locationId: locationId,
        appointmentId: Number(appointmentId),
      });
    }
  }, [appointmentId, appointment?.id, locationId]);

  useEffect(() => {
    if (developmentsAppointmentId !== Number(appointmentId) && locationId) {
      getDevelopmentsFx({
        locationId: locationId,
        appointmentId: Number(appointmentId),
      });
    }
  }, [developmentsAppointmentId, appointmentId, locationId]);

  useEffect(() => {
    setPageName("Edit travel hours");
    setShowGoBackButton();
    setGoToBackUrl(goToBackUrl);
    setElevation(16);

    return () => {
      setPageName("");
      setShowGoBackButton();
      setGoToBackUrl("");
      setElevation(0);
    };
  }, [
    goToBackUrl,
    setPageName,
    setShowGoBackButton,
    setGoToBackUrl,
    setElevation,
  ]);

  return (
    <Box flex={1} display="flex" flexDirection="column">
      {!isMobile && (
        <BackTitle
          title="Edit travel hours"
          elevation={16}
          onBack={handleGoBack}
        />
      )}

      <Box
        p={3.75}
        pb={10}
        bgcolor={colors.white}
        display="flex"
        flexDirection="column"
        flex={1}
      >
        <EditDevelopmentsHoursForm
          initialValues={initialValues}
          type="travel"
          loading={developmentsLoading}
          onSubmit={onSubmit}
        />
      </Box>
    </Box>
  );
});
