import { FC, memo, useCallback, useMemo } from "react";
import { useController, useWatch } from "react-hook-form";
import { FormControl, FormControlLabel, FormGroup } from "@material-ui/core";
import { Checkbox, UserCheckbox } from "@chhjpackages/components";

import { HelperTxt, useFormCheckboxGroupStyles } from "./styles";
import { FormCheckboxGroupProps } from "./types";

export const FormCheckboxGroup: FC<FormCheckboxGroupProps> = memo(
  ({
    row = false,
    groups,
    control,
    name,
    size = "medium",
    isUserPill = false,
    variant = "outline",
    required,
    ...rest
  }) => {
    const styles = useFormCheckboxGroupStyles();

    const {
      field: { ref, value, onChange, ...inputProps },
      fieldState: { error },
    } = useController({
      name,
      control,
      defaultValue: [],
    });

    const checkboxIds = useWatch({ control, name: name, defaultValue: [] });

    const helperText = useMemo(() => {
      if (error) {
        return error.message?.trim();
      }

      if (required) {
        return "*required";
      }

      return "";
    }, [error, required]);

    const handleChange = useCallback(
      (handleValue: number) => {
        const newArray = [...checkboxIds];
        const item = handleValue;

        //Ensure array isn"t empty
        if (newArray.length > 0) {
          //Attempt to find an item in array with matching id
          const index = newArray.findIndex((x) => x === item);

          // If theres no match add item to the array
          if (index === -1) {
            newArray.push(item);
          } else {
            //If there is a match and the handleValue is empty, remove the item from the array
            newArray.splice(index, 1);
          }
        } else {
          //If the array is empty, add the item to the array
          newArray.push(item);
        }

        //Overwrite existing array with newArray}
        onChange(newArray);
      },
      [checkboxIds, onChange],
    );

    return (
      <FormControl error={!!error} fullWidth>
        <FormGroup row={row} className={styles.group}>
          {groups?.map((checkbox) => (
            <FormControlLabel
              key={checkbox.id}
              name={name}
              label={null}
              className={styles.controlLabel}
              control={
                isUserPill ? (
                  <UserCheckbox
                    {...rest}
                    {...inputProps}
                    userName={checkbox.name}
                    checked={value?.some(
                      (checked: number) => checked === checkbox.id,
                    )}
                    inputRef={ref}
                    onChange={() => handleChange(checkbox.id)}
                    size={size}
                    required={required}
                  />
                ) : (
                  <Checkbox
                    inputRef={ref}
                    checked={value?.some(
                      (checked: number) => checked === checkbox.id,
                    )}
                    size={size}
                    variant={variant}
                    label={checkbox.name}
                    required={required}
                    onChange={() => handleChange(checkbox.id)}
                    className="checkbox"
                    {...rest}
                    {...inputProps}
                  />
                )
              }
            />
          ))}
        </FormGroup>

        {helperText && <HelperTxt>{helperText}</HelperTxt>}
      </FormControl>
    );
  },
);
