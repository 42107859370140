import { useStore } from "effector-react/compat";
import { memo, useMemo } from "react";
import { Box, Typography } from "@material-ui/core";

import { $appointmentStore } from "features/appointment";
import { $coupons } from "features/coupons";
import { useCouponsPageContext } from "features/coupons-and-discounts";

import { CouponsList } from "./ui";

export const Coupons = memo(() => {
  const { selectedCouponId, onChangeSelectedCouponId } =
    useCouponsPageContext();

  const { coupons, loading: couponsLoading } = useStore($coupons);
  const { appointment, loading: appointmentLoading } =
    useStore($appointmentStore);

  const disabledCoupons = useMemo(
    () => (appointment ? appointment.discount.amount > 0 : false),
    [appointment],
  );

  return (
    <Box px={2} py={3}>
      {disabledCoupons && (
        <Box mb={2}>
          <Typography variant="h5">
            You can only add one discount or coupon.
          </Typography>
        </Box>
      )}
      <CouponsList
        coupons={coupons}
        selectedCouponId={selectedCouponId}
        loading={couponsLoading || appointmentLoading}
        disabled={disabledCoupons}
        onToggleSelect={onChangeSelectedCouponId}
      />
    </Box>
  );
});
