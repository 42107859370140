import { createStore, createEvent } from "effector/compat";

type DashboardStore = {
  isGridView: boolean;
};

export const $dashboardStore = createStore<DashboardStore>({
  isGridView: false,
});

export const setIsGridView = createEvent<boolean>();

$dashboardStore.on(setIsGridView, (store, isGridView) => ({
  isGridView,
}));
