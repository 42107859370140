import { colors } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 8,
    textDecoration: "none",
    border: `1px solid ${colors.grey20}`,
    fontWeight: 700,
    padding: "8px 12px",
  },
  content: {
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
  checkIconWrapper: {
    width: 12,
    display: "flex",
    alignItems: "center",
  },
  title: {
    color: colors.basic.black,
  },
}));
