import { Typography } from "@material-ui/core";
import { memo } from "react";

import { TotalTime } from "features/appointment";

import { GeneralTimesProps } from "./model";
import { useStyles } from "./assets";

export const GeneralTimes = memo(
  ({
    timeTravelled,
    billableTimeTravelled,
    timeWorked,
    billableTimeWorked,
  }: GeneralTimesProps) => {
    const styles = useStyles();

    return (
      <div className={styles.root}>
        <Typography variant="h4" color="inherit">
          Total times
        </Typography>

        <TotalTime
          timeTravelled={timeTravelled}
          billableTimeTravelled={billableTimeTravelled}
          timeWorked={timeWorked}
          billableTimeWorked={billableTimeWorked}
        />
      </div>
    );
  },
);
