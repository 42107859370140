import { createEffect, createStore } from "effector/compat";

import { LiabilityWaiver } from "../types";
import {
  generateLiablityWaiver,
  getCurrentLiablityWaiver,
  updateLiablityWaiver,
} from "../api";

export const $liabilityWaiver = createStore<{
  liabilityWaiver: LiabilityWaiver | null;
  loading: boolean;
}>({ liabilityWaiver: null, loading: true });

export const getCurrentLiablityWaiverFx = createEffect(
  getCurrentLiablityWaiver,
);
$liabilityWaiver
  .on(getCurrentLiablityWaiverFx.doneData, (store, response) => ({
    ...store,
    liabilityWaiver: response.data.liabilityWaivers[0],
  }))
  .on(getCurrentLiablityWaiverFx.fail, (store) => ({
    ...store,
    liabilityWaiver: null,
  }))
  .on(getCurrentLiablityWaiverFx.pending, (store, loading) => ({
    ...store,
    loading,
  }));

export const generateLiablityWaiverFx = createEffect(generateLiablityWaiver);
$liabilityWaiver
  .on(generateLiablityWaiverFx.doneData, (store, response) => ({
    ...store,
    liabilityWaiver: response.data.liabilityWaivers[0],
  }))
  .on(generateLiablityWaiverFx.fail, (store) => ({
    ...store,
    liabilityWaiver: null,
  }))
  .on(generateLiablityWaiverFx.pending, (store, loading) => ({
    ...store,
    loading,
  }));

export const updateLiablityWaiverFx = createEffect(updateLiablityWaiver);
$liabilityWaiver.on(updateLiablityWaiverFx.doneData, (store, response) => ({
  ...store,
  liabilityWaiver: response.data.liabilityWaivers[0],
}));
