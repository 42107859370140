import { Avatar, DataItem } from "shared/types";

export enum AppExperienceTypesEnum {
  TeamBased = 1,
  LocationBased = 2,
  Sales = 3,
  SlcAgent = 4,
  CorporateEmployee = 5,
}

export type User = {
  id: number;
  appExperienceType: DataItem;
  approved: number;
  password: string;
  firstName: string;
  lastName: string;
  userName: string;
  email: string;
  title: string;
  sms: string;
  bio: string;
  defaultDashboard: DataItem;
  location: DataItem & {
    shortName: string;
    timezone: {
      id: number;
      name: string;
      offset: number;
      shortName: string;
    };
  };
  group: DataItem;
  image: Avatar;
  updated: {
    by: string;
    datetime: string;
  };
  emailCount: number;
  smsCount: number;
  passwordExpiresDatetime?: string;
  team: DataItem;
  isSlcAgent: boolean;
  useNewJobPage: boolean;
  useNewMoveEstimatingTool: boolean;
};
