import moment from "moment";

import { FilterItem } from "widgets/filters-bar/model";
import { Appointments } from "features/appointment/model";
import { DataItem, DataList } from "shared/types";

export type AppointmentsFilterParams = {
  appointmentTypes: DataList;
  appointmentStatuses: DataList;
  appointmentTimes: DataList;
  zones: DataList;
  categories: DataList;
  assignments: DataList;
};

export const appointmentFilters = ({
  appointmentTypes,
  appointmentStatuses,
  appointmentTimes,
  zones,
  categories,
  assignments,
}: AppointmentsFilterParams): FilterItem<
  Omit<Appointments, "type"> & { type: DataItem | string }
>[] => [
  {
    id: 2,
    name: "Type of appointment",
    type: "select",
    multiple: true,
    options: appointmentTypes,
    filterByField: "type",
    filterByType: "object",
    initialValue: [-1],
    filterByComplexField: (data) => (data as DataItem).id,
  },
  {
    id: 3,
    name: "Start of appointment",
    type: "select",
    multiple: true,
    options: appointmentTimes,
    filterByType: "array",
    filterByField: "startDate",
    timePeriod: true,
    initialValue: [-1],
    filterByComplexField: (data) =>
      moment(data as Appointments["startDate"]).format("hh:mm A"),
  },
  {
    id: 4,
    name: "Appointment status",
    type: "select",
    options: appointmentStatuses,
    filterByField: "status",
    filterByType: "object",
    multiple: true,
    initialValue: [-1],
    filterByComplexField: (data) => (data as Appointments["status"]).id,
  },
  {
    id: 5,
    name: "Zone",
    type: "select",
    filterByField: "zone",
    filterByType: "object",
    multiple: true,
    options: zones,
    initialValue: [-1],
    filterByComplexField: (data) => (data as Appointments["zone"]).id,
  },
  {
    id: 6,
    name: "Appointment category",
    type: "select",
    filterByField: "category",
    filterByType: "object",
    multiple: true,
    options: categories,
    initialValue: [-1],
    filterByComplexField: (data) => (data as Appointments["category"]).id,
  },
  {
    id: 7,
    name: "Assignments",
    type: "select",
    options: assignments,
    filterByField: "numHunks",
    filterByType: "object",
    initialValue: 1,
    resetValue: -1,
    filterByComplexField: (data) => data as Appointments["numHunks"],
  },
];
