import { colors, SvgIconProps } from "@chhjpackages/components";
import { SvgIcon } from "@material-ui/core";

export const TrashIcon = ({
  className,
  color = colors.primary.main,
  fontSize = 24,
}: SvgIconProps) => (
  <SvgIcon
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    style={{ fill: "none", fontSize: fontSize }}
    fill="currentColor"
  >
    <path
      d="M2 4.85718H22"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.0947 2H9.80894C9.04704 2 8.38037 2.66667 8.38037 3.42857V4.85714H15.5232V3.33333C15.5232 2.57143 14.8566 2 14.0947 2Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.80859 16.9527V9.80981"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.0947 16.9527V9.80981"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.4757 20.6667C18.3804 21.4286 17.809 22 17.0471 22H6.85663C6.09473 22 5.5233 21.4286 5.42806 20.6667L4.09473 4.85718H19.809L18.4757 20.6667Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
