import { useStore } from "effector-react/compat";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { Button, colors, useAlert } from "@chhjpackages/components";
import moment from "moment";
import { InfoOutlined } from "@material-ui/icons";

import {
  $quotes,
  CoupounsDiscountsEnum,
  calculateQuoteFx,
  getCalculateQuotePayload,
  getQuoteFx,
  setCalculatedQuoteEv,
  setQuoteEv,
} from "features/quotes";
import {
  $appointmentStore,
  getAppointmentFx,
  updateAppointmentFx,
  useNotesDialog,
} from "features/appointment";
import { $auth } from "features/auth";
import { useSideNavDispatch } from "features/sidenav";
import { ActionsFooter, BackTitle, ImportantBadge } from "shared/ui";
import { apiDateFormat, routePaths } from "shared/utils";
import { $pricings, getPricingsFx } from "features/pricings";
import {
  BillOfLadingView,
  BillOfLadingFormValues,
  useBillOfLadingView,
} from "features/quotes";
import { $products, addProductFx } from "features/products";
import {
  $completedActions,
  getCompletedAction,
  useCompletedActions,
} from "features/completed-actions";
import { CompletedActionsJobEnum, DiscountTypesEnum } from "shared/types";

export const BillOfLadingEstimate = memo(() => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), { noSsr: true });

  const location = useLocation();
  const navigate = useNavigate();

  const { showAlert } = useAlert();
  const { addCompletedAction } = useCompletedActions();
  const {
    setPageName,
    setShowGoBackButton,
    setGoToBackUrl,
    setActionButton,
    clearActionButton,
    setElevation,
  } = useSideNavDispatch();
  const { handleNotesDialogOpen } = useNotesDialog({});

  const { appointment, loading: appointmentLoading } =
    useStore($appointmentStore);
  const { quote, calculatedQuote } = useStore($quotes);
  const { locationId } = useStore($auth);
  const { appointmentId } = useParams();
  const {
    pricings,
    pricingsAppointmentId,
    loading: pricingsLoading,
  } = useStore($pricings);
  const { products } = useStore($products);
  const { completedActions } = useStore($completedActions);

  const [quoteLoading, setQuoteLoading] = useState(true);
  const [isNoFoundProducts, setIsNoFoundProducts] = useState(false);

  const loading = useMemo(
    () => appointmentLoading || quoteLoading || pricingsLoading,
    [appointmentLoading, quoteLoading, pricingsLoading],
  );

  const isBillingCompleted = useMemo(() => {
    if (!appointment) {
      return null;
    }

    return !!getCompletedAction(
      completedActions,
      CompletedActionsJobEnum.FinalizeBilling,
    );
  }, [appointment, completedActions]);

  const {
    control,
    formState,
    junkRemovalOptions,
    discountsCouponsOptions,
    watch,
    setValue,
    handleSubmit,
  } = useBillOfLadingView({
    quote,
    calculatedQuote,
    appointment,
    pricings,
  });

  const goToBackUrl = useMemo(
    () => routePaths.jobDetails(Number(appointmentId)),
    [appointmentId],
  );

  const handleGoBack = useCallback(() => {
    navigate(goToBackUrl);
  }, [goToBackUrl, navigate]);

  const handleUseTheseValues = useCallback(
    async (data: BillOfLadingFormValues) => {
      if (!appointment || !locationId) {
        return;
      }

      const junkRemoval = quote?.pricing.junkRemoval ?? 0;
      const junkRemovalProduct =
        junkRemoval > 0 && data.junkRemoval
          ? pricings.find((pricing) => pricing.id === data.junkRemoval?.value)
          : undefined;

      const additionalProducts = [
        ...data.additionalProducts,
        ...(junkRemovalProduct
          ? [
              {
                id: junkRemovalProduct.id,
                quantity: 1,
                price: junkRemoval,
                name: junkRemovalProduct.name,
              },
            ]
          : []),
      ];
      const filteredAdditionalProducts = additionalProducts.filter(
        (additionalProduct) => additionalProduct.quantity > 0,
      );

      let isAllRequestsDone = true;

      await Promise.all([
        ...filteredAdditionalProducts.map(async (additionalProduct) => {
          const pricing = pricings.find((p) => p.id === additionalProduct.id);

          if (pricing) {
            try {
              const salesTaxId =
                appointment.location.taxCodes.length === 0 ? 0 : pricing.tax.id;

              return await addProductFx({
                locationId: appointment.location.id,
                appointmentId: appointment.id,
                payload: {
                  actualPrice: additionalProduct.price,
                  retailPrice: pricing.price,
                  qty: additionalProduct.quantity,
                  salesTaxId: salesTaxId ?? 0,
                  appointment: {
                    id: appointment.id,
                  },
                  product: {
                    id: pricing.id,
                  },
                  notes: "",
                },
              });
            } catch {
              isAllRequestsDone = false;
              showAlert("Error! Failed to add products from quote.", {
                variant: "error",
              });
            }
          }
        }),
        ...(filteredAdditionalProducts.length > 0
          ? [
              await addCompletedAction(
                appointment.id,
                appointment.location.id,
                CompletedActionsJobEnum.AddProducts,
              ),
            ]
          : []),
      ]);

      let appointmentPayload = {};
      if (discountsCouponsOptions.length > 0 && data.couponDiscount) {
        if (data.couponDiscount.value === CoupounsDiscountsEnum.DiscountQuote) {
          appointmentPayload = {
            discount: {
              type: {
                id: data.couponDiscount.discountType,
              },
              amount: data.couponDiscount.amount,
            },
            coupon: {
              id: 0,
            },
          };
        } else if (
          data.couponDiscount.value === CoupounsDiscountsEnum.DiscountJob &&
          appointment.coupon.id
        ) {
          appointmentPayload = {
            coupon: {
              id: 0,
            },
          };
        } else if (
          data.couponDiscount.value === CoupounsDiscountsEnum.Coupon &&
          appointment.discount.amount
        ) {
          appointmentPayload = {
            discount: {
              type: {
                id: DiscountTypesEnum.Dollar,
              },
              amount: 0,
            },
          };
        }
      }

      if (
        filteredAdditionalProducts.length > 0 ||
        Object.keys(appointmentPayload).length > 0
      ) {
        await updateAppointmentFx({
          locationId: appointment.location.id,
          appointmentId: appointment.id,
          payload: appointmentPayload,
        });
      }

      if (isAllRequestsDone) {
        const isNoLaborServiceProduct =
          data.numOfHunksMoving > 0 &&
          data.numOfHoursMoving > 0 &&
          Number(data.hourlyRateMoving) > 0 &&
          !!!filteredAdditionalProducts.find(
            (product) => product.name === "Labor Services",
          );
        const isNoPackingServiceProduct =
          data.numOfHunksPacking > 0 &&
          data.numOfHoursPacking > 0 &&
          Number(data.hourlyRatePacking) > 0 &&
          !!!filteredAdditionalProducts.find(
            (product) => product.name === "Packing Services",
          );
        const isNoTruckAndTravelFeeProduct =
          data.numOfHunksMoving > 0 &&
          Number(data.truckAndTravelFee) > 0 &&
          !!!filteredAdditionalProducts.find(
            (product) => product.name === "Truck & Travel Fee",
          );

        if (
          isNoLaborServiceProduct ||
          isNoPackingServiceProduct ||
          isNoTruckAndTravelFeeProduct
        ) {
          setIsNoFoundProducts(true);
          return;
        }

        navigate(routePaths.jobDetailsbillOfLadingSignature(appointment.id));
      }
    },
    [
      appointment,
      quote,
      pricings,
      discountsCouponsOptions.length,
      locationId,
      showAlert,
      navigate,
      addCompletedAction,
    ],
  );

  const handleEditManually = useCallback(() => {
    navigate(routePaths.jobDetailsCart(Number(appointmentId)), {
      state: {
        cartBack: location.pathname,
        isBillOfLading: true,
      },
    });
  }, [appointmentId, location.pathname, navigate]);

  useEffect(() => {
    if (!appointment) {
      return;
    }

    const getQuote = async () => {
      try {
        await getQuoteFx({
          locationId: appointment.quotes[0].location.id,
          quoteId: appointment.quotes[0].id,
        }).then(async (response) => {
          const quoteResponse = response.data.quotes[0];

          await calculateQuoteFx({
            locationId: appointment.quotes[0].location.id,
            payload: getCalculateQuotePayload(quoteResponse),
          });
        });
      } catch {
        showAlert("Error! Failed to retrieve data.", {
          variant: "error",
        });
      }

      setQuoteLoading(false);
    };

    if (appointment.quotes.length > 0) {
      getQuote();
    } else {
      setQuoteLoading(false);
    }
  }, [appointment?.id]);

  useEffect(() => {
    if (appointment && pricingsAppointmentId !== appointment.id) {
      getPricingsFx({
        locationId: appointment.location.id,
        appointmentId: appointment.id,
        postal: appointment.origin.postal,
        categoryId: appointment.category.id,
        jobDate: moment(appointment.startDate).format(apiDateFormat),
      });
    }
  }, [appointment, pricingsAppointmentId]);

  useEffect(() => {
    if (locationId && appointment?.id !== Number(appointmentId)) {
      getAppointmentFx({
        locationId: locationId,
        appointmentId: Number(appointmentId),
      });
    }
  }, [locationId, appointment?.id, appointmentId]);

  useEffect(() => {
    setPageName("Bill of lading");
    setShowGoBackButton();
    setGoToBackUrl(goToBackUrl);
    setActionButton({
      name: "Notes",
      icon: <InfoOutlined color="inherit" fontSize="small" />,
      disabled: appointmentLoading,
      onClick: () => handleNotesDialogOpen(),
    });
    setElevation(16);

    return () => {
      setQuoteEv(null);
      setCalculatedQuoteEv(null);
      setPageName("");
      setGoToBackUrl("");
      setShowGoBackButton();
      clearActionButton();
      setElevation(0);
    };
  }, [
    goToBackUrl,
    appointmentLoading,
    setPageName,
    setShowGoBackButton,
    setGoToBackUrl,
    setActionButton,
    clearActionButton,
    handleNotesDialogOpen,
    setElevation,
  ]);

  const firstRender = useRef(true);
  useEffect(() => {
    if (!appointment) {
      return;
    }

    if (!isBillingCompleted && products.length > 0 && firstRender.current) {
      navigate(routePaths.jobDetailsCart(Number(appointmentId)), {
        state: {
          cartBack: routePaths.jobDetails(Number(appointmentId)),
          isBillOfLading: true,
        },
      });
    }

    firstRender.current = false;
  }, [isBillingCompleted, products.length]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        backgroundColor: colors.white,
      }}
    >
      {!isMobile && (
        <BackTitle
          title="Bill of lading"
          elevation={16}
          actionButtons={[
            {
              name: "Notes",
              icon: <InfoOutlined color="inherit" fontSize="small" />,
              disabled: appointmentLoading,
              onClick: handleNotesDialogOpen,
            },
          ]}
          onBack={handleGoBack}
        />
      )}
      <div style={{ flex: 1, padding: 16 }}>
        {isNoFoundProducts ? (
          <>
            <ImportantBadge
              bgcolor={colors.functionals.alert}
              color={colors.functionals.alertLight}
              border="none"
              py={2}
              px={3}
            >
              <Typography variant="body1" color="inherit">
                <b>
                  The products added to the quote are no longer active at your
                  location. You must add products manually to continue.
                </b>
              </Typography>
            </ImportantBadge>
            <div
              style={{
                marginTop: 16,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div style={{ width: 290 }}>
                <Button
                  buttonType="twoTone"
                  fullWidth
                  onClick={handleEditManually}
                >
                  Continue to add products
                </Button>
              </div>
            </div>
          </>
        ) : (
          <BillOfLadingView
            control={control}
            calculatedQuote={calculatedQuote}
            junkRemoval={quote?.pricing.junkRemoval ?? 0}
            junkRemovalOptions={junkRemovalOptions}
            discountsCouponsOptions={discountsCouponsOptions}
            setValue={setValue}
            watch={watch}
          />
        )}
      </div>
      <ActionsFooter
        show={!loading && !isNoFoundProducts}
        actions={[
          {
            label: "Use these values",
            buttonType: "filled",
            isLoading: formState.isSubmitting,
            disabled: !formState.isValid,
            onClick: handleSubmit(handleUseTheseValues),
          },
          {
            label: "Edit manually",
            buttonType: "text",
            color: "primary",
            size: "medium",
            onClick: handleEditManually,
          },
        ]}
      />
    </div>
  );
});
