import { createEffect } from "effector/compat";
import { DeepPartial } from "react-hook-form";

import { fetcher } from "shared/libs";
import { Communication, CommunicationsRequest } from "../types";

export const addCommunication = ({
  locationId,
  appointmentId,
  payload,
}: CommunicationsRequest.Params & { payload: DeepPartial<Communication> }) =>
  fetcher.post<CommunicationsRequest.Response>(
    `locations/${locationId}/appointments/${appointmentId}/communications`,
    payload,
  );

export const addCommunicationFx = createEffect(addCommunication);
