import { Button } from "@chhjpackages/components";
import { InputAdornment } from "@material-ui/core";
import { memo } from "react";

import {
  FormCheckbox,
  FormInput,
  FormNewSelect,
  FormTextArea,
} from "shared/ui";

import {
  PaymentFormProps,
  cardTypesOptions,
  expMonthsOptions,
  expYearsOptions,
  paymentMethodsOptions,
} from "./model";
import { useStyles } from "./assets";
import { usePaymentForm } from "./model/hooks";
import { ProcessPlaceholder } from "./ui";

export const PaymentForm = memo(
  ({ terminals, initialValues, onSubmit }: PaymentFormProps) => {
    const styles = useStyles();

    const {
      control,
      formState: { isSubmitting },
      showTerminal,
      showPaymentDate,
      showCheckNumber,
      showDepositDateAndNumber,
      showNotes,
      showManualCard,
      terminalsOptions,
      isTerminalPayment,
      disableSubmit,
      isContactless,
      handleOnSubmit,
    } = usePaymentForm({
      terminals,
      initialValues,
      onSubmit,
    });

    if (isSubmitting && isTerminalPayment) {
      return <ProcessPlaceholder isContactless={isContactless} />;
    }

    return (
      <form className={styles.root} onSubmit={handleOnSubmit}>
        <FormNewSelect
          control={control}
          name="method"
          required
          label="Payment method"
          options={paymentMethodsOptions}
          virtualize={false}
        />

        {showTerminal && (
          <FormNewSelect
            control={control}
            name="terminal"
            required
            label="Terminal"
            options={terminalsOptions}
            virtualize={false}
          />
        )}

        <FormInput
          control={control}
          name="amount"
          required
          label="Payment amount"
          mask="positive-two-decimal"
          classes={{
            input: styles.priceInput,
          }}
          startAdornment={
            <InputAdornment
              position="start"
              disablePointerEvents={true}
              className={styles.inputAdornment}
            >
              $
            </InputAdornment>
          }
        />

        {showTerminal && (
          <FormCheckbox
            control={control}
            name="isContactless"
            label="Card has chip / contactless feature"
            className="checkbox"
          />
        )}

        {showPaymentDate && (
          <FormInput
            control={control}
            name="paymentDate"
            required
            label="Payment date"
            mask="date"
            calendarAdornment
            dateFormat="MM/DD/YYYY"
          />
        )}

        {showManualCard && (
          <>
            <FormNewSelect
              control={control}
              name="cardType"
              required
              label="Card type"
              options={cardTypesOptions}
              virtualize={false}
            />

            <FormInput
              control={control}
              name="cardNumber"
              required
              label="Card number (last 4 digits)"
              mask="positive-integer-with-zero"
              inputProps={{ maxLength: 4 }}
            />

            <FormInput control={control} name="cardName" label="Name on card" />

            <div className={styles.expMonthYearContainer}>
              <FormNewSelect
                control={control}
                name="expMonth"
                required
                label="Exp. month"
                options={expMonthsOptions}
                virtualize={false}
              />

              <FormNewSelect
                control={control}
                name="expYear"
                required
                label="Exp. year"
                options={expYearsOptions}
                virtualize={false}
              />
            </div>

            <FormInput control={control} name="address1" label="Address 1" />

            <FormInput control={control} name="address2" label="Address 2" />

            <FormInput
              control={control}
              name="zip"
              label="Zip/postal code"
              mask="positive-integer-with-zero"
            />

            <FormInput
              control={control}
              name="transactionId"
              mask={["digits", "letters", "space", "dash", "underscore"]}
              label="Transaction ID"
            />

            <FormInput
              control={control}
              name="authCode"
              mask={["digits", "letters", "space", "dash", "underscore"]}
              label="Auth code"
            />
          </>
        )}

        {showCheckNumber && (
          <FormInput
            control={control}
            name="checkNumber"
            required
            mask={["digits", "letters", "space", "dash", "underscore"]}
            label="Check number"
          />
        )}

        {showDepositDateAndNumber && (
          <FormInput
            control={control}
            name="depositDate"
            required
            label="Deposit date"
            mask="date"
            calendarAdornment
            dateFormat="MM/DD/YYYY"
          />
        )}

        {showDepositDateAndNumber && (
          <FormInput
            control={control}
            name="depositNumber"
            mask={["digits", "letters", "space", "underscore"]}
            label="Deposit batch number"
          />
        )}

        {showNotes && (
          <FormTextArea control={control} name="notes" label="Notes" />
        )}

        <Button
          buttonType="twoTone"
          type="submit"
          fullWidth
          disabled={disableSubmit}
          isLoading={isSubmitting}
        >
          {isTerminalPayment ? "Collect payment" : "Log payment"}
        </Button>
      </form>
    );
  },
);
