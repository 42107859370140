import { Appointments } from "features/appointment";

export const returnPrices = (
  appointment: Appointments | null,
  permissions: { showAdminFee: boolean },
) => {
  return {
    "": [
      {
        id: 1,
        name: "Products:",
        price: appointment?.productTotal ?? 0,
      },
      {
        id: 2,
        name: "Discounts:",
        price: appointment?.discountTotal ?? 0,
      },
      {
        id: 3,
        name: "Coupons:",
        price: appointment?.couponTotal ?? 0,
      },
      ...(permissions.showAdminFee
        ? [
            {
              id: 3,
              name: "Admin fee:",
              price: appointment?.administrativeFee ?? 0,
            },
          ]
        : []),
      {
        id: 4,
        name: "Taxes:",
        price: appointment?.appointmentTaxTotal ?? 0,
      },
      {
        id: 5,
        name: "Tip:",
        price: appointment?.tipAmount ?? 0,
      },
      {
        id: 6,
        name: "Paid:",
        price: appointment?.amountPaid ?? 0,
      },
      {
        id: 7,
        name: "Balance:",
        price: appointment?.balanceDue ?? 0,
      },
    ],
  };
};
