import { useForm } from "react-hook-form";
import { memo, useEffect, useMemo, useState } from "react";
import moment from "moment";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@chhjpackages/components";

import { FormNewSelect } from "shared/ui";
import { USATimeFormatAmPmSmall } from "shared/utils";

import { PickUpFormProps, PickUpFormValues } from "./model";

export const pickUpValidationSchema = yup.object({
  schedule: yup
    .object({
      label: yup.string().required(""),
      value: yup.number().required(""),
    })
    .nullable()
    .required("Schedule is required"),
});

export const PickUpForm = memo(
  ({ schedules, onClose, onSubmit }: PickUpFormProps) => {
    const { control, formState, handleSubmit } = useForm<PickUpFormValues>({
      mode: "all",
      resolver: yupResolver(pickUpValidationSchema),
    });

    const [dialogScrollbar, setDialogScrollbar] = useState(
      document.querySelectorAll('[role="dialog"]')[0] as HTMLElement,
    );
    const [dialogPortal, setDialogPortal] = useState(
      document.getElementsByClassName("MuiDialog-root")[0] as HTMLElement,
    );
    const [selectMenuOpen, setSelectMenuOpen] = useState(false);

    const scheduleOptions = useMemo(
      () =>
        schedules.map((schedule) => ({
          value: schedule.id,
          label: `${moment(schedule.startDate).format(
            USATimeFormatAmPmSmall,
          )} - ${moment(schedule.endDate).format(USATimeFormatAmPmSmall)}`,
          isDisabled: !!schedule.team.id,
        })),
      [schedules],
    );

    useEffect(() => {
      if (!dialogScrollbar) {
        return;
      }

      if (selectMenuOpen) {
        dialogScrollbar.style.overflow = "hidden";
      } else {
        dialogScrollbar.style.overflow = "";
      }
    }, [selectMenuOpen]);

    useEffect(() => {
      setDialogScrollbar(
        document.querySelectorAll('[role="dialog"]')[0] as HTMLElement,
      );
      setDialogPortal(
        document.getElementsByClassName("MuiDialog-root")[0] as HTMLElement,
      );
    }, []);

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormNewSelect
          control={control}
          name="schedule"
          label="Schedule"
          options={scheduleOptions}
          virtualize={false}
          required
          menuPlacement="auto"
          menuPortalTarget={dialogPortal}
          menuShouldScrollIntoView={false}
          menuIsOpen={selectMenuOpen}
          onMenuOpen={() => setSelectMenuOpen(true)}
          onMenuClose={() => setSelectMenuOpen(false)}
        />

        <div
          style={{
            marginTop: 24,
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <Button color="primary" buttonType="text" onClick={onClose}>
            Close
          </Button>
          <div style={{ width: 136, marginLeft: 8 }}>
            <Button
              buttonType="twoTone"
              fullWidth
              disabled={!formState.isValid}
              isLoading={formState.isSubmitting}
              type="submit"
            >
              Pick up
            </Button>
          </div>
        </div>
      </form>
    );
  },
);
