import { memo } from "react";
import { Typography } from "@material-ui/core";

import { FormTextArea } from "shared/ui";

import { LiabilityWaiverFormProps } from "./types";
import { useStyles } from "./styles";

export const LiabilityWaiverForm = memo(
  ({ form: { control } }: LiabilityWaiverFormProps) => {
    const styles = useStyles();

    return (
      <div className={styles.root}>
        <Typography variant="body1">
          College Hunks Moving® is not liable for hazardous \ uncommon moving
          situations or the following (please list any items and/or reason):
        </Typography>

        <FormTextArea
          control={control}
          name="additionalText"
          label="Additional exclusions to add"
          variant="outlined"
          size="medium"
          rows={9}
        />
      </div>
    );
  },
);
