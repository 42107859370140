import { makeStyles } from "@material-ui/core";
import { colors } from "@chhjpackages/components";

export const useStyles = makeStyles({
  input: {
    "& .MuiInput-input": {
      cursor: "pointer",
      caretColor: "transparent",
    },
  },
  button: {
    "& .buttonContainer": {
      width: "8rem",
    },
  },

  root: {
    "& .MuiSelect-icon": {
      color: `${colors.secondary.main} !important`,
      fontSize: "17px",
      marginTop: "-4px",
    },
  },
});
