import { useState, useCallback, useEffect, useMemo, memo } from "react";
import { Grid, IconButton } from "@material-ui/core";
import { Button, MinusIcon, PlusIcon } from "@chhjpackages/components";
import Cropper, { Area, Point } from "react-easy-crop";

import { cropImage } from "shared/utils";
import { RotateIcon } from "shared/assets";

import { useStyles } from "./styles";
import { CropperProps } from "./types";

export const ImageCropper = memo(
  ({ imageForCrop, onCropperComplete, onClose }: CropperProps) => {
    const styles = useStyles();

    const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [rotation, setRotation] = useState(0);
    const [croppedImgAreaPixels, setCroppedImgAreaPixels] = useState<Area>();
    const [isHeightAvatarGreaterWidth, setIsHeightAvatarGreaterWidth] =
      useState(true);

    const image = useMemo(() => {
      return imageForCrop ? URL.createObjectURL(imageForCrop) : "";
    }, [imageForCrop]);

    const onCropChange = useCallback((location: Point) => {
      setCrop(location);
    }, []);

    const onCropComplete = useCallback((_, croppedAreaPixels: Area) => {
      setCroppedImgAreaPixels(croppedAreaPixels);
    }, []);

    const onCrop = useCallback(async () => {
      if (croppedImgAreaPixels) {
        const croppedImageBlob = await cropImage(
          URL.createObjectURL(imageForCrop),
          croppedImgAreaPixels,
          rotation,
        );
        if (croppedImageBlob) {
          onCropperComplete(croppedImageBlob);
        }
      }
    }, [croppedImgAreaPixels, imageForCrop, rotation, onCropperComplete]);

    useEffect(() => {
      if (imageForCrop) {
        const imageObj = new Image();
        imageObj.src = URL.createObjectURL(imageForCrop);
        imageObj.onload = () => {
          if (imageObj.height < imageObj.width) {
            setIsHeightAvatarGreaterWidth(false);
          }
        };
      }
    }, [imageForCrop]);

    if (!image) {
      return <></>;
    }

    return (
      <>
        <div className={styles.cropContainer}>
          <div className={styles.freeSpace} />
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            rotation={rotation}
            aspect={1}
            onCropChange={onCropChange}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            objectFit={
              isHeightAvatarGreaterWidth ? "horizontal-cover" : "vertical-cover"
            }
          />
        </div>
        <div className={styles.controls}>
          <Grid
            container
            spacing={1}
            alignItems="center"
            justify="space-between"
          >
            <Grid item>
              <IconButton
                className="iconButtonMedium"
                onClick={() =>
                  setZoom((prevValue) =>
                    prevValue > 1 ? prevValue - 0.1 : prevValue,
                  )
                }
              >
                <MinusIcon color="black" className={styles.controlIcon} />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                className="iconButtonMedium"
                onClick={() => setRotation((prevValue) => prevValue + 90)}
              >
                <RotateIcon color="black" fontSize={30} />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                className="iconButtonMedium"
                onClick={() => setZoom((prevValue) => prevValue + 0.1)}
              >
                <PlusIcon color="black" className={styles.controlIcon} />
              </IconButton>
            </Grid>
          </Grid>
        </div>
        <Grid container justify="flex-end" alignItems="center" spacing={1}>
          {onClose && (
            <Grid item>
              <Button buttonType="text" color="primary" onClick={onClose}>
                Close
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button
              buttonType="twoTone"
              fullWidth
              className={styles.submitButton}
              onClick={() => onCrop()}
            >
              Submit photo
            </Button>
          </Grid>
        </Grid>
      </>
    );
  },
);
