import { Button } from "@chhjpackages/components";
import { Box, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { memo, useCallback, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useUserAvatar } from "features/user-avatar";
import { FormInput, FormTextArea, ImportantBadge } from "shared/ui";
import { networkCheck } from "shared/utils";

import {
  getInitialUserProfileValues,
  UserProfileFormProps,
  UserProfileFormValues,
} from "./model";
import { useStyles } from "./assets";

const userProfileSchema = yup.object({
  firstName: yup.string().trim().required("First name is required"),
  lastName: yup.string().trim().required("Last name is required"),
  phone: yup
    .string()
    .required("Phone is required")
    .nullable()
    .transform((_, originvalValue) => {
      return originvalValue.replace(/\D/g, "");
    })
    .when((phone, schema) => {
      if (String(phone).length > 0) {
        return schema.min(10, "Phone must be at least 10 characters");
      }
    }),
  email: yup
    .string()
    .trim()
    .required("Email is required")
    .email("Must be a valid email"),
  biography: yup.string(),
});

export const UserProfileForm = memo(
  ({ user, isSubmiting, onSubmit }: UserProfileFormProps) => {
    const styles = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"), {
      noSsr: true,
    });

    const { control, formState, setValue, handleSubmit } =
      useForm<UserProfileFormValues>({
        mode: "all",
        defaultValues: getInitialUserProfileValues(user),
        resolver: yupResolver(userProfileSchema),
      });

    const [isEmailFocus, setIsEmailFocus] = useState(false);
    const [isPhoneFocus, setIsPhoneFocus] = useState(false);

    const setImageFieldDirty = useCallback(
      (blobImage) =>
        setValue("blobImage", blobImage, {
          shouldDirty: true,
        }),
      [setValue],
    );

    const { renderAvatar } = useUserAvatar({
      image: user.image,
      locationId: user.location.id,
      onChange: setImageFieldDirty,
      userId: user.id,
    });

    const onHandleSubmit = useCallback(
      async (data) => {
        await onSubmit(data);
        setValue("phone", user.sms, { shouldDirty: true });
        setValue("email", user.email, { shouldDirty: true });
        setValue("blobImage", undefined);
      },
      [user.sms, user.email, onSubmit, setValue],
    );

    return (
      <form onSubmit={handleSubmit(onHandleSubmit)}>
        <Box>{renderAvatar(isMobile)}</Box>
        <Box mt={2}>
          <FormInput
            name="firstName"
            control={control}
            required
            label="First name"
          />
        </Box>
        <Box mt={2}>
          <FormInput
            name="lastName"
            control={control}
            required
            label="Last name"
          />
        </Box>
        <Box mt={2}>
          <FormInput
            name="phone"
            control={control}
            required
            mask="phone"
            label="Phone number"
            onFocus={() => setIsPhoneFocus(true)}
            onBlurCapture={() => setIsPhoneFocus(false)}
          />
          {isPhoneFocus && (
            <Box mt={1}>
              <ImportantBadge>
                <Typography variant="body1" className={styles.typographyWrap}>
                  <Box fontWeight="bold" display="inline">
                    IMPORTANT:
                  </Box>
                  {"\n"}
                  <Box fontWeight="bold" display="inline">
                    Phone numbers are unique to each user account.
                  </Box>
                  {"\n"}
                  If this phone exists on any other user accounts, it will be
                  removed from those accounts once it is verified.{"\n\n"}
                  <Box fontWeight="bold" display="inline">
                    This will text a verification link to your phone
                  </Box>
                  , and the phone on your account will not be updated until that
                  link is clicked.{"\n\n"}
                  <Box fontWeight="bold" display="inline">
                    Verification links expire after 30 minutes
                  </Box>{" "}
                  and must be resent if not clicked in time.
                </Typography>
              </ImportantBadge>
            </Box>
          )}
        </Box>
        <Box mt={2}>
          <FormInput
            name="email"
            control={control}
            required
            label="Email"
            onFocus={() => setIsEmailFocus(true)}
            onBlurCapture={() => setIsEmailFocus(false)}
          />
          {isEmailFocus && (
            <Box mt={1}>
              <ImportantBadge>
                <Typography variant="body1" className={styles.typographyWrap}>
                  <Box fontWeight="bold" display="inline">
                    IMPORTANT:
                  </Box>
                  {"\n"}
                  <Box fontWeight="bold" display="inline">
                    Emails are unique to each user account.
                  </Box>
                  {"\n"}
                  If this email exists on any other user accounts, it will be
                  removed from those accounts once it is verified.{"\n\n"}
                  <Box fontWeight="bold" display="inline">
                    This will send a verification link to your email
                  </Box>
                  , and the email on your account will not be updated until that
                  link is clicked.{"\n\n"}
                  <Box fontWeight="bold" display="inline">
                    Verification links expire after 30 minutes
                  </Box>{" "}
                  and must be resent if not clicked in time.
                </Typography>
              </ImportantBadge>
            </Box>
          )}
        </Box>
        <Box mt={2}>
          <FormTextArea
            name="bio"
            control={control}
            label="My bio"
            rowsMax={3}
            rows={3}
          />
        </Box>
        <Box mt={2}>
          <Button
            buttonType="filled"
            size="large"
            disabled={
              (isSubmiting
                ? false
                : !formState.isValid || !formState.isDirty) || !networkCheck()
            }
            isLoading={isSubmiting}
            fullWidth
            type="submit"
          >
            Confirm changes
          </Button>
        </Box>
      </form>
    );
  },
);
