import { RequestProp } from "features/job-offline-changes";

export const groupBy = (items: RequestProp[], key: any) =>
  items.reduce(
    (result: any, item: any) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {},
  );
