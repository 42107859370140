import { colors } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1,
    width: "100%",
    padding: "0px 16px 8px",
    backgroundColor: colors.white,

    [theme.breakpoints.up("lg")]: {
      paddingTop: 6,
    },
  },
  controlPanelRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: 16,
  },
  titleSection: {
    display: "flex",
    alignItems: "center",
    gap: 24,
  },
  backButton: {
    margin: -8,
  },
  backButtonRoot: {
    padding: 8,
  },
  title: {
    color: colors.black,
  },
  controlPanelActionsContainer: {
    display: "flex",
    alignItems: "center",
    gap: 16,
  },
  actionButton: {
    margin: "-2px -8px",

    "&:last-child": {
      marginRight: 0,
    },
  },
  actionButtonRoot: {
    padding: "2px 8px",
  },
  actionButtonContent: {
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
  childrenContainer: {
    marginTop: 8,
  },
}));
