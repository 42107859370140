import { memo } from "react";
import { Typography } from "@material-ui/core";

import { useStyles } from "./styles";
import { companies } from "./utils";

export const Page3 = memo(() => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <Typography variant="h2" component="div" align="center">
          Where have i seen
        </Typography>
        <Typography variant="h4" component="div" align="center">
          college hunks Hauling junk?
        </Typography>
      </div>

      {companies.map(({ name, logo }, index) => (
        <div key={index} className={styles.logoBox}>
          <img src={logo} alt={name} title={name} />
        </div>
      ))}
    </div>
  );
});
