import { createEffect, createStore } from "effector/compat";

import { getCoupons } from "../api";
import { Coupon } from "../types";

export const getCouponsFx = createEffect(getCoupons);

export const $coupons = createStore<{
  coupons: Coupon[];
  couponsLocationId?: number;
  loading: boolean;
}>({
  coupons: [],
  couponsLocationId: undefined,
  loading: true,
});

$coupons
  .on(getCouponsFx.doneData, (store, response) => ({
    ...store,
    coupons: response.data.coupons,
    couponsLocationId: response.locationId,
  }))
  .on(getCouponsFx.pending, (store, loading) => ({
    ...store,
    loading,
  }));
