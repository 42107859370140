import { memo, useEffect, useRef } from "react";
import { Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { colors, Scrollbars } from "@chhjpackages/components";
import { useStore } from "effector-react";

import {
  AppointmentListViewCard,
  $appointmentStore,
} from "features/appointment";

import { AppointmentListProps } from "./types";

export const AppointmentListViewList = memo(
  ({
    isScroll,
    actualTime,
    groupedByTimeAppointments,
    currentTeams,
    handleOpenMapTypesDialog,
    handleOpenContactsDialog,
    handleOpenNotesDialog,
    handlePickUp,
  }: AppointmentListProps) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
      noSsr: true,
    });
    const actualPeriod = useRef<null | HTMLDivElement>(null);
    const { appointment } = useStore($appointmentStore);

    useEffect(() => {
      if (actualPeriod.current && isScroll) {
        actualPeriod.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, [actualTime, isScroll]);

    return (
      <>
        {groupedByTimeAppointments.map((item) => (
          <div key={item.time}>
            <div
              style={{
                backgroundColor: colors.grey30,
                padding: 8,
              }}
            >
              <Typography
                ref={
                  !!appointment && item.time === actualTime
                    ? actualPeriod
                    : null
                }
                variant="h5"
              >
                {item.time}
              </Typography>
            </div>

            {isMobile ? (
              <div style={{ marginTop: 8 }}>
                {item.data.map((cardAppointment) => (
                  <div key={cardAppointment.id} style={{ marginBottom: 8 }}>
                    <AppointmentListViewCard
                      actualPeriod={actualPeriod}
                      isActual={appointment?.id === cardAppointment.id}
                      appointment={cardAppointment}
                      currentTeams={currentTeams}
                      handleOpenContactsDialog={handleOpenContactsDialog}
                      handleOpenNotesDialog={handleOpenNotesDialog}
                      handleOpenMapTypesDialog={handleOpenMapTypesDialog}
                      handlePickUp={handlePickUp}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div style={{ margin: "16px 0px 8px" }}>
                <Scrollbars
                  autoHide
                  autoHeight
                  autoHeightMin="100%"
                  autoHeightMax="100%"
                  style={{ height: "100%" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: 20,
                    }}
                  >
                    {item.data.map((cardAppointment) => (
                      <div key={cardAppointment.id} style={{ marginRight: 16 }}>
                        <AppointmentListViewCard
                          appointment={cardAppointment}
                          currentTeams={currentTeams}
                          handleOpenContactsDialog={handleOpenContactsDialog}
                          handleOpenNotesDialog={handleOpenNotesDialog}
                          handleOpenMapTypesDialog={handleOpenMapTypesDialog}
                          handlePickUp={handlePickUp}
                        />
                      </div>
                    ))}
                  </div>
                </Scrollbars>
              </div>
            )}
          </div>
        ))}
      </>
    );
  },
);
