import { Box, Divider, Typography } from "@material-ui/core";
import { memo } from "react";
import { ClockIcon, colors, TrashIcon } from "@chhjpackages/components";
import moment from "moment";

import { ArrowIcon } from "shared/assets/icons";
import { RequestProp } from "features/job-offline-changes";

import { useStyles } from "./styles";

export const JobOfflineCard = memo(
  ({ requests }: { requests: RequestProp[] }) => {
    const styles = useStyles();

    return (
      <Box className={styles.mainBox}>
        <Box className={styles.changesTime}>
          <ClockIcon className={styles.clockIcon} color={colors.grey100} />
          <Typography variant="body1">
            {moment(requests[0].time).format("DD/MM/YY hh:mm a")}
          </Typography>
        </Box>
        <Box px={2} pb={2} className={styles.changes}>
          {requests.map(({ description, startState, endState, deleted }, i) => (
            <Box key={i} mt={2}>
              <Typography variant="body1">{description}</Typography>
              <Box my={2} className={styles.stateChanges}>
                <Box
                  display="flex"
                  alignItems="center"
                  px={1}
                  bgcolor={colors.functionals.alertLight}
                >
                  <Typography
                    variant="body1"
                    style={{ textDecoration: "line-through" }}
                  >
                    {startState || "//"}
                  </Typography>
                </Box>
                <ArrowIcon className={styles.arrowIcon} />
                <Box
                  display="flex"
                  alignItems="center"
                  px={1}
                  bgcolor={colors.functionals.successLight}
                >
                  {deleted ? (
                    <TrashIcon fontSize={24} />
                  ) : (
                    <Typography variant="body1">{endState || "//"}</Typography>
                  )}
                </Box>
              </Box>
              <Divider />
            </Box>
          ))}
        </Box>
      </Box>
    );
  },
);
