import { colors } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  clockTimePickerButtonRoot: {
    width: 124,
    border: `1px solid ${colors.grey30}`,
    borderRadius: 4,
  },
  title: {
    fontSize: 12,
    lineHeight: "24px",
    fontWeight: 500,
  },
});
