import { Switch, withStyles } from "@material-ui/core";
import { colors } from "@chhjpackages/components";

export const StyledSwitch = withStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    width: 51,
    height: 30,

    "& .MuiIconButton-root:hover": {
      background: "none",
    },
  },
  switchBase: {
    top: "initial",
    padding: 2,
    transition:
      "left 220ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,transform 220ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    "&$checked": {
      color: "#35C759",
    },
    "&$checked + $track": {
      color: "#35C759",
      backgroundColor: "#35C759",
      opacity: 1,
    },
  },
  thumb: {
    width: 26,
    height: 26,
    background: colors.basic.white,
    boxShadow:
      "0px 1px 1px rgba(0, 0, 0, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.01), 0px 1px 1px rgba(0, 0, 0, 0.03)",
    borderRadius: 100,
  },
  track: {
    color: "#35C759",
    borderRadius: 36.5,
    background: colors.grey20,
    opacity: 1,
  },
  checked: {},
  focusVisible: {},
  disabled: {},
})(Switch);
