import { colors } from "@chhjpackages/components";
import { Box, IconButton } from "@material-ui/core";
import { useMemo, useState } from "react";

import { ArrowBackIcon } from "shared/assets";

import { SliderProps } from "./types";
import { useStyles } from "./styles";

export const Slider = <T,>({
  items,
  itemWidth,
  numberShowSlides,
  spacing,
  classes,
  offset,
  renderElement,
}: SliderProps<T>) => {
  const styles = useStyles();

  const [slideNumber, setSlideNumber] = useState(0);

  const width = useMemo(
    () => (itemWidth + ((spacing || 0) / 2) * 8) * numberShowSlides,
    [itemWidth, spacing, numberShowSlides],
  );

  const translateXValue = useMemo(
    () =>
      slideNumber * width +
      slideNumber * (spacing || 0) * 8 +
      (offset && slideNumber ? offset(slideNumber) : 0),
    [slideNumber, width, spacing, offset],
  );

  const disabledLeftArrow = useMemo(() => slideNumber === 0, [slideNumber]);

  const disabledRightArrow = useMemo(
    () => slideNumber + 1 >= items.length / numberShowSlides,
    [slideNumber, items.length, numberShowSlides],
  );

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box>
        <Box width={width} overflow="hidden">
          <Box
            display="flex"
            alignItems="center"
            style={{
              transform: `translateX(-${translateXValue}px)`,
              transition: "all 0.3s ease-in-out 0s",
            }}
            ml={-((spacing || 0) / 2)}
          >
            {items.map((item, i) => (
              <Box key={i} mx={spacing}>
                {renderElement(item, slideNumber, i)}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        minWidth={98}
        mt={2}
        className={classes?.arrowsContainer}
      >
        <Box m={-1.5}>
          <IconButton
            onClick={() => setSlideNumber((prev) => prev - 1)}
            disabled={disabledLeftArrow}
          >
            <ArrowBackIcon
              color={disabledLeftArrow ? colors.grey40 : colors.grey140}
              fontSize={10}
            />
          </IconButton>
        </Box>
        <Box m={-1.5}>
          <IconButton
            onClick={() => setSlideNumber((prev) => prev + 1)}
            disabled={disabledRightArrow}
          >
            <ArrowBackIcon
              color={disabledRightArrow ? colors.grey40 : colors.grey140}
              fontSize={10}
              className={styles.rightArrow}
            />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};
