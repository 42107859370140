import { Box, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

export const EditHoursElementSkeleton = () => {
  return (
    <Box display="flex" alignItems="center">
      <Box minWidth={66}>
        <Typography variant="caption">
          <Skeleton variant="text" />
        </Typography>
      </Box>
      <Box ml={4}>
        <Skeleton
          variant="rect"
          width={124}
          height={39}
          style={{ borderRadius: 4 }}
        />
      </Box>
    </Box>
  );
};
