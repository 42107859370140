import { colors } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/core";

export const useValuationStyles = makeStyles(() => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.white,
  },
  content: {
    flex: 1,
    padding: 16,
  },
  title: {
    color: colors.grey140,
  },
  valuationOptionsList: {
    marginTop: 16,
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },
  valuationPlansListCollapseContainer: {
    padding: 16,
    margin: -16,
  },
  valuationPlansList: {
    marginTop: 16,
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },
}));
