import { useCallback, useEffect, useState } from "react";
import moment from "moment";
import camelcase from "camelcase";

import { FilterItem, FilterState } from "../types";
import { filterMapping, returnFilters } from "../utils";
import { HunkTeam } from "features/teams";

export const useFilters = <T>(
  items: T[],
  filters: FilterItem<T>[],
  userTeams: HunkTeam[] | null,
  onFilterCallback?: (filterState: FilterState) => void,
) => {
  const [filteredList, setFilteredList] = useState<T[]>(items);
  const [usedFilters, setUsedFilters] = useState<string>("");

  const onFilter = useCallback(
    (filterState: FilterState) => {
      let filteredData = [...items];
      let actuaFilters = "";

      filters.forEach((filter) => {
        const filterType = filter.type;
        const filterByType = filter.filterByType;
        const currentFilter = filterState[camelcase(filter.name)];

        if (Array.isArray(currentFilter)) {
          currentFilter.forEach((filterId) => {
            const choosenFilter = filter.options?.find(
              ({ id }) => id === filterId,
            );

            if (choosenFilter && filterId !== -1) {
              actuaFilters += `${choosenFilter.name}, `;
            }
          });
        }

        if (typeof currentFilter === "number" && currentFilter !== -1) {
          const choosenFilter = filter.options?.find(
            ({ id }) => id === currentFilter,
          );

          if (choosenFilter) {
            actuaFilters += `${choosenFilter.name}, `;
          }
        }

        if (
          !(
            currentFilter === -1 ||
            (Array.isArray(currentFilter) &&
              (currentFilter as number[]).includes(-1))
          )
        ) {
          if (
            filterType === "select" &&
            filterByType === "object" &&
            filter.multiple &&
            (currentFilter as number[]).length
          ) {
            filteredData = filterMapping<T>(filteredData, filter, (value) =>
              (currentFilter as number[]).includes(Number(value) as number),
            );
          }

          if (
            filterType === "select" &&
            filterByType === "array" &&
            filter.multiple &&
            (currentFilter as number[]).length
          ) {
            filteredData = filteredData.filter((itemNestedArray) => {
              if (filter.filterByComplexField) {
                const data = filter.filterByComplexField(
                  Object(itemNestedArray)[filter.filterByField],
                );

                const actualFilters = filter.options?.filter((option) =>
                  (currentFilter as number[]).includes(Number(option.id)),
                );

                return actualFilters?.some((filteredTime) =>
                  (data as string).includes(filteredTime.name),
                );
              } else {
                return false;
              }
            });
          }

          if (filter.name === "Assignments") {
            const filteredAppointments: T[] = [];

            filteredData.forEach((appointment: any) => {
              const teamAssignedToAppointment = !!userTeams?.find(
                (team) =>
                  !!appointment?.schedules.find(
                    (schedule: any) => schedule.team.id === team.id,
                  ),
              );

              if (currentFilter === 1 && teamAssignedToAppointment) {
                filteredAppointments.push(appointment);
              } else if (currentFilter === 2 && !teamAssignedToAppointment) {
                filteredAppointments.push(appointment);
              }
            });

            filteredData = filteredAppointments;
          }

          if (
            filterType === "select" &&
            !filter.multiple &&
            currentFilter &&
            filter.name !== "Assignments"
          ) {
            filteredData = filterMapping(
              filteredData,
              filter,
              (data) => data === Number(currentFilter),
            );
          }

          if (
            filterType === "button" &&
            filter.filterByValue &&
            currentFilter
          ) {
            filteredData = filterMapping(
              filteredData,
              filter,
              (data) => data === filter.filterByValue,
            );
          }

          if (filterType === "button" && filter.recentDate && currentFilter) {
            filteredData = filteredData.sort((el1, el2) =>
              filter.filterByComplexField
                ? (
                    filter.filterByComplexField(
                      Object(el2)[filter.filterByField],
                    ) as string
                  ).localeCompare(
                    filter.filterByComplexField(
                      Object(el1)[filter.filterByField],
                    ) as string,
                  )
                : Object(el2)[filter.filterByField].localeCompare(
                    Object(el1)[filter.filterByField],
                  ),
            );
          }

          if (filterType === "date" && filter.dateRange && currentFilter) {
            const dates = currentFilter as Date[];
            const start = moment(new Date(dates[0])).startOf("day");
            const end = dates[1]
              ? moment(new Date(dates[1])).endOf("day")
              : moment().endOf("day");

            filteredData = filterMapping(
              filteredData,
              filter,
              (data) =>
                start.isSameOrBefore(data, "minutes") &&
                end.isSameOrAfter(data, "minutes"),
            );
          }
        }
      });

      setUsedFilters(actuaFilters.slice(0, -2));
      setFilteredList(filteredData);

      if (onFilterCallback) {
        onFilterCallback(filterState);
      }
    },
    [items, filters, userTeams, onFilterCallback],
  );

  useEffect(() => {
    const savedFilters = returnFilters();

    if (savedFilters) {
      onFilter(savedFilters);
    } else {
      setFilteredList(items);
    }
  }, [items]);

  return { filteredList, onFilter, usedFilters };
};
