import { Navigate, useLocation } from "react-router";
import { Loader } from "@chhjpackages/components";
import { useStore } from "effector-react/compat";
import { memo, useMemo } from "react";
import { useDebounce } from "use-debounce";

import { $location, useAuth } from "../../model";
import { $permissionsStore } from "features/permissions";

import { useStyles } from "./styles";
import { ProtectedRouteProps } from "./types";

export const ProtectedRoute = memo(({ children }: ProtectedRouteProps) => {
  const styles = useStyles();

  const location = useLocation();

  const { isAuthorized, isAuthorizing } = useAuth();

  const { loading: permissionsLoading } = useStore($permissionsStore);
  const { loading: locationLoading } = useStore($location);

  const showLoader = useMemo(
    () => isAuthorizing || permissionsLoading || locationLoading,
    [isAuthorizing, permissionsLoading, locationLoading],
  );

  const accessDenied = useMemo(
    () => !isAuthorized && !isAuthorizing,
    [isAuthorized, isAuthorizing],
  );

  const [showLoaderDebounce] = useDebounce(showLoader, 10);

  if (accessDenied) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (showLoaderDebounce) {
    return (
      <div className={styles.root}>
        <Loader />
      </div>
    );
  }

  return <>{children}</>;
});
