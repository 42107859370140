import { makeStyles } from "@material-ui/core";

export const useCollectStyles = makeStyles(() => ({
  root: {
    padding: 16,
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
}));
