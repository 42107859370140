import { fetcher } from "shared/libs";

import { AddCompletedAction, DeleteCompletedAction } from "./api-types";

export const addCompletedAction = ({
  locationId,
  appointmentId,
  payload,
}: AddCompletedAction.Params) =>
  fetcher.post<AddCompletedAction.Response>(
    `/locations/${locationId}/appointments/${appointmentId}/completedactions`,
    payload,
  );

export const deleteCompletedAction = async ({
  locationId,
  appointmentId,
  completedActionId,
}: DeleteCompletedAction.Params) => {
  const response = await fetcher.delete<DeleteCompletedAction.Resposne>(
    `/locations/${locationId}/appointments/${appointmentId}/completedactions/${completedActionId}`,
  );

  return {
    ...response,
    completedActionId,
  };
};
