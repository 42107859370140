import { makeStyles } from "@material-ui/core";

export const usePaymentsStyles = makeStyles(() => ({
  root: {
    flex: 1,
    padding: 16,
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },
  actionButtonsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },
}));
