import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  root: {
    zIndex: 1200,
    backgroundColor: "rgba(255, 255, 255, 0.75)",
  },
  containedBackdrop: {
    position: "absolute",
  },
});
