import { RadioGroup } from "@material-ui/core";
import { memo, useCallback } from "react";

import { BillOfLadingViewProps, CoupounsDiscountsEnum } from "./model";
import { DiscountCard } from "./ui";

export const DiscountSection = memo(
  ({
    discountsCouponsOptions,
    watch,
    setValue,
  }: Pick<
    BillOfLadingViewProps,
    "discountsCouponsOptions" | "watch" | "setValue"
  >) => {
    const couponDiscountWatched = watch("couponDiscount");

    const handleChange = useCallback(
      (_, value) => {
        const selectedOption = discountsCouponsOptions.find(
          (option) => option.value === Number(value),
        );

        if (selectedOption) {
          setValue("couponDiscount", selectedOption, {
            shouldDirty: true,
            shouldValidate: true,
          });
        }
      },
      [discountsCouponsOptions, setValue],
    );

    const handleSelectSelectedValue = useCallback(
      (value: number) => {
        if (
          discountsCouponsOptions.length === 1 &&
          value === CoupounsDiscountsEnum.DiscountQuote
        ) {
          setValue("couponDiscount", undefined);
        }
      },
      [discountsCouponsOptions.length],
    );

    return (
      <div>
        <RadioGroup
          value={String(couponDiscountWatched?.value)}
          onChange={handleChange}
        >
          {discountsCouponsOptions.map((item, index) => (
            <div key={item.label} style={{ marginTop: index !== 0 ? 10 : 0 }}>
              <DiscountCard
                label={item.label}
                value={item.value}
                amount={item.amount}
                discountType={item.discountType}
                selected={couponDiscountWatched?.value === item.value}
                onSelectSelectedValue={handleSelectSelectedValue}
              />
            </div>
          ))}
        </RadioGroup>
      </div>
    );
  },
);
