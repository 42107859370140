import { Box, withStyles, Theme, makeStyles } from "@material-ui/core";
import { colors } from "@chhjpackages/components";

export const Wrapper = withStyles((theme: Theme) => ({
  root: {
    position: "relative",
    marginTop: "-154px",
    [theme.breakpoints.down("xs")]: {
      height: 500,
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: "-110px",
    },
  },
}))(Box);

export const StepGraphic = withStyles((theme: Theme) => ({
  root: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",

    [theme.breakpoints.down("xs")]: {
      width: "100%",
      minHeight: "100%",
      transform: "rotate(90deg)",

      "& svg": {
        width: "100%",
        minHeight: "100%",
        transform: "rotate(90deg)",
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",

      "& svg": {
        width: "100%",
        height: "100%",
        transform: "rotate(0deg)",
      },
    },
  },
}))(Box);

export const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    top: -50,
    right: -20,
    transform: "rotate(90deg)",
    [theme.breakpoints.down("sm")]: {
      transform: "rotate(90deg)",

      "& svg": {
        transform: "rotate(90deg)",
      },
    },
  },

  heading1: {
    [theme.breakpoints.down("xs")]: {
      fontSize: 20,
      lineHeight: 1.25,
      color: "#A0ADC2",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 20,
      lineHeight: 1.25,
      color: "#A0ADC2",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: 20,
      lineHeight: 1.25,
      color: "#A0ADC2",
    },
  },

  heading2: {
    [theme.breakpoints.down("xs")]: {
      fontSize: 42,
      lineHeight: 1.25,
      color: "#323847",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 42,
      lineHeight: 1.25,
      color: "#323847",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: 42,
      lineHeight: 1.25,
      color: "#323847",
    },
  },

  details: {
    minHeight: 135,
    marginTop: 16,

    [theme.breakpoints.down("xs")]: {
      minHeight: 120,

      "& *": {
        fontSize: 16,
      },
    },
  },

  note: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 54,
    marginTop: 32,

    [theme.breakpoints.down("xs")]: {
      marginTop: 24,

      "& *": {
        fontSize: 16,
      },
    },
  },

  bulletNav: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    background: "#fff",
    bottom: 0,
  },

  bullet: {
    width: 10,
    height: 10,
    borderRadius: 10,
    backgroundColor: colors.grey40,
    display: "inline-block",
    margin: 4,
    position: "relative",
    transition: "all .25s easy",

    "&:before": {
      content: '""',
      display: "block",
      width: "100%",
      height: "100%",
      borderRadius: "100%",
      position: "absolute",
      top: 0,
      left: 0,
      backgroundColor: colors.primary.main,
      transform: "scale(0) rotate(90deg)",
      transition: "all 0.35s linear",
    },

    "&.active": {
      "&:before": {
        transform: "scale(1)",
      },
    },
  },

  bulletButton: {
    width: 60,

    [theme.breakpoints.down("xs")]: {
      width: 50,
    },
    [theme.breakpoints.down("sm")]: {
      width: 50,
      transform: "rotate(0deg)",
    },
  },

  bulletText: {
    color: colors.grey120,
  },
}));
