import { FC, memo, useMemo } from "react";
import { useController } from "react-hook-form";
import { FormControl } from "@material-ui/core";
import { NewSelect } from "@chhjpackages/components";

import { HelperTxt } from "./styles";
import { FormNewSelectProps } from "./types";

export const FormNewSelect: FC<FormNewSelectProps> = memo(
  ({
    placeholder,
    control,
    name,
    size = "medium",
    variant = "outlined",
    options,
    required,
    ...newSelectProps
  }) => {
    const {
      field: { value, onChange },
      fieldState: { error },
    } = useController({
      name,
      control,
      defaultValue: "",
    });

    const helperText = useMemo(() => {
      if (error) {
        return error.message;
      }

      if (required) {
        return "*required";
      }

      return "";
    }, [error, required]);

    return (
      <FormControl error={!!error} fullWidth>
        <NewSelect
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          name={name}
          options={options}
          required={required}
          size={size}
          variant={variant}
          error={!!error}
          isValid={error}
          {...newSelectProps}
        />

        {helperText && <HelperTxt>{helperText}</HelperTxt>}
      </FormControl>
    );
  },
);
