import { colors } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/core";
import { RiftFont } from "@chhjpackages/components";

export const useStyles = makeStyles(() => ({
  editButton: {
    padding: 0,
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "22px",
    color: colors.primary.main,
    fontFamily: RiftFont,
  },
  buttonIcon: {
    marginRight: "10px",
    width: "12px",
  },
}));
