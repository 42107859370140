import { createEvent } from "effector";
import { createStore } from "effector/compat";

import { PricingItem } from "../types";

export const $pricingUpdates = createStore<{
  updates: PricingItem[];
}>({
  updates: [],
});

export const addPricingUpdate = createEvent<PricingItem>();
$pricingUpdates.on(addPricingUpdate, (store, pricing) => ({
  ...store,
  updates: [...store.updates, pricing],
}));

export const editPricingUpdate = createEvent<
  Partial<PricingItem> & Required<Pick<PricingItem, "id">>
>();
$pricingUpdates.on(editPricingUpdate, (store, pricing) => ({
  ...store,
  updates: store.updates.map((update) =>
    update.key === pricing.key ? { ...update, ...pricing } : update,
  ),
}));

export const deletePricingUpdate = createEvent<{ key: number }>();
$pricingUpdates.on(deletePricingUpdate, (store, { key }) => ({
  ...store,
  updates: store.updates.filter((update) => update.key !== key),
}));

export const setPricingUpdate = createEvent<{
  pricing: PricingItem;
  ignoreDelete?: boolean;
}>();
$pricingUpdates.on(setPricingUpdate, (store, { pricing, ignoreDelete }) => {
  const isExist = store.updates.find((update) => update.key === pricing.key);

  if (isExist) {
    if (pricing.quantity === 0 && !ignoreDelete) {
      deletePricingUpdate({ key: pricing.key });
    } else {
      editPricingUpdate({ ...pricing });
    }
  } else {
    addPricingUpdate({ ...pricing });
  }
});

export const setPricingUpdates = createEvent<PricingItem[]>();
$pricingUpdates.on(setPricingUpdates, (store, updates) => ({
  ...store,
  updates,
}));
