import { useAlert, useDialogDispatch } from "@chhjpackages/components";
import { memo, useCallback, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useStore } from "effector-react/compat";

import {
  SendPaymentLinkForm,
  SendPaymentLinkFormValues,
  SendPaymentLinkMethodsEnum,
} from "features/payments";
import { routePaths } from "shared/utils";
import { $appointmentStore } from "features/appointment";
import { DialogContentWrapper } from "shared/ui";
import { sendPaymentLink } from "entities/payments";

export const SendPaymentLink = memo(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const { appointmentId } = useParams();

  const setDialog = useDialogDispatch();
  const { showAlert } = useAlert();

  const { appointment } = useStore($appointmentStore);

  const handleCloseSendPaymentLink = useCallback(() => {
    navigate(routePaths.jobDetailsPayments(Number(appointmentId)), {
      state: location.state,
      replace: true,
    });
  }, [appointmentId, location.state, navigate]);

  const handleSubmitSendPaymentLink = useCallback(
    async (data: SendPaymentLinkFormValues) => {
      if (appointment) {
        try {
          await sendPaymentLink({
            locationId: appointment.location.id,
            appointmentId: appointment.id,
            payload: {
              method:
                data.method === SendPaymentLinkMethodsEnum.email
                  ? "email"
                  : "phone",
            },
          });

          handleCloseSendPaymentLink();
          showAlert("Success! Payment link has been sent.", {
            variant: "success",
          });
        } catch {
          showAlert("Error! Failed to send payment link.", {
            variant: "error",
          });
        }
      }
    },
    [appointment, handleCloseSendPaymentLink, showAlert],
  );

  useEffect(() => {
    if (appointment) {
      setDialog({
        open: true,
        variant: "basic",
        title: "Payment link",
        disableBackdropClick: true,
        dialogContent: (
          <DialogContentWrapper maxWidth={488}>
            <SendPaymentLinkForm
              initialValues={{
                email: appointment.account.email,
                phone: appointment.account.phone,
              }}
              onSubmit={handleSubmitSendPaymentLink}
              onClose={handleCloseSendPaymentLink}
            />
          </DialogContentWrapper>
        ),
        onClose: handleCloseSendPaymentLink,
      });
    }

    return () => setDialog({ open: false });
  }, [
    appointment,
    setDialog,
    handleSubmitSendPaymentLink,
    handleCloseSendPaymentLink,
  ]);

  return null;
});
