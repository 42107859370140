import { memo, useCallback, useEffect, useMemo } from "react";
import { useStore } from "effector-react/compat";
import { Typography, useMediaQuery, useTheme } from "@material-ui/core";
import moment from "moment";
import { useAlert } from "@chhjpackages/components";
import { useLocation } from "react-router-dom";

import { useSideNavDispatch } from "features/sidenav";
import {
  $notifications,
  NotificationCard,
  NotificationCardSkeleton,
  dismissNotification,
  getNotificationsFx,
  undoDismissNotification,
  updateNotificationFx,
} from "entities/notifications";
import { $auth, $authorizedUser } from "features/auth";
import { useLocationTimezone } from "shared/hooks";
import { apiDateTimeFormat, routePaths } from "shared/utils";

import { useListStyles } from "./assets";

export const Notifications = memo(() => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), { noSsr: true });
  const styles = useListStyles();

  const location = useLocation();

  const { locationId } = useStore($auth);
  const { user } = useStore($authorizedUser);
  const {
    filteredNotifications,
    loading: notificationsLoading,
    loadCount: notificationsLoadCount,
  } = useStore($notifications);

  const { setPageName, setShowGoBackButton, setGoToBackUrl } =
    useSideNavDispatch();

  const { showAlert } = useAlert();
  const { utcToZone } = useLocationTimezone("location");

  const isLoading = useMemo(
    () => notificationsLoading && notificationsLoadCount === 0,
    [notificationsLoading, notificationsLoadCount],
  );

  const sortedNotifications = useMemo(
    () =>
      filteredNotifications
        .map((notification) => ({
          ...notification,
          createdDatetime: utcToZone(
            notification.createdDatetime,
            apiDateTimeFormat,
          ),
        }))
        .sort((a, b) => moment(b.createdDatetime).diff(a.createdDatetime)),
    [filteredNotifications, utcToZone],
  );

  const noNotifications = useMemo(
    () => !isLoading && filteredNotifications.length === 0,
    [isLoading, filteredNotifications.length],
  );

  const goBackUrl = useMemo(
    () => (location.key === "default" ? routePaths.dashboard() : -1),
    [location.key],
  );

  const handleOnDismissNotification = useCallback(
    async (notificationId: number) => {
      if (!locationId || !user?.id) {
        showAlert("Error! Failed to dismiss notification.", {
          variant: "error",
        });

        return;
      }

      dismissNotification({ id: notificationId });

      try {
        await updateNotificationFx({
          locationId,
          userId: user.id,
          notificationId,
          payload: {
            isDismissed: 1,
          },
        });
      } catch {
        undoDismissNotification({ id: notificationId });
        showAlert("Error! Failed to dismiss notification.", {
          variant: "error",
        });
      }
    },
    [locationId, user?.id, showAlert],
  );

  useEffect(() => {
    if (locationId && user?.id && notificationsLoadCount > 0) {
      getNotificationsFx({
        locationId,
        userId: user.id,
      });
    }
  }, [locationId, user?.id]);

  useEffect(() => {
    setPageName("Notifications");
    setShowGoBackButton(true);
    // @ts-ignore
    setGoToBackUrl(goBackUrl);

    return () => {
      setPageName("");
      setShowGoBackButton(false);
      setGoToBackUrl("");
    };
  }, [goBackUrl, setPageName, setShowGoBackButton, setGoToBackUrl]);

  return (
    <div className={styles.root}>
      {!isMobile && (
        <Typography variant="h3" className={styles.title}>
          Notifications
        </Typography>
      )}

      <div className={styles.list}>
        {isLoading
          ? [...Array(3)].map((_, i) => <NotificationCardSkeleton key={i} />)
          : sortedNotifications.map((notification) => (
              <NotificationCard
                key={notification.id}
                title={notification.title}
                date={moment(notification.createdDatetime).toDate()}
                disableb={notificationsLoading}
                onDismiss={() =>
                  handleOnDismissNotification(Number(notification.id))
                }
              />
            ))}

        {noNotifications && (
          <Typography variant="body1">There are no notifications.</Typography>
        )}
      </div>
    </div>
  );
});
