import { fetcher } from "shared/libs";
import { ValuationOptions } from "../types";

export const getValuationOptions = ({
  locationId,
  appointmentId,
}: ValuationOptions.GetAll) =>
  fetcher.get<ValuationOptions.Response>(
    `/locations/${locationId}/appointments/${appointmentId}/valuation/options`,
  );
