import { createEffect, createStore } from "effector/compat";

import {
  generateBillOfLading,
  getCurrentBillOfLading,
  updateBillOfLading,
} from "../api";
import { BillOfLading } from "../types";

export const getCurrentBillOfLadingFx = createEffect(getCurrentBillOfLading);
export const generateBillOfLadingFx = createEffect(generateBillOfLading);
export const updateBillOfLadingFx = createEffect(updateBillOfLading);

export const $billOfLading = createStore<{
  billOfLading: BillOfLading | null;
  loading: boolean;
}>({
  billOfLading: null,
  loading: true,
});

$billOfLading
  .on(getCurrentBillOfLadingFx.doneData, (store, response) => ({
    ...store,
    billOfLading: response.data.billOfLading[0] || null,
  }))
  .on(getCurrentBillOfLadingFx.fail, (store) => ({
    ...store,
    billOfLading: null,
  }))
  .on(getCurrentBillOfLadingFx.pending, (store, loading) => ({
    ...store,
    loading,
  }));

$billOfLading
  .on(generateBillOfLadingFx.doneData, (store, response) => ({
    ...store,
    billOfLading: response.data.billOfLading[0] || null,
  }))
  .on(generateBillOfLadingFx.fail, (store) => ({
    ...store,
    billOfLading: null,
  }))
  .on(generateBillOfLadingFx.pending, (store, loading) => ({
    ...store,
    loading,
  }));

$billOfLading.on(updateBillOfLadingFx.doneData, (store, response) => ({
  ...store,
  billOfLading: response.data.billOfLading[0] || null,
}));
