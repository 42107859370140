import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useRef } from "react";

export const restorePasswordValidationSchema = yup.object({
  password: yup.string().required("Please input password"),
  confirmedPassword: yup
    .string()
    .required("Please confirm password")
    .oneOf([yup.ref("password")], "Passwords do not match"),
});

export const useRestorePasswordForm = () => {
  const { control, formState, trigger, watch, handleSubmit } = useForm({
    defaultValues: {
      password: "",
      confirmedPassword: "",
    },
    resolver: yupResolver(restorePasswordValidationSchema),
    mode: "all",
  });

  const password = watch("password");

  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    trigger(["confirmedPassword"]);
  }, [password]);

  return {
    control,
    password,
    formState,
    handleSubmit,
  };
};
