import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useState } from "react";
import { validateEmail, validatePhone } from "@chhjpackages/components";

import { ForgotPasswordFormProps } from "./types";

export const forgotPasswordValidationSchema = yup.object({
  profileData: yup
    .string()
    .required("Please input username, email or phone number")
    .test("profileData", "Email or phone number is invalid", (value) => {
      if (value) {
        if (value.includes("@")) {
          return validateEmail(value);
        } else if (Number(value)) {
          return validatePhone(value);
        }

        return true;
      }

      return false;
    }),
});

export const useForgotPasswordForm = ({
  onSubmit,
}: {
  onSubmit: ForgotPasswordFormProps["onSubmit"];
}) => {
  const { control, formState, handleSubmit } = useForm({
    defaultValues: {
      profileData: "",
    },
    resolver: yupResolver(forgotPasswordValidationSchema),
    mode: "all",
  });

  const [method, setMethod] = useState<"email" | "phone">();

  const onHandleSubmit = useCallback(
    async (data) => {
      if (method) {
        await onSubmit(data, method);
      }
    },
    [method, onSubmit],
  );

  return {
    control,
    formState,
    method,
    setMethod,
    onHandleSubmit,
    handleSubmit,
  };
};
