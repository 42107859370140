import { fetcher } from "shared/libs";

import { AddProduct, AddProducts, DeleteProduct, EditProduct } from "./types";

export const addProduct = ({
  locationId,
  appointmentId,
  payload,
}: AddProduct.Params) =>
  fetcher.post<AddProduct.Response>(
    `/locations/${locationId}/appointments/${appointmentId}/products`,
    payload,
  );

export const setProducts = ({
  locationId,
  appointmentId,
  payload,
}: AddProducts.Params) =>
  fetcher.post<AddProduct.Response>(
    `/locations/${locationId}/appointments/${appointmentId}/products/bulk`,
    payload,
  );

export const editProduct = async ({
  locationId,
  appointmentId,
  productLineId,
  payload,
}: EditProduct.Params) => {
  const response = await fetcher.put<EditProduct.Response>(
    `/locations/${locationId}/appointments/${appointmentId}/products/${productLineId}`,
    payload,
  );

  return {
    ...response,
    productLineId,
  };
};

export const deleteProduct = async ({
  locationId,
  appointmentId,
  productLineId,
}: DeleteProduct.Params) => {
  const response = await fetcher.delete<DeleteProduct.Response>(
    `/locations/${locationId}/appointments/${appointmentId}/products/${productLineId}`,
  );

  return {
    ...response,
    productLineId,
  };
};
