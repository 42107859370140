import { Typography } from "@material-ui/core";

import { PriceItemProps } from "./types";
import { useStyles } from "./styles";

export const PriceItem = ({ label, value, valuePrefix }: PriceItemProps) => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Typography variant="subtitle2" component="div" className={styles.label}>
        {label}:
      </Typography>
      <Typography variant="body2" component="div">
        {valuePrefix && valuePrefix}${value.toFixed(2)}
      </Typography>
    </div>
  );
};
