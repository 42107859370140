import { memo } from "react";
import { Typography } from "@material-ui/core";
import { Paper } from "@chhjpackages/components";

import { ReactComponent as Recycled } from "../../assets/images/recycled.svg";

import { useStyles } from "./styles";

export const Page6 = memo(() => {
  const styles = useStyles();

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.circle}>
          <Typography variant="h1" component="div" color="primary">
            60%
          </Typography>
          <Typography variant="h5" component="div">
            of all items
          </Typography>
          <Typography variant="h5" component="div">
            are{" "}
            <Typography variant="h5" component="span" color="secondary">
              recycled!
            </Typography>
          </Typography>
        </div>

        <div className={styles.leftSide}>
          <div className={styles.mainText}>
            <div className={styles.mainTextIcon}>
              <Recycled />
            </div>
            <Typography variant="h1" component="div" align="center">
              we’ve gone green!
            </Typography>
          </div>
        </div>

        <div className={styles.rightSide}>
          <Typography variant="h3" component="div">
            We help prevent
          </Typography>

          <div className={styles.cardWrapper}>
            <Paper variant="outlined">
              <div className={styles.card}>
                <Typography variant="h2" component="div" color="secondary">
                  3,000
                </Typography>
                <Typography variant="h4" component="div" color="inherit">
                  tons of metal
                </Typography>
              </div>
            </Paper>
          </div>

          <div className={styles.cardWrapper}>
            <Paper variant="outlined">
              <div className={styles.card}>
                <Typography variant="h2" component="div" color="secondary">
                  2,000
                </Typography>
                <Typography variant="h4" component="div" color="inherit">
                  tons of computer equipment
                </Typography>
              </div>
            </Paper>
          </div>

          <div className={styles.cardWrapper}>
            <Paper variant="outlined">
              <div className={styles.card}>
                <Typography variant="h2" component="div" color="secondary">
                  3,000
                </Typography>
                <Typography variant="h4" component="div" color="inherit">
                  tons of paper and cardboard
                </Typography>
              </div>
            </Paper>
          </div>

          <Typography variant="h4" component="div">
            from being placed in landfills
          </Typography>
          <Typography variant="h2" component="div" color="primary">
            every year
          </Typography>
        </div>
      </div>
    </div>
  );
});
