import { Checkbox, Paper } from "@chhjpackages/components";
import { Divider, Typography } from "@material-ui/core";
import { memo } from "react";
import clsx from "clsx";
import { Skeleton } from "@material-ui/lab";

import { useStyles } from "./assets";
import { PriceItemSkeleton } from "./ui";

export const CartTotalSkeleton = memo(() => {
  const styles = useStyles();

  return (
    <Paper elevation={16} className={styles.root}>
      <div className={styles.itemLine}>
        <Typography variant="h4" component="div">
          Cart total:
        </Typography>
        <Typography variant="h4" component="div">
          <Skeleton variant="text" width={80} />
        </Typography>
      </div>
      <div className={styles.checkboxWrapper}>
        <Checkbox variant="outline" label="Charge admin fee" disabled={true} />
      </div>
      <div>
        <PriceItemSkeleton />
        <PriceItemSkeleton />
        <PriceItemSkeleton />
        <PriceItemSkeleton />
      </div>
      <div className={styles.dividerContainer}>
        <Divider />
      </div>
      <div className={clsx(styles.itemLine, styles.balanceDueLine)}>
        <Typography
          variant="body1"
          component="div"
          className={styles.balanceDue}
        >
          Total price:
        </Typography>
        <Typography
          variant="body1"
          color="secondary"
          component="div"
          className={styles.balanceDueValue}
        >
          <Skeleton variant="text" width={150} />
        </Typography>
      </div>
    </Paper>
  );
});
