import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  category: {
    fontSize: 12,
    lineHeight: "24px",
  },
  name: {
    lineHeight: "24px",
  },
});
