import { createStore } from "effector";
import { createEffect } from "effector/compat";

import { SquareLocation } from "../types";
import { getSquareLocations } from "../api/locations";

export const $squareLocations = createStore<{
  squareLocations: SquareLocation[];
  locationId: number | null;
  loading: boolean;
}>({
  squareLocations: [],
  locationId: null,
  loading: true,
});

export const getSquareLocationsFx = createEffect(getSquareLocations);
$squareLocations
  .on(getSquareLocationsFx.done, (store, response) => ({
    ...store,
    squareLocations: response.result.data.locations,
    locationId: response.params.locationId,
  }))
  .on(getSquareLocationsFx.fail, (store) => ({
    ...store,
    squareLocations: [],
    locationId: null,
  }))
  .on(getSquareLocationsFx.pending, (store, loading) => ({
    ...store,
    loading,
  }));
