import { memo, useEffect, useMemo } from "react";
import { Collapse, Divider } from "@material-ui/core";

import { FormCheckboxGroup, FormTextArea } from "shared/ui";
import {
  AddendumChangeOptionIdsEnum,
  descriptionOfChangesOptions,
} from "../../model";

import { DescriptionOfChangesFormProps } from "./types";
import { useStyles } from "./styles";

export const DescriptionOfChangesForm = memo(
  ({
    form: { control, watch, setValue, clearErrors },
  }: DescriptionOfChangesFormProps) => {
    const styles = useStyles();

    const changeOptions = watch("changeOptions");

    const showAdditionalNotes = useMemo(
      () => !!changeOptions.length,
      [changeOptions.length],
    );

    const showDescriptionOfSituation = useMemo(
      () =>
        changeOptions.includes(
          AddendumChangeOptionIdsEnum.UnforeseeableCircumstances,
        ),
      [changeOptions],
    );

    useEffect(() => {
      if (changeOptions.length > 1) {
        const newChangeOption = changeOptions[changeOptions.length - 1];

        if (
          newChangeOption !==
          AddendumChangeOptionIdsEnum.UnforeseeableCircumstances
        ) {
          clearErrors("descriptionOfSituation");
        }

        setValue("changeOptions", [newChangeOption]);
      }
    }, [changeOptions.length]);

    return (
      <div>
        <FormCheckboxGroup
          control={control}
          name="changeOptions"
          groups={descriptionOfChangesOptions}
        />

        <Collapse in={showDescriptionOfSituation}>
          <div className={styles.decriptionOfSituationContent}>
            <FormTextArea
              control={control}
              required
              name="descriptionOfSituation"
              label="Description of the situation"
              variant="outlined"
              size="medium"
              rows={5}
            />
          </div>
        </Collapse>

        <Collapse in={showAdditionalNotes}>
          <div className={styles.additionalNotesContent}>
            <Divider />

            <FormTextArea
              control={control}
              name="additionalNotes"
              label="Additional notes"
              variant="outlined"
              size="medium"
              rows={5}
            />
          </div>
        </Collapse>
      </div>
    );
  },
);
