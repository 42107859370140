import { Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { useStore } from "effector-react/compat";
import { useCallback, useMemo, memo, useEffect, useState } from "react";
import { Paper, useAlert } from "@chhjpackages/components";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { InfoOutlined } from "@material-ui/icons";
import clsx from "clsx";

import {
  $appointmentStore,
  updateAppointmentFx,
  getAppointmentFx,
  useNotesDialog,
} from "features/appointment";
import {
  $productItems,
  $products,
  deleteProductItem,
  setProductsFx,
  triggerRecalculateProductItems,
} from "features/products";
import { ActionsFooter, BackTitle, ImportantBadge } from "shared/ui";
import { routePaths } from "shared/utils";
import { $developments, getDevelopmentsFx } from "features/developments";
import { $auth } from "features/auth";
import {
  $productUpdates,
  CartFooter,
  setProductUpdate,
  setProductUpdates,
} from "features/cart";
import { TotalTime, TotalTimeSkeleton } from "features/appointment";
import { useSideNavDispatch } from "features/sidenav";
import { useCompletedActions } from "features/completed-actions";
import { CompletedActionsJobEnum } from "shared/types";
import {
  setInitialAddendumAppointment,
  setInitialAddendumProductItems,
  useAddendumProcessData,
} from "entities/addendum";

import { ProductsList } from "./ui";
import { useCartStyles } from "./assets";

export const Cart = memo(() => {
  const styles = useCartStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), { noSsr: true });

  const location = useLocation();
  const { appointmentId } = useParams();
  const navigate = useNavigate();

  const { setActionButton, clearActionButton } = useSideNavDispatch();
  const { deleteCompletedAction } = useCompletedActions();
  const { handleNotesDialogOpen } = useNotesDialog({});
  const { showAlert } = useAlert();
  const {
    originalEstimate,
    newEstimate,
    additionalCost,
    isSameAppointment,
    addendumUpdatedProducts,
  } = useAddendumProcessData();

  const { locationId } = useStore($auth);
  const { products, loading: productsLoading } = useStore($products);
  const { productItems } = useStore($productItems);
  const { appointment, loading: appointmentLoading } =
    useStore($appointmentStore);
  const {
    developmentsAppointmentId,
    timeTravelled,
    billableTimeTravelled,
    timeWorked,
    billableTimeWorked,
    loading: developmentsLoading,
  } = useStore($developments);
  const { updates } = useStore($productUpdates);

  const [isSaving, setIsSaving] = useState(false);
  const [isShowCouponsAndDiscounts, setIsShowCouponsAndDiscounts] =
    useState(true);

  const locationState = useMemo<
    | { cartBack: string; isAddendum?: boolean; isBillOfLading?: boolean }
    | undefined
  >(() => location.state, [location.state]);

  const isAddendum = useMemo(
    () => !!locationState?.isAddendum,
    [locationState?.isAddendum],
  );

  const isBillOfLading = useMemo(
    () => !!locationState?.isBillOfLading,
    [locationState?.isBillOfLading],
  );

  const title = useMemo(() => (isAddendum ? "Addendum" : "Cart"), [isAddendum]);

  const noProducts = useMemo(
    () => !productsLoading && productItems.length === 0,
    [productsLoading, productItems.length],
  );

  const handleSaveAndContinue = useCallback(
    async (continueCallback?: (newProductsLength: number) => void) => {
      if (!appointment?.id || updates.length === 0) {
        if (!!continueCallback) {
          continueCallback(products.length);
        }
        return;
      }

      setIsSaving(true);

      const productsResult = products
        .map((product) => {
          const productInUpdates = updates.find(
            (update) => update.productLineId === product.productLineId,
          );

          if (productInUpdates) {
            return {
              actualPrice: productInUpdates.actualPrice,
              retailPrice: product.retailPrice,
              qty: productInUpdates.quantity,
              salesTaxId: productInUpdates.salesTaxId ?? 0,
              appointment: {
                id: appointment.id,
              },
              product: {
                id: product.productId,
              },
              notes: productInUpdates.notes,
            };
          }

          return {
            actualPrice: product.actualPrice,
            retailPrice: product.retailPrice,
            qty: product.quantity,
            salesTaxId: product.salesTaxId ?? 0,
            appointment: {
              id: appointment.id,
            },
            product: {
              id: product.productId,
            },
            notes: product.notes,
          };
        })
        .filter((product) => product.qty > 0);

      try {
        await setProductsFx({
          locationId: appointment.location.id,
          appointmentId: appointment.id,
          payload: {
            products: productsResult,
          },
        });
      } catch {
        showAlert("Error! Failed to update products. Try again later.", {
          variant: "error",
        });

        setIsSaving(false);

        return;
      }

      if (productsResult.length === 0) {
        await deleteCompletedAction(
          appointment.id,
          appointment.location.id,
          CompletedActionsJobEnum.AddProducts,
        );
      }

      let isUpdateAppointmentSuccess = true;
      try {
        await updateAppointmentFx({
          locationId: appointment.location.id,
          appointmentId: appointment.id,
        });

        showAlert("Success! Products have been updated.", {
          variant: "success",
        });
      } catch {
        showAlert("Success! Products have been updated.", {
          variant: "success",
        });

        showAlert("Error! Failed to update appointment.", {
          variant: "error",
        });

        isUpdateAppointmentSuccess = false;
      }

      setProductUpdates([]);
      setIsSaving(false);

      if (isUpdateAppointmentSuccess) {
        if (!!continueCallback) {
          continueCallback(productsResult.length);
        }
      } else {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
    },
    [
      appointment?.id,
      appointment?.location.id,
      updates,
      products,
      deleteCompletedAction,
      showAlert,
    ],
  );

  const handleBack = useCallback(() => {
    if (isBillOfLading && products.length === 0) {
      navigate(routePaths.jobDetailsBillOfLading(Number(appointmentId)));
    } else if (locationState?.cartBack) {
      navigate(
        isBillOfLading && products.length > 0
          ? routePaths.jobDetails(Number(appointmentId))
          : locationState.cartBack,
        {
          state: { ...(locationState ?? {}) },
        },
      );
    } else {
      navigate(
        {
          pathname: routePaths.jobDetailsAddProduct(Number(appointmentId)),
        },
        {
          state: locationState,
        },
      );
    }
  }, [appointmentId, isBillOfLading, products.length, locationState, navigate]);

  const handleAddProduct = useCallback(
    (shouldSave?: boolean) => {
      if (shouldSave && updates.length !== 0) {
        handleSaveAndContinue();
      } else {
        navigate(routePaths.jobDetailsAddProduct(Number(appointmentId)), {
          state: {
            ...(locationState ?? {}),
            addProductBack: location.pathname,
          },
        });
      }
    },
    [
      appointmentId,
      locationState,
      location.pathname,
      updates.length,
      handleSaveAndContinue,
      navigate,
    ],
  );

  const handleContinueToPayment = useCallback(() => {
    navigate(routePaths.jobDetailsPayments(Number(appointmentId)));
  }, [appointmentId, navigate]);

  const handleContinueToAddendum = useCallback(() => {
    navigate(routePaths.jobDetailsAddendum(Number(appointmentId)), {
      state: {
        ...locationState,
        estimateOld: originalEstimate,
        estimateNew: newEstimate,
        updateProducts: addendumUpdatedProducts,
      },
    });
  }, [
    appointmentId,
    originalEstimate,
    newEstimate,
    addendumUpdatedProducts,
    locationState,
    navigate,
  ]);

  const handleBackToAppointment = useCallback(
    () => navigate(routePaths.jobDetails(Number(appointmentId))),
    [appointmentId, navigate],
  );

  const handleAddCoupounsAndDiscounts = useCallback(
    () =>
      navigate(routePaths.jobDetailsCoupons(Number(appointmentId)), {
        state: locationState,
      }),
    [appointmentId, locationState, navigate],
  );

  const handleEditDiscount = useCallback(
    () =>
      navigate(routePaths.jobDetailsDiscounts(Number(appointmentId)), {
        state: locationState,
      }),
    [appointmentId, locationState, navigate],
  );

  const handleClickTotalPrice = useCallback(() => {
    setIsShowCouponsAndDiscounts((prev) => !prev);
  }, []);

  const handleContinueToSignature = useCallback(
    () =>
      handleSaveAndContinue((newProductsLength) => {
        if (!updates.length) {
          navigate(
            routePaths.jobDetailsbillOfLadingSignature(Number(appointmentId)),
            {
              state: {
                ...(locationState ?? {}),
                signatureBack:
                  newProductsLength > 0 ? location.pathname : undefined,
              },
            },
          );
        }
      }),
    [
      appointmentId,
      location.pathname,
      locationState,
      updates.length,
      handleSaveAndContinue,
      navigate,
    ],
  );

  const handleEditProduct = useCallback(
    (productLineId: number) =>
      navigate(
        routePaths.jobDetailsCartEdit(Number(appointmentId), productLineId),
        { replace: true, state: locationState },
      ),
    [appointmentId, locationState, navigate],
  );

  const handleDeleteProduct = useCallback(
    async (productLineId: number) => {
      const deletingProduct = productItems.find(
        (product) => product.productLineId === productLineId,
      );

      if (deletingProduct) {
        setProductUpdate({
          product: { ...deletingProduct, quantity: 0 },
          ignoreDelete: true,
        });

        deleteProductItem({ productLineId: deletingProduct.productLineId });
      }
    },
    [productItems],
  );

  useEffect(() => {
    if (Number(appointmentId) !== developmentsAppointmentId && locationId) {
      getDevelopmentsFx({
        locationId: locationId,
        appointmentId: Number(appointmentId),
      });
    }
  }, [appointmentId, developmentsAppointmentId, locationId]);

  useEffect(() => {
    if (Number(appointmentId) !== appointment?.id && locationId) {
      getAppointmentFx({
        locationId: locationId,
        appointmentId: Number(appointmentId),
      });
    }
  }, [appointment?.id, locationId, appointmentId]);

  useEffect(() => {
    setActionButton({
      name: "Notes",
      icon: <InfoOutlined color="inherit" fontSize="small" />,
      disabled: appointmentLoading,
      onClick: () => handleNotesDialogOpen(),
    });

    return () => {
      clearActionButton();
    };
  }, [
    appointmentLoading,
    clearActionButton,
    handleNotesDialogOpen,
    setActionButton,
  ]);

  useEffect(() => {
    if (isAddendum && !isSameAppointment && appointment) {
      setInitialAddendumAppointment(appointment);
      setInitialAddendumProductItems(productItems);
    }
  }, [isAddendum, isSameAppointment, appointment?.id]);

  useEffect(() => {
    return () => {
      setProductUpdates([]);
      triggerRecalculateProductItems();
    };
  }, []);

  return (
    <div className={styles.root}>
      <BackTitle
        title={title}
        actionButtons={[
          {
            name: "Add product",
            onClick: () => handleAddProduct(),
          },
          ...(!isMobile
            ? [
                {
                  name: "Notes",
                  icon: <InfoOutlined color="inherit" fontSize="small" />,
                  disabled: appointmentLoading,
                  onClick: handleNotesDialogOpen,
                },
              ]
            : []),
        ]}
        onBack={handleBack}
      />

      <div className={styles.content}>
        {isBillOfLading && productItems.length > 0 && (
          <ImportantBadge type="info" borderRadius={0} p={2}>
            <Typography variant="body1" className={styles.bolText}>
              To pre-fill items from the associated quote, please remove all
              items from the cart and access the bill of lading again.
            </Typography>
          </ImportantBadge>
        )}

        {!isBillOfLading && (
          <Paper elevation={16}>
            <div className={styles.totalTimeContainer}>
              <Typography variant="h4" color="inherit">
                Total time
              </Typography>

              {developmentsLoading ? (
                <TotalTimeSkeleton />
              ) : (
                <TotalTime
                  timeTravelled={timeTravelled}
                  billableTimeTravelled={billableTimeTravelled}
                  timeWorked={timeWorked}
                  billableTimeWorked={billableTimeWorked}
                />
              )}
            </div>
          </Paper>
        )}

        <div
          className={clsx(
            styles.productsListWrapper,
            noProducts && styles.productsListNoProductsWrapper,
          )}
        >
          <ProductsList
            products={productItems}
            loading={productsLoading}
            disable={isSaving}
            onEdit={handleEditProduct}
            onDelete={handleDeleteProduct}
          />
        </div>
      </div>

      <ActionsFooter
        show={!!appointment}
        actions={[
          {
            label:
              isSaving || (noProducts && updates.length > 0)
                ? "Save"
                : "Add a product",
            buttonType: "filled",
            hide: !noProducts || isBillOfLading || isAddendum,
            isLoading: isSaving,
            onClick: () => handleAddProduct(true),
          },
          {
            label: "Add discounts & coupons",
            buttonType: "text",
            color: "primary",
            hide: noProducts || isAddendum,
            hideCollapse: !isShowCouponsAndDiscounts,
            onClick: handleAddCoupounsAndDiscounts,
          },
          {
            label: !!updates.length
              ? "Save"
              : isAddendum
              ? "Continue to addendum"
              : "Continue to payment",
            buttonType: "filled",
            isLoading: isSaving,
            disabled:
              isAddendum &&
              additionalCost === 0 &&
              addendumUpdatedProducts.length === 0 &&
              updates.length === 0,
            hide: (isBillOfLading || noProducts) && !isAddendum,
            onClick: !!updates.length
              ? () => handleSaveAndContinue()
              : isAddendum
              ? handleContinueToAddendum
              : handleContinueToPayment,
          },
          {
            label: "Back to appointment",
            buttonType: "text",
            hide: noProducts || isBillOfLading,
            onClick: handleBackToAppointment,
          },
          {
            label: !!updates.length ? "Save" : "Continue to crew signature",
            buttonType: "filled",
            isLoading: isSaving,
            hide: !isBillOfLading,
            onClick: handleContinueToSignature,
          },
        ]}
        topAdditionalContent={
          (!noProducts || isAddendum) && (
            <CartFooter
              couponTotal={appointment?.couponTotal ?? 0}
              discountTotal={appointment?.discountTotal ?? 0}
              productTotal={appointment?.productTotal ?? 0}
              totalPrice={
                !isAddendum ? appointment?.subTotal ?? 0 : additionalCost
              }
              isAddendum={isAddendum}
              onEditCoupon={handleAddCoupounsAndDiscounts}
              onEditDiscount={handleEditDiscount}
              onClickTotalPrice={handleClickTotalPrice}
            />
          )
        }
      />

      <Outlet />
    </div>
  );
});
