import { ChangeEvent, memo, useState } from "react";
import { Box } from "@material-ui/core";
import { Paper } from "@chhjpackages/components";

import { ArrowTopIcon } from "shared/assets";

import {
  AccordionDetailsStyled,
  AccordionStyled,
  AccordionSummaryStyled,
  useStyles,
} from "./styles";
import { AccordionProps } from "./types";

export const Accordion = memo(
  ({
    disabled,
    header,
    paperElevation,
    children,
    hideToggle,
    expandIconColor,
    isOpen,
    changeExpand,
  }: AccordionProps) => {
    const styles = useStyles();

    const [isExpanded, setExpanded] = useState(false);

    const handleChange = (event: ChangeEvent<{}>, expanded: boolean) => {
      setExpanded(expanded);
    };

    return (
      <Paper
        variant={paperElevation ? "elevation" : "outlined"}
        elevation={paperElevation}
        style={{ marginBottom: "16px" }}
      >
        <AccordionStyled
          expanded={isOpen ? isOpen : isExpanded}
          disabled={disabled}
          onChange={changeExpand ? changeExpand : handleChange}
        >
          <AccordionSummaryStyled
            expandIcon={
              !hideToggle && (
                <ArrowTopIcon
                  color={expandIconColor}
                  className={styles.arrowIcon}
                />
              )
            }
            aria-controls="accordion-content"
            id="accordion-summary"
          >
            {header}
          </AccordionSummaryStyled>

          <AccordionDetailsStyled>
            {(isOpen ? isOpen : isExpanded) && (
              <Box width="100%">{children}</Box>
            )}
          </AccordionDetailsStyled>
        </AccordionStyled>
      </Paper>
    );
  },
);
