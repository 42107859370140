import { createEffect, createStore } from "effector/compat";

import { ValuationPlan } from "../types";
import { getValuationPlans } from "../api";

export const $valuationPlans = createStore<{
  valuationPlans: ValuationPlan[];
  appointmentId: number | null;
  loading: boolean;
}>({
  valuationPlans: [],
  appointmentId: null,
  loading: true,
});

export const getValuationPlansFx = createEffect(getValuationPlans);
$valuationPlans
  .on(getValuationPlansFx.done, (store, response) => ({
    ...store,
    valuationPlans: response.result.data.valuationPlans,
    appointmentId: response.params.appointmentId,
  }))
  .on(getValuationPlansFx.fail, (store) => ({
    ...store,
    valuationPlans: [],
    appointmentId: null,
  }))
  .on(getValuationPlansFx.pending, (store, loading) => ({
    ...store,
    loading,
  }));
