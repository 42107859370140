import { createStore, createEvent } from "effector";

export const stepsCompletedCount = createEvent<number>();

const addToSteps = (steps: number[], newSlideIndex: number): number[] => [
  ...steps,
  newSlideIndex,
];

export const $perfectJobsStore = createStore<{
  stepsCompleted: number[];
}>({
  stepsCompleted: [],
});

$perfectJobsStore.on(stepsCompletedCount, (store, newStep) => ({
  ...store,
  stepsCompleted: addToSteps(store.stepsCompleted, newStep),
}));
