import { getCompletedAction } from "features/completed-actions";
import { Requirement } from "features/requirements";
import { Appointments } from "features/appointment/model";
import { getBinderPresentationStatus } from "features/training-details";
import {
  CategoryIdEnum,
  CompletedAction,
  CompletedActionsJobEnum,
  StepStatusesEnum,
} from "shared/types";
import { routePaths } from "shared/utils";
import { StepTwoLinksTypesEnum } from "../types";

export const getStepTwoLinks = (
  completedActions: CompletedAction[],
  requirements: Requirement[],
  isNasa: boolean,
  appointment: Appointments | null,
  preJobStatus: boolean,
  postJobStatus: boolean,
  billOfLadingDenied: boolean,
  currentPath: string,
): {
  id: number;
  name: string;
  isChecked: boolean;
  type: StepTwoLinksTypesEnum;
  path: (id: number) => string;
  pathState?: unknown;
}[] => {
  const shouldShowBillOfLading =
    !billOfLadingDenied &&
    appointment?.type !== "EST" &&
    (appointment?.category.id === CategoryIdEnum.Moving ||
      appointment?.category.id === CategoryIdEnum.MoveLabor);
  const shouldShowLiabilityWaiver = shouldShowBillOfLading;
  const shouldShowValuation = shouldShowBillOfLading;
  const shouldShowPreExistingDamage = shouldShowBillOfLading;
  const shouldShowAddendum = shouldShowBillOfLading;
  const shouldShowBinderPresentation =
    appointment?.category.id === CategoryIdEnum.JunkRemoval;
  const shouldShowNasaRequirements = isNasa && requirements.length > 0;

  const isLiabilityWaiverCompleted = !!getCompletedAction(
    completedActions,
    CompletedActionsJobEnum.FinalizeLiabilityWaiver,
  );
  const isValuationCompleted = !!getCompletedAction(
    completedActions,
    CompletedActionsJobEnum.FinalizeValuation,
  );
  const isBillOfLadingCompleted = !!getCompletedAction(
    completedActions,
    CompletedActionsJobEnum.FinalizeBilling,
  );
  const isPreExistingDamageCompleted = !!getCompletedAction(
    completedActions,
    CompletedActionsJobEnum.FinalizePreExistingDamage,
  );
  const isBinderPresentationCompleted = getBinderPresentationStatus(
    appointment?.id ?? 0,
  );
  const isPreJobInspectionCompleted = !!getCompletedAction(
    completedActions,
    CompletedActionsJobEnum.PreJobInspection,
  );
  const isAddendumCompleted = !!getCompletedAction(
    completedActions,
    CompletedActionsJobEnum.FinalizeAddendum,
  );
  const isPostJobInspectionCompleted = !!getCompletedAction(
    completedActions,
    CompletedActionsJobEnum.PostJobInspection,
  );
  const isNasaRequirementsCompleted = !!!requirements.find(
    (requirement) =>
      requirement.status === StepStatusesEnum.ToBeDone ||
      requirement.status === StepStatusesEnum.InProgress,
  );

  return [
    ...(shouldShowLiabilityWaiver
      ? [
          {
            id: 1,
            name: "Liability waiver",
            isChecked: isLiabilityWaiverCompleted,
            type: StepTwoLinksTypesEnum.BeforeWork,
            path: (id: number) =>
              isLiabilityWaiverCompleted
                ? routePaths.jobDetailsLiabilityWaiverSignature(id)
                : routePaths.jobDetailsLiabilityWaiver(id),
          },
        ]
      : []),
    ...(shouldShowValuation
      ? [
          {
            id: 2,
            name: "Valuation form",
            isChecked: isValuationCompleted,
            type: StepTwoLinksTypesEnum.BeforeWork,
            path: (id: number) =>
              isValuationCompleted
                ? routePaths.jobDetailsValuationSignature(id)
                : routePaths.jobDetailsValuation(id),
          },
        ]
      : []),
    ...(shouldShowBillOfLading
      ? [
          {
            id: 3,
            name: "Bill of lading",
            isChecked: isBillOfLadingCompleted,
            type: StepTwoLinksTypesEnum.BeforeWork,
            path: (id: number) =>
              isBillOfLadingCompleted
                ? routePaths.jobDetailsbillOfLadingSignature(id)
                : appointment.products.length > 0
                ? routePaths.jobDetailsCart(id)
                : routePaths.jobDetailsBillOfLading(id),
            pathState:
              appointment.products.length > 0
                ? {
                    cartBack: currentPath,
                    isBillOfLading: true,
                  }
                : undefined,
          },
        ]
      : []),
    ...(shouldShowPreExistingDamage
      ? [
          {
            id: 4,
            name: "Pre-existing damage",
            isChecked: isPreExistingDamageCompleted,
            type: StepTwoLinksTypesEnum.BeforeWork,
            path: (id: number) =>
              isPreExistingDamageCompleted
                ? routePaths.jobDetailsPreExistingDamageSignature(id)
                : routePaths.jobDetailsPreExistingDamage(id),
          },
        ]
      : []),
    ...(shouldShowBinderPresentation
      ? [
          {
            id: 5,
            name: "Binder presentation",
            isChecked: isBinderPresentationCompleted,
            type: StepTwoLinksTypesEnum.BeforeWork,
            path: () =>
              routePaths.trainingBinderPresentation() +
              "?appointmentId=" +
              appointment.id,
          },
        ]
      : []),
    ...(preJobStatus
      ? [
          {
            id: 6,
            name: "Pre-job inspection",
            isChecked: isPreJobInspectionCompleted,
            type: StepTwoLinksTypesEnum.BeforeWork,
            path: (id: number) =>
              `${routePaths.jobInspectionTerms(id)}?type=pre`,
          },
        ]
      : []),
    ...(shouldShowAddendum
      ? [
          {
            id: 7,
            name: "Addendum",
            isChecked: isAddendumCompleted,
            type: StepTwoLinksTypesEnum.DuringJob,
            path: (id: number) =>
              isAddendumCompleted
                ? routePaths.jobDetailsAddendumSignature(id)
                : routePaths.jobDetailsAddProduct(id),
            pathState: {
              isAddendum: true,
            },
          },
        ]
      : []),
    ...(postJobStatus
      ? [
          {
            id: 8,
            name: "Post-job inspection",
            isChecked: isPostJobInspectionCompleted,
            type: StepTwoLinksTypesEnum.AfterJob,
            path: (id: number) =>
              `${routePaths.jobInspectionTerms(id)}?type=post`,
          },
        ]
      : []),
    ...(shouldShowNasaRequirements
      ? [
          {
            id: 9,
            name: "NASA requirements",
            isChecked: isNasaRequirementsCompleted,
            type: StepTwoLinksTypesEnum.None,
            path: (id: number) => routePaths.nasaRequirements(id),
          },
        ]
      : []),
  ];
};
