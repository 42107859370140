import { createEffect, createStore } from "effector";

import { updateAccount } from "../api/account";
import { Account } from "../types/accounts";

export const updateAccountFx = createEffect(updateAccount);

export const $accountStore = createStore<{
  account: Account | null;
  loading: boolean;
  updating: boolean;
}>({
  account: null,
  loading: true,
  updating: false,
});
$accountStore
  .on(updateAccountFx.doneData, (store, response) => ({
    ...store,
    account: response.data.account[0],
  }))
  .on(updateAccountFx.pending, (store, loading) => ({
    ...store,
    updating: loading,
  }));
