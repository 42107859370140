import { fetcher } from "shared/libs";

import { Notifications } from "./types";

export const getNotifications = ({
  locationId,
  userId,
}: Notifications.GetAll) =>
  fetcher.get<Notifications.Response>(
    `/locations/${locationId}/users/${userId}/app/notifications`,
  );

export const updateNotification = ({
  locationId,
  userId,
  notificationId,
  payload,
}: Notifications.Update) =>
  fetcher.put<Notifications.Response>(
    `/locations/${locationId}/users/${userId}/app/notifications/${notificationId}`,
    payload,
  );
