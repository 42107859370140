import { ClickAwayListener, Collapse, Typography } from "@material-ui/core";
import { memo, useCallback, useState } from "react";
import clsx from "clsx";

import { numberToCurrency, numberWithCommas } from "shared/utils";

import { CartFooterProps } from "./model";
import { EditingItem } from "./ui";
import { useStyles } from "./assets";

export const CartFooter = memo(
  ({
    couponTotal,
    discountTotal,
    productTotal,
    totalPrice,
    isAddendum,
    onEditCoupon,
    onEditDiscount,
    onClickTotalPrice,
  }: CartFooterProps) => {
    const styles = useStyles();

    const [
      isShowCouponsAndDiscountsSection,
      setIsShowCouponsAndDiscountsSection,
    ] = useState(false);

    const handleOnClickTotalPrice = useCallback(() => {
      setIsShowCouponsAndDiscountsSection((prev) => !prev);
      onClickTotalPrice();
    }, [onClickTotalPrice]);

    return (
      <div className={styles.root}>
        <ClickAwayListener
          onClickAway={() =>
            isShowCouponsAndDiscountsSection && handleOnClickTotalPrice()
          }
        >
          <Collapse in={isShowCouponsAndDiscountsSection}>
            <div className={styles.additionalContentContainer}>
              <div className={styles.productTotalContainer}>
                <Typography variant="h4">Product total:</Typography>

                <Typography variant="h4">
                  <b>${(productTotal || 0).toFixed(2)}</b>
                </Typography>
              </div>

              <div className={styles.editingItemsList}>
                <EditingItem
                  title="Discounts"
                  value={numberToCurrency(numberWithCommas(discountTotal, 2))}
                  onEdit={onEditDiscount}
                />

                <EditingItem
                  title="Coupons"
                  value={numberToCurrency(numberWithCommas(couponTotal, 2))}
                  onEdit={onEditCoupon}
                />
              </div>
            </div>
          </Collapse>
        </ClickAwayListener>

        <div className={styles.totalPriceContainer}>
          <Typography variant="body1" className={styles.totalPriceTitle}>
            {isAddendum ? "Additional cost:" : "Total price:"}
          </Typography>

          <Typography
            variant="body1"
            color="secondary"
            component="div"
            className={clsx(styles.totalPrice, !isAddendum && "cursorPointer")}
            onClick={(e) =>
              !isAddendum &&
              ((event) => {
                event.stopPropagation();
                handleOnClickTotalPrice();
              })(e)
            }
          >
            {numberToCurrency(numberWithCommas(totalPrice, 2))}
          </Typography>
        </div>
      </div>
    );
  },
);
