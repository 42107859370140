import { memo, useState, useEffect } from "react";
import { Box, Typography, Avatar, Badge } from "@material-ui/core";
import {
  colors,
  getComplementaryColorByString,
} from "@chhjpackages/components";

import {
  salesData,
  averageData,
  completionPercentData,
  salesPerHourData,
  Data,
} from "./model";
import { useStyles, SmallAvatar } from "./assets";

export const Ranking = memo(({ ranks, rankType }: Data) => {
  const styles = useStyles();
  const [rankData, setRankData] = useState<[]>([]);

  useEffect(() => {
    switch (rankType) {
      case "sales":
        if (ranks.sales) {
          setRankData(salesData(ranks));
        }
        break;
      case "completionPercent":
        setRankData(completionPercentData(ranks));
        break;
      case "average":
        setRankData(averageData(ranks));
        break;
      case "salesPerHour":
        setRankData(salesPerHourData(ranks));
        break;

      default:
        break;
    }
  }, [ranks, rankType]);

  return (
    <Box>
      {rankData?.length
        ? rankData?.map((item: any, itemIndex: number) => {
            return (
              <div key={item.username}>
                <table className={styles.mainTable}>
                  <tr>
                    <td className={styles.tdAvatar}>
                      <Badge
                        overlap="circle"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        className={
                          itemIndex === 0
                            ? styles.mainBadge1
                            : itemIndex === 1
                            ? styles.mainBadge2
                            : styles.mainBadge3
                        }
                        badgeContent={
                          <SmallAvatar
                            alt="Rank"
                            src="1"
                            className={
                              itemIndex === 0
                                ? styles.badgeStyle1
                                : itemIndex === 1
                                ? styles.badgeStyle2
                                : styles.badgeStyle3
                            }
                          >
                            <Box
                              color={
                                itemIndex === 1
                                  ? colors.basic.black
                                  : colors.basic.white
                              }
                            >
                              <Typography variant="h5" color="inherit">
                                {itemIndex + 1}
                              </Typography>
                            </Box>
                          </SmallAvatar>
                        }
                      >
                        <Avatar
                          style={{
                            backgroundColor: item.userProfileUrl
                              ? ""
                              : `${getComplementaryColorByString(
                                  item.username,
                                )}`,
                          }}
                          alt="Profile Image"
                          src={item.userProfileUrl ? item.userProfileUrl : ""}
                        >
                          <Typography style={{ color: "white" }} variant="h3">
                            {item.username.charAt(0)}
                          </Typography>
                        </Avatar>
                      </Badge>
                    </td>
                    <td className={styles.tdName}>
                      <Typography style={{ fontFamily: "Noah" }} variant="h6">
                        {item.username}
                      </Typography>
                    </td>
                    <td className={styles.tdValue}>
                      <Typography variant="caption">
                        {rankType === "completionPercent"
                          ? `${item.jobValue}%`
                          : item.jobValue}
                      </Typography>
                    </td>
                  </tr>
                </table>
              </div>
            );
          })
        : null}
    </Box>
  );
});
