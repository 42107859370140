import { Button, colors, Paper } from "@chhjpackages/components";
import { IconButton, Typography } from "@material-ui/core";
import { memo } from "react";
import clsx from "clsx";

import { ArrowBackIcon } from "shared/assets";

import { BackTitleProps } from "./types";
import { useStyles } from "./styles";

export const BackTitle = memo(
  ({
    title,
    content,
    actionButtons,
    elevation,
    classes,
    children,
    onBack,
  }: BackTitleProps) => {
    const styles = useStyles();

    return (
      <Paper variant="elevation" elevation={elevation} className={styles.root}>
        <div
          className={clsx(styles.controlPanelRoot, classes?.controlPanelRoot)}
        >
          <div className={styles.titleSection}>
            <IconButton
              className={styles.backButton}
              classes={{ root: styles.backButtonRoot }}
              onClick={onBack}
            >
              <ArrowBackIcon color={colors.black} fontSize={17} />
            </IconButton>

            {title && (
              <Typography
                variant="h3"
                className={clsx(styles.title, classes?.title)}
              >
                {title}
              </Typography>
            )}
          </div>

          <div
            className={clsx(
              styles.controlPanelActionsContainer,
              classes?.controlPanelActionsContainer,
            )}
          >
            {content && (
              <div className={classes?.controlPanelContentContainer}>
                {content}
              </div>
            )}

            {actionButtons?.map((actionButton, i) => (
              <Button
                key={i}
                name={actionButton?.name}
                type={actionButton?.type}
                onClick={actionButton?.onClick}
                disabled={actionButton?.disabled}
                isLoading={actionButton?.loading}
                buttonType="text"
                color="primary"
                className={styles.actionButton}
                classes={{
                  root: styles.actionButtonRoot,
                }}
              >
                <div className={styles.actionButtonContent}>
                  {actionButton.icon && actionButton.icon}

                  {actionButton.name}
                </div>
              </Button>
            ))}
          </div>
        </div>

        {children && <div className={styles.childrenContainer}>{children}</div>}
      </Paper>
    );
  },
);
