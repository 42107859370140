import { colors } from "@chhjpackages/components";
import { Typography } from "@material-ui/core";
import { memo, useMemo } from "react";

import { FormIncrementInputStyled } from "./assets";
import { BillOfLadingSectionProps } from "./model";
import { ItemCard, List } from "./ui";

export const AdditionalProductsSection = memo(
  ({ control, watch }: Pick<BillOfLadingSectionProps, "control" | "watch">) => {
    const additionalProductsWatched = watch("additionalProducts");

    const filteredAdditionalProducts = useMemo(
      () => additionalProductsWatched.filter((product) => !product.hidden),
      [additionalProductsWatched],
    );

    return (
      <>
        {filteredAdditionalProducts.length > 0 && (
          <List>
            {additionalProductsWatched.map(
              (product, i) =>
                !product.hidden && (
                  <ItemCard
                    title={product.name}
                    controlElement={
                      <FormIncrementInputStyled
                        control={control}
                        name={`additionalProducts[${i}].quantity`}
                        min={0}
                        mask="positive-two-decimal"
                      />
                    }
                  />
                ),
            )}
          </List>
        )}
        {filteredAdditionalProducts.length === 0 && (
          <div style={{ color: colors.grey140, marginTop: 16 }}>
            <Typography variant="body2" color="inherit">
              There are no additional products from quote
            </Typography>
          </div>
        )}
      </>
    );
  },
);
