import { memo, useCallback, useMemo } from "react";
import {
  Paper,
  useAlert,
  colors,
  MessageBadge,
  WarningIcon,
} from "@chhjpackages/components";

import { LinkSuccessSend, requestResetPasswordLink } from "features/auth";
import { routePaths } from "shared/utils";
import { BackButton } from "shared/ui";

import { useStyles } from "./assets";
import { useLoginNavigate } from "./hooks";

export const ResetPasswordLinkSuccess = memo(() => {
  const styles = useStyles();

  const { showAlert } = useAlert();
  const { isExpiredType, loginNavigate } = useLoginNavigate();

  const resetLinkData = useMemo(
    () => localStorage.getItem("crew/resetLink"),
    [],
  );

  const handleRepeatRequestPasswordLink = useCallback(async () => {
    if (resetLinkData)
      try {
        const payload = JSON.parse(resetLinkData);

        await requestResetPasswordLink({ payload });

        showAlert("Success! Reset password link has been sent.", {
          variant: "success",
        });
      } catch {
        showAlert(
          "Error! Failed to send reset password link. \nPlease try again later.",
          {
            variant: "error",
          },
        );
      }
  }, [resetLinkData, showAlert]);

  return (
    <>
      <div className={styles.backButtonContainer}>
        <BackButton
          onClick={() => loginNavigate(routePaths.resetPasswordLink())}
        />
      </div>

      <div className={styles.root}>
        <div className={styles.rootContent}>
          {isExpiredType && (
            <MessageBadge
              type="error"
              message={
                <div className={styles.passwordExpiredMessageRoot}>
                  <WarningIcon
                    color={colors.white}
                    bgColor={colors.functionals.alert}
                    fontSize={20}
                  />
                  Your password has expired. We have sent a link to change your
                  password.
                </div>
              }
            />
          )}

          <Paper elevation={16} className={styles.content}>
            <LinkSuccessSend
              handleResendLink={handleRepeatRequestPasswordLink}
              handleNavigateTo={loginNavigate}
              disableResend={!!!resetLinkData}
            />
          </Paper>
        </div>
      </div>
    </>
  );
});
