import {
  CompletedAction,
  CompletedActionsEstimateEnum,
  CompletedActionsJobEnum,
} from "shared/types";

export const getCompletedAction = (
  completedActions: CompletedAction[],
  typeId: CompletedActionsJobEnum | CompletedActionsEstimateEnum,
) => {
  return completedActions.find(
    (completedAction) => completedAction.type.id === typeId,
  );
};
