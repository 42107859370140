import { Typography } from "@material-ui/core";
import { memo } from "react";

import { PricingItem } from "./pricingItem/PricingItem";
import { ListStyled, useStyles } from "./PricingList.styles";
import { PricingListProps } from "./PricingList.types";

export const PricingList = memo(({ pricingsMap }: PricingListProps) => {
  const styles = useStyles();

  return (
    <div>
      {pricingsMap &&
        Object.keys(pricingsMap).length > 0 &&
        Object.entries(pricingsMap).map(([category, pricings], i) => (
          <div className={styles.categoryContainer} key={i}>
            <Typography variant="h5">{category}</Typography>

            <ListStyled>
              {pricings.map((pricing) => (
                <PricingItem
                  pricingItem={{
                    id: pricing.id,
                    name: pricing.name,
                    price: pricing.price,
                  }}
                  key={pricing.id}
                />
              ))}
            </ListStyled>
          </div>
        ))}
      {!pricingsMap ||
        (Object.keys(pricingsMap).length === 0 && (
          <div className={styles.noPricingContainer}>
            <Typography variant="h6">
              No Pricing Information Available
            </Typography>
          </div>
        ))}
    </div>
  );
});
