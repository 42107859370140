import { fetcher } from "shared/libs";

import {
  CreateTeamProps,
  GetTeams,
  GetTeamsByDate,
  UpdateTeamSchedules,
} from "./types";

export const getTeams = ({ locationId }: GetTeams.Params) =>
  fetcher.get<GetTeams.Response>(`/locations/${locationId}/teams`);

export const getTeamsByDate = ({ locationId, date }: GetTeamsByDate.Params) =>
  fetcher.get<GetTeamsByDate.Response>(`/locations/${locationId}/teams`, {
    params: { date: date },
  });

export const createTeam = ({ locationId, payload }: CreateTeamProps.Params) =>
  fetcher.post<CreateTeamProps.Response>(
    `/locations/${locationId}/teams`,
    payload,
  );

export const updateTeamSchedules = ({
  locationId,
  teamId,
  payload,
}: UpdateTeamSchedules.Params) =>
  fetcher.put<UpdateTeamSchedules.Response>(
    `/locations/${locationId}/teams/${teamId}`,
    payload,
  );
