import { memo, useCallback, useEffect } from "react";
import { useDialogDispatch } from "@chhjpackages/components";
import { useNavigate } from "react-router-dom";
import { useStore } from "effector-react";
import { useParams } from "react-router";

import {
  $appointmentStore,
  markAppointmentCompletedFx,
} from "features/appointment";
import { CompleteAppointmentForm } from "features/appointment/features/complete-appointment-form";
import { routePaths } from "shared/utils";
import { CompletedActionsJobEnum } from "shared/types";
import { useCompletedActions } from "features/completed-actions";
import { DialogContentWrapper } from "shared/ui";

export const CompleteAppointment = memo(() => {
  const { appointmentId } = useParams();
  const navigate = useNavigate();
  const setDialog = useDialogDispatch();
  const { addCompletedAction } = useCompletedActions();

  const { appointment } = useStore($appointmentStore);

  const onCompleteAppointment = useCallback(async () => {
    if (appointment) {
      await markAppointmentCompletedFx({
        locationId: appointment.location.id,
        appointmentId: appointment.id,
        type: appointment.type,
      });

      await addCompletedAction(
        appointment.id,
        appointment.location.id,
        CompletedActionsJobEnum.CompleteJob,
      );
    }
  }, [appointment, addCompletedAction]);

  const onCloseCompleteAppointmentDialog = useCallback(
    () =>
      navigate(routePaths.jobDetails(Number(appointmentId)), { replace: true }),
    [appointmentId, navigate],
  );

  useEffect(() => {
    if (appointment) {
      setDialog({
        open: true,
        variant: "basic",
        title: "Complete appointment",
        disableBackdropClick: true,
        dialogContent: (
          <DialogContentWrapper maxWidth={488}>
            <CompleteAppointmentForm
              onSubmit={onCompleteAppointment}
              onClose={onCloseCompleteAppointmentDialog}
            />
          </DialogContentWrapper>
        ),
        onClose: onCloseCompleteAppointmentDialog,
      });
    }

    return () => setDialog({ open: false });
  }, [
    appointment,
    setDialog,
    onCompleteAppointment,
    onCloseCompleteAppointmentDialog,
  ]);

  return null;
});
