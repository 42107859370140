import { memo } from "react";
import { Skeleton } from "@material-ui/lab";

import { useStyles } from "./styles";

export const DamageCardSkeleton = memo(() => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Skeleton
        variant="rect"
        width="100%"
        height={108}
        style={{ borderRadius: 4 }}
      />

      <Skeleton variant="rect" width="100%" height={41} />

      <Skeleton variant="rect" width="100%" height={41} />
    </div>
  );
});
