import { createEffect, createStore } from "effector/compat";

import { getSquareToken } from "../api";

export const $squareTokens = createStore<{
  token: string | null;
  loading: boolean;
}>({
  token: null,
  loading: true,
});

export const getSquareTokenFx = createEffect(getSquareToken);
$squareTokens
  .on(getSquareTokenFx.doneData, (store, response) => ({
    ...store,
    token: response.data.mobileToken.authorizationCode,
  }))
  .on(getSquareTokenFx.failData, (store) => ({
    ...store,
    token: null,
  }))
  .on(getSquareTokenFx.pending, (store, loading) => ({
    ...store,
    loading,
  }));
