import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  avatarIconButton: {
    padding: "0px",
  },
  inputAvatar: {
    display: "none",
  },
  infoMessage: {
    whiteSpace: "pre-wrap",
    "& > ul": {
      margin: 0,
      padding: "0px 24px",
    },
  },
}));
