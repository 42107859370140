import { Typography } from "@material-ui/core";
import { useState, useEffect, useCallback, useMemo, memo } from "react";
import { Button, colors, Paper } from "@chhjpackages/components";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import clsx from "clsx";

import { PricingList, Slider, StepLink } from "shared/ui";
import { routePaths } from "shared/utils";
import { Pricing } from "shared/types";

import { ProcessBillingProps, returnPrices } from "./model";
import { useStyles } from "./assets";
import { QuickAddCard } from "./ui";

export const ProcessBilling = memo(
  ({
    appointment,
    location: locationStore,
    pricings,
    products,
    isAddingProducts,
    managingProductsDenied,
    onQuickAddProduct,
    onSendItemizedReceipt,
  }: ProcessBillingProps) => {
    const styles = useStyles();

    const location = useLocation();
    const navigate = useNavigate();
    const { appointmentId } = useParams();

    const [slides, setSlides] = useState<Pricing[]>([]);
    const [quickAddPricingsAddingId, setQuickAddPricingsAddingId] = useState<
      number[]
    >([]);

    const prices = useMemo(
      () =>
        returnPrices(appointment, {
          showAdminFee:
            (appointment?.administrativeFee ?? 0) > 0 ||
            (!!appointment?.administrativeFeeEnabled &&
              (locationStore?.adminFeeAmount ?? 0) > 0),
        }),
      [appointment, locationStore?.adminFeeAmount],
    );

    const isAddProductsChecked = useMemo(
      () => !!products.length,
      [products.length],
    );

    const onGoToPayment = useCallback(() => {
      navigate(routePaths.jobDetailsPayments(Number(appointmentId)), {
        state: {
          ...location.state,
          paymentBack: location.pathname,
        },
      });
    }, [appointmentId, location.pathname, location.state, navigate]);

    const handleQuickAddProduct = useCallback(
      async (pricingId: number) => {
        setQuickAddPricingsAddingId((prev) => [...prev, pricingId]);

        await onQuickAddProduct(pricingId);

        if (!isAddingProducts) {
          setQuickAddPricingsAddingId((prev) =>
            prev.filter((id) => id !== pricingId),
          );
        }
      },
      [isAddingProducts, onQuickAddProduct],
    );

    useEffect(() => {
      setSlides(
        pricings ? pricings.filter((pricing) => pricing.isFavorite) : [],
      );
    }, [pricings]);

    return (
      <div>
        {!managingProductsDenied && (
          <>
            <div className={styles.stepLinksList}>
              <StepLink
                path={routePaths.jobDetailsAddProduct(appointment?.id)}
                name="Add products"
                isChecked={isAddProductsChecked}
              />

              <StepLink
                path={routePaths.jobDetailsCart(appointment?.id)}
                pathState={{
                  cartBack: location.pathname,
                }}
                name="View cart & discounts"
                isChecked={isAddProductsChecked}
              />
            </div>

            {slides.length !== 0 && (
              <div className={styles.quickAddContainer}>
                <Typography
                  variant="h5"
                  align="center"
                  style={{ color: colors.grey140 }}
                >
                  Quick add
                </Typography>

                <Slider
                  items={slides}
                  itemWidth={150}
                  numberShowSlides={2}
                  offset={(slideNumber) =>
                    (slideNumber > 0 ? slideNumber : 1) * -10
                  }
                  classes={{ arrowsContainer: styles.quickAddArrowsContainer }}
                  renderElement={({ name, price, id }, slideNumber, index) => (
                    <div className={styles.quickAddCardWrapper}>
                      <Paper
                        elevation={
                          index >= (slideNumber + 1) * 2 - 2 &&
                          index < (slideNumber + 1) * 2
                            ? 16
                            : 0
                        }
                      >
                        <QuickAddCard
                          title={name}
                          subtitle={String(price)}
                          loading={quickAddPricingsAddingId.includes(id)}
                          onClick={() => handleQuickAddProduct(id)}
                        />
                      </Paper>
                    </div>
                  )}
                />
              </div>
            )}
          </>
        )}

        <div
          className={clsx(managingProductsDenied && styles.pricingsListWrapper)}
        >
          <PricingList pricingsMap={prices} />
        </div>

        <div className={styles.buttonContainer}>
          <Button
            buttonType="twoTone"
            size="medium"
            fullWidth
            disabled={(appointment?.balanceDue ?? 0) <= 0}
            onClick={onGoToPayment}
          >
            Go to payment
          </Button>
        </div>

        <div className={styles.buttonContainer}>
          <Button
            buttonType="text"
            color="primary"
            fullWidth
            onClick={onSendItemizedReceipt}
          >
            Send itemized receipt
          </Button>
        </div>
      </div>
    );
  },
);
