import { FC, memo, useMemo } from "react";
import { useController } from "react-hook-form";
import { FormControl } from "@material-ui/core";
import { Input } from "@chhjpackages/components";

import { HelperTxt } from "./styles";
import { FormInputProps } from "./types";

export const FormInput: FC<FormInputProps> = memo(
  ({
    control,
    className,
    name,
    size = "medium",
    variant = "outlined",
    required,
    requiredText,
    ...rest
  }) => {
    const {
      field,
      fieldState: { error },
    } = useController({
      name,
      control,
      defaultValue: undefined,
    });

    const helperText = useMemo(() => {
      if (error) {
        return error.message;
      }

      if (required) {
        return requiredText || "*required";
      }

      return "";
    }, [error, required, requiredText]);

    return (
      <FormControl error={!!error} fullWidth>
        <Input
          {...rest}
          {...field}
          className={className}
          size={size}
          variant={variant}
          required={required}
          error={!!error}
          onBlur={rest.onBlur}
        />

        {helperText && <HelperTxt>{helperText}</HelperTxt>}
      </FormControl>
    );
  },
);
