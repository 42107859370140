import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { memo } from "react";

export const UserProfileFormSkeleton = memo(() => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), {
    noSsr: true,
  });

  return (
    <Box>
      <Box display="flex" justifyContent="center">
        <Skeleton
          variant="circle"
          width={isMobile ? 66 : 100}
          height={isMobile ? 66 : 100}
        />
      </Box>
      <Box mt={2}>
        <Skeleton
          variant="rect"
          width="100%"
          height={55}
          style={{ borderRadius: 4 }}
        />
      </Box>
      <Box mt={3}>
        <Skeleton
          variant="rect"
          width="100%"
          height={55}
          style={{ borderRadius: 4 }}
        />
      </Box>
      <Box mt={3}>
        <Skeleton
          variant="rect"
          width="100%"
          height={55}
          style={{ borderRadius: 4 }}
        />
      </Box>
      <Box mt={3}>
        <Skeleton
          variant="rect"
          width="100%"
          height={55}
          style={{ borderRadius: 4 }}
        />
      </Box>
      <Box mt={3}>
        <Skeleton
          variant="rect"
          width="100%"
          height={93}
          style={{ borderRadius: 4 }}
        />
      </Box>
      <Box mt={3}>
        <Skeleton
          variant="rect"
          width="100%"
          height={51}
          style={{ borderRadius: 4 }}
        />
      </Box>
    </Box>
  );
});
