import { routePaths } from "shared/utils";

export const trainingSection = [
  {
    heading1: "Steps to perfect junk jobs",
    heading2: "Tips to help you provide the best experience",
    route: routePaths.trainingPerfectJob(),
  },
  {
    heading1: "Steps to a perfect move job",
    heading2: "Tips to help you provide the best experience",
    route: routePaths.trainingMoveJob(),
  },
  {
    heading1: "Example binder presentation",
    heading2: "Pricing is not shown, this is for training only",
    route: "",
  },
];
