import { fetcher } from "shared/libs";

import {
  GenerateBillOfLading,
  GetCurrentBillOfLading,
  UpdateBillOfLading,
} from "./types";

export const getCurrentBillOfLading = ({
  locationId,
  appointmentId,
}: GetCurrentBillOfLading.Params) =>
  fetcher.get<GetCurrentBillOfLading.Response>(
    `/locations/${locationId}/appointments/${appointmentId}/bol`,
  );

export const generateBillOfLading = ({
  locationId,
  appointmentId,
}: GenerateBillOfLading.Params) =>
  fetcher.post<GenerateBillOfLading.Response>(
    `/locations/${locationId}/appointments/${appointmentId}/bol`,
  );

export const updateBillOfLading = ({
  locationId,
  appointmentId,
  bolId,
  payload,
}: UpdateBillOfLading.Params) =>
  fetcher.put<UpdateBillOfLading.Response>(
    `/locations/${locationId}/appointments/${appointmentId}/bol/${bolId}`,
    payload,
  );
