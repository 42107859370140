import { colors, NoahFont } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles({
  root: {
    padding: "4px 8px",
    borderRadius: 60,
    backgroundColor: colors.grey10,
    color: colors.grey140,
    fontFamily: NoahFont,
    fontWeight: 700,
    fontSize: 14,
    lineHeight: "17px",
    display: "inline-block",
  },

  successVariant: {
    color: colors.functionals.success,
    backgroundColor: colors.functionals.successLight,
  },
  errorVariant: {
    color: colors.functionals.alert,
    backgroundColor: colors.functionals.alertLight,
  },
  infoVariant: {
    color: colors.functionals.info,
    backgroundColor: colors.functionals.infoLight,
  },
  warningVariant: {
    color: colors.complementary.yellow.shade02,
    backgroundColor: colors.complementary.yellow.shade01,
  },
});
