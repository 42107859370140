import { memo, useCallback, useState } from "react";
import { Grid, Box, IconButton, Typography } from "@material-ui/core";

import { ArrowLeftSliderIcon, ArrowRightSliderIcon } from "shared/assets/icons";
import { Ranking } from "features/leaderboard";

import { RankingCardProps } from "./model";
import { useStyles } from "./assets";

export const RankingCard = memo(({ userMetrics }: RankingCardProps) => {
  const styles = useStyles();

  const [rankingType, setRankingType] = useState(1);
  const [rankingName, setRankingName] = useState("SALES RANKINGS");

  const scrollLeft = useCallback(() => {
    if (rankingType > 1) {
      if (rankingName === "SALES PER HOUR") {
        setRankingName("AVERAGE JOB RANKINGS");
      } else if (rankingName === "AVERAGE JOB RANKINGS") {
        setRankingName("COMPLETION PERCENT RANKINGS");
      } else {
        setRankingName("SALES RANKINGS");
      }
      setRankingType(rankingType - 1);
    }
  }, [rankingName, rankingType]);

  const scrollRight = useCallback(() => {
    if (rankingType < 5) {
      if (rankingName === "SALES RANKINGS") {
        setRankingName("COMPLETION PERCENT RANKINGS");
      } else if (rankingName === "COMPLETION PERCENT RANKINGS") {
        setRankingName("AVERAGE JOB RANKINGS");
      } else {
        setRankingName("SALES PER HOUR");
      }
      if (rankingType < 4) {
        setRankingType(rankingType + 1);
      }
    }
  }, [rankingName, rankingType]);

  return (
    <Grid>
      <Box>
        <Box display="flex" justifyContent="space-between" flexDirection="row">
          <IconButton
            className={rankingType > 1 ? "" : styles.hideArrow}
            size="small"
            onClick={() => scrollLeft()}
          >
            <ArrowLeftSliderIcon />
          </IconButton>
          <Typography variant="h4">{rankingName}</Typography>
          <IconButton
            className={rankingType < 4 ? "" : styles.hideArrow}
            size="small"
            onClick={() => scrollRight()}
          >
            <ArrowRightSliderIcon />
          </IconButton>
        </Box>
        <Box className={styles.mainBox}>
          {rankingType === 1 ? (
            <Ranking ranks={userMetrics} rankType={"sales"} />
          ) : rankingType === 2 ? (
            <Ranking ranks={userMetrics} rankType={"completionPercent"} />
          ) : rankingType === 3 ? (
            <Ranking ranks={userMetrics} rankType={"average"} />
          ) : rankingType === 4 ? (
            <Ranking ranks={userMetrics} rankType={"salesPerHour"} />
          ) : null}
        </Box>
      </Box>
    </Grid>
  );
});
