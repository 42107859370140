import { createStore, createEvent } from "effector/compat";

import { Product } from "shared/types";
import { getProductItems } from "../utils";

import { $products } from "./products";

export const $productItems = createStore<{ productItems: Product[] }>({
  productItems: [],
});

$productItems.on(
  $products.map((store) => store.products),
  (store, productsStore) => ({
    ...store,
    productItems: getProductItems(productsStore),
  }),
);

export const updateProductItem = createEvent<Product>();
$productItems.on(updateProductItem, (store, product) => ({
  ...store,
  productItems: store.productItems.map((item) =>
    item.productLineId === product.productLineId ? product : item,
  ),
}));

export const deleteProductItem = createEvent<{ productLineId: number }>();
$productItems.on(deleteProductItem, (store, { productLineId }) => ({
  ...store,
  productItems: store.productItems.filter(
    (item) => item.productLineId !== productLineId,
  ),
}));

export const triggerRecalculateProductItems = createEvent();
$productItems.on(triggerRecalculateProductItems, (store) => {
  const { products } = $products.getState();

  return {
    ...store,
    productItems: getProductItems(products),
  };
});
