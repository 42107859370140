import { memo, useCallback, useEffect, useMemo } from "react";
import { useDialogDispatch } from "@chhjpackages/components";
import { useNavigate } from "react-router-dom";
import { useStore } from "effector-react";
import { useParams } from "react-router";

import {
  $appointmentsStore,
  $appointmentStore,
  Notes,
} from "features/appointment";
import { routePaths } from "shared/utils";
import { DialogContentWrapper } from "shared/ui";

export const NotesDialog = memo(() => {
  const { appointmentId } = useParams();
  const navigate = useNavigate();
  const setDialog = useDialogDispatch();

  const { appointment } = useStore($appointmentStore);
  const { appointments } = useStore($appointmentsStore);

  const handleCloseNotesDialog = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const selectedAppointment = useMemo(
    () =>
      appointments.length > 0
        ? appointments.find((app) => app.id === Number(appointmentId))
        : appointment,
    [appointments, appointment, appointmentId],
  );

  useEffect(() => {
    if (selectedAppointment) {
      setDialog({
        open: true,
        variant: "basic",
        title: "Notes",
        disableBackdropClick: true,
        dialogContent: (
          <DialogContentWrapper maxWidth={488}>
            <Notes appointment={selectedAppointment} />
          </DialogContentWrapper>
        ),
        onClose: handleCloseNotesDialog,
      });
    } else {
      navigate(routePaths.dashboard());
    }

    return () => setDialog({ open: false });
  }, [setDialog, handleCloseNotesDialog, selectedAppointment, navigate]);

  return null;
});
