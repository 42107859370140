import { fetcher } from "shared/libs";
import { Addendums } from "../types";

export const getCurrentAddendum = ({
  locationId,
  appointmentId,
}: Addendums.GetOne) =>
  fetcher.get<Addendums.Response>(
    `/locations/${locationId}/appointments/${appointmentId}/addendum`,
  );

export const generateAddendum = ({
  locationId,
  appointmentId,
  payload,
}: Addendums.Create) =>
  fetcher.post<Addendums.Response>(
    `/locations/${locationId}/appointments/${appointmentId}/addendum`,
    payload,
  );

export const updateAddendum = ({
  locationId,
  appointmentId,
  addendumId,
  payload,
}: Addendums.Update) =>
  fetcher.put<Addendums.Response>(
    `/locations/${locationId}/appointments/${appointmentId}/addendum/${addendumId}`,
    payload,
  );
