import { memo, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  colors,
  MessageBadge,
  Paper,
  useAlert,
  WarningIcon,
} from "@chhjpackages/components";
import { AxiosError } from "axios";

import { routePaths } from "shared/utils";
import {
  checkRestorePasswordTokenTime,
  restorePassword,
  RestorePasswordForm,
} from "features/auth";
import { BackButton } from "shared/ui";
import { ApiResponseBase } from "shared/types";

import { useLoginNavigate } from "./hooks";
import { useStyles } from "./assets";

export const RestorePassword = memo(() => {
  const styles = useStyles();

  const { userId, resetToken } = useParams();

  const { showAlert } = useAlert();
  const { isExpiredType, loginNavigate } = useLoginNavigate();

  const [tokenTime, setTokenTime] = useState(0);

  const handleSubmitNewPassword = useCallback(
    async ({ password }) => {
      if (userId && resetToken) {
        try {
          const payload = {
            user_id: Number(userId),
            token: resetToken,
            password_unprotected: password,
          };

          await restorePassword({ payload });
          localStorage.removeItem("crew/resetLink");

          loginNavigate(routePaths.restorePasswordSuccess(), {
            ignoreSearch: true,
          });
        } catch (err) {
          const error = err as AxiosError;
          const errorBase = error.response?.data as ApiResponseBase;

          let errorMessage = "";
          if (
            errorBase &&
            errorBase.meta.errors &&
            errorBase.meta.errors.length > 0
          ) {
            errorMessage = errorBase.meta.errors[0].message;
          }

          showAlert(
            `Error! ${errorMessage || "Failed to restore password!"}.`,
            {
              variant: "error",
            },
          );
        }
      }
    },
    [userId, resetToken, loginNavigate, showAlert],
  );

  const [checkingToken, setCheckingToken] = useState(false);

  const getRestorePasswordTokenTime = useCallback(async () => {
    if (!userId || !resetToken) {
      return;
    }

    setCheckingToken(true);

    try {
      const response = await checkRestorePasswordTokenTime({
        payload: { user_id: Number(userId), token: resetToken },
      });

      setTokenTime(response.data.tokens[0].timeout);
    } catch (err) {
      const error = err as AxiosError;
      const errorBase = error.response?.data as ApiResponseBase;

      setTokenTime(0);

      let errorMessage = "";
      if (
        errorBase &&
        errorBase.meta.errors &&
        errorBase.meta.errors.length > 0
      ) {
        errorMessage = errorBase.meta.errors[0].message;
      }

      showAlert(`Error! ${errorMessage || "Failed to verify token"}.`, {
        variant: "error",
        autoHideDuration: null,
      });
    }

    setCheckingToken(false);
  }, [userId, resetToken, showAlert]);

  useEffect(() => {
    getRestorePasswordTokenTime();
  }, [getRestorePasswordTokenTime]);

  return (
    <>
      <div className={styles.backButtonContainer}>
        <BackButton
          onClick={() => loginNavigate(routePaths.resetPasswordLinkSuccess())}
        />
      </div>

      <div className={styles.root}>
        <div className={styles.rootContent}>
          {isExpiredType && (
            <MessageBadge
              type="error"
              message={
                <div className={styles.passwordExpiredMessageRoot}>
                  <WarningIcon
                    color={colors.white}
                    bgColor={colors.functionals.alert}
                    fontSize={20}
                  />
                  Your password has expired. Set a new one to coninue.
                </div>
              }
            />
          )}

          <Paper elevation={16} className={styles.content}>
            <RestorePasswordForm
              tokenTime={tokenTime}
              isTokenLoading={checkingToken}
              onSubmit={handleSubmitNewPassword}
            />
          </Paper>
        </div>
      </div>
    </>
  );
});
