import { colors } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.grey10,
  },
  boxHover: {
    cursor: "pointer",
  },
  sideScroll: {
    overflowX: "hidden",
  },

  sectionBox: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid rgb(236, 239, 243)",
    padding: "1em",
    margin: "0.7em",
    borderRadius: "4px",
    boxShadow: "0px 0px 16px rgba(134, 145, 166, 0.16)",
    backgroundColor: colors.basic.white,
  },
  backBox: {
    marginLeft: "1.2rem",
    fontWeight: 700,
    marginTop: "0.5rem",
  },
  backButton: {
    // width: "10px",
  },
  trainingTextTypography: {
    color: "#4A4A4A",
    variant: "h5",
    fontFamily: "Noah",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "140%",
  },
  tipTextTypography: {
    width: "265px",
    height: "17px",
    left: "calc(50% - 265px/2 - 7px)",
    bottom: "0px",
    fontFamily: "Noah",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "17px",
  },
  gridPosition: {
    position: "sticky",
    // bottom: "-18px"
  },

  presentationButtons: {
    position: "fixed",
    bottom: 12,
    right: 12,
    zIndex: 10,
    display: "flex",
    alignItems: "center",
  },

  presentationNav: {
    height: 32,
    borderRadius: 16,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255, 255, 255, 0.70)",
    backdropFilter: "blur(15px)",
    marginRight: 8,
    padding: 3,
    filter: "drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.35))",
  },

  presentationCounter: {
    marginLeft: 4,
    marginRight: 4,
    width: 30,
  },

  presentationClose: {
    color: colors.functionals.alert,
    width: 32,
    height: 32,
    borderRadius: 16,
    backgroundColor: "rgba(255, 255, 255, 0.70)",
    backdropFilter: "blur(21px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    filter: "drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.35))",
  },

  presentationCloseIconButton: {
    padding: 6,
  },
}));
