import { colors } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  root: {
    border: `1px solid ${colors.grey20}`,
    borderRadius: 4,
    padding: 13,
  },
  noteText: {
    fontWeight: "bold",
    color: "rgba(14, 15, 22, 0.6)",
    fontSize: 16,
    whiteSpace: "pre-wrap",
  },
}));
