import { Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { InfoOutlined } from "@material-ui/icons";
import { useStore } from "effector-react/compat";
import { useAlert } from "@chhjpackages/components";

import { useSideNavDispatch } from "features/sidenav";
import { ActionsFooter, BackTitle } from "shared/ui";
import {
  $appointmentStore,
  getAppointmentFx,
  useNotesDialog,
} from "features/appointment";
import { routePaths } from "shared/utils";
import { $auth } from "features/auth";
import {
  LiablityWaiverFormValues,
  useLiabilityWaiverForm,
} from "features/liability-waiver";

import { useLiabilityWaiverStyles } from "./assets";

export const LiabilityWaiver = memo(() => {
  const styles = useLiabilityWaiverStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), { noSsr: true });

  const { appointmentId } = useParams();
  const navigate = useNavigate();

  const { locationId } = useStore($auth);
  const { appointment, loading: appointmentLoading } =
    useStore($appointmentStore);

  const {
    setPageName,
    setShowGoBackButton,
    setGoToBackUrl,
    setActionButton,
    clearActionButton,
    setElevation,
  } = useSideNavDispatch();

  const { handleNotesDialogOpen } = useNotesDialog({});
  const { showAlert } = useAlert();
  const {
    form: { handleSubmit },
    render: renderLiabilityWaiverForm,
  } = useLiabilityWaiverForm();

  const backPath = useMemo(
    () => routePaths.jobDetails(Number(appointmentId)),
    [appointmentId],
  );

  const handleBack = useCallback(
    () => navigate(backPath),
    [backPath, navigate],
  );

  const onSubmit = useCallback(
    (data: LiablityWaiverFormValues) => {
      if (!Number(appointmentId)) {
        showAlert("Error! Something went wrong. Try again later.", {
          variant: "error",
        });

        return;
      }

      navigate(
        routePaths.jobDetailsLiabilityWaiverSignature(Number(appointmentId)),
        {
          state: {
            additionalText: data.additionalText,
          },
        },
      );
    },
    [appointmentId, showAlert, navigate],
  );

  useEffect(() => {
    if (locationId && appointment?.id !== Number(appointmentId)) {
      getAppointmentFx({
        locationId: locationId,
        appointmentId: Number(appointmentId),
      }).catch(() => {
        showAlert("Error! Failed to get appointment. Try again later.", {
          variant: "error",
        });
      });
    }
  }, [locationId, appointment?.id, appointmentId]);

  useEffect(() => {
    setPageName("Liability waiver");
    setShowGoBackButton(true);
    setGoToBackUrl(backPath);
    setActionButton({
      name: "Notes",
      icon: <InfoOutlined color="inherit" fontSize="small" />,
      disabled: appointmentLoading,
      onClick: () => handleNotesDialogOpen(),
    });
    setElevation(16);

    return () => {
      setPageName("");
      setShowGoBackButton(false);
      setGoToBackUrl("");
      clearActionButton();
      setElevation(0);
    };
  }, [
    backPath,
    appointmentLoading,
    setPageName,
    setShowGoBackButton,
    setGoToBackUrl,
    setActionButton,
    clearActionButton,
    setElevation,
    handleNotesDialogOpen,
  ]);

  return (
    <div className={styles.root}>
      {!isMobile && (
        <BackTitle
          title="Liability waiver"
          elevation={16}
          actionButtons={[
            {
              name: "Notes",
              icon: <InfoOutlined color="inherit" fontSize="small" />,
              disabled: appointmentLoading,
              onClick: handleNotesDialogOpen,
            },
          ]}
          onBack={handleBack}
        />
      )}

      <div className={styles.content}>
        <Typography variant="h4" className={styles.title}>
          Other exclusions to include
        </Typography>

        {renderLiabilityWaiverForm()}
      </div>

      <ActionsFooter
        actions={[
          {
            label: "Continue to customer signature",
            buttonType: "filled",
            onClick: handleSubmit(onSubmit),
          },
        ]}
      />
    </div>
  );
});
