import { createEvent, createStore } from "effector/compat";

import { Appointments } from "features/appointment";
import { Product } from "shared/types";

export const $addendumProcessData = createStore<{
  initialAppointment: Appointments | null;
  initialProductItems: Product[];
}>({
  initialAppointment: null,
  initialProductItems: [],
});

export const setInitialAddendumAppointment = createEvent<Appointments | null>();
$addendumProcessData.on(
  setInitialAddendumAppointment,
  (store, initialAppointment) => ({ ...store, initialAppointment }),
);

export const setInitialAddendumProductItems = createEvent<Product[]>();
$addendumProcessData.on(
  setInitialAddendumProductItems,
  (store, initialProductItems) => ({
    ...store,
    initialProductItems,
  }),
);
