import { fetcher } from "shared/libs";

import { GetPricings } from "./types";

export const getPricings = async ({
  locationId,
  appointmentId,
  postal,
  categoryId,
  jobDate,
}: GetPricings.Params) => {
  const response = await fetcher.get<GetPricings.Response>(
    `/locations/${locationId}/viewpricingbyproduct?postal=${postal}&category_id=${categoryId}&job_date=${jobDate}`,
  );

  return {
    appointmentId,
    ...response,
  };
};
