import { memo, useCallback, useState } from "react";
import { Grid } from "@material-ui/core";
import { Button } from "@chhjpackages/components";
import { Skeleton } from "@material-ui/lab";

import { openMapByType } from "shared/utils";

import { useStyles } from "./styles";
import { MapTypesProps } from "./types";

export const MapTypes = memo(
  ({
    mapType,
    destination,
    isLoading,
    onStartTravel,
    onClose,
  }: MapTypesProps) => {
    const styles = useStyles();

    const [isStartingTravel, setIsStartingTravel] = useState(false);

    const handleNavigateOnly = useCallback(() => {
      openMapByType(mapType, "My Location", destination);
      onClose();
    }, [mapType, destination, onClose]);

    const handleNavigateAndStartTimer = useCallback(async () => {
      if (!onStartTravel) {
        return;
      }

      setIsStartingTravel(true);
      const isSuccess = await onStartTravel();
      setIsStartingTravel(false);

      if (isSuccess) {
        handleNavigateOnly();
      }
    }, [onStartTravel, handleNavigateOnly]);

    return (
      <div>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            {isLoading ? (
              <Skeleton variant="rect" height={51} width="100%" />
            ) : (
              <Button
                buttonType="twoTone"
                size="large"
                fullWidth
                isLoading={isStartingTravel}
                disabled={!onStartTravel && !isStartingTravel}
                onClick={handleNavigateAndStartTimer}
              >
                Navigate and start timer
              </Button>
            )}
          </Grid>

          <Grid item xs={12}>
            {isLoading ? (
              <Skeleton variant="rect" height={51} width="100%" />
            ) : (
              <Button
                buttonType="outlined"
                size="large"
                fullWidth
                onClick={handleNavigateOnly}
              >
                Navigate only
              </Button>
            )}
          </Grid>
        </Grid>

        <div className={styles.actionButtonsContainer}>
          <Button buttonType="text" color="primary" onClick={onClose}>
            Close
          </Button>
        </div>
      </div>
    );
  },
);
