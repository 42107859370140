import { FC, memo, useMemo } from "react";
import { useController } from "react-hook-form";
import { FormControl } from "@material-ui/core";
import { Checkbox } from "@chhjpackages/components";

import { HelperTxt } from "./styles";
import { FormCheckboxProps } from "./types";

export const FormCheckbox: FC<FormCheckboxProps> = memo(
  ({
    control,
    name,
    size = "medium",
    variant = "outline",
    required,
    ...rest
  }) => {
    const {
      field,
      fieldState: { error },
    } = useController({
      name,
      control,
    });

    const helperText = useMemo(() => {
      if (error) {
        return error.message;
      }

      if (required) {
        return "*required";
      }

      return "";
    }, [error, required]);

    return (
      <FormControl error={!!error} fullWidth>
        <Checkbox
          {...rest}
          {...field}
          checked={field.value}
          size={size}
          variant={variant}
          required={required}
        />

        {required && <HelperTxt>{helperText}</HelperTxt>}
      </FormControl>
    );
  },
);
