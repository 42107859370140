import { AppointmentScheduleResponseData } from "features/appointment";
import { HunkTeam } from "features/teams";

export const createChipText = (
  schedules: AppointmentScheduleResponseData[],
  allTeams: HunkTeam[],
) => {
  const teamsIds = schedules.flatMap((schedule) =>
    schedule.team.name !== "" ? schedule.team.id : [],
  );
  const teamsUsers: string[] = [];
  let chipText = "";

  allTeams.forEach((team) => {
    if (teamsIds.includes(team.id)) {
      team.users.forEach((user) => {
        teamsUsers.push(`${user.firstName} ${user.lastName.slice(0, 1)}.`);
      });
    }
  });

  if (teamsUsers) {
    if (teamsUsers[0]) {
      chipText += teamsUsers[0];
    }
    if (teamsUsers[1]) {
      chipText += `, ${teamsUsers[1]}`;
    }
    if (teamsUsers[2]) {
      chipText += `+${teamsUsers.length - 2}`;
    }
  }

  return chipText;
};
