import { SvgIcon } from "@material-ui/core";
import { colors, SvgIconProps } from "@chhjpackages/components";

export const ListViewIcon = ({
  className,
  color = colors.grey140,
  fontSize = 14,
}: SvgIconProps) => (
  <SvgIcon
    className={className}
    style={{ fontSize }}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <rect width="4.70588" height="4.70588" rx="1" fill={color} />
    <rect x="7.05884" width="12.9412" height="4.70588" rx="1" fill={color} />
    <rect y="14.1174" width="4.70588" height="4.70588" rx="1" fill={color} />
    <rect
      x="7.05884"
      y="14.1174"
      width="12.9412"
      height="4.70588"
      rx="1"
      fill="#6B7587"
    />
    <rect y="7.05859" width="4.70588" height="4.70588" rx="1" fill={color} />
    <rect
      x="7.05884"
      y="7.05859"
      width="12.9412"
      height="4.70588"
      rx="1"
      fill={color}
    />
  </SvgIcon>
);
