import { CloseIcon, Paper } from "@chhjpackages/components";
import { IconButton, Typography } from "@material-ui/core";
import { memo, useMemo } from "react";
import moment from "moment";

import { USADateFormatShortest, USATimeFormatAmPm } from "shared/utils";

import { NotificationCardProps } from "./types";
import { useStyles } from "./styles";

export const NotificationCard = memo(
  ({ title, date, disableb, onDismiss }: NotificationCardProps) => {
    const styles = useStyles();

    const formattedDate = useMemo(
      () =>
        moment(date).format(`${USATimeFormatAmPm} ${USADateFormatShortest}`),
      [date],
    );

    return (
      <Paper elevation={16} roundingValue={4} className={styles.root}>
        <div className={styles.content}>
          <Typography variant="body1" className={styles.title}>
            {title}
          </Typography>

          <Typography variant="body2" className={styles.date}>
            {formattedDate}
          </Typography>
        </div>

        <IconButton
          className={"iconButtonMedium"}
          disabled={disableb}
          classes={{ disabled: styles.dismissDisabled }}
          onClick={onDismiss}
        >
          <CloseIcon fontSize={18} />
        </IconButton>
      </Paper>
    );
  },
);
