import { memo } from "react";
import moment from "moment";

import { AppointmentListProps } from "./types";
import { AppointmentGridViewList } from "./appointment-grid-view-list";
import { AppointmentListViewList } from "./appointment-list-view-list";

export const AppointmentList = memo(
  ({
    isScroll,
    isGridView,
    groupedByTimeAppointments,
    currentTeams,
    handleOpenContactsDialog,
    handleOpenNotesDialog,
    handleOpenMapTypesDialog,
    handlePickUp,
  }: Omit<AppointmentListProps, "appointmentTime" | "appointments">) => {
    const actualTime = groupedByTimeAppointments.find(
      ({ time }) => time >= moment().format("HH:mm"),
    );

    return (
      <div>
        {isGridView ? (
          <AppointmentGridViewList
            isScroll={isScroll}
            actualTime={actualTime?.time}
            groupedByTimeAppointments={groupedByTimeAppointments}
            currentTeams={currentTeams}
            handleOpenContactsDialog={handleOpenContactsDialog}
            handleOpenNotesDialog={handleOpenNotesDialog}
            handleOpenMapTypesDialog={handleOpenMapTypesDialog}
            handlePickUp={handlePickUp}
          />
        ) : (
          <AppointmentListViewList
            isScroll={isScroll}
            actualTime={actualTime?.time}
            groupedByTimeAppointments={groupedByTimeAppointments}
            currentTeams={currentTeams}
            handleOpenContactsDialog={handleOpenContactsDialog}
            handleOpenNotesDialog={handleOpenNotesDialog}
            handleOpenMapTypesDialog={handleOpenMapTypesDialog}
            handlePickUp={handlePickUp}
          />
        )}
      </div>
    );
  },
);
