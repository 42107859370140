import { colors } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  root: {
    padding: "8px 24px 16px",
    display: "flex",
    flexDirection: "column",
    gap: 16,

    "&:before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      height: 16,
      boxShadow: "0px -8px 8px rgba(134, 145, 166, 0.08)",
      pointerEvents: "none",
    },
  },
  item: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 16,
  },
  newEstimateTitle: {
    fontSize: 18,
    color: colors.grey140,
    fontWeight: "bold",
  },
  newEstimate: {
    fontSize: 36,
    fontWeight: "bold",
  },
  colorGrey180: {
    color: colors.grey180,
  },
}));
