import { memo, useCallback, useEffect } from "react";
import { Typography } from "@material-ui/core";
import { colors, useConfirmationDialog } from "@chhjpackages/components";
import { useNavigate } from "react-router-dom";

import { useSideNavDispatch } from "features/sidenav";
import { trainingSection, GuideCard } from "features/training-details";
import { routePaths } from "shared/utils";

export const Training = memo(() => {
  const navigate = useNavigate();
  const { setPageName } = useSideNavDispatch();

  const { openConfirmation, closeConfirmation } = useConfirmationDialog({
    title: "Binder Presentation",
    message:
      "The Binder presentation under the Training section is for training purposes only. It will not display pricing, open the binder presentation from a job.",
    hideCancelButton: true,
    hideConfirmButton: true,
  });

  const handleBinderSection = useCallback(() => {
    openConfirmation(
      () => {},
      () => {
        closeConfirmation();
        setTimeout(() => {
          navigate(routePaths.trainingBinderPresentation());
        }, 10);
      },
    );
  }, [openConfirmation, closeConfirmation, navigate]);

  useEffect(() => {
    setPageName("Training");

    return () => {
      setPageName("");
    };
  }, [setPageName]);

  return (
    <div style={{ padding: 16 }}>
      <Typography variant="h5" style={{ color: colors.grey120 }}>
        Guides
      </Typography>
      <div style={{ marginTop: 16 }}>
        {trainingSection.map((item, index) => (
          <div style={{ marginTop: index !== 0 ? 16 : 0 }}>
            <GuideCard
              title={item.heading1}
              description={item.heading2}
              onClickData={index < 2 ? item.route : handleBinderSection}
              guideNumber={index}
            />
          </div>
        ))}
      </div>
    </div>
  );
});
