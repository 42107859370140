import { fetcher } from "shared/libs";

import { GetPermissions } from "./types/permissions-api";

export const getPermissions = async () => {
  try {
    const response = await fetcher.get<GetPermissions.Response>(
      "locations/users/permissions",
    );

    return response.data.permissions[0];
  } catch (error) {
    throw new Error();
  }
};
