import { createEffect, createEvent, createStore } from "effector";

import { Appointments } from "../types";
import { getAppointments } from "../api";

export const getAppointmentsFx = createEffect(getAppointments);

export const setAppointmentInAppointmentsEv = createEvent<Appointments>();

export const $appointmentsStore = createStore<{
  appointments: Appointments[];
  startTime: { id: number; time: string }[];
  error: Error | null;
  loading: boolean;
}>({
  appointments: [],
  startTime: [],
  error: null,
  loading: false,
});

$appointmentsStore
  .on(getAppointmentsFx.doneData, (store, response) => ({
    ...store,
    appointments: response.data.appointments,
    startTime: response.data.appointments.map((appointment, i) => ({
      id: i + 1,
      time: `${appointment.startDate}:${appointment.endDate}`,
    })),
    loading: false,
  }))
  .on(getAppointmentsFx.pending, (store, loading) => ({
    ...store,
    loading,
  }))
  .on(getAppointmentsFx.failData, (store, error) => ({
    ...store,
    error,
  }));

$appointmentsStore.on(
  setAppointmentInAppointmentsEv,
  (store, newAppointment) => {
    const newAppointments = store.appointments.map((appointment) =>
      appointment.id === newAppointment?.id ? newAppointment : appointment,
    );

    return {
      ...store,
      appointments: newAppointments,
      startTime: newAppointments.map((app, i) => ({
        id: i + 1,
        time: `${app.startDate}:${app.endDate}`,
      })),
    };
  },
);
