import { useDialogDispatch } from "@chhjpackages/components";
import { useCallback, useEffect, useState } from "react";

import { DialogContentWrapper } from "shared/ui";
import { SignDialog } from "../../ui";

export const useSignDialog = ({
  title,
  onSubmit,
}: {
  title: string;
  onSubmit: (signBlob: Blob) => void;
}) => {
  const setDialog = useDialogDispatch();

  const [isDialogOpen, setIsDialogOpen] = useState<boolean | null>(null);

  const handleSignDialogOpen = useCallback(() => setIsDialogOpen(true), []);

  const handleSignDialogClose = useCallback(() => setIsDialogOpen(false), []);

  const handleSubmit = useCallback(
    (signBlob: Blob) => {
      onSubmit(signBlob);
      handleSignDialogClose();
    },
    [onSubmit, handleSignDialogClose],
  );

  useEffect(() => {
    if (isDialogOpen) {
      setDialog({
        open: true,
        variant: "basic",
        title: title,
        disableBackdropClick: true,
        dialogContent: (
          <DialogContentWrapper maxWidth={488}>
            <SignDialog
              onSubmit={handleSubmit}
              onClose={handleSignDialogClose}
            />
          </DialogContentWrapper>
        ),
        onClose: handleSignDialogClose,
      });
    } else if (isDialogOpen === false) {
      setIsDialogOpen(null);
      setDialog({ open: false });
    }
  }, [
    title,
    isDialogOpen,
    handleSignDialogOpen,
    handleSignDialogClose,
    handleSubmit,
    setDialog,
  ]);

  return { handleSignDialogOpen, handleSignDialogClose };
};
