import { colors } from "@chhjpackages/components";
import { Collapse, Typography } from "@material-ui/core";
import { memo, useMemo } from "react";

import { AdditionalProductsSection } from "./additional-products";
import { BillOfLadingViewProps, CoupounsDiscountsEnum } from "./model/types";
import { MovingSection } from "./moving";
import { PackingSection } from "./packing";
import { JunkRemovalSection } from "./junk-removal";
import { DiscountSection } from "./discount";

export const BillOfLadingView = memo(
  ({
    control,
    calculatedQuote,
    junkRemoval,
    junkRemovalOptions,
    discountsCouponsOptions,
    setValue,
    watch,
  }: BillOfLadingViewProps) => {
    const couponDiscountWatched = watch("couponDiscount");

    const isDiscountsError = useMemo(
      () => discountsCouponsOptions.length > 1 && !couponDiscountWatched,
      [discountsCouponsOptions.length, couponDiscountWatched],
    );

    const showDiscountSection = useMemo(
      () =>
        !!(
          discountsCouponsOptions.length > 1 ||
          discountsCouponsOptions.find(
            (option) => option.value === CoupounsDiscountsEnum.DiscountQuote,
          )
        ),
      [discountsCouponsOptions],
    );

    const showPackingSection = useMemo(
      () => !!calculatedQuote?.packing.services.hours,
      [calculatedQuote?.packing.services.hours],
    );

    return (
      <div>
        <Typography
          variant="h4"
          color="inherit"
          style={{ color: colors.grey140 }}
        >
          Estimate
        </Typography>

        <div style={{ marginTop: 24 }}>
          <Typography variant="h6" style={{ color: colors.grey140 }}>
            Moving
          </Typography>
          <MovingSection control={control} />
        </div>

        <Collapse in={junkRemoval > 0} unmountOnExit>
          <div style={{ marginTop: 16 }}>
            <JunkRemovalSection
              control={control}
              junkRemoval={junkRemoval}
              junkRemovalOptions={junkRemovalOptions}
            />
          </div>
        </Collapse>

        <Collapse in={showDiscountSection} unmountOnExit>
          <div
            style={{
              marginTop: 24,
              color: isDiscountsError
                ? colors.functionals.alert
                : colors.grey140,
            }}
          >
            <Typography variant="h6" color="inherit">
              Discount/Coupon
            </Typography>

            <Collapse in={isDiscountsError}>
              <Typography variant="caption" color="inherit">
                <i>
                  You can only have one discount or coupon. Please select the
                  one you wish to apply
                </i>
              </Typography>
            </Collapse>

            <DiscountSection
              discountsCouponsOptions={discountsCouponsOptions}
              watch={watch}
              setValue={setValue}
            />
          </div>
        </Collapse>

        <Collapse in={showPackingSection} unmountOnExit>
          <div style={{ marginTop: 24 }}>
            <Typography variant="h6" style={{ color: colors.grey140 }}>
              Packing
            </Typography>
            <PackingSection control={control} />
          </div>
        </Collapse>

        <div style={{ marginTop: 24, color: colors.grey140 }}>
          <Typography variant="h5" color="inherit">
            Additional products from quote
          </Typography>
          <Typography variant="body2" color="inherit">
            More products can be added on the next screen
          </Typography>
          <AdditionalProductsSection control={control} watch={watch} />
        </div>
      </div>
    );
  },
);
