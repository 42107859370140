import { Button } from "@chhjpackages/components";
import { memo, useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useStore } from "effector-react/compat";
import { Typography } from "@material-ui/core";

import { StepLink, Timer } from "shared/ui";
import { StepStatusesEnum } from "shared/types";
import { $settings } from "features/settings";
import { groupBy, sortObject } from "shared/utils";

import {
  getStepTwoLinks,
  ProcessWorkProps,
  StepTwoLinksTypesEnum,
} from "./model";
import { useStyles } from "./assets";

export const ProcessWork = memo(
  ({
    appointment,
    status,
    time,
    isPaused,
    completedActions,
    requirements,
    billOfLadingDenied,
    onStartWork,
    onPauseWork,
    onEndWork,
    onEditTime,
  }: ProcessWorkProps) => {
    const styles = useStyles();

    const { pathname } = useLocation();

    const { preJobInspection, postJobInspetion } = useStore($settings);

    const [isStartWorkLoading, setIsStartWorkLoading] = useState(false);
    const [isPauseWorkLoading, setIsPauseWorkLoading] = useState(false);
    const [isEndWorkLoading, setIsEndWorkLoading] = useState(false);

    const isNasa = useMemo(
      () => !!appointment?.subpartner.id,
      [appointment?.subpartner.id],
    );

    const workLinks = useMemo(
      () =>
        getStepTwoLinks(
          completedActions,
          requirements,
          isNasa,
          appointment,
          preJobInspection,
          postJobInspetion,
          billOfLadingDenied,
          pathname,
        ),
      [
        appointment,
        requirements,
        billOfLadingDenied,
        completedActions,
        isNasa,
        pathname,
        preJobInspection,
        postJobInspetion,
      ],
    );

    const groupedWorkLinks = useMemo(
      () =>
        sortObject(
          groupBy(workLinks, (workLink) => workLink.type),
          [
            { key: StepTwoLinksTypesEnum.None, index: 0 },
            { key: StepTwoLinksTypesEnum.BeforeWork, index: 1 },
            { key: StepTwoLinksTypesEnum.DuringJob, index: 2 },
            { key: StepTwoLinksTypesEnum.AfterJob, index: 3 },
          ],
        ),
      [workLinks],
    );

    const handleStartWork = useCallback(async () => {
      setIsStartWorkLoading(true);

      await onStartWork();

      setIsStartWorkLoading(false);
    }, [onStartWork]);

    const handlePauseWork = useCallback(async () => {
      setIsPauseWorkLoading(true);

      await onPauseWork();

      setIsPauseWorkLoading(false);
    }, [onPauseWork]);

    const handleEndWork = useCallback(async () => {
      setIsEndWorkLoading(true);

      await onEndWork();

      setIsEndWorkLoading(false);
    }, [onEndWork]);

    return (
      <div>
        <Timer initialTime={time} onEdit={onEditTime} />

        {Object.entries(groupedWorkLinks).map(([key, stepTwoLinks]) => (
          <div className={styles.workLinksList}>
            {!!key && (
              <Typography variant="h5" className={styles.workLinksSectionTitle}>
                {key}
              </Typography>
            )}

            {stepTwoLinks.map((stepTwoLink) => (
              <StepLink
                key={stepTwoLink.id}
                path={stepTwoLink.path(Number(appointment?.id))}
                pathState={stepTwoLink.pathState}
                name={stepTwoLink.name}
                isChecked={stepTwoLink.isChecked}
              />
            ))}
          </div>
        ))}

        <div className={styles.actionButtonsContainer}>
          {status === StepStatusesEnum.InProgress || isPaused ? (
            <>
              <Button
                buttonType="text"
                color="primary"
                fullWidth
                isLoading={isPauseWorkLoading}
                className={styles.actionButton}
                onClick={handlePauseWork}
              >
                {isPaused ? "Resume work" : "Pause time"}
              </Button>

              <Button
                buttonType="twoTone"
                fullWidth
                isLoading={isEndWorkLoading}
                className={styles.actionButton}
                onClick={handleEndWork}
              >
                End work
              </Button>
            </>
          ) : (
            <Button
              buttonType="twoTone"
              size="medium"
              fullWidth
              isLoading={isStartWorkLoading}
              className={styles.actionButton}
              onClick={handleStartWork}
            >
              Start working
            </Button>
          )}
        </div>
      </div>
    );
  },
);
