import { colors } from "@chhjpackages/components";
import { Box, Typography } from "@material-ui/core";
import { memo } from "react";

import { TwoLinesDialogHeaderProps } from "./types";

export const TwoLinesDialogHeader = memo(
  ({ title, description }: TwoLinesDialogHeaderProps) => {
    return (
      <Box mr={2}>
        <Typography variant="h5">{title}</Typography>
        {description && (
          <Box color={colors.grey140}>
            <Typography variant="subtitle2" color="inherit">
              {description}
            </Typography>
          </Box>
        )}
      </Box>
    );
  },
);
