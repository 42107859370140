import { memo, useCallback, useEffect, useState } from "react";
import {
  Box,
  FormControl,
  InputAdornment,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import {
  Button,
  CalendarRangePicker,
  Input,
  Select,
  useDialogDispatch,
} from "@chhjpackages/components";
import moment from "moment";
import { useStore } from "effector-react/compat";

import { useSideNavDispatch } from "features/sidenav";
import { $rankingStore, getRankingFx, RankingCard } from "features/leaderboard";
import { $auth } from "features/auth";

import { useStyles } from "./styles";

export const LeaderBoard = memo(() => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), { noSsr: true });
  const styles = useStyles();
  const { userMetrics } = useStore($rankingStore);
  const { locationId } = useStore($auth);
  const { setPageName } = useSideNavDispatch();
  const setDialog = useDialogDispatch();

  const [jobValue, setJobValue] = useState<number>(1);
  const [calendarDate, setCalendarDate] = useState<Date | null | undefined>(
    new Date(),
  );
  const [passCalendarDate, setPassCalendarDate] = useState<
    Date | null | undefined
  >(new Date());
  const [selectedDate, setSelectedDate] = useState<Date | string | number>(
    moment(new Date()).format("MMMM yyyy"),
  );
  const [formatedDate, setFormatedDate] = useState<Date | string | number>(
    moment(new Date()).format("MM/yyyy"),
  );
  const [dateVisibility, setDateVisibility] = useState<boolean | null>(null);

  const handleDateChange = useCallback((newDate: Date[]) => {
    setPassCalendarDate(newDate[0]);
  }, []);

  const handleCloseDate = useCallback(() => {
    setDateVisibility(false);
    setPassCalendarDate(calendarDate);
  }, [calendarDate]);

  const handleAcceptDate = useCallback(() => {
    setSelectedDate(
      moment(passCalendarDate).format("MMMM YYYY".replace(/\s/g, " ")),
    );
    setFormatedDate(moment(passCalendarDate).format("MM/yyyy"));
    setCalendarDate(passCalendarDate || null);
    setDateVisibility(false);
  }, [passCalendarDate]);

  const handleCalendarSection = useCallback(() => {
    setDateVisibility(true);
  }, []);

  useEffect(() => {
    if (dateVisibility === true) {
      setDialog({
        open: true,
        variant: "basic",
        title: "CHOOSE DATE",
        disableBackdropClick: true,
        onClose: handleCloseDate,
        dialogContent: (
          <Box>
            <CalendarRangePicker
              onChange={handleDateChange}
              startDate={passCalendarDate}
              endDate={passCalendarDate}
              maxDate={moment().toDate()}
              showMonthYearPicker
              showFullMonthYearPicker
            />
            <Box
              mt={1}
              textAlign="center"
              display="flex"
              justifyContent="flex-end"
            >
              <Box>
                <Button
                  buttonType="text"
                  fullWidth
                  size="large"
                  color="primary"
                  onClick={handleCloseDate}
                >
                  Close
                </Button>
              </Box>
              <Box width={136}>
                <Button
                  size="large"
                  fullWidth
                  buttonType="twoTone"
                  onClick={handleAcceptDate}
                >
                  Select
                </Button>
              </Box>
            </Box>
          </Box>
        ),
      });
    } else if (dateVisibility === false) {
      setDateVisibility(null);
      setDialog({ open: false });
    }
  }, [
    dateVisibility,
    handleAcceptDate,
    setDialog,
    handleCloseDate,
    handleDateChange,
    passCalendarDate,
  ]);

  useEffect(() => {
    if (locationId) {
      const [monthPart, yearPart] = formatedDate.toString().split("/");
      const date = new Date(parseInt(yearPart), parseInt(monthPart) - 1);
      const month = moment(date).format("Y-MM-DD");
      const monthStart = moment(month)
        .startOf("month")
        .format("Y-MM-DD 00:00:00");
      const monthEnd = moment(month).endOf("month").format("Y-MM-DD 23:59:59");
      getRankingFx({
        payload: {
          all_time: "false",
          interval: "month",
          datetime_period: {
            start: monthStart,
            end: monthEnd,
          },
          categories: [jobValue],
          locations: [locationId],
        },
      });
    }
  }, [locationId, selectedDate, jobValue, formatedDate]);

  useEffect(() => {
    setPageName("Leaderboards");

    return () => {
      setPageName("");
    };
  }, [setPageName]);

  return (
    <Box p={2}>
      {!isMobile && (
        <Box>
          <Typography variant="h3">LEADERBOARDS</Typography>
        </Box>
      )}
      <Box mt={isMobile ? 0 : 2}>
        <FormControl fullWidth>
          <Input
            className={styles.input}
            label="Date"
            size="medium"
            variant="outlined"
            value={selectedDate}
            onClick={() => handleCalendarSection()}
            endAdornment={
              <InputAdornment
                style={{
                  cursor: "pointer",
                  height: "100%",
                  margin: "0 8px 0 0",
                }}
                position="end"
              >
                <Box mb={1} mr={-0.625}>
                  <ArrowDropDown
                    color="secondary"
                    fontSize="small"
                    style={{ width: "17px" }}
                  />
                </Box>
              </InputAdornment>
            }
          />
        </FormControl>
        <Box mt={2}>
          <Select
            variant="outlined"
            size="medium"
            label="Job Type"
            className={styles.root}
            value={jobValue}
            onChange={(e) => setJobValue(e.target.value as number)}
          >
            <MenuItem style={{ textAlign: "center" }} value={1}>
              JUNK
            </MenuItem>
            <MenuItem style={{ textAlign: "center" }} value={4}>
              LABOR
            </MenuItem>
            <MenuItem style={{ textAlign: "center" }} value={5}>
              MOVE
            </MenuItem>
            <MenuItem style={{ textAlign: "center" }} value={7}>
              MOVE LABOR
            </MenuItem>
          </Select>
        </Box>
        <Box mt={3}>
          <RankingCard userMetrics={userMetrics} />
        </Box>
      </Box>
    </Box>
  );
});
