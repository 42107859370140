import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
    },
  },
  sidebarNavContainer: {
    zIndex: 100,
  },
  sidebarContainer: {
    marginTop: 70,
    order: 3,
    width: 250,

    [theme.breakpoints.down("md")]: {
      marginTop: 0,
      order: 2,
      width: "initial",
    },

    [theme.breakpoints.up("lg")]: {
      marginRight: 24,
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    width: "1%",
    order: 2,

    [theme.breakpoints.down("md")]: {
      width: "100%",
      order: 3,
    },
  },
}));
