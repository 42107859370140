import { createEffect, createStore } from "effector/compat";

import { Addendum } from "../types";
import { getCurrentAddendum, generateAddendum, updateAddendum } from "../api";

export const $addendum = createStore<{
  addendum: Addendum | null;
  loading: boolean;
}>({ addendum: null, loading: true });

export const getCurrentAddendumFx = createEffect(getCurrentAddendum);
$addendum
  .on(getCurrentAddendumFx.doneData, (store, response) => ({
    ...store,
    addendum: response.data.addendumEstimate[0],
  }))
  .on(getCurrentAddendumFx.fail, (store) => ({
    ...store,
    addendum: null,
  }))
  .on(getCurrentAddendumFx.pending, (store, loading) => ({
    ...store,
    loading,
  }));

export const generateAddendumFx = createEffect(generateAddendum);
$addendum
  .on(generateAddendumFx.doneData, (store, response) => ({
    ...store,
    addendum: response.data.addendumEstimate[0],
  }))
  .on(generateAddendumFx.fail, (store) => ({
    ...store,
    addendum: null,
  }))
  .on(generateAddendumFx.pending, (store, loading) => ({
    ...store,
    loading,
  }));

export const updateAddendumFx = createEffect(updateAddendum);
$addendum.on(updateAddendumFx.doneData, (store, response) => ({
  ...store,
  addendum: response.data.addendumEstimate[0],
}));
