import { createEffect, createStore } from "effector/compat";
import { createEvent } from "effector";

import {
  getUser,
  updateUser,
  updateUserEmail,
  updateUserPhone,
  updateUserAvatar,
} from "../api";
import { User } from "../types";

export const getUserFx = createEffect(getUser);
export const updateUserFx = createEffect(updateUser);
export const updateUserEmailFx = createEffect(updateUserEmail);
export const updateUserPhoneFx = createEffect(updateUserPhone);
export const updateUserAvatarFx = createEffect(updateUserAvatar);

export const resetAuthorizedUser = createEvent();

const authorizedUserInitialValues = {
  user: null,
  loading: true,
};

export const $authorizedUser = createStore<{
  user: User | null;
  loading: boolean;
}>(authorizedUserInitialValues);

// GET user
$authorizedUser
  .on(getUserFx.doneData, (user, response) => ({
    ...user,
    user: response?.data?.users[0],
  }))
  .on(getUserFx.pending, (user, value) => ({
    ...user,
    loading: value,
  }));

// Update user
$authorizedUser.on(updateUserFx.doneData, (store, response) => {
  return {
    ...store,
    user: response.data.users[0],
  };
});

// Update user avatar
$authorizedUser.on(updateUserAvatarFx.doneData, (store, response) => {
  return {
    ...store,
    user: store.user
      ? { ...store.user, image: response.data.images[0] }
      : store.user,
  };
});

$authorizedUser.on(resetAuthorizedUser, () => authorizedUserInitialValues);
