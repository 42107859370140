import { Box, withStyles, Theme, makeStyles } from "@material-ui/core";
import { colors } from "@chhjpackages/components";

export const MainWrapper = withStyles((theme: Theme) => ({
  root: {
    position: "relative",

    "&:before": {
      position: "absolute",
      top: -300,
      left: -60,
      content: '""',
      display: "inline-block",
      width: 440,
      height: 440,
      borderRadius: "50%",
      backgroundColor: "#fef4eb",
    },

    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
    },

    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },

    [theme.breakpoints.down("lg")]: {
      maxWidth: "100%",
      minHeight: "100%",
    },
  },
}))(Box);

export const StepGraphic = withStyles((theme: Theme) => ({
  root: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    marginTop: -16,

    [theme.breakpoints.down("xs")]: {
      height: 150,

      "& svg": {
        height: 150,
      },
    },

    [theme.breakpoints.down("sm")]: {
      height: 270,

      "& svg": {
        height: 270,
      },
    },
  },
}))(Box);

export const useStyles = makeStyles((theme) => ({
  details: {
    minHeight: 135,
    marginTop: 16,

    [theme.breakpoints.down("xs")]: {
      minHeight: 120,

      "& *": {
        fontSize: 16,
      },
    },
  },

  note: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 54,
    marginTop: 32,

    [theme.breakpoints.down("xs")]: {
      marginTop: 24,

      "& *": {
        fontSize: 16,
      },
    },
  },

  bulletNav: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 32,

    [theme.breakpoints.down("xs")]: {
      marginTop: 24,
    },
  },

  bullet: {
    width: 10,
    height: 10,
    borderRadius: 10,
    backgroundColor: colors.grey40,
    display: "inline-block",
    margin: 4,
    position: "relative",
    transition: "all .25s easy",

    "&:before": {
      content: '""',
      display: "block",
      width: "100%",
      height: "100%",
      borderRadius: "100%",
      position: "absolute",
      top: 0,
      left: 0,
      backgroundColor: colors.primary.main,
      transform: "scale(0)",
      transition: "all 0.35s linear",
    },

    "&.active": {
      "&:before": {
        transform: "scale(1)",
      },
    },
  },

  bulletButton: {
    width: 60,

    [theme.breakpoints.down("xs")]: {
      width: 50,
    },
  },

  bulletText: {
    color: colors.grey120,
  },
}));
