import { colors } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  root: {
    padding: "8px 16px",
    backgroundColor: colors.basic.white,
    border: `1px solid ${colors.primary.light}`,
    borderRadius: "100px",
    cursor: "pointer",
  },
  active: {
    backgroundColor: colors.primary.main,
  },
  disabled: {
    backgroundColor: colors.grey10,
    border: `1px solid ${colors.grey40}`,
    cursor: "initial",
  },
  title: {
    lineHeight: "25px",
    color: colors.primary.main,
    textWrap: "nowrap",
  },
  titleActive: {
    color: colors.basic.white,
  },
  titleDisabled: {
    color: colors.grey120,
  },
});
