import { Button, useAlert } from "@chhjpackages/components";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useStore } from "effector-react/compat";
import { AxiosError } from "axios";
import { Collapse } from "@material-ui/core";

import { NasaRequirements } from "features/nasa-job-details";
import { Accordion, ContentAccordionLabel } from "shared/ui";
import { openInNewTab, openMapByType, routePaths } from "shared/utils";
import {
  $developments,
  addDevelopmentFx,
  DevelopmentStatusesEnum,
  getStartTravelSmsContent,
  ProcessStatusEnum,
  recalculateDevelopmentsEv,
  togglePausedWork,
} from "features/developments";
import {
  CompletedActionsJobEnum,
  StepNamesEnum,
  StepStatusesEnum,
} from "shared/types";
import {
  ProcessBilling,
  ProcessTravel,
  ProcessWork,
  ProcessComplete,
  $appointmentStore,
  updateAppointmentFx,
} from "features/appointment";
import {
  $products,
  addProductFx,
  checkExistingProduct,
  editProductFx,
} from "features/products";
import { $pricings } from "features/pricings";
import {
  $completedActions,
  useCompletedActions,
} from "features/completed-actions";
import { addOfflineRequestEv } from "features/job-offline-changes";
import { useSteps } from "features/appointment/model/hooks";
import { $settings } from "features/settings";
import { $requirements } from "features/requirements";
import { $authorizedUser, $location } from "features/auth";

export const ProcessTab = memo(({ isLoading }: { isLoading: boolean }) => {
  const navigate = useNavigate();
  const { appointmentId } = useParams();
  const { showAlert } = useAlert();
  const { addCompletedAction } = useCompletedActions();
  const {
    steps,
    actualStepName,
    managingProductsDenied,
    isShowWorkStep,
    isShowBillingStep,
  } = useSteps({
    loading: isLoading,
    isJobDetails: true,
  });

  const { workStatus, billableTimeTravelled, billableTimeWorked } =
    useStore($developments);
  const { appointment } = useStore($appointmentStore);
  const { location } = useStore($location);
  const { products } = useStore($products);
  const { pricings } = useStore($pricings);
  const { completedActions } = useStore($completedActions);
  const { filteredRequirements } = useStore($requirements);
  const { autoExpandJobSteps: isAutoExpandSteps, navigationApp } =
    useStore($settings);
  const { user } = useStore($authorizedUser);

  const [expandedTypes, setExpand] = useState<string[]>([]);

  const isNasa = useMemo(
    () => !!appointment?.subpartner.id,
    [appointment?.subpartner.id],
  );

  const requirementsList = useMemo(
    () =>
      filteredRequirements.map((requirement) => ({
        id: requirement.id,
        name: requirement.title,
      })),
    [filteredRequirements],
  );

  const isWorkPaused = useMemo(
    () => workStatus === ProcessStatusEnum.Paused,
    [workStatus],
  );

  const disabledCompleteStep = useMemo(
    () => isShowWorkStep && steps.work !== StepStatusesEnum.Done,
    [steps.work, isShowWorkStep],
  );

  const changeExpand = useCallback(
    (expandType: string, isProcess?: boolean) => {
      if (isProcess) {
        setExpand([expandType]);
      } else {
        if (expandedTypes.includes(expandType)) {
          setExpand(expandedTypes.filter((type) => type !== expandType));
        } else {
          setExpand([...expandedTypes, expandType]);
        }
      }
    },
    [expandedTypes],
  );

  const convertJob = useCallback(() => {
    if (!appointment) {
      return;
    }

    if (appointment.convertedAppointmentId === 0) {
      navigate(routePaths.jobDetailsConvertToJob(appointment.id));
    } else {
      openInNewTab(routePaths.jobDetails(appointment.convertedAppointmentId));
    }
  }, [appointment, navigate]);

  const onOpenCompleteAppointmentDialog = useCallback(() => {
    navigate(routePaths.jobDetailsCompleteAppointment(Number(appointmentId)), {
      replace: true,
    });
  }, [navigate, appointmentId]);

  const onNavigate = useCallback(
    (destination: string) => {
      openMapByType(
        navigationApp.value as "apple" | "google" | "waze",
        "My Location",
        destination,
      );
    },
    [navigationApp.value],
  );

  const onStartTravel = useCallback(async () => {
    if (appointment) {
      try {
        await addDevelopmentFx({
          locationId: appointment.location.id,
          appointmentId: appointment.id,
          payload: {
            status: {
              id: DevelopmentStatusesEnum.StartTravel,
            },
            manual: false,
            sendEta: true,
            sendTracking: false,
            phone: appointment.account.phone,
            message: getStartTravelSmsContent(appointment.account.firstName),
          },
        });

        showAlert("Success! Travel has been started.", {
          variant: "success",
        });
      } catch {
        showAlert("Error! Failed to start travel.", {
          variant: "error",
        });
      }
    }
  }, [appointment, showAlert]);

  const onEndTravel = useCallback(async () => {
    if (appointment) {
      try {
        await addDevelopmentFx({
          locationId: appointment.location.id,
          appointmentId: appointment.id,
          payload: {
            status: {
              id: DevelopmentStatusesEnum.StopTravel,
            },
          },
        });

        showAlert("Success! Travel has been ended.", {
          variant: "success",
        });
      } catch {
        showAlert("Error! Failed to end travel.", {
          variant: "error",
        });
      }
    }
  }, [appointment, showAlert]);

  const onEditTravelTime = useCallback(
    () => navigate(routePaths.jobDetailsEditTravelHours(Number(appointmentId))),
    [appointmentId, navigate],
  );

  const onStartWork = useCallback(async () => {
    if (appointment) {
      try {
        await addDevelopmentFx({
          locationId: appointment.location.id,
          appointmentId: appointment.id,
          payload: {
            status: {
              id: DevelopmentStatusesEnum.StartWork,
            },
          },
        });

        showAlert("Success! Work has been started.", {
          variant: "success",
        });
      } catch {
        showAlert("Error! Failed to start work.", {
          variant: "error",
        });
      }
    }
  }, [appointment, showAlert]);

  const onPauseWork = useCallback(async () => {
    if (appointment) {
      try {
        togglePausedWork(appointment.id);

        await addDevelopmentFx({
          locationId: appointment.location.id,
          appointmentId: appointment.id,
          payload: {
            status: {
              id: isWorkPaused
                ? DevelopmentStatusesEnum.StartWork
                : DevelopmentStatusesEnum.StopWork,
            },
          },
        });

        showAlert(
          `Success! Work has been ${isWorkPaused ? "resumed" : "stopped"}.`,
          {
            variant: "success",
          },
        );
      } catch {
        showAlert(
          `Error! Failed to ${isWorkPaused ? "resume" : "stop"} work.`,
          {
            variant: "error",
          },
        );
      }
    }
  }, [appointment, isWorkPaused, showAlert]);

  const onEndWork = useCallback(async () => {
    if (appointment) {
      if (!isWorkPaused) {
        try {
          await addDevelopmentFx({
            locationId: appointment.location.id,
            appointmentId: appointment.id,
            payload: {
              status: {
                id: DevelopmentStatusesEnum.StopWork,
              },
            },
          });

          showAlert("Success! Work has been ended.", {
            variant: "success",
          });
        } catch {
          showAlert("Error! Failed to end work.", {
            variant: "error",
          });
        }
      } else {
        togglePausedWork(appointment.id);

        recalculateDevelopmentsEv();

        showAlert("Success! Work has been ended.", {
          variant: "success",
        });
      }
    }
  }, [appointment, isWorkPaused, showAlert]);

  const onEditWorkTime = useCallback(
    () => navigate(routePaths.jobDetailsEditWorkHours(Number(appointmentId))),
    [appointmentId, navigate],
  );

  const onQuickAddProduct = useCallback(
    async (productId: number) => {
      if (!appointment?.id) {
        return;
      }

      const pricing = pricings.find((p) => p.id === productId);

      if (pricing) {
        const existingProduct = checkExistingProduct(products, {
          ...pricing,
          taxId: pricing.tax.id,
          notes: "",
        });

        try {
          if (existingProduct) {
            try {
              await editProductFx({
                locationId: appointment.location.id,
                appointmentId: appointment.id,
                productLineId: existingProduct.productLineId,
                payload: {
                  actualPrice: existingProduct.actualPrice,
                  qty: existingProduct.quantity + 1,
                  salesTaxId: existingProduct.salesTaxId,
                  notes: existingProduct.notes,
                },
              });
            } catch (err) {
              const error = err as AxiosError;
              addOfflineRequestEv({
                status: error.response?.status,
                request: {
                  config: error.config,
                  id: `${appointment.id}`,
                  description: "Changes product",
                  startState: `${existingProduct.productName} (ID: ${
                    existingProduct.productId
                  }) - ${existingProduct.quantity} - $${Number(
                    existingProduct.actualPrice,
                  ).toFixed(2)}`,
                  endState: `${existingProduct.productName} (ID: ${
                    existingProduct.productId
                  }) - ${existingProduct.quantity + 1} - $${Number(
                    existingProduct.actualPrice,
                  ).toFixed(2)}`,
                  userId: user?.id,
                },
              });
              throw new Error();
            }
          } else {
            const salesTaxId =
              appointment.location.taxCodes.length === 0 ? 0 : pricing.tax.id;

            try {
              await addProductFx({
                locationId: appointment.location.id,
                appointmentId: appointment.id,
                payload: {
                  actualPrice: pricing.price,
                  retailPrice: pricing.price,
                  qty: 1,
                  salesTaxId: salesTaxId ?? 0,
                  appointment: {
                    id: appointment.id,
                  },
                  product: {
                    id: pricing.id,
                  },
                  notes: "",
                },
              });
            } catch (err) {
              const error = err as AxiosError;
              addOfflineRequestEv({
                status: error.response?.status,
                request: {
                  config: error.config,
                  id: `${appointment.id}`,
                  description: "Added product",
                  startState: "",
                  endState: `${pricing.name} (ID: ${
                    pricing.id
                  }) - 1 - $${Number(pricing.price).toFixed(2)}`,
                  userId: user?.id,
                },
              });
              throw new Error();
            }

            await addCompletedAction(
              appointment.id,
              appointment.location.id,
              CompletedActionsJobEnum.AddProducts,
            );
          }

          await updateAppointmentFx({
            locationId: appointment.location.id,
            appointmentId: appointment.id,
          });

          showAlert("Success! Item has been added to cart.", {
            variant: "success",
          });
        } catch {
          showAlert("Error! Failed to add item to cart.", {
            variant: "error",
          });
        }
      }
    },
    [
      appointment?.id,
      appointment?.location.id,
      appointment?.location.taxCodes,
      pricings,
      products,
      user?.id,
      addCompletedAction,
      showAlert,
    ],
  );

  const onSendItemizedReceipt = useCallback(
    () =>
      navigate(routePaths.jobDetailsSendInvoice(Number(appointmentId)), {
        replace: true,
      }),
    [appointmentId, navigate],
  );

  const renderCount = useRef(0);
  const [prevActualStep, setPrevActualStep] = useState("");
  useEffect(() => {
    if (!actualStepName || isLoading) {
      return;
    }

    if (isAutoExpandSteps && renderCount.current === 0) {
      renderCount.current += 1;
      return;
    }

    if (
      prevActualStep !== StepNamesEnum.Billing ||
      actualStepName !== StepNamesEnum.Complete
    ) {
      if (isAutoExpandSteps) {
        setExpand((prev) => [
          ...prev.filter((step) => step !== prevActualStep),
          actualStepName,
        ]);
      } else {
        changeExpand(actualStepName, true);
      }

      if (renderCount.current === 1) {
        renderCount.current += 1;
        return;
      }

      setPrevActualStep(actualStepName);
    }
  }, [actualStepName, isLoading]);

  useEffect(() => {
    if (isAutoExpandSteps && !isLoading) {
      const availableSteps = Object.keys(steps);
      setExpand(availableSteps);
    }
  }, [isAutoExpandSteps, isLoading]);

  return (
    <div style={{ padding: 16 }}>
      <Collapse in={!isLoading && isNasa} unmountOnExit>
        <NasaRequirements
          requirements={requirementsList}
          expanded={true}
          hideExpandIcon={true}
          isLoading={isLoading}
        />
      </Collapse>

      <div style={{ marginTop: !isLoading && isNasa ? 16 : 0 }}>
        <Accordion
          isOpen={expandedTypes.includes(StepNamesEnum.Travel)}
          changeExpand={() => changeExpand(StepNamesEnum.Travel)}
          disabled={isLoading}
          header={
            <ContentAccordionLabel
              title="Step 1: Travel"
              status={steps.travel}
            />
          }
        >
          <div style={{ marginTop: 8 }}>
            <ProcessTravel
              appointment={appointment}
              status={steps.travel}
              time={billableTimeTravelled}
              onStartTravel={onStartTravel}
              onEndTravel={onEndTravel}
              onEditTime={onEditTravelTime}
              handleNavigate={onNavigate}
            />
          </div>
        </Accordion>
      </div>

      {isShowWorkStep && steps.work ? (
        <Accordion
          isOpen={expandedTypes.includes(StepNamesEnum.Work)}
          changeExpand={() => changeExpand(StepNamesEnum.Work)}
          disabled={isLoading}
          header={
            <ContentAccordionLabel title="Step 2: Work" status={steps.work} />
          }
        >
          <div style={{ marginTop: 8 }}>
            <ProcessWork
              appointment={appointment}
              status={steps.work}
              completedActions={completedActions}
              requirements={filteredRequirements}
              time={billableTimeWorked}
              isPaused={isWorkPaused}
              billOfLadingDenied={managingProductsDenied}
              onStartWork={onStartWork}
              onPauseWork={onPauseWork}
              onEndWork={onEndWork}
              onEditTime={onEditWorkTime}
            />
          </div>
        </Accordion>
      ) : (
        <></>
      )}

      {isShowBillingStep && steps.billing ? (
        <Accordion
          isOpen={expandedTypes.includes(StepNamesEnum.Billing)}
          changeExpand={() => changeExpand(StepNamesEnum.Billing)}
          disabled={isLoading}
          header={
            <ContentAccordionLabel
              title={`Step ${isShowWorkStep ? "3" : "2"}: Billing`}
              status={steps.billing}
            />
          }
        >
          <ProcessBilling
            appointment={appointment}
            location={location}
            pricings={pricings}
            products={products}
            managingProductsDenied={managingProductsDenied}
            onQuickAddProduct={onQuickAddProduct}
            onSendItemizedReceipt={onSendItemizedReceipt}
          />
        </Accordion>
      ) : (
        <></>
      )}

      <Accordion
        isOpen={expandedTypes.includes(StepNamesEnum.Complete)}
        changeExpand={() => changeExpand(StepNamesEnum.Complete)}
        disabled={isLoading}
        header={
          <ContentAccordionLabel
            title={`Step ${
              isShowWorkStep && isShowBillingStep
                ? "4"
                : !isShowWorkStep && !isShowBillingStep
                ? "2"
                : "3"
            }: Complete`}
            status={steps.complete}
          />
        }
      >
        <ProcessComplete
          appointment={appointment || undefined}
          disabled={disabledCompleteStep}
          requirements={filteredRequirements}
          handleCompleteAppointmentDialog={onOpenCompleteAppointmentDialog}
        />
      </Accordion>

      {!isLoading && appointment?.type === "EST" && (
        <Button
          buttonType="twoTone"
          size="medium"
          fullWidth
          disabled={isLoading}
          onClick={convertJob}
        >
          {appointment.convertedAppointmentId === 0
            ? "Convert to job"
            : `Go to job ${appointment.convertedAppointmentId}`}
        </Button>
      )}
    </div>
  );
});
