import moment from "moment";

import { apiDateTimeFormat } from "shared/utils";

import {
  Development,
  ProcessStatusEnum,
  DevelopmentStatusesEnum,
} from "./types";

export const getPausedWorks = (): number[] => {
  const pausedWorks = localStorage.getItem("crew/pausedWorks");

  if (pausedWorks) {
    return JSON.parse(pausedWorks);
  }

  return [];
};

export const togglePausedWork = (appointmentId: number) => {
  const pausedWorks = getPausedWorks();

  if (pausedWorks.includes(appointmentId)) {
    const newPausedWorks = pausedWorks.filter(
      (pausedWork) => pausedWork !== appointmentId,
    );
    localStorage.setItem("crew/pausedWorks", JSON.stringify(newPausedWorks));
  } else {
    const newPausedWorks = [...pausedWorks, appointmentId];
    localStorage.setItem("crew/pausedWorks", JSON.stringify(newPausedWorks));
  }
};

const getDevelopmentStatus = (
  startedDevelopments: Development[],
  stoppedDevelopments: Development[],
  appointmentId: number,
  type: "travel" | "work",
) => {
  if (startedDevelopments.length <= 0) {
    return ProcessStatusEnum.ToBeDone;
  } else {
    if (startedDevelopments.length > stoppedDevelopments.length) {
      return ProcessStatusEnum.InProgress;
    } else if (startedDevelopments.length === stoppedDevelopments.length) {
      if (type === "work") {
        const pausedWorks = getPausedWorks();
        if (pausedWorks.includes(appointmentId)) {
          return ProcessStatusEnum.Paused;
        }
      }

      return ProcessStatusEnum.Done;
    }
  }

  return ProcessStatusEnum.ToBeDone;
};

export const getDevelopmentsInfo = (
  developments: Development[],
  type: "travel" | "work",
  appointmentId: number,
) => {
  const startedDevelopments = developments
    .filter(
      (development) =>
        development.status.id ===
        (type === "work"
          ? DevelopmentStatusesEnum.StartWork
          : DevelopmentStatusesEnum.StartTravel),
    )
    .reverse();
  const endedDevelopments = developments
    .filter(
      (development) =>
        development.status.id ===
        (type === "work"
          ? DevelopmentStatusesEnum.StopWork
          : DevelopmentStatusesEnum.StopTravel),
    )
    .reverse();

  const developmentPairs: {
    start: Development;
    end?: Development;
  }[] = [];

  if (startedDevelopments.length > 0) {
    startedDevelopments.map((prc, index) => {
      if (endedDevelopments[index] !== undefined) {
        developmentPairs.push({ start: prc, end: endedDevelopments[index] });
      } else {
        developmentPairs.push({ start: prc, end: undefined });
      }
    });
  }

  const status = getDevelopmentStatus(
    startedDevelopments,
    endedDevelopments,
    appointmentId,
    type,
  );

  let generalTime = 0;
  if (developmentPairs.length > 0) {
    const generalStart = developmentPairs[0].start;
    const generalEnd = developmentPairs[developmentPairs.length - 1].end;

    generalTime = (
      generalEnd
        ? moment(generalEnd.datetime, apiDateTimeFormat)
        : moment(moment().utc().format(apiDateTimeFormat))
    ).diff(moment(generalStart.datetime, apiDateTimeFormat), "seconds");
  }

  const billableTime = developmentPairs.reduce(
    (acc, cur) =>
      acc +
      (cur.end
        ? moment(cur.end?.datetime, apiDateTimeFormat)
        : moment(moment().utc().format(apiDateTimeFormat))
      ).diff(moment(cur.start?.datetime, apiDateTimeFormat), "seconds"),
    0,
  );

  return {
    generalTime: generalTime,
    billableTime: billableTime,
    developmentStatus: status,
    developmentPairs: developmentPairs,
  };
};

export const getStartTravelSmsContent = (
  customerName: string,
) => `Dear ${customerName}, our HUNKS will be arriving soon.
We look forward to meeting you and hope that we can provide you with a stress-free experience. - College Hunks`;
