import { createEffect, createStore } from "effector/compat";

import { LocationUser } from "features/teams";

import { getUsers } from "./api";

export const getUsersFx = createEffect(getUsers);

export const $usersStore = createStore<{
  users: LocationUser[];
  loading: boolean;
}>({
  users: [],
  loading: true,
});

$usersStore
  .on(getUsersFx.doneData, (store, response) => ({
    ...store,
    users: response.data.users,
  }))
  .on(getUsersFx.pending, (store, loading) => ({
    ...store,
    loading,
  }));
