import { createStore, createEvent } from "effector";

export const completedSlideValue = createEvent<number>();

const updateSelectedSlide = (newSlideIndex: number): number => newSlideIndex;

export const $binderStore = createStore<{
  selectedSlideIndex: number;
}>({
  selectedSlideIndex: -1,
});

$binderStore.on(completedSlideValue, (store, newStep) => ({
  ...store,
  selectedSlideIndex: updateSelectedSlide(newStep),
}));
