import moment from "moment";

import { groupBy } from "./group-by";

export const groupByTime = <T, K extends string | number | symbol>(
  list: T[],
  getKey: (item: T) => K,
  format: string,
) => {
  const groupedList = groupBy(list, getKey);

  const listByTime = Object.entries(groupedList).map(([key, values]) => ({
    time: key,
    data: values as T[],
  }));

  const sortedListByTime = listByTime.sort((a, b) =>
    moment(a.time).diff(moment(b.time)),
  );

  return sortedListByTime.map((item) => ({
    ...item,
    time: moment(item.time).format(format),
  }));
};
