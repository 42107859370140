import { Grid, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { memo } from "react";

import { useStyles } from "./styles";

export const UserInfoSkeleton = memo(() => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Grid container spacing={3} wrap="nowrap" alignItems="center">
        <Grid item>
          <Skeleton variant="circle" width={66} height={66} />
        </Grid>
        <Grid item style={{ flex: 1 }}>
          <Typography
            variant="subtitle2"
            color="inherit"
            className={styles.greeting}
          >
            <Skeleton variant="text" width="100%" />
          </Typography>
          <Typography variant="h4">
            <Skeleton variant="text" width="80%" />
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
});
