import { memo, useCallback } from "react";
import { useForm } from "react-hook-form";
import { Button } from "@chhjpackages/components";
import { yupResolver } from "@hookform/resolvers/yup";

import { FormTextArea } from "shared/ui";

import { NoteFormProps, noteFormSchema, NoteFormValues } from "./model";
import { useStyles } from "./assets";

export const NoteForm = memo(({ label, onSubmit }: NoteFormProps) => {
  const styles = useStyles();

  const {
    control,
    formState: { isValid, isSubmitting },
    reset,
    handleSubmit,
  } = useForm<NoteFormValues>({
    mode: "all",
    resolver: yupResolver(noteFormSchema),
  });

  const handleOnSubmit = useCallback(
    async (data: NoteFormValues) => {
      try {
        await onSubmit(data);

        reset();
      } catch (error) {
        throw error;
      }
    },
    [onSubmit, reset],
  );

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <div className={styles.addNoteButtonContainer}>
        <Button
          size="small"
          variant="text"
          color="primary"
          type="submit"
          isLoading={isSubmitting}
          disabled={!isValid}
          classes={{ root: styles.addNoteButtonRoot }}
        >
          Add note
        </Button>
      </div>

      <FormTextArea
        control={control}
        name="note"
        label={label}
        error={false}
        disabled={isSubmitting}
      />
    </form>
  );
});
