import { memo } from "react";
import { Typography } from "@material-ui/core";
import { colors } from "@chhjpackages/components";
import { Skeleton } from "@material-ui/lab";

import { ArrowTopIcon } from "shared/assets";
import { DataList } from "shared/types";

import {
  AccordionStyled,
  AccordionSummaryStyled,
  AccordionDetailsStyled,
  useStyles,
} from "./styles";

export const NasaRequirements = memo(
  ({
    requirements,
    expanded,
    hideExpandIcon,
    isLoading,
  }: {
    requirements: DataList;
    expanded?: boolean;
    hideExpandIcon?: boolean;
    isLoading?: boolean;
  }) => {
    const styles = useStyles();

    return (
      <AccordionStyled expanded={expanded}>
        <AccordionSummaryStyled
          expandIcon={
            !hideExpandIcon && (
              <ArrowTopIcon color={colors.white} className={styles.arrowIcon} />
            )
          }
        >
          <Typography variant="h4" color="inherit">
            Nasa requirements
          </Typography>
        </AccordionSummaryStyled>
        <AccordionDetailsStyled>
          <div
            style={{
              padding: "8px 16px",
              flex: 1,
              backgroundColor: colors.grey10,
            }}
          >
            {isLoading &&
              [...Array(3)].map((_, index) => (
                <Typography
                  key={index}
                  variant="body1"
                  style={{ marginTop: index !== 0 ? 16 : 0 }}
                >
                  <Skeleton variant="text" />
                </Typography>
              ))}
            {!isLoading &&
              requirements.length > 0 &&
              requirements.map((requirement, index) => (
                <Typography
                  key={requirement.id}
                  variant="body1"
                  style={{ marginTop: index !== 0 ? 16 : 0 }}
                >
                  <b>{requirement.name}</b>
                </Typography>
              ))}
            {!isLoading && requirements.length === 0 && (
              <Typography variant="body1">
                <b>No special instructions</b>
              </Typography>
            )}
          </div>
        </AccordionDetailsStyled>
      </AccordionStyled>
    );
  },
);
