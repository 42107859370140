import { Box, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { Button, colors, WarningIcon } from "@chhjpackages/components";
import { memo, useState } from "react";

import { ColorfulText } from "shared/ui";
import { routePaths } from "shared/utils";

import { useStyles } from "./styles";
import { LinkSuccessSendProps } from "./types";

export const LinkSuccessSend = memo(
  ({
    disableResend,
    handleNavigateTo,
    handleResendLink,
  }: LinkSuccessSendProps) => {
    const styles = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"), {
      noSsr: true,
    });

    const [isResendLinkLoading, setIsResendLinkLoading] = useState(false);

    return (
      <div>
        <Box textAlign={{ xs: "left", sm: "center" }} color={colors.grey180}>
          <Typography variant="h3" color="inherit">
            Link has been sent
          </Typography>
        </Box>

        <ColorfulText
          textAlign={{ xs: "left", sm: "center" }}
          mt={{ xs: 1, sm: 3 }}
        >
          <>
            If an account matches the information you provided, a link was sent
            to your phone/email. If you do not receive a text/email, please try
            again.
            <br />
            <Box mt={2}>
              {isMobile && (
                <WarningIcon
                  color={colors.functionals.alert}
                  className={styles.warningIcon}
                />
              )}
              Link is valid only for <strong>15 minutes</strong>. After this
              time you’ll need to resend the link again.
            </Box>
          </>
        </ColorfulText>

        <Box mt={4}>
          <Button
            fullWidth
            buttonType="twoTone"
            size="large"
            onClick={() =>
              handleNavigateTo(routePaths.login(), { ignoreSearch: true })
            }
          >
            Back to login page
          </Button>
        </Box>
        <Box mt={2}>
          <Button
            fullWidth
            buttonType="outlined"
            size="large"
            onClick={() => handleNavigateTo(routePaths.resetPasswordLink())}
          >
            Change link delivery option
          </Button>
        </Box>
        <Box mt={1}>
          <Button
            fullWidth
            buttonType="text"
            color="secondary"
            size="large"
            type="submit"
            isLoading={isResendLinkLoading}
            disabled={disableResend}
            onClick={async () => {
              setIsResendLinkLoading(true);
              await handleResendLink();
              setIsResendLinkLoading(false);
            }}
          >
            Resend link
          </Button>
        </Box>
      </div>
    );
  },
);
