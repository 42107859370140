import { Box, Divider, Typography, IconButton } from "@material-ui/core";
import { colors, useAlert } from "@chhjpackages/components";
import { ReactNode, memo, useCallback } from "react";

import { ClipboardIcon } from "shared/assets";

import { ButtonStyled, useStyles } from "./styles";

export const ContactItem = memo(
  ({
    isEmail,
    divider,
    icon,
    type,
    contact,
  }: {
    isEmail?: boolean;
    divider?: boolean;
    icon: ReactNode;
    type: string;
    contact: string;
  }) => {
    const styles = useStyles();

    const { showAlert } = useAlert();

    const handleCopyToClipBoard = useCallback(
      (text: string) => {
        navigator.clipboard.writeText(text);
        showAlert("Success! Copied to clipboard.", {
          variant: "success",
        });
      },
      [showAlert],
    );

    return (
      <>
        <Box p={2}>
          <Box ml={4.75} color={colors.grey140}>
            <Typography variant="h6" color="inherit">
              {type}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            {icon}
            <Box ml={2} mt={!contact ? 0.5 : 0} flex={1}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box display="flex" alignItems="center">
                  <Box mr={0.5} color={colors.black}>
                    <Typography
                      variant="body1"
                      color="inherit"
                      style={{ wordBreak: "break-word" }}
                    >
                      {contact || "No data"}
                    </Typography>
                  </Box>
                  {contact && (
                    <IconButton
                      size="small"
                      onClick={() => handleCopyToClipBoard(contact)}
                    >
                      <ClipboardIcon fontSize={12} />
                    </IconButton>
                  )}
                </Box>
                <Box
                  display="flex"
                  flexWrap="wrap"
                  alignItems="center"
                  justifyContent="flex-end"
                  mr={-0.5}
                  ml={2}
                >
                  {!isEmail
                    ? contact && (
                        <>
                          <Box>
                            <a href={`tel:${contact}`} className={styles.link}>
                              <ButtonStyled buttonType="text" color="primary">
                                Call
                              </ButtonStyled>
                            </a>
                          </Box>
                          <Box ml={1}>
                            <a href={`sms:${contact}`} className={styles.link}>
                              <ButtonStyled buttonType="text" color="primary">
                                Text
                              </ButtonStyled>
                            </a>
                          </Box>
                        </>
                      )
                    : contact && (
                        <a href={`mailto:${contact}`} className={styles.link}>
                          <ButtonStyled buttonType="text" color="primary">
                            Email
                          </ButtonStyled>
                        </a>
                      )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {divider && <Divider />}
      </>
    );
  },
);
