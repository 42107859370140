import { createEffect, createStore, createEvent } from "effector/compat";

import { calculateQuote, getQuote } from "../api";
import { CalculatedQuote, Quote } from "../types";

export const getQuoteFx = createEffect(getQuote);
export const calculateQuoteFx = createEffect(calculateQuote);
export const setQuoteEv = createEvent<Quote | null>();
export const setCalculatedQuoteEv = createEvent<CalculatedQuote | null>();

export const $quotes = createStore<{
  quote: Quote | null;
  calculatedQuote: CalculatedQuote | null;
  loading: boolean;
}>({
  quote: null,
  calculatedQuote: null,
  loading: true,
});

$quotes
  .on(getQuoteFx.doneData, (store, response) => ({
    ...store,
    quote: response.data.quotes[0],
  }))
  .on(getQuoteFx.pending, (store, loading) => ({
    ...store,
    loading,
  }));

$quotes
  .on(calculateQuoteFx.doneData, (store, response) => ({
    ...store,
    calculatedQuote: response.data.quote[0],
  }))
  .on(calculateQuoteFx.pending, (store, loading) => ({
    ...store,
    loading,
  }));

$quotes.on(setQuoteEv, (store, value) => ({
  ...store,
  quote: value,
}));

$quotes.on(setCalculatedQuoteEv, (store, value) => ({
  ...store,
  calculatedQuote: value,
}));
