import { memo, useMemo } from "react";
import { Collapse } from "@material-ui/core";
import clsx from "clsx";

import { BottomPageButton } from "../bottom-page-button";

import { useStyles } from "./styles";
import { ActionsFooterProps } from "./types";

export const ActionsFooter = memo(
  ({ show = true, actions, topAdditionalContent }: ActionsFooterProps) => {
    const styles = useStyles();

    const filteredActions = useMemo(
      () => actions?.filter((action) => !action.hide) ?? [],
      [actions],
    );

    return (
      <div className={styles.root}>
        <Collapse in={show}>
          {topAdditionalContent && topAdditionalContent}

          {filteredActions.map(({ label, hideCollapse, className, ...rest }) =>
            hideCollapse !== undefined ? (
              <Collapse in={!hideCollapse}>
                <BottomPageButton
                  size="large"
                  fullWidth
                  className={clsx(
                    className,
                    (!show || hideCollapse) && "pointerEventsNone",
                  )}
                  {...rest}
                >
                  {label}
                </BottomPageButton>
              </Collapse>
            ) : (
              <BottomPageButton
                size="large"
                fullWidth
                className={clsx(className, !show && "pointerEventsNone")}
                {...rest}
              >
                {label}
              </BottomPageButton>
            ),
          )}
        </Collapse>
      </div>
    );
  },
);
