import { CalculateQuote, Quote } from "./types";

export const getCalculateQuotePayload = (
  quote: Quote,
): CalculateQuote.Params["payload"] => {
  return {
    appliances: quote.appliances,
    assemblyItems: quote.assemblyItems,
    category: quote.category,
    customItems: quote.customItems,
    date: quote.date,
    destination: quote.destination,
    forceHunks: true,
    hunks: quote.hunks,
    inventory: quote.inventory,
    isWeekend: quote.isWeekend,
    numberOfBoxes: quote.numberOfBoxes,
    origin: quote.origin,
    pricing: quote.pricing,
    rooms: quote.rooms,
    source: {
      id: 104,
    },
    spaces: quote.spaces.map((space) => ({
      ...space,
      typeId: space.type.id,
    })),
    stops: quote.stops,
    type: quote.type,
    workType: quote.workType,
    heavyItems: quote.heavyItems,
  };
};
