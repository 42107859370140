import { createEffect, createStore } from "effector/compat";

import { Valuation } from "../types";
import {
  generateValuation,
  getCurrentValuation,
  updateValuation,
} from "../api";

export const $valuation = createStore<{
  valuation: Valuation | null;
  loading: boolean;
}>({ valuation: null, loading: true });

export const getCurrentValuationFx = createEffect(getCurrentValuation);
$valuation
  .on(getCurrentValuationFx.doneData, (store, response) => ({
    ...store,
    valuation: response.data.shipperDeclaration[0],
  }))
  .on(getCurrentValuationFx.fail, (store) => ({
    ...store,
    valuation: null,
  }))
  .on(getCurrentValuationFx.pending, (store, loading) => ({
    ...store,
    loading,
  }));

export const generateValuationFx = createEffect(generateValuation);
$valuation
  .on(generateValuationFx.doneData, (store, response) => ({
    ...store,
    valuation: response.data.shipperDeclaration[0],
  }))
  .on(generateValuationFx.fail, (store) => ({
    ...store,
    valuation: null,
  }))
  .on(generateValuationFx.pending, (store, loading) => ({
    ...store,
    loading,
  }));

export const updateValuationFx = createEffect(updateValuation);
$valuation.on(updateValuationFx.doneData, (store, response) => ({
  ...store,
  valuation: response.data.shipperDeclaration[0],
}));
