import { fetcher } from "shared/libs";

import { GetDevelopments, AddDevelopment, EditDevelopment } from "./types";

export const getDevelopments = async ({
  locationId,
  appointmentId,
}: GetDevelopments.Params) =>
  fetcher.get<GetDevelopments.Response>(
    `/locations/${locationId}/appointments/${appointmentId}/developments`,
  );

export const addDevelopment = ({
  locationId,
  appointmentId,
  payload,
}: AddDevelopment.Params) =>
  fetcher.post<AddDevelopment.Response>(
    `/locations/${locationId}/appointments/${appointmentId}/developments`,
    payload,
  );

export const editDevelopment = async ({
  locationId,
  appointmentId,
  developmentId,
  payload,
}: EditDevelopment.Params) =>
  fetcher.put<EditDevelopment.Response>(
    `/locations/${locationId}/appointments/${appointmentId}/developments/${developmentId}`,
    payload,
  );
