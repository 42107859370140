import { colors } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  root: {
    padding: 16,
    backgroundColor: colors.grey10,
    borderBottom: `1px solid ${colors.grey30}`,
  },
  avatarIconButton: {
    padding: 0,
  },
  userName: {
    wordBreak: "break-word",
  },
  greeting: {
    color: colors.grey140,
    lineHeight: "17px",
  },
});
