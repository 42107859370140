import { createEffect, createStore } from "effector/compat";
import { createEvent } from "effector";

import { Notification } from "./types";
import { getNotifications, updateNotification } from "./api";

export const $notifications = createStore<{
  notifications: Notification[];
  filteredNotifications: Notification[];
  loadCount: number;
  loading: boolean;
}>({
  notifications: [],
  filteredNotifications: [],
  loadCount: 0,
  loading: true,
});

export const getNotificationsFx = createEffect(getNotifications);
$notifications
  .on(getNotificationsFx.doneData, (store, response) => ({
    ...store,
    notifications: response.data.notifications,
    filteredNotifications: response.data.notifications.filter(
      (notification) => !Number(notification.isDismissed),
    ),
    loadCount: store.loadCount + 1,
  }))
  .on(getNotificationsFx.pending, (store, loading) => ({
    ...store,
    loading,
  }));

export const updateNotificationFx = createEffect(updateNotification);
$notifications.on(updateNotificationFx.doneData, (store, response) => {
  const [updatedNotification] = response.data.notifications;

  const notifications = store.notifications.map((notification) =>
    notification.id === updatedNotification?.id
      ? updatedNotification
      : notification,
  );
  const filteredNotifications = notifications.filter(
    (notification) => !Number(notification.isDismissed),
  );

  return {
    ...store,
    notifications,
    filteredNotifications,
  };
});

export const dismissNotification = createEvent<{ id: number }>();
$notifications.on(dismissNotification, (store, payload) => ({
  ...store,
  filteredNotifications: store.filteredNotifications.filter(
    (notification) => Number(notification.id) !== payload.id,
  ),
}));

export const undoDismissNotification = createEvent<{ id: number }>();
$notifications.on(undoDismissNotification, (store, payload) => {
  const dismissedNotification = store.notifications.find(
    (notification) =>
      Number(notification.id) === payload.id &&
      !Number(notification.isDismissed),
  );

  return {
    ...store,
    filteredNotifications: dismissedNotification
      ? [...store.filteredNotifications, dismissedNotification]
      : store.filteredNotifications,
  };
});
