import { MouseEvent as ReactMouseEvent, memo, useCallback } from "react";
import { Typography } from "@material-ui/core";
import { Button, colors } from "@chhjpackages/components";

import { ListViewItemProps } from "./types";
import { useStyles } from "./styles";

export const ListViewItem = memo(
  ({ icon, label, text, actions, chips }: ListViewItemProps) => {
    const styles = useStyles();

    const handleActionClick = useCallback(
      (action) => (e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();

        action();
      },
      [],
    );

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 16,
          paddingTop: label ? 23 : undefined,
        }}
      >
        <div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                marginRight: 16,
                width: 24,
                height: 24,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {icon}
            </div>

            <div className={styles.content}>
              {label && (
                <Typography
                  variant="h6"
                  color="inherit"
                  className={styles.label}
                  component="div"
                >
                  {label}
                </Typography>
              )}

              {text && (
                <div style={{ fontSize: 18, flex: 1, wordBreak: "break-word" }}>
                  {text}
                </div>
              )}

              {chips && chips.length && (
                <div style={{ marginRight: 8 }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      margin: -4,
                    }}
                  >
                    {chips.map((chip, i) => (
                      <div key={i} style={{ padding: 4 }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "2.4px 8px",
                            width: "fit-content",
                            backgroundColor: colors.grey20,
                            borderRadius: 60,
                          }}
                        >
                          <div style={{ marginLeft: 4 }}>
                            <Typography variant="body2">{chip.text}</Typography>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {!!actions?.length && (
          <div className={styles.actions}>
            {actions.map((action, index) => (
              <Button
                key={index}
                color="primary"
                buttonType="text"
                disabled={action.disabled}
                isLoading={action.loading}
                onClick={handleActionClick(action.onClick)}
                classes={{ root: styles.actionButtonRoot }}
                style={action.style}
              >
                {action.title}
              </Button>
            ))}
          </div>
        )}
      </div>
    );
  },
);
