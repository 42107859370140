import { fetcher } from "shared/libs";

import { CalculateQuote, GetQuote } from "./types";

export const getQuote = ({ locationId, quoteId }: GetQuote.Params) =>
  fetcher.get<GetQuote.Response>(`locations/${locationId}/quotes/${quoteId}`);

export const calculateQuote = ({
  locationId,
  payload,
}: CalculateQuote.Params) =>
  fetcher.post<CalculateQuote.Response>(
    `locations/${locationId}/quotes/calculate`,
    payload,
  );
