import { useStore } from "effector-react/compat";
import { useCallback } from "react";

import {
  $completedActions,
  addCompletedActionFx,
  deleteCompletedActionFx,
} from "../store";
import { CompletedActionsJobEnum } from "shared/types";

export const useCompletedActions = () => {
  const { completedActions } = useStore($completedActions);

  const addCompletedAction = useCallback(
    async (
      appointmentId: number,
      locationId: number,
      type: CompletedActionsJobEnum,
    ) => {
      const isCompletedActionAlreadyExist = completedActions.find(
        (completedAction) => completedAction.type.id === type,
      );

      if (isCompletedActionAlreadyExist) {
        return;
      }

      await addCompletedActionFx({
        appointmentId,
        locationId,
        payload: {
          type: {
            id: type,
          },
        },
      });
    },
    [completedActions],
  );

  const deleteCompletedAction = useCallback(
    async (
      appointmentId: number,
      locationId: number,
      type: CompletedActionsJobEnum,
    ) => {
      const isCompletedActionExist = completedActions.find(
        (completedAction) => completedAction.type.id === type,
      );

      if (!isCompletedActionExist) {
        return;
      }

      await deleteCompletedActionFx({
        appointmentId,
        locationId,
        completedActionId: isCompletedActionExist.id,
      });
    },
    [completedActions],
  );

  return { addCompletedAction, deleteCompletedAction };
};
