import { colors } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  root: {
    padding: 16,
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
  itemLine: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 16,
  },
  checkboxWrapper: {
    marginLeft: 2,
  },
  dividerContainer: {
    margin: "8px -16px 0px",
  },
  balanceDueLine: {
    marginTop: 8,
  },
  balanceDue: {
    fontWeight: "bold",
    color: colors.grey140,
  },
  balanceDueValue: {
    fontSize: 36,
  },
}));
