import { memo } from "react";
import { Typography } from "@material-ui/core";

import { useStyles } from "./styles";

export const Page2 = memo(() => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <div className={styles.column}></div>
      <div className={styles.column}>
        <div>
          <Typography variant="h1" className={styles.letterRow}>
            H. U. N. K. S.
          </Typography>

          <div className={styles.description}>
            <Typography
              variant="h1"
              color="inherit"
              component="div"
              className={styles.letterRow}
            >
              <span>h</span>
              onest
            </Typography>
            <Typography
              variant="h1"
              color="inherit"
              component="div"
              className={styles.letterRow}
            >
              <span>u</span>
              niformed
            </Typography>
            <Typography
              variant="h1"
              color="inherit"
              component="div"
              className={styles.letterRow}
            >
              <span>n</span>
              ice
            </Typography>
            <Typography
              variant="h1"
              color="inherit"
              component="div"
              className={styles.letterRow}
            >
              <span>k</span>
              nowledgeable
            </Typography>
            <Typography
              variant="h1"
              color="inherit"
              component="div"
              className={styles.letterRow}
            >
              <span>s</span>
              ervice
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
});
