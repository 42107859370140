import { colors } from "@chhjpackages/components";
import { SvgIcon } from "@material-ui/core";

export const ArrowLeftSliderIcon = ({
  className,
  color = colors.grey140,
  fontSize = 12,
}: {
  className?: string;
  color?: string;
  fontSize?: number;
}) => (
  <SvgIcon
    className={className}
    style={{ fontSize }}
    width="6"
    height="11"
    viewBox="0 0 6 11"
    fill="none"
  >
    <path
      d="M5.14604 0.800018C5.03318 0.799369 4.9213 0.820916 4.81681 0.863427C4.71233 0.905938 4.6173 0.968576 4.53717 1.04775L0.249372 5.31893C0.0896506 5.47898 0 5.69549 0 5.92117C0 6.14685 0.0896506 6.36336 0.249372 6.52341L4.53717 10.7946C4.70122 10.9345 4.91225 11.0077 5.12807 10.9994C5.3439 10.9911 5.54863 10.9019 5.70136 10.7498C5.85409 10.5977 5.94356 10.3937 5.95189 10.1787C5.96023 9.96374 5.88682 9.75354 5.74633 9.59012L2.0674 5.92544L5.74633 2.26076C5.86675 2.14179 5.94905 1.98992 5.98282 1.82436C6.0166 1.6588 6.00033 1.48698 5.93608 1.33063C5.87182 1.17427 5.76247 1.04041 5.62185 0.945962C5.48122 0.851516 5.31564 0.800727 5.14604 0.800018Z"
      fill={color}
    />
  </SvgIcon>
);
