import { colors } from "@chhjpackages/components";
import { SvgIcon } from "@material-ui/core";

export const ConnectionIcon = ({
  className,
  color = colors.functionals.alert,
  fontSize = 24,
}: {
  className?: string;
  color?: string;
  fontSize?: number;
}) => (
  <SvgIcon
    className={className}
    style={{ fontSize }}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clipPath="url(#clip0_113_10015)">
      <path
        d="M12 11.889C13.6569 11.889 15 10.5458 15 8.88898C15 7.23212 13.6569 5.88898 12 5.88898C10.3431 5.88898 9 7.23212 9 8.88898C9 10.5458 10.3431 11.889 12 11.889Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M12.001 11.889L11.998 22.889"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M4.22255 16.667C-0.0663705 12.3685 -0.0663705 5.40946 4.22255 1.11099"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M19.7793 1.11099C24.0682 5.40946 24.0682 12.3685 19.7793 16.667"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M7.05069 13.839C4.32164 11.1032 4.32164 6.67478 7.05069 3.939"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M16.9512 3.939C19.6802 6.67478 19.6802 11.1032 16.9512 13.839"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M12 11.889C13.6569 11.889 15 10.5458 15 8.88898C15 7.23212 13.6569 5.88898 12 5.88898C10.3431 5.88898 9 7.23212 9 8.88898C9 10.5458 10.3431 11.889 12 11.889Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <line
        x1="0.353553"
        y1="0.646447"
        x2="22.981"
        y2="23.2739"
        stroke={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_113_10015">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
