import { Button } from "@chhjpackages/components";
import { Box, Typography } from "@material-ui/core";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMemo } from "react";

import { FormInput, FormSelect } from "shared/ui";

import {
  SendPaymentLinkFormProps,
  SendPaymentLinkFormValues,
  SendPaymentLinkMethodsEnum,
} from "./model";

const sendPaymentLinkSchema = yup.object({
  method: yup.string().required("Method is required"),
  email: yup.string().when("method", (method, schema) => {
    if (method === SendPaymentLinkMethodsEnum.email) {
      return schema
        .email("Must be a valid email")
        .required("Email is required");
    }
  }),
  phone: yup.string().when("method", (method, schema) => {
    if (method === SendPaymentLinkMethodsEnum.phone) {
      return schema.required("Phone number is required");
    }
  }),
});

export const SendPaymentLinkForm = ({
  initialValues,
  onSubmit,
  onClose,
}: SendPaymentLinkFormProps) => {
  const { control, formState, handleSubmit, watch } =
    useForm<SendPaymentLinkFormValues>({
      mode: "all",
      defaultValues: {
        ...initialValues,
        method: initialValues.method ?? SendPaymentLinkMethodsEnum.email,
      },
      resolver: yupResolver(sendPaymentLinkSchema),
    });

  const methodWatch = watch("method");

  const methodOptions = useMemo(
    () => [SendPaymentLinkMethodsEnum.email, SendPaymentLinkMethodsEnum.phone],
    [],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="body1">Send payment link</Typography>
      <div>
        <FormSelect
          control={control}
          name="method"
          required
          label="Method"
          options={methodOptions}
        />
      </div>
      <Box mt={1}>
        {methodWatch === SendPaymentLinkMethodsEnum.email && (
          <FormInput
            control={control}
            name="email"
            label="Email"
            disabled
            required
          />
        )}
        {methodWatch === SendPaymentLinkMethodsEnum.phone && (
          <FormInput
            control={control}
            name="phone"
            required
            label="Phone Number"
            mask="phone"
            disabled
          />
        )}
      </Box>
      <Box mt={3} display="flex" alignItems="center" justifyContent="flex-end">
        <Box mr={1}>
          <Button buttonType="text" color="primary" onClick={onClose}>
            Exit
          </Button>
        </Box>
        <Box width={136}>
          <Button
            buttonType="twoTone"
            type="submit"
            fullWidth
            isLoading={formState.isSubmitting}
            disabled={!formState.isValid}
          >
            Send
          </Button>
        </Box>
      </Box>
    </form>
  );
};
