import { colors } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  mapTypeItem: {
    color: colors.grey120,
    fontWeight: "bold",
    cursor: "pointer",
    width: "fit-content",

    "&:hover": {
      color: colors.grey100,
    },
  },

  actionButtonsContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: 32,
  },
}));
