export const reviews = [
  {
    text: "Probably the best experience I’ve had moving. I hired them for two days and both dates they were on time and very efficient. They were moving all of my boxes in the heat and never complained once. Not only were they fast, they were very nice and pleasant to work with. I would use College Hunks for any move.",
    author: "Lucy G.",
    address: "Portland, OR",
  },
  {
    text: "Not one complaint. They were on time, worked so hard in the heat, moved at a good pace, and they treated our stuff as if it was their own. I feel you get what you pay for in a moving service, and they were great, professional and I didn't have worry about anything being lost or damaged. I would defiantly recommend.",
    author: "Chelsey S.",
    address: "Burlingame, CA",
  },
  {
    text: "I’ve now used College Hunks for 2 moves and can honestly say I will continue to use them for any future moving needs. Both moves were during the summer on very hot and humid days and the 3 guys worked incredibly hard despite the tough conditions. They arrived exactly on time, were courteous and professional, and were very careful with my more important furniture pieces. One of the guys was part of the crew from the first move and even remem- bered us after a year. Great service, highly recommend!",
    author: "Bailey F.",
    address: "St. Louis, MO",
  },
];
