import { Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { memo, useCallback } from "react";
import { colors } from "@chhjpackages/components";

import { PricingCard, PricingCardSkeleton } from "features/pricings";
import { PricingItem } from "features/add-product";
import { EmptyBoxIcon } from "shared/assets";

import { PricingListProps } from "./types";
import { useStyles } from "./styles";

export const PricingsList = memo(
  ({
    groupedPricings,
    isAllCategories,
    noPricing,
    loading,
    disable,
    onChangeQuantity,
    onChangeFavorite,
    onPricingClick,
    errorCallback,
  }: PricingListProps) => {
    const styles = useStyles();

    const handlePricingClick = useCallback(
      (pricing: PricingItem) => {
        if (disable) {
          return;
        }

        onPricingClick(pricing);
      },
      [disable, onPricingClick],
    );

    return (
      <div style={{ width: "100%" }}>
        {loading && (
          <>
            <Typography variant="h5">
              <Skeleton variant="text" width={110} />
            </Typography>
            {[...Array(3)].map((_, i) => (
              <div key={i} style={{ marginTop: 16 }}>
                <PricingCardSkeleton />
              </div>
            ))}
          </>
        )}
        {!loading &&
          !noPricing &&
          Object.keys(groupedPricings).map((category, categoryIndex) => (
            <div
              key={category}
              style={{
                marginTop: isAllCategories && categoryIndex !== 0 ? 24 : 0,
              }}
            >
              {isAllCategories && (
                <Typography variant="h3">{category}</Typography>
              )}

              {Object.keys(groupedPricings[category]).map((type, typeIndex) => (
                <div
                  key={type}
                  style={{
                    marginTop: isAllCategories || typeIndex !== 0 ? 16 : 0,
                  }}
                >
                  <Typography variant="h5">{type}</Typography>

                  {groupedPricings[category][type].map((pricing) => (
                    <div
                      key={pricing.key}
                      style={{ marginTop: 16, cursor: "pointer" }}
                      onClick={() => handlePricingClick(pricing)}
                    >
                      <PricingCard
                        title={pricing.name}
                        price={pricing.actualPrice}
                        quantity={pricing.quantity}
                        isFavorite={pricing.isFavorite}
                        disableActions={disable}
                        hideFavorite={pricing.hideFavorite}
                        onChangeQuantity={(value) =>
                          onChangeQuantity(value, pricing)
                        }
                        onChangeFavorite={() => onChangeFavorite(pricing.id)}
                        errorCallback={errorCallback}
                      />
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ))}
        {!loading && noPricing && (
          <div
            style={{ display: "flex", justifyContent: "center", margin: 16 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                flex: 1,
              }}
            >
              <EmptyBoxIcon className={styles.emptyBoxIcon} />
              <div
                style={{
                  marginTop: 48,
                  color: colors.grey140,
                  textAlign: "center",
                }}
              >
                <Typography variant="h4" color="inherit">
                  Pricing for this product has not been set up
                </Typography>
                <div style={{ marginTop: 16 }}>
                  <Typography variant="body1" color="inherit">
                    Please contact your manager for help
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  },
);
