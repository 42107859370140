import { colors, IncrementInput, Paper } from "@chhjpackages/components";
import { IconButton, Typography } from "@material-ui/core";
import {
  memo,
  MouseEvent as ReactMouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { StarFilledIcon, StarIcon } from "shared/assets";

import { PricingCardProps } from "./types";
import { useStyles } from "./styles";

export const PricingCard = memo(
  ({
    title,
    price,
    quantity: quantityProp,
    isFavorite,
    disableActions,
    hideFavorite,
    onChangeQuantity,
    onChangeFavorite,
    errorCallback,
  }: PricingCardProps) => {
    const styles = useStyles();

    const [quantity, setQuantity] = useState(quantityProp);

    const isQuantityError = useMemo(() => quantity % 0.25 !== 0, [quantity]);

    const handleChangeQuantity = useCallback(
      (value) => {
        if (value === quantity) {
          return;
        }

        const isSuccess = onChangeQuantity(value);

        if (isSuccess) {
          setQuantity(value);
        }
      },
      [onChangeQuantity, quantity],
    );

    const handleChageFavorite = useCallback(
      (e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (disableActions) {
          return;
        }

        e.stopPropagation();
        onChangeFavorite();
      },
      [disableActions, onChangeFavorite],
    );

    useEffect(() => {
      if (errorCallback) {
        errorCallback(isQuantityError);
      }
    }, [isQuantityError]);

    useEffect(() => {
      setQuantity(quantityProp);
    }, [quantityProp]);

    return (
      <Paper elevation={16}>
        <div
          style={{
            padding: 16,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 24,
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: 24, minWidth: 28 }}>
              {!hideFavorite && (
                <IconButton size="small" onClick={handleChageFavorite}>
                  {isFavorite ? (
                    <StarFilledIcon fontSize={22} />
                  ) : (
                    <StarIcon fontSize={22} />
                  )}
                </IconButton>
              )}
            </div>
            <div>
              <Typography variant="subtitle1" style={{ color: colors.grey190 }}>
                {title}
              </Typography>

              <Typography variant="subtitle1" style={{ color: colors.grey140 }}>
                ${price.toFixed(2)}
              </Typography>
            </div>
          </div>
          <div onClick={(e) => e.stopPropagation()}>
            <IncrementInput
              value={quantity}
              min={0}
              step={1}
              error={isQuantityError}
              onChange={handleChangeQuantity}
              mask="positive-two-decimal"
              disabled={disableActions}
              classes={{
                button: styles.incrementButton,
                inputWrapper: styles.incrementInputWrapper,
              }}
            />
          </div>
        </div>
      </Paper>
    );
  },
);
