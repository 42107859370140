import { colors } from "@chhjpackages/components";
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { memo, useCallback } from "react";

import { FilterPicker, FormInput } from "shared/ui";
import { DiscountTypesEnum } from "shared/types";

import { DiscountFormProps } from "./model";

export const DiscountForm = memo(
  ({
    control,
    disabled,
    setValue,
    watch,
  }: Pick<
    DiscountFormProps,
    "control" | "disabled" | "setValue" | "watch"
  >) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"), {
      noSsr: true,
    });

    const typeWatch = watch("type");

    const handleChangeType = useCallback(
      (type: number) => {
        setValue("type", type, { shouldDirty: true, shouldValidate: true });
      },
      [setValue],
    );

    return (
      <Box p={2} pb={3} bgcolor={colors.white}>
        <Typography variant="subtitle1">Discount type</Typography>
        <Box mt={1}>
          <Grid
            container
            spacing={2}
            justify={isMobile ? "space-between" : "flex-start"}
          >
            <Grid item>
              <FilterPicker
                title="Dollar Amount"
                isActive={typeWatch === DiscountTypesEnum.Dollar}
                disabled={disabled}
                onClick={() => handleChangeType(DiscountTypesEnum.Dollar)}
              />
            </Grid>
            <Grid item>
              <FilterPicker
                title="Percentage"
                isActive={typeWatch === DiscountTypesEnum.Percentage}
                disabled={disabled}
                onClick={() => handleChangeType(DiscountTypesEnum.Percentage)}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mt={2}>
          <FormInput
            name={disabled ? "" : "discount"}
            control={control}
            label="Discount"
            disabled={disabled}
          />
        </Box>
      </Box>
    );
  },
);
