import { useDialogState } from "@chhjpackages/components";
import { memo, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

export const ScrollToTop = memo(() => {
  const { pathname } = useLocation();

  const { open } = useDialogState();

  const openRef = useRef(open);

  useEffect(() => {
    if (openRef.current) {
      setTimeout(() => {
        openRef.current = open;
      }, 1);
    } else {
      openRef.current = open;
    }
  }, [open]);

  useEffect(() => {
    setTimeout(() => {
      if (!openRef.current) {
        window.scrollTo(0, 0);
      }
    }, 0);
  }, [pathname]);

  return null;
});
