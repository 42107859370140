import { Button } from "@chhjpackages/components";
import { Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

export const TeamButtonSkeleton = () => {
  return (
    <Button buttonType="text" disabled={true} style={{ padding: "0px 8px" }}>
      <Typography
        variant="body1"
        color="inherit"
        align="left"
        style={{ textTransform: "initial" }}
      >
        <Skeleton variant="text" width={131} />
      </Typography>
    </Button>
  );
};
