import { colors } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/core";

export const useAddendumStyles = makeStyles(() => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.white,
  },
  content: {
    flex: 1,
    padding: 16,
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },
  title: {
    color: colors.grey140,
  },
}));
