import { colors } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/core";

export const useDashboardStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  titleContainer: {
    marginTop: 16,
    padding: "0px 16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  teamButtonContainer: {
    margin: -8,
  },
  usedFiltersContainer: {
    backgroundColor: colors.grey20,
    padding: "8px 16px",
  },
  contentRoot: {
    padding: 16,
    display: "flex",
    flexDirection: "column",
    flex: 1,
    position: "relative",

    [theme.breakpoints.down("md")]: {
      position: "initial",
    },
  },
  changedQueueLink: {
    textDecoration: "none",
    cursor: "pointer",
    marginBottom: 16,
  },
  appoinmentsListContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "initial",
    flex: 1,
    borderRadius: 4,
    padding: 16,
    backgroundColor: colors.basic.white,
    boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.15)",

    [theme.breakpoints.down("md")]: {
      padding: 0,
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
  appoinmentsListContainerNoAppointments: {
    justifyContent: "center",
  },
  backToTop: {
    bottom: 0,
  },
  backToTopWithEta: {
    bottom: 48,
  },
  etaBanner: {
    marginBottom: -16,
    marginTop: -3,
    marginLeft: 0,
    width: "100%",

    [theme.breakpoints.down("md")]: {
      marginTop: 0,
      marginLeft: -16,
      width: "calc(100% + 32px)",
    },
  },
  filtersBarContainer: {
    [theme.breakpoints.up("lg")]: {
      marginTop: 70,
      marginRight: 16,
      paddingBottom: 16,
      top: 16,
      width: 266,
      height: "auto",
      maxHeight: "calc(100vh - 16px)",
    },
  },
}));
