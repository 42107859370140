import { makeStyles, Theme } from "@material-ui/core";
import { colors } from "@chhjpackages/components";

export const useStyles = makeStyles((theme: Theme) => ({
  bulletNav: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    maxWidth: 320,
    margin: "0 auto",
  },

  bullet: {
    width: 10,
    height: 10,
    borderRadius: 10,
    backgroundColor: colors.grey40,
    display: "inline-block",
    margin: 4,
    position: "relative",
    transition: "all .25s easy",

    "&:before": {
      content: "''",
      display: "block",
      width: "100%",
      height: "100%",
      borderRadius: "100%",
      position: "absolute",
      top: 0,
      left: 0,
      backgroundColor: colors.primary.main,
      transform: "scale(0)",
      transition: "all 0.35s linear",
    },

    "&.active": {
      "&:before": {
        transform: "scale(1)",
      },
    },
  },

  bulletButton: {
    width: 60,

    [theme.breakpoints.down("xs")]: {
      width: 50,
    },
  },

  bulletText: {
    color: colors.grey120,
  },
}));
