import { createEffect, createStore } from "effector/compat";

import { getAvailabilities } from "../api";
import { Availability } from "../types";

export const $availabilities = createStore<{
  loading: boolean;
  availabilities: Availability[];
}>({
  loading: true,
  availabilities: [],
});

export const getAvailabilitiesFx = createEffect(getAvailabilities);

$availabilities
  .on(getAvailabilitiesFx.doneData, (store, response) => ({
    ...store,
    availabilities: response.data.availability,
  }))
  .on(getAvailabilitiesFx.pending, (store, loading) => ({
    ...store,
    loading,
  }));
