import Resizer from "react-image-file-resizer";

export const resizeImage = async (
  imgToResize: Blob,
  maxWidth: number,
  maxHeight: number,
): Promise<Blob | null> => {
  return new Promise((resolve) => {
    let image = new Image();
    image.src = URL.createObjectURL(imgToResize);
    image.onload = () => {
      const width = image.width;
      const height = image.height;

      if (width <= maxWidth && height <= maxHeight) {
        resolve(imgToResize);
      }

      let newWidth = maxWidth;
      let newHeight = maxHeight;

      if (width > height && width > maxWidth) {
        const residual = height / maxHeight;
        newHeight = maxHeight;
        newWidth = width / residual;
      } else if (height > width && height > maxHeight) {
        const residual = width / maxWidth;
        newWidth = maxWidth;
        newHeight = height / residual;
      }

      Resizer.imageFileResizer(
        imgToResize,
        newWidth,
        newHeight,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri as Blob);
        },
        "blob",
        newWidth,
        newHeight,
      );
    };
  });
};
