import { makeStyles } from "@material-ui/core";
import { NoahFont } from "@chhjpackages/components";

export const useStyles = makeStyles({
  divider: {
    margin: "24px 0",
  },
  checkboxWrapper: {
    padding: "0 14px",
  },
  buttonContainer: {
    marginBottom: 16,
  },
  button: {
    "& .MuiButton-text": {
      padding: 0,
    },
    "& .MuiButton-root": {
      fontFamily: NoahFont,
      letterSpacing: 1.25,
      lineHeight: "17px",
    },
    marginRight: 24,
  },
});
