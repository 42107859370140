import { memo, useCallback, useMemo } from "react";
import { Box, Typography } from "@material-ui/core";
import { Button, Checkbox, colors } from "@chhjpackages/components";

import { AppointmentStatus, StepStatusesEnum } from "shared/types";
import {
  Requirement,
  markRequirementAsCompletedEv,
  markRequirementAsUncompletedEv,
} from "features/requirements";

import { ProcessCompleteProps } from "./model";

export const ProcessComplete = memo(
  ({
    appointment,
    disabled,
    requirements = [],
    handleCompleteAppointmentDialog,
  }: ProcessCompleteProps) => {
    const isNasa = useMemo(
      () => !!appointment?.subpartner.id,
      [appointment?.subpartner.id],
    );

    const isJobClosed = useMemo(
      () =>
        appointment?.type === "JOB" &&
        appointment?.status.id === AppointmentStatus.Job.Closed,
      [appointment?.type, appointment?.status.id],
    );

    const isAppointmentCompleted = useMemo(
      () =>
        appointment?.type === "EST"
          ? appointment?.estStatus.id === AppointmentStatus.Estimate.Completed
          : appointment?.status.id === AppointmentStatus.Job.Completed,
      [appointment?.type, appointment?.status.id, appointment?.estStatus.id],
    );

    const thereIsUncompletedRequirements = useMemo(
      () =>
        !!requirements.find(
          (requirement) => requirement.status !== StepStatusesEnum.Done,
        ),
      [requirements],
    );

    const disabledComplete = useMemo(
      () =>
        (isJobClosed
          ? true
          : isNasa
          ? thereIsUncompletedRequirements || isAppointmentCompleted
          : isAppointmentCompleted) || disabled,
      [
        isJobClosed,
        thereIsUncompletedRequirements,
        disabled,
        isAppointmentCompleted,
        isNasa,
      ],
    );

    const changeCheckbox = useCallback(
      (requirement: Requirement) => {
        if (!appointment?.id) {
          return;
        }

        if (requirement.status === StepStatusesEnum.Done) {
          markRequirementAsUncompletedEv({
            appointmentId: appointment.id,
            requirementId: requirement.id,
          });
        } else {
          markRequirementAsCompletedEv({
            appointmentId: appointment.id,
            requirementId: requirement.id,
          });
        }
      },
      [appointment?.id],
    );

    return (
      <Box>
        {isNasa ? (
          <Box mb={2} flexDirection="column">
            {requirements.map((requirement, idx) => (
              <Box
                key={requirement.title}
                px={3}
                pb={2}
                pt={idx === 0 ? 2 : 0}
                style={{ backgroundColor: colors.grey10 }}
              >
                <Checkbox
                  label={requirement.title}
                  variant="outline"
                  checked={requirement.status === StepStatusesEnum.Done}
                  onChange={() => changeCheckbox(requirement)}
                  disabled={isAppointmentCompleted}
                />
              </Box>
            ))}
          </Box>
        ) : (
          <Box
            mt={1}
            mb={2}
            px={2}
            py={1}
            color={colors.grey160}
            bgcolor={colors.grey10}
          >
            <Typography variant="body1" color="inherit">
              After completing all the tasks, please make sure to mark the
              appointment as completed by clicking the button below.
            </Typography>
          </Box>
        )}

        <Box width="100%">
          <Button
            buttonType="twoTone"
            size="medium"
            fullWidth
            disabled={disabledComplete}
            onClick={handleCompleteAppointmentDialog}
          >
            Complete appointment
          </Button>
        </Box>
      </Box>
    );
  },
);
