import { makeStyles, createStyles, withStyles, List } from "@material-ui/core";
import { colors } from "@chhjpackages/components";

export const ListStyled = withStyles(() =>
  createStyles({
    root: {
      paddingBottom: 0,
      backgroundColor: colors.grey10,
    },
  }),
)(List);

export const useStyles = makeStyles(() =>
  createStyles({
    categoryContainer: {
      paddingTop: "24px",
      "&:first-child": {
        paddingTop: 0,
      },
    },
    noPricingContainer: {
      padding: "20px 0 12px",
      "& .MuiTypography-root": {
        color: colors.functionals.alert,
      },
    },
  }),
);
