import { openInNewTab } from "./open-in-new-tab";

export const openMapByType = (
  mapType: "apple" | "google" | "waze",
  from: string,
  to: string,
) => {
  switch (mapType) {
    case "apple":
      openInNewTab(`https://maps.apple.com/?saddr=${from}&daddr=${to}`);
      break;

    case "google":
      openInNewTab(
        `https://maps.google.com/maps?mapclient=embed&saddr=${from}&daddr=${to}&dirflg=d`,
      );
      break;

    case "waze":
      openInNewTab(`https://waze.com/ul?q=${to}&navigate=yes`);
      break;

    default:
      break;
  }
};
