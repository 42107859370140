import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  actionContainer: {
    marginRight: "36px",
  },
  squareButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 3,
    paddingTop: 6,
    width: 20,
    height: 20,

    "& svg": {
      fontSize: 14,
    },
  },
}));
