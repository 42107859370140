import { createEffect, createStore } from "effector";

import { Rankings } from "shared/types";
import { getRanking } from "../api";
import { getRankingsInOrder } from "../utils";

export const getRankingFx = createEffect(getRanking);

export const $rankingStore = createStore<{
  userMetrics: Rankings;
  error: Error | null;
  loading: boolean;
}>({
  userMetrics: {
    sales: [],
    average_job: [],
    completion_percent: [],
    sales_per_hour: [],
  },
  error: null,
  loading: false,
});

$rankingStore
  .on(getRankingFx.doneData, (store, response) => ({
    ...store,
    userMetrics: getRankingsInOrder(response.data),
  }))
  .on(getRankingFx.pending, (store, loading) => ({
    ...store,
    loading,
  }))
  .on(getRankingFx.failData, (store, error) => ({
    ...store,
    error,
  }));
