import { Theme, makeStyles } from "@material-ui/core";

import Page1 from "../../assets/images/page-1.png";

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: "100%",
    width: "100%",
    backgroundImage: `url(${Page1})`,
    backgroundSize: "cover",
    position: "relative",
    backgroundPosition: "left 35% center",

    "@media (orientation: portrait)": {
      backgroundPosition: "left 12% center",
    },
  },

  sidebar: {
    position: "absolute",
    top: 0,
    right: 0,
    height: "100%",
    width: "35%",
    backgroundColor: "rgba(255, 255, 255, 0.6)",
    backdropFilter: "blur(21px)",
    padding: 24,

    "@media (orientation: portrait)": {
      width: "45%",

      [theme.breakpoints.down("xs")]: {
        width: "70%",
        backgroundColor: "rgba(255, 255, 255, 0.5)",
      },
    },
  },

  content: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },

  logo: {
    width: 140,
    height: "auto",
    marginBottom: 32,
  },

  text1: {
    fontSize: 60,
  },

  text2: {
    fontSize: 36,
    opacity: 0.6,
  },

  webLink: {
    marginTop: "auto",
    fontSize: 22,
    position: "absolute",
    bottom: 0,
  },
}));
