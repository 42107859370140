import { RequestProp } from "./types";

export const findDuplication = (
  offlineRequests: RequestProp[],
  event: RequestProp,
) => {
  return offlineRequests.find(
    ({ id, description }) =>
      id === event.id && description === event.description,
  );
};

export const checkDublication = (
  offlineRequests: RequestProp[],
  event: RequestProp,
) => {
  return offlineRequests.find(
    ({ endState, startState, description, id, config }) =>
      endState === event.endState &&
      startState === event.startState &&
      description === event.description &&
      id === event.id &&
      config.baseURL === event.config.baseURL,
  );
};
