import { PricingResponse } from "features/appointment";
import { Pricing, Product, TaxCode } from "shared/types";
import { PricingItem } from "features/add-product";
import { getUniqueNumberId, groupBy } from "shared/utils";

import {
  PricingCategoriesEnum,
  PricingCategoriesNameEnum,
  PricingTypesEnum,
} from "./types";

export const getFavoritePricingsId = (): number[] => {
  const favoritePricingsString = localStorage.getItem("crew/favorite-pricing");

  if (favoritePricingsString) {
    return JSON.parse(favoritePricingsString);
  }

  return [];
};

export const setFavoritePricingsId = (pricingId: number) => {
  const favoritePricingsId = getFavoritePricingsId();

  if (favoritePricingsId.includes(pricingId)) {
    localStorage.setItem(
      "crew/favorite-pricing",
      JSON.stringify(
        favoritePricingsId.filter((favoriteId) => favoriteId !== pricingId),
      ),
    );
  } else {
    localStorage.setItem(
      "crew/favorite-pricing",
      JSON.stringify([...favoritePricingsId, pricingId]),
    );
  }
};

const getPricingCategory = (subcategoryName: string) => {
  const subcategoryNameLowerCase = subcategoryName.toLowerCase();

  if (subcategoryNameLowerCase.startsWith("express junk")) {
    return {
      id: PricingCategoriesEnum.ExpressJunk,
      name: PricingCategoriesNameEnum.ExpressJunk,
    };
  } else if (subcategoryNameLowerCase.startsWith("general labor")) {
    return {
      id: PricingCategoriesEnum.GeneralLabor,
      name: PricingCategoriesNameEnum.GeneralLabor,
    };
  } else if (subcategoryNameLowerCase.startsWith("junk removal")) {
    return {
      id: PricingCategoriesEnum.JunkRemoval,
      name: PricingCategoriesNameEnum.JunkRemoval,
    };
  } else if (subcategoryNameLowerCase.startsWith("moving - labor")) {
    return {
      id: PricingCategoriesEnum.MoveLabor,
      name: PricingCategoriesNameEnum.MoveLabor,
    };
  } else if (subcategoryNameLowerCase.startsWith("moving - accessorial")) {
    return {
      id: PricingCategoriesEnum.MovingAccessorial,
      name: PricingCategoriesNameEnum.MovingAccessorial,
    };
  } else if (subcategoryNameLowerCase.startsWith("moving")) {
    return {
      id: PricingCategoriesEnum.Moving,
      name: PricingCategoriesNameEnum.Moving,
    };
  } else {
    return {
      id: 0,
      name: subcategoryName,
    };
  }
};

const getPricingType = (subcategoryName: string, categoryId: number) => {
  const subcategoryNameLowerCase = subcategoryName.toLowerCase();

  if (subcategoryNameLowerCase.includes("basic")) {
    return {
      id: PricingTypesEnum.Basic,
      name: "Basic",
    };
  } else if (subcategoryNameLowerCase.includes("surcharges")) {
    return {
      id: PricingTypesEnum.Surcharges,
      name: "Surcharges",
    };
  } else if (subcategoryNameLowerCase.includes("flat rate")) {
    return {
      id: PricingTypesEnum.FlatRate,
      name: "Flat Rate",
    };
  } else {
    switch (categoryId) {
      case PricingCategoriesEnum.ExpressJunk:
        return {
          id: 0,
          name: subcategoryName.replace("Express Junk - ", ""),
        };
      case PricingCategoriesEnum.GeneralLabor:
        return {
          id: 0,
          name: subcategoryName.replace("General Labor - ", ""),
        };
      case PricingCategoriesEnum.JunkRemoval:
        return {
          id: 0,
          name: subcategoryName.replace("Junk Removal - ", ""),
        };
      case PricingCategoriesEnum.MoveLabor:
        return {
          id: 0,
          name: subcategoryName.replace("Moving - Labor - ", ""),
        };
      case PricingCategoriesEnum.Moving:
        return {
          id: 0,
          name: subcategoryName.replace("Moving - ", ""),
        };
      default:
        return {
          id: 0,
          name: subcategoryName.split("-").pop() ?? "",
        };
    }
  }
};

export const getPricingsFromResponse = (
  pricingsResponse: PricingResponse[],
): Pricing[] => {
  const favoritePricingsId = getFavoritePricingsId();

  return pricingsResponse.map((pricing) => {
    const category = getPricingCategory(pricing.subcategory.name);
    const type = getPricingType(pricing.subcategory.name, category.id);

    return {
      ...pricing,
      isFavorite: favoritePricingsId.includes(pricing.id),
      category: category,
      isLabor: pricing.subcategory.name.toLowerCase().includes("labor"),
      type: type,
    };
  });
};

export const checkIsLabor = (productName: string) =>
  productName.includes("Hunks *Price Per Hour");

export const getMatchedPricingWithProducts = (
  pricingsStore: Pricing[],
  products: Product[],
  taxCodes: TaxCode[],
) => {
  let pricings: PricingItem[] = pricingsStore.map((pricing) => ({
    ...pricing,
    actualPrice: pricing.price,
    key: getUniqueNumberId(),
    isOriginal: true,
    quantity: 0,
    productLineId: 0,
    notes: "",
  }));

  const productsById = groupBy(products, (product) => product.productId);

  Object.entries(productsById).forEach(([, productsByKey]) => {
    const product = productsByKey[0];

    const pricingIndex = pricings.findIndex(
      (pricing) => pricing.id === product.productId,
    );

    if (pricingIndex >= 0) {
      pricings[pricingIndex] = {
        ...pricings[pricingIndex],
        quantity: product.quantity,
        actualPrice: product.actualPrice,
        tax:
          taxCodes.find((taxCode) => taxCode.id === product.salesTaxId) ??
          pricings[pricingIndex].tax,
        notes: product.notes,
        productLineId: product.productLineId,
      };
    }

    if (productsByKey.length > 1) {
      const pricing = pricings.find((p) => p.id === product.productId);

      if (pricing) {
        const [, ...restProducts] = productsByKey;

        pricings = [
          ...pricings,
          ...restProducts.map((restProduct, index) => ({
            ...pricing,
            key: getUniqueNumberId(),
            isOriginal: false,
            name: `${pricing.name} ${index + 2}`,
            quantity: restProduct.quantity,
            actualPrice: restProduct.actualPrice,
            tax:
              taxCodes.find(
                (taxCode) => taxCode.id === restProduct.salesTaxId,
              ) ?? pricing.tax,
            notes: restProduct.notes,
            productLineId: restProduct.productLineId,
            hideFavorite: true,
          })),
        ];
      }
    }
  });

  return pricings;
};
