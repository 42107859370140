import { Appointments } from "../types";
import { createAddressString } from "shared/utils";

export const generateRoute = (appointment?: Appointments | null) => {
  const strings: { [key: string]: string | null } = {
    originString: null,
    destinationString: null,
    stopsString: null,
  };

  if (appointment) {
    const { origin, destination, stops } = appointment;

    strings.originString = createAddressString(
      origin.address,
      origin.address2,
      origin.city,
      origin.state,
      origin.postal,
    );
    strings.destinationString = createAddressString(
      destination.address,
      destination.address2,
      destination.city,
      destination.state,
      destination.postal,
    );

    if (stops?.length) {
      strings.stopsString = stops
        .map(
          (item: {
            address: string;
            address2: string;
            city: string;
            state: string;
            postal: string;
          }) =>
            createAddressString(
              item.address,
              item.address2,
              item.city,
              item.state,
              item.postal,
            ),
        )
        .join("|");
    }
  }

  return strings;
};
