import { makeStyles } from "@material-ui/core";

export const useListStyles = makeStyles(() => ({
  root: {
    padding: 16,
  },
  title: {
    marginBottom: 16,
  },
  list: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },
}));
