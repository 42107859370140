import { useCallback, useMemo } from "react";
import { useStore } from "effector-react";
import moment from "moment-timezone";

import { $authorizedUser, $location } from "features/auth";
import { apiDateTimeFormat, USADateTimeFormat } from "shared/utils";

export const useLocationTimezone = (
  offsetType: "user" | "location" = "user",
) => {
  const { user } = useStore($authorizedUser);
  const { location } = useStore($location);

  const timezone = useMemo<string>(
    () =>
      (offsetType === "user"
        ? user?.location.timezone.shortName
        : location?.timeZone.shortName) || "",
    [
      offsetType,
      user?.location.timezone.shortName,
      location?.timeZone.shortName,
    ],
  );

  const utcToZone = useCallback(
    (dateTime: string, format?: string) => {
      const date = moment(dateTime);

      if (date.isValid()) {
        return moment
          .utc(dateTime)
          .tz(timezone)
          .format(format || USADateTimeFormat);
      }

      throw new Error("Invalid date");
    },
    [timezone],
  );

  const zoneToUTC = useCallback(
    (dateTime: string) => {
      const date = moment.tz(dateTime, timezone);

      if (date.isValid()) {
        return date.utc().format(apiDateTimeFormat);
      }

      throw new Error("Invalid date");
    },
    [timezone],
  );

  return { utcToZone, zoneToUTC };
};
