import { Box, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { Button, colors, Paper } from "@chhjpackages/components";
import { memo, useCallback } from "react";
import { Link } from "react-router-dom";

import { LoginForm, useAuth } from "features/auth/";
import { ChhjCrewLogoIcon } from "shared/assets";
import { routePaths } from "shared/utils";

export const Login = memo(() => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), { noSsr: true });

  const navigate = useNavigate();
  const { authorize } = useAuth();

  const handleNeedHelp = useCallback(
    () => navigate(routePaths.needHelp()),
    [navigate],
  );

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flex={1}
      p={2}
    >
      <Box flex={1} maxWidth={426}>
        <Paper elevation={16}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            bgcolor={colors.basic.white}
            pt={{ xs: 0, sm: 0 }}
            pb={{ xs: 3, sm: 3 }}
            p={{ xs: 3, sm: 4 }}
          >
            <Box mt={-1.5}>
              <ChhjCrewLogoIcon fontSize={152} />
            </Box>
            <Box width="100%">
              <LoginForm onSubmit={authorize} />
            </Box>
            {!isMobile && (
              <Box mt={2} width="100%">
                <Button
                  variant="text"
                  color="secondary"
                  size="large"
                  fullWidth
                  onClick={handleNeedHelp}
                >
                  Need help?
                </Button>
              </Box>
            )}
            <Box mt={!isMobile ? 2 : 3}>
              <Typography variant="body1">
                By accessing this site you agree to our{" "}
                <Link
                  to={routePaths.termsAndConditions()}
                  style={{
                    color: colors.primary.main,
                    textDecorationSkipInk: "none",
                    textUnderlineOffset: "2px",
                    textDecorationThickness: 1,
                  }}
                >
                  terms and conditions
                </Link>
              </Typography>
            </Box>
          </Box>
        </Paper>
        {isMobile && (
          <Box mt={1}>
            <Button
              variant="text"
              color="secondary"
              size="large"
              fullWidth
              onClick={handleNeedHelp}
            >
              Need help?
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
});
