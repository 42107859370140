import { Instruction, StepStatusesEnum } from "shared/types";

export type Requirement = Instruction & {
  status: StepStatusesEnum;
};

export enum InstructionTypesEnum {
  ACKNOWLEDGE = 1,
  PHOTO_UPLOAD = 2,
  DISCOUNT = 3,
}
