import { Box, Typography } from "@material-ui/core";
import { memo } from "react";
import { Button } from "@chhjpackages/components";
import { useForm } from "react-hook-form";

import { CompleteIcon } from "shared/assets";

import { CompleteAppointmentFormProps } from "./model";

export const CompleteAppointmentForm = memo(
  ({ onSubmit, onClose }: CompleteAppointmentFormProps) => {
    const {
      formState: { isSubmitted, isSubmitting },
      handleSubmit,
    } = useForm({});

    return (
      <Box>
        {!isSubmitted ? (
          <>
            <Typography variant="body1">
              Would you like to complete this appointment?
            </Typography>
            <Box
              mt={3}
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Box mr={1}>
                <Button buttonType="text" color="primary" onClick={onClose}>
                  Close
                </Button>
              </Box>
              <Box width={135}>
                <Button
                  buttonType="twoTone"
                  fullWidth
                  isLoading={isSubmitting}
                  onClick={handleSubmit(onSubmit)}
                >
                  Complete
                </Button>
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              textAlign="center"
            >
              <Typography variant="h4" color="secondary">
                Awesome!
              </Typography>
              <Typography variant="h4">This job has been completed</Typography>
              <Box mt={3}>
                <CompleteIcon />
              </Box>
            </Box>
            <Box
              mt={3}
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Button buttonType="text" color="primary" onClick={onClose}>
                Close
              </Button>
            </Box>
          </>
        )}
      </Box>
    );
  },
);
