import { Box, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { Button, colors } from "@chhjpackages/components";
import moment from "moment";
import { memo } from "react";

import { hunkwareLink } from "shared/config";

import { useStyles } from "./styles";
import { TermsAndConditionsProps } from "./types";

export const TermsAndConditions = memo(
  ({ onClose }: TermsAndConditionsProps) => {
    const styles = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"), {
      noSsr: true,
    });

    return (
      <div>
        {isMobile && (
          <Box mb={2}>
            <Typography variant="h3">Terms & conditions</Typography>
          </Box>
        )}
        <Box color={colors.grey160}>
          <Typography variant="body1" color="inherit">
            This is a summary of (and not a substitute for) our{" "}
            <a
              className={styles.link}
              href={`${hunkwareLink}/hunkware/terms-of-use.html`}
              target="_blank"
              rel="noreferrer"
            >
              Terms of Use
            </a>{" "}
            and{" "}
            <a
              className={styles.link}
              href={`${hunkwareLink}/hunkware/privacy-policy.html`}
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            **.
            <Box mt={3}>
              <ul className={styles.ul}>
                <li>
                  This is a privately owned system only intended for use by
                  Authorized CHHJ Users.
                </li>
                <li>
                  Password & account sharing is prohibited. Anyone caught
                  sharing accounts/passwords will have their account(s)
                  suspended.
                </li>
                <li>
                  We have the right to (and will) track every interaction you
                  have with our systems.
                </li>
                <li>
                  The data stored here is private and exporting or sharing the
                  data outside of CHHJ without written permission is prohibited.
                </li>
                <li>The privacy & security of our system is a top priority.</li>
                <li>
                  Abusing our system is grounds for banning your IP and/or
                  account and potentially employee termination.
                </li>
                <li>
                  We will store information in your browser such as cookies &
                  tracking data.
                </li>
              </ul>
            </Box>
            <Box mt={3}>
              Here are links to the full version of each of our agreements:
              <ul className={styles.ul}>
                <li>
                  <a
                    className={styles.link}
                    href={`${hunkwareLink}/hunkware/terms-of-use.html`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms of Use
                  </a>
                </li>
                <li>
                  <a
                    className={styles.link}
                    href={`${hunkwareLink}/hunkware/privacy-policy.html`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </Box>
            <Box mt={3}>
              **This summary highlights only some of the key features and terms
              of the actual agreements. It is not an agreement and has no legal
              value. You should carefully review all of the terms and conditions
              of the actual agreements before accessing our site.
            </Box>
            <Box mt={3}>
              Copyright © {moment().format("YYYY")} College Hunks. All rights
              reserved.
            </Box>
          </Typography>
        </Box>
        {isMobile && (
          <Box mt={2}>
            <Button
              buttonType="twoTone"
              color="primary"
              fullWidth
              size="large"
              onClick={onClose}
            >
              Close
            </Button>
          </Box>
        )}
      </div>
    );
  },
);
