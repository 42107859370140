import { ApiResponseBase } from "shared/types";

export enum IsWeekendEnum {
  Auto = -1,
  Weekday = 0,
  Weekend = 1,
}

export type NumberRange = {
  low: number;
  exact: number;
  exactBase?: number;
  high: number;
};

export enum AccessTypeEnum {
  FrontDoor = 1,
  Stairs = 2,
  Elevator = 3,
}

export enum TypeOfDwellingEnum {
  Unknown = 0,
  House = 1,
  Apartment = 2,
  Condo = 3,
  Townhome = 4,
  StorageUnit = 5,
  Business = 6,
}

export enum TruckParkingEnum {
  Unknown = 0,
  Driveway = 1,
  LoadingDock = 2,
  OnTheStreet = 3,
  ParkingLot = 4,
}

export enum PricingTypeEnum {
  None = 0,
  Basic = 1,
  Surcharges = 2,
  FlatRate = 3,
  Bedload = 4,
  OneHour = 5,
  Accessorial = 6,
}

export enum FormModeEnum {
  StressFree = 1,
  Inventory = 2,
}

export type QuoteNote = {
  size?: string;
  readiness?: string;
  logistical?: string;
  special?: string;
  junk?: string;
  generated?: string;
  moveFactors?: string;
  internalNotes?: string;
  internal?: string;
};

export type Address = {
  address: string;
  address2: string;
  city: string;
  state: string;
  postal: string;
  floor: number;
  country: string;
  accessType: AccessTypeEnum | "";
  longWalks: number;
  loadingDockReservationRequired: boolean;
  dwellingType: {
    id: TypeOfDwellingEnum;
  };
  truckParkingType: {
    id: TruckParkingEnum;
  };
};

export type StopAddress = {
  address: string;
  address2: string;
  city: string;
  state: string;
  postal: string;
  country: string;
};

export type Pricing = {
  adjustment: number;
  junkRemoval: number;
  type: PricingTypeEnum;
  flatRate: number;
  discount: number;
  discountTruckAndTravel: number;
  hourlyRate: number;
};

export type Space = {
  id: number;
  key: string;
  name: string;
  type: {
    id: number;
    name: string;
    iconUrl: string;
    order: number;
  };
};

export type InventoryItem = {
  id: number;
  quantity: number;
  assemblyCount: number;
  disassemblyCount: number;
  productId: number;
  name: string;
  weight: number;
  cubicFeet: number;
  subcategory: string;
  notes: string;
  spaceKey: string;
};

export type CustomInventoryItem = {
  id: number | string;
  name: string;
  quantity: number;
  spaceKey: string;
  cubicFeet: number;
  weight: number;
  price: number;
  assemblyCount: number;
  disassemblyCount: number;
  notes: string;
};

export type Quote = {
  id: number;
  account: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  };
  numStops: number;
  squareFootage: number;
  numberOfBoxes: number;
  cioNeeded: boolean;
  moveReady: boolean;
  packingNeeded: boolean;
  rooms: string;
  hunks: number;
  date: string;
  parsedDate: string;
  appliances: number;
  assemblyItems: number;
  heavyItems: number;
  isWeekend: IsWeekendEnum;
  origin: Address;
  stops: StopAddress[];
  destination: Address;
  pricing: Pricing;
  inventory: InventoryItem[];
  spaces: Space[];
  customItems: CustomInventoryItem[];
  location: {
    id: number;
  };
  estimate: {
    id: number;
  };
  job: {
    id: number;
  };
  category: {
    id: number;
    name: string;
  };
  workType: {
    id: number;
  };
  type: {
    id: FormModeEnum;
  };
  notes: QuoteNote;
  created: {
    by: number;
    datetime: string;
  };
  modified: {
    by: number;
    datetime: string;
  };
};

export namespace GetQuote {
  export type Params = {
    locationId: number;
    quoteId: number;
  };

  export type Response = {
    quotes: Quote[];
  } & ApiResponseBase;
}

export type CalculatedQuote = {
  origin: Address & { country: string };
  destination: Address & { country: string };
  spaces: Space[];
  rooms: string;
  date: string;
  notes: QuoteNote;
  assemblyItems: number;
  isWeekend: IsWeekendEnum;
  pricing: Pricing;
  inventory: InventoryItem[];
  customItems: CustomInventoryItem[];
  callRequired: boolean;
  hunks: number;
  hourlyRate: number;
  minimumHours: number;
  minimumCharge: number;
  moveFactors: string;
  numberOfBoxes: number;
  email: string;
  truckAndTravel: number;
  calc: {
    packing: number;
    timePacking: number;
  };
  prePayment: {
    amount: number;
    collectOutline: number;
    refundDays: number;
    refundableBy: string;
    refundable: boolean;
    squareLocationId: string;
    truckAndtravel: number;
  };
  laborHours: NumberRange;
  laborPrice: NumberRange;
  totalPrice: NumberRange;
  packing: {
    materials: number;
    services: {
      hours: number;
      price: number;
      rate: number;
    };
  };
};

export namespace CalculateQuote {
  type Payload = {
    appliances: number;
    assemblyItems: number;
    category: {
      id: number;
    };
    customItems: {
      id: string | number;
      name: string;
      cubicFeet: number;
      weight: number;
      quantity: number;
      price: number;
      assemblyCount: number;
      disassemblyCount: number;
    }[];
    date: string;
    destination: Address & { country: string };
    forceHunks: boolean;
    hunks: number;
    inventory: {
      productId: number;
      quantity: number;
      spaceKey: string;
      assemblyCount: number;
      disassemblyCount: number;
    }[];
    isWeekend: IsWeekendEnum;
    numberOfBoxes: number;
    origin: Address & { country: string };
    pricing: Pricing;
    rooms: string;
    source: {
      id: number;
    };
    spaces: {
      id: string | number;
      key: string;
      name: string;
      typeId: number;
    }[];
    stops: StopAddress[];
    type: {
      id: FormModeEnum;
    };
    workType: {
      id: number;
    };
    heavyItems: number;
  };

  export type Params = {
    locationId: number;
    payload: Payload;
  };

  export type Response = {
    quote: CalculatedQuote[];
  } & ApiResponseBase;
}
