import { fetcher } from "shared/libs";

import { AddAttachments, GetAttachments, DeleteAttachments } from "./api-types";

export const addAttachments = ({
  locationId,
  appointmentId,
  description,
  extension,
  content,
  typeId,
  emailClient,
}: AddAttachments.Params) =>
  fetcher.post<AddAttachments.Response>(
    `/locations/${locationId}/appointments/${appointmentId}/attachments`,
    {
      type: {
        id: typeId || 3,
      },
      description,
      content,
      extension,
      emailClient,
    },
  );

export const getAttachments = ({
  locationId,
  appointmentId,
}: GetAttachments.Params) =>
  fetcher.get<GetAttachments.Response>(
    `/locations/${locationId}/appointments/${appointmentId}/attachments`,
  );

export const deleteAttachment = ({
  locationId,
  appointmentId,
  attachmentId,
}: DeleteAttachments.Params) =>
  fetcher.delete<DeleteAttachments.Response>(
    `/locations/${locationId}/appointments/${appointmentId}/attachments/${attachmentId}`,
  );
