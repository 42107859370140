import { createEffect, createStore } from "effector/compat";

import { Damage } from "../types";
import { addDamage, deleteDamage, getDamages, updateDamage } from "../api";

export const $damages = createStore<{
  damages: Damage[];
  appointmentId: number | null;
  loading: boolean;
}>({
  damages: [],
  appointmentId: null,
  loading: true,
});

export const getDamagesFx = createEffect(getDamages);
$damages
  .on(getDamagesFx.done, (store, response) => ({
    ...store,
    damages: response.result.data.damages,
    appointmentId: response.params.appointmentId,
  }))
  .on(getDamagesFx.fail, (store) => ({
    ...store,
    damages: [],
    appointmentId: null,
  }))
  .on(getDamagesFx.pending, (store, loading) => ({
    ...store,
    loading,
  }));

export const addDamageFx = createEffect(addDamage);
$damages.on(addDamageFx.doneData, (store, response) => ({
  ...store,
  damages: [...store.damages, ...response.data.damages],
}));

export const updateDamageFx = createEffect(updateDamage);
$damages.on(updateDamageFx.done, (store, response) => ({
  ...store,
  damages: store.damages.map((damage) =>
    Number(damage.id) === response.params.damageId
      ? response.result.data.damages[0]
      : damage,
  ),
}));

export const deleteDamageFx = createEffect(deleteDamage);
$damages.on(deleteDamageFx.done, (store, response) => ({
  ...store,
  damages: store.damages.filter(
    (damage) => Number(damage.id) !== response.params.damageId,
  ),
}));
