import { makeStyles, Radio as MuiRadio, withStyles } from "@material-ui/core";
import { colors } from "@chhjpackages/components";

export const Radio = withStyles(() => ({
  root: {
    fontSize: "1.8rem",
    color: colors.primary.main,
  },
  checked: {
    "& .MuiSvgIcon-root:last-child": {
      color: colors.secondary.main,
    },
  },
}))(MuiRadio);

export const useStyles = makeStyles(() => ({
  label: {
    fontSize: 16,
    fontWeight: 700,
  },
}));
