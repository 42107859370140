import { memo } from "react";
import { Typography } from "@material-ui/core";

import LogoGoodwil from "../../assets/images/logo-goodwill.png";
import LogoHabitat from "../../assets/images/logo-habitat.png";
import LogoTheSalvationArmy from "../../assets/images/logo-the-salvation-army.png";

import { useStyles } from "./styles";

export const Page7 = memo(() => {
  const styles = useStyles();

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.leftSide}>
          <div className={styles.mainText}>
            <Typography variant="h3" component="div">
              let us give your items
            </Typography>
            <Typography variant="h1" component="div">
              a second life
            </Typography>
            <br />
            <Typography component="div">
              We have partnered with some of the leaders in the industry and
              local organizations to ensure we find a home for your items.
            </Typography>
          </div>
        </div>

        <div className={styles.rightSide}>
          <div className={styles.card}>
            <img src={LogoGoodwil} alt="Goodwill" />
          </div>
          <div className={styles.card}>
            <img src={LogoHabitat} alt="Habitat" />
          </div>
          <div className={styles.card}>
            <img src={LogoTheSalvationArmy} alt="The Salvation Army" />
          </div>
        </div>
      </div>
    </div>
  );
});
