import { memo, useCallback, useEffect, useMemo } from "react";
import { MessageBadge, useDialogDispatch } from "@chhjpackages/components";
import { useNavigate } from "react-router-dom";
import { useStore } from "effector-react";
import { useParams } from "react-router";

import {
  $appointmentsStore,
  $appointmentStore,
  Appointments,
  ContactsDialog,
} from "features/appointment";
import { $teamsStore } from "features/teams";
import { $authorizedUser, AppExperienceTypesEnum } from "features/auth";
import { DialogContentWrapper } from "shared/ui";

export const Contacts = memo(() => {
  const { appointmentId } = useParams();
  const navigate = useNavigate();
  const setDialog = useDialogDispatch();

  const { user } = useStore($authorizedUser);
  const { appointments } = useStore($appointmentsStore);
  const { appointment } = useStore($appointmentStore);
  const { userTeamsByDate } = useStore($teamsStore);

  const handleCloseContactsDialog = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const selectedAppointment = useMemo(
    () =>
      appointments.length > 0
        ? appointments.find((app) => app.id === Number(appointmentId))
        : appointment,
    [appointments, appointment, appointmentId],
  );

  const canViewContacts = useMemo(
    () =>
      Number(user?.appExperienceType.id) ===
        AppExperienceTypesEnum.LocationBased ||
      !!userTeamsByDate?.find(
        (team) =>
          !!selectedAppointment?.schedules.find(
            (schedule) => schedule.team.id === team.id,
          ),
      ),
    [
      user?.appExperienceType.id,
      selectedAppointment?.schedules,
      userTeamsByDate,
    ],
  );

  useEffect(() => {
    setDialog({
      open: true,
      variant: "basic",
      title: "Contact client",
      disableBackdropClick: true,
      dialogContent: (
        <DialogContentWrapper maxWidth={488}>
          {canViewContacts ? (
            <ContactsDialog
              appointment={selectedAppointment as Appointments | undefined}
            />
          ) : (
            <MessageBadge
              type="error"
              message="Only users assigned to the appointment can see contacts"
            />
          )}
        </DialogContentWrapper>
      ),
      onClose: handleCloseContactsDialog,
    });

    return () => setDialog({ open: false });
  }, [
    selectedAppointment,
    setDialog,
    handleCloseContactsDialog,
    canViewContacts,
  ]);

  return null;
});
