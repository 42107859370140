export const numberWithCommas = (
  num: number | string,
  fractionDigits: number = 0,
) => {
  const number = typeof num === "string" ? Number(num.replace(/,/g, "")) : num;

  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(number);
};
