import { useContext } from "react";

import { SideNavDispatchContext, SideNavStateContext } from "../context";

export const useSideNavState = () => {
  const context = useContext(SideNavStateContext);

  if (context === undefined) {
    throw new Error(
      "useSideNavState must be used within a SideNavContextController",
    );
  }

  return context;
};

export const useSideNavDispatch = () => {
  const context = useContext(SideNavDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useSideNavDispatch must be used within a SideNavContextController",
    );
  }

  return context;
};
