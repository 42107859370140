import { makeStyles } from "@material-ui/styles";
import { colors, RiftFont } from "@chhjpackages/components";

export const useStyles = makeStyles({
  mainBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  awesome: {
    color: colors.secondary.main,
  },
  handsIcon: {
    marginTop: "15px",
    width: "110px",
    height: "125px",
  },
  closeButton: {
    width: "66px",
    height: "41px",
    background: "none",
    border: "none",
    fontFamily: RiftFont,
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "32px",
    color: colors.primary.main,
    cursor: "pointer",
  },
});
