import { Box, createStyles, withStyles } from "@material-ui/core";
import { colors } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/styles";

export const HelpBox = withStyles(() =>
  createStyles({
    root: {
      margin: "16px 0",
      color: colors.grey140,
      fontSize: 18,
      lineHeight: "140%",
    },
  }),
)(Box);

export const useStyles = makeStyles({
  link: {
    textDecoration: "none",
    color: colors.primary.main,
    cursor: "pointer",
    fontWeight: "bold",
  },
});
