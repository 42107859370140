import { colors } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  mainBox: {
    border: `1px solid ${colors.grey20}`,
    padding: 16,
    backgroundColor: colors.basic.white,
    display: "flex",
    justifyContent: "space-between",
  },
  checkIconBox: {
    height: 26,
    width: 26,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#C9F0DD",
  },
  numberBox: {
    width: 26,
    height: 26,
    lineHeight: "26px",
    textAlign: "center",
    backgroundColor: "#FFF1E0",
    color: "#EA7200",
    fontWeight: 700,
    fontSize: 16,
    fontFamily: "Noah",
  },
  checkIconStyle: {
    color: "#69D4A1",
    width: 16,
    height: 16,
  },
  root: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    marginLeft: 24,
    color: colors.grey180,
  },
  actionContainer: {
    display: "flex",
    alignItems: "center",
    margin: "-8px 0px",
  },
  dividerWrapper: {
    margin: "0px 16px",
    height: "100%",
  },
  iconButtonWrapper: {
    margin: -12,
  },
}));
