import { useAlert, useConfirmationDialog } from "@chhjpackages/components";
import { useCallback, useMemo } from "react";
import { useStore } from "effector-react";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import { $teamsStore } from "features/teams";
import {
  $appointmentsStore,
  Appointments,
  getAppointmentsFx,
} from "features/appointment";
import { AccountInfo } from "../ui";
import {
  CompletedActionsEstimateEnum,
  CompletedActionsJobEnum,
} from "shared/types";
import { addCompletedActionFx } from "features/completed-actions";
import { apiDateFormat, apiDateTimeFormat, routePaths } from "shared/utils";
import { useLocationTimezone } from "shared/hooks";

export const useMarkEtaComplete = () => {
  const navigate = useNavigate();

  const { appointments } = useStore($appointmentsStore);
  const { userTeamsByDate } = useStore($teamsStore);

  const { showAlert } = useAlert();
  const { utcToZone } = useLocationTimezone("location");

  const assignedAppointments = useMemo(
    () =>
      appointments.filter((appointment) =>
        userTeamsByDate.some(
          (team) => Number(team.id) === Number(appointment.team.id),
        ),
      ),
    [appointments, userTeamsByDate],
  );

  const lateOrNearAppointments = useMemo(() => {
    const currentDate = moment(
      utcToZone(moment().utc().format(apiDateTimeFormat)),
    );
    const futureDate = currentDate.add(30, "minutes");

    return assignedAppointments.filter((appointment) => {
      const isLate = moment(appointment.startDate).isBefore(currentDate);
      const isNear = futureDate.isSameOrAfter(appointment.startDate);
      const isTravelStarted = appointment.completedactions.some((action) =>
        appointment.type === "EST"
          ? action.type.id === CompletedActionsEstimateEnum.StartTravel
          : action.type.id === CompletedActionsJobEnum.StartTravelETA,
      );

      return (isLate || isNear) && !isTravelStarted;
    });
  }, [assignedAppointments, utcToZone]);

  const orderedAppointments = useMemo(
    () =>
      lateOrNearAppointments.sort((a, b) => {
        if (moment(a.startDate).isBefore(b.startDate)) {
          return -1;
        }

        if (moment(b.startDate).isBefore(a.startDate)) {
          return 1;
        }

        if (moment(a.endDate).isBefore(b.startDate)) {
          return -1;
        }

        if (moment(b.endDate).isBefore(a.endDate)) {
          return 1;
        }

        if (moment(a.created.date).isBefore(b.created.date)) {
          return -1;
        }

        if (moment(b.created.date).isBefore(a.created.date)) {
          return 1;
        }

        return 0;
      }),
    [lateOrNearAppointments],
  );

  const nextAppointment = useMemo<Appointments | undefined>(
    () => orderedAppointments[0],
    [orderedAppointments],
  );

  const accountInfoContent = useMemo(
    () =>
      nextAppointment ? <AccountInfo appointment={nextAppointment!} /> : <></>,
    [nextAppointment],
  );

  const { closeConfirmation, openConfirmation, setLoader } =
    useConfirmationDialog({
      title: "Send ETA to client",
      message: accountInfoContent,
      confirmButtonText: "Mark ETA as complete",
      cancelButtonText: "Exit",
      maxWidth: 488,
    });

  const onSendEta = useCallback(async () => {
    if (nextAppointment) {
      try {
        await addCompletedActionFx({
          appointmentId: nextAppointment.id,
          locationId: nextAppointment.location.id,
          payload: {
            type: {
              id: CompletedActionsJobEnum.ETACallCompleted,
            },
          },
        });

        await getAppointmentsFx({
          locationId: nextAppointment.location.id,
          date: utcToZone(
            moment.utc().format(apiDateTimeFormat),
            apiDateFormat,
          ),
        });

        showAlert("Success! ETA call completed.", {
          variant: "success",
        });
      } catch {
        showAlert("Error! Failed to complete ETA call.", {
          variant: "error",
        });
      }
    }
  }, [nextAppointment, utcToZone, showAlert]);

  const openMarkEtaComplete = useCallback(() => {
    const isEtaCallCompleted = nextAppointment?.completedactions.some(
      (action) => action.type.id === CompletedActionsJobEnum.ETACallCompleted,
    );

    if (isEtaCallCompleted && nextAppointment?.id) {
      navigate(routePaths.jobDetails(nextAppointment.id));

      return;
    }

    openConfirmation(async () => {
      setLoader(true);

      await onSendEta();

      setLoader(false);
      closeConfirmation();
    });
  }, [
    nextAppointment?.completedactions,
    nextAppointment?.id,
    navigate,
    onSendEta,
    setLoader,
    openConfirmation,
    closeConfirmation,
  ]);

  return { nextAppointment, openMarkEtaComplete };
};
