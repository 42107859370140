export enum PaymentProviderEnum {
  Square,
  HunkPay,
  Manual,
}

export enum PaymentVendorEnum {
  Automatic = 1,
  HunkPay = 2,
  Square = 3,
}
