import { memo } from "react";
import { Typography } from "@material-ui/core";

import { ReactComponent as Seal } from "../../assets/images/seal.svg";

import { useStyles } from "./styles";

export const Page4 = memo(() => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <div className={styles.column}>
        <div className={styles.textBox}>
          <Typography variant="h3" className={styles.letterRow} component="div">
            We are licensed
          </Typography>
          <Typography variant="h1" className={styles.letterRow} component="div">
            & insured
          </Typography>
          <Typography variant="h4" className={styles.letterRow} component="div">
            for your protection
          </Typography>
        </div>

        <div className={styles.seal}>
          <Seal />
        </div>
      </div>
    </div>
  );
});
