import { Grid, Typography } from "@material-ui/core";
import { memo } from "react";
import { WarningIcon, colors } from "@chhjpackages/components";

import { OfflineAccordionLabelProps } from "./types";
import { useStyles } from "./styles";

export const OfflineAccordionLabel = memo(
  ({ title }: OfflineAccordionLabelProps) => {
    const styles = useStyles();

    return (
      <Grid
        container
        style={{ height: "45px" }}
        alignItems="center"
        justify="flex-start"
      >
        <WarningIcon
          className={styles.warningIcon}
          color={colors.functionals.alert}
        />
        <Grid item>
          <Typography variant="h4">{`JOB ${title}`}</Typography>
        </Grid>
      </Grid>
    );
  },
);
