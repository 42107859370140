import { useDialogDispatch } from "@chhjpackages/components";
import { useStore } from "effector-react/compat";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  $productItems,
  $products,
  AddEditProductForm,
  updateProductItem,
} from "features/products";
import { $appointmentStore } from "features/appointment";
import { routePaths } from "shared/utils";
import { AddEditProductFormValues } from "features/products";
import { DialogContentWrapper, TwoLinesDialogHeader } from "shared/ui";
import { checkIsLabor } from "features/pricings";
import { $developments } from "features/developments";
import { deleteProductUpdate, setProductUpdate } from "features/cart";

export const CartEdit = memo(() => {
  const setDialog = useDialogDispatch();
  const { productLineId, appointmentId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { appointment } = useStore($appointmentStore);
  const { billableTimeTravelled, billableTimeWorked } = useStore($developments);
  const { products } = useStore($products);
  const { productItems } = useStore($productItems);

  const editingProduct = useMemo(
    () =>
      productItems.find(
        (product) => product.productLineId === Number(productLineId),
      ),
    [productItems, productLineId],
  );

  const productFromCart = useMemo(
    () =>
      editingProduct &&
      products.find(
        (product) => product.productLineId === editingProduct.productLineId,
      ),
    [products, editingProduct],
  );

  const taxCodes = useMemo(
    () => (appointment ? appointment.location.taxCodes : []),
    [appointment],
  );

  const handleClose = useCallback(
    () =>
      navigate(routePaths.jobDetailsCart(Number(appointmentId)), {
        replace: true,
        state: location.state,
      }),
    [appointmentId, location.state, navigate],
  );

  const handleSave = useCallback(
    async (data: AddEditProductFormValues) => {
      if (!editingProduct) {
        return;
      }

      if (
        productFromCart &&
        productFromCart.quantity === data.quantity &&
        productFromCart.actualPrice === Number(data.price) &&
        productFromCart.salesTaxId === data.taxId &&
        productFromCart.notes === data.notes
      ) {
        deleteProductUpdate({ id: editingProduct.productLineId });

        updateProductItem({
          ...editingProduct,
          actualPrice: Number(data.price),
          quantity: data.quantity,
          salesTaxId: data.taxId ?? 0,
          notes: data.notes,
        });

        handleClose();
        return;
      }

      setProductUpdate({
        product: {
          ...editingProduct,
          actualPrice: Number(data.price),
          quantity: data.quantity,
          salesTaxId: data.taxId ?? 0,
          notes: data.notes,
        },
        ignoreDelete: true,
      });

      updateProductItem({
        ...editingProduct,
        actualPrice: Number(data.price),
        quantity: data.quantity,
        salesTaxId: data.taxId ?? 0,
        notes: data.notes,
      });

      handleClose();
    },
    [productFromCart, editingProduct, handleClose],
  );

  useEffect(() => {
    if (editingProduct) {
      const isLabor = checkIsLabor(editingProduct.productName);

      setDialog({
        open: true,
        variant: "basic",
        title: (
          <TwoLinesDialogHeader
            title={
              isLabor
                ? editingProduct.productSubcategoryName
                : editingProduct.productName
            }
            description={isLabor ? editingProduct.productName : ""}
          />
        ),
        disableBackdropClick: true,
        dialogContent: (
          <DialogContentWrapper maxWidth={488}>
            <AddEditProductForm
              initialValues={{
                quantity: editingProduct.quantity,
                price: String(editingProduct.actualPrice),
                taxId: editingProduct.salesTaxId,
                notes: editingProduct.notes,
              }}
              mode="edit"
              isLabor={isLabor}
              travelTime={billableTimeTravelled}
              workTime={billableTimeWorked}
              taxCodes={taxCodes}
              onSave={handleSave}
              onClose={handleClose}
            />
          </DialogContentWrapper>
        ),
        onClose: handleClose,
      });
    }

    return () => setDialog({ open: false });
  }, [
    editingProduct,
    taxCodes,
    billableTimeTravelled,
    billableTimeWorked,
    setDialog,
    handleSave,
    handleClose,
  ]);

  return null;
});
