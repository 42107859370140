import { colors, NoahFont } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },
  title: {
    color: colors.grey180,
    textAlign: "center",

    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
    },
  },
  sectionTitle: {
    color: colors.grey160,
  },
  time: {
    color: colors.functionals.alert,
  },
  timeSkeleton: {
    display: "inline-block",
  },
  ul: {
    margin: 0,
    paddingLeft: 24,
    fontSize: 18,
  },
  list: {
    fontWeight: "normal",
  },
  listItem: {
    listStyleType: "disc",
    color: colors.grey180,
    fontFamily: NoahFont,

    "&$valid": {
      color: colors.secondary.main,
      fontWeight: "bold",
    },

    "&$crossedValid": {
      textDecoration: "line-through",
      color: colors.grey120,
    },
  },
  valid: {},
  crossedValid: {},
}));
