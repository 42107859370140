import { createEffect, createStore } from "effector/compat";

import { getAppointmentFx } from "features/appointment/model/store";
import { CompletedAction } from "shared/types";
import { addCompletedAction, deleteCompletedAction } from "../api";

export const addCompletedActionFx = createEffect(addCompletedAction);
export const deleteCompletedActionFx = createEffect(deleteCompletedAction);

export const $completedActions = createStore<{
  completedActions: CompletedAction[];
  loading: boolean;
}>({
  completedActions: [],
  loading: true,
});

$completedActions
  .on(getAppointmentFx.doneData, (store, response) => ({
    ...store,
    completedActions: response.data.appointments[0].completedactions,
  }))
  .on(getAppointmentFx.pending, (store, loading) => ({
    ...store,
    loading,
  }));

$completedActions.on(addCompletedActionFx.doneData, (store, response) => ({
  ...store,
  completedActions: response.data.completedactions,
}));

$completedActions.on(deleteCompletedActionFx.doneData, (store, response) => ({
  ...store,
  completedActions: store.completedActions.filter(
    (completedAction) => completedAction.id !== response.completedActionId,
  ),
}));
