import { createStore, createEvent } from "effector/compat";

import { getAppointmentFx } from "features/appointment/model/store";
import { StepStatusesEnum } from "shared/types";
import { Requirement } from "../types";
import {
  getJobCompletedRequirements,
  markRequirementAsCompleted,
  markRequirementAsUncompleted,
} from "../utils";

export const markRequirementAsCompletedEv = createEvent<{
  appointmentId: number;
  requirementId: number;
}>();
export const markRequirementAsUncompletedEv = createEvent<{
  appointmentId: number;
  requirementId: number;
}>();

export const $requirements = createStore<{
  requirements: Requirement[];
  filteredRequirements: Requirement[];
  loading: boolean;
}>({
  requirements: [],
  filteredRequirements: [],
  loading: true,
});

$requirements
  .on(getAppointmentFx.doneData, (store, response) => {
    const responseRequirements =
      response.data.appointments[0].subpartner.instructions ?? [];
    const jobCompletedRequirements =
      getJobCompletedRequirements(response.data.appointments[0].id)
        ?.requirements ?? [];
    const requirements = responseRequirements.map((requirement) => {
      const status = jobCompletedRequirements.includes(requirement.id)
        ? StepStatusesEnum.Done
        : StepStatusesEnum.ToBeDone;

      return {
        ...requirement,
        status,
      };
    });

    return {
      ...store,
      requirements: requirements,
      filteredRequirements: requirements
        .filter((requirement) => Number(requirement.showInApp))
        .sort((a, b) => a.order - b.order),
    };
  })
  .on(getAppointmentFx.pending, (store, loading) => ({
    ...store,
    loading,
  }));

$requirements.on(markRequirementAsCompletedEv, (store, event) => {
  markRequirementAsCompleted(event.appointmentId, event.requirementId);

  return {
    ...store,
    requirements: store.requirements.map((requirement) =>
      requirement.id === event.requirementId
        ? { ...requirement, status: StepStatusesEnum.Done }
        : requirement,
    ),
    filteredRequirements: store.filteredRequirements.map((requirement) =>
      requirement.id === event.requirementId
        ? { ...requirement, status: StepStatusesEnum.Done }
        : requirement,
    ),
  };
});

$requirements.on(markRequirementAsUncompletedEv, (store, event) => {
  markRequirementAsUncompleted(event.appointmentId, event.requirementId);

  return {
    ...store,
    requirements: store.requirements.map((requirement) =>
      requirement.id === event.requirementId
        ? { ...requirement, status: StepStatusesEnum.ToBeDone }
        : requirement,
    ),
    filteredRequirements: store.filteredRequirements.map((requirement) =>
      requirement.id === event.requirementId
        ? { ...requirement, status: StepStatusesEnum.ToBeDone }
        : requirement,
    ),
  };
});
