import { Box, Typography } from "@material-ui/core";
import { memo, useMemo } from "react";
import { Button, colors } from "@chhjpackages/components";

import { ArrowTopIcon } from "shared/assets";

import { TeamButtonProps } from "./types";

export const TeamButton = memo(({ teams, onClick }: TeamButtonProps) => {
  const teamsCount = useMemo(() => teams?.length || 0, [teams?.length]);

  return (
    <Box display="flex" alignItems="center">
      <Button
        buttonType="text"
        onClick={onClick}
        style={{ padding: "0px 8px" }}
      >
        <Box display="flex" alignItems="center">
          <Box color={colors.grey120} mr={1}>
            <Typography
              variant="body1"
              color="inherit"
              align="left"
              style={{ textTransform: "initial" }}
            >
              {teamsCount === 0 ? "No team assigned" : "Team assigned"}
            </Typography>
          </Box>
          {teamsCount === 0 ? (
            <Box style={{ transform: "rotate(180deg)" }}>
              <ArrowTopIcon />
            </Box>
          ) : (
            <Box
              display="flex"
              width={13}
              height={13}
              bgcolor={colors.primary.main}
              color={colors.basic.white}
              fontWeight={700}
              fontSize={10}
              borderRadius={16}
              lineHeight="11px"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
            >
              <Box alignSelf="center" justifySelf="center" textAlign="center">
                {teamsCount}
              </Box>
            </Box>
          )}
        </Box>
      </Button>
    </Box>
  );
});
