import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  cropContainer: {
    position: "relative",
    width: "100%",
    display: "inline-block",
    "& .reactEasyCrop_CropArea": {
      width: "100%!important",
      height: "100%!important",
    },
  },
  freeSpace: {
    marginTop: "100%",
  },
  controls: {
    margin: "20px 0px 30px",
  },
  controlIcon: {
    width: 22,
    height: 22,
  },
  submitButton: {
    width: 136,
  },
}));
