import { makeStyles } from "@material-ui/styles";
import { colors } from "@chhjpackages/components";

export const useStyles = makeStyles({
  mainBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "25px",
  },
  changesTime: {
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
    width: "100%",
  },
  clockIcon: {
    margin: "-2px 13px 0 0",
  },
  changes: {
    width: "100%",
    borderRadius: "4px",
    backgroundColor: colors.grey10,
  },
  stateChanges: {
    display: "flex",
    flexWrap: "wrap",
  },
  stepText: {
    textDecoration: "line-through",
  },
  arrowIcon: {
    margin: "0 10px",
  },
});
