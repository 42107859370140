import { Theme, makeStyles } from "@material-ui/core";
import { colors } from "@chhjpackages/components";

import Page4 from "../../assets/images/page-4.png";

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    height: "100%",
    backgroundImage: `url(${Page4})`,
    backgroundSize: "cover",
    backgroundPosition: "right center",
  },

  column: {
    padding: 24,
    width: "40%",
    backgroundColor: colors.basic.white,
    position: "relative",

    [theme.breakpoints.down("xs")]: {
      width: "50%",
    },
  },

  textBox: {
    marginTop: 100,

    [theme.breakpoints.down("xs")]: {
      marginTop: 60,
    },
  },

  letterRow: {
    textAlign: "center",
    lineHeight: "56px",

    "&:nth-of-type(1)": {
      fontSize: 36,
    },

    "&:nth-of-type(3)": {
      fontSize: 30,
    },

    [theme.breakpoints.down("xs")]: {
      fontSize: 48,

      "&:nth-of-type(1)": {
        fontSize: 32,
      },

      "&:nth-of-type(3)": {
        fontSize: 24,
      },
    },
  },

  seal: {
    position: "absolute",
    right: 0,
    bottom: 64,
    transform: "translateX(50%)",

    [theme.breakpoints.down("xs")]: {
      bottom: 16,
    },
  },
}));
