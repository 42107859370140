import { Button, colors, Input } from "@chhjpackages/components";
import { makeStyles, withStyles } from "@material-ui/core";

export const ButtonStyled = withStyles({
  root: {
    fontSize: 18,
    padding: "4px 8px",
    marginRight: -8,
  },
})(Button);

export const InputStyled = withStyles({
  input: {
    width: 45,
    height: 24,
    textAlign: "center",
    "-webkit-text-fill-color": `${colors.black}!important`,
    color: `${colors.black}!important`,
  },
})(Input);

export const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 8,
  },
  contentRoot: {
    display: "flex",
    alignItems: "center",
    gap: 16,
    color: colors.grey100,
  },
  timeElement: {
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
  editButtonContent: {
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
}));
