import { Box } from "@material-ui/core";
import { ReactNode, memo, useMemo } from "react";
import { BoxProps } from "@material-ui/core/Box";
import { colors } from "@chhjpackages/components";

export const ImportantBadge = memo(
  ({
    children,
    type = "info",
    ...rest
  }: { children: ReactNode; type?: "info" | "error" } & BoxProps) => {
    const bgColor = useMemo(() => {
      switch (type) {
        case "info":
          return colors.complementary.blue.shade04;
        case "error":
          return colors.functionals.alertLight;
        default:
          return colors.complementary.blue.shade04;
      }
    }, [type]);

    const borderColor = useMemo(() => {
      switch (type) {
        case "info":
          return colors.complementary.blue.shade02;
        case "error":
          return colors.functionals.alert;
        default:
          return colors.complementary.blue.shade02;
      }
    }, [type]);

    return (
      <Box
        bgcolor={bgColor}
        p={1}
        border={`1px solid ${borderColor}`}
        borderRadius={4}
        {...rest}
      >
        {children}
      </Box>
    );
  },
);
