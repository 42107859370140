import { SvgIcon } from "@material-ui/core";
import { colors, SvgIconProps } from "@chhjpackages/components";

export const ClipboardIcon = ({
  className,
  color = colors.grey100,
  fontSize = 18,
}: SvgIconProps) => (
  <SvgIcon
    className={className}
    style={{ fontSize, color: "#fff" }}
    width="10"
    height="13"
    viewBox="0 0 10 13"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.78087 2.06076V2.58076C6.78087 2.86795 6.54734 3.10076 6.25926 3.10076H3.12963C2.84156 3.10076 2.60803 2.86795 2.60803 2.58076V2.06076H1.56482C0.988667 2.06076 0.521606 2.52638 0.521606 3.10076V11.4208C0.521606 11.9951 0.988667 12.4608 1.56482 12.4608H7.82408C8.40022 12.4608 8.86729 11.9951 8.86729 11.4208V3.10076C8.86729 2.52638 8.40022 2.06076 7.82408 2.06076H6.78087Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.25927 1.02076H3.12964C2.84156 1.02076 2.60803 1.25357 2.60803 1.54076V2.58076C2.60803 2.86795 2.84156 3.10076 3.12964 3.10076H6.25927C6.54734 3.10076 6.78087 2.86795 6.78087 2.58076V1.54076C6.78087 1.25357 6.54734 1.02076 6.25927 1.02076Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.69446 5.70076H6.78088"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.60803 5.70076H3.12964"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.25925 7.78076H6.78085"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.60803 7.78076H4.69445"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.21606 9.86076H6.78088"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.60803 9.86076H3.65124"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
