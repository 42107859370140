import { Button } from "@chhjpackages/components";
import { makeStyles, withStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  link: {
    textDecoration: "none",
  },
  customChip: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "17px",
  },
});

export const ButtonStyled = withStyles({
  root: {
    padding: "4px 8px",
  },
})(Button);
