import { colors, SvgIconProps } from "@chhjpackages/components";
import { SvgIcon } from "@material-ui/core";

export const FilterIcon = ({
  className,
  color = colors.grey140,
}: SvgIconProps) => (
  <SvgIcon
    className={className}
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.74998 5.45453C3.91207 5.45223 4.94648 4.72371 5.33159 3.63635H21.0833C21.5896 3.63635 22 3.22934 22 2.72726C22 2.22518 21.5896 1.81817 21.0833 1.81817H5.33159C4.86317 0.514035 3.48255 -0.233881 2.12295 0.0799719C0.76336 0.393825 -0.142308 1.66952 0.0181347 3.04475C0.178577 4.41997 1.35405 5.45686 2.74998 5.45453Z"
      fill={color}
    />
    <path
      d="M19.25 14.5454C18.0879 14.5477 17.0535 15.2763 16.6684 16.3636H0.916667C0.410406 16.3636 0 16.7706 0 17.2727C0 17.7748 0.410406 18.1818 0.916667 18.1818H16.6684C17.1368 19.4859 18.5174 20.2339 19.877 19.92C21.2366 19.6062 22.1423 18.3305 21.9818 16.9552C21.8214 15.58 20.6459 14.5431 19.25 14.5454Z"
      fill={color}
    />
    <path
      d="M21.0833 9.09089H13.5816C13.1984 8.00209 12.1628 7.27274 11 7.27274C9.83718 7.27274 8.80156 8.00209 8.41839 9.09089H0.916667C0.410406 9.09089 0 9.49791 0 9.99998C0 10.5021 0.410406 10.9091 0.916667 10.9091H8.41839C8.80156 11.9979 9.83718 12.7272 11 12.7272C12.1628 12.7272 13.1984 11.9979 13.5816 10.9091H21.0833C21.5896 10.9091 22 10.5021 22 9.99998C22 9.49791 21.5896 9.09089 21.0833 9.09089Z"
      fill={color}
    />
  </SvgIcon>
);
