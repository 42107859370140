import { Grid, IconButton, Typography } from "@material-ui/core";
import { memo } from "react";

import { BoldPencilIcon } from "shared/assets";
import { Avatar } from "shared/ui";

import { UserInfoProps } from "./types";
import { useStyles } from "./styles";

export const UserInfo = memo(({ avatar, name, onClick }: UserInfoProps) => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Grid container spacing={3} wrap="nowrap" alignItems="center">
        <Grid item>
          <IconButton onClick={onClick} className={styles.avatarIconButton}>
            <Avatar
              imageUrl={avatar}
              icon={<BoldPencilIcon fontSize={14} />}
              isMobile={true}
            />
          </IconButton>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2" className={styles.greeting}>
            GREAT TO SEE YOU AGAIN,
          </Typography>
          <Typography variant="h4" className={styles.userName}>
            {name}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
});
