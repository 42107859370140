import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  emptyBoxIcon: {
    width: "30%",
    height: "auto",
    maxWidth: 200,
    minWidth: 138,
  },
});
