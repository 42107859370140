import { memo, useCallback, useEffect, useMemo } from "react";
import { Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { useStore } from "effector-react/compat";
import { Button, colors } from "@chhjpackages/components";
import { useParams, useNavigate, useLocation } from "react-router";
import { Outlet } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";

import { getAppointmentFx } from "features/appointment";
import { $appointmentStore } from "features/appointment";
import { Accordion, BackTitle, ContentAccordionLabel } from "shared/ui";
import { useSideNavDispatch } from "features/sidenav";
import { routePaths } from "shared/utils";
import { $auth } from "features/auth";
import {
  $requirements,
  InstructionTypesEnum,
  markRequirementAsCompletedEv,
} from "features/requirements";
import { StepStatusesEnum } from "shared/types";

export const NasaRequirements = memo(() => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: true,
  });

  const navigate = useNavigate();
  const location = useLocation();
  const { appointmentId } = useParams();
  const { setPageName, setGoToBackUrl, setShowGoBackButton } =
    useSideNavDispatch();

  const { locationId } = useStore($auth);
  const { appointment } = useStore($appointmentStore);
  const { filteredRequirements, loading: requirementsLoading } =
    useStore($requirements);

  const goToBackUrl = useMemo(
    () => routePaths.jobDetails(Number(appointmentId)),
    [appointmentId],
  );

  const openAttachments = useCallback(() => {
    navigate(routePaths.nasaRequirementsAttachments(Number(appointmentId)));
  }, [appointmentId, navigate]);

  const openDiscounts = useCallback(() => {
    navigate(routePaths.jobDetailsDiscounts(Number(appointmentId)), {
      state: {
        couponsAndDiscountsBack: location.pathname,
      },
    });
  }, [appointmentId, location.pathname, navigate]);

  const handleClickBack = useCallback(() => {
    navigate(goToBackUrl);
  }, [goToBackUrl, navigate]);

  useEffect(() => {
    setPageName("Nasa Requirements");
    setShowGoBackButton();
    setGoToBackUrl(goToBackUrl);

    return () => {
      setPageName("");
      setGoToBackUrl("");
      setShowGoBackButton();
    };
  }, [goToBackUrl, setPageName, setGoToBackUrl, setShowGoBackButton]);

  useEffect(() => {
    if (locationId && appointment?.id !== Number(appointmentId)) {
      getAppointmentFx({
        locationId: locationId,
        appointmentId: Number(appointmentId),
      });
    }
  }, [locationId, appointment?.id, appointmentId]);

  const getActionButton = useCallback(
    (typeId: number, status: StepStatusesEnum) => {
      switch (typeId) {
        case InstructionTypesEnum.PHOTO_UPLOAD:
          return (
            <Button
              buttonType="twoTone"
              color="primary"
              fullWidth
              disabled={status === StepStatusesEnum.Done}
              onClick={openAttachments}
            >
              Upload Photos
            </Button>
          );

        case InstructionTypesEnum.DISCOUNT:
          return (
            <Button
              buttonType="twoTone"
              color="primary"
              fullWidth
              disabled={status === StepStatusesEnum.Done}
              onClick={openDiscounts}
            >
              Go to Discounts
            </Button>
          );

        default:
          return null;
      }
    },
    [openAttachments, openDiscounts],
  );

  const handleMarkAsComplete = useCallback(
    (requirementId: number) => {
      if (appointment?.id) {
        markRequirementAsCompletedEv({
          appointmentId: appointment.id,
          requirementId,
        });
      }
    },
    [appointment?.id],
  );

  return (
    <div style={{ flex: 1 }}>
      {!isMobile && (
        <BackTitle title="Nasa requirements" onBack={handleClickBack} />
      )}

      <div style={{ padding: 16 }}>
        {!requirementsLoading &&
          filteredRequirements.map((requirement, index) => {
            const actionButton = getActionButton(
              requirement.type.id,
              requirement.status,
            );

            return (
              <div
                style={{ marginTop: index !== 0 ? 24 : 0 }}
                key={requirement.id}
              >
                <Accordion
                  header={
                    <ContentAccordionLabel
                      title={requirement.title}
                      status={requirement.status}
                    />
                  }
                >
                  <div style={{ flexDirection: "column" }}>
                    <div style={{ marginBottom: 8 }}>
                      <div
                        style={{
                          fontSize: 18,
                          lineHeight: "25.2px",
                          color: colors.grey160,
                        }}
                      >
                        {requirement.description}
                      </div>
                    </div>

                    {actionButton && (
                      <div style={{ marginTop: 16 }}>{actionButton}</div>
                    )}

                    <div style={{ marginTop: 16 }}>
                      <Button
                        buttonType="outlined"
                        fullWidth
                        disabled={requirement.status === StepStatusesEnum.Done}
                        onClick={() => handleMarkAsComplete(requirement.id)}
                      >
                        Mark as complete
                      </Button>
                    </div>
                  </div>
                </Accordion>
              </div>
            );
          })}

        {requirementsLoading &&
          [...Array(3)].map((_, index) => (
            <div style={{ marginTop: index !== 0 ? 24 : 0 }}>
              <Skeleton
                variant="rect"
                width="100%"
                height={45}
                style={{ borderRadius: 4 }}
              />
            </div>
          ))}

        {!requirementsLoading && filteredRequirements.length === 0 && (
          <Typography variant="body1">
            This partner account does not have any instructions.
          </Typography>
        )}

        <Outlet />
      </div>
    </div>
  );
});
