import { User } from "features/auth";

import { UserProfileFormValues } from "./types";

export const getInitialUserProfileValues = (
  user: User,
): UserProfileFormValues => ({
  firstName: user.firstName,
  lastName: user.lastName,
  phone: user.sms,
  email: user.email,
  bio: user.bio,
});
