import { Box, Typography, Divider, Chip, Grid } from "@material-ui/core";
import { Button, colors } from "@chhjpackages/components";
import { EmailOutlined } from "@material-ui/icons";
import { memo, useMemo } from "react";

import {
  UserIcon,
  PhoneIcon,
  MessageIcon,
  BoldPencilIcon,
} from "shared/assets/icons";
import { phoneFormat } from "features/appointment";
import { openInNewTab } from "shared/utils";

import { GeneralInformationProps } from "./model";
import { ButtonStyled, useStyles } from "./assets";

export const GeneralInformation = memo(
  ({ appointment }: GeneralInformationProps) => {
    const styles = useStyles();

    const isNasa = useMemo(
      () => !!appointment.subpartner.id,
      [appointment.subpartner.id],
    );

    const userName = useMemo(
      () =>
        [appointment.account.firstName, appointment.account.lastName]
          .filter((item) => !!item)
          .join(" "),
      [appointment.account],
    );

    const source = useMemo(
      () => appointment.source.name,
      [appointment.source.name],
    );

    const primaryPhone = useMemo(
      () => phoneFormat(appointment.account.phone),
      [appointment.account.phone],
    );

    const secondaryPhone = useMemo(
      () => phoneFormat(appointment.account.cell),
      [appointment.account.cell],
    );

    const email = useMemo(
      () => appointment.account.email,
      [appointment.account.email],
    );

    return (
      <Box pt={1} mt={2}>
        <Box px={2} color={colors.grey190}>
          <Typography variant="h4" color="inherit">
            Client information
          </Typography>
        </Box>

        <Box p={2}>
          <Box display="flex" alignItems="center">
            <UserIcon fontSize={22} />
            <Box ml={2}>
              <Typography variant="body1">{userName}</Typography>
            </Box>
          </Box>
          {source && (
            <Box mt={1}>
              <Chip size="small" label={source} />
            </Box>
          )}
        </Box>

        <Divider />

        {isNasa && (
          <>
            <div style={{ padding: 16 }}>
              <Chip size="small" label={"Nasa"} className={styles.customChip} />
            </div>{" "}
            <Divider />
          </>
        )}

        {!isNasa ? (
          <>
            {primaryPhone && (
              <>
                <Box px={2} mt={1}>
                  <Box color={colors.grey140}>
                    <Typography variant="h6" color="inherit">
                      Primary phone
                    </Typography>
                  </Box>
                  <Box
                    py={2}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box display="flex" alignItems="center">
                      <PhoneIcon fontSize={22} />
                      <Box ml={2}>
                        <Typography variant="body1">{primaryPhone}</Typography>
                      </Box>
                    </Box>
                    {primaryPhone && (
                      <Box
                        ml={2}
                        mr={-1}
                        display="flex"
                        flexWrap="wrap"
                        alignItems="center"
                        justifyContent="flex-end"
                      >
                        <Box>
                          <a
                            href={`tel:${primaryPhone}`}
                            className={styles.link}
                          >
                            <ButtonStyled buttonType="text" color="primary">
                              Call
                            </ButtonStyled>
                          </a>
                        </Box>
                        <Box ml={1}>
                          <a
                            href={`sms:${primaryPhone}`}
                            className={styles.link}
                          >
                            <ButtonStyled buttonType="text" color="primary">
                              Text
                            </ButtonStyled>
                          </a>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
                <Divider />
              </>
            )}

            {secondaryPhone && (
              <>
                <Box px={2} mt={1}>
                  <Box color={colors.grey140}>
                    <Typography variant="h6" color="inherit">
                      Secondary phone
                    </Typography>
                  </Box>
                  <Box
                    py={2}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box display="flex" alignItems="center">
                      <PhoneIcon fontSize={22} />
                      <Box ml={2}>
                        <Typography variant="body1">
                          {secondaryPhone}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      ml={2}
                      mr={-1}
                      display="flex"
                      flexWrap="wrap"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <Box>
                        <a
                          href={`tel:${secondaryPhone}`}
                          className={styles.link}
                        >
                          <ButtonStyled buttonType="text" color="primary">
                            Call
                          </ButtonStyled>
                        </a>
                      </Box>
                      <Box ml={1}>
                        <a
                          href={`sms:${secondaryPhone}`}
                          className={styles.link}
                        >
                          <ButtonStyled buttonType="text" color="primary">
                            Text
                          </ButtonStyled>
                        </a>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Divider />
              </>
            )}

            {email && (
              <Box px={2} mt={1}>
                <Box color={colors.grey140}>
                  <Typography variant="h6" color="inherit">
                    Email
                  </Typography>
                </Box>
                <Box
                  py={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box display="flex" alignItems="center">
                    <MessageIcon fontSize={22} />
                    <Box ml={2}>
                      <Typography
                        variant="body1"
                        style={{ wordBreak: "break-word" }}
                      >
                        {email}
                      </Typography>
                    </Box>
                  </Box>
                  {email && (
                    <Box ml={2} mr={-1}>
                      <a href={`mailto:${email}`} className={styles.link}>
                        <ButtonStyled buttonType="text" color="primary">
                          Email
                        </ButtonStyled>
                      </a>
                    </Box>
                  )}
                </Box>
              </Box>
            )}
          </>
        ) : (
          <>
            {appointment.account.phone && (
              <Grid container wrap="nowrap">
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    buttonType="text"
                    color="primary"
                    onClick={() =>
                      openInNewTab(`message:${appointment.account.phone}`)
                    }
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <EmailOutlined />
                      <span style={{ marginLeft: 8 }}>Message</span>
                    </div>
                  </Button>
                </Grid>
                <Grid item>
                  <div style={{ padding: "8px 0px", height: "100%" }}>
                    <Divider orientation="vertical" />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    buttonType="text"
                    color="primary"
                    fullWidth
                    onClick={() =>
                      openInNewTab(`tel:${appointment.account.phone}`)
                    }
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <BoldPencilIcon />
                      <span style={{ marginLeft: 8 }}>Call</span>
                    </div>
                  </Button>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Box>
    );
  },
);
