import { useCallback, useEffect } from "react";

export const useThemeColor = (color?: string, returnColor?: string) => {
  const changeThemeColor = useCallback((newColor: string) => {
    const metaTag = document.querySelector('meta[name="theme-color"]');

    if (!metaTag) {
      return;
    }

    metaTag.setAttribute("content", newColor);
  }, []);

  useEffect(() => {
    if (color) {
      changeThemeColor(color);
    }

    return () => {
      if (returnColor) {
        changeThemeColor(returnColor);
      }
    };
  }, [color, returnColor, changeThemeColor]);

  return {
    changeThemeColor,
  };
};
