import { colors } from "@chhjpackages/components";
import { SvgIcon } from "@material-ui/core";

export const MessageIcon = ({
  className,
  color = colors.grey80,
  fontSize = 24,
}: {
  className?: string;
  color?: string;
  fontSize?: number;
}) => (
  <SvgIcon
    className={className}
    style={{ fontSize }}
    width="24"
    height="23"
    viewBox="0 0 24 23"
    fill="none"
  >
    <path
      d="M1.5 6.28355C1.5 5.52165 2.16667 4.85498 2.92857 4.85498H20.0714C20.8333 4.85498 21.5 5.52165 21.5 6.28355V17.7121C21.5 18.474 20.8333 19.1407 20.0714 19.1407H2.92857C2.16667 19.1407 1.5 18.474 1.5 17.7121V6.28355V6.28355Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M21.1191 5.42651L13.4048 11.4265C12.262 12.2837 10.7381 12.2837 9.59529 11.4265L1.78577 5.42651"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
