import { Box, Typography } from "@material-ui/core";
import { useStore } from "effector-react/compat";
import { memo, useMemo } from "react";

import { $appointmentStore } from "features/appointment";
import { DiscountForm, DiscountFormSkeleton } from "features/discount";
import { useDiscountsPageContext } from "features/coupons-and-discounts";

export const Discounts = memo(() => {
  const { appointment } = useStore($appointmentStore);
  const { control, setValue, watch } = useDiscountsPageContext();

  const disabledDiscounts = useMemo(
    () => (appointment ? appointment.coupon.id !== 0 : false),
    [appointment],
  );

  return (
    <Box p={2} pt={3}>
      {disabledDiscounts && (
        <Box mb={2}>
          <Typography variant="h5">
            You can only add one discount or coupon.
          </Typography>
        </Box>
      )}
      {appointment ? (
        <DiscountForm
          control={control}
          disabled={disabledDiscounts}
          setValue={setValue}
          watch={watch}
        />
      ) : (
        <DiscountFormSkeleton />
      )}
    </Box>
  );
});
