import { Typography } from "@material-ui/core";
import clsx from "clsx";
import { MouseEvent as ReactMouseEvent, memo, useCallback } from "react";

import { RadioButton } from "shared/ui";
import { DiscountTypesEnum } from "shared/types";

import { useStyles } from "./styles";
import { DiscountCardProps } from "./types";

export const DiscountCard = memo(
  ({
    label,
    value,
    amount,
    discountType,
    selected,
    onSelectSelectedValue,
  }: DiscountCardProps) => {
    const styles = useStyles();

    const handleSelectSelectedValue = useCallback(
      (e: ReactMouseEvent<HTMLElement, MouseEvent>) => {
        if (selected) {
          e.preventDefault();
          onSelectSelectedValue(value);
        }
      },
      [selected, value, onSelectSelectedValue],
    );

    return (
      <div className={clsx(styles.root, selected && styles.rootSelected)}>
        <div onClick={handleSelectSelectedValue}>
          <RadioButton
            label={label}
            value={String(value)}
            classes={{ label: styles.radioButtonLabel }}
          />
        </div>
        <div>
          <Typography
            variant="body1"
            color="inherit"
            style={{ fontWeight: "bold" }}
          >
            {discountType === DiscountTypesEnum.Dollar && "$"}
            {Number(amount).toFixed(2)}
            {discountType === DiscountTypesEnum.Percentage && "%"}
          </Typography>
        </div>
      </div>
    );
  },
);
