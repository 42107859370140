import { createEffect, createStore } from "effector/compat";

import { DataList } from "shared/types";

import { getZones } from "./api";

export const getZonesFx = createEffect(getZones);

type ZonesStore = {
  zones: DataList;
  error: Error | null;
  loading: boolean;
};

export const $zonesStore = createStore<ZonesStore>({
  zones: [],
  error: null,
  loading: true,
});

$zonesStore
  .on(getZonesFx.doneData, (store, response) => ({
    ...store,
    zones: response.data.zones,
  }))
  .on(getZonesFx.failData, (store, error) => ({
    ...store,
    error,
  }))
  .on(getZonesFx.pending, (store, loading) => ({
    ...store,
    loading,
  }));
