import { createContext } from "react";

import {
  SidebarDispatchContextValue,
  SidebarStateContextValue,
} from "../types";

export const initialSidebarStateContext: SidebarStateContextValue = {
  isShowSidebar: false,
  sidebarContent: null,
};

export const SidebarStateContext = createContext<SidebarStateContextValue>(
  initialSidebarStateContext,
);
export const SidebarDispatchContext = createContext<
  SidebarDispatchContextValue | undefined
>(undefined);
