import { Grid } from "@material-ui/core";
import { memo } from "react";
import { colors } from "@chhjpackages/components";

import { Appointments, phoneFormat } from "features/appointment";
import { MessageIcon, PhoneIcon } from "shared/assets";

import { ContactItem } from "./contact-item";

export const ContactsDialog = memo(
  ({ appointment }: { appointment?: Appointments }) => {
    return (
      <Grid container direction="column">
        <Grid item>
          <ContactItem
            divider
            icon={<PhoneIcon fontSize={22} color={colors.grey80} />}
            type="Primary phone"
            contact={phoneFormat(appointment?.account.phone || "")}
          />
        </Grid>

        <Grid item>
          <ContactItem
            divider
            icon={<PhoneIcon fontSize={22} color={colors.grey80} />}
            type="Secondary phone"
            contact={phoneFormat(appointment?.account.cell || "")}
          />
        </Grid>
        <Grid item>
          <ContactItem
            isEmail
            icon={<MessageIcon fontSize={22} />}
            type="Email"
            contact={appointment?.account.email || ""}
          />
        </Grid>
      </Grid>
    );
  },
);
