import { colors } from "@chhjpackages/components";
import { Typography } from "@material-ui/core";
import { memo } from "react";

import { FormNewSelect, ImportantBadge } from "shared/ui";

import { BillOfLadingSectionProps, BillOfLadingViewProps } from "./model";

export const JunkRemovalSection = memo(
  ({
    control,
    junkRemoval,
    junkRemovalOptions,
  }: Pick<BillOfLadingSectionProps, "control"> &
    Pick<BillOfLadingViewProps, "junkRemoval" | "junkRemovalOptions">) => {
    return (
      <ImportantBadge
        type="error"
        p={2}
        borderRadius={0}
        color={colors.functionals.alert}
      >
        <Typography variant="body1" color="inherit">
          The quote included ${junkRemoval} of junk removal. Please add a
          specific junk removal product to this appointment.
        </Typography>
        <div style={{ marginTop: 16, backgroundColor: colors.white }}>
          <FormNewSelect
            control={control}
            name="junkRemoval"
            label="Junk removal"
            options={junkRemovalOptions}
            virtualize={false}
          />
        </div>
      </ImportantBadge>
    );
  },
);
