import { colors } from "@chhjpackages/components";
import { SvgIcon } from "@material-ui/core";

export const SearchIcon = ({
  className,
  color = colors.primary.main,
  fontSize = 24,
}: {
  className?: string;
  color?: string;
  fontSize?: number;
}) => (
  <SvgIcon
    className={className}
    style={{ fontSize }}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M15.8645 14.321H15.0515L14.7633 14.0431C15.7719 12.8698 16.3791 11.3466 16.3791 9.68967C16.3791 5.99491 13.3842 3 9.68954 3C5.99485 3 3 5.99491 3 9.68967C3 13.3844 5.99485 16.3793 9.68954 16.3793C11.3465 16.3793 12.8696 15.7721 14.0429 14.7635L14.3208 15.0517V15.8647L19.4666 21.0003L21 19.4669L15.8645 14.321ZM9.68954 14.321C7.12693 14.321 5.05832 12.2523 5.05832 9.68967C5.05832 7.12701 7.12693 5.05836 9.68954 5.05836C12.2521 5.05836 14.3208 7.12701 14.3208 9.68967C14.3208 12.2523 12.2521 14.321 9.68954 14.321Z"
      fill={color}
    />
  </SvgIcon>
);
