import { Box, IconButton, InputAdornment } from "@material-ui/core";
import { FC, memo, useCallback, useState } from "react";

import { VisibilityIcon, VisibilityOffIcon } from "shared/assets";
import { FormInput } from "shared/ui";
import { FormInputProps } from "shared/ui/form-items/types";

import { useStyles } from "./styles";

export const FormInputWithEye: FC<FormInputProps> = memo(
  ({ control, name, placeholder, type, ...props }) => {
    const styles = useStyles();
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = useCallback(() => {
      setShowPassword(!showPassword);
    }, [showPassword, setShowPassword]);

    return (
      <FormInput
        {...props}
        name={name}
        placeholder={placeholder}
        type={showPassword ? "text" : type}
        control={control}
        endAdornment={
          <InputAdornment
            position="end"
            classes={{ root: styles.inputAdortmentRoot }}
          >
            <Box m={-1}>
              <IconButton
                aria-label="toggle password visibility"
                edge="end"
                classes={{ root: styles.eyeIconButtonRoot }}
                onClick={handleClickShowPassword}
              >
                {showPassword ? (
                  <VisibilityOffIcon className={styles.visibilityOffIcon} />
                ) : (
                  <VisibilityIcon />
                )}
              </IconButton>
            </Box>
          </InputAdornment>
        }
        fullWidth
      />
    );
  },
);
