import { fetcher } from "shared/libs";

import { UpdateUserAvatar } from "./types";

export const updateUserAvatar = ({
  locationId,
  userId,
  payload,
}: UpdateUserAvatar.Params) =>
  fetcher.post<UpdateUserAvatar.Response>(
    `/locations/${locationId}/users/${userId}/images`,
    payload,
  );
