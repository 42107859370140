import {
  Box,
  Collapse,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { memo, useCallback, useEffect, useState } from "react";
import { Button } from "@chhjpackages/components";
import { useStore } from "effector-react/compat";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";

import {
  RequestProp,
  JobOfflineCard,
  $offlineRequestsStore,
  repeatOfflineRequestFx,
  removeOfflineRequestEv,
} from "features/job-offline-changes";
import { Accordion, BackTitle, OfflineAccordionLabel } from "shared/ui";
import { ChangesQueue } from "shared/ui/changes-queue";
import { useSideNavDispatch } from "features/sidenav";
import { routePaths } from "shared/utils";

import { groupBy } from "./utils";

export const UnprocessedChanges = memo(() => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: true,
  });

  const navigate = useNavigate();
  const { setPageName } = useSideNavDispatch();

  const { offlineRequests } = useStore($offlineRequestsStore);

  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [grouppedChanges, setChanges] = useState([]);
  const [isSendingRequests, setIsSendingRequests] = useState(false);

  const setOnline = useCallback(() => setIsOnline(true), []);
  const setOffline = useCallback(() => setIsOnline(false), []);

  const onBack = useCallback(
    () => navigate(routePaths.dashboard()),
    [navigate],
  );

  const repeatRequests = useCallback(async () => {
    setIsSendingRequests(true);

    await Promise.all(
      offlineRequests.map((offlineRequest) =>
        repeatOfflineRequestFx(offlineRequest).catch((err) => {
          const error = err as AxiosError;

          if (
            error.response?.status === 400 ||
            error.response?.status === 404
          ) {
            removeOfflineRequestEv({ id: offlineRequest.id });
          }
        }),
      ),
    );

    setIsSendingRequests(false);
  }, [offlineRequests]);

  useEffect(() => {
    const grouppedArray = groupBy(offlineRequests, "id");

    setChanges(grouppedArray);
  }, [offlineRequests]);

  useEffect(() => {
    setPageName("Unprocessed changes");
    window.addEventListener("offline", setOffline);
    window.addEventListener("online", setOnline);

    return () => {
      setPageName("");
      window.removeEventListener("offline", setOffline);
      window.removeEventListener("online", setOnline);
    };
  }, []);

  return (
    <>
      {!isMobile && <BackTitle onBack={onBack} title="Unprocessed changes" />}
      <Box p={2}>
        <Collapse in={!isOnline}>
          <Box mb={2}>
            <ChangesQueue
              title="YOU ARE Currently OFFLINE"
              body="Changes will be placed in queue."
              isDashboard={false}
            />
          </Box>
        </Collapse>

        {Object.keys(grouppedChanges).length === 0 && (
          <Typography variant="body1">
            There are no unprocessed changes.
          </Typography>
        )}

        {Object.keys(grouppedChanges).length > 0 &&
          Object.keys(grouppedChanges).map((key: any) => (
            <Box key={key} mb={3}>
              <Accordion header={<OfflineAccordionLabel title={key} />}>
                <JobOfflineCard
                  requests={grouppedChanges[key] as RequestProp[]}
                />
              </Accordion>
            </Box>
          ))}

        {Object.keys(grouppedChanges).length > 0 && (
          <Box mx={2}>
            <Button
              buttonType="twoTone"
              size="medium"
              fullWidth
              disabled={!isOnline}
              isLoading={isSendingRequests}
              onClick={repeatRequests}
            >
              Retry connecting
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
});
