import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormEvent, useEffect, useMemo, useRef } from "react";
import { useConfirmationDialog } from "@chhjpackages/components";
import { Box, Typography } from "@material-ui/core";

import { ReactComponent as CardWithChip } from "../../assets/images/card-with-chip.svg";
import {
  PaymentFormProps,
  PaymentFormValues,
  PaymentMethodsEnum,
} from "../types";
import {
  getDefaultTerminal,
  getPaymentFormsShema,
  paymentMethodsOptions,
  setDefaultTerminal,
} from "../utils";
import { PaymentProviderEnum, usePaymentProvider } from "entities/payments";

export const usePaymentForm = ({
  terminals,
  initialValues,
  onSubmit,
}: PaymentFormProps) => {
  const paymentProvider = usePaymentProvider();

  const { openConfirmation, closeConfirmation } = useConfirmationDialog({
    title: "Confirm chipless card",
    message: (
      <div>
        <Typography>
          Please confirm that there is no chip as shown on the diagram below.
        </Typography>

        <Box display="flex" justifyContent="center" mt={2}>
          <CardWithChip />
        </Box>
      </div>
    ),
    maxWidth: 364,
  });

  const isManual = useMemo(
    () => paymentProvider === PaymentProviderEnum.Manual,
    [paymentProvider],
  );

  const terminalsOptions = useMemo(
    () =>
      terminals.map((terminal) => ({
        label: terminal.nickname,
        value: terminal.serialNumber,
      })),
    [terminals],
  );

  const defaultTerminal = useMemo(() => {
    const terminalSerialNumber = getDefaultTerminal();

    return terminalsOptions.find(
      (terminal) => terminal.value === terminalSerialNumber,
    );
  }, [terminalsOptions]);

  const initialMethod = useMemo(
    () => initialValues.method ?? paymentMethodsOptions[2],
    [initialValues.method],
  );

  const {
    control,
    formState,
    setValue,
    watch,
    reset,
    clearErrors,
    handleSubmit,
  } = useForm<PaymentFormValues>({
    mode: "all",
    resolver: yupResolver(getPaymentFormsShema(isManual)),
    defaultValues: {
      terminal: initialValues.terminal ?? defaultTerminal,
      method: initialMethod,
      amount: (Number(initialValues.amount) ?? 0).toFixed(2),
      isContactless:
        initialMethod.value === PaymentMethodsEnum.CreditCard &&
        paymentProvider === PaymentProviderEnum.HunkPay,
    },
  });

  const methodWatched = watch("method");
  const amountWatched = watch("amount");
  const isContactless = watch("isContactless");

  const showTerminal = useMemo(
    () =>
      methodWatched.value === PaymentMethodsEnum.CreditCard &&
      paymentProvider === PaymentProviderEnum.HunkPay,
    [methodWatched.value, paymentProvider],
  );

  const showPaymentDate = useMemo(
    () => methodWatched.value !== PaymentMethodsEnum.CreditCard || isManual,
    [methodWatched.value, isManual],
  );

  const showCheckNumber = useMemo(
    () => methodWatched.value === PaymentMethodsEnum.Check,
    [methodWatched.value],
  );

  const showDepositDateAndNumber = useMemo(
    () =>
      methodWatched.value === PaymentMethodsEnum.Check ||
      methodWatched.value === PaymentMethodsEnum.Cash,
    [methodWatched.value],
  );

  const showNotes = useMemo(
    () => methodWatched.value !== PaymentMethodsEnum.CreditCard,
    [methodWatched.value],
  );

  const showManualCard = useMemo(
    () => methodWatched.value === PaymentMethodsEnum.CreditCard && isManual,
    [methodWatched.value, isManual],
  );

  const isTerminalPayment = useMemo(
    () =>
      methodWatched.value === PaymentMethodsEnum.CreditCard &&
      paymentProvider === PaymentProviderEnum.HunkPay,
    [methodWatched.value, paymentProvider],
  );

  const disableSubmit = useMemo(
    () => !formState.isValid || Number(amountWatched) <= 0,
    [formState.isValid, amountWatched],
  );

  const handleOnSubmit = useMemo(() => {
    if (showTerminal && !isContactless) {
      return (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();

        openConfirmation(async () => {
          handleSubmit(
            (values) =>
              new Promise((resolve, rej) => {
                if (values.terminal?.value) {
                  setDefaultTerminal(values.terminal?.value);
                }

                closeConfirmation();

                onSubmit(values)
                  .then((resp) => resolve(resp))
                  .catch((err) => rej(err));
              }),
          )();
        });
      };
    }

    return handleSubmit(async (values) => {
      if (values.terminal?.value) {
        setDefaultTerminal(values.terminal?.value);
      }

      await onSubmit(values);
    });
  }, [
    closeConfirmation,
    handleSubmit,
    isContactless,
    onSubmit,
    openConfirmation,
    showTerminal,
  ]);

  useEffect(() => {
    setValue("amount", (Number(initialValues.amount) ?? 0).toFixed(2));
  }, [initialValues.amount]);

  useEffect(() => {
    if (amountWatched) {
      setValue("amount", Number(amountWatched).toFixed(2), {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  }, [amountWatched]);

  const lastMethodValue = useRef<number | null>(methodWatched.value);
  useEffect(() => {
    if (methodWatched.value !== lastMethodValue.current) {
      lastMethodValue.current = methodWatched.value;
      reset({
        method: methodWatched,
        amount: amountWatched,
        terminal: undefined,
        paymentDate: "",
        checkNumber: "",
        depositDate: "",
        depositNumber: "",
        notes: "",
        cardType: undefined,
        cardNumber: "",
        cardName: "",
        expMonth: undefined,
        expYear: undefined,
        address1: "",
        address2: "",
        zip: "",
        transactionId: "",
        authCode: "",
      });
      setTimeout(() => {
        clearErrors();
      }, 0);
    }
  }, [methodWatched]);

  return {
    control,
    formState,
    showTerminal,
    showPaymentDate,
    showCheckNumber,
    showDepositDateAndNumber,
    showNotes,
    showManualCard,
    terminalsOptions,
    isTerminalPayment,
    disableSubmit,
    isContactless,
    handleOnSubmit,
  };
};
