import { Box } from "@material-ui/core";
import { useStore } from "effector-react/compat";
import { memo, useCallback, useEffect, useState } from "react";
import { useAlert } from "@chhjpackages/components";

import {
  $authorizedUser,
  updateUserAvatarFx,
  updateUserEmailFx,
  updateUserFx,
  updateUserPhoneFx,
} from "features/auth";
import {
  UserProfileForm,
  UserProfileFormSkeleton,
  UserProfileFormValues,
} from "features/user-profile";
import { useSideNavDispatch } from "features/sidenav";
import { fileToBase64 } from "shared/utils";

export const UserProfile = memo(() => {
  const { showAlert } = useAlert();
  const { setPageName } = useSideNavDispatch();

  const { user } = useStore($authorizedUser);

  const [isUserProfileSubmiting, setIsUserProfileSubmiting] = useState(false);

  const onSubmitUserProfile = useCallback(
    async (data: UserProfileFormValues) => {
      if (!user) {
        return;
      }

      setIsUserProfileSubmiting(true);

      if (
        data.firstName !== user.firstName ||
        data.lastName !== user.lastName ||
        data.bio !== user.bio
      ) {
        try {
          await updateUserFx({
            locationId: user.location.id,
            userId: user.id,
            payload: {
              firstName: data.firstName,
              lastName: data.lastName,
              bio: data.bio,
            },
          });

          showAlert("Success! User info has been updated.", {
            variant: "success",
          });
        } catch {
          showAlert(
            "Error! Failed to update user info. Please try again later.",
            {
              variant: "error",
            },
          );
        }
      }

      if (data.email !== user.email) {
        try {
          await updateUserEmailFx({
            payload: {
              email: data.email,
              username: user.userName,
            },
          });

          showAlert(
            "Success! Verification link has been sent to specified email.",
            {
              variant: "success",
            },
          );
        } catch {
          showAlert("Error! Failed to update email.\nPlease try again later.", {
            variant: "error",
          });
        }
      }

      if (data.phone !== user.sms) {
        try {
          await updateUserPhoneFx({
            payload: {
              sms: data.phone,
              username: user.userName,
            },
          });

          showAlert(
            "Success! Verification link has been sent to the specified phone.",
            {
              variant: "success",
            },
          );
        } catch {
          showAlert("Error! Failed to update phone.\nPlease try again later.", {
            variant: "error",
          });
        }
      }

      if (data.blobImage) {
        try {
          const content = await fileToBase64(data.blobImage);
          await updateUserAvatarFx({
            locationId: user.location.id,
            userId: user.id,
            payload: {
              content: content,
            },
          });

          showAlert("Success! Avatar has been updated.", {
            variant: "success",
          });
        } catch {
          showAlert("Error! Failed to update avatar. Please try again later.", {
            variant: "error",
          });
        }
      }

      setIsUserProfileSubmiting(false);
    },
    [user, showAlert],
  );

  useEffect(() => {
    setPageName("User profile");

    return () => {
      setPageName("");
    };
  }, [setPageName]);

  return (
    <Box p={2}>
      {user ? (
        <UserProfileForm
          user={user}
          isSubmiting={isUserProfileSubmiting}
          onSubmit={onSubmitUserProfile}
        />
      ) : (
        <UserProfileFormSkeleton />
      )}
    </Box>
  );
});
