import {
  Box,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import { Button, colors, Input } from "@chhjpackages/components";
import { memo, ReactNode, useCallback, useState } from "react";

import {
  FilterIcon,
  GridViewIcon,
  ListViewIcon,
  SearchIcon,
} from "shared/assets";

import { useStyles } from "./styles";
import { FilterItem, FiltersBarProps } from "./model";
import { FiltersMobileSkeleton } from "./ui";

export const FiltersMobile = memo(
  <T,>({
    isLoading,
    isSearch,
    isGridView,
    countFilters,
    filters,
    handleSetView,
    renderFilterItem,
    resetFilters,
    onSearch,
  }: Omit<FiltersBarProps<T>, "onFilter"> & {
    countFilters: number;
    renderFilterItem: (filter: FilterItem<T>) => ReactNode;
    resetFilters: () => void;
  }) => {
    const styles = useStyles();
    const [expanded, setExpanded] = useState<boolean>(false);
    const handleFilterExpand = useCallback(() => {
      setExpanded(!expanded);
    }, [expanded, setExpanded]);

    const setSearch = useCallback(
      (event) => {
        onSearch(event.target.value);
      },
      [onSearch],
    );

    return isLoading ? (
      <FiltersMobileSkeleton />
    ) : (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: colors.basic.white,
          borderTop: `solid 1px ${colors.grey30}`,
          padding: 16,
        }}
      >
        <Grid
          container
          alignItems="center"
          justify="space-between"
          spacing={2}
          wrap="nowrap"
        >
          {isSearch && (
            <Grid item xs={12}>
              <Box
                flex={{ xs: "initial", md: 1 }}
                width={{ xs: "100%", md: "initial" }}
              >
                <Input
                  onChange={setSearch}
                  className={styles.input}
                  variant="standard"
                  placeholder="Search jobs & estimates"
                  startAdornment={
                    <SearchIcon
                      className={styles.searchIcon}
                      fontSize={16}
                      color={colors.grey120}
                    />
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: 42.5,
                          height: 33,
                          backgroundColor: colors.basic.white,
                          borderRadius: 16,
                        }}
                      >
                        <div>
                          <IconButton
                            className={styles.button}
                            onClick={handleFilterExpand}
                          >
                            <FilterIcon />
                          </IconButton>
                        </div>

                        <div
                          style={{ position: "absolute", right: 8, bottom: 3 }}
                        >
                          <div
                            style={{
                              display: "flex",
                              width: 13,
                              height: 13,
                              backgroundColor: colors.primary.main,
                              color: colors.basic.white,
                              fontWeight: 700,
                              fontSize: 10,
                              borderRadius: 16,
                              lineHeight: "11px",
                              alignItems: "center",
                              justifyContent: "center",
                              textAlign: "center",
                            }}
                          >
                            <div
                              style={{
                                alignSelf: "center",
                                justifySelf: "center",
                                textAlign: "center",
                              }}
                            >
                              {countFilters}
                            </div>
                          </div>
                        </div>
                      </div>
                    </InputAdornment>
                  }
                  fullWidth
                />
              </Box>
            </Grid>
          )}
          <Grid item>
            <div style={{ margin: -12 }}>
              <IconButton onClick={handleSetView}>
                {isGridView ? <GridViewIcon /> : <ListViewIcon />}
              </IconButton>
            </div>
          </Grid>
        </Grid>

        <div style={{ zIndex: 6 }}>
          <Collapse in={expanded} unmountOnExit>
            <div style={{ marginTop: 16 }}>
              {countFilters > 0 && (
                <div
                  style={{
                    display: "flex",
                    justifySelf: "flex-end",
                    justifyContent: "flex-end",
                  }}
                >
                  <div style={{ margin: "0px -8px" }}>
                    <Button
                      buttonType="text"
                      color="primary"
                      classes={{ root: styles.button }}
                      onClick={resetFilters}
                    >
                      Reset filters
                    </Button>
                  </div>
                </div>
              )}
              <div>
                {filters?.map((filter) => (
                  <div
                    style={{ zIndex: 6, backgroundColor: colors.basic.white }}
                    key={filter.id}
                  >
                    <Grid container direction="column" spacing={2}>
                      <Grid item>
                        <div style={{ color: colors.grey140 }}>
                          <Typography variant="h6" color="inherit">
                            {filter.name}
                          </Typography>
                        </div>
                      </Grid>
                      <div style={{ margin: "0px 8px 8px" }}>
                        {renderFilterItem(filter)}
                      </div>
                    </Grid>
                  </div>
                ))}
              </div>
            </div>
          </Collapse>
        </div>
      </div>
    );
  },
);
