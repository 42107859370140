import { Typography } from "@material-ui/core";
import { memo, useCallback } from "react";
import { colors } from "@chhjpackages/components";

import { ProductCard, ProductCardSkeleton } from "features/products";
import { EmptyBoxIcon } from "shared/assets";

import { ProductsListProps } from "./types";
import { useStyles } from "./styles";

export const ProductsList = memo(
  ({ products, loading, disable, onEdit, onDelete }: ProductsListProps) => {
    const styles = useStyles();

    const handleDeleteProduct = useCallback(
      async (productLineId: number) => {
        await onDelete(productLineId);
      },
      [onDelete],
    );

    return (
      <div style={{ width: "100%" }}>
        {loading &&
          [...Array(3)].map((_, i) => (
            <div key={i} style={{ marginTop: i !== 0 ? 16 : 0 }}>
              <ProductCardSkeleton />
            </div>
          ))}
        {!loading &&
          products.length !== 0 &&
          products.map((product, i) => (
            <div key={i} style={{ marginTop: i !== 0 ? 16 : 0 }}>
              <ProductCard
                product={product}
                disableActions={disable}
                onEdit={() => onEdit(product.productLineId)}
                onDelete={() => handleDeleteProduct(product.productLineId)}
              />
            </div>
          ))}
        {!loading && products.length === 0 && (
          <div
            style={{ display: "flex", justifyContent: "center", margin: 16 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                flex: 1,
              }}
            >
              <EmptyBoxIcon className={styles.emptyBoxIcon} />
              <div
                style={{
                  marginTop: 48,
                  color: colors.grey140,
                  textAlign: "center",
                }}
              >
                <Typography variant="h4" color="inherit">
                  No products have been added
                </Typography>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  },
);
