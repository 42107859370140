import { memo } from "react";
import { Typography } from "@material-ui/core";
import { colors } from "@chhjpackages/components";

import {
  TitlesProps,
  GuideListArrayProps,
  GuideStepGroupProps,
  GuideStepCard,
} from "features/training-details";

export const GuideStepsGroup = memo(
  ({ guideListArray, stepsValidate, callGuideJob }: GuideStepGroupProps) => {
    return (
      <div>
        {guideListArray.map((content: GuideListArrayProps, index) => (
          <div key={index}>
            <div
              style={{ marginTop: index !== 0 ? 24 : 0, color: colors.grey140 }}
            >
              <Typography variant="h5" color="inherit">
                {content.category}
              </Typography>
            </div>

            {content.titles.map((heading: TitlesProps, i) => (
              <div
                key={heading.id}
                style={{
                  marginTop: i === 0 ? (!!content.category ? 16 : 0) : 16,
                }}
              >
                <GuideStepCard
                  title={heading.title}
                  titleIndex={heading.id}
                  stepsValidate={stepsValidate}
                  onClickGuideStep={callGuideJob}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  },
);
