export const isEmail = (string: string) =>
  string
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

export const ishasUpperCase = (string: string) => /[A-Z]/.test(string);

export const ishasLowerCase = (string: string) => /[a-z]/.test(string);

export const ishasNumbers = (string: string) => /\d/.test(string);

export const ishasSymbols = (string: string) =>
  /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(string);
