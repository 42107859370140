import { Control, UseFormSetValue, UseFormWatch } from "react-hook-form";

import { CalculatedQuote } from "features/quotes";
import { DiscountTypesEnum, NewSelectOption } from "shared/types";

export type CoupounDiscountOption = {
  label: string;
  amount: number;
  value: CoupounsDiscountsEnum;
  discountType: DiscountTypesEnum;
};

export type BillOfLadingFormValues = {
  numOfHunksMoving: number;
  numOfHoursMoving: number;
  hourlyRateMoving: string;
  truckAndTravelFee: string;
  numOfHunksPacking: number;
  numOfHoursPacking: number;
  hourlyRatePacking: string;
  junkRemoval?: NewSelectOption;
  additionalProducts: {
    id: number;
    quantity: number;
    price: number;
    name: string;
    hidden?: boolean;
  }[];
  couponDiscount?: CoupounDiscountOption;
};

export type BillOfLadingSectionProps = {
  control: Control<BillOfLadingFormValues, unknown>;
  setValue: UseFormSetValue<BillOfLadingFormValues>;
  watch: UseFormWatch<BillOfLadingFormValues>;
};

export type BillOfLadingViewProps = {
  calculatedQuote: CalculatedQuote | null;
  junkRemoval: number;
  junkRemovalOptions: NewSelectOption[];
  discountsCouponsOptions: CoupounDiscountOption[];
} & BillOfLadingSectionProps;

export enum CoupounsDiscountsEnum {
  Coupon = 1,
  DiscountJob = 2,
  DiscountQuote = 3,
}
