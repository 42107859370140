import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { useAlert, useConfirmationDialog } from "@chhjpackages/components";
import { useStore } from "effector-react/compat";

import { ActionsFooter, BackTitle, PdfViewer, SignatureArea } from "shared/ui";
import { useSignDialog, useSignPdf } from "features/signature";
import {
  $completedActions,
  getCompletedAction,
  useCompletedActions,
} from "features/completed-actions";
import { $auth } from "features/auth";
import { $appointmentStore, getAppointmentFx } from "features/appointment";
import { CompletedActionsJobEnum } from "shared/types";
import { useSideNavDispatch } from "features/sidenav";
import { fileToBase64, routePaths, allFieldsDefined } from "shared/utils";
import { addAttachmentsFx, getAttachmentsFx } from "features/attachments";
import {
  $addendum,
  generateAddendumFx,
  getCurrentAddendumFx,
  updateAddendumFx,
} from "entities/addendum";

import { useSignatureStyles } from "./assets";
import { AddendumSignatureLocationState } from "./model";

export const AddendumSignature = memo(() => {
  const styles = useSignatureStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), { noSsr: true });

  const location = useLocation();
  const { appointmentId } = useParams();
  const navigate = useNavigate();

  const { locationId } = useStore($auth);
  const { appointment, loading: appointmentLoading } =
    useStore($appointmentStore);
  const { completedActions } = useStore($completedActions);
  const { addendum } = useStore($addendum);

  const {
    setPageName,
    setShowGoBackButton,
    setGoToBackUrl,
    setGoToBackOptions,
    setElevation,
  } = useSideNavDispatch();

  const [isAddendumGetting, setIsAddendumGetting] = useState(true);
  const [isPdfRendering, setIsPdfRendering] = useState(true);
  const [isAddendumSaving, setIsAddendumSaving] = useState(false);
  const [signatureMode, setSignatureMode] = useState<
    "crew" | "client" | "done"
  >("crew");

  const { showAlert } = useAlert();
  const { addCompletedAction } = useCompletedActions();

  const currentSignaturePdfField = useMemo(() => {
    if (signatureMode === "crew") {
      return "signature_2";
    } else if (signatureMode === "client") {
      return "signature_1";
    }
  }, [signatureMode]);

  const signatureFieldOffset = useMemo(() => {
    const xOffset = signatureMode === "crew" ? -49 : -45;

    return {
      x: xOffset,
      y: -19,
    };
  }, [signatureMode]);

  const {
    pdfRef,
    pdfWidth,
    blobPdf,
    pdfDoc,
    signs,
    countPages,
    pageNumberWithSignature,
    signatureFieldCoordinates,
    getPdfDoc,
    addSign,
    savePdf,
    renderSign,
  } = useSignPdf({
    width: 800,
    currentSignaturePdfField,
    signatureFieldOffset,
    isLoading: isPdfRendering,
  });

  const noticeMessage = useMemo(() => {
    if (signatureMode === "client") {
      return "Please give the device to the client";
    }

    if (signatureMode === "done") {
      return "Please give the device back to the crew member";
    }
  }, [signatureMode]);

  const {
    openConfirmation: handleNoticeDialogOpen,
    closeConfirmation: handleNoticeDialogClose,
  } = useConfirmationDialog({
    title: "Notice",
    message: noticeMessage,
    confirmButtonText: "Continue",
    hideCancelButton: true,
    maxWidth: 488,
  });

  const locationState = useMemo<AddendumSignatureLocationState | undefined>(
    () => location.state,
    [location.state],
  );

  const isAllLocationStateFieldsDefined = useMemo(
    () =>
      allFieldsDefined(locationState, [
        "typeOfChange",
        "additionalNotes",
        "descriptionOfSituation",
        "estimateOld",
        "estimateNew",
        "updateProducts",
      ]),
    [locationState],
  );

  const isAddendumCompleted = useMemo(() => {
    if (!appointment?.id || appointmentLoading) {
      return null;
    }

    return !!getCompletedAction(
      completedActions,
      CompletedActionsJobEnum.FinalizeAddendum,
    );
  }, [appointment?.id, appointmentLoading, completedActions]);

  const signDialogTitle = useMemo(
    () => `${signatureMode === "crew" ? "Crew" : "Client"} signature`,
    [signatureMode],
  );

  const backPath = useMemo(
    () =>
      isAddendumCompleted
        ? routePaths.jobDetails(Number(appointmentId))
        : routePaths.jobDetailsAddendum(Number(appointmentId)),
    [isAddendumCompleted, appointmentId],
  );

  const backState = useMemo(
    () =>
      isAddendumCompleted ? undefined : { ...locationState, isAddendum: true },
    [isAddendumCompleted, locationState],
  );

  const isShouldDisplayFooter = useMemo(
    () => !isAddendumCompleted && !isPdfRendering,
    [isAddendumCompleted, isPdfRendering],
  );

  const handleBack = useCallback(
    () => navigate(backPath, { state: backState }),
    [backPath, backState, navigate],
  );

  const handleSubmitSignDialog = useCallback(
    (signBlob: Blob) => {
      if (!pageNumberWithSignature) {
        return;
      }

      addSign({ blob: signBlob, imageHeight: 27 }, pageNumberWithSignature, {
        ...signatureFieldCoordinates,
        width: {
          type: "percent",
          value: 38.1,
        },
      });

      if (signatureMode === "crew") {
        setSignatureMode("client");
      } else if (signatureMode === "client") {
        setSignatureMode("done");
      }

      if (signatureMode !== "done") {
        setTimeout(() => {
          handleNoticeDialogOpen(() => handleNoticeDialogClose());
        }, 0);
      }
    },
    [
      signatureMode,
      pageNumberWithSignature,
      signatureFieldCoordinates,
      addSign,
      handleNoticeDialogOpen,
      handleNoticeDialogClose,
    ],
  );

  const { handleSignDialogOpen } = useSignDialog({
    title: signDialogTitle,
    onSubmit: handleSubmitSignDialog,
  });

  const handleSaveAddendum = useCallback(async () => {
    if (!appointment || !addendum?.id) {
      showAlert("Error! Something went wrong. Try again later.", {
        variant: "error",
      });
      return;
    }

    setIsAddendumSaving(true);

    try {
      const newBlobPdf = await savePdf();

      if (!newBlobPdf) {
        throw new Error();
      }

      await Promise.all([
        await updateAddendumFx({
          locationId: appointment.location.id,
          appointmentId: appointment.id,
          addendumId: addendum.id,
          payload: {
            content: await fileToBase64(newBlobPdf),
          },
        }),
        await addAttachmentsFx({
          locationId: appointment.location.id,
          appointmentId: appointment.id,
          description: `Addendum To Estimated Cost Of Services - ${appointment.id} - Electronically Signed Document`,
          extension: "pdf",
          content: await fileToBase64(newBlobPdf),
          typeId: 2,
          emailClient: true,
        }).then(() =>
          getAttachmentsFx({
            locationId: appointment.location.id,
            appointmentId: appointment.id,
          }),
        ),
        await addCompletedAction(
          appointment.id,
          appointment.location.id,
          CompletedActionsJobEnum.FinalizeAddendum,
        ),
      ]);

      showAlert("Success! Document has been uploaded.", { variant: "success" });

      navigate(routePaths.jobDetails(appointment.id));
    } catch {
      showAlert("Error! Failed to upload document.", { variant: "error" });
    }

    setIsAddendumSaving(false);
  }, [
    appointment,
    addendum?.id,
    showAlert,
    navigate,
    addCompletedAction,
    savePdf,
  ]);

  useEffect(() => {
    if (
      !isAllLocationStateFieldsDefined &&
      !isAddendumCompleted &&
      !!appointment
    ) {
      handleBack();
    }
  }, [isAllLocationStateFieldsDefined, isAddendumCompleted, appointment?.id]);

  useEffect(() => {
    if (!isAddendumGetting && addendum?.url && !pdfDoc) {
      getPdfDoc(addendum.url);
    }
  }, [isAddendumGetting, addendum?.url, pdfDoc, getPdfDoc]);

  useEffect(() => {
    const getAddendum = async (locId: number, appId: number) => {
      try {
        if (isAddendumCompleted) {
          await getCurrentAddendumFx({
            locationId: locId,
            appointmentId: appId,
          });
        } else if (locationState && isAllLocationStateFieldsDefined) {
          await generateAddendumFx({
            locationId: locId,
            appointmentId: appId,
            payload: {
              typeOfTheChanges: locationState.typeOfChange!,
              additionalNotes: locationState.additionalNotes!,
              descriptionOfTheSituation: locationState.descriptionOfSituation!,
              estimateOld: locationState.estimateOld!,
              estimateNew: locationState.estimateNew!,
              updateProducts: locationState.updateProducts!,
            },
          });
        }
      } catch {
        showAlert("Error! Failed to get document. Try again later.", {
          variant: "error",
        });
      }

      setIsAddendumGetting(false);
    };

    if (
      appointment?.id &&
      typeof isAddendumCompleted === "boolean" &&
      isAddendumGetting
    ) {
      getAddendum(appointment.location.id, appointment.id);
    }
  }, [
    appointment?.id,
    appointment?.location.id,
    isAddendumCompleted,
    isAddendumGetting,
    isAllLocationStateFieldsDefined,
    locationState,
  ]);

  useEffect(() => {
    if (locationId && appointment?.id !== Number(appointmentId)) {
      getAppointmentFx({
        locationId: locationId,
        appointmentId: Number(appointmentId),
      }).catch(() => {
        showAlert("Error! Failed to get appointment. Try again later.", {
          variant: "error",
        });
      });
    }
  }, [locationId, appointment?.id, appointmentId]);

  useEffect(() => {
    setPageName("Addendum");
    setShowGoBackButton(true);
    setGoToBackUrl(backPath);
    setGoToBackOptions({ state: backState });
    setElevation(16);

    return () => {
      setPageName("");
      setShowGoBackButton(false);
      setGoToBackUrl("");
      setGoToBackOptions(undefined);
      setElevation(0);
    };
  }, [
    backPath,
    backState,
    setPageName,
    setShowGoBackButton,
    setGoToBackUrl,
    setGoToBackOptions,
    setElevation,
  ]);

  return (
    <div className={styles.root}>
      {!isMobile && (
        <BackTitle title="Addendum" elevation={16} onBack={handleBack} />
      )}

      <div className={styles.pdfViewerContainer}>
        <div className={styles.pdfViewerWrapper}>
          <PdfViewer
            ref={pdfRef}
            width={pdfWidth}
            pagesCount={countPages}
            loading={isPdfRendering}
            documentProps={{
              file: blobPdf,
            }}
            pageProps={{
              onRenderSuccess: () => {
                setIsPdfRendering(false);
              },
            }}
            pageContent={(pageNumber) => {
              const shouldRenderSignatureArea =
                !isAddendumCompleted &&
                signatureMode !== "done" &&
                pageNumber === pageNumberWithSignature &&
                signatureFieldCoordinates.x !== 0 &&
                signatureFieldCoordinates.y !== 0;

              const signatureAreaElement = shouldRenderSignatureArea ? (
                <SignatureArea
                  classes={{ root: styles.signatureArea }}
                  style={{
                    left: `${signatureFieldCoordinates.x}px`,
                    top: `${signatureFieldCoordinates.y}px`,
                  }}
                  onClick={handleSignDialogOpen}
                />
              ) : (
                <></>
              );

              const signElements = signs.map((sign) =>
                sign.pageNumber === pageNumber ? renderSign(sign) : <></>,
              );

              return (
                <>
                  {signatureAreaElement}
                  {signElements}
                </>
              );
            }}
          />
        </div>
      </div>

      <ActionsFooter
        show={isShouldDisplayFooter}
        actions={[
          {
            label: "Sign",
            hide: signatureMode === "done",
            buttonType: "filled",
            onClick: handleSignDialogOpen,
          },
          {
            label: "Save",
            hide: signatureMode !== "done",
            buttonType: "filled",
            isLoading: isAddendumSaving,
            onClick: handleSaveAddendum,
          },
        ]}
      />
    </div>
  );
});
