import { colors } from "@chhjpackages/components";
import { Box, makeStyles, withStyles } from "@material-ui/core";

export const FilterButton = withStyles(() => ({
  root: {
    border: `1px solid ${colors.primary.main}`,
    borderRadius: 100,
    padding: "5px 16px",
    fontSize: 18,
    color: colors.primary.main,
    cursor: "pointer",
    backgroundColor: colors.white,

    "&.active": {
      backgroundColor: colors.primary.main,
      color: colors.white,
    },

    "&:not(.active):hover": {
      backgroundColor: colors.primary.superlight,
    },
  },
}))(Box);

export const useStyles = makeStyles({
  button: {
    padding: "0 8px",
  },
  input: {
    display: "flex",
    alignItems: "center",
    backgroundColor: colors.grey20,
    borderRadius: 16,
    padding: 8,
    height: 43,

    "& ::placeholder": {
      color: `${colors.grey120} !important`,
      fontWeight: "normal !important",
      fontSize: "13px !important",
      lineHeight: "16.24px",
      letterSpacing: "2%",
    },

    "& .MuiInput-input": {
      color: `${colors.grey120} !important`,
      fontWeight: "normal !important",
      fontSize: "13px !important",
      paddingLeft: "0 !important",
    },

    "& .MuiInput-underline": {
      "&::before": {
        content: "none",
      },
      "&::after": {
        content: "none",
      },
    },
  },
  searchIcon: {
    margin: "0 13px 0 11px",
  },
  filterSelect: {
    backgroundColor: colors.white,
    borderRadius: 100,
  },
  filterSelectActive: {
    backgroundColor: `${colors.primary.main} !important`,
    color: colors.white,

    "& .MuiSelect-select.MuiInputBase-input": {
      color: colors.white,
      whiteSpace: "pre-wrap",
    },

    "& .MuiSvgIcon-root.MuiSelect-icon": {
      color: colors.white,
    },
  },
});
