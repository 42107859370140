import { FilterItem } from "../types";

export const filterMapping = <T>(
  data: T[],
  filter: FilterItem<T>,
  condition: (data: string | number) => boolean,
) => {
  return data.filter((item) => {
    const filteredData = filter?.filterByComplexField
      ? filter.filterByComplexField(Object(item)[filter.filterByField])
      : Object(item)[filter.filterByField];

    return condition(filteredData);
  });
};

export const returnFilters = () => {
  const filterValue = localStorage.getItem("crew/filters");

  if (filterValue) {
    const filterObject = JSON.parse(filterValue);

    if (filterObject) {
      return filterObject;
    }
  }
};
