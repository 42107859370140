import { fetcher } from "shared/libs";
import { OperationLogs } from "../types";

export const addOperationLog = ({
  locationId,
  appointmentId,
  payload,
}: OperationLogs.Create) =>
  fetcher.post<OperationLogs.Response>(
    `/locations/${locationId}/appointments/${appointmentId}/operationlogs`,
    payload,
  );
