import { Theme, makeStyles } from "@material-ui/core";
import { colors } from "@chhjpackages/components";

import Wallpaper from "../../assets/images/truck-wallpaper.jpeg";

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "100%",

    [theme.breakpoints.down("xs")]: {
      display: "block",
      minHeight: "initial",
    },
  },

  header: {
    padding: "16px 32px",
    maxWidth: 800,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },

  mainText: {
    marginRight: 24,

    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
      marginBottom: 24,
    },
  },

  surcharges: {
    boxShadow: "0px 0px 16px 0px rgba(134, 145, 166, 0.16)",
    position: "relative",
    backgroundColor: colors.basic.white,
  },

  surchargesTitle: {
    padding: 16,
    paddingRight: 96,
    borderBottom: `2px dashed ${colors.grey100}`,
  },

  surchargesList: {
    position: "absolute",
    top: "100%",
    left: 0,
    width: "100%",
    backgroundColor: colors.basic.white,
  },

  surchargesListItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 16px",

    "&:nth-of-type(even)": {
      backgroundColor: colors.grey10,
    },
  },

  surchargeName: {
    minHeight: 22,
  },

  body: {
    flex: 1,
    height: "100%",
    width: "100%",
    backgroundImage: `url(${Wallpaper})`,
    backgroundSize: "cover",
    backgroundPosition: "center",

    [theme.breakpoints.down("xs")]: {
      height: "40vh",
    },
  },

  footer: {
    padding: "16px 32px",
    maxWidth: 800,
    width: "100%",
  },

  priceList: {
    display: "flex",
    marginTop: 16,
    gap: 8,

    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
    },
  },

  priceCard: {
    border: `1px solid ${colors.grey20}`,
    borderRadius: 4,
    backgroundColor: colors.grey10,
    padding: 4,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1,

    [theme.breakpoints.down("xs")]: {
      width: "calc(50% - 4px)",
      flex: "initial",

      "&:last-child": {
        width: "100%",
      },
    },
  },

  priceCardCustom: {
    flex: "initial",
  },

  price: {
    height: 27,

    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
}));
