import { colors } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  root: {
    "&:before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      height: 16,
      boxShadow: "0px -8px 8px rgba(134, 145, 166, 0.08)",
      pointerEvents: "none",
    },
  },
  additionalContentContainer: {
    padding: "8px 24px",
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },
  productTotalContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 16,
  },
  editingItemsList: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    padding: 16,
    backgroundColor: colors.grey10,
  },
  totalPriceContainer: {
    padding: "8px 24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 16,
  },
  totalPriceTitle: {
    fontSize: 18,
    color: colors.grey140,
    fontWeight: "bold",
  },
  totalPrice: {
    fontSize: 36,
    fontWeight: "bold",
  },
}));
