import { Box, Typography } from "@material-ui/core";
import { WarningIcon, colors } from "@chhjpackages/components";
import { memo } from "react";

import { ConnectionIcon } from "shared/assets";

import { ChangesQueueProps } from "./types";
import { useStyles } from "./styles";

export const ChangesQueue = memo(
  ({ title, body, isDashboard }: ChangesQueueProps) => {
    const styles = useStyles();

    return (
      <Box className={styles.mainBox}>
        {isDashboard ? (
          <WarningIcon
            className={styles.warningIcon}
            color={colors.functionals.alert}
          />
        ) : (
          <ConnectionIcon
            className={styles.connectionIcon}
            color={colors.functionals.alert}
          />
        )}

        <Box>
          <Typography style={{ color: "#E9554E" }} variant={"h5"}>
            {title}
          </Typography>
          <Typography className={styles.bodyText} variant={"body2"}>
            {body}
          </Typography>
        </Box>
      </Box>
    );
  },
);
