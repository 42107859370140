import { createEffect, createStore } from "effector/compat";

import { getEstimateStatuses } from "../api";
import { StatusesStore } from "../types";

export const getEstimateStatusesFx = createEffect(getEstimateStatuses);

export const $estimateStatusesStore = createStore<StatusesStore>({
  statuses: [],
  error: null,
  loading: true,
});

$estimateStatusesStore
  .on(getEstimateStatusesFx.doneData, (store, response) => ({
    ...store,
    statuses: response.data.statuses,
  }))
  .on(getEstimateStatusesFx.failData, (store, error) => ({
    ...store,
    error,
  }))
  .on(getEstimateStatusesFx.pending, (store, loading) => ({
    ...store,
    loading,
  }));
