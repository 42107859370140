import { memo, useCallback, useEffect, useMemo } from "react";
import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import { useStore } from "effector-react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { colors, useAlert } from "@chhjpackages/components";

import {
  $appointmentStore,
  convertEstimateToJobFx,
  getAppointmentFx,
  rescheduleAppointmentFx,
  updateAppointmentFx,
} from "features/appointment";
import { ConvertToJobForm, ConvertToJobFormValues } from "features/appointment";
import { useSideNavDispatch } from "features/sidenav";
import { $auth } from "features/auth";
import { $availabilities } from "features/availabilities";
import { apiDateTimeFormat, openInNewTab, routePaths } from "shared/utils";
import { BackTitle } from "shared/ui";
import { ConvertToJobFormSkeleton } from "features/appointment/features/convert-to-job-form/skeleton";

export const EstimateDetail = memo(() => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: true,
  });

  const { appointmentId } = useParams<"appointmentId">();
  const navigate = useNavigate();
  const { locationId } = useStore($auth);
  const { appointment, loading: appointmentLoading } =
    useStore($appointmentStore);
  const { availabilities, loading: availabilitiesLoading } =
    useStore($availabilities);
  const { setPageName, setShowGoBackButton, setGoToBackUrl } =
    useSideNavDispatch();
  const { showAlert } = useAlert();

  const goToBackUrl = useMemo(
    () => routePaths.jobDetails(Number(appointmentId)),
    [appointmentId],
  );

  const handleGoBack = useCallback(
    () => navigate(goToBackUrl),
    [goToBackUrl, navigate],
  );

  const handleSubmitConvertToJob = useCallback(
    async (data: ConvertToJobFormValues) => {
      if (!appointment || !data.startDate || !data.endDate || !data.truckId) {
        return;
      }

      try {
        const convertResponse = await convertEstimateToJobFx({
          locationId: appointment.location.id,
          appointmentId: appointment.id,
        });

        const convertedAppointment = convertResponse.data.appointments[0];

        await rescheduleAppointmentFx({
          locationId: convertedAppointment.location.id,
          appointmentId: convertedAppointment.id,
          payload: {
            startDate: moment(data.startDate).format(apiDateTimeFormat),
            endDate: moment(data.endDate).format(apiDateTimeFormat),
            resource: {
              id: data.truckId,
            },
          },
        });

        await updateAppointmentFx({
          locationId: appointment.location.id,
          appointmentId: appointment.id,
        });

        openInNewTab(routePaths.jobDetails(convertedAppointment.id));

        showAlert("Success! Estimate has been converted to Job.", {
          variant: "success",
        });

        navigate(routePaths.jobDetails(appointment.id));
      } catch {
        showAlert("Error! Failed to convert Estimate to Job.", {
          variant: "error",
        });
      }
    },
    [appointment, showAlert, navigate],
  );

  useEffect(() => {
    setPageName("Convert to job");
    setShowGoBackButton();
    setGoToBackUrl(goToBackUrl);

    return () => {
      setPageName("");
      setShowGoBackButton();
      setGoToBackUrl("");
    };
  }, [goToBackUrl, setGoToBackUrl, setPageName, setShowGoBackButton]);

  useEffect(() => {
    if (appointment?.id !== Number(appointmentId) && locationId)
      getAppointmentFx({
        locationId: locationId,
        appointmentId: Number(appointmentId),
      });
  }, [appointment?.id, locationId, appointmentId]);

  return (
    <Box>
      {!isMobile && <BackTitle title="Convert to job" onBack={handleGoBack} />}

      <Box m={2} mt={4} p={2} borderRadius={4} bgcolor={colors.white}>
        {appointment ? (
          <ConvertToJobForm
            appointment={appointment}
            availabilities={availabilities}
            loading={appointmentLoading || availabilitiesLoading}
            onSubmit={handleSubmitConvertToJob}
          />
        ) : (
          <ConvertToJobFormSkeleton />
        )}
      </Box>
    </Box>
  );
});
