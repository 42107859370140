import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import { Button, colors, Input, Paper } from "@chhjpackages/components";
import { memo, ReactNode, useCallback } from "react";

import { GridViewIcon, ListViewIcon, SearchIcon } from "shared/assets";

import { useStyles } from "./styles";
import { FilterItem, FiltersBarProps } from "./model";
import { FiltersSkeleton } from "./ui";

export const FiltersDesktop = memo(
  <T,>({
    isLoading,
    isSearch,
    isGridView,
    filters,
    countFilters,
    handleSetView,
    renderFilterItem,
    resetFilters,
    onSearch,
  }: Omit<FiltersBarProps<T>, "onFilter"> & {
    countFilters: number;
    renderFilterItem: (filter: FilterItem<T>) => ReactNode;
    resetFilters: () => void;
  }) => {
    const styles = useStyles();

    const setSearch = useCallback(
      (event) => {
        onSearch(event.target.value);
      },
      [onSearch],
    );

    if (isLoading) {
      return <FiltersSkeleton />;
    }

    return (
      <Paper
        variant="elevation"
        elevation={20}
        style={{
          display: "flex",
          flexDirection: "column",
        }}
        roundingValue={4}
      >
        <div
          style={{
            padding: 16,
            marginBottom: 48,
            maxWidth: 300,
            width: "100%",
          }}
        >
          <Grid container direction="column" spacing={2}>
            <Grid item xs={12}>
              <Grid container alignItems="center" justify="space-between">
                <Grid item>
                  <Typography variant="h4">Filters</Typography>
                </Grid>
                <Grid item>
                  <div style={{ margin: -12 }}>
                    <IconButton onClick={handleSetView}>
                      {isGridView ? <GridViewIcon /> : <ListViewIcon />}
                    </IconButton>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            {countFilters > 0 && (
              <Grid item>
                <div
                  style={{
                    display: "flex",
                    justifySelf: "flex-end",
                    justifyContent: "flex-end",
                    margin: "0px -8px",
                  }}
                >
                  <Button
                    buttonType="text"
                    color="primary"
                    onClick={resetFilters}
                    classes={{
                      root: styles.button,
                    }}
                  >
                    Reset filters
                  </Button>
                </div>
              </Grid>
            )}
            {isSearch && (
              <Grid item>
                <Box
                  flex={{ xs: "initial", md: 1 }}
                  width={{ xs: "100%", md: "initial" }}
                >
                  <Input
                    onChange={setSearch}
                    className={styles.input}
                    variant="standard"
                    placeholder="Search jobs & estimates"
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon fontSize={16} color={colors.grey120} />
                      </InputAdornment>
                    }
                    fullWidth
                  />
                </Box>
              </Grid>
            )}
            {filters?.map((filter) => (
              <Grid item key={filter.id}>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <div style={{ color: colors.grey140 }}>
                      <Typography variant="h6" color="inherit">
                        {filter.name}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item>{renderFilterItem(filter)}</Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </div>
      </Paper>
    );
  },
);
