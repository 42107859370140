import { colors, useAlert } from "@chhjpackages/components";
import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useStore } from "effector-react/compat";

import { AddTermsForm, AddTermsFormValues } from "features/appointment";
import { routePaths } from "shared/utils";
import { generateInspectionFx } from "features/inspection";
import { $auth } from "features/auth";
import { useSideNavDispatch } from "features/sidenav";
import { BackTitle } from "shared/ui";
import { $appSettings } from "features/settings";

export const JobInspectionTerms = memo(() => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), { noSsr: true });

  const navigate = useNavigate();
  const { appointmentId } = useParams();
  const [searchParams] = useSearchParams();

  const { showAlert } = useAlert();
  const { setPageName, setShowGoBackButton, setGoToBackUrl, setElevation } =
    useSideNavDispatch();

  const { locationId } = useStore($auth);
  const { appSettings } = useStore($appSettings);

  const type = useMemo(
    () => searchParams.get("type") as "pre" | "post" | null,
    [searchParams],
  );

  const signatureText = useMemo(() => {
    if (type === "pre") {
      return appSettings?.preInspection.stepText ?? "";
    } else if (type === "post") {
      return appSettings?.postInspection.stepText ?? "";
    }

    return "";
  }, [
    appSettings?.preInspection.stepText,
    appSettings?.postInspection.stepText,
    type,
  ]);

  const backPath = useMemo(
    () => routePaths.jobDetails(Number(appointmentId)),
    [appointmentId],
  );

  const onSubmitAddTerms = useCallback(
    async (data: AddTermsFormValues) => {
      if (!locationId || !Number(appointmentId) || !type) {
        showAlert("Error! Something went wrong. Try again later.", {
          variant: "error",
        });
        return;
      }

      try {
        await generateInspectionFx({
          locationId: locationId,
          appointmentId: Number(appointmentId),
          payload: {
            signatureType: type,
            additionalTerms: data.terms,
          },
        });

        navigate({
          pathname: routePaths.jobInspectionSignature(Number(appointmentId)),
          search: `?type=${type}`,
        });
      } catch {
        showAlert("Error! Failed to generate work order. Try again later.", {
          variant: "error",
        });
      }
    },
    [appointmentId, locationId, type, navigate, showAlert],
  );

  useEffect(() => {
    setPageName(`${type === "pre" ? "Pre" : "Post"}-job inspection`);
    setShowGoBackButton();
    setGoToBackUrl(backPath);
    setElevation(16);

    return () => {
      setPageName("");
      setShowGoBackButton();
      setGoToBackUrl("");
      setElevation(0);
    };
  }, [type, backPath]);

  return (
    <Box flex={1} bgcolor={colors.white}>
      {!isMobile && (
        <BackTitle
          title={`${type === "pre" ? "Pre" : "Post"}-job inspection`}
          elevation={16}
          onBack={() => navigate(backPath)}
        />
      )}
      <Box mt={6.43} p={2}>
        <AddTermsForm
          signatureText={signatureText}
          onSubmit={onSubmitAddTerms}
        />
      </Box>
    </Box>
  );
});
