import { Paper, useDialogDispatch, colors } from "@chhjpackages/components";
import { memo, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, useMediaQuery, useTheme } from "@material-ui/core";

import { routePaths } from "shared/utils";
import { TermsAndConditions as TermsAndConditionsContent } from "features/auth";
import { BackButton, DialogContentWrapper } from "shared/ui";

export const TermsAndConditions = memo(() => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), { noSsr: true });

  const setDialog = useDialogDispatch();
  const navigate = useNavigate();

  const handleCloseTermsAndCondition = useCallback(
    () => navigate(routePaths.login()),
    [navigate],
  );

  useEffect(() => {
    if (!isMobile) {
      setDialog({
        open: true,
        variant: "basic",
        title: "Terms & conditions",
        disableBackdropClick: true,
        dialogContent: (
          <DialogContentWrapper maxWidth={488}>
            <TermsAndConditionsContent onClose={handleCloseTermsAndCondition} />
          </DialogContentWrapper>
        ),

        onClose: handleCloseTermsAndCondition,
      });
    }

    return () => {
      if (!isMobile) {
        setDialog({ open: false });
      }
    };
  }, [isMobile, handleCloseTermsAndCondition, setDialog]);

  if (!isMobile) {
    return null;
  }

  return (
    <>
      <Box m={2}>
        <BackButton url={routePaths.login()} />
      </Box>

      <Box
        flex={1}
        p={2}
        pt={0}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box width="100%" maxWidth={488}>
          <Paper elevation={16}>
            <Box bgcolor={colors.white} p={3}>
              <TermsAndConditionsContent
                onClose={handleCloseTermsAndCondition}
              />
            </Box>
          </Paper>
        </Box>
      </Box>
    </>
  );
});
