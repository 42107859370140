import { useCallback, useState } from "react";
import { useAlert } from "@chhjpackages/components";

import { Avatar } from "shared/types";
import { fileToBase64, resizeImage } from "shared/utils";
import { PickAvatar } from "shared/ui";
import { updateUserAvatar } from "../api";

export const useUserAvatar = ({
  image,
  locationId,
  userId,
  onChange,
}: {
  image?: Avatar;
  locationId: number;
  userId: number;
  onChange?: (blobImage: Blob) => void;
}) => {
  const { showAlert } = useAlert();
  const [blobImage, setBlobImage] = useState<Blob | null>(null);

  const onCrop = useCallback(
    async (croppedImage: Blob) => {
      const resizedCroppedImage = await resizeImage(croppedImage, 512, 512);

      if (resizedCroppedImage) {
        setBlobImage(resizedCroppedImage);

        if (onChange) {
          onChange(resizedCroppedImage);
        }
      }
    },
    [onChange],
  );

  const renderAvatar = useCallback(
    (isMobile?: boolean) =>
      image ? (
        <PickAvatar
          avatarUrl={image.url}
          onCropAvatarComplete={onCrop}
          isMobile={isMobile}
        />
      ) : (
        <>Please provide image</>
      ),
    [image, onCrop],
  );

  const saveAvatar = useCallback(async () => {
    try {
      if (blobImage) {
        const response = await updateUserAvatar({
          locationId,
          userId,
          payload: {
            content: await fileToBase64(blobImage),
          },
        });

        const { messages, images } = response.data;

        if (messages?.[0].subject) {
          showAlert(messages[0].subject, {
            variant: "success",
          });
        }

        setBlobImage(null);

        return (
          images?.[0] && {
            approved: images[0].approved,
            height: images[0].height,
            id: images[0].id,
            mimeType: images[0].mimeType,
            url: images[0].url,
            width: images[0].width,
          }
        );
      }
    } catch (error) {
      showAlert("Error! Something went wrong. Try again later.", {
        variant: "error",
      });
    }
  }, [blobImage, locationId, userId]);

  return {
    renderAvatar,
    onCrop,
    saveAvatar,
  };
};
