import { memo } from "react";

import { TotalTimeRowSkeleton } from "./ui";
import { useStyles } from "./assets";

export const TotalTimeSkeleton = memo(() => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <TotalTimeRowSkeleton title="Time travelled:" />
      <TotalTimeRowSkeleton title="Billable time travelled:" />
      <TotalTimeRowSkeleton title="Time Worked:" />
      <TotalTimeRowSkeleton title="Billable time worked:" />
    </div>
  );
});
