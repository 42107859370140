import { fetcher } from "shared/libs";

import { RequestProp } from "./types";

export const repeatOfflineRequest = async (request: RequestProp) => {
  if (!window.navigator.onLine) {
    return Promise.reject("error");
  }

  switch (request.config.method) {
    case "delete":
      await fetcher.delete(`${request.config.baseURL}${request.config.url}`);

      break;
    case "put":
      await fetcher.put(
        `${request.config.baseURL}${request.config.url}`,
        JSON.parse(request.config.data),
      );

      break;
    default:
      await fetcher.post(
        `${request.config.baseURL}${request.config.url}`,
        JSON.parse(request.config.data),
      );

      break;
  }
};
