import { createEffect, createStore } from "effector/compat";

import { PreExistingDamage } from "../types";
import {
  generatePreExistingDamage,
  getCurrentPreExistingDamage,
  updatePreExistingDamage,
} from "../api";

export const $preExistingDamage = createStore<{
  preExistingDamage: PreExistingDamage | null;
  loading: boolean;
}>({ preExistingDamage: null, loading: true });

export const getCurrentPreExistingDamageFx = createEffect(
  getCurrentPreExistingDamage,
);
$preExistingDamage
  .on(getCurrentPreExistingDamageFx.doneData, (store, response) => ({
    ...store,
    preExistingDamage: response.data.preExistingDamages[0],
  }))
  .on(getCurrentPreExistingDamageFx.fail, (store) => ({
    ...store,
    preExistingDamage: null,
  }))
  .on(getCurrentPreExistingDamageFx.pending, (store, loading) => ({
    ...store,
    loading,
  }));

export const generatePreExistingDamageFx = createEffect(
  generatePreExistingDamage,
);
$preExistingDamage
  .on(generatePreExistingDamageFx.doneData, (store, response) => ({
    ...store,
    preExistingDamage: response.data.preExistingDamages[0],
  }))
  .on(generatePreExistingDamageFx.fail, (store) => ({
    ...store,
    preExistingDamage: null,
  }))
  .on(generatePreExistingDamageFx.pending, (store, loading) => ({
    ...store,
    loading,
  }));

export const updatePreExistingDamageFx = createEffect(updatePreExistingDamage);
$preExistingDamage.on(
  updatePreExistingDamageFx.doneData,
  (store, response) => ({
    ...store,
    preExistingDamage: response.data.preExistingDamages[0],
  }),
);
