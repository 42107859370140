import { Box, Typography } from "@material-ui/core";
import { TimePicker } from "@chhjpackages/components";
import { Skeleton } from "@material-ui/lab";

export const ConvertToJobFormSkeleton = () => {
  return (
    <Box>
      <Typography variant="body1">
        Select a date to check our availability
      </Typography>
      <Box mt={2}>
        <Skeleton variant="rect" height={55} width="100%" />
      </Box>
      <Box mt={2}>
        <Skeleton variant="rect" height={55} width="100%" />
      </Box>
      <Box mt={4}>
        <Typography variant="body1">
          Select the arrival window that works best for you for our HUNKS to
          arrive
        </Typography>
      </Box>
      <Box mt={3}>
        <TimePicker
          period="Morning"
          schedule={[]}
          onTimeSelected={() => {}}
          onChangePeriod={() => {}}
          isLoading={true}
        />
      </Box>
      <Box mt={2}>
        <Skeleton variant="rect" height={51} width="100%" />
      </Box>
    </Box>
  );
};
