import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import { colors } from "@chhjpackages/components";
import { memo } from "react";
import { Skeleton } from "@material-ui/lab";

import { ListViewSkeleton } from "shared/ui";

export const AppointmentListViewSkeletonCard = memo(() => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });

  return (
    <div
      style={{
        maxWidth: isMobile ? "100%" : 328,
        minWidth: isMobile ? "100%" : 328,
        flex: 1,
        backgroundColor: colors.basic.white,
        border: `solid 1px ${colors.grey20}`,
        padding: 16,
        borderRadius: 8,
        marginRight: 16,
      }}
    >
      <Grid container justify="center" direction="column" spacing={2}>
        <Grid item>
          <Grid container justify="space-between">
            <Grid item>
              <Skeleton variant="text" width={30} />
            </Grid>
            <Grid item>
              <Skeleton variant="text" width={50} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Skeleton variant="rect" width="100%" />
        </Grid>
        <Grid item>
          <ListViewSkeleton />
        </Grid>
        <Grid item>
          <ListViewSkeleton />
        </Grid>
        <Grid item>
          <ListViewSkeleton />
        </Grid>
        <Grid item>
          <ListViewSkeleton />
        </Grid>
        <Grid item>
          <ListViewSkeleton />
        </Grid>
      </Grid>
    </div>
  );
});
