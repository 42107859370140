export const binderListTitle = [
  {
    category: "",
    titles: [
      { title: "Intro", id: 0 },
      { title: "H.U.N.K.S", id: 1 },
      { title: "Where have you seen us?", id: 2 },
      { title: "We are licensed & ensured", id: 3 },
      { title: "Doing our part to move...", id: 4 },
      { title: "We've gone green", id: 5 },
      { title: "A second life for your items", id: 6 },
      { title: "Before and After", id: 7 },
      { title: "We love to hear from you!", id: 8 },
      // { title: "How do we determine pricing?", id: 9 },
      { title: "10% bigger than our competition", id: 9 },
    ],
  },
];
