import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  signatureCanvasContainer: {
    marginTop: 8,
  },
  actionButtonsContainer: {
    marginTop: 24,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 8,
  },
  submitButton: {
    width: 136,
  },
}));
