import { colors } from "@chhjpackages/components";
import { SvgIcon } from "@material-ui/core";

export const AttachmentIcon = ({
  className,
  color = colors.grey120,
  fontSize = 24,
}: {
  className?: string;
  color?: string;
  fontSize?: number;
}) => (
  <SvgIcon
    className={className}
    style={{ fontSize }}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M11.4998 3.99984C11.2237 3.99984 10.9998 4.22369 10.9998 4.49984V11.6665C10.9998 13.1398 9.8065 14.3332 8.33317 14.3332C6.85984 14.3332 5.6665 13.1398 5.6665 11.6665V3.33317C5.6665 2.41317 6.41317 1.6665 7.33317 1.6665C8.25317 1.6665 8.99984 2.41317 8.99984 3.33317V10.3332C8.99984 10.6998 8.69984 10.9998 8.33317 10.9998C7.9665 10.9998 7.6665 10.6998 7.6665 10.3332V4.49984C7.6665 4.2237 7.44265 3.99984 7.1665 3.99984C6.89036 3.99984 6.6665 4.22369 6.6665 4.49984V10.3332C6.6665 11.2532 7.41317 11.9998 8.33317 11.9998C9.25317 11.9998 9.99984 11.2532 9.99984 10.3332V3.33317C9.99984 1.85984 8.8065 0.666504 7.33317 0.666504C5.85984 0.666504 4.6665 1.85984 4.6665 3.33317V11.6665C4.6665 13.6932 6.3065 15.3332 8.33317 15.3332C10.3598 15.3332 11.9998 13.6932 11.9998 11.6665V4.49984C11.9998 4.22369 11.776 3.99984 11.4998 3.99984Z"
      fill={color}
    />
  </SvgIcon>
);
