import { makeStyles, withStyles, createStyles, Theme } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import { colors } from "@chhjpackages/components";

export const useStyles = makeStyles(() => ({
  mainTable: {
    fontFamily: "arial, sans-serif",
    borderCollapse: "collapse",
    width: "100%",
    height: "5rem",
  },
  tdValue: {
    textAlign: "right",
    borderBottom: "1px solid #dddddd",
    width: "25%",
    padding: "8px",
  },
  tdName: {
    borderBottom: "1px solid #dddddd",
    width: "25%",
    textAlign: "left",
    padding: "8px",
  },
  tdAvatar: {
    borderBottom: "1px solid #dddddd",
    width: "25%",
    textAlign: "left",
    padding: "8px",
  },
  thStyle: {
    border: "1px solid #dddddd",
    textAlign: "left",
    padding: "8px",
  },
  badgeStyle1: {
    backgroundColor: `${colors.complementary.yellow.shade01}`,
    border: `2px solid ${colors.complementary.yellow.shade01} !important`,
  },
  badgeStyle2: {
    backgroundColor: `${colors.complementary.blue.shade03}`,
    border: `2px solid ${colors.complementary.blue.shade03} !important`,
  },
  badgeStyle3: {
    backgroundColor: `${colors.primary.main}`,
    border: `2px solid ${colors.primary.main} !important`,
  },
  mainBadge1: {
    border: `4px solid ${colors.complementary.yellow.shade01}`,
    borderRadius: "100%",
  },
  mainBadge2: {
    border: `4px solid ${colors.complementary.blue.shade03}`,
    borderRadius: "100%",
  },
  mainBadge3: {
    border: `4px solid ${colors.primary.main}`,
    borderRadius: "100%",
  },
  numberColumn: {
    marginLeft: "1.2rem",
    color: colors.grey120,
  },
}));

export const SmallAvatar = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 22,
      height: 22,
      border: `2px solid ${theme.palette.background.paper}`,
    },
  }),
)(Avatar);
