import { useMediaQuery, useTheme } from "@material-ui/core";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useAlert } from "@chhjpackages/components";
import { useStore } from "effector-react/compat";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { $inspections, generateInspectionFx } from "features/inspection";
import { addAttachmentsFx, getAttachmentsFx } from "features/attachments";
import { useCompletedActions } from "features/completed-actions";
import { $auth } from "features/auth";
import { BackTitle, SignatureArea, PdfViewer, ActionsFooter } from "shared/ui";
import { fileToBase64, routePaths } from "shared/utils";
import { useSideNavDispatch } from "features/sidenav";
import { CompletedActionsJobEnum } from "shared/types";
import { useSignDialog, useSignPdf } from "features/signature";

import { useSignatureStyles } from "./assets";

const signatureFieldOffset = {
  x: 0,
  y: 13,
};

export const JobInspectionSignature = memo(() => {
  const styles = useSignatureStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), { noSsr: true });

  const { appointmentId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { locationId } = useStore($auth);
  const {
    preInspection,
    postInspection,
    preInspectionAppointmentId,
    postInspectionAppointmentId,
  } = useStore($inspections);

  const { setPageName, setShowGoBackButton, setGoToBackUrl, setElevation } =
    useSideNavDispatch();

  const [isInspectionGetting, setIsInspectionGetting] = useState(true);
  const [isPdfRendering, setIsPdfRendering] = useState(true);
  const [isSavingDocument, setIsSavingDocument] = useState(false);
  const [signatureMode, setSignatureMode] = useState<"sign" | "done">("sign");

  const { showAlert } = useAlert();
  const { addCompletedAction } = useCompletedActions();

  const {
    pdfRef,
    pdfWidth,
    blobPdf,
    pdfDoc,
    signs,
    countPages,
    pageNumberWithSignature,
    signatureFieldCoordinates,
    getPdfDoc,
    addSign,
    savePdf,
    renderSign,
  } = useSignPdf({
    width: 800,
    currentSignaturePdfField: "signature_1",
    signatureFieldOffset,
    isLoading: isPdfRendering,
  });

  const type = useMemo(
    () => searchParams.get("type") as "pre" | "post" | null,
    [searchParams],
  );

  const inspection = useMemo(
    () =>
      type === "pre"
        ? preInspectionAppointmentId !== Number(appointmentId)
          ? null
          : preInspection
        : postInspectionAppointmentId !== Number(appointmentId)
        ? null
        : postInspection,
    [
      type,
      preInspection,
      postInspection,
      appointmentId,
      postInspectionAppointmentId,
      preInspectionAppointmentId,
    ],
  );

  const backPath = useMemo(
    () =>
      `${routePaths.jobInspectionTerms(Number(appointmentId))}?type=${type}`,
    [appointmentId, type],
  );

  const jobPath = useMemo(
    () => routePaths.jobDetails(Number(appointmentId)),
    [appointmentId],
  );

  const handleSubmitSignDialog = useCallback(
    (signBlob?: Blob) => {
      if (!signBlob || !pageNumberWithSignature) {
        return;
      }

      addSign({ blob: signBlob, imageHeight: 21 }, pageNumberWithSignature, {
        ...signatureFieldCoordinates,
        width: {
          type: "percent",
          value: 40,
        },
      });

      setSignatureMode("done");
    },
    [pageNumberWithSignature, signatureFieldCoordinates, addSign],
  );

  const { handleSignDialogOpen } = useSignDialog({
    title: "Client signature",
    onSubmit: handleSubmitSignDialog,
  });

  const saveInspection = useCallback(async () => {
    if (!locationId || !Number(appointmentId) || !type) {
      showAlert("Error! Something went wrong. Try again later.", {
        variant: "error",
      });
      return;
    }

    setIsSavingDocument(true);

    const newBlobPdf = await savePdf();

    if (!newBlobPdf) {
      throw new Error();
    }

    try {
      await Promise.all([
        await addAttachmentsFx({
          locationId: locationId,
          appointmentId: Number(appointmentId),
          description: `${
            type === "pre" ? "Pre" : "Post"
          }-Job Inspection - ${Number(
            appointmentId,
          )} - Electronically Signed Document`,
          extension: "pdf",
          content: await fileToBase64(newBlobPdf),
          typeId: 2,
          emailClient: true,
        }).then(() =>
          getAttachmentsFx({
            locationId: locationId,
            appointmentId: Number(appointmentId),
          }),
        ),
        await addCompletedAction(
          Number(appointmentId),
          locationId,
          type === "pre"
            ? CompletedActionsJobEnum.PreJobInspection
            : CompletedActionsJobEnum.PostJobInspection,
        ),
      ]);

      showAlert("Success! Document has been uploaded.", { variant: "success" });

      navigate(jobPath);
    } catch {
      showAlert("Error! Failed to upload document.", { variant: "error" });
    }

    setIsSavingDocument(false);
  }, [
    appointmentId,
    locationId,
    type,
    jobPath,
    navigate,
    showAlert,
    savePdf,
    addCompletedAction,
  ]);

  useEffect(() => {
    if (!isInspectionGetting && inspection?.url && !pdfDoc) {
      getPdfDoc(inspection.url);
    }
  }, [isInspectionGetting, inspection?.url, pdfDoc, getPdfDoc]);

  useEffect(() => {
    const getInspection = async (
      locId: number,
      appId: number,
      signType: "pre" | "post",
    ) => {
      try {
        await generateInspectionFx({
          locationId: locId,
          appointmentId: appId,
          payload: {
            signatureType: signType,
          },
        });
      } catch {
        showAlert("Error! Failed to get document. Try again later.", {
          variant: "error",
        });
      }

      setIsInspectionGetting(false);
    };

    if (
      !inspection &&
      locationId &&
      Number(appointmentId) &&
      type &&
      isInspectionGetting
    ) {
      getInspection(locationId, Number(appointmentId), type);
    } else if (inspection) {
      setIsInspectionGetting(false);
    }
  }, [inspection, locationId, appointmentId, type, isInspectionGetting]);

  useEffect(() => {
    setPageName(`${type === "pre" ? "Pre" : "Post"}-job inspection`);
    setShowGoBackButton();
    setGoToBackUrl(backPath);
    setElevation(16);

    return () => {
      setPageName("");
      setShowGoBackButton();
      setGoToBackUrl("");
      setElevation(0);
    };
  }, [
    type,
    backPath,
    setPageName,
    setShowGoBackButton,
    setGoToBackUrl,
    setElevation,
  ]);

  return (
    <div className={styles.root}>
      {!isMobile && (
        <BackTitle
          title={`${type === "pre" ? "Pre" : "Post"}-job inspection`}
          elevation={16}
          onBack={() => navigate(backPath)}
        />
      )}

      <div className={styles.pdfViewerContainer}>
        <div className={styles.pdfViewerWrapper}>
          <PdfViewer
            ref={pdfRef}
            width={pdfWidth}
            pagesCount={countPages}
            loading={isPdfRendering}
            documentProps={{
              file: blobPdf,
            }}
            pageProps={{
              onRenderSuccess: () => {
                setIsPdfRendering(false);
              },
            }}
            pageContent={(pageNumber) => {
              const shouldRenderSignatureArea =
                signatureMode !== "done" &&
                pageNumber === pageNumberWithSignature &&
                signatureFieldCoordinates.x !== 0 &&
                signatureFieldCoordinates.y !== 0;

              const signatureAreaElement = shouldRenderSignatureArea ? (
                <SignatureArea
                  classes={{ root: styles.signatureArea }}
                  style={{
                    left: `${signatureFieldCoordinates.x}px`,
                    top: `${signatureFieldCoordinates.y}px`,
                  }}
                  onClick={handleSignDialogOpen}
                />
              ) : (
                <></>
              );

              const signElements = signs.map((sign) =>
                sign.pageNumber === pageNumber ? renderSign(sign) : <></>,
              );

              return (
                <>
                  {signatureAreaElement}
                  {signElements}
                </>
              );
            }}
          />
        </div>
      </div>

      <ActionsFooter
        show={!isPdfRendering}
        actions={[
          {
            label: "Sign",
            hide: signatureMode !== "sign",
            buttonType: "filled",
            disabled: isPdfRendering,
            onClick: handleSignDialogOpen,
          },
          {
            label: "Save",
            hide: signatureMode !== "done",
            buttonType: "filled",
            isLoading: isSavingDocument,
            onClick: saveInspection,
          },
        ]}
      />
    </div>
  );
});
