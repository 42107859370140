import MobileDetect from "mobile-detect";

export enum OSEnum {
  android = "android",
  ios = "ios",
  other = "other",
}

export const getOS = (): "android" | "ios" | "other" => {
  const os = new MobileDetect(window.navigator.userAgent).os();
  const isIPad =
    /Macintosh/i.test(navigator.userAgent) &&
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 1;
  const isAndroidTablet =
    /Linux/i.test(navigator.userAgent) &&
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 1;

  if (os === "AndroidOS" || isAndroidTablet) {
    return "android";
  } else if (os === "iOS" || os === "iPadOS" || isIPad) {
    return "ios";
  } else {
    return "other";
  }
};
