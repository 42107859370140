import { Box, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { Button, colors } from "@chhjpackages/components";
import { Link } from "react-router-dom";
import { memo } from "react";

import { routePaths } from "shared/utils";

import { HelpBox, useStyles } from "./styles";
import { HelpDialogProps } from "./types";

export const HelpDialog = memo(({ onClose }: HelpDialogProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), { noSsr: true });

  const styles = useStyles();

  return (
    <div>
      {isMobile && (
        <Box mb={2}>
          <Typography variant="h3">Help</Typography>
        </Box>
      )}

      <Box color={colors.grey160}>
        <Typography variant="h4" color="inherit">
          Forgotten username
        </Typography>
        <HelpBox>
          Your username is typically your first and last name connected by a
          period and in some cases it may have a number at the end. For example:
          john.hancock123
        </HelpBox>
      </Box>
      <Box color={colors.grey160}>
        <Typography variant="h4" color="inherit">
          Forgotten password
        </Typography>
        <HelpBox>
          In most cases, your HunkWare password is the same as your chhj.com
          email account (Office365) password.{" "}
          <Link to={routePaths.resetPasswordLink()} className={styles.link}>
            Click here to reset your password
          </Link>
          .
        </HelpBox>
      </Box>
      <Box color={colors.grey160}>
        <Typography variant="h4" color="inherit">
          Password expired
        </Typography>
        <HelpBox>
          In our system, passwords expire after 990 days. We will e-mail the
          week before your password expires. If your password has already
          expired, you will need to reset your password using the link above.
        </HelpBox>
      </Box>
      <Box color={colors.grey160}>
        <Typography variant="h4" color="inherit">
          Unable to login
        </Typography>
        <HelpBox>
          If you have already tried resetting your password, are unable to
          remember your username or are otherwise unable to log in to your
          account, the please contact{" "}
          <a href="mailto:hunkhelp@chhj.com" className={styles.link}>
            hunkhelp@chhj.com
          </a>{" "}
          and they will assist you.
        </HelpBox>
      </Box>
      {isMobile && (
        <Button
          buttonType="twoTone"
          color="primary"
          fullWidth
          size="large"
          onClick={onClose}
        >
          Close
        </Button>
      )}
      {!isMobile && (
        <Box display="flex" justifyContent="flex-end">
          <Button buttonType="text" color="primary" onClick={onClose}>
            Close
          </Button>
        </Box>
      )}
    </div>
  );
});
