import { Theme, makeStyles } from "@material-ui/core";
import { colors } from "@chhjpackages/components";

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.grey10,
    minHeight: "100%",

    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },

  container: {
    minHeight: "100%",
    display: "flex",
    alignItems: "center",
    maxWidth: 1200,
    width: "100%",
    position: "relative",

    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      minHeight: "auto",
      padding: 24,
    },
  },

  circle: {
    fill: "var(--basic-white, #FFF)",
    filter: "drop-shadow(0px 0px 24px rgba(134, 145, 166, 0.32))",
    backgroundColor: colors.basic.white,
    width: 174,
    height: 174,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    borderRadius: 135,
    position: "absolute",
    top: 40,
    left: 32,

    [theme.breakpoints.down("xs")]: {
      top: 16,
      left: 16,
    },
  },

  leftSide: {
    padding: 32,
    flex: 3,

    "@media (orientation: portrait)": {
      [theme.breakpoints.down("lg")]: {
        flex: 1,
      },
    },

    [theme.breakpoints.down("xs")]: {
      marginTop: 100,
    },
  },

  mainText: {
    display: "flex",
    alignItems: "center",

    "@media (orientation: portrait)": {
      [theme.breakpoints.down("lg")]: {
        flexDirection: "column",
      },
    },
  },

  mainTextIcon: {
    display: "flex",
    alignItems: "center",
    marginRight: 16,

    "@media (orientation: portrait)": {
      [theme.breakpoints.down("lg")]: {
        marginRight: 0,
      },
    },
  },

  rightSide: {
    padding: 64,
    flex: 2,

    "@media (orientation: portrait)": {
      [theme.breakpoints.down("lg")]: {
        flex: 1,
      },
    },

    [theme.breakpoints.down("xs")]: {
      padding: 32,
    },
  },

  cardWrapper: {
    margin: "16px 0",
  },

  card: {
    padding: "16px 24px",
    color: colors.grey120,
  },
}));
