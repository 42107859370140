import { fetcher } from "shared/libs";
import { Damages } from "../types";

export const getDamages = ({ locationId, appointmentId }: Damages.GetAll) =>
  fetcher.get<Damages.Response>(
    `/locations/${locationId}/appointments/${appointmentId}/damages`,
  );

export const addDamage = ({
  locationId,
  appointmentId,
  payload,
}: Damages.Create) =>
  fetcher.post<Damages.Response>(
    `/locations/${locationId}/appointments/${appointmentId}/damages`,
    payload,
  );

export const updateDamage = ({
  locationId,
  appointmentId,
  damageId,
  payload,
}: Damages.Update) =>
  fetcher.put<Damages.Response>(
    `/locations/${locationId}/appointments/${appointmentId}/damages/${damageId}`,
    payload,
  );

export const deleteDamage = ({
  locationId,
  appointmentId,
  damageId,
}: Damages.Delete) =>
  fetcher.delete<Damages.Response>(
    `/locations/${locationId}/appointments/${appointmentId}/damages/${damageId}`,
  );
