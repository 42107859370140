import { Theme, makeStyles } from "@material-ui/core";
import { colors } from "@chhjpackages/components";

import Page2 from "../../assets/images/page-2.png";

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    height: "100%",

    "@media (orientation: portrait)": {
      flexDirection: "column",
    },
  },

  column: {
    flex: 1,

    "&:nth-of-type(1)": {
      backgroundImage: `url(${Page2})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
    },

    "&:nth-of-type(2)": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: 32,
    },
  },

  description: {
    marginTop: 32,
    color: colors.primary.light,

    [theme.breakpoints.down("xs")]: {
      marginTop: 16,
    },

    "& span": {
      color: colors.primary.main,
    },
  },

  letterRow: {
    [theme.breakpoints.down("xs")]: {
      fontSize: 48,
      lineHeight: 1.5,
    },
  },
}));
