import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useStore } from "effector-react/compat";

import {
  $developments,
  ProcessStatusEnum,
  getPausedWorks,
} from "features/developments";
import { $completedActions } from "features/completed-actions";
import { usePermissions } from "features/permissions";
import { $requirements } from "features/requirements";
import { $settings } from "features/settings";
import {
  AppointmentStatus,
  CompletedActionsEstimateEnum,
  CompletedActionsJobEnum,
  StepNamesEnum,
  StepStatusesEnum,
} from "shared/types";
import { $appointmentStore } from "../store";
import { AppointmentTypesEnum, Appointments } from "../types";
import { routePaths } from "shared/utils";
import { getStepTwoLinks } from "features/appointment/widgets/process-work/model";

export const useSteps = ({
  appointmentOuter,
  useDevelopments,
  loading,
  isJobDetails,
}: {
  appointmentOuter?: Appointments;
  useDevelopments?: boolean;
  loading?: boolean;
  isJobDetails?: boolean;
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { administration } = usePermissions();

  const { appointment: appointmentStore, loading: appointmentLoading } =
    useStore($appointmentStore);
  const {
    workStatus,
    travelStatus,
    loading: developmentsLoading,
  } = useStore($developments);
  const { completedActions } = useStore($completedActions);
  const { filteredRequirements } = useStore($requirements);
  const { preJobInspection, postJobInspetion } = useStore($settings);

  const appointment = useMemo(
    () => appointmentOuter ?? appointmentStore,
    [appointmentOuter, appointmentStore],
  );

  const isLoading = useMemo(
    () =>
      !!appointmentOuter
        ? useDevelopments && developmentsLoading
        : loading ?? (appointmentLoading || developmentsLoading),
    [
      appointmentOuter,
      useDevelopments,
      loading,
      appointmentLoading,
      developmentsLoading,
    ],
  );

  const isNasa = useMemo(
    () => !!appointment?.subpartner.id,
    [appointment?.subpartner.id],
  );

  const managingProductsDenied = useMemo(
    () =>
      appointment?.type !== "EST" &&
      appointment?.status.id === AppointmentStatus.Job.Closed &&
      !administration.editProductsAfterRoyalties,
    [
      appointment?.type,
      appointment?.status.id,
      administration.editProductsAfterRoyalties,
    ],
  );

  const workLinks = useMemo(
    () =>
      getStepTwoLinks(
        completedActions,
        filteredRequirements,
        isNasa,
        appointment,
        preJobInspection,
        postJobInspetion,
        managingProductsDenied,
        pathname,
      ),
    [
      appointment,
      filteredRequirements,
      managingProductsDenied,
      completedActions,
      isNasa,
      pathname,
      preJobInspection,
      postJobInspetion,
    ],
  );

  const isShowWorkStep = useMemo(
    () =>
      (!appointmentOuter && appointmentLoading) ||
      appointment?.convertedAppointmentId === 0,
    [appointmentOuter, appointmentLoading, appointment?.convertedAppointmentId],
  );

  const isShowBillingStep = useMemo(
    () =>
      (!appointmentOuter && appointmentLoading) || appointment?.type !== "EST",
    [appointmentOuter, appointmentLoading, appointment?.type],
  );

  const isAddProductsChecked = useMemo(() => {
    return isLoading ? false : !!appointment?.products.length;
  }, [isLoading, appointment]);

  const isAppointmentCompleted = useMemo(
    () =>
      isLoading
        ? false
        : appointment?.type === "EST"
        ? appointment?.estStatus.id === AppointmentStatus.Estimate.Completed
        : appointment?.status.id === AppointmentStatus.Job.Completed,
    [
      isLoading,
      appointment?.type,
      appointment?.status.id,
      appointment?.estStatus.id,
    ],
  );

  const travelStepStatus = useMemo(() => {
    if (isLoading) {
      return StepStatusesEnum.ToBeDone;
    }

    if (appointmentOuter?.id && !useDevelopments) {
      const startTravelActions = appointmentOuter.completedactions.filter(
        (completedaction) =>
          completedaction.type.id ===
          (appointmentOuter.type === AppointmentTypesEnum.EST
            ? CompletedActionsEstimateEnum.StartTravel
            : CompletedActionsJobEnum.StartTravelETA),
      );
      const endTravelActions = appointmentOuter.completedactions.filter(
        (completedaction) =>
          completedaction.type.id ===
          (appointmentOuter.type === AppointmentTypesEnum.EST
            ? CompletedActionsEstimateEnum.EndTravel
            : CompletedActionsJobEnum.EndTravel),
      );

      if (startTravelActions.length === 0) {
        return StepStatusesEnum.ToBeDone;
      } else if (startTravelActions.length > endTravelActions.length) {
        return StepStatusesEnum.InProgress;
      } else if (startTravelActions.length === endTravelActions.length) {
        return StepStatusesEnum.Done;
      }

      return StepStatusesEnum.ToBeDone;
    }

    return travelStatus === ProcessStatusEnum.InProgress
      ? StepStatusesEnum.InProgress
      : travelStatus === ProcessStatusEnum.Done
      ? StepStatusesEnum.Done
      : StepStatusesEnum.ToBeDone;
  }, [
    isLoading,
    appointmentOuter?.id,
    appointmentOuter?.completedactions,
    appointmentOuter?.type,
    useDevelopments,
    travelStatus,
  ]);

  const workStepStatus = useMemo(() => {
    if (isLoading) {
      return StepStatusesEnum.ToBeDone;
    }

    if (appointmentOuter?.id && !useDevelopments) {
      const startWorkActions = appointmentOuter.completedactions.filter(
        (completedaction) =>
          completedaction.type.id ===
          (appointmentOuter.type === AppointmentTypesEnum.EST
            ? CompletedActionsEstimateEnum.StartWork
            : CompletedActionsJobEnum.StartWork),
      );
      const endWorkActions = appointmentOuter.completedactions.filter(
        (completedaction) =>
          completedaction.type.id ===
          (appointmentOuter.type === AppointmentTypesEnum.EST
            ? CompletedActionsEstimateEnum.EndWork
            : CompletedActionsJobEnum.EndWork),
      );

      if (startWorkActions.length === 0) {
        return StepStatusesEnum.ToBeDone;
      } else if (startWorkActions.length > endWorkActions.length) {
        return StepStatusesEnum.InProgress;
      } else if (startWorkActions.length === endWorkActions.length) {
        const pausedWorks = getPausedWorks();

        if (pausedWorks.includes(appointmentOuter.id)) {
          return StepStatusesEnum.InProgress;
        }

        return StepStatusesEnum.Done;
      }

      return StepStatusesEnum.ToBeDone;
    }

    return workStatus === ProcessStatusEnum.Paused ||
      workStatus === ProcessStatusEnum.InProgress
      ? StepStatusesEnum.InProgress
      : workStatus === ProcessStatusEnum.Done
      ? StepStatusesEnum.Done
      : StepStatusesEnum.ToBeDone;
  }, [
    isLoading,
    appointmentOuter?.id,
    appointmentOuter?.completedactions,
    appointmentOuter?.type,
    useDevelopments,
    workStatus,
  ]);

  const billingStepStatus = useMemo(() => {
    if (isLoading || !appointment?.id) {
      return StepStatusesEnum.ToBeDone;
    }

    if (!isAddProductsChecked && appointment.balanceDue <= 0) {
      return StepStatusesEnum.ToBeDone;
    } else if (isAddProductsChecked && appointment.balanceDue > 0) {
      return StepStatusesEnum.InProgress;
    } else if (isAddProductsChecked && appointment.balanceDue <= 0) {
      return StepStatusesEnum.Done;
    }

    return StepStatusesEnum.ToBeDone;
  }, [
    isLoading,
    appointment?.id,
    appointment?.balanceDue,
    isAddProductsChecked,
  ]);

  const steps = useMemo(
    () => ({
      [StepNamesEnum.Travel]: travelStepStatus,
      ...(isShowWorkStep
        ? {
            [StepNamesEnum.Work]: workStepStatus,
          }
        : {}),
      ...(isShowBillingStep
        ? {
            [StepNamesEnum.Billing]: billingStepStatus,
          }
        : {}),
      [StepNamesEnum.Complete]: isAppointmentCompleted
        ? StepStatusesEnum.Done
        : StepStatusesEnum.ToBeDone,
    }),
    [
      travelStepStatus,
      workStepStatus,
      isAppointmentCompleted,
      isShowWorkStep,
      isShowBillingStep,
      billingStepStatus,
    ],
  );

  const actualStep = useMemo(() => {
    if (isLoading) {
      return;
    }

    const step = Object.entries(steps).find(
      ([label, value]) =>
        value === StepStatusesEnum.ToBeDone ||
        value === StepStatusesEnum.InProgress ||
        (isJobDetails &&
          label === StepNamesEnum.Work &&
          StepStatusesEnum.Done &&
          workLinks.find((workLink) => !workLink.isChecked)),
    );

    if (step) {
      return { [step[0]]: step[1] };
    }
  }, [isLoading, workLinks, isJobDetails, steps]);

  const actualStepName = useMemo(
    () => (actualStep ? Object.getOwnPropertyNames(actualStep)[0] : null),
    [actualStep],
  );

  const isAllStepsDone = useMemo(
    () =>
      Object.entries(steps).filter(
        ([, value]) => value === StepStatusesEnum.Done,
      ).length === Object.keys(steps).length,
    [steps],
  );

  const totalStepsNumber = useMemo(() => Object.keys(steps).length, [steps]);

  const currentStepNumber = useMemo(
    () =>
      Object.keys(steps).findIndex((stepKey) => stepKey === actualStepName) + 1,
    [steps, actualStepName],
  );

  const nextStep = useMemo(() => {
    const stepsKeys = Object.keys(steps);

    const actualStepIndex = stepsKeys.findIndex(
      (stepKey) => stepKey === actualStepName,
    );

    if (actualStepIndex < stepsKeys.length) {
      return stepsKeys[actualStepIndex + 1];
    }
  }, [actualStepName, steps]);

  const mainAction = useMemo(() => {
    if (!actualStep || !actualStepName || !appointment?.id) {
      return;
    }

    const stepValue = actualStep[actualStepName];

    switch (actualStepName) {
      case StepNamesEnum.Travel:
        return {
          name:
            stepValue === StepStatusesEnum.InProgress
              ? "End travel"
              : "Start travel",
          onClick: () => navigate(routePaths.jobDetails(appointment.id)),
        };
      case StepNamesEnum.Work:
        return {
          name:
            stepValue === StepStatusesEnum.InProgress
              ? "End work"
              : "Start work",
          onClick: () => navigate(routePaths.jobDetails(appointment.id)),
        };

      case StepNamesEnum.Billing:
        return {
          name:
            stepValue === StepStatusesEnum.InProgress
              ? "Collect payment"
              : "Add products",
          onClick: () => navigate(routePaths.jobDetails(appointment.id)),
        };
      case StepNamesEnum.Complete:
        return {
          name: "Complete appointment",
          onClick: () => navigate(routePaths.jobDetails(appointment.id)),
        };
    }
  }, [actualStep, actualStepName, appointment?.id, navigate]);

  return {
    steps,
    actualStep,
    actualStepName,
    managingProductsDenied,
    isShowWorkStep,
    isShowBillingStep,
    isAddProductsChecked,
    isAppointmentCompleted,
    isAllStepsDone,
    totalStepsNumber,
    currentStepNumber,
    nextStep,
    isLoading,
    mainAction,
  };
};
