import { Grid, Typography } from "@material-ui/core";
import { memo } from "react";
import clsx from "clsx";
import { colors } from "@chhjpackages/components";

import { ChipStyled } from "shared/ui";
import { StepStatusesEnum } from "shared/types";

import { ContentAccordionLabelProps } from "./types";
import { useStyles } from "./styles";

export const ContentAccordionLabel = memo(
  ({ title, status }: ContentAccordionLabelProps) => {
    const styles = useStyles();

    return (
      <Grid
        container
        style={{ height: "45px" }}
        alignItems="center"
        justify="space-between"
      >
        <Grid item color={colors.grey190}>
          <Typography variant="h4" color="inherit">
            {title}
          </Typography>
        </Grid>
        <Grid item>
          {status && (
            <ChipStyled
              className={clsx(
                status === StepStatusesEnum.InProgress && styles.chipProgress,
                status === StepStatusesEnum.ToBeDone && styles.chipBeDone,
              )}
              size="small"
              label={status}
            />
          )}
        </Grid>
      </Grid>
    );
  },
);
