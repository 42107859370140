import { AddendumChangeOptionIdsEnum } from "../types";

export const descriptionOfChangesOptions = [
  {
    id: AddendumChangeOptionIdsEnum.AdditionalHouseholdGoods,
    name: "Additional household goods",
  },
  {
    id: AddendumChangeOptionIdsEnum.PackingServicesRequired,
    name: "Packing services required",
  },
  {
    id: AddendumChangeOptionIdsEnum.UnforeseeableCircumstances,
    name: "Unforeseeable circumstances",
  },
];
