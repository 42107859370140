import { useState, useEffect, useCallback, useMemo, memo } from "react";
import { useStore } from "effector-react";
import { useParams } from "react-router";
import { useNavigate, Outlet } from "react-router";
import moment from "moment";
import { useDebounce } from "use-debounce";

import { AttachmentIcon } from "shared/assets";
import { useSideNavDispatch } from "features/sidenav";
import {
  $appointmentStore,
  $appointmentsStore,
  getAppointmentFx,
  setAppointmentEv,
  AppointmentHeader,
} from "features/appointment";
import {
  USATimeFormatAmPmSmall,
  apiDateFormat,
  apiDateTimeFormat,
  routePaths,
} from "shared/utils";
import { $auth } from "features/auth";
import { $developments, getDevelopmentsFx } from "features/developments";
import { $pricings, getPricingsFx } from "features/pricings";
import { $teamsStore, getTeamsByDateFx, getTeamsFx } from "features/teams";
import { useLocationTimezone } from "shared/hooks";

import { ProcessTab } from "./process-tab";
import { GeneralTab } from "./general-tab";
import { NotesTab } from "./notes-tab";

export const JobDetails = memo(() => {
  const navigate = useNavigate();
  const { appointmentId } = useParams<"appointmentId">();

  const { setActionButton, clearActionButton } = useSideNavDispatch();
  const { utcToZone } = useLocationTimezone("location");

  const { locationId } = useStore($auth);
  const { appointment, loading: appointmentLoading } =
    useStore($appointmentStore);
  const { appointments } = useStore($appointmentsStore);
  const { allTeams, loading: teamLoading } = useStore($teamsStore);
  const { developmentsAppointmentId } = useStore($developments);
  const { pricingsAppointmentId } = useStore($pricings);
  const { loading: pricingsLoading } = useStore($pricings);

  const [tab, setTab] = useState("process");
  const [isLoadingDevelopments, setIsLoadingDevelopments] = useState(true);

  const shouldFetchPricings = useMemo(
    () =>
      !!appointment &&
      appointment.type !== "EST" &&
      pricingsAppointmentId !== appointment.id,
    [appointment?.id, appointment?.type, pricingsAppointmentId],
  );

  const isLoading = useMemo(
    () =>
      isLoadingDevelopments ||
      appointmentLoading ||
      teamLoading ||
      (shouldFetchPricings && pricingsLoading),
    [
      isLoadingDevelopments,
      appointmentLoading,
      teamLoading,
      pricingsLoading,
      shouldFetchPricings,
    ],
  );

  const [isLoadingDebounce] = useDebounce(isLoading, 10);

  const isNasa = useMemo(
    () => !!appointment?.subpartner.id,
    [appointment?.subpartner.id],
  );

  const status = useMemo(
    () =>
      appointment?.type === "EST"
        ? appointment.estStatus.name
        : appointment?.status.name,
    [appointment?.type, appointment?.estStatus.name, appointment?.status.name],
  );

  const appointmentTeams = useMemo(() => {
    const filteredSchedules = (appointment?.schedules ?? [])
      .filter((schedule) => schedule.team.id)
      .sort((a, b) => moment(a.startDate).diff(moment(b.startDate)));

    return filteredSchedules
      .map((schedule) => {
        const team = allTeams.find((t) => t.id === schedule.team.id);

        if (team) {
          return {
            id: team.id,
            name: team.users
              .map(
                ({ firstName, lastName }) =>
                  `${firstName}${
                    lastName ? `.${lastName[0].toUpperCase()}` : ""
                  }`,
              )
              .join(" | "),
            scheduleId: schedule.id,
            time: moment(schedule.startDate).format(USATimeFormatAmPmSmall),
          };
        }

        return undefined;
      })
      .filter((item) => item !== undefined) as {
      id: number;
      name: string;
      scheduleId: number;
      time: string;
    }[];
  }, [allTeams, appointment?.schedules]);

  const handleOpenAttachments = useCallback(() => {
    navigate(routePaths.jobDetailsAttachments(Number(appointmentId)));
  }, [appointmentId, navigate]);

  const changeTab = useCallback((_: React.ChangeEvent<{}>, value: unknown) => {
    if (typeof value === "string") {
      setTab(value);
    }
  }, []);

  const handleOnBack = useCallback(
    () => navigate(routePaths.dashboard()),
    [navigate],
  );

  const returnTab = useCallback(() => {
    switch (tab) {
      case "process":
        return <ProcessTab isLoading={isLoadingDebounce} />;

      case "general":
        return <GeneralTab />;

      default:
        return <NotesTab />;
    }
  }, [tab, isLoadingDebounce]);

  useEffect(() => {
    setActionButton({
      name: "Attachments",
      icon: <AttachmentIcon color="primary" fontSize={22} />,
      onClick: () => handleOpenAttachments(),
    });

    return () => {
      clearActionButton();
    };
  }, [setActionButton, clearActionButton, handleOpenAttachments]);

  useEffect(() => {
    const getAppointment = async () => {
      if (locationId) {
        try {
          await getAppointmentFx({
            locationId: locationId,
            appointmentId: Number(appointmentId),
          });
        } catch (error) {
          const actualAppointment = appointments.find(
            (job) => job.id === Number(appointmentId),
          );

          if (actualAppointment) {
            setAppointmentEv(actualAppointment);
          }
        }
      }
    };

    getAppointment();
  }, [appointmentId, locationId, appointments]);

  useEffect(() => {
    if (locationId) {
      getTeamsFx({ locationId });
      getTeamsByDateFx({
        locationId: locationId,
        date: utcToZone(moment.utc().format(apiDateTimeFormat), apiDateFormat),
      });
    }
  }, [locationId]);

  useEffect(() => {
    if (shouldFetchPricings && appointment) {
      getPricingsFx({
        locationId: appointment.location.id,
        appointmentId: appointment.id,
        postal: appointment.origin.postal,
        categoryId: appointment.category.id,
        jobDate: moment(appointment.startDate).format(apiDateFormat),
      });
    }
  }, [
    shouldFetchPricings,
    appointment?.location.id,
    appointment?.id,
    appointment?.origin.postal,
    appointment?.category.id,
    appointment?.startDate,
  ]);

  useEffect(() => {
    const getDevelopments = async () => {
      if (Number(appointmentId) !== developmentsAppointmentId && locationId) {
        await getDevelopmentsFx({
          locationId: locationId,
          appointmentId: Number(appointmentId),
        });
      }

      setIsLoadingDevelopments(false);
    };

    getDevelopments();
  }, [appointmentId, developmentsAppointmentId, locationId]);

  return (
    <div>
      <div style={{ width: "100%" }}>
        <AppointmentHeader
          id={Number(appointmentId)}
          tab={tab}
          teams={appointmentTeams}
          type={appointment?.type as string}
          category={appointment?.category.name}
          status={status}
          isNasa={isLoading ? false : isNasa}
          zone={appointment?.zone.name}
          loading={isLoading}
          changeTab={changeTab}
          onBack={handleOnBack}
          openAttachment={handleOpenAttachments}
        />
        {returnTab()}
      </div>

      <Outlet />
    </div>
  );
});
