import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  addNoteButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 6,
  },
  addNoteButtonRoot: {
    marginRight: -4,
    padding: "4px 8px",
  },
}));
