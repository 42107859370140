import { memo } from "react";
import { Typography } from "@material-ui/core";

import { ReactComponent as Logo } from "../../assets/images/logo.svg";

import { useStyles } from "./styles";

export const Page1 = memo(() => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <div className={styles.sidebar}>
        <div className={styles.content}>
          <Logo className={styles.logo} />

          <Typography variant="h1" component="div" className={styles.text1}>
            Thank you
          </Typography>

          <Typography variant="h2" component="div" className={styles.text2}>
            for choosing us
          </Typography>

          <Typography
            variant="subtitle1"
            component="div"
            color="secondary"
            align="center"
            className={styles.webLink}
          >
            www.collegehunks.com
          </Typography>
        </div>
      </div>
    </div>
  );
});
