import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { memo } from "react";

export const ListViewSkeleton = memo(() => {
  return (
    <Grid container alignItems="center" justify="space-between" wrap="nowrap">
      <Grid item xs={12}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Skeleton variant="rect" width={24} />
          </Grid>
          <Grid item>
            <Skeleton variant="rect" width={100} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Skeleton variant="rect" width={80} />
      </Grid>
    </Grid>
  );
});
