import { useCallback, useEffect, useState } from "react";

export const useSearch = <T>(
  items: T[],
  getSearchObject: (item: T) => Record<string, string | number>,
) => {
  const [searchedList, setSearchedList] = useState(items);

  useEffect(() => {
    setSearchedList(items);
  }, [items]);

  const onSearch = useCallback(
    (searchData: string) => {
      const filteredData = items.filter((item) => {
        const searchObject = getSearchObject(item);

        return (
          Object.values(searchObject).filter((el) =>
            String(el).toLowerCase().includes(searchData.toLowerCase()),
          ).length > 0
        );
      });

      setSearchedList(filteredData);
    },
    [items, getSearchObject],
  );

  return { searchedList, onSearch };
};
