import { colors } from "@chhjpackages/components";
import { SvgIcon } from "@material-ui/core";

export const GeoIcon = ({
  className,
  color = colors.grey80,
  fontSize = 24,
}: {
  className?: string;
  color?: string;
  fontSize?: number;
}) => (
  <SvgIcon
    className={className}
    style={{ fontSize }}
    width="16"
    height="22"
    viewBox="0 0 16 22"
    fill="none"
  >
    <path
      d="M7.95278 1C11.5972 1 14.6194 4.02222 14.6194 7.66667C14.6194 10.6889 10.0861 18.3333 8.48611 20.7333C8.39722 20.9111 8.13056 21 7.95278 21C7.68611 21 7.50833 20.9111 7.41944 20.7333C5.81944 18.2444 1.375 10.6889 1.375 7.57778C1.375 3.93333 4.30833 1 7.95278 1Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M7.95285 10.9555C9.81952 10.9555 11.3306 9.4444 11.3306 7.57773C11.3306 5.71106 9.81952 4.19995 7.95285 4.19995C6.08618 4.19995 4.57507 5.71106 4.57507 7.57773C4.57507 9.4444 6.17507 10.9555 7.95285 10.9555Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
