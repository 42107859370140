import { OperationLog } from "entities/operation-logs";
import { Attachment } from "features/attachments";
import {
  BasicUserAccount,
  CompletedAction,
  CouponAppointment,
  DataItem,
  Discount,
  Note,
  Place,
  Product,
  Subpartner,
  TaxCode,
  TaxRate,
  TimeZone,
} from "shared/types";

export enum AccountType {
  "Commercial" = 3,
  "Residential" = 5,
}

export const AccountTypes: Record<AccountType, string> = {
  3: "Commercial",
  5: "Residential",
};

export type UserData = {
  id: number;
  first_name: string;
  last_name: string;
  company_name: string;
  phone: string;
  work: string;
  cell: string;
  email: string;
  type: DataItem;
};

export type AppointmentType = "ALL" | "JOB" | "EST" | "LJOB";

export const AppointmentTypes: Record<AppointmentType, string> = {
  ALL: "All types",
  JOB: "Job",
  EST: "Estimate",
  LJOB: "Linked",
};

export enum AppointmentTypesEnum {
  ALL = "ALL",
  EST = "EST",
  JOB = "JOB",
  LJOB = "LJOB",
}

export type AppointmentInvoice = {
  id: number;
};

export type QuoteModel = {
  id: number;
  notes: {
    internalNotes: string;
    moveFactors: string;
    specialInstructions: string;
    specialNotes: string;
  };
  numHunks: number;
  type: DataItem;
  metUrl: string;
  location: DataItem;
  created: {
    datetime: string;
  } & DataItem;
};

export type AppointmentLocation = DataItem & {
  status: DataItem;
  taxCodes: TaxCode[];
  timeZone: TimeZone;
};

export type AppointmentAction = {
  completed: Date;
  id: number;
  location: AppointmentLocation;
  type: DataItem;
};

export type Appointments = {
  account: BasicUserAccount;
  administrativeFeeEnabled: number;
  administrativeFee: number;
  attachments: Attachment[];
  amountPaid: number;
  appointmentTaxTotal: number;
  balanceDue: number;
  clientConfirmed: boolean;
  category: DataItem;
  completedactions: CompletedAction[];
  convertedAppointmentId: number;
  couponTotal: number;
  coupon: CouponAppointment;
  created: DataItem & {
    date: Date;
  };
  destination: Place;
  discountTotal: number;
  discount: Discount;
  endDate: string;
  estStatus: DataItem;
  grandTotal: number;
  id: number;
  invoice: AppointmentInvoice;
  linked?: number[];
  location: AppointmentLocation;
  net: number;
  notes: Note;
  numHunks: number;
  operationLogs: OperationLog[];
  origin: Place;
  originalDate?: string;
  purchaseOrder: string;
  startDate: string;
  targetDate: string;
  taxRate: TaxRate;
  type: keyof typeof AppointmentTypes;
  productServicesTotal: number;
  productTotal: number;
  quotes: QuoteModel[];
  refundDays: number;
  status: DataItem;
  stops: Place[];
  subTotal: number;
  team: AppointmentTeamResponseData;
  tipAmount: number;
  schedules: AppointmentScheduleResponseData[];
  source: DataItem;
  subpartner: Subpartner;
  parent: {
    id: number;
  };
  products: Product[];
  payments: AppointmentPaymentResponseData[];
  zone: DataItem;
  royalty: { id: number; isSubmitted: boolean };
  // actualStep: string;
};

export type PricingResponse = DataItem & {
  subcategory: DataItem & {
    category: DataItem;
  };
  icon: string;
  price: number;
  active: boolean;
  tax: DataItem & {
    isTaxable: boolean;
  };
};

export type AppointmentTeamResponseData = DataItem & {
  captain: DataItem;
};

export type AppointmentScheduleResponseData = {
  id: number;
  startDate: string;
  endDate: string;
  resource: DataItem;
  development: DataItem;
  team: DataItem;
  colors: {
    background: string;
    foreground: string;
  };
};

export type AppointmentPaymentResponseData = {
  id: number;
  accountId: number;
  locationId: number;
  appointmentId: number;
  payment: {
    amount: number;
    authCode: string;
    batch: string;
    checkNumber: string;
    creditCard: {
      address: string;
      name: string;
      number: string;
      typeId: string;
      zip: string;
      expiryMonth: number;
      expiryYear: number;
      expiry: number;
    };
    createdDate: string;
    date: string;
    memo: string;
    transactionId: string;
    method: DataItem;
    createdBy: string;
    zone: DataItem;
  };
};
