import { colors } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  workLinksSectionTitle: {
    color: colors.grey140,
  },
  workLinksList: {
    marginTop: 16,
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },
  actionButtonsContainer: {
    marginTop: 24,
    display: "flex",
    alignItems: "center",
    gap: 16,
    width: "100%",
  },
  actionButton: {
    flex: 1,
  },
}));
