import { Divider } from "@material-ui/core";
import { Button, colors } from "@chhjpackages/components";
import { memo } from "react";

import { AppointmentActionListProps } from "./types";

export const AppointmentActionList = memo(
  ({ actions }: AppointmentActionListProps) => (
    <div style={{ display: "flex", borderTop: `1px dashed ${colors.grey80}` }}>
      {actions.map((action, index) => (
        <>
          <div
            key={action.title}
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ width: "100%" }}>
              <Button
                buttonType="text"
                color="primary"
                fullWidth
                disabled={action.disabled}
                isLoading={action.loading}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  action.onClick();
                }}
                style={{ padding: "8px 12px", fontSize: 18 }}
              >
                {action.title}
              </Button>
            </div>
          </div>
          {index !== actions.length - 1 && (
            <div key={`${action.title}-divider`}>
              <Divider orientation="vertical" />
            </div>
          )}
        </>
      ))}
    </div>
  ),
);
