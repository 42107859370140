import { Theme, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    justifyContent: "center",

    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },

  container: {
    display: "flex",
    position: "relative",
    maxWidth: 800,
    width: "100%",

    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      minHeight: "auto",
    },
  },

  leftSide: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    position: "sticky",
    top: 0,

    [theme.breakpoints.down("xs")]: {
      position: "static",
      padding: "64px 0 0",
    },
  },

  mainText: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    maxWidth: 340,
  },

  rightSide: {
    padding: 16,
    flex: 2,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",

    [theme.breakpoints.down("xs")]: {
      flexDirection: "initial",
      flexWrap: "wrap",
    },
  },

  cardWrapper: {
    margin: "32px 0",
    display: "inline-flex",
    justifyContent: "center",
  },

  card: {
    padding: 16,

    [theme.breakpoints.down("xs")]: {
      "& img": {
        maxWidth: "100%",
      },
    },
  },
}));
