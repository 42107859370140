import { colors } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/core";

export const previewStyle = {
  justifyContent: "center",
};

export const getPreviewImageStyle = (
  size: "mobile" | "tablet" | "desktop" | undefined = "desktop",
) => {
  let maxWidth: number | string = 400;

  if (size === "mobile") {
    maxWidth = "100%";
  } else if (size === "tablet") {
    maxWidth = 300;
  }

  return {
    borderRadius: 12,
    width: "50%",
    maxWidth: maxWidth,
  };
};

export const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    padding: 16,
    borderRadius: 8,
    backgroundColor: colors.white,
  },
  deleteButtonRoot: {
    color: colors.functionals.alert,
  },
}));
