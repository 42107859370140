import { colors } from "@chhjpackages/components";
import { SvgIcon } from "@material-ui/core";

export const RotateIcon = ({
  className,
  color = colors.black,
  fontSize = 18,
}: {
  className?: string;
  color?: string;
  fontSize?: number;
}) => {
  return (
    <SvgIcon
      style={{ fontSize }}
      className={className}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      fontSize="inherit"
    >
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 0C23.2843 0 30 6.71573 30 15C30 20.1295 27.4012 24.775 23.2948 27.5015L26.25 27.5C26.9403 27.5 27.5 28.0597 27.5 28.75C27.5 29.3828 27.0297 29.9058 26.4197 29.9887L26.25 30H19.5833C18.9505 30 18.4275 29.5297 18.3447 28.9197L18.3333 28.75V22.0833C18.3333 21.393 18.893 20.8333 19.5833 20.8333C20.2162 20.8333 20.7392 21.3037 20.822 21.9137L20.8333 22.0833L20.835 26.0583C24.8735 23.9262 27.5 19.7078 27.5 15C27.5 8.09643 21.9035 2.5 15 2.5C8.09643 2.5 2.5 8.09643 2.5 15C2.5 15.6903 1.94035 16.25 1.25 16.25C0.55965 16.25 0 15.6903 0 15C0 6.71573 6.71573 0 15 0ZM15 10.4167C17.5313 10.4167 19.5833 12.4687 19.5833 15C19.5833 17.5313 17.5313 19.5833 15 19.5833C12.4687 19.5833 10.4167 17.5313 10.4167 15C10.4167 12.4687 12.4687 10.4167 15 10.4167ZM15 12.9167C13.8493 12.9167 12.9167 13.8493 12.9167 15C12.9167 16.1507 13.8493 17.0833 15 17.0833C16.1507 17.0833 17.0833 16.1507 17.0833 15C17.0833 13.8493 16.1507 12.9167 15 12.9167Z"
          fill={color}
        />
      </svg>
    </SvgIcon>
  );
};
