export const USADateTimeShortDescriptiveFormat = "MMMM D, YYYY"; // example: Jan 20, 2021
export const apiDateFormat = "YYYY-MM-DD";
export const apiTimeFormat = "HH:mm:ss";
export const apiDateTimeFormat = "YYYY-MM-DD HH:mm:ss";
export const apiDateTimeMinutesFormat = "YYYY-MM-DD HH:mm";
export const USADateFormat = "MM/DD/YYYY";
export const USADateFormatShort = "MM/DD/YY";
export const USADateFormatShortest = "M/D/YY";
export const USADateTimeFormat = "MM/DD/YYYY HH:mm";
export const USATimeFormatAmPm = "h:mm A";
export const USATimeFormatAmPmSmall = "h:mm a";
