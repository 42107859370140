import { colors } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    gap: 12,
    color: colors.primary.main,
  },
}));
