import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  stepLinksList: {
    margin: "16px 0px 32px",
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },
  quickAddContainer: {
    marginBottom: 32,
  },
  quickAddCardWrapper: {
    padding: "16px 10px",
    marginRight: -10,
  },
  quickAddArrowsContainer: {
    marginTop: 2,
  },
  pricingsListWrapper: {
    marginTop: 8,
  },
  buttonContainer: {
    marginTop: 16,
  },
}));
