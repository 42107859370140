import { fetcher } from "shared/libs";
import { ValuationPlans } from "../types";

export const getValuationPlans = ({
  locationId,
  appointmentId,
}: ValuationPlans.GetAll) =>
  fetcher.get<ValuationPlans.Response>(
    `/locations/${locationId}/appointments/${appointmentId}/valuation/plans`,
  );
