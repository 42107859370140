import { colors } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: colors.grey140,
  },
  emptyBoxIcon: {
    width: "30%",
    height: "auto",
    maxWidth: 200,
    minWidth: 138,
  },
  noAppointmentsr: {
    marginTop: 16,
  },
  review: {
    marginTop: 32,
  },
});
