import { useStore } from "effector-react/compat";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDialogDispatch } from "@chhjpackages/components";

import {
  $pricingItems,
  addPricingItem,
  checkIsLabor,
  deletePricingItem,
  updatePricingItem,
} from "features/pricings";
import { getUniqueNumberId, routePaths } from "shared/utils";
import { $appointmentStore } from "features/appointment";
import {
  AddEditProductFormValues,
  AddEditProductForm,
  $products,
} from "features/products";
import { DialogContentWrapper, TwoLinesDialogHeader } from "shared/ui";
import { $developments } from "features/developments";
import {
  $pricingUpdates,
  addPricingUpdate,
  deletePricingUpdate,
  setPricingUpdate,
} from "features/add-product";

export const PricingsAdd = memo(() => {
  const setDialog = useDialogDispatch();

  const { pricingId, appointmentId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { pricingItems } = useStore($pricingItems);
  const { products } = useStore($products);
  const { updates } = useStore($pricingUpdates);
  const { appointment } = useStore($appointmentStore);
  const { billableTimeTravelled, billableTimeWorked } = useStore($developments);

  const addindPricing = useMemo(
    () => pricingItems.find((pricing) => pricing.key === Number(pricingId)),
    [pricingItems, pricingId],
  );

  const recordInUpdates = useMemo(
    () => updates.find((update) => update.key === addindPricing?.key),
    [updates, addindPricing?.key],
  );

  const productFromCart = useMemo(
    () =>
      addindPricing &&
      products.find(
        (product) => product.productLineId === addindPricing.productLineId,
      ),
    [products, addindPricing],
  );

  const taxCodes = useMemo(
    () => (appointment ? appointment.location.taxCodes : []),
    [appointment],
  );

  const disableAddAsNew = useMemo(
    () => (recordInUpdates?.quantity ?? addindPricing?.quantity ?? 0) === 0,
    [recordInUpdates?.quantity, addindPricing?.quantity],
  );

  const initialValues = useMemo(() => {
    if (!addindPricing) {
      return;
    }

    return {
      quantity: recordInUpdates?.quantity ?? (addindPricing.quantity || 1),
      price: String(addindPricing.actualPrice),
      taxId: addindPricing.tax.id,
      notes: addindPricing.notes,
    };
  }, [addindPricing, recordInUpdates?.quantity]);

  const handleCloseAddProductDialog = useCallback(
    () =>
      navigate(routePaths.jobDetailsAddProduct(Number(appointmentId)), {
        replace: true,
        state: location.state,
      }),
    [appointmentId, location.state, navigate],
  );

  const handleOnSave = useCallback(
    (data: AddEditProductFormValues) => {
      if (addindPricing) {
        const taxStatus = taxCodes.find((taxCode) => taxCode.id === data.taxId);

        if (
          productFromCart &&
          productFromCart.quantity === data.quantity &&
          productFromCart.actualPrice === Number(data.price) &&
          productFromCart.salesTaxId === data.taxId &&
          productFromCart.notes === data.notes
        ) {
          deletePricingUpdate({ key: addindPricing.key });
        } else {
          setPricingUpdate({
            pricing: {
              ...addindPricing,
              actualPrice: Number(data.price),
              quantity: data.quantity,
              tax: taxStatus ?? addindPricing.tax,
              notes: data.notes,
            },
            ignoreDelete: !!productFromCart,
          });
        }

        if (!addindPricing.isOriginal && data.quantity === 0) {
          deletePricingItem({ key: addindPricing.key });
        } else {
          const pricingsItemsWithSameId = pricingItems.filter(
            (pricingItem) => pricingItem.id === addindPricing.id,
          );
          const isHidden =
            addindPricing.isOriginal &&
            data.quantity === 0 &&
            pricingsItemsWithSameId.length > 1;

          updatePricingItem({
            ...addindPricing,
            actualPrice: Number(data.price),
            quantity: getUniqueNumberId(),
            tax: taxStatus ?? addindPricing.tax,
            notes: data.notes,
            isHidden,
          });

          updatePricingItem({
            ...addindPricing,
            actualPrice: Number(data.price),
            quantity: data.quantity,
            tax: taxStatus ?? addindPricing.tax,
            notes: data.notes,
            isHidden,
          });
        }
      }

      handleCloseAddProductDialog();
    },
    [
      addindPricing,
      pricingItems,
      taxCodes,
      productFromCart,
      handleCloseAddProductDialog,
    ],
  );

  const handleOnAddAsNew = useCallback(
    (data: AddEditProductFormValues) => {
      if (addindPricing) {
        const newKey = getUniqueNumberId();
        const taxStatus = taxCodes.find((taxCode) => taxCode.id === data.taxId);

        addPricingUpdate({
          ...addindPricing,
          key: newKey,
          productLineId: 0,
          isOriginal: false,
          actualPrice: Number(data.price),
          quantity: data.quantity,
          tax: taxStatus ?? addindPricing.tax,
          notes: data.notes,
        });

        addPricingItem({
          ...addindPricing,
          key: newKey,
          productLineId: 0,
          isOriginal: false,
          actualPrice: Number(data.price),
          quantity: data.quantity,
          tax: taxStatus ?? addindPricing.tax,
          notes: data.notes,
        });
      }

      handleCloseAddProductDialog();
    },
    [addindPricing, taxCodes, handleCloseAddProductDialog],
  );

  useEffect(() => {
    if (addindPricing) {
      const isLabor = checkIsLabor(addindPricing.name);

      setDialog({
        open: true,
        variant: "basic",
        title: (
          <TwoLinesDialogHeader
            title={
              isLabor ? addindPricing.subcategory.name : addindPricing.name
            }
            description={isLabor ? addindPricing.name : ""}
          />
        ),
        disableBackdropClick: true,
        dialogContent: (
          <DialogContentWrapper maxWidth={488}>
            <AddEditProductForm
              initialValues={initialValues}
              isLabor={isLabor}
              travelTime={billableTimeTravelled}
              workTime={billableTimeWorked}
              taxCodes={taxCodes}
              minQuantity={
                addindPricing
                  ? !!productFromCart
                    ? 0
                    : updates.find((update) => update.key === addindPricing.key)
                    ? 0
                    : 0.25
                  : undefined
              }
              disableAddAsNew={disableAddAsNew}
              onSave={handleOnSave}
              onAddAsNew={handleOnAddAsNew}
              onClose={handleCloseAddProductDialog}
            />
          </DialogContentWrapper>
        ),
        onClose: handleCloseAddProductDialog,
      });
    }

    return () => setDialog({ open: false });
  }, [
    addindPricing,
    taxCodes,
    billableTimeTravelled,
    billableTimeWorked,
    initialValues,
    productFromCart,
    updates,
    disableAddAsNew,
    setDialog,
    handleCloseAddProductDialog,
    handleOnAddAsNew,
    handleOnSave,
  ]);

  return null;
});
