import { useMediaQuery, useTheme } from "@material-ui/core";
import { useSearchParams } from "react-router-dom";
import "react-spring-bottom-sheet/dist/style.css";
import { memo, useCallback, useEffect, useMemo } from "react";
import { colors, Button, Paper } from "@chhjpackages/components";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useStore } from "effector-react/compat";

import { routePaths } from "shared/utils";
import { useSideNavDispatch } from "features/sidenav";
import { GuideStepsGroup, binderListTitle } from "features/training-details";
import { BackTitle } from "shared/ui";
import { $appointmentStore, getAppointmentFx } from "features/appointment";
import { $auth } from "features/auth";

export const BinderPresentationList = memo(() => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), { noSsr: true });

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { setPageName, setGoToBackUrl, setShowGoBackButton } =
    useSideNavDispatch();

  const { locationId } = useStore($auth);
  const { appointment } = useStore($appointmentStore);

  const appointmentId = useMemo(
    () => searchParams.get("appointmentId"),
    [searchParams],
  );

  const backRoute = useMemo(() => {
    if (appointmentId) {
      return routePaths.jobDetails(Number(appointmentId));
    }

    return routePaths.training();
  }, [appointmentId]);

  const callGuideJob = useCallback(
    (val: number) => {
      navigate({
        pathname: routePaths.trainingBinderPresentationDetails(val + 1),
        search: `?${createSearchParams(searchParams)}`,
      });
    },
    [navigate, searchParams],
  );

  useEffect(() => {
    if (
      Number(appointmentId) &&
      appointment?.id !== Number(appointmentId) &&
      locationId
    ) {
      getAppointmentFx({
        locationId,
        appointmentId: Number(appointmentId),
      });
    }
  }, [appointment?.id, appointmentId, locationId]);

  useEffect(() => {
    setPageName("Binder presentation");
    setGoToBackUrl(backRoute);
    setShowGoBackButton();

    return () => {
      setPageName("");
      setGoToBackUrl("");
      setShowGoBackButton();
    };
  }, [backRoute, setPageName, setGoToBackUrl, setShowGoBackButton]);

  return (
    <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
      {!isMobile && (
        <BackTitle
          title="Binder presentation"
          onBack={() => navigate(backRoute)}
        />
      )}

      <div style={{ padding: 16, flex: 1 }}>
        <GuideStepsGroup
          callGuideJob={callGuideJob}
          guideListArray={binderListTitle}
          stepsValidate={[false]}
        />
      </div>

      <div
        style={{
          position: "sticky",
          bottom: 0,
          backgroundColor: colors.white,
        }}
      >
        <Paper elevation={16} style={{ padding: "16px 24px" }}>
          <Button
            buttonType="twoTone"
            fullWidth
            size="large"
            onClick={() => callGuideJob(0)}
          >
            Start presentation
          </Button>
        </Paper>
      </div>
    </div>
  );
});
