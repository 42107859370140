import { createEffect, createStore } from "effector/compat";

import { getJobStatuses } from "../api";
import { StatusesStore } from "../types";

export const getJobStatusesFx = createEffect(getJobStatuses);

export const $jobStatusesStore = createStore<StatusesStore>({
  statuses: [],
  error: null,
  loading: true,
});

$jobStatusesStore
  .on(getJobStatusesFx.doneData, (store, response) => ({
    ...store,
    statuses: response.data.statuses,
  }))
  .on(getJobStatusesFx.failData, (store, error) => ({
    ...store,
    error,
  }))
  .on(getJobStatusesFx.pending, (store, loading) => ({
    ...store,
    loading,
  }));
