import { forwardRef, memo, useMemo } from "react";
import { useResizeDetector } from "react-resize-detector";
import clsx from "clsx";

import { SignatureAreaProps } from "./types";
import { useStyles } from "./styles";

export const SignatureArea = memo(
  forwardRef<HTMLDivElement, SignatureAreaProps>(
    ({ signature, classes, style, onClick }, ref) => {
      const { ref: imageRef, width: imageWidth } = useResizeDetector();

      const imageUrl = useMemo(
        () => signature && URL.createObjectURL(signature),
        [signature],
      );

      const styles = useStyles({
        imageWidth: imageWidth ?? 0,
        isShowSignature: !!imageUrl,
      });

      return (
        <div
          ref={ref}
          style={style}
          className={clsx(styles.root, classes?.root)}
        >
          <div
            className={clsx(styles.signatureArea, classes?.signatureArea)}
            onClick={onClick}
          >
            <img
              ref={imageRef}
              src={imageUrl ?? undefined}
              alt="signature"
              className={clsx(styles.signature, classes?.signature)}
            />
          </div>
        </div>
      );
    },
  ),
);
