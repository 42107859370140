import { DataItem } from "shared/types";

export type Development = {
  id: number;
  datetime: string;
  manual: boolean;
  status: DataItem;
  created: {
    datetime: string;
    by: {
      id: number;
      fullName: string;
    };
  };
};

export enum ProcessStatusEnum {
  ToBeDone = 1,
  InProgress = 2,
  Done = 3,
  Paused = 4,
}

export enum DevelopmentStatusesEnum {
  StartTravel = 1,
  StopTravel = 2,
  StartWork = 3,
  StopWork = 4,
}
