import { Tab, withStyles, createStyles, Theme, Tabs } from "@material-ui/core";
import { colors, RiftFont } from "@chhjpackages/components";

export const TabStyled = withStyles((theme: Theme) =>
  createStyles({
    root: {
      opacity: 1,
      padding: "21px 12px",

      "& .MuiTab-wrapper": {
        fontFamily: RiftFont,
        fontSize: "18px",
        fontWeight: "bold",
        color: colors.grey140,
        lineHeight: "22px",
      },

      "& .MuiTouchRipple-root": {
        color: colors.primary.main,
      },

      [theme.breakpoints.down("sm")]: {
        minWidth: "fit-content",
        padding: 8,
        minHeight: "0px",
      },
    },
    selected: {
      [theme.breakpoints.down("sm")]: {
        backgroundColor: colors.white,
      },
      "& .MuiTab-wrapper": {
        color: colors.primary.main,
      },
    },
  }),
)(Tab);

export const TabsStyled = withStyles(() =>
  createStyles({
    root: {
      minHeight: "30px",
    },
    indicator: {
      backgroundColor: colors.primary.main,
    },
  }),
)(Tabs);
