import { Button, useAlert } from "@chhjpackages/components";
import { Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useStore } from "effector-react";

import {
  SendItemizedReceiptForm,
  SendItemizedReceiptFormSkeleton,
  SendItemizedReceiptFormValues,
} from "features/payments";
import { getOS, routePaths } from "shared/utils";
import { $auth } from "features/auth";
import {
  $appointmentStore,
  getAppointmentFx,
  markAppointmentCompletedFx,
  sendAppointmentInvoiceFx,
} from "features/appointment";
import { useSideNavDispatch } from "features/sidenav";
import { ActionsFooter, BackTitle } from "shared/ui";
import { AppointmentStatus, CompletedActionsJobEnum } from "shared/types";
import { useCompletedActions } from "features/completed-actions";
import {
  // $squareLocations,
  SquareErrorCodesAndroidEnum,
  SquareErrorCodesIOsEnum,
  // SquareLocation,
  generateSquareDeepLink,
  generateSquareMetaData,
  // getSquareLocationsFx,
  getTransactionResult,
  recordSquarePayment,
} from "entities/square";
import { PaymentProviderEnum, usePaymentProvider } from "entities/payments";
import { squareForceProduction } from "shared/config";

import { useResultStyles } from "./assets";

export const PaymentResult = memo(() => {
  const styles = useResultStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: true,
  });

  const { appointmentId } = useParams();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  const { locationId } = useStore($auth);
  const { appointment, loading: appointmentLoading } =
    useStore($appointmentStore);
  // const {
  //   squareLocations,
  //   loading: squareLocationsLoading,
  //   locationId: squareLocationsLocationId,
  // } = useStore($squareLocations);

  const {
    setPageName,
    setShowGoBackButton,
    setGoToBackUrl,
    setGoToBackOptions,
  } = useSideNavDispatch();

  const paymentProvider = usePaymentProvider();
  const { showAlert } = useAlert();
  const { addCompletedAction } = useCompletedActions();

  const [appointmentInitialLoading, setAppointmentInitialLoading] =
    useState(true);
  const [errorCode, setErrorCode] = useState("");
  const [clientTransactionId, setClientTransactionId] = useState<string>();
  const [serverTransactionId, setServerTransactionId] = useState<string>();
  const [isRecordingPayment, setIsRecordingPayment] = useState(false);
  const [isRecordPaymentError, setIsRecordPaymentError] = useState(false);
  const [isMarkingJobAsCompleted, setIsMarkingJobAsCompleted] = useState(false);

  const successSearch = useMemo(() => {
    const succesSearchValue = searchParams.get("success");

    if (succesSearchValue && !isNaN(Number(succesSearchValue))) {
      return Number(succesSearchValue);
    }

    return null;
  }, [searchParams]);

  const isSuccesBasedOnSearch = useMemo(
    () => typeof successSearch === "number",
    [successSearch],
  );

  const [isSuccess, setIsSuccess] = useState<boolean | null>(
    isSuccesBasedOnSearch ? successSearch === 1 : null,
  );

  const system = useMemo(() => getOS(), []);

  const isSquare = useMemo(
    () => paymentProvider === PaymentProviderEnum.Square,
    [paymentProvider],
  );

  const isLoading = useMemo(
    () =>
      appointmentLoading ||
      appointmentInitialLoading ||
      // (isSquare && squareLocationsLoading) ||
      paymentProvider === null,
    [
      appointmentLoading,
      appointmentInitialLoading,
      // isSquare,
      // squareLocationsLoading,
      paymentProvider,
    ],
  );

  const squareNotLoggedIn = useMemo(
    () =>
      errorCode === SquareErrorCodesAndroidEnum.NOT_LOGGED_IN ||
      errorCode === SquareErrorCodesAndroidEnum.NO_EMPLOYEE_LOGGED_IN ||
      errorCode === SquareErrorCodesIOsEnum.NOT_LOGGED_IN,
    [errorCode],
  );

  const errorCodeForDisplay = useMemo(
    () => errorCode.replace("com.squareup.pos.", ""),
    [errorCode],
  );

  const showMarkJobAsCompleted = useMemo(
    () =>
      !!isSuccess &&
      !isLoading &&
      !!appointment?.id &&
      !isRecordingPayment &&
      !isRecordPaymentError,
    [
      isSuccess,
      isLoading,
      appointment?.id,
      isRecordingPayment,
      isRecordPaymentError,
    ],
  );

  const isDisableMarkJobAsCompleted = useMemo(
    () =>
      appointment?.type === "EST"
        ? appointment.estStatus.id === AppointmentStatus.Estimate.Completed
        : appointment?.status.id === AppointmentStatus.Job.Completed,
    [appointment?.type, appointment?.estStatus.id, appointment?.status.id],
  );

  const goToBackUrl = useMemo(
    () =>
      isSuccess
        ? location.state?.paymentBack ??
          routePaths.jobDetails(Number(appointmentId))
        : isSquare
        ? routePaths.jobDetailsPayments()
        : routePaths.jobDetailsPaymentsCollect(Number(appointmentId)),
    [appointmentId, isSuccess, isSquare, location.state?.paymentBack],
  );

  const pageName = useMemo(() => {
    let result = "Payment";

    if (isSuccess === true) {
      if (isRecordPaymentError) {
        result += " error";
      } else {
        result += " accepted";
      }
    } else if (isSuccess === false) {
      if (squareNotLoggedIn) {
        result += " error";
      } else {
        result += " declined";
      }
    }

    return result;
  }, [isSuccess, isRecordPaymentError, squareNotLoggedIn]);

  const { title: resultTitle, description: resultDescription } = useMemo<{
    title: string;
    description: string;
  }>(() => {
    if (isSuccess) {
      if (isRecordPaymentError) {
        return {
          title:
            "The payment processed, however it was not recorded correctly.",
          description:
            "Please make sure you have your credentials set up correctly and that you are connected to the internet to continue.",
        };
      }

      return {
        title: "Payment has been accepted",
        description:
          "Would you like to send a copy of the itemized receipt to the client?",
      };
    }

    if (squareNotLoggedIn) {
      return {
        title: "Not logged into Square",
        description:
          "Please ensure that the Square app is open and you have logged in using your device code before attempting payment.",
      };
    }

    return {
      title: "Payment has been declined",
      description:
        "Don’t worry, you can try again with another method of payment.",
    };
  }, [isSuccess, isRecordPaymentError, squareNotLoggedIn]);

  const handleGoBack = useCallback(() => {
    navigate(goToBackUrl, {
      state: location.state,
    });
  }, [goToBackUrl, location.state, navigate]);

  const recordPayment = useCallback(async () => {
    if (!appointment?.id) {
      return;
    }

    setIsRecordingPayment(true);

    try {
      const recordPaymentResponse = await recordSquarePayment({
        payload: {
          state: `${appointment.id}-${appointment.account.id}-${appointment.location.id}`,
          type: serverTransactionId ? "server_id" : "client_id",
          transactionId: (serverTransactionId ?? clientTransactionId) as string,
          ...(squareForceProduction && {
            forceProduction: squareForceProduction,
          }),
        },
      });

      if (
        recordPaymentResponse.status !== 200 ||
        (recordPaymentResponse.data.meta.errors?.length ?? 0) > 0
      ) {
        throw new Error();
      }

      if (location.search) {
        navigate(
          { pathname: location.pathname, search: "" },
          { replace: true, state: location.state },
        );
      }

      await getAppointmentFx({
        locationId: appointment.location.id,
        appointmentId: appointment.id,
      });

      setIsRecordPaymentError(false);
      showAlert("Success! Payment has been recorded.", {
        variant: "success",
      });
    } catch {
      setIsRecordPaymentError(true);
      showAlert("Error! Failed to record payment.", {
        variant: "error",
      });
    }

    setIsRecordingPayment(false);
  }, [
    location,
    appointment?.id,
    appointment?.location.id,
    appointment?.account.id,
    serverTransactionId,
    clientTransactionId,
    navigate,
    showAlert,
  ]);

  const checkPayment = useCallback(
    async (transactionId: string) => {
      if (!appointment?.id) {
        return;
      }

      const isPaymentRecorded = !!appointment.payments.find(
        (payment) => payment.payment.transactionId === transactionId,
      );

      if (!isPaymentRecorded) {
        recordPayment();
      }
    },
    [appointment?.id, recordPayment],
  );

  const handleSubmitSendItemizedReceipt = useCallback(
    async (data: SendItemizedReceiptFormValues) => {
      if (!appointment) {
        return;
      }

      try {
        await sendAppointmentInvoiceFx({
          locationId: appointment.location.id,
          appointmentId: appointment.id,
          payload: {
            emailTo: data.email,
          },
        });

        showAlert("Success! Itemized receipt has been sent.", {
          variant: "success",
        });
      } catch {
        showAlert("Error! Failed to send itemized receipt.", {
          variant: "error",
        });
      }
    },
    [appointment, showAlert],
  );

  const handleTryAgain = useCallback(() => {
    if (!appointment || isLoading) {
      return;
    }

    if (isSquare) {
      // const squareLocation: SquareLocation | undefined =
      //   squareLocations.find((squareLoc) => squareLoc.isDefault) ??
      //   squareLocations[0];

      // if (!squareLocation) {
      //   showAlert("Error! Square is not configured correctly in HW.", {
      //     variant: "error",
      //     autoHideDuration: null,
      //   });

      //   return;
      // }

      const deepLink = generateSquareDeepLink(
        appointment.balanceDue,
        "USD",
        system,
        // squareLocation.id,
        window.location.origin,
        String(appointment.id),
        generateSquareMetaData(appointment, location.state?.paymentBack),
      );

      if (deepLink) {
        window.location.href = deepLink;
      } else {
        showAlert("Available only on mobile devices.", {
          variant: "warning",
        });
      }
    } else {
      navigate(routePaths.jobDetailsPaymentsCollect(Number(appointmentId)), {
        state: location.state,
      });
    }
  }, [
    isLoading,
    system,
    appointment,
    appointmentId,
    isSquare,
    location.state,
    // squareLocations,
    navigate,
    showAlert,
  ]);

  const handleMarkJobAsCompleted = useCallback(async () => {
    if (!appointment?.id) {
      return;
    }

    setIsMarkingJobAsCompleted(true);

    try {
      await markAppointmentCompletedFx({
        locationId: appointment.location.id,
        appointmentId: appointment.id,
        type: appointment.type,
      });

      await addCompletedAction(
        appointment.id,
        appointment.location.id,
        CompletedActionsJobEnum.CompleteJob,
      );

      showAlert("Success! Job has been marked as completed.", {
        variant: "success",
      });

      navigate(routePaths.jobDetails(appointment.id));
    } catch {
      showAlert("Error! Failed to mark job as completed.", {
        variant: "success",
      });
    }

    setIsMarkingJobAsCompleted(false);
  }, [
    appointment?.location.id,
    appointment?.id,
    appointment?.type,
    addCompletedAction,
    navigate,
    showAlert,
  ]);

  useEffect(() => {
    if (!location.search || successSearch) {
      return;
    }

    const result = getTransactionResult(window.location.href, system);

    if (location.pathname === routePaths.squarePaymentResult() && result.meta) {
      navigate(
        `${routePaths.jobDetailsPaymentsResult(
          Number(result.meta.appointmentId),
        )}${location.search}`,
        {
          replace: true,
          state: {
            paymentBack: result.meta.paymentBack,
          },
        },
      );
      return;
    }

    if (
      result?.success &&
      (result.clientTransactionId || result.serverTransactionId)
    ) {
      setIsSuccess(true);

      if (result.clientTransactionId) {
        setClientTransactionId(result.clientTransactionId);
      }

      if (result.serverTransactionId) {
        setServerTransactionId(result.serverTransactionId);
      }
    } else if (!result?.success) {
      setErrorCode(result.errorCode);
      setIsSuccess(false);
    }
  }, [system, location.pathname, location.search, successSearch, navigate]);

  useEffect(() => {
    setPageName(pageName);
    setGoToBackUrl(goToBackUrl);
    setShowGoBackButton(true);
    setGoToBackOptions({ state: location.state });

    return () => {
      setPageName("");
      setGoToBackUrl("");
      setShowGoBackButton(false);
      setGoToBackOptions(undefined);
    };
  }, [
    pageName,
    goToBackUrl,
    location.state,
    setPageName,
    setGoToBackUrl,
    setShowGoBackButton,
    setGoToBackOptions,
  ]);

  useEffect(() => {
    const transactionId = serverTransactionId || clientTransactionId;

    if (transactionId && appointment?.id) {
      checkPayment(transactionId);
    }
  }, [clientTransactionId, serverTransactionId, appointment?.id]);

  // useEffect(() => {
  //   if (isSquare && locationId && locationId !== squareLocationsLocationId) {
  //     getSquareLocationsFx({
  //       locationId,
  //       forceProduction: squareForceProduction,
  //     });
  //   }
  // }, [isSquare, locationId]);

  useEffect(() => {
    if (locationId && Number(appointmentId)) {
      setAppointmentInitialLoading(true);

      getAppointmentFx({
        locationId,
        appointmentId: Number(appointmentId),
      });

      setAppointmentInitialLoading(false);
    }
  }, [locationId, appointmentId]);

  return (
    <>
      {!isMobile && <BackTitle onBack={handleGoBack} title={pageName} />}

      {isSuccess !== null && (
        <div className={styles.root}>
          <div className={styles.contentRoot}>
            <div className={styles.content}>
              <div className={styles.resultTextContainer}>
                <Typography variant="h4" color="secondary">
                  {resultTitle}
                </Typography>

                <Typography
                  variant="body2"
                  className={styles.resultDescription}
                >
                  {resultDescription}
                </Typography>
              </div>

              {isSuccess ? (
                <div className={styles.successSection}>
                  {isRecordPaymentError ? (
                    <Button
                      buttonType="twoTone"
                      fullWidth
                      isLoading={isRecordingPayment}
                      onClick={recordPayment}
                    >
                      Retry recording payment
                    </Button>
                  ) : !isLoading && !isRecordingPayment && !!appointment ? (
                    <SendItemizedReceiptForm
                      initialValues={{ email: appointment.account.email }}
                      onSubmit={handleSubmitSendItemizedReceipt}
                    />
                  ) : (
                    <SendItemizedReceiptFormSkeleton />
                  )}
                </div>
              ) : (
                <div className={styles.errorSection}>
                  <Button
                    buttonType="twoTone"
                    fullWidth
                    disabled={isLoading}
                    onClick={handleTryAgain}
                  >
                    Try again?
                  </Button>
                </div>
              )}
            </div>

            {errorCodeForDisplay && (
              <Typography
                variant="body1"
                align="center"
                className={styles.errorCode}
              >
                Error code: {errorCodeForDisplay}
              </Typography>
            )}
          </div>

          <ActionsFooter
            show={showMarkJobAsCompleted}
            actions={[
              {
                label: "Mark job as complete",
                buttonType: "filled",
                disabled: isDisableMarkJobAsCompleted,
                isLoading: isMarkingJobAsCompleted,
                onClick: handleMarkJobAsCompleted,
              },
            ]}
          />
        </div>
      )}
    </>
  );
});
