import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles({
  inputAdortmentRoot: {
    marginRight: "24px!important",
    marginBottom: "15px!important",
  },
  eyeIconButtonRoot: {
    padding: 8,
  },
  visibilityOffIcon: {
    marginBottom: 1,
  },
});
