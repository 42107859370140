import { memo } from "react";

import { useStyles } from "./styles";
import { TrainingModeBarProps } from "./types";

export const TrainingModeBar = memo(({ onClick }: TrainingModeBarProps) => {
  const styles = useStyles();

  return (
    <div className={styles.root} onClick={onClick}>
      <b>Training mode</b>: Changes will <b>NOT</b> be saved
    </div>
  );
});
