import { FC, memo } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { Paper } from "@chhjpackages/components";

import { GridViewIcon } from "shared/assets";

export const FiltersSkeleton: FC = memo(() => (
  <Paper
    variant="elevation"
    elevation={20}
    style={{
      display: "flex",
      flexDirection: "column",
    }}
    roundingValue={4}
  >
    <Box padding={2} mb={6} minWidth={248} width="100%">
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Grid container alignItems="flex-end" justify="space-between">
            <Grid item>
              <Typography variant="h4">Filters</Typography>
            </Grid>
            <Grid item>
              <GridViewIcon />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Skeleton
            variant="rect"
            animation="wave"
            width="100%"
            height={41}
            style={{
              borderRadius: 100,
            }}
          />
        </Grid>
        <Grid item>
          <Skeleton
            variant="rect"
            animation="wave"
            width="100%"
            height={41}
            style={{
              borderRadius: 100,
            }}
          />
        </Grid>
        <Grid item>
          <Skeleton
            variant="rect"
            animation="wave"
            width="100%"
            height={41}
            style={{
              borderRadius: 100,
            }}
          />
        </Grid>
        <Grid item>
          <Skeleton
            variant="rect"
            animation="wave"
            width="100%"
            height={41}
            style={{
              borderRadius: 100,
            }}
          />
        </Grid>
        <Grid item>
          <Skeleton
            variant="rect"
            animation="wave"
            width="100%"
            height={41}
            style={{
              borderRadius: 100,
            }}
          />
        </Grid>
        <Grid item>
          <Skeleton
            variant="rect"
            animation="wave"
            width="100%"
            height={41}
            style={{
              borderRadius: 100,
            }}
          />
        </Grid>
      </Grid>
    </Box>
  </Paper>
));
