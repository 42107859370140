import { colors } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  accordionHeader: {
    padding: "16px 0px",
  },
  accordionContent: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },
  note: {
    color: colors.grey160,
  },
  operationLogsSectionContent: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
}));
