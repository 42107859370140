import { useCallback, useState } from "react";
import { MenuItem } from "@material-ui/core";
import clsx from "clsx";
import moment from "moment";
import { CalendarRangePicker, Select, Tooltip } from "@chhjpackages/components";
import camelcase from "camelcase";

import { FilterButton, useStyles } from "../../styles";
import { FilterItem, FilterState } from "../types";
import { returnFilters } from "../utils";

export function useFilterItems<T>({
  initialFiltersState,
  resetFiltersState,
}: {
  initialFiltersState: FilterState;
  resetFiltersState?: FilterState;
}) {
  const styles = useStyles();

  const [state, setState] = useState<FilterState>(
    returnFilters() || initialFiltersState,
  );

  const setFilter = useCallback(
    ({
      field,
      value,
    }: {
      field: string;
      value: Date[] | (number | string)[] | boolean | number;
    }) => {
      if (Array.isArray(value) && (value as number[]).includes(-1)) {
        const lastElement = (value as number[])[(value as number[]).length - 1];

        setState({
          ...state,
          [field]: lastElement === -1 ? [-1] : [lastElement],
        });
      } else {
        setState({
          ...state,
          [field]: value,
        });
      }
    },
    [state],
  );

  const resetFilters = useCallback(() => {
    setState(resetFiltersState ?? initialFiltersState);
  }, [resetFiltersState, initialFiltersState]);

  const [visibleCalendar, setVisibleCalendar] = useState<null | string>(null);

  const toggleCalendar = useCallback(
    (filterName: string | null) =>
      setVisibleCalendar(visibleCalendar ? null : filterName),
    [visibleCalendar],
  );

  const renderSelectMultipleValue = useCallback(
    (count: number[], fieldName: string, isAll?: boolean) => {
      if (isAll) {
        return "All";
      }

      return `${fieldName} ${count?.length ? `(${count.length})` : ""}`;
    },
    [],
  );

  const renderSelectValue = useCallback(
    (isValueNotEmpty, value: string, fieldName: string) =>
      isValueNotEmpty ? value : fieldName,
    [],
  );

  const renderFilterItem = useCallback(
    (filter: FilterItem<T>) => {
      const fieldName = camelcase(filter.name);
      const selectValue = state[fieldName];

      const menuProps = {
        PaperProps: {
          style: {
            maxHeight: 250,
          },
        },
      };

      switch (filter?.type) {
        case "select":
          return (
            <Select
              variant="pill"
              size="large"
              fullWidth
              MenuProps={menuProps}
              className={clsx(
                styles.filterSelect,
                ((typeof selectValue === "number" && selectValue !== -1) ||
                  ((selectValue as number[])?.length &&
                    !(selectValue as number[]).includes(-1))) &&
                  styles.filterSelectActive,
              )}
              multiple={filter.multiple}
              value={
                Array.isArray(selectValue) && selectValue.length === 0
                  ? [-1]
                  : selectValue
              }
              onChange={(event) => {
                setFilter({
                  field: fieldName,
                  value: event.target.value as
                    | number
                    | boolean
                    | (string | number)[]
                    | Date[],
                });
              }}
              renderValue={(value) => {
                const count = value as number[];
                const isAll = Array.isArray(value) && value.includes(-1);

                return filter.multiple
                  ? renderSelectMultipleValue(count, filter.name, isAll)
                  : renderSelectValue(
                      state[fieldName],
                      String(
                        filter?.options?.find((option) => option.id === value)
                          ?.name,
                      ),
                      filter.name,
                    );
              }}
            >
              {filter?.options?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          );

        case "date":
          return (
            <Tooltip
              isClick
              isArrow
              interactive
              placement="bottom"
              title={
                <CalendarRangePicker
                  isRange={filter.dateRange}
                  onChange={(dates) => {
                    setFilter({ field: fieldName, value: dates });
                  }}
                  startDate={(state[fieldName] as Date[])?.[0]}
                  endDate={(state[fieldName] as Date[])?.[1]}
                />
              }
              open={visibleCalendar === fieldName}
              onClose={() => setVisibleCalendar(null)}
            >
              <FilterButton
                className={clsx(state[fieldName] && "active")}
                onClick={() => toggleCalendar(fieldName)}
              >
                {state[fieldName] ? (
                  <>
                    {(state[fieldName] as Date[])?.[0] &&
                      moment(
                        new Date((state[fieldName] as Date[])?.[0]),
                      ).format("MM/DD/YY")}
                    {(state[fieldName] as Date[])?.[1] &&
                      " - " +
                        moment(
                          new Date((state[fieldName] as Date[])?.[1]),
                        ).format("MM/DD/YY")}
                  </>
                ) : (
                  filter.name
                )}
              </FilterButton>
            </Tooltip>
          );

        default:
          return (
            <FilterButton
              className={clsx(state[fieldName] && "active")}
              onClick={() => {
                setFilter({ field: fieldName, value: !state[fieldName] });
              }}
            >
              {filter.name}
            </FilterButton>
          );
      }
    },
    [
      styles.filterSelect,
      styles.filterSelectActive,
      state,
      visibleCalendar,
      setFilter,
      renderSelectMultipleValue,
      renderSelectValue,
      toggleCalendar,
    ],
  );

  return { state, renderFilterItem, resetFilters, setFilter };
}
