import { memo, useCallback, useMemo } from "react";
import { Button } from "@chhjpackages/components";
import { useFieldArray, useWatch } from "react-hook-form";
import { Collapse } from "@material-ui/core";
import clsx from "clsx";

import { DamageCard } from "../damage-card";

import { useStyles } from "./styles";
import { DamageFormProps } from "./types";

export const DamageForm = memo(({ form: { control } }: DamageFormProps) => {
  const styles = useStyles();

  const {
    fields: damages,
    insert: insertDamage,
    update: updateDamage,
    remove: removeDamage,
  } = useFieldArray({
    control: control,
    shouldUnregister: false,
    name: "damages",
  });

  const damagesWatch = useWatch({ control, name: "damages" });

  const controlledDamages = useMemo(
    () =>
      damages.map((damage, index) => {
        return {
          ...damage,
          ...damagesWatch[index],
        };
      }),
    [damages, damagesWatch],
  );

  const shouldShowAddMore = useMemo(
    () => damagesWatch.length > 0 && !!damagesWatch[0]?.description,
    [damagesWatch],
  );

  const shouldShowDelete = useMemo(
    () =>
      damagesWatch.length > 1
        ? true
        : !!damagesWatch?.[0]?.description || !!damagesWatch?.[0]?.imageUrl,
    [damagesWatch],
  );

  const handleAddDamage = useCallback(() => {
    insertDamage(0, {
      damageId: null,
      description: "",
      file: null,
      imageUrl: null,
    });
  }, [insertDamage]);

  const handleDeleteDamage = useCallback(
    (id: string) => {
      const damageIndex = controlledDamages.findIndex(
        (damage) => damage.id === id,
      );

      removeDamage(damageIndex);

      if (controlledDamages.length === 1) {
        handleAddDamage();
      }
    },
    [controlledDamages, removeDamage, handleAddDamage],
  );

  const handleChangePhoto = useCallback(
    (index: number, file: File) => {
      updateDamage(index, {
        ...controlledDamages[index],
        file,
        imageUrl: URL.createObjectURL(file),
      });
    },
    [controlledDamages, updateDamage],
  );

  return (
    <div className={styles.root}>
      <Collapse in={shouldShowAddMore}>
        <div className={styles.addMoreContainer}>
          <Button
            buttonType="text"
            size="small"
            color="primary"
            className={clsx(!shouldShowAddMore && "pointerEventsNone")}
            classes={{ root: styles.addMoreButtonRoot }}
            onClick={handleAddDamage}
          >
            Add more
          </Button>
        </div>
      </Collapse>

      {controlledDamages.map((damage, index) => (
        <DamageCard
          key={damage.damageId || damage.id}
          control={control}
          index={index}
          imageUrl={damage.imageUrl ?? undefined}
          permissions={{
            hideDelete: !shouldShowDelete,
          }}
          onChangePhoto={(file) => handleChangePhoto(index, file)}
          onDelete={() => handleDeleteDamage(damage.id)}
        />
      ))}
    </div>
  );
});
