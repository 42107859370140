import { PricingResponse } from "features/appointment";

export enum PricingCategoriesNameEnum {
  All = "All categories",
  JunkRemoval = "Junk Removal",
  ExpressJunk = "Express Junk",
  GeneralLabor = "Labor",
  MoveLabor = "Move Labor",
  Moving = "Moving",
  MovingAccessorial = "Moving Accessories",
}

export enum PricingCategoriesEnum {
  All = -1,
  JunkRemoval = 1,
  ExpressJunk = 2,
  GeneralLabor = 3,
  MoveLabor = 4,
  Moving = 5,
  MovingAccessorial = 6,
}

export enum PricingTypesEnum {
  Basic = 1,
  Surcharges = 2,
  FlatRate = 3,
  Bedload = 4,
  Accessorial = 5,
  OneHour = 6,
  TwoHour = 7,
  ThreeHour = 8,
  FourHour = 9,
  Container = 10,
}

export namespace GetPricings {
  export type Params = {
    locationId: number;
    appointmentId: number;
    postal: string;
    categoryId: number;
    jobDate: string;
  };

  export type Response = {
    viewpricingbyproduct: PricingResponse[];
  };
}
