import { colors, RiftFont } from "@chhjpackages/components";
import {
  withStyles,
  createStyles,
  MenuItem,
  makeStyles,
} from "@material-ui/core";

export const MenuItemStyled = withStyles(() =>
  createStyles({
    root: {
      fontSize: 16,
      fontWeight: "bold",
      textTransform: "uppercase",
      "&:nth-child(odd)": {
        backgroundColor: `${colors.grey10}`,
      },
      padding: "8px 16px",
    },
    selected: {
      color: colors.white,
      backgroundColor: `${colors.primary.main}!important`,
      "&:hover": {
        color: colors.white,
        backgroundColor: `${colors.primary.dark}!important`,
      },
    },
  }),
)(MenuItem);

export const useStyles = makeStyles({
  select: {
    paddingLeft: 4,
    fontFamily: RiftFont,
    color: `${colors.primary.main}!important`,
    fontWeight: "bold",
    "& .MuiSelect-root": {
      padding: "3px 24px 3px 4px!important",
    },
    "& .MuiSelect-icon": {
      marginRight: "0px!important",
    },
    "& .MuiList-root": {
      padding: 0,
    },
  },
});
