import { colors } from "@chhjpackages/components";
import { SvgIcon } from "@material-ui/core";

export const ArrowTopIcon = ({
  className,
  color = colors.black,
  fontSize = 12,
}: {
  className?: string;
  color?: string;
  fontSize?: number;
}) => (
  <SvgIcon
    className={className}
    style={{ fontSize }}
    width="12"
    height="7"
    viewBox="0 0 12 7"
    fill="none"
  >
    <svg width="12" height="7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 6.00079C12.0007 5.86919 11.9754 5.73872 11.9254 5.61689C11.8754 5.49505 11.8017 5.38423 11.7085 5.29079L6.68361 0.290792C6.49531 0.104542 6.2406 0 5.9751 0C5.70959 0 5.45488 0.104542 5.26658 0.290792L0.241656 5.29079C0.0770127 5.48209 -0.00902012 5.72817 0.0007495 5.97985C0.0105191 6.23152 0.115371 6.47026 0.294354 6.64836C0.473336 6.82645 0.713265 6.93078 0.966196 6.9405C1.21913 6.95023 1.46643 6.86462 1.65868 6.70079L5.97007 2.41079L10.2815 6.70079C10.4214 6.84121 10.6001 6.93718 10.7949 6.97657C10.9897 7.01596 11.1918 6.99699 11.3757 6.92206C11.5597 6.84713 11.7172 6.71962 11.8283 6.55563C11.9394 6.39165 11.9991 6.19857 12 6.00079Z"
        fill={color}
      />
    </svg>
  </SvgIcon>
);
