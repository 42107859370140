import { useErrorHandler } from "react-error-boundary";

import { $errors } from "./store";

export const useErrorBoundary = () => {
  const handleError = useErrorHandler();

  $errors.watch((error) => {
    if (error) {
      handleError(error);
    }
  });
};
