import { fetcher } from "shared/libs";
import { SquareLocations } from "../types";

export const getSquareLocations = ({
  locationId,
  forceProduction,
}: SquareLocations.Get) =>
  fetcher.get<SquareLocations.Response>(`/square/locations/${locationId}`, {
    params: {
      ...(forceProduction && { force_production: forceProduction }),
    },
  });
