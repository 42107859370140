import { SvgIcon } from "@material-ui/core";
import { colors, SvgIconProps } from "@chhjpackages/components";

export const GridViewIcon = ({
  className,
  color = colors.grey140,
  fontSize = 14,
}: SvgIconProps) => (
  <SvgIcon
    className={className}
    style={{ fontSize }}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0V8.88889H8.88889V0H0ZM6.66667 6.66667H2.22222V2.22222H6.66667V6.66667ZM0 11.1111V20H8.88889V11.1111H0ZM6.66667 17.7778H2.22222V13.3333H6.66667V17.7778ZM11.1111 0V8.88889H20V0H11.1111ZM17.7778 6.66667H13.3333V2.22222H17.7778V6.66667ZM11.1111 11.1111V20H20V11.1111H11.1111ZM17.7778 17.7778H13.3333V13.3333H17.7778V17.7778Z"
      fill={color}
    />
  </SvgIcon>
);
