import { Theme, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(10, 1fr)",
    gridTemplateAreas: `
      'text text text text text text 1fr 1fr 1fr 1fr'
      'text text text text text text . . . .'
      'text text text text text text . . . .'
      'text text text text text text . . . .'
      'text text text text text text . . . .'
      '. . . . . . . . . .'
      '. . . . . . . . . .'`,
    gridGap: 20,
    minHeight: "100%",
    padding: 20,

    "@media (orientation: portrait)": {
      gridTemplateColumns: "repeat(8, 1fr)",
      gridTemplateAreas: `
      'text text text text text . . .'
      'text text text text text . . .'
      'text text text text text . . .'
      'text text text text text . . .'
      'text text text text text . . .'
      '. . . . . . . .'
      '. . . . . . . .'
      '. . . . . . . .'`,

      [theme.breakpoints.down("sm")]: {
        gridTemplateColumns: "repeat(7, 1fr)",
        gridTemplateAreas: `
        'text text text text . . .'
        'text text text text . . .'
        'text text text text . . .'
        'text text text text . . .'
        'text text text text . . .'
        '. . . . . . .'
        '. . . . . . .'
        '. . . . . . .'
        '. . . . . . .'`,
      },

      [theme.breakpoints.down("xs")]: {
        minHeight: "auto",
        gridTemplateColumns: "repeat(3, 1fr)",
        gridTemplateAreas: `
        'text text .'
        'text text .'
        'text text .'
        'text text .'
        'text text .'
        '. . .'
        '. . .'
        '. . .'
        '. . .'
        '. . .'
        '. . .'
        '. . .'
        '. . .'
        '. . .'
        '. . .'
        '. . .'
        '. . .'`,
      },
    },
  },

  text: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gridArea: "text",
    whiteSpace: "break-spaces",
  },

  logoBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
