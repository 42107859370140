import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";

import { DiscountFormProps, DiscountFormValues } from "../types";

const discountSchema = yup.object({
  type: yup.number().required(""),
  discount: yup
    .number()
    .required("Amount is required")
    .transform((_, originalValue) => {
      if (typeof originalValue === "number") {
        return originalValue;
      }
      return originalValue ? Number(originalValue) : undefined;
    }),
});

export const useDiscountForm = ({
  initialValues,
}: Pick<DiscountFormProps, "initialValues">) => {
  const { control, formState, handleSubmit, setValue, watch, reset } =
    useForm<DiscountFormValues>({
      defaultValues: {
        type: initialValues?.type.id,
        discount: initialValues?.amount,
      },
      mode: "all",
      resolver: yupResolver(discountSchema),
    });

  useEffect(() => {
    reset({
      type: initialValues?.type.id,
      discount: initialValues?.amount,
    });
  }, [initialValues, reset]);

  const typeWatch = watch("type");
  const discountWatch = watch("discount");

  useEffect(() => {
    if (typeWatch === 1 && discountWatch > 100) {
      setValue("discount", 100, { shouldDirty: true });
    }
  }, [typeWatch, discountWatch, setValue]);

  return { control, formState, setValue, watch, handleSubmit };
};
