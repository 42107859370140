import { memo } from "react";
import { Typography } from "@material-ui/core";
import { Checkbox } from "@chhjpackages/components";
import clsx from "clsx";

import { ValuationOptionCardProps } from "./types";
import { useStyles } from "./styles";

export const ValuationOptionCard = memo(
  ({ option, label, isSelected, onSelect }: ValuationOptionCardProps) => {
    const styles = useStyles();

    return (
      <div>
        <Checkbox
          variant="outline"
          checked={isSelected}
          label={label}
          className={clsx("checkbox", styles.checkbox)}
          onChange={onSelect}
        />

        <div className={styles.content}>
          <Typography variant="h4" color="secondary">
            {option.title}
          </Typography>

          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{
              __html: option.text,
            }}
            className={styles.text}
          />
        </div>
      </div>
    );
  },
);
