export namespace AppointmentStatus {
  export enum Estimate {
    NewLead = 1,
    Scheduled = 2,
    Completed = 3,
    BookedJob = 4,
    Lost = 5,
    Canceled = 7,
  }

  export enum Job {
    WaitingForApproval = 1,
    Scheduled = 2,
    Completed = 3,
    Closed = 4,
    Canceled = 5,
    Lost = 7,
  }
}

export enum NoteType {
  TYPE_OF_JUNK = 0,
  LOCATINO_OF_JUNK = 1,
  SPECIAL_INSTRUCTIONS = 2,
  MOVING_FACTORS = 3,
}
