import {
  createElement,
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import clsx from "clsx";
import { Button, colors } from "@chhjpackages/components";

import { StepGraphic, MainWrapper, useStyles } from "./assets";
import { GuideProps } from "./model";

export const GuideOne: FC<GuideProps> = memo(
  ({ stepsCount, selectedSlide, list, onClose }) => {
    const styles = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
      noSsr: true,
    });
    const minSwipeDistance = 50;
    const [currentId, setCurrentId] = useState(-1);
    useEffect(() => {
      setCurrentId(selectedSlide);
    }, []);

    useEffect(() => {
      if (currentId >= 0) {
        stepsCount(currentId);
      }
    }, [currentId, stepsCount]);
    const current = useMemo(() => list[currentId], [currentId, list]);

    const isFirst = useMemo(() => currentId === 0, [currentId]);

    const isLast = useMemo(
      () => currentId === list.length - 1,
      [currentId, list.length],
    );

    const onPrev = useCallback(
      () => !isFirst && setCurrentId(currentId - 1),
      [currentId, isFirst],
    );

    const onNext = useCallback(
      () => (!isLast ? setCurrentId(currentId + 1) : onClose()),
      [currentId, isLast],
    );

    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);

    const onTouchStart = useCallback((e) => {
      setTouchEnd(null);
      setTouchStart(e.targetTouches[0].clientX);
    }, []);

    const onTouchMove = useCallback((e) => {
      setTouchEnd(e.targetTouches[0].clientX);
    }, []);

    const onTouchEnd = useCallback(() => {
      if (!touchStart || !touchEnd) return;
      const distance = touchStart - touchEnd;
      const isLeftSwipe = distance > minSwipeDistance;
      const isRightSwipe = distance < -minSwipeDistance;
      if (isLeftSwipe) {
        onNext();
      }
      if (isRightSwipe) {
        onPrev();
      }
    }, [onNext, onPrev, touchStart, touchEnd]);

    return (
      <div>
        {currentId >= 0 && (
          <MainWrapper
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
          >
            <StepGraphic>{createElement(current.img)}</StepGraphic>

            <Box mt={isMobile ? "0px" : "179px"}>
              <Box
                fontSize={{ xs: 42, sm: 42, md: 42, lg: 42 }}
                lineHeight={{ xs: 1.25, sm: 1.25, md: 1.25, lg: 1.25 }}
                color={colors.grey100}
              >
                <Typography
                  variant="h3"
                  component="div"
                  align="center"
                  color="inherit"
                >
                  {current.heading1}
                </Typography>
              </Box>
              <Box
                fontSize={{ xs: 42, sm: 42, md: 42, lg: 42 }}
                lineHeight={{ xs: 1.25, sm: 1.25, md: 1.25, lg: 1.25 }}
                color={colors.grey180}
              >
                <Typography
                  variant="h3"
                  component="div"
                  color="inherit"
                  align="center"
                >
                  {current.heading2}
                </Typography>
              </Box>
            </Box>

            <Box className={styles.details}>
              <Typography variant="body1" component="div" align="center">
                {current.details}
              </Typography>
            </Box>

            <Box className={styles.bulletNav}>
              <Box mr={1} className={styles.bulletButton}>
                <Button
                  color="primary"
                  fullWidth
                  disabled={isFirst}
                  onClick={onPrev}
                >
                  Back
                </Button>
              </Box>

              <Box display="flex">
                {Array.from(
                  Array(list.length < 10 ? list.length : 10).keys(),
                ).map((item, index) => (
                  <Box
                    key={index}
                    className={clsx(
                      styles.bullet,
                      (index === currentId || (index === 9 && currentId > 9)) &&
                        "active",
                    )}
                  ></Box>
                ))}
              </Box>

              <Box ml={1} className={styles.bulletButton}>
                <Button color="primary" fullWidth onClick={onNext}>
                  {isLast ? "Close" : "Next"}
                </Button>
              </Box>
            </Box>

            {list.length > 10 && (
              <Typography
                variant="h5"
                component="div"
                align="center"
                className={styles.bulletText}
              >
                STEP {currentId + 1}/{list.length}
              </Typography>
            )}
          </MainWrapper>
        )}
      </div>
    );
  },
);
