import { Checkbox, Paper } from "@chhjpackages/components";
import { Divider, Typography } from "@material-ui/core";
import { memo } from "react";
import clsx from "clsx";

import { useStyles } from "./assets";
import { CartTotalProps } from "./model";
import { PriceItem } from "./ui";

export const CartTotal = memo(
  ({
    cartTotal,
    chargeAdminFee,
    paid,
    adminFee,
    taxes,
    tips,
    balanceDue,
    permissions,
    onChangeAdminFee,
  }: CartTotalProps) => {
    const styles = useStyles();

    return (
      <Paper elevation={16} className={styles.root}>
        <div className={styles.itemLine}>
          <Typography variant="h4" component="div">
            Cart total:
          </Typography>
          <Typography variant="h4" component="div">
            ${cartTotal.toFixed(2)}
          </Typography>
        </div>
        {!permissions?.hideChargeAdminFee && (
          <div className={styles.checkboxWrapper}>
            <Checkbox
              variant="outline"
              checked={chargeAdminFee}
              label="Charge admin fee"
              onChange={onChangeAdminFee}
            />
          </div>
        )}
        <div>
          <PriceItem
            label="Prior payments"
            value={paid}
            valuePrefix={paid > 0 ? "-" : ""}
          />
          {!permissions?.hideChargeAdminFee && (
            <PriceItem label="Admin fee" value={adminFee} />
          )}
          <PriceItem label="Taxes" value={taxes} />
          {!permissions?.hideTips && <PriceItem label="Tips" value={tips} />}
        </div>
        <div className={clsx(styles.dividerContainer)}>
          <Divider />
        </div>
        <div className={clsx(styles.itemLine, styles.balanceDueLine)}>
          <Typography
            variant="body1"
            component="div"
            className={styles.balanceDue}
          >
            Balance due:
          </Typography>
          <Typography
            variant="body1"
            color="secondary"
            component="div"
            className={styles.balanceDueValue}
          >
            <b>${balanceDue.toFixed(2)}</b>
          </Typography>
        </div>
      </Paper>
    );
  },
);
