export const salesData = (rankData: any) => {
  return (
    rankData.sales?.map((item: any) => {
      return {
        username: item.username,
        userProfileUrl: item.userUrl,
        jobValue: item.sales,
      };
    }) || []
  );
};

export const completionPercentData = (rankData: any) => {
  return (
    rankData.completion_percent?.map((item: any) => {
      return {
        username: item.username,
        userProfileUrl: item.userUrl,
        jobValue: item.completion_percent,
      };
    }) || []
  );
};

export const averageData = (rankData: any) => {
  return (
    rankData.average_job?.map((item: any) => {
      return {
        username: item.username,
        userProfileUrl: item.userUrl,
        jobValue: item.average_job,
      };
    }) || []
  );
};

export const salesPerHourData = (rankData: any) => {
  return (
    rankData.sales_per_hour?.map((item: any) => {
      return {
        username: item.username,
        userProfileUrl: item.userUrl,
        jobValue: item.sales_per_hour,
      };
    }) || []
  );
};
