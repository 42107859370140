import moment from "moment";
import * as yup from "yup";

import { USADateFormat } from "shared/utils";
import { PaymentMethodsEnum } from "../types";

export const getPaymentFormsShema = (isManual: boolean) =>
  yup.object({
    method: yup
      .object({
        label: yup.string(),
        value: yup.number(),
      })
      .nullable()
      .required("Payment method is required"),
    amount: yup.string().trim().required("Payment amount is required"),
    terminal: yup
      .object({
        label: yup.string(),
        value: yup.string(),
      })
      .nullable()
      .when(["method.value"], {
        is: (method?: number) =>
          method === PaymentMethodsEnum.CreditCard && !isManual,
        then: (schema) => schema.required("Terminal is required"),
        otherwise: (schema) => schema,
      }),
    paymentDate: yup
      .date()
      .typeError("Date must be in MM/DD/YYYY format")
      .max(
        new Date(),
        `Payment date must be earlier than ${moment()
          .add(1, "day")
          .format(USADateFormat)}`,
      )
      .transform((_, originalValue) => {
        if (!originalValue) {
          return undefined;
        }
        if (originalValue.length < 8) {
          return null;
        }
        const date = moment(originalValue, USADateFormat).toDate();
        return date.getTime() ? date : undefined;
      })
      .when(["method.value"], {
        is: (method?: number) =>
          method !== PaymentMethodsEnum.CreditCard || isManual,
        then: (schema) => schema.required("Payment date is required"),
        otherwise: (schema) => schema,
      }),
    checkNumber: yup
      .string()
      .trim()
      .when(["method.value"], {
        is: (method?: number) => method === PaymentMethodsEnum.Check,
        then: (schema) => schema.required("Check number is required"),
        otherwise: (schema) => schema,
      }),
    depositDate: yup
      .date()
      .typeError("Date must be in MM/DD/YYYY format")
      .max(
        new Date(),
        `Deposit date must be earlier than ${moment()
          .add(1, "day")
          .format(USADateFormat)}`,
      )
      .transform((_, originalValue) => {
        if (!originalValue) {
          return undefined;
        }
        if (originalValue.length < 8) {
          return null;
        }
        const date = moment(originalValue, USADateFormat).toDate();
        return date.getTime() ? date : undefined;
      })
      .when(["method.value"], {
        is: (method?: number) =>
          method === PaymentMethodsEnum.Check ||
          method === PaymentMethodsEnum.Cash,
        then: (schema) => schema.required("Deposit date is required"),
        otherwise: (schema) => schema,
      }),
    depositNumber: yup.string().trim(),
    notes: yup.string().trim(),
    cardType: yup
      .object({
        label: yup.string(),
        value: yup.number(),
      })
      .nullable()
      .when(["method.value"], {
        is: (method?: number) =>
          method === PaymentMethodsEnum.CreditCard && isManual,
        then: (schema) => schema.required("Card type is required"),
        otherwise: (schema) => schema,
      }),
    cardNumber: yup
      .string()
      .trim()
      .max(4, "Maximum 4 digits")
      .when(["method.value"], {
        is: (method?: number) =>
          method === PaymentMethodsEnum.CreditCard && isManual,
        then: (schema) => schema.required("Card number is required"),
        otherwise: (schema) => schema,
      })
      .when((cardNumber, schema) => {
        if ((cardNumber?.length ?? 0) > 0) {
          return schema.min(4, "Minimum 4 digits");
        }
      }),
    cardName: yup.string().trim(),
    expMonth: yup
      .object({
        label: yup.string(),
        value: yup.number(),
      })
      .nullable()
      .when(["method.value"], {
        is: (method?: number) =>
          method === PaymentMethodsEnum.CreditCard && isManual,
        then: (schema) => schema.required("Exp. month is required"),
        otherwise: (schema) => schema,
      }),
    expYear: yup
      .object({
        label: yup.string(),
        value: yup.number(),
      })
      .nullable()
      .when(["method.value"], {
        is: (method?: number) =>
          method === PaymentMethodsEnum.CreditCard && isManual,
        then: (schema) => schema.required("Exp. year is required"),
        otherwise: (schema) => schema,
      }),
    address1: yup.string().trim(),
    address2: yup.string().trim(),
    zip: yup.string().trim(),
    transactionId: yup.string().trim(),
    authCode: yup.string().trim(),
  });
