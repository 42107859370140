import { ReactComponent as preArrivalImg } from "../../assets/images/pre-arrival.svg";
import { ReactComponent as arrivalImg } from "../../assets/images/arrival.svg";
import { ReactComponent as approachImg } from "../../assets/images/approach.svg";
import { ReactComponent as atTheDoorImg } from "../../assets/images/at-the-door.svg";
import { ReactComponent as theWalkThroughImg } from "../../assets/images/the-walk-through.svg";
import { ReactComponent as buildWalveImg } from "../../assets/images/build-walve.svg";
import { ReactComponent as performImg } from "../../assets/images/perform.svg";
import { ReactComponent as completeTheWowImg } from "../../assets/images/complete-the-wow.svg";
import { ReactComponent as closeTheJobImg } from "../../assets/images/close-the-job.svg";
import { ReactComponent as reviewsImg } from "../../assets/images/reviews.svg";
import { ReactComponent as goodByeImg } from "../../assets/images/good-bye.svg";
import { ReactComponent as setupImg } from "../../assets/images/setup.svg";
import { ReactComponent as preLoadImg } from "../../assets/images/pre-load.svg";
import { ReactComponent as unloadImg } from "../../assets/images/unload.svg";

export const guideMoveList = [
  {
    heading1: "1/14",
    heading2: "Pre-arrival",
    details:
      "Passenger calls the client 20-30 mins. prior to arrival window as well as 20-30 mins. before arrival to give an updated time and peace of mind. Crew App sends the text with HUNK pictures.",
    img: preArrivalImg,
  },
  {
    heading1: "2/14",
    heading2: "arrival",
    details:
      "We park on the street unless given permission to park in driveway – The passenger always gets out of truck to guide driver if backing up.",
    img: arrivalImg,
  },
  {
    heading1: "3/14",
    heading2: "the approach",
    details:
      "We  carry  our  binder  like  a  scholar, we knock at the door and  then  take  take  one  step  back from doorway.",
    img: approachImg,
  },
  {
    heading1: "4/14",
    heading2: "At the door",
    details:
      "The captain introduces himself and wingman and identifies that they are with College HUNKS. We wait to be invited into the residence.",
    img: atTheDoorImg,
  },
  {
    heading1: "5/14",
    heading2: "Build value",
    details:
      "We present the Junk Binder presentation and deliver the HUNKS Introduction Script. We explain HUNKS acronym and share college affiliation.",
    img: buildWalveImg,
  },
  {
    heading1: "6/14",
    heading2: "The walktrough",
    details:
      "We  assess  the  items  the  client  is  looking  to  have  moved. The  Captain will then go through all the  paperwork  with  the  Client  and  receive  all  required  signatures",
    img: theWalkThroughImg,
  },
  {
    heading1: "7/14",
    heading2: "setup & inspection",
    details: "HUNKS inspect furniture to move and set up.",
    img: setupImg,
  },
  {
    heading1: "8/14",
    heading2: "pre-load",
    details:
      "HUNKS incorporate the five points of protection when wrapping furniture. Cover ALL finished surfaces with a blanket.",
    img: preLoadImg,
  },
  {
    heading1: "9/14",
    heading2: "load",
    details:
      "HUNKS  will  then  start  to  load  the truck in this order for an efficient and smooth unload: Boxes > Beds > Base > Miscellaneous",
    img: performImg,
  },
  {
    heading1: "10/14",
    heading2: "unload",
    details:
      "Upon arrival at the destination, HUNKS will conduct a walkthrough to   maximize efficiency throughout the unload. HUNKS will set up home protection to increase client security  and ensure a smooth and safe unload.",
    img: unloadImg,
  },
  {
    heading1: "11/14",
    heading2: "Complete the wow",
    details:
      "Sweep and clean up leftover debris both inside the house and near the truck.",
    img: completeTheWowImg,
  },
  {
    heading1: "12/14",
    heading2: "Close the job",
    details:
      "We ask “Is there anything else we can do to provide a Stress-Free Day?” Collect payment in Crew App.",
    img: closeTheJobImg,
  },
  {
    heading1: "13/14",
    heading2: "reviews, picture & sign",
    details:
      "After a positive experience, ask to leave a yard sign and for a review. Ask to take a picture with them outside the truck to post on their social media. Request they tag us.",
    img: reviewsImg,
  },
  {
    heading1: "14/14",
    heading2: "Good bye",
    details:
      "We thank the client for using College HUNKS Hauling Junk. We perform 10 Up and Down with doorhangers.",
    img: goodByeImg,
  },
];
