import { Box, Typography } from "@material-ui/core";
import moment from "moment";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  TimePicker,
  TimePickerSchedule,
} from "@chhjpackages/components";

import { FormInput, FormSelect } from "shared/ui";
import {
  apiDateFormat,
  apiDateTimeFormat,
  groupBy,
  USADateFormat,
} from "shared/utils";
import {
  AvailabilityPeriodsEnum,
  getAvailabilitiesFx,
} from "features/availabilities";
import { AppointmentTypesEnum } from "features/appointment/model";

import { ConvertToJobFormProps, ConvertToJobFormValues } from "./model";

const defaultValues = {
  date: moment().format(USADateFormat),
  interval: 1,
};

const convertToJobFormSchema = yup.object({
  date: yup
    .string()
    .typeError("Date must be in MM/DD/YYYY format")
    .required("Date is required")
    .transform((_, originalValue) => {
      if (originalValue.length < 8) {
        return null;
      }
      const date = moment(originalValue, USADateFormat).toDate();
      return date.getTime() ? originalValue : undefined;
    }),
  interval: yup.number().required("Hours needed is required"),
  startDate: yup.date(),
  endDate: yup.date(),
});

export const ConvertToJobForm = memo(
  ({
    appointment,
    availabilities,
    loading,
    onSubmit,
  }: ConvertToJobFormProps) => {
    const { control, formState, watch, setValue, handleSubmit } =
      useForm<ConvertToJobFormValues>({
        mode: "all",
        defaultValues: appointment?.startDate
          ? {
              date: moment(appointment.startDate).format(USADateFormat),
              interval: moment
                .duration(
                  moment(appointment.endDate, apiDateTimeFormat).diff(
                    appointment.startDate,
                  ),
                )
                .asHours(),
              startDate: moment(
                appointment.startDate,
                apiDateTimeFormat,
              ).toDate(),
              endDate: moment(appointment.endDate, apiDateTimeFormat).toDate(),
            }
          : defaultValues,
        resolver: yupResolver(convertToJobFormSchema),
      });

    const dateWatch = watch("date");
    const intervalWatch = watch("interval");

    const [currentPeriod, setCurrentPeriod] = useState<AvailabilityPeriodsEnum>(
      AvailabilityPeriodsEnum.Morning,
    );
    const [selectedAvailability, setSelectedAvailability] =
      useState<TimePickerSchedule | null>(null);

    const periodOptions = useMemo(
      () => [
        AvailabilityPeriodsEnum.Morning,
        AvailabilityPeriodsEnum.Afternoon,
        AvailabilityPeriodsEnum.Evening,
      ],
      [],
    );

    const intervalOptions = useMemo(() => {
      let step = 0.75;

      const options = Array.from({ length: 44 }, () => {
        step += 0.25;

        return step;
      });

      if (!options.includes(intervalWatch)) {
        options.push(intervalWatch);
      }

      return options;
    }, [intervalWatch]);

    const groupedAvailabilities = useMemo(
      () =>
        groupBy(
          availabilities.map((availability) => ({
            start: moment(availability.schedule.start).toDate(),
            end: moment(availability.schedule.end).toDate(),
            friendly: availability.schedule.friendly,
            period: availability.schedule.period,
            resourceId: availability.resource.id,
            selected:
              moment(availability.schedule.start).isSame(
                selectedAvailability?.start,
              ) &&
              moment(availability.schedule.end).isSame(
                selectedAvailability?.end,
              )
                ? true
                : false,
          })),
          (availability) => availability.period,
        ),
      [availabilities, selectedAvailability],
    );

    const availabilitiesForDisplay = useMemo(
      () => groupedAvailabilities[`${currentPeriod}`] ?? [],
      [groupedAvailabilities, currentPeriod],
    );

    const changePeriod = useCallback(
      (changing: "add" | "decrease") => {
        const currentPeriodIndex = periodOptions.findIndex(
          (period) => period === currentPeriod,
        );

        if (changing === "add") {
          if (currentPeriodIndex === periodOptions.length - 1) {
            setCurrentPeriod(periodOptions[0]);
          } else {
            setCurrentPeriod(periodOptions[currentPeriodIndex + 1]);
          }
        }

        if (changing === "decrease") {
          if (currentPeriodIndex === 0) {
            setCurrentPeriod(periodOptions[periodOptions.length - 1]);
          } else {
            setCurrentPeriod(periodOptions[currentPeriodIndex - 1]);
          }
        }
      },
      [currentPeriod, periodOptions],
    );

    const onPickSchedule = useCallback((schedule: TimePickerSchedule) => {
      setSelectedAvailability(schedule);
    }, []);

    useEffect(() => {
      if (selectedAvailability) {
        setValue("startDate", moment(selectedAvailability.start).toDate());
        setValue("endDate", moment(selectedAvailability.end).toDate());
        setValue("truckId", selectedAvailability.resourceId);
      } else {
        setValue("startDate", undefined);
        setValue("endDate", undefined);
        setValue("truckId", undefined);
      }
    }, [selectedAvailability, setValue]);

    useEffect(() => {
      if (appointment && formState.isValid) {
        getAvailabilitiesFx({
          locationId: appointment.location.id,
          payload: {
            type: AppointmentTypesEnum.JOB,
            categoryId: appointment.category.id,
            viewDate: moment(dateWatch, USADateFormat).format(apiDateFormat),
            zoneId: appointment.zone.id || 0,
            duration: intervalWatch,
            numberOfDays: 1,
          },
        });
        setSelectedAvailability(null);
      }
    }, [dateWatch, intervalWatch, appointment, formState.isValid]);

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="body1">
          Select a date to check our availability
        </Typography>
        <Box mt={2}>
          <FormInput
            name="date"
            control={control}
            calendarAdornment
            dateFormat="MM/DD/YYYY"
            label="Select job date"
            mask="date"
          />
        </Box>
        <Box mt={2}>
          <FormSelect
            name="interval"
            label="Hours needed"
            control={control}
            options={intervalOptions}
          />
        </Box>
        <Box mt={4}>
          <Typography variant="body1">
            Select the arrival window that works best for you for our HUNKS to
            arrive
          </Typography>
        </Box>
        <Box mt={3}>
          <TimePicker
            period={currentPeriod}
            schedule={availabilitiesForDisplay}
            onTimeSelected={onPickSchedule}
            onChangePeriod={changePeriod}
            isLoading={loading}
          />
        </Box>
        <Box mt={4}>
          <Button
            buttonType="twoTone"
            fullWidth
            type="submit"
            size="large"
            isLoading={formState.isSubmitting}
            disabled={!formState.isValid || !selectedAvailability}
          >
            Confirm converting
          </Button>
        </Box>
      </form>
    );
  },
);
