import { makeStyles } from "@material-ui/core";
import { colors } from "@chhjpackages/components";

export const useStyles = makeStyles(() => ({
  chipProgress: {
    backgroundColor: colors.functionals.infoLight,
    color: colors.functionals.info,
  },
  chipBeDone: {
    backgroundColor: colors.grey10,
    color: colors.grey140,
  },
}));
