import { makeStyles } from "@material-ui/core";
import { colors } from "@chhjpackages/components";

export const useStyles = makeStyles(() => ({
  mainBox: {
    display: "flex",
    alignItems: "center",
    padding: "6px",
    borderRadius: "4px",
    backgroundColor: colors.white,
    boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.15)",
  },
  warningIcon: {
    margin: "0 18px",
    height: "18px",
    width: "18px",
  },
  connectionIcon: {
    margin: "0 19px",
    height: "24px",
    width: "24px",
  },
  bodyText: {
    color: colors.grey140,
  },
}));
