import { Button } from "@chhjpackages/components";
import { Box } from "@material-ui/core";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { memo } from "react";

import { FormInput } from "shared/ui";

import { SendInvoiceFormProps, SendInvoiceFormValues } from "./model";

const sendInvoiceFormSchema = yup.object({
  email: yup
    .string()
    .required("Email is required")
    .email("Must be a valid email"),
});

export const SendInvoiceForm = memo(
  ({ initialValues, onSubmit, onClose }: SendInvoiceFormProps) => {
    const {
      control,
      formState: { isSubmitting, isValid },
      handleSubmit,
    } = useForm<SendInvoiceFormValues>({
      mode: "all",
      defaultValues: initialValues,
      resolver: yupResolver(sendInvoiceFormSchema),
    });

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          control={control}
          name="email"
          label="Email or mobile phone number"
          required
        />
        <Box
          mt={3}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Box>
            <Button buttonType="text" color="primary" onClick={onClose}>
              Exit
            </Button>
          </Box>
          <Box width={136} ml={1}>
            <Button
              buttonType="twoTone"
              fullWidth
              type="submit"
              isLoading={isSubmitting}
              disabled={!isValid}
            >
              Send
            </Button>
          </Box>
        </Box>
      </form>
    );
  },
);
