import { ApiResponseBase, DataItem } from "shared/types";

export enum AvailabilityPeriodsEnum {
  Morning = "morning",
  Afternoon = "afternoon",
  Evening = "evening",
}

export type Availability = {
  resource: DataItem;
  schedule: {
    end: string;
    friendly: string;
    period: AvailabilityPeriodsEnum;
    start: string;
  };
};

export namespace GetAvailabilities {
  type Payload = {
    type: string;
    categoryId: number;
    viewDate: string;
    postal?: string;
    duration?: number;
    numberOfDays?: number;
    zoneId?: number;
    truckId?: number;
    excludeAppointments?: number[];
  };

  export type Params = {
    locationId: number;
    payload: Payload;
  };

  export type Response = {
    availability: Availability[];
  } & ApiResponseBase;
}
