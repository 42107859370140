import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect } from "react";

import { LoginFormProps } from "../types";

export const loginValidationSchema = yup.object({
  username: yup.string().required("Username is required"),
  password: yup.string().required("Password is required"),
});

export const useLoginForm = ({
  onSubmit,
}: {
  onSubmit: LoginFormProps["onSubmit"];
}) => {
  const { control, formState, watch, setError, trigger, handleSubmit } =
    useForm({
      defaultValues: {
        username: "",
        password: "",
      },
      resolver: yupResolver(loginValidationSchema),
      mode: "all",
    });

  const usernameWatched = watch("username");

  const onHandleSubmit = useCallback(
    async (data) => {
      try {
        await onSubmit(data);
      } catch {
        setError("password", {
          message: "Username & password not accepted",
        });
      }
    },
    [onSubmit, setError],
  );

  useEffect(() => {
    if (formState.errors.password) {
      trigger("password");
    }
  }, [usernameWatched]);

  return {
    control,
    formState,
    handleSubmit,
    onHandleSubmit,
  };
};
