import { colors } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  selected: {
    outline: `1px solid ${colors.primary.main}`,
  },
  section: {
    display: "flex",
    padding: 16,
    gap: 16,

    "& > div": {
      flex: 1,
    },
  },
  itemTitle: {
    color: colors.grey140,
    fontWeight: "bold",
  },
  itemDescription: {
    color: colors.grey160,
  },
  buttonRoot: {
    borderRadius: 0,
  },
}));
