import { useMemo } from "react";
import { useStore } from "effector-react/compat";

import { $permissionsStore } from "../store";

export const usePermissions = () => {
  const { permissions, loading } = useStore($permissionsStore);

  const addOnSettings = useMemo(
    () => ({
      add: permissions?.["addOnSettings/editAddOnSettings"],
      edit: permissions?.["addOnSettings/editAddOnSettings"],
      view: permissions?.["addOnSettings/viewAddOnSettings"],
    }),
    [permissions],
  );

  const administration = useMemo(
    () => ({
      advancedMoveSettings:
        permissions?.["administration/advancedMoveSettings"],
      alertManagement: permissions?.["administration/alertManagement"],
      announcementCreator: permissions?.["administration/announcementCreator"],
      assignPartnerAccounts:
        permissions?.["administration/assignPartnerAccounts"],
      attachmentManagement:
        permissions?.["administration/attachmentManagement"],
      editProductsAfterRoyalties:
        permissions?.["administration/editProductsAfterRoyalties"],
      moveJobsToAnotherLocation:
        permissions?.["administration/moveJobsToAnotherLocation"],
      royaltyReporting: permissions?.["administration/royaltyReporting"],
      slcPrePaymentCollection:
        permissions?.["administration/slcPrePaymentCollection"],
      teamMemberManagement:
        permissions?.["administration/teamMemberManagement"],
      ticketQueue: permissions?.["administration/ticketQueue"],
    }),
    [permissions],
  );

  const administrationPrimaryTerritories = useMemo(
    () => ({
      add: permissions?.["administration/addPrimaryTerritories"],
      edit: permissions?.["administration/editPrimaryTerritories"],
      delete: permissions?.["administration/deletePrimaryTerritories"],
    }),
    [permissions],
  );

  const administrationSecondaryTerritories = useMemo(
    () => ({
      add: permissions?.["administration/addSecondaryTerritories"],
      edit: permissions?.["administration/editSecondaryTerritories"],
      delete: permissions?.["administration/deleteSecondaryTerritories"],
    }),
    [permissions],
  );

  const administrationLocation = useMemo(
    () => ({
      add: permissions?.["administration/createLocations"],
      edit: permissions?.["administration/editLocations"],
    }),
    [permissions],
  );

  const administrationReviewSettings = useMemo(
    () => ({
      edit: permissions?.["administration/editReviewSettings"],
      view: permissions?.["administration/viewReviewSettings"],
    }),
    [permissions],
  );

  const appSettings = useMemo(
    () => ({
      edit: permissions?.["appSettings/editAppSettings"],
      view: permissions?.["appSettings/viewAppSettings"],
    }),
    [permissions],
  );

  const arrivalWindows = useMemo(
    () => ({
      ManageArrivalWindows:
        permissions?.["arrivalWindows/manageArrivalWindows"],
    }),
    [permissions],
  );

  const billOfLadingSettings = useMemo(
    () => ({
      edit: permissions?.["billOfLadingSettings/editBillOfLadingSettings"],
      view: permissions?.["billOfLadingSettings/viewBillOfLadingSettings"],
    }),
    [permissions],
  );

  const callRecordings = useMemo(
    () => ({
      searchRecordings: permissions?.["callRecordings/searchRecordings"],
    }),
    [permissions],
  );

  const calls = useMemo(
    () => ({
      list: permissions?.["calls/listCalls"],
    }),
    [permissions],
  );

  const campaignManagement = useMemo(
    () => ({
      add: permissions?.["campaignManagement/createCampaigns"],
      list: permissions?.["campaignManagement/listCampaigns"],
    }),
    [permissions],
  );

  const claims = useMemo(
    () => ({
      list: permissions?.["claims/listClaims"],
      resolve: permissions?.["claims/resolveClaims"],
      view: permissions?.["claims/viewClaims"],
    }),
    [permissions],
  );

  const clients = useMemo(
    () => ({
      edit: permissions?.["clients/editClients"],
      merge: permissions?.["clients/mergeClients"],
      print: permissions?.["clients/printClients"],
      send: permissions?.["clients/sendClients"],
      view: permissions?.["clients/viewClients"],
      list: permissions?.["clients/listClients"],
      listCalls: permissions?.["clients/listCalls"],
      listEstimates: permissions?.["clients/listEstimates"],
      listJobs: permissions?.["clients/listJobs"],
      listPendingRequests: permissions?.["clients/listPendingRequests"],
      listQuotes: permissions?.["clients/listQuotes"],
    }),
    [permissions],
  );

  const clientsAlerts = useMemo(
    () => ({
      add: permissions?.["clients/addAlerts"],
      edit: permissions?.["clients/editAlerts"],
    }),
    [permissions],
  );

  const clientsCommunications = useMemo(
    () => ({
      add: permissions?.["clients/createCommunications"],
      edit: permissions?.["clients/editCommunications"],
      list: permissions?.["clients/listCommunications"],
    }),
    [permissions],
  );

  const contactManagement = useMemo(
    () => ({
      edit: permissions?.["contactManagement/editContacts"],
      list: permissions?.["contactManagement/editContacts"],
    }),
    [permissions],
  );

  const dailyCompanyLog = useMemo(
    () => ({
      editHours: permissions?.["dailyCompanyLog/editHours"],
      generateLogs: permissions?.["dailyCompanyLog/generateLogs"],
      listLogs: permissions?.["dailyCompanyLog/listLogs"],
    }),
    [permissions],
  );

  const dailyTruckLogExpenses = useMemo(
    () => ({
      add: permissions?.["dailyTruckLog/createExpenses"],
      delete: permissions?.["dailyTruckLog/deleteExpenses"],
      list: permissions?.["dailyTruckLog/listExpenses"],
    }),
    [permissions],
  );

  const estimates = useMemo(
    () => ({
      add: permissions?.["estimates/createEstimates"],
      list: permissions?.["estimates/listEstimates"],
      view: permissions?.["estimates/viewEstimates"],
      reschedule: permissions?.["estimates/rescheduleEstimates"],
      changeCategory: permissions?.["estimates/changeCategory"],
      changePrimaryTeam: permissions?.["estimates/changePrimaryTeam"],
      changeStatus: permissions?.["estimates/changeStatus"],
      convertToJob: permissions?.["estimates/convertToJob"],
      listCalls: permissions?.["estimates/listCalls"],
      listCompletedActions: permissions?.["estimates/listCompletedActions"],
    }),
    [permissions],
  );

  const estimatesAttachments = useMemo(
    () => ({
      add: permissions?.["estimates/addAttachments"],
      edit: permissions?.["estimates/editAttachments"],
      delete: permissions?.["estimates/deleteAttachments"],
      list: permissions?.["estimates/listAttachments"],
    }),
    [permissions],
  );

  const estimatesFollowUps = useMemo(
    () => ({
      add: permissions?.["estimates/addFollowUps"],
      edit: permissions?.["estimates/editFollowUps"],
      delete: permissions?.["estimates/deleteFollowUps"],
      list: permissions?.["estimates/listFollowUps"],
    }),
    [permissions],
  );

  const estimatesTimesheets = useMemo(
    () => ({
      add: permissions?.["estimates/addTimesheets"],
      delete: permissions?.["estimates/deleteTimesheets"],
      list: permissions?.["estimates/listTimesheets"],
    }),
    [permissions],
  );

  const estimatesCommunications = useMemo(
    () => ({
      add: permissions?.["estimates/createCommunications"],
      edit: permissions?.["estimates/editCommunications"],
      list: permissions?.["estimates/listCommunications"],
    }),
    [permissions],
  );

  const estimatesDispatchMessages = useMemo(
    () => ({
      customize: permissions?.["estimates/customizeDispatchMessages"],
      send: permissions?.["estimates/sendDispatchMessages"],
    }),
    [permissions],
  );

  const estimatesInvoices = useMemo(
    () => ({
      send: permissions?.["estimates/sendInvoices"],
      view: permissions?.["estimates/viewInvoices"],
    }),
    [permissions],
  );

  const estimatesLocation = useMemo(
    () => ({
      edit: permissions?.["estimates/editLocations"],
    }),
    [permissions],
  );

  const estimatesNotes = useMemo(
    () => ({
      edit: permissions?.["estimates/editNotes"],
    }),
    [permissions],
  );

  const estimatesProblems = useMemo(
    () => ({
      add: permissions?.["estimates/addProblems"],
      edit: permissions?.["estimates/editProblems"],
      delete: permissions?.["estimates/deleteProblems"],
      list: permissions?.["estimates/listProblems"],
    }),
    [permissions],
  );

  const estimatesWorkOrders = useMemo(
    () => ({
      send: permissions?.["estimates/sendWorkOrders"],
      view: permissions?.["estimates/viewWorkOrders"],
    }),
    [permissions],
  );

  const estimatesWorkStatuses = useMemo(
    () => ({
      add: permissions?.["estimates/addWorkStatuses"],
      edit: permissions?.["estimates/editWorkStatuses"],
      delete: permissions?.["estimates/deleteWorkStatuses"],
      list: permissions?.["estimates/listWorkStatuses"],
    }),
    [permissions],
  );

  const jobDuration = useMemo(
    () => ({
      edit: permissions?.["jobDuration/editJobDefaultDuration"],
      view: permissions?.["jobDuration/viewJobDefaultDuration"],
    }),
    [permissions],
  );

  const jobs = useMemo(
    () => ({
      add: permissions?.["jobs/createJobs"],
      view: permissions?.["jobs/viewJobs"],
      list: permissions?.["jobs/listJobs"],
      reschedule: permissions?.["jobs/rescheduleJobs"],
      changeCategory: permissions?.["jobs/changeCategory"],
      changePrimaryTeam: permissions?.["jobs/changePrimaryTeam"],
      changeStatus: permissions?.["jobs/changeStatus"],
      changeTaxRate: permissions?.["jobs/changeTaxRate"],
      chargeCreditCard: permissions?.["jobs/chargeCreditCard"],
      listCalls: permissions?.["jobs/listCalls"],
      listCompletedActions: permissions?.["jobs/listCompletedActions"],
      manageDiscountsCoupons: permissions?.["jobs/manageDiscounts/coupons"],
      manageTips: permissions?.["jobs/manageTips"],
      revertToEstimate: permissions?.["jobs/revertToEstimate"],
      swipeWithSquare: permissions?.["jobs/swipeWithSquare"],
    }),
    [permissions],
  );

  const jobsAttachments = useMemo(
    () => ({
      add: permissions?.["jobs/addAttachments"],
      edit: permissions?.["jobs/editAttachments"],
      delete: permissions?.["jobs/deleteAttachments"],
      list: permissions?.["jobs/listAttachments"],
    }),
    [permissions],
  );

  const jobsFollowUps = useMemo(
    () => ({
      add: permissions?.["jobs/addFollowUps"],
      edit: permissions?.["jobs/editFollowUps"],
      delete: permissions?.["jobs/deleteFollowUps"],
      list: permissions?.["jobs/listFollowUps"],
    }),
    [permissions],
  );

  const jobsLocation = useMemo(
    () => ({
      edit: permissions?.["jobs/editLocations"],
    }),
    [permissions],
  );

  const jobsNotes = useMemo(
    () => ({
      edit: permissions?.["jobs/editNotes"],
    }),
    [permissions],
  );

  const jobsOperationsLogs = useMemo(
    () => ({
      add: permissions?.["jobs/addOperationsLogs"],
      edit: permissions?.["jobs/editOperationsLogs"],
      delete: permissions?.["jobs/deleteOperationsLogs"],
      list: permissions?.["jobs/listOperationsLogs"],
    }),
    [permissions],
  );

  const jobsPayments = useMemo(
    () => ({
      add: permissions?.["jobs/addPayments"],
      edit: permissions?.["jobs/editPayments"],
      delete: permissions?.["jobs/deletePayments"],
      list: permissions?.["jobs/listPayments"],
    }),
    [permissions],
  );

  const jobsProblems = useMemo(
    () => ({
      add: permissions?.["jobs/addProblems"],
      edit: permissions?.["jobs/editProblems"],
      delete: permissions?.["jobs/deleteProblems"],
      list: permissions?.["jobs/listProblems"],
    }),
    [permissions],
  );

  const jobsProducts = useMemo(
    () => ({
      add: permissions?.["jobs/addProducts"],
      edit: permissions?.["jobs/editProducts"],
      delete: permissions?.["jobs/deleteProducts"],
      list: permissions?.["jobs/listProducts"],
    }),
    [permissions],
  );

  const jobsTimesheets = useMemo(
    () => ({
      add: permissions?.["jobs/addTimesheets"],
      delete: permissions?.["jobs/deleteTimesheets"],
      list: permissions?.["jobs/listTimesheets"],
    }),
    [permissions],
  );

  const jobsCommunications = useMemo(
    () => ({
      add: permissions?.["jobs/createCommunications"],
      edit: permissions?.["jobs/editCommunications"],
      list: permissions?.["jobs/listCommunications"],
    }),
    [permissions],
  );

  const jobsDispatchMessages = useMemo(
    () => ({
      customize: permissions?.["jobs/customizeDispatchMessages"],
      send: permissions?.["jobs/sendDispatchMessages"],
    }),
    [permissions],
  );

  const jobsInvoices = useMemo(
    () => ({
      send: permissions?.["jobs/sendInvoices"],
      view: permissions?.["jobs/viewInvoices"],
    }),
    [permissions],
  );

  const jobsWorkOrders = useMemo(
    () => ({
      send: permissions?.["jobs/sendWorkOrders"],
      view: permissions?.["jobs/viewWorkOrders"],
    }),
    [permissions],
  );

  const jobsWorkStatuses = useMemo(
    () => ({
      add: permissions?.["jobs/addWorkStatuses"],
      edit: permissions?.["jobs/editWorkStatuses"],
      delete: permissions?.["jobs/deleteWorkStatuses"],
      list: permissions?.["jobs/listWorkStatuses"],
    }),
    [permissions],
  );

  const leads = useMemo(
    () => ({
      edit: permissions?.["leads/editLeads"],
      view: permissions?.["leads/viewLeads"],
      merge: permissions?.["leads/mergeLeads"],
      print: permissions?.["leads/printLeads"],
      send: permissions?.["leads/sendLeads"],
      listCalls: permissions?.["leads/listCalls"],
      listLeads: permissions?.["leads/listLeads"],
      listPendingRequests: permissions?.["leads/listPendingRequests"],
      listQuotes: permissions?.["leads/listQuotes"],
    }),
    [permissions],
  );

  const leadsAlerts = useMemo(
    () => ({
      add: permissions?.["leads/addAlerts"],
      edit: permissions?.["leads/editAlerts"],
    }),
    [permissions],
  );

  const leadsCommunications = useMemo(
    () => ({
      add: permissions?.["leads/createCommunications"],
      edit: permissions?.["leads/editCommunications"],
      list: permissions?.["leads/listCommunications"],
    }),
    [permissions],
  );

  const lms = useMemo(
    () => ({
      viewAllScores: permissions?.["lms/viewAllScores"],
    }),
    [permissions],
  );

  const locationManagement = useMemo(
    () => ({
      viewLocation: permissions?.["locationManagement/viewLocation"],
    }),
    [permissions],
  );

  const mainPricing = useMemo(
    () => ({
      edit: permissions?.["mainPricing/editPriceList"],
      view: permissions?.["mainPricing/viewPriceList"],
    }),
    [permissions],
  );

  const moveSettings = useMemo(
    () => ({
      edit: permissions?.["moveSettings/editMoveSettings"],
      view: permissions?.["moveSettings/viewMoveSettings"],
      quoteTemplates: permissions?.["moveSettings/quoteTemplates"],
    }),
    [permissions],
  );

  const notificationManagement = useMemo(
    () => ({
      edit: permissions?.["notificationManagement/editNotifications"],
      list: permissions?.["notificationManagement/listNotifications"],
    }),
    [permissions],
  );

  const operationsDashboard = useMemo(
    () => ({
      view: permissions?.["operationsDashboard/viewOperationsDashboard"],
    }),
    [permissions],
  );

  const pendingRequests = useMemo(
    () => ({
      approve: permissions?.["pendingRequests/approvePendingRequests"],
      decline: permissions?.["pendingRequests/declinePendingRequests"],
      list: permissions?.["pendingRequests/listPendingRequests"],
      view: permissions?.["pendingRequests/viewPendingRequests"],
    }),
    [permissions],
  );

  const quotes = useMemo(
    () => ({
      add: permissions?.["quotes/createQuotes"],
      edit: permissions?.["quotes/editQuotes"],
      list: permissions?.["quotes/listQuotes"],
      view: permissions?.["quotes/viewQuotes"],
    }),
    [permissions],
  );

  const reporting = useMemo(
    () => ({
      view: permissions?.["reporting/viewReports"],
    }),
    [permissions],
  );

  const resourceManagement = useMemo(
    () => ({
      add: permissions?.["resourceManagement/createResources"],
      delete: permissions?.["resourceManagement/deleteResources"],
      edit: permissions?.["resourceManagement/editResources"],
      list: permissions?.["resourceManagement/listResources"],
      view: permissions?.["resourceManagement/viewResource"],
    }),
    [permissions],
  );

  const resourceManagementOutages = useMemo(
    () => ({
      add: permissions?.["resourceManagement/createOutages"],
      delete: permissions?.["resourceManagement/deleteOutages"],
      edit: permissions?.["resourceManagement/editOutages"],
      view: permissions?.["resourceManagement/viewOutages"],
    }),
    [permissions],
  );

  const resourceManagementSchedules = useMemo(
    () => ({
      add: permissions?.["resourceManagement/createSchedules"],
      delete: permissions?.["resourceManagement/deleteSchedules"],
      edit: permissions?.["resourceManagement/editSchedules"],
      view: permissions?.["resourceManagement/viewSchedules"],
    }),
    [permissions],
  );

  const royaltyReporting = useMemo(
    () => ({
      submit: permissions?.["royaltyReporting/submitRoyalties"],
      view: permissions?.["royaltyReporting/viewRoyalties"],
    }),
    [permissions],
  );

  const schedule = useMemo(
    () => ({
      view: permissions?.["schedule/viewSchedule"],
    }),
    [permissions],
  );

  const surgePricing = useMemo(
    () => ({
      add: permissions?.["surgePricing/createSurgePriceLists"],
      delete: permissions?.["surgePricing/deleteSurgePriceLists"],
      edit: permissions?.["surgePricing/editSurgePriceLists"],
      list: permissions?.["surgePricing/listSurgePriceLists"],
      view: permissions?.["surgePricing/viewSurgePriceLists"],
    }),
    [permissions],
  );

  const teamPayRate = useMemo(
    () => ({
      add: permissions?.["teamPayRate/createPayRates"],
      delete: permissions?.["teamPayRate/deletePayRates"],
      list: permissions?.["teamPayRate/listPayRates"],
    }),
    [permissions],
  );

  const teams = useMemo(
    () => ({
      edit: permissions?.["teams/editTeams"],
    }),
    [permissions],
  );

  const territoryManagement = useMemo(
    () => ({
      view: permissions?.["territoryManagement/viewTerritories"],
    }),
    [permissions],
  );

  const training = useMemo(
    () => ({
      moveUniversity: permissions?.["training/moveUniversity"],
    }),
    [permissions],
  );

  const userManagement = useMemo(
    () => ({
      add: permissions?.["userManagement/createUsers"],
      deActivate: permissions?.["userManagement/deActivateUsers"],
      edit: permissions?.["userManagement/editUsers"],
      list: permissions?.["userManagement/listUsers"],
      view: permissions?.["userManagement/viewUser"],
    }),
    [permissions],
  );

  const workOrderSettings = useMemo(
    () => ({
      edit: permissions?.["workOrderSettings/editWorkOrderSettings"],
      view: permissions?.["workOrderSettings/viewWorkOrderSettings"],
    }),
    [permissions],
  );

  const zoneManagement = useMemo(
    () => ({
      add: permissions?.["zoneManagement/createZones"],
      delete: permissions?.["zoneManagement/deleteZones"],
      edit: permissions?.["zoneManagement/editZones"],
      list: permissions?.["zoneManagement/listZones"],
      editZonePriceLists: permissions?.["zoneManagement/editZonePriceLists"],
      viewZonePriceLists: permissions?.["zoneManagement/viewZonePriceLists"],
    }),
    [permissions],
  );

  return {
    addOnSettings,
    administration,
    administrationPrimaryTerritories,
    administrationSecondaryTerritories,
    administrationLocation,
    administrationReviewSettings,
    appSettings,
    arrivalWindows,
    billOfLadingSettings,
    callRecordings,
    calls,
    campaignManagement,
    claims,
    clients,
    clientsAlerts,
    clientsCommunications,
    contactManagement,
    dailyCompanyLog,
    dailyTruckLogExpenses,
    estimates,
    estimatesAttachments,
    estimatesCommunications,
    estimatesDispatchMessages,
    estimatesFollowUps,
    estimatesInvoices,
    estimatesLocation,
    estimatesNotes,
    estimatesProblems,
    estimatesTimesheets,
    estimatesWorkOrders,
    estimatesWorkStatuses,
    jobDuration,
    jobs,
    jobsAttachments,
    jobsCommunications,
    jobsDispatchMessages,
    jobsFollowUps,
    jobsInvoices,
    jobsLocation,
    jobsNotes,
    jobsOperationsLogs,
    jobsPayments,
    jobsProblems,
    jobsProducts,
    jobsTimesheets,
    jobsWorkOrders,
    jobsWorkStatuses,
    leads,
    leadsAlerts,
    leadsCommunications,
    lms,
    locationManagement,
    mainPricing,
    moveSettings,
    notificationManagement,
    operationsDashboard,
    pendingRequests,
    quotes,
    reporting,
    resourceManagement,
    resourceManagementOutages,
    resourceManagementSchedules,
    royaltyReporting,
    schedule,
    surgePricing,
    teamPayRate,
    teams,
    territoryManagement,
    training,
    userManagement,
    workOrderSettings,
    zoneManagement,
    loading,
  };
};
