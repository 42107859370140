import { Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { memo, useState, useCallback, useEffect, useMemo } from "react";
import { useStore } from "effector-react";
import { colors, Button, useDialogDispatch } from "@chhjpackages/components";

import { AwardIcon } from "shared/assets/icons/award-icon";
import {
  $moveJobsStore,
  moveStepsCompletedCount,
  guideMoveList,
  guideMoveListWithTitle,
  guideMoveParagraph,
  GuideStepsGroup,
  GuideOne,
} from "features/training-details";
import { routePaths } from "shared/utils";
import { useSideNavDispatch } from "features/sidenav";
import { BackTitle } from "shared/ui";

import { useStyles } from "./assets";

export const MoveJobSteps = memo(() => {
  const { moveStepsCompleted } = useStore($moveJobsStore);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: true,
  });
  const styles = useStyles();
  const setDialog = useDialogDispatch();
  const { setPageName, setShowGoBackButton, setGoToBackUrl, setElevation } =
    useSideNavDispatch();
  let stepsCount = JSON.parse(localStorage.getItem("move_steps") as string);
  let stepsCountLength =
    stepsCount !== null && stepsCount !== undefined ? stepsCount.length : 0;
  const [buttonSteps, setButtonSteps] = useState<number>(stepsCountLength);
  const [completedList, setCompletedList] = useState<number[]>([]);
  const [visibility, setVisibility] = useState<boolean>(false);
  const [slideIndex, setSlideIndex] = useState<number>(-1);
  const [stepsValidate, setStepsValidate] = useState<boolean[]>(
    new Array(13).fill(false),
  );

  const goToBackUrl = useMemo(() => routePaths.training(), []);

  const closeGuide = useCallback(() => setVisibility(false), []);

  const callGuideJob = (val: number) => {
    setSlideIndex(val);
    setVisibility(true);
  };

  const handleContinue = useCallback(() => {
    for (let i = 0, j = 0; i < 14; ++i) {
      if (completedList[i] === j) {
        j++;
      } else {
        callGuideJob(j);
        break;
      }
    }
  }, [completedList]);

  useEffect(() => {
    let steps = JSON.parse(localStorage.getItem("move_steps") as string);
    let stepsLength = steps !== null && steps !== undefined ? steps.length : 0;
    setButtonSteps(stepsLength);
  }, [moveStepsCompleted, stepsCount]);

  useEffect(() => {
    setPageName("Steps of a perfect move job");
    setGoToBackUrl(goToBackUrl);
    setShowGoBackButton();
    setElevation(16);

    return () => {
      setPageName("");
      setGoToBackUrl("");
      setShowGoBackButton();
      setElevation(0);
    };
  }, [
    goToBackUrl,
    setPageName,
    setShowGoBackButton,
    setGoToBackUrl,
    setElevation,
  ]);

  useEffect(() => {
    setDialog({
      open: visibility,
      variant: "fullScreen",
      dialogContent: (
        <GuideOne
          stepsCount={moveStepsCompletedCount}
          selectedSlide={slideIndex}
          list={guideMoveList}
          onClose={closeGuide}
        />
      ),
      onClose: closeGuide,
      disableBackdropClick: true,
      maxWidth: "xl",
      classes: { wrapper: styles.sideScroll },
      hideCloseButton: true,
    });
  }, [visibility, slideIndex]);

  useEffect(() => {
    const sortedSteps = moveStepsCompleted.sort(
      (a: number, b: number) => a - b,
    );
    const completedSlides: number[] = sortedSteps.filter(function (item, pos) {
      return sortedSteps.indexOf(item) === pos;
    });
    let actualSteps = [];
    let completedSteps = JSON.parse(
      localStorage.getItem("move_steps") as string,
    );
    if (
      completedSteps?.length === 0 ||
      completedSteps === undefined ||
      completedSteps === null
    ) {
      let moveSteps = [];
      moveSteps = [...completedSlides];
      localStorage.setItem("move_steps", JSON.stringify(moveSteps));
      completedSteps = JSON.parse(localStorage.getItem("move_steps") as string);
      actualSteps = completedSteps;
    } else {
      const allSteps = completedSteps.concat(...completedSlides);
      const sortedAllSteps = allSteps.sort((a: number, b: number) => a - b);
      actualSteps = sortedAllSteps.filter(function (item: number, pos: number) {
        return sortedAllSteps.indexOf(item) === pos;
      });
      localStorage.setItem("move_steps", JSON.stringify(actualSteps));
    }
    setCompletedList(actualSteps);
    const items = [...stepsValidate];
    actualSteps.map((slide: number) => {
      items[slide] = true;
      setStepsValidate(items);
      return 0;
    });
  }, [moveStepsCompleted]);

  const navigate = useNavigate();

  return (
    <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
      {!isMobile && (
        <BackTitle
          title="Steps of a perfect move job"
          elevation={16}
          onBack={() => navigate(goToBackUrl)}
        />
      )}
      <div style={{ padding: 16, backgroundColor: colors.white, flex: 1 }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <AwardIcon />
          <div style={{ marginLeft: 12, color: colors.grey180 }}>
            <Typography variant="h3" color="inherit">
              Steps of a move job
            </Typography>
          </div>
        </div>
        <div style={{ marginTop: 8, color: colors.grey140 }}>
          <Typography variant="body1" color="inherit">
            {guideMoveParagraph}
          </Typography>
        </div>
        <div style={{ marginTop: 16, color: colors.grey160 }}>
          <Typography variant="h5" color="inherit">
            STEPS COMPLETED: {completedList?.length} / 14
          </Typography>
        </div>
        <div style={{ marginTop: 32 }}>
          <Button
            buttonType="twoTone"
            fullWidth
            size="large"
            disabled={buttonSteps === 14}
            onClick={() => handleContinue()}
          >
            Continue
          </Button>
        </div>
        <div style={{ marginTop: 24 }}>
          <Button
            buttonType="outlined"
            fullWidth
            size="large"
            onClick={() => callGuideJob(0)}
          >
            Start from beginning
          </Button>
        </div>
        <div style={{ marginTop: 32 }}>
          <GuideStepsGroup
            guideListArray={guideMoveListWithTitle}
            callGuideJob={callGuideJob}
            stepsValidate={stepsValidate}
          />
        </div>
      </div>
    </div>
  );
});
