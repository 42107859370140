import { memo, useMemo } from "react";
import { Box, Typography } from "@material-ui/core";

export const ProcessPlaceholder = memo(
  ({ isContactless }: { isContactless?: boolean }) => {
    const text = useMemo(
      () =>
        isContactless
          ? {
              main: "Tap or use chip reader",
              sub: "Use the contactless or chip feature to process the card.",
            }
          : {
              main: "Swipe the card",
              sub: "Swipe the client’s card in the terminal.",
            },
      [isContactless],
    );

    return (
      <Box px={2} py={10}>
        <Box mb={2}>
          <Typography variant="h4" color="secondary" align="center">
            {text.main}
          </Typography>
        </Box>

        <Typography align="center">{text.sub}</Typography>
      </Box>
    );
  },
);
