import { memo } from "react";
import { Typography } from "@material-ui/core";
import { Paper } from "@chhjpackages/components";

import UsHunger from "../../assets/images/logo-us-hunger.png";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { ReactComponent as IconMeal } from "../../assets/images/icon-meal.svg";
import { ReactComponent as IconTruck } from "../../assets/images/icon-truck.svg";

import { useStyles } from "./styles";

export const Page5 = memo(() => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <Paper variant="outlined" roundingValue={0} className={styles.content}>
        <div className={styles.leftSide}>
          <Typography variant="h3" component="div">
            Your job benefits
          </Typography>
          <Typography variant="h3" component="div">
            children in need!
          </Typography>

          <br />
          <Typography component="div">
            Through our partnership with U.S. Hunger, we{" "}
            <strong>donate two healthy meals for every job.</strong>
          </Typography>

          <br />
          <Typography component="div">
            <strong>25% of students are impoverished</strong> and
            undernourished, and many would only receive school lunches without
            this program. Help us to change this by providing the nutrition they
            need to survive{" "}
            <strong>as a benefit to your business with us.</strong>
          </Typography>
          <br />
          <br />
          <br />
        </div>

        <div className={styles.rightSide}>
          <div className={styles.partners}>
            <div>
              <img src={UsHunger} alt="Us hunger" />
            </div>

            <Typography
              variant="h1"
              component="div"
              className={styles.partnersPlus}
            >
              +
            </Typography>

            <div>
              <Logo />
            </div>
          </div>

          <div className={styles.mainText}>
            <Typography component="div">
              We are partnered with U.S. Hunger
            </Typography>

            <Typography variant="h4" component="div">
              we have already donated{" "}
              <Typography variant="h4" component="span" color="primary">
                millions of meals!
              </Typography>
            </Typography>
          </div>

          <div>
            <div className={styles.info}>
              <div className={styles.infoIcon}>
                <IconMeal />
              </div>
              <div>
                <Typography
                  variant="h4"
                  component="div"
                  color="secondary"
                  style={{ lineHeight: 1 }}
                >
                  2 meals
                </Typography>
                <Typography component="div" style={{ lineHeight: 1 }}>
                  donated per every job
                </Typography>
              </div>
            </div>

            <div className={styles.info}>
              <div className={styles.infoIcon}>
                <IconTruck />
              </div>
              <div>
                <Typography
                  variant="h4"
                  component="div"
                  color="secondary"
                  style={{ lineHeight: 1 }}
                >
                  help
                </Typography>
                <Typography component="div" style={{ lineHeight: 1 }}>
                  load, transport and deliver food supplies
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
});
