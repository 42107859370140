import { Box } from "@material-ui/core";
import { Button } from "@chhjpackages/components";
import { memo, useCallback } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { phoneFormat } from "features/appointment";
import { FormInput } from "shared/ui";
import { ClientInfoFormProps } from "features/appointment/model/types/client-contact";

const clientContactSchema = yup.object({
  firstName: yup.string(),
  lastName: yup.string(),
  primaryPhone: yup
    .string()
    .nullable()
    .transform((_, originvalValue) => {
      return originvalValue.replace(/\D/g, "");
    })
    .when((phone, schema) => {
      if (String(phone).length > 0) {
        return schema.min(10, "Phone must be at least 10 characters");
      }
    }),
  secondaryPhone: yup
    .string()
    .nullable()
    .transform((_, originvalValue) => {
      return originvalValue.replace(/\D/g, "");
    })
    .when((phone, schema) => {
      if (String(phone).length > 0) {
        return schema.min(10, "Phone must be at least 10 characters");
      }
    }),
  email: yup.string().email("Must be a valid email"),
});

export const ContactEditForm = memo(
  ({
    appointment,
    isUserDataSubmiting,
    onDataSubmit,
    handleCloseEditContactsDialog,
  }: ClientInfoFormProps) => {
    const { control, formState, handleSubmit } = useForm({
      mode: "all",
      defaultValues: {
        firstName: appointment?.account.firstName || "",
        lastName: appointment?.account.lastName || "",
        primaryPhone: phoneFormat(appointment?.account.phone || ""),
        secondaryPhone: phoneFormat(appointment?.account.cell || ""),
        email: appointment?.account.email || "",
      },
      resolver: yupResolver(clientContactSchema),
    });

    const onHandleSubmit = useCallback(
      (data) => {
        onDataSubmit(data);
      },
      [onDataSubmit],
    );

    return (
      <form onSubmit={handleSubmit(onHandleSubmit)}>
        <Box>
          <FormInput name="firstName" control={control} label="First name" />
        </Box>
        <Box mt={2}>
          <FormInput name="lastName" control={control} label="Last name" />
        </Box>
        <Box mt={2}>
          <FormInput
            name="primaryPhone"
            control={control}
            mask="phone"
            label="Primary Phone"
          />
        </Box>
        <Box mt={2}>
          <FormInput
            name="secondaryPhone"
            control={control}
            mask="phone"
            label="Secondary Phone"
          />
        </Box>
        <Box mt={2}>
          <FormInput name="email" control={control} label="Email" />
        </Box>
        <Box
          mt={4}
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
        >
          <Box mr={1.25}>
            <Button
              buttonType="text"
              color="primary"
              onClick={handleCloseEditContactsDialog}
              fullWidth
            >
              Close
            </Button>
          </Box>
          <Box width={150}>
            <Button
              buttonType="twoTone"
              disabled={!formState.isValid || !formState.isDirty}
              isLoading={
                !formState.isValid || !formState.isDirty
                  ? false
                  : isUserDataSubmiting
              }
              fullWidth
              type="submit"
            >
              Save changes
            </Button>
          </Box>
        </Box>
      </form>
    );
  },
);
