import { colors } from "@chhjpackages/components";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  createStyles,
  makeStyles,
  withStyles,
} from "@material-ui/core";

export const AccordionStyled = withStyles(() =>
  createStyles({
    root: {
      margin: "0px!important",
      boxShadow: "none",
      backgroundColor: "initial",
    },
  }),
)(Accordion);

export const AccordionSummaryStyled = withStyles(() =>
  createStyles({
    root: {
      padding: "8px 16px",
      minHeight: "fit-content",
      backgroundColor: colors.grey160,
      color: colors.white,
    },
    content: {
      margin: "0px!important",
    },
    expanded: {
      minHeight: "fit-content!important",
    },
    expandIcon: {
      margin: -12,
    },
  }),
)(AccordionSummary);

export const AccordionDetailsStyled = withStyles(() =>
  createStyles({
    root: {
      padding: "8px 16px 16px",
      backgroundColor: colors.white,
    },
  }),
)(AccordionDetails);

export const useStyles = makeStyles({
  arrowIcon: {
    transform: "rotate(180deg)",
  },
});
