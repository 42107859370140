import { Select } from "@chhjpackages/components";
import { memo } from "react";

import { MenuItemStyled, useStyles } from "./styles";
import { CategorySelectProps } from "./types";

export const CategorySelect = memo(
  ({ options, ...rest }: CategorySelectProps) => {
    const styles = useStyles();

    return (
      <Select {...rest} variant="clean" className={styles.select}>
        {Array.isArray(options)
          ? options.map((option, index) => (
              <MenuItemStyled key={index} value={option}>
                {option}
              </MenuItemStyled>
            ))
          : Object.keys(options).map((key) => (
              <MenuItemStyled key={key} value={key}>
                {options[key]}
              </MenuItemStyled>
            ))}
      </Select>
    );
  },
);
