import { memo, useCallback, useEffect, useMemo } from "react";
import { useAlert, useDialogDispatch } from "@chhjpackages/components";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useStore } from "effector-react/compat";

import {
  $appointmentStore,
  $appointmentsStore,
  MapTypes,
  getAppointment,
  setAppointmentInAppointmentsEv,
} from "features/appointment";
import { $settings } from "features/settings";
import {
  DevelopmentStatusesEnum,
  addDevelopmentFx,
  getStartTravelSmsContent,
} from "features/developments";
import { useSteps } from "features/appointment/model/hooks";
import { StepStatusesEnum } from "shared/types";
import { DialogContentWrapper } from "shared/ui";

export const MapTypesDialog = memo(() => {
  const location = useLocation();
  const { appointmentId } = useParams();
  const navigate = useNavigate();

  const { showAlert } = useAlert();

  const setDialog = useDialogDispatch();

  const { appointment: appointmentStore } = useStore($appointmentStore);
  const { appointments: appointmentsStore, loading: appointmentsLoading } =
    useStore($appointmentsStore);
  const { navigationApp } = useStore($settings);

  const appointment = useMemo(
    () =>
      appointmentStore?.id === Number(appointmentId)
        ? appointmentStore
        : appointmentsStore.find((app) => app.id === Number(appointmentId)),
    [appointmentStore, appointmentsStore, appointmentId],
  );

  const { steps } = useSteps({
    appointmentOuter: appointment,
  });

  const isLoading = useMemo(
    () => appointmentsLoading || !appointment,
    [appointmentsLoading, appointment],
  );

  const locationState = useMemo<{ destination: string } | undefined>(
    () => location.state,
    [location.state],
  );

  const startTravelDisabled = useMemo(
    () => steps.travel !== StepStatusesEnum.ToBeDone,
    [steps.travel],
  );

  const handleStartTravel = useCallback(async () => {
    if (!appointment) {
      return false;
    }

    try {
      await addDevelopmentFx({
        locationId: appointment.location.id,
        appointmentId: appointment.id,
        payload: {
          status: {
            id: DevelopmentStatusesEnum.StartTravel,
          },
          manual: false,
          sendEta: true,
          sendTracking: false,
          phone: appointment.account.phone,
          message: getStartTravelSmsContent(appointment.account.firstName),
        },
      });

      const appointmentResponse = await getAppointment({
        locationId: appointment.location.id,
        appointmentId: appointment.id,
      });

      setAppointmentInAppointmentsEv(appointmentResponse.data.appointments[0]);

      showAlert("Success! Travel has been started.", {
        variant: "success",
      });

      return true;
    } catch {
      showAlert("Error! Failed to start travel.", {
        variant: "error",
      });

      return false;
    }
  }, [appointment, showAlert]);

  const handleCloseMapTypesDialog = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useEffect(() => {
    if (locationState?.destination) {
      setDialog({
        open: true,
        variant: "basic",
        title: "Begin navigation",
        disableBackdropClick: true,
        dialogContent: (
          <DialogContentWrapper maxWidth={488}>
            <MapTypes
              mapType={navigationApp.value as "google" | "apple" | "waze"}
              destination={locationState.destination}
              isLoading={isLoading}
              onStartTravel={
                !startTravelDisabled ? handleStartTravel : undefined
              }
              onClose={handleCloseMapTypesDialog}
            />
          </DialogContentWrapper>
        ),
        onClose: handleCloseMapTypesDialog,
      });
    }

    return () => setDialog({ open: false });
  }, [
    locationState?.destination,
    navigationApp.value,
    startTravelDisabled,
    isLoading,
    setDialog,
    handleStartTravel,
    handleCloseMapTypesDialog,
  ]);

  return null;
});
