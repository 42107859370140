import { Button, colors, Paper } from "@chhjpackages/components";
import { Divider, Typography } from "@material-ui/core";
import { memo } from "react";

import { BoldPencilIcon, TrashIcon } from "shared/assets";

import { ProductCardProps } from "./types";
import { useStyles } from "./styles";

export const ProductCard = memo(
  ({
    product: { productSubcategoryName, productName, actualPrice, quantity },
    disableActions,
    onEdit,
    onDelete,
  }: ProductCardProps) => {
    const styles = useStyles();

    return (
      <Paper elevation={16}>
        <div style={{ padding: "8px 16px" }}>
          <div style={{ color: colors.grey100 }}>
            <Typography
              variant="body1"
              color="inherit"
              className={styles.category}
            >
              {productSubcategoryName}
            </Typography>
          </div>
          <div style={{ color: colors.grey190 }}>
            <Typography
              variant="subtitle1"
              color="inherit"
              className={styles.name}
            >
              {productName}
            </Typography>
          </div>
          <div style={{ marginTop: 8, display: "flex", color: colors.grey100 }}>
            <div style={{ marginRight: 16 }}>
              <Typography variant="subtitle1" color="inherit">
                ${actualPrice.toFixed(2)}/each
              </Typography>
            </div>
            <div>
              <Typography variant="subtitle1" color="inherit">
                Quantity: {quantity}
              </Typography>
            </div>
          </div>
        </div>
        <div>
          <Divider style={{ backgroundColor: colors.grey20 }} />
        </div>

        <div style={{ display: "flex" }}>
          <div style={{ flex: 1 }}>
            <Button
              buttonType="text"
              fullWidth
              disabled={disableActions}
              onClick={onDelete}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <TrashIcon
                  fontSize={22}
                  color={
                    disableActions ? colors.grey60 : colors.functionals.alert
                  }
                />
                <div
                  style={{
                    marginLeft: 8,
                    color: disableActions
                      ? "inherit"
                      : colors.functionals.alert,
                  }}
                >
                  <Typography variant="h5" color="inherit">
                    Delete
                  </Typography>
                </div>
              </div>
            </Button>
          </div>
          <div style={{ padding: "8px 0px" }}>
            <Divider
              orientation="vertical"
              style={{ backgroundColor: colors.grey20 }}
            />
          </div>
          <div style={{ flex: 1 }}>
            <Button
              buttonType="text"
              fullWidth
              disabled={disableActions}
              onClick={onEdit}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <BoldPencilIcon
                  fontSize={16}
                  color={disableActions ? colors.grey60 : colors.primary.main}
                />
                <div
                  style={{
                    marginLeft: 8,
                    color: disableActions ? "inherit" : colors.primary.main,
                  }}
                >
                  <Typography variant="h5" color="inherit">
                    Edit
                  </Typography>
                </div>
              </div>
            </Button>
          </div>
        </div>
      </Paper>
    );
  },
);
