import { memo, useEffect, useRef } from "react";
import { Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { colors, Scrollbars } from "@chhjpackages/components";
import { useStore } from "effector-react";

import {
  AppointmentGridViewCard,
  $appointmentStore,
} from "features/appointment";

import { AppointmentListProps } from "./types";

export const AppointmentGridViewList = memo(
  ({
    isScroll,
    actualTime,
    groupedByTimeAppointments,
    currentTeams,
    handleOpenNotesDialog,
    handleOpenContactsDialog,
    handleOpenMapTypesDialog,
    handlePickUp,
  }: AppointmentListProps) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
      noSsr: true,
    });
    const { appointment } = useStore($appointmentStore);
    const actualPeriod = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
      if (actualPeriod.current && isScroll) {
        actualPeriod.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, [actualTime, isScroll]);

    return (
      <>
        {groupedByTimeAppointments.map((item) => (
          <div key={item.time}>
            <div
              style={{
                backgroundColor: colors.grey30,
                padding: 8,
              }}
            >
              <Typography
                ref={item.time === actualTime ? actualPeriod : null}
                variant="h5"
              >
                {item.time}
              </Typography>
            </div>

            {isMobile ? (
              <div style={{ marginTop: 8 }}>
                {item.data.map((job) => (
                  <AppointmentGridViewCard
                    isActual={appointment?.id === job.id}
                    appointment={appointment?.id === job.id ? appointment : job}
                    key={job.id}
                    currentTeams={currentTeams}
                    handleOpenContactsDialog={handleOpenContactsDialog}
                    handleOpenNotesDialog={handleOpenNotesDialog}
                    handleOpenMapTypesDialog={handleOpenMapTypesDialog}
                    handlePickUp={handlePickUp}
                  />
                ))}
              </div>
            ) : (
              <div style={{ margin: "16px 0px 8px" }}>
                <Scrollbars
                  autoHide
                  autoHeight
                  autoHeightMin="100%"
                  autoHeightMax="100%"
                  style={{ height: "100%" }}
                >
                  <div
                    style={{
                      display: "flex",
                      marginBottom: 20,
                    }}
                  >
                    {item.data.map((job) => (
                      <AppointmentGridViewCard
                        key={job.id}
                        appointment={job}
                        currentTeams={currentTeams}
                        handleOpenContactsDialog={handleOpenContactsDialog}
                        handleOpenNotesDialog={handleOpenNotesDialog}
                        handleOpenMapTypesDialog={handleOpenMapTypesDialog}
                        handlePickUp={handlePickUp}
                      />
                    ))}
                  </div>
                </Scrollbars>
              </div>
            )}
          </div>
        ))}
      </>
    );
  },
);
