import { colors } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  backButtonContainer: {
    margin: 16,
  },
  root: {
    flex: 1,
    padding: 16,
    paddingTop: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  rootContent: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 426,
    width: "100%",
    gap: 24,
  },
  content: {
    backgroundColor: colors.white,
    padding: "24px 32px",

    [theme.breakpoints.down("xs")]: {
      padding: 24,
    },
  },
  passwordExpiredMessageRoot: {
    display: "flex",
    alignItems: "center",
    gap: 16,
    fontWeight: "bold",
  },
}));
