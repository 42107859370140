import { useNavigate } from "react-router";
import { memo, useCallback } from "react";
import { Box } from "@material-ui/core";
import { Paper } from "@chhjpackages/components";

import { routePaths } from "shared/utils";
import { PasswordChanged } from "features/auth";

export const RestorePasswordSuccess = memo(() => {
  const navigate = useNavigate();

  const handleNavigateTo = useCallback(() => {
    navigate(routePaths.login());
  }, [navigate]);

  return (
    <Box
      flex={1}
      p={2}
      pt={0}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box maxWidth={426} width="100%">
        <Paper elevation={16}>
          <Box p={3} px={{ xs: 3, sm: 4 }}>
            <PasswordChanged handleNavigateTo={handleNavigateTo} />
          </Box>
        </Paper>
      </Box>
    </Box>
  );
});
