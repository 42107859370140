import { fetcher } from "shared/libs";
import { PreExistingDamages } from "../types";

export const getCurrentPreExistingDamage = ({
  locationId,
  appointmentId,
}: PreExistingDamages.GetOne) =>
  fetcher.get<PreExistingDamages.Response>(
    `/locations/${locationId}/appointments/${appointmentId}/ped`,
  );

export const generatePreExistingDamage = ({
  locationId,
  appointmentId,
}: PreExistingDamages.Create) =>
  fetcher.post<PreExistingDamages.Response>(
    `/locations/${locationId}/appointments/${appointmentId}/ped`,
  );

export const updatePreExistingDamage = ({
  locationId,
  appointmentId,
  preExistingDamageId,
  payload,
}: PreExistingDamages.Update) =>
  fetcher.put<PreExistingDamages.Response>(
    `/locations/${locationId}/appointments/${appointmentId}/ped/${preExistingDamageId}`,
    payload,
  );
