import { createEffect, createStore } from "effector/compat";

import { Inspection } from "./types";
import { generateInspection } from "./api";

export const generateInspectionFx = createEffect(generateInspection);

export const $inspections = createStore<{
  preInspection: Inspection | null;
  preInspectionAppointmentId: number | null;
  postInspection: Inspection | null;
  postInspectionAppointmentId: number | null;
  loading: boolean;
}>({
  preInspection: null,
  preInspectionAppointmentId: null,
  postInspection: null,
  postInspectionAppointmentId: null,
  loading: true,
});

$inspections
  .on(generateInspectionFx.done, (store, response) => {
    const isPre = response.params.payload.signatureType === "pre";
    const appointmentId = response.params.appointmentId;
    const inspection = response.result.data.inspections[0];

    return {
      ...store,
      ...(isPre
        ? {
            preInspection: inspection,
            preInspectionAppointmentId: appointmentId,
          }
        : {
            postInspection: inspection,
            postInspectionAppointmentId: appointmentId,
          }),
    };
  })
  .on(generateInspectionFx.pending, (store, loading) => ({
    ...store,
    loading,
  }));
