import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
  BillOfLadingFormValues,
  CoupounDiscountOption,
  CoupounsDiscountsEnum,
} from "../types";
import { CalculatedQuote, FormModeEnum, Quote } from "features/quotes/model";
import { DiscountTypesEnum, Pricing } from "shared/types";
import { Appointments } from "features/appointment";

const billOfLadingViewSchema = (discountsCouponsOptions: unknown[]) =>
  yup.object({
    numOfHunksMoving: yup.number().typeError(""),
    numOfHoursMoving: yup.number().typeError(""),
    hourlyRateMoving: yup.string().required(""),
    truckAndTravelFee: yup.string().required(""),
    numOfHunksPacking: yup.number().typeError(""),
    numOfHoursPacking: yup.number().typeError(""),
    hourlyRatePacking: yup.string().required(""),
    additionalProducts: yup.array().of(
      yup.object({
        quantity: yup.number().typeError(""),
      }),
    ),
    couponDiscount: yup.object().when((value, schema) => {
      if (discountsCouponsOptions.length > 1 && !value) {
        return schema.required();
      }
    }),
  });

const initialValues: BillOfLadingFormValues = {
  numOfHunksMoving: 0,
  numOfHoursMoving: 0,
  hourlyRateMoving: Number(0).toFixed(2),
  truckAndTravelFee: Number(0).toFixed(2),
  numOfHunksPacking: 0,
  numOfHoursPacking: 0,
  hourlyRatePacking: Number(0).toFixed(2),
  additionalProducts: [],
};

export const useBillOfLadingView = ({
  quote,
  calculatedQuote,
  appointment,
  pricings,
}: {
  quote: Quote | null;
  calculatedQuote: CalculatedQuote | null;
  appointment: Appointments | null;
  pricings: Pricing[];
}) => {
  const discountsCouponsOptions: CoupounDiscountOption[] = useMemo(
    () => [
      ...(appointment?.coupon.id
        ? [
            {
              label: appointment.coupon.name,
              amount: appointment.coupon.amount,
              value: CoupounsDiscountsEnum.Coupon,
              discountType: appointment.coupon.type
                .id as unknown as DiscountTypesEnum,
            },
          ]
        : []),
      ...(appointment?.discount.amount
        ? [
            {
              label: "From job",
              amount: appointment.discount.amount,
              value: CoupounsDiscountsEnum.DiscountJob,
              discountType: appointment.discount.type.id as DiscountTypesEnum,
            },
          ]
        : []),
      ...(quote?.pricing.discount
        ? [
            {
              label: "From quote",
              amount: quote.pricing.discount,
              value: CoupounsDiscountsEnum.DiscountQuote,
              discountType: DiscountTypesEnum.Dollar,
            },
          ]
        : []),
    ],
    [appointment, quote],
  );

  const {
    control,
    formState: { isValid, isSubmitting },
    watch,
    setValue,
    trigger,
    reset,
    handleSubmit,
  } = useForm<BillOfLadingFormValues>({
    mode: "all",
    defaultValues: initialValues,
    resolver: yupResolver(billOfLadingViewSchema(discountsCouponsOptions)),
  });

  const valuesWatched = watch();
  const numOfHunksMovingWatched = watch("numOfHunksMoving");
  const numOfHoursMovingWatched = watch("numOfHoursMoving");
  const hourlyRateMovingWatched = watch("hourlyRateMoving");
  const truckAndTravelFeeWatched = watch("truckAndTravelFee");
  const numOfHunksPackingWatched = watch("numOfHunksPacking");
  const numOfHoursPackingWatched = watch("numOfHoursPacking");
  const hourlyRatePackingWatched = watch("hourlyRatePacking");
  const additionalProductsWatched = watch("additionalProducts");

  const [isAdditionalProductsUpdated, setIsAdditionalProductsUpdated] =
    useState(false);

  const isWeekend = useMemo(() => !!quote?.isWeekend, [quote?.isWeekend]);

  const inventoryItems = useMemo(
    () =>
      quote?.inventory.filter(
        (item) => item.subcategory === "Moving - Accessorial",
      ) ?? [],
    [quote?.inventory],
  );

  const movingItems = useMemo(
    () =>
      pricings.filter((item) =>
        item.subcategory.name.includes("Moving - Full Service"),
      ),
    [pricings],
  );

  const moveLaborItems = useMemo(
    () =>
      pricings.filter((item) =>
        item.subcategory.name.includes("Moving - Labor"),
      ),
    [pricings],
  );

  const junkRemovalItems = useMemo(
    () =>
      pricings.filter((item) =>
        item.subcategory.name.includes("Junk Removal - Basic"),
      ),
    [pricings],
  );

  const accessoryItems = useMemo(
    () =>
      pricings
        ? pricings.filter((item) =>
            item.subcategory.name.includes("Moving - Accessorial"),
          )
        : [],
    [pricings],
  );

  const junkRemovalOptions = useMemo(
    () =>
      junkRemovalItems.map((item) => ({
        value: item.id,
        label: item.name,
      })),
    [junkRemovalItems],
  );

  const laborServicesProduct = useMemo(() => {
    if (
      numOfHunksMovingWatched > 0 &&
      numOfHoursMovingWatched > 0 &&
      Number(hourlyRateMovingWatched) > 0
    ) {
      const laborProductMatches = movingItems.filter((item) => {
        if (!item.active) {
          return false;
        }

        if (
          isWeekend &&
          item.name ===
            `W/E F/S/S- ${numOfHunksMovingWatched} Hunks *Price Per Hour`
        ) {
          return true;
        } else if (
          !isWeekend &&
          item.name === `${numOfHunksMovingWatched} Hunks *Price Per Hour`
        ) {
          return true;
        }

        return false;
      });

      if (laborProductMatches.length > 0) {
        return {
          id: laborProductMatches[0].id,
          quantity: numOfHoursMovingWatched,
          price: Number(hourlyRateMovingWatched),
          name: "Labor Services",
          hidden: true,
        };
      }
    }
  }, [
    hourlyRateMovingWatched,
    isWeekend,
    movingItems,
    numOfHoursMovingWatched,
    numOfHunksMovingWatched,
  ]);

  const packingServicesProduct = useMemo(() => {
    if (
      numOfHunksPackingWatched > 0 &&
      numOfHoursPackingWatched > 0 &&
      Number(hourlyRatePackingWatched) > 0
    ) {
      const packingProductsMatches = moveLaborItems.filter((item) => {
        if (!item.active) {
          return false;
        }

        if (
          item.name ===
            `Packing - ${numOfHunksPackingWatched} Hunks *Price Per Hour` &&
          item.subcategory.name.includes(
            `${calculatedQuote?.minimumHours ?? 1} Hour Minimum`,
          )
        ) {
          return true;
        }

        return false;
      });

      if (packingProductsMatches.length > 0) {
        return {
          id: packingProductsMatches[0].id,
          quantity: numOfHoursPackingWatched,
          price: Number(hourlyRatePackingWatched),
          name: "Packing Services",
          hidden: true,
        };
      }
    }
  }, [
    calculatedQuote?.minimumHours,
    hourlyRatePackingWatched,
    moveLaborItems,
    numOfHoursPackingWatched,
    numOfHunksPackingWatched,
  ]);

  const truckAndTravelFeeProduct = useMemo(() => {
    if (numOfHunksMovingWatched > 0 && Number(truckAndTravelFeeWatched) > 0) {
      const truckAndTravelFeeMatches = movingItems.filter((item) => {
        if (!item.active) {
          return false;
        }

        if (
          item.name ===
          `Truck and Travel Fee - ${numOfHunksMovingWatched} Hunks`
        ) {
          return true;
        }

        return false;
      });

      if (truckAndTravelFeeMatches.length > 0) {
        return {
          id: truckAndTravelFeeMatches[0].id,
          quantity: 1,
          price: Number(truckAndTravelFeeWatched),
          name: "Truck & Travel Fee",
          hidden: true,
        };
      }
    }
  }, [movingItems, numOfHunksMovingWatched, truckAndTravelFeeWatched]);

  const inventoryProducts = useMemo(() => {
    const result: {
      id: number;
      quantity: number;
      price: number;
      name: string;
    }[] = [];

    if (quote) {
      inventoryItems.forEach((item) => {
        const itemMatches = accessoryItems.find(
          (movingItem) => movingItem.id === item.productId,
        );

        if (itemMatches) {
          result.push({
            id: itemMatches.id,
            quantity: item.quantity,
            price: itemMatches.price,
            name: itemMatches.name,
          });
        }
      });
    }

    return result;
  }, [accessoryItems, inventoryItems, quote]);

  const additionalProducts = useMemo(() => {
    const currentInventoryProducts = additionalProductsWatched.filter(
      (item) =>
        item.name !== "Labor Services" &&
        item.name !== "Packing Services" &&
        item.name !== "Truck & Travel Fee",
    );

    return [
      ...(laborServicesProduct ? [laborServicesProduct] : []),
      ...(packingServicesProduct ? [packingServicesProduct] : []),
      ...(truckAndTravelFeeProduct ? [truckAndTravelFeeProduct] : []),
      ...(currentInventoryProducts.length === 0
        ? inventoryProducts
        : currentInventoryProducts),
    ];
  }, [
    laborServicesProduct,
    packingServicesProduct,
    truckAndTravelFeeProduct,
    inventoryProducts,
  ]);

  useEffect(() => {
    if (additionalProducts.length > 0 || isAdditionalProductsUpdated) {
      reset(
        {
          ...valuesWatched,
          additionalProducts: additionalProducts,
        },
        { keepErrors: true },
      );
      setIsAdditionalProductsUpdated(true);
    }
  }, [additionalProducts]);

  useEffect(() => {
    if (!quote || !calculatedQuote) {
      return;
    }

    const isInventoryMode = quote.type.id === FormModeEnum.Inventory;

    reset({
      numOfHunksMoving: calculatedQuote.hunks,
      numOfHoursMoving: isInventoryMode
        ? calculatedQuote.laborHours.exactBase
        : calculatedQuote.laborHours.high,
      hourlyRateMoving: Number(calculatedQuote.hourlyRate).toFixed(2),
      truckAndTravelFee: Number(calculatedQuote.truckAndTravel).toFixed(2),
      numOfHunksPacking: calculatedQuote.hunks,
      numOfHoursPacking: calculatedQuote.packing.services.hours,
      hourlyRatePacking: Number(calculatedQuote.packing.services.rate).toFixed(
        2,
      ),
      additionalProducts: [],
      junkRemoval: valuesWatched.junkRemoval,
      couponDiscount: valuesWatched.couponDiscount,
    });
  }, [calculatedQuote, quote]);

  useEffect(() => {
    if (
      junkRemovalOptions.length > 0 &&
      (quote?.pricing.junkRemoval ?? 0) > 0
    ) {
      setValue("junkRemoval", junkRemovalOptions[0], {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  }, [junkRemovalOptions, quote?.pricing.junkRemoval]);

  useEffect(() => {
    trigger();
  }, [discountsCouponsOptions.length]);

  return {
    control,
    formState: { isValid, isSubmitting },
    junkRemovalOptions,
    discountsCouponsOptions,
    watch,
    setValue,
    handleSubmit,
  };
};
