import { FC, memo } from "react";
import { FormControlLabel, FormControlLabelProps } from "@material-ui/core";
import clsx from "clsx";

import { Radio, useStyles } from "./styles";

export const RadioButton: FC<
  Omit<FormControlLabelProps, "control"> & {
    classes?: {
      label?: string;
    };
  }
> = memo(({ label, classes, ...rest }) => {
  const styles = useStyles();

  return (
    <FormControlLabel
      {...rest}
      control={<Radio color="primary" />}
      label={label}
      classes={{
        label: clsx(styles.label, classes?.label),
      }}
    />
  );
});
