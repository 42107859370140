export type DescriptionOfChangesFormValues = {
  changeOptions: number[];
  additionalNotes: string;
  descriptionOfSituation: string;
};

export enum AddendumChangeOptionIdsEnum {
  AdditionalHouseholdGoods = 1,
  PackingServicesRequired = 2,
  UnforeseeableCircumstances = 3,
}
