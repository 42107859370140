import { withStyles, createStyles, List, makeStyles } from "@material-ui/core";

export const ListStyled = withStyles(() =>
  createStyles({
    root: {
      padding: "0px",
    },
  }),
)(List);

export const useStyles = makeStyles(() => ({
  parentList: {
    margin: "-8px",
  },
}));
