import { Button } from "@chhjpackages/components";
import { withStyles } from "@material-ui/core";

export const BottomPageButton = withStyles({
  root: {
    lineHeight: "21px",
    fontSize: 18,
    borderRadius: 0,
    padding: "15px 19px",
  },
})(Button);
