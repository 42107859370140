import { fetcher } from "shared/libs";

import { GenerateInspection } from "./types";

export const generateInspection = ({
  locationId,
  appointmentId,
  payload,
}: GenerateInspection.Params) =>
  fetcher.post<GenerateInspection.Response>(
    `/locations/${locationId}/appointments/${appointmentId}/inspection`,
    payload,
  );
