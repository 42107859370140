import { createStore } from "effector";
import { createEffect } from "effector/compat";

import { getAppointmentFx } from "features/appointment";
import { Product } from "shared/types";
import { addProduct, setProducts, deleteProduct, editProduct } from "../api";
import { getProductsFromResponse } from "../utils";

export const addProductFx = createEffect(addProduct);
export const setProductsFx = createEffect(setProducts);
export const editProductFx = createEffect(editProduct);
export const deleteProductFx = createEffect(deleteProduct);

export const $products = createStore<{
  products: Product[];
  productsAppointmentId?: number;
  loading: boolean;
}>({
  products: [],
  productsAppointmentId: undefined,
  loading: true,
});

// GET
$products
  .on(getAppointmentFx.done, (store, response) => ({
    ...store,
    products: response.result.data.appointments[0].products,
    appointmentId: response.params.appointmentId,
  }))
  .on(getAppointmentFx.pending, (store, loading) => ({
    ...store,
    loading,
  }));

// POST
$products.on(setProductsFx.doneData, (store, response) => ({
  ...store,
  products: getProductsFromResponse(response.data.products),
}));

$products.on(addProductFx.doneData, (store, response) => ({
  ...store,
  products: getProductsFromResponse(response.data.products),
}));

// PUT
$products.on(editProductFx.doneData, (store, response) => ({
  ...store,
  products: store.products.map((product) =>
    product.productLineId === response.data.products[0].id
      ? getProductsFromResponse([response.data.products[0]])[0]
      : product,
  ),
}));

// DELETE
$products.on(deleteProductFx.doneData, (store, response) => ({
  ...store,
  products: store.products.filter(
    (product) => product.productLineId !== response.productLineId,
  ),
}));
