import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import { colors } from "@chhjpackages/components";
import { memo } from "react";
import { Skeleton } from "@material-ui/lab";

export const AppointmentGridViewSkeletonCard = memo(() => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginRight: isMobile ? 0 : 16,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: colors.basic.white,
          border: isMobile ? "none" : `solid 1px ${colors.grey20}`,
          borderRadius: isMobile ? 0 : 8,
          maxWidth: isMobile ? "100%" : 328,
          minWidth: isMobile ? "100%" : 328,
          flex: 1,
          padding: 16,
        }}
      >
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <Skeleton variant="text" width={30} />
              </Grid>
              <Grid item>
                <Skeleton variant="text" width={50} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Skeleton variant="text" width={100} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
});
