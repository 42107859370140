import { useMediaQuery, useTheme } from "@material-ui/core";
import { useStore } from "effector-react/compat";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import moment from "moment";

import {
  PaymentForm,
  PaymentFormSkeleton,
  PaymentFormValues,
  PaymentMethodsEnum,
} from "features/payments";
import { $appointmentStore, getAppointmentFx } from "features/appointment";
import { $auth } from "features/auth";
import { apiDateFormat, routePaths } from "shared/utils";
import { BackTitle } from "shared/ui";
import { useSideNavDispatch } from "features/sidenav";
import {
  $terminals,
  createHunkPayPayment,
  getTerminalsFx,
} from "entities/hunk-pay";
import {
  PaymentProviderEnum,
  createPayment,
  usePaymentProvider,
} from "entities/payments";

import { useCollectStyles } from "./assets";

export const PaymentCollect = memo(() => {
  const styles = useCollectStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: true,
  });

  const { appointmentId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const { locationId } = useStore($auth);
  const { appointment, loading: appointmentLoading } =
    useStore($appointmentStore);
  const {
    terminals,
    locationId: terminalsLocationId,
    loading: terminalsLoading,
  } = useStore($terminals);

  const {
    setPageName,
    setShowGoBackButton,
    setGoToBackUrl,
    setGoToBackOptions,
  } = useSideNavDispatch();

  const paymentProvider = usePaymentProvider();

  const [isSubmitting, setSubmitStatus] = useState(false);
  const [isCardProcessing, setIsCardProcessing] = useState(false);

  const isSquare = useMemo(
    () => paymentProvider === PaymentProviderEnum.Square,
    [paymentProvider],
  );

  const isLoading = useMemo(
    () =>
      appointmentLoading ||
      paymentProvider === null ||
      (isSquare ? false : terminalsLoading),
    [appointmentLoading, terminalsLoading, paymentProvider, isSquare],
  );

  const goToBackUrl = useMemo(
    () => routePaths.jobDetailsPayments(Number(appointmentId)),
    [appointmentId],
  );

  const pageName = useMemo(
    () =>
      isSubmitting && isCardProcessing ? "Process card" : "Payment method",
    [isSubmitting, isCardProcessing],
  );

  const paymentFormInitialValues = useMemo(() => {
    const amount =
      appointment && appointment.balanceDue > 0 ? appointment.balanceDue : 0;

    return {
      amount: amount.toFixed(2),
    };
  }, [appointment]);

  const handleClickBack = useCallback(() => {
    navigate(goToBackUrl, { state: location.state });
  }, [goToBackUrl, location.state, navigate]);

  const handleSubmitPaymentForm = useCallback(
    async (data: PaymentFormValues) => {
      if (!locationId || !Number(appointmentId)) {
        return;
      }

      setSubmitStatus(true);

      let isSuccess = 1;
      try {
        if (data.terminal?.value) {
          setIsCardProcessing(true);

          await createHunkPayPayment({
            locationId: locationId,
            appointmentId: Number(appointmentId),
            payload: {
              type: "POS-Customer-Card",
              amount: Number(data.amount) * 100,
              pos: {
                serialNumber: data.terminal.value,
              },
              redirect: window.location.href,
              isSwipe: !data.isContactless,
            },
          });

          setIsCardProcessing(false);
        } else if (appointment) {
          await createPayment({
            locationId: locationId,
            appointmentId: Number(appointmentId),
            payload: {
              appointment: {
                id: appointment.id,
              },
              account: {
                id: appointment.account.id,
              },
              payment: {
                amount: Number(data.amount),
                authCode: data.authCode || undefined,
                batch:
                  data.depositDate || data.depositNumber
                    ? `${
                        data.depositDate
                          ? moment(data.depositDate).format("YYYYMMDD")
                          : ""
                      }-${data.depositNumber ?? ""}`
                    : undefined,
                checkNumber: data.checkNumber || undefined,
                date: moment(data.paymentDate).format(apiDateFormat),
                memo: data.notes || undefined,
                transactionId: data.transactionId || undefined,
                ...(data.method.value === PaymentMethodsEnum.CreditCard &&
                paymentProvider === PaymentProviderEnum.Manual
                  ? {
                      creditCard: {
                        address:
                          [
                            ...(data.address1 ? [data.address1] : []),
                            ...(data.address2 ? [data.address2] : []),
                          ].join(", ") || undefined,
                        expiryMonth: data.expMonth?.value || undefined,
                        expiryYear: data.expYear?.value || undefined,
                        name: data.cardName || undefined,
                        number: data.cardNumber || undefined,
                        typeId: data.cardType?.value || undefined,
                        zip: data.zip || undefined,
                      },
                    }
                  : []),
                method: {
                  id: data.method.value,
                },
              },
            },
          });
        }
      } catch {
        isSuccess = 0;
      }

      navigate(
        {
          pathname: routePaths.jobDetailsPaymentsResult(Number(appointmentId)),
          search: `${createSearchParams({ success: String(isSuccess) })}`,
        },
        { state: location.state },
      );

      setSubmitStatus(false);
    },
    [
      appointmentId,
      locationId,
      appointment,
      location.state,
      paymentProvider,
      navigate,
    ],
  );

  useEffect(() => {
    setPageName(pageName);
    setShowGoBackButton();
    setGoToBackUrl(goToBackUrl);
    setGoToBackOptions({ state: location.state });

    return () => {
      setPageName("");
      setGoToBackUrl("");
      setShowGoBackButton();
      setGoToBackOptions(undefined);
    };
  }, [
    goToBackUrl,
    pageName,
    location.state,
    setPageName,
    setShowGoBackButton,
    setGoToBackUrl,
    setGoToBackOptions,
  ]);

  useEffect(() => {
    if (!isSquare && locationId && terminalsLocationId !== locationId) {
      getTerminalsFx({
        locationId: locationId,
      });
    }
  }, [isSquare, locationId]);

  useEffect(() => {
    if (
      Number(appointmentId) &&
      locationId &&
      appointment?.id !== Number(appointmentId)
    ) {
      getAppointmentFx({
        appointmentId: Number(appointmentId),
        locationId: locationId,
      });
    }
  }, [appointmentId, appointment?.id, locationId]);

  return (
    <>
      {!isMobile && <BackTitle title={pageName} onBack={handleClickBack} />}

      <div className={styles.root}>
        {isLoading ? (
          <PaymentFormSkeleton />
        ) : (
          <PaymentForm
            terminals={terminals}
            initialValues={paymentFormInitialValues}
            onSubmit={handleSubmitPaymentForm}
          />
        )}
      </div>
    </>
  );
});
