import {
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { RoomOutlined } from "@material-ui/icons";
import moment from "moment";
import { colors, Paper, ProgressBar } from "@chhjpackages/components";
import {
  MouseEvent as ReactMouseEvent,
  memo,
  useCallback,
  useMemo,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { useStore } from "effector-react";

import { ListViewItem } from "shared/ui";
import { PersonIcon, TruckIcon } from "shared/assets";
import {
  AppointmentTypeCategoryStatusView,
  generateRoute,
  useStyles,
} from "features/appointment";
import { createAddressString, createChipText, routePaths } from "shared/utils";
import { $teamsStore } from "features/teams";
import { StepStatusesEnum } from "shared/types";
import { useSteps } from "features/appointment/model/hooks";

import { AppointmentCardItemProps } from "./types";

export const AppointmentListViewCard = memo(
  ({
    actualPeriod,
    isActual,
    appointment,
    currentTeams,
    handleOpenContactsDialog,
    handleOpenNotesDialog,
    handleOpenMapTypesDialog,
    handlePickUp,
  }: AppointmentCardItemProps) => {
    const styles = useStyles();
    const theme = useTheme();
    const isUpXs = useMediaQuery(theme.breakpoints.up("xs"), { noSsr: true });
    const isUpMd = useMediaQuery(theme.breakpoints.up("md"), { noSsr: true });

    const { allTeams } = useStore($teamsStore);

    const [isPickingUpScheduleId, setIsPickingUpScheduleId] = useState<
      number | null
    >(null);

    const {
      steps,
      actualStepName,
      isAllStepsDone,
      totalStepsNumber,
      currentStepNumber,
      nextStep,
      mainAction,
    } = useSteps({ appointmentOuter: appointment });

    const { originString, destinationString, stopsString } = useMemo(
      () => generateRoute(appointment),
      [appointment],
    );

    const sortedSchedules = useMemo(
      () =>
        appointment.schedules.sort((a, b) =>
          moment(a.startDate).diff(moment(b.startDate)),
        ),
      [appointment.schedules],
    );

    const originAddress = useMemo(
      () =>
        createAddressString(
          appointment.origin.address,
          appointment.origin.address2,
          appointment.origin.city,
          appointment.origin.state,
          appointment.origin.postal,
        ),
      [appointment.origin],
    );

    const destinationAddress = useMemo(
      () =>
        createAddressString(
          appointment.destination.address,
          appointment.destination.address2,
          appointment.destination.city,
          appointment.destination.state,
          appointment.destination.postal,
        ),
      [appointment.destination],
    );

    const isDiplayProgressBar = useMemo(() => {
      if (isAllStepsDone) {
        return false;
      }

      const stepsValues = Object.values(steps);
      const numberStepsInToBeDone = stepsValues.filter(
        (stepValue) => stepValue === StepStatusesEnum.ToBeDone,
      ).length;
      return numberStepsInToBeDone !== stepsValues.length;
    }, [steps, isAllStepsDone]);

    const isAssigned = useMemo(
      () =>
        !!currentTeams.find(
          (team) =>
            !!sortedSchedules.find((schedule) => schedule.team.id === team.id),
        ),
      [currentTeams, sortedSchedules],
    );

    const userName = useMemo(
      () =>
        [
          appointment.account.firstName,
          isAssigned
            ? appointment.account.lastName
            : `${appointment.account.lastName[0]}.` ?? "",
        ]
          .filter((item) => !!item)
          .join(" "),
      [appointment.account.firstName, appointment.account.lastName, isAssigned],
    );

    const onPickUp = useCallback(
      async (appointmentId: number, scheduleId: number) => {
        setIsPickingUpScheduleId(scheduleId);
        await handlePickUp(appointmentId, scheduleId);
        setIsPickingUpScheduleId(null);
      },
      [handlePickUp],
    );

    return (
      <Link
        to={routePaths.jobDetails(appointment.id)}
        style={{
          textDecoration: "none",
        }}
      >
        <Paper variant="outlined">
          <div
            style={{
              padding: 16,
              maxWidth: isUpMd ? 340 : isUpXs ? "100%" : "initial",
              minWidth: isUpMd ? 340 : isUpXs ? "100%" : "initial",
            }}
          >
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ color: colors.grey160 }}>
                    <Typography
                      variant="h5"
                      color="inherit"
                      ref={isActual ? actualPeriod : null}
                    >
                      {appointment.id}
                    </Typography>
                  </div>

                  {!!appointment.subpartner.id && (
                    <Typography variant="h5" color="secondary">
                      Nasa
                    </Typography>
                  )}
                </div>
              </Grid>

              <Grid item>
                <Grid
                  container
                  alignItems="center"
                  justify="space-between"
                  wrap="nowrap"
                  spacing={1}
                >
                  <Grid item>
                    <AppointmentTypeCategoryStatusView
                      type={appointment.type}
                      status={
                        appointment.type === "EST"
                          ? appointment.estStatus.name
                          : appointment.status.name
                      }
                      category={appointment.category.name}
                      zone={appointment.zone.name}
                    />
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h5"
                      component="span"
                      onClick={(e: ReactMouseEvent<HTMLSpanElement>) => {
                        e.preventDefault();
                        e.stopPropagation();

                        handleOpenNotesDialog(appointment.id);
                      }}
                      className={styles.textButton}
                    >
                      Notes
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {originString && (
                <Grid item>
                  <iframe
                    title="job-marker-map"
                    width="100%"
                    height="100%"
                    style={{ border: 0 }}
                    frameBorder={0}
                    loading="lazy"
                    allowFullScreen
                    src={`https://www.google.com/maps/embed/v1/${
                      destinationString ? "directions" : "place"
                    }?key=${process.env.REACT_APP_GOOGLE_API_KEY}&${
                      destinationString
                        ? `origin=${originString}&destination=${destinationString}${
                            stopsString ? "&waypoints=" + stopsString : ""
                          }`
                        : `q=${originString}`
                    }`}
                  />
                </Grid>
              )}

              {userName && (
                <>
                  <Grid item>
                    <ListViewItem
                      icon={<PersonIcon fontSize={24} />}
                      text={<b>{userName}</b>}
                      actions={[
                        {
                          title: "Contact",
                          onClick: () =>
                            handleOpenContactsDialog(appointment.id),
                        },
                      ]}
                    />
                  </Grid>

                  <Grid item>
                    <Divider />
                  </Grid>
                </>
              )}

              {originAddress && (
                <>
                  <Grid item>
                    <ListViewItem
                      icon={
                        <div style={{ color: colors.grey80 }}>
                          <RoomOutlined
                            color="inherit"
                            style={{ display: "block" }}
                          />
                        </div>
                      }
                      text={originAddress}
                      actions={[
                        {
                          title: "Navigate",
                          onClick: () =>
                            handleOpenMapTypesDialog(
                              appointment.id,
                              originAddress,
                              // true,
                            ),
                        },
                      ]}
                    />
                  </Grid>

                  <Grid item>
                    <Divider />
                  </Grid>
                </>
              )}

              {destinationAddress && (
                <>
                  <Grid item>
                    <ListViewItem
                      icon={
                        <div style={{ color: colors.grey80 }}>
                          <RoomOutlined
                            color="inherit"
                            style={{ display: "block" }}
                          />
                        </div>
                      }
                      text={destinationAddress}
                      actions={[
                        {
                          title: "Navigate",
                          onClick: () =>
                            handleOpenMapTypesDialog(
                              appointment.id,
                              destinationAddress,
                            ),
                        },
                      ]}
                    />
                  </Grid>

                  <Grid item>
                    <Divider />
                  </Grid>
                </>
              )}

              {sortedSchedules.map((schedule, i) => (
                <>
                  <Grid item key={schedule.id}>
                    <ListViewItem
                      icon={<TruckIcon fontSize={24} />}
                      text={`${moment(schedule.startDate).format(
                        "h:mm A",
                      )} - ${moment(schedule.endDate).format("h:mm A")}`}
                      chips={[
                        {
                          text: schedule.resource.name,
                        },
                        {
                          text: createChipText([schedule], allTeams),
                        },
                      ].filter((item) => item.text)}
                      actions={[
                        {
                          title: "Pick up",
                          disabled: !!schedule.team.id,
                          loading: isPickingUpScheduleId === schedule.id,
                          style: { whiteSpace: "nowrap" },
                          onClick: () => onPickUp(appointment.id, schedule.id),
                        },
                      ]}
                    />
                  </Grid>
                  {i + 1 !== sortedSchedules.length && (
                    <Grid item key={`${schedule.id}-divider`}>
                      <Divider />
                    </Grid>
                  )}
                </>
              ))}

              {isDiplayProgressBar && (
                <>
                  <Grid item>
                    <Divider />
                  </Grid>
                  <Grid
                    item
                    style={{ width: "100%" }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <ProgressBar
                      totalCount={totalStepsNumber}
                      currentCount={currentStepNumber}
                      title={actualStepName ?? ""}
                      nextStep={nextStep}
                      mainAction={mainAction}
                      isMobile={true}
                      classes={{
                        paper: styles.progressBarPaper,
                        wrapper: styles.progressBarWrapper,
                      }}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        </Paper>
      </Link>
    );
  },
);
