import { Metric, Rankings } from "shared/types";

export const getSortedRankingsFromMetrics = (array: Metric[]) => {
  const objects = {} as Rankings;

  objects.sales = array.sort(function (a, b) {
    return b.sales - a.sales;
  });
  objects.average_job = array.slice().sort(function (a, b) {
    return b.average_job - a.average_job;
  });
  objects.completion_percent = array.slice().sort(function (a, b) {
    return b.completion_percent - a.completion_percent;
  });
  objects.sales_per_hour = array.slice().sort(function (a, b) {
    return b.sales_per_hour - a.sales_per_hour;
  });
  return objects;
};

export const zeroIfNanOrInf = (value: number) => {
  const isNan = Number.isNaN(value);
  const isInf = !Number.isFinite(value);
  return isInf || isNan ? 0 : value;
};

export const getRankingsInOrder = (userMetrics: any) => {
  const userMetricsData = userMetrics?.userMetrics;
  // const metricsDataJson = require("./user-metrics-mock.json");
  // const userMetricsData = metricsDataJson.user_metrics;
  const calculatedMetrics: Metric[] = userMetricsData.map((item: any) => {
    const { totals, user } = item;
    const { hasRevenue, overall } = totals.appointments;
    const completionPercent = (hasRevenue / overall) * 100;
    const averageJob = zeroIfNanOrInf(totals.subTotal / hasRevenue);

    const timesheetHours = user.timesheets.reduce(
      (pV: number, cV: { test: string; hours: number }) => {
        return pV + cV.hours;
      },
      0,
    );

    const salesPerHour = zeroIfNanOrInf(totals.subTotal / timesheetHours);

    return {
      userId: user.id,
      username: user.name,
      userUrl: user.imageUrl,
      sales: totals.subTotal?.toFixed(2),
      average_job: averageJob?.toFixed(2),
      completion_percent: parseFloat(completionPercent?.toFixed(2)),
      sales_per_hour: parseFloat(salesPerHour?.toFixed(2)),
    };
  }, null);

  const rankings = getSortedRankingsFromMetrics(calculatedMetrics);

  return rankings;
};
