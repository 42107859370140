import { makeStyles } from "@material-ui/core";

export const useSignatureStyles = makeStyles(() => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#f2f2f2",
  },
  pdfViewerContainer: {
    padding: "16px 8px",
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  pdfViewerWrapper: {
    maxWidth: 800,
    width: "100%",
  },
  signatureArea: {
    position: "absolute",
    width: "38.1%",
    height: "3.35%",
    opacity: 0.5,
  },
}));
