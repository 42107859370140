import { Button } from "@chhjpackages/components";
import { Box, Grid, InputAdornment, Typography } from "@material-ui/core";
import { memo, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { FormIncrementInput, FormInput, FormSelect } from "shared/ui";
import { calculateMinutesAndHours } from "shared/utils";

import { useStyles } from "./assets";
import { AddEditProductFormValues, AddEditProductFormProps } from "./model";

const getAddEditProductFormSchema = (minQuantity: number) =>
  yup.object({
    quantity: yup
      .number()
      .min(minQuantity)
      .test("quantity", (value) => Number(value) % 0.25 === 0),
    price: yup.string().typeError("Price is required"),
    taxId: yup.number().nullable(),
    notes: yup.string(),
  });

export const AddEditProductForm = memo(
  ({
    initialValues,
    taxCodes,
    isLabor,
    travelTime,
    workTime,
    mode = "add",
    minQuantity,
    disableAddAsNew,
    onClose,
    onSave,
    onAddAsNew,
  }: AddEditProductFormProps) => {
    const styles = useStyles();

    const { control, formState, setValue, watch, handleSubmit } =
      useForm<AddEditProductFormValues>({
        mode: "all",
        defaultValues: {
          ...initialValues,
          price: Number(initialValues?.price).toFixed(2),
          taxId: taxCodes.length === 0 ? null : initialValues?.taxId || null,
        },
        resolver: yupResolver(
          getAddEditProductFormSchema(
            minQuantity ?? (mode === "add" ? 0 : 0.25),
          ),
        ),
      });

    const priceWatched = watch("price");
    const quantityWatched = watch("quantity");

    const [isPriceEditable, setIsPriceEditable] = useState(false);

    const taxStatusOptions = useMemo(
      () =>
        taxCodes.reduce(
          (acc, cur) => ({
            ...acc,
            [cur.id]: cur.name,
          }),
          {},
        ),
      [taxCodes],
    );

    return (
      <form>
        {isLabor && (
          <Box mb={1.25}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
              m={-0.5}
            >
              <Box p={0.5}>
                <Typography variant="caption">
                  <b>Travel time:</b>{" "}
                  {calculateMinutesAndHours(
                    travelTime ?? 0,
                    "string",
                    "billable",
                  )}
                </Typography>
              </Box>
              <Box p={0.5}>
                <Typography variant="caption">
                  <b>Work time:</b>{" "}
                  {calculateMinutesAndHours(
                    workTime ?? 0,
                    "string",
                    "billable",
                  )}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
        <Typography variant="subtitle1" style={{ lineHeight: "24px" }}>
          Quantity
        </Typography>
        <Box mt={1.25}>
          <FormIncrementInput
            name="quantity"
            control={control}
            min={minQuantity ?? (mode === "add" ? 0 : 0.25)}
            mask="positive-two-decimal"
          />
        </Box>
        <Box mt={1.25} display="flex">
          <Box mr={1} flex={1}>
            <FormInput
              name="price"
              control={control}
              label="Price"
              mask="positive-two-decimal"
              onBlur={() =>
                setValue("price", Number(priceWatched).toFixed(2), {
                  shouldDirty: true,
                })
              }
              className={styles.priceInput}
              disabled={!isPriceEditable}
              startAdornment={
                <InputAdornment
                  position="start"
                  disablePointerEvents={true}
                  className={styles.inputAdornment}
                >
                  $
                </InputAdornment>
              }
            />
          </Box>
          <Button
            buttonType="text"
            color="primary"
            disabled={!!formState.errors.price}
            classes={{ root: styles.saveEditPriceButtonRoot }}
            onClick={() => setIsPriceEditable((prevValue) => !prevValue)}
          >
            {isPriceEditable ? "Save" : "Edit"}
          </Button>
        </Box>
        <Box mt={1.25}>
          <FormSelect
            name="taxId"
            control={control}
            label="Tax status"
            options={taxStatusOptions}
          />
        </Box>
        <Box mt={1.25}>
          <FormInput name="notes" control={control} label="Notes" />
        </Box>
        <Box mt={3}>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <Button
                buttonType="twoTone"
                fullWidth
                disabled={
                  !formState.isValid ||
                  (mode === "edit" && !formState.isDirty) ||
                  isPriceEditable
                }
                onClick={handleSubmit(onSave)}
              >
                Save
              </Button>
            </Grid>
            {!!onAddAsNew && (
              <Grid item>
                <Button
                  buttonType="outlined"
                  fullWidth
                  disabled={
                    !formState.isValid ||
                    isPriceEditable ||
                    quantityWatched === 0 ||
                    disableAddAsNew
                  }
                  onClick={handleSubmit(onAddAsNew)}
                >
                  Add as new
                </Button>
              </Grid>
            )}
            <Grid item>
              <Button
                buttonType="text"
                color="primary"
                fullWidth
                onClick={onClose}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    );
  },
);
