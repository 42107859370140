import { colors } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  root: {
    minHeight: 65,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "8px 16px",
  },
  title: {
    color: colors.black,
  },
}));
