import { fetcher } from "shared/libs";
import {
  GetUserResponse,
  UpdateUser,
  UpdateUserEmail,
  UpdateUserPhone,
  UpdateUserAvatar,
} from "../types";

export const getUser = () => fetcher.get<GetUserResponse>("/users/me");

export const updateUser = async ({
  locationId,
  userId,
  payload,
}: UpdateUser.Params) =>
  fetcher.put<UpdateUser.Response>(
    `/locations/${locationId}/users/${userId}`,
    payload,
  );

export const updateUserEmail = async ({ payload }: UpdateUserEmail.Params) =>
  fetcher.post<UpdateUserEmail.Response>("/verify/send/email", payload);

export const updateUserPhone = async ({ payload }: UpdateUserPhone.Params) =>
  fetcher.post<UpdateUserPhone.Response>("/verify/send/sms", payload);

export const updateUserAvatar = ({
  locationId,
  userId,
  payload,
}: UpdateUserAvatar.Params) =>
  fetcher.post<UpdateUserAvatar.Response>(
    `/locations/${locationId}/users/${userId}/images`,
    payload,
  );
