import {
  createStyles,
  withStyles,
  Theme,
  ListItemText,
  ListItem,
  makeStyles,
} from "@material-ui/core";
import { colors, RiftFont, NoahFont } from "@chhjpackages/components";

export const ListItemStyled = withStyles(() =>
  createStyles({
    root: {
      margin: "8px 0px",
      padding: "8px 8px",
      borderRadius: "4px",
      transition: "background-color 250ms ease-out",
      "& .MuiTypography-root": {
        transition: "color 250ms ease-out",
      },
      "& path": {
        transition: "fill 250ms ease-out",
        fill: colors.primary.main,
      },
      "&:hover": {
        backgroundColor: colors.white,
        "& .MuiTypography-root": {
          color: colors.primary.main,
        },
        "& path": {
          fill: colors.primary.main,
        },
      },
      "& .MuiTouchRipple-root": {
        color: colors.primary.main,
      },
      "&:first-child": {
        margin: "0px",
        padding: "15px 16px",
      },
    },
    selected: {
      background: "none!important",
      "& .MuiTypography-root": {
        color: colors.primary.main,
      },
      "& path": {
        fill: colors.primary.main,
      },
    },
  }),
)(ListItem);

export const NestedListItemStyled = withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "8px",
      [theme.breakpoints.down("sm")]: {
        padding: "0",
      },
      borderRadius: "4px",
      transition: "background-color 250ms ease-out",
      "& .MuiTypography-root": {
        transition: "color 250ms ease-out",
      },
      "&:hover": {
        backgroundColor: colors.primary.superlight,
        "& .MuiTypography-root": {
          color: colors.primary.main,
        },
      },
      "& .MuiTouchRipple-root": {
        color: colors.primary.main,
      },
    },
    selected: {
      background: "none!important",
      "& .MuiTypography-root": {
        color: colors.primary.main,
      },
    },
  }),
)(ListItem);

export const NestedListItemTextStyled = withStyles(() =>
  createStyles({
    root: {
      margin: "0px",
      padding: "0 32px 16px 16px",
      backgroundColor: colors.white,
    },
    primary: {
      whiteSpace: "pre-wrap",
      textAlign: "left",
      fontFamily: NoahFont,
      color: "rgba(17, 24, 32, 0.48)",
      fontSize: "18px",
      fontWeight: 400,
      lineHeight: "25px",
    },
  }),
)(ListItemText);

export const ListItemTextStyled = withStyles(() =>
  createStyles({
    root: {
      margin: "0px",
    },
    primary: {
      fontFamily: RiftFont,
      fontSize: "20px",
      lineHeight: "24px",
      fontWeight: "bold",
    },
  }),
)(ListItemText);

export const useStyles = makeStyles(() => ({
  arrowIcon: {
    transition: "transform 150ms ease-out",
    transform: "rotateZ(180deg)",
  },
  arrowIconDown: {
    transform: "rotateZ(0deg)",
  },
  sidenavMenu: {
    margin: "16px 0",
    backgroundColor: colors.white,
  },
  notesContent: {
    margin: "0px",
    padding: "0 32px 16px 16px",
    backgroundColor: colors.white,
    whiteSpace: "pre-wrap",
    textAlign: "left",
    fontFamily: NoahFont,
    color: "rgba(17, 24, 32, 0.48)",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "25px",

    "& p": {
      margin: 0,
    },
  },
}));
