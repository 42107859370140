export const allFieldsDefined = <T>(obj: T | undefined, keys: (keyof T)[]) => {
  if (!obj) {
    return false;
  }

  for (const key of keys) {
    if (obj[key] === undefined) {
      return false;
    }
  }

  return true;
};
