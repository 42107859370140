import { LocationUser, HunkTeam } from "./types";

export const filterUsers = (
  users: LocationUser[],
  userTeams: HunkTeam[],
  isRecent: boolean,
) => {
  const filteredUsers = {
    mostRecentUsers: [] as LocationUser[],
    otherUsers: [] as LocationUser[],
  };

  let mostRecentUsersId: number[] = [];

  if (userTeams.length > 0 && isRecent) {
    mostRecentUsersId = userTeams[userTeams.length - 1].users.map(
      (user) => user.id,
    );
  }

  users.forEach((user) => {
    if (mostRecentUsersId.includes(user.id)) {
      filteredUsers.mostRecentUsers.push(user);
    } else {
      filteredUsers.otherUsers.push(user);
    }
  });

  return filteredUsers;
};
