import { useForm } from "react-hook-form";
import { useCallback, useEffect, useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";

import { FormDamage, PreExistingDamageFormValues } from "../types";
import { preExistingDamageFormValidation } from "../utils";
import { Damage } from "entities/pre-existing-damage";
import { DamageForm, DamageFormSkeleton } from "../../ui/damage-form";

type Props = {
  initialValues?: {
    damages: Damage[];
  };
  isLoading?: boolean;
};

export const usePreExistingDamageForm = ({
  initialValues,
  isLoading,
}: Props) => {
  const initialDamages: FormDamage[] = useMemo(
    () =>
      initialValues?.damages.length
        ? initialValues.damages.map((damage) => ({
            damageId: Number(damage.id),
            description: damage.description,
            file: null,
            imageUrl: damage.image,
          }))
        : [
            {
              damageId: null,
              description: "",
              file: null,
              imageUrl: null,
            },
          ],
    [initialValues?.damages],
  );

  const form = useForm<PreExistingDamageFormValues>({
    mode: "all",
    defaultValues: {
      ...initialValues,
      damages: initialDamages,
    },
    resolver: yupResolver(preExistingDamageFormValidation),
  });

  const { reset } = form;

  const render = useCallback(
    () =>
      isLoading ? <DamageFormSkeleton /> : <DamageForm form={{ ...form }} />,
    [isLoading, form],
  );

  useEffect(() => {
    if (!isLoading && initialValues) {
      const defaultDamages = form.formState.defaultValues?.damages;

      reset({
        ...initialValues,
        damages: initialDamages.length ? initialDamages : defaultDamages,
      });
    }
  }, [isLoading]);

  return {
    form,
    render,
  };
};
