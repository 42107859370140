import {
  useState,
  useCallback,
  useRef,
  ChangeEvent,
  useEffect,
  memo,
} from "react";
import { Box, Grid, IconButton, Input, Typography } from "@material-ui/core";
import { colors, useDialogDispatch, Button } from "@chhjpackages/components";

import {
  ImageCropper,
  Avatar,
  ImportantBadge,
  DialogContentWrapper,
} from "shared/ui";
import { BoldPencilIcon } from "shared/assets";

import { useStyles } from "./styles";
import { PickAvatarProps } from "./types";

export const PickAvatar = memo(
  ({ avatarUrl, isMobile, onCropAvatarComplete }: PickAvatarProps) => {
    const styles = useStyles();
    const setDialog = useDialogDispatch();

    const inputAvatar = useRef<HTMLInputElement>();
    const [selectedAvatar, setSelectedAvatar] = useState<Blob>();
    const [selectedCroppedAvatar, setSelectedCroppedAvatar] = useState<Blob>();
    const [isOpenUploadPhoto, setIsOpenUploadPhoto] = useState(false);

    const handleOpenUploadPhotoDialog = useCallback(() => {
      setIsOpenUploadPhoto(true);
    }, []);

    const handleCloseUploadPhotoDialog = useCallback(() => {
      setIsOpenUploadPhoto(false);
      setSelectedAvatar(undefined);
    }, []);

    const onCrop = useCallback(
      (croppedAvatar: Blob) => {
        setSelectedCroppedAvatar(croppedAvatar);
        onCropAvatarComplete(croppedAvatar);
        handleCloseUploadPhotoDialog();
      },
      [handleCloseUploadPhotoDialog, onCropAvatarComplete],
    );

    const onChangeSelectedFile = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
          setSelectedAvatar(e.target.files[0]);
          handleOpenUploadPhotoDialog();
        }
      },
      [setSelectedAvatar, handleOpenUploadPhotoDialog],
    );

    const handleUploadPhoto = useCallback(() => {
      (inputAvatar.current?.firstChild as HTMLInputElement).click();
    }, [inputAvatar]);

    useEffect(() => {
      if (isOpenUploadPhoto === true) {
        setDialog({
          open: true,
          variant: "basic",
          title: "Upload profile photo",
          disableBackdropClick: true,
          dialogContent: (
            <DialogContentWrapper maxWidth={488}>
              {!selectedAvatar ? (
                <>
                  <ImportantBadge>
                    <Box color={colors.functionals.info}>
                      <Typography
                        variant="subtitle2"
                        color="inherit"
                        className={styles.infoMessage}
                      >
                        Please DO:{"\n"}
                        <ul>
                          <li>Wear a branded uniform</li>
                          <li>Smile</li>
                          <li>Have a plain background</li>
                        </ul>
                        {"\n"}
                        Please DO NOT:{"\n"}
                        <ul>
                          <li>Have other people in the picture</li>
                          <li>Add text or other embellishments</li>
                          <li>Show any unprofessional art, symbols, etc.</li>
                        </ul>
                      </Typography>
                    </Box>
                  </ImportantBadge>
                  <Box mt={2}>
                    <Button
                      buttonType="filled"
                      size="large"
                      fullWidth
                      onClick={handleUploadPhoto}
                    >
                      Upload photo
                    </Button>
                  </Box>
                  <Box mt={3} display="flex" justifyContent="flex-end">
                    <Button
                      buttonType="text"
                      color="primary"
                      onClick={handleCloseUploadPhotoDialog}
                    >
                      Close
                    </Button>
                  </Box>
                </>
              ) : (
                <ImageCropper
                  imageForCrop={selectedAvatar}
                  onCropperComplete={onCrop}
                  onClose={handleCloseUploadPhotoDialog}
                />
              )}
            </DialogContentWrapper>
          ),
          onClose: handleCloseUploadPhotoDialog,
        });
      } else if (isOpenUploadPhoto === false) {
        setDialog({ open: false });
      }
    }, [
      styles,
      isOpenUploadPhoto,
      selectedAvatar,
      handleCloseUploadPhotoDialog,
      handleUploadPhoto,
      onCrop,
      setDialog,
    ]);

    return (
      <Grid container alignItems="center" direction="column">
        <Grid item>
          <Input
            className={styles.inputAvatar}
            type="file"
            inputProps={{ accept: ".jpg,.png,.bmp" }}
            ref={inputAvatar}
            onChange={onChangeSelectedFile}
            onClick={(event) => {
              const element = event.target as HTMLInputElement;
              element.value = "";
            }}
          />
          <IconButton
            className={styles.avatarIconButton}
            onClick={() => handleOpenUploadPhotoDialog()}
          >
            <Avatar
              imageUrl={
                selectedCroppedAvatar
                  ? URL.createObjectURL(selectedCroppedAvatar)
                  : avatarUrl
              }
              isMobile={isMobile}
              icon={<BoldPencilIcon fontSize={14} />}
            />
          </IconButton>
        </Grid>
      </Grid>
    );
  },
);
