import { memo } from "react";

import { calculateMinutesAndHours } from "shared/utils";

import { TotalTimeProps } from "./model";
import { TotalTimeRow } from "./ui";
import { useStyles } from "./assets";

export const TotalTime = memo(
  ({
    timeTravelled,
    billableTimeTravelled,
    timeWorked,
    billableTimeWorked,
  }: TotalTimeProps) => {
    const styles = useStyles();

    return (
      <div className={styles.root}>
        <TotalTimeRow
          title="Time travelled:"
          time={calculateMinutesAndHours(timeTravelled, "string") as string}
        />

        <TotalTimeRow
          title="Billable time travelled:"
          time={
            calculateMinutesAndHours(
              billableTimeTravelled,
              "string",
              "billable",
            ) as string
          }
        />

        <TotalTimeRow
          title="Time Worked:"
          time={calculateMinutesAndHours(timeWorked, "string") as string}
        />

        <TotalTimeRow
          title="Billable time worked:"
          time={
            calculateMinutesAndHours(
              billableTimeWorked,
              "string",
              "billable",
            ) as string
          }
        />
      </div>
    );
  },
);
