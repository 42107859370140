import { colors } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles({
  infoIcon: {
    marginRight: "16px",
  },

  button: {
    "& .MuiButton-root": {
      padding: "8px 0 !important",
    },
  },

  textButton: {
    color: colors.primary.main,

    "&:hover": {
      color: colors.primary.dark,
    },

    "&:active": {
      color: colors.primary.light,
    },
  },

  disabledTextButton: {
    color: colors.grey120,
  },

  progressBarPaper: {
    border: "none",
  },

  progressBarWrapper: {
    padding: "0px!important",
  },
});
