import { Button } from "@chhjpackages/components";
import { Box } from "@material-ui/core";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { memo } from "react";

import { FormInput } from "shared/ui";

import {
  SendItemizedReceiptFormProps,
  SendItemizedReceiptFormValues,
} from "./model";

const sendItemizedReceiptSchema = yup.object({
  email: yup
    .string()
    .email("Must be a valid email")
    .required("Email is required"),
});

export const SendItemizedReceiptForm = memo(
  ({ initialValues, onSubmit }: SendItemizedReceiptFormProps) => {
    const { control, formState, handleSubmit } =
      useForm<SendItemizedReceiptFormValues>({
        mode: "all",
        defaultValues: initialValues,
        resolver: yupResolver(sendItemizedReceiptSchema),
      });

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInput control={control} name="email" required label="Email" />
        <Box mt={2}>
          <Button
            buttonType="twoTone"
            type="submit"
            disabled={!formState.isValid}
            isLoading={formState.isSubmitting}
            fullWidth
          >
            Send itemized receipt
          </Button>
        </Box>
      </form>
    );
  },
);
