import { Box, Typography } from "@material-ui/core";
import { Button, colors } from "@chhjpackages/components";
import { memo, useMemo } from "react";

import { ColorfulText, FormInput } from "shared/ui";

import { ForgotPasswordFormProps, useForgotPasswordForm } from "./model";

export const ForgotPasswordForm = memo(
  ({ isPasswordExpired, onSubmit }: ForgotPasswordFormProps) => {
    const {
      control,
      formState,
      method,
      setMethod,
      handleSubmit,
      onHandleSubmit,
    } = useForgotPasswordForm({
      onSubmit,
    });

    const title = useMemo(
      () => (isPasswordExpired ? "Password expired" : "Forgot password"),
      [isPasswordExpired],
    );

    return (
      <form onSubmit={handleSubmit(onHandleSubmit)}>
        <Box textAlign={{ xs: "left", sm: "center" }} color={colors.grey180}>
          <Typography variant="h3" color="inherit">
            {title}
          </Typography>
        </Box>

        <Box mt={{ xs: 1, sm: 3 }}>
          <ColorfulText textAlign={{ xs: "left", sm: "center" }}>
            Provide us with your information and we will send you a link to
            change the password.
          </ColorfulText>
        </Box>

        <Box mt={{ xs: 3, sm: 4 }}>
          <FormInput
            name="profileData"
            label="Username, email or phone number"
            control={control}
          />
        </Box>

        <Box marginTop={4}>
          <Box>
            <Button
              fullWidth
              buttonType="twoTone"
              color="secondary"
              type="submit"
              size="large"
              onClick={() => setMethod("email")}
              disabled={!formState.isDirty || !formState.isValid}
              isLoading={method === "email" && formState.isSubmitting}
            >
              Send me the link via email
            </Button>
          </Box>
          <Box mt={3}>
            <Button
              fullWidth
              buttonType="text"
              type="submit"
              size="large"
              color="secondary"
              onClick={() => setMethod("phone")}
              disabled={!formState.isValid}
              isLoading={method === "phone" && formState.isSubmitting}
            >
              Send me the link via text
            </Button>
          </Box>
        </Box>
      </form>
    );
  },
);
