import { useCallback, MouseEvent as MouseEventReact, memo } from "react";
import { IconButton, Typography } from "@material-ui/core";
import { ArrowUpward } from "@material-ui/icons";
import clsx from "clsx";

import { BackToTopButtonProps } from "./types";
import { useStyles } from "./styles";

export const BackToTopButton = memo(({ classes }: BackToTopButtonProps) => {
  const styles = useStyles();

  const handleToTopClick = useCallback(
    (e: MouseEventReact<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();

      document.scrollingElement?.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    [],
  );

  return (
    <IconButton
      color="inherit"
      className={clsx(styles.root, classes?.root)}
      onClick={handleToTopClick}
    >
      <div className={styles.content}>
        <div className={styles.iconContainer}>
          <ArrowUpward className={styles.icon} />
        </div>

        <Typography variant="button" className={styles.title}>
          Scroll Top
        </Typography>
      </div>
    </IconButton>
  );
});
