import {
  Collapse,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  Button,
  colors,
  ProgressBar,
  useDialogDispatch,
} from "@chhjpackages/components";
import { memo, useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { KeyboardArrowDown } from "@material-ui/icons";
import { useStore } from "effector-react";

import { createAddressString, createChipText, routePaths } from "shared/utils";
import {
  AppointmentTypeCategoryStatusView,
  PickUpForm,
  useStyles,
} from "features/appointment";
import { AppointmentActionList } from "../appointment-action-list";
import { $teamsStore } from "features/teams";
import { StepStatusesEnum } from "shared/types";
import { useSteps } from "features/appointment/model/hooks";
import { ColorfulBox, DialogContentWrapper } from "shared/ui";

import { AppointmentCardItemProps } from "./types";

export const AppointmentGridViewCard = memo(
  ({
    isActual,
    appointment,
    currentTeams,
    handleOpenContactsDialog,
    handleOpenNotesDialog,
    handleOpenMapTypesDialog,
    handlePickUp,
  }: AppointmentCardItemProps) => {
    const styles = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
      noSsr: true,
    });

    const { allTeams } = useStore($teamsStore);

    const {
      steps,
      actualStepName,
      isAllStepsDone,
      totalStepsNumber,
      currentStepNumber,
      nextStep,
      mainAction,
    } = useSteps({ appointmentOuter: appointment });
    const setDialog = useDialogDispatch();

    const [expanded, setExpanded] = useState<boolean>(!!isActual);
    const [isPickingUp, setIsPickingUp] = useState(false);

    const chipText = useMemo(
      () =>
        appointment.schedules.filter((schedule) => !!schedule.team.id).length >
        1
          ? "Multiple teams"
          : createChipText(appointment.schedules, allTeams),
      [allTeams, appointment.schedules],
    );

    const originAddress = useMemo(
      () =>
        createAddressString(
          appointment.origin.address,
          appointment.origin.address2,
          appointment.origin.city,
          appointment.origin.state,
          appointment.origin.postal,
        ),
      [appointment.origin],
    );

    const isNasa = useMemo(
      () => !!appointment.subpartner.id,
      [appointment.subpartner.id],
    );

    const isDiplayProgressBar = useMemo(() => {
      if (isAllStepsDone) {
        return false;
      }

      const stepsValues = Object.values(steps);
      const numberStepsInToBeDone = stepsValues.filter(
        (stepValue) => stepValue === StepStatusesEnum.ToBeDone,
      ).length;
      return numberStepsInToBeDone !== stepsValues.length;
    }, [steps, isAllStepsDone]);

    const isAssigned = useMemo(
      () =>
        !!currentTeams.find(
          (team) =>
            !!appointment.schedules.find(
              (schedule) => schedule.team.id === team.id,
            ),
        ),
      [currentTeams, appointment.schedules],
    );

    const userName = useMemo(
      () =>
        [
          appointment.account.firstName,
          isAssigned
            ? appointment.account.lastName
            : `${appointment.account.lastName[0]}.` ?? "",
        ]
          .filter((item) => !!item)
          .join(" "),
      [appointment.account.firstName, appointment.account.lastName, isAssigned],
    );

    const isPickUpDisabled = useMemo(
      () =>
        appointment.schedules.length ===
        appointment.schedules.filter((schedule) => !!schedule.team.id).length,
      [appointment.schedules],
    );

    const handleFilterExpand = useCallback(() => {
      setExpanded(!expanded);
    }, [expanded, setExpanded]);

    const onPickUp = useCallback(
      async (appointmentId: number, scheduleId: number) => {
        setIsPickingUp(true);
        await handlePickUp(appointmentId, scheduleId);
        setIsPickingUp(false);
      },
      [handlePickUp],
    );

    const handlePickUpWrapper = useCallback(
      async (appointmentId: number) => {
        if (appointment.schedules.length > 1) {
          setDialog({
            open: true,
            variant: "basic",
            title: "Select schedule to pick up",
            disableBackdropClick: true,
            dialogContent: (
              <DialogContentWrapper maxWidth={488}>
                <PickUpForm
                  schedules={appointment.schedules}
                  onClose={() => setDialog({ open: false })}
                  onSubmit={(data) => {
                    onPickUp(appointment.id, data.schedule.value);
                    setDialog({ open: false });
                  }}
                />
              </DialogContentWrapper>
            ),
            onClose: () => setDialog({ open: false }),
          });
        } else {
          await onPickUp(appointmentId, appointment.schedules[0].id);
        }
      },
      [appointment.schedules, appointment.id, onPickUp, setDialog],
    );

    const actions = useMemo(
      () => [
        {
          title: "Contact",
          onClick: () => handleOpenContactsDialog(appointment.id),
        },
        {
          title: "Navigate",
          onClick: () =>
            handleOpenMapTypesDialog(appointment.id, originAddress),
        },
        {
          title: "Pick up",
          disabled: isPickUpDisabled,
          loading: isPickingUp,
          onClick: () => handlePickUpWrapper(appointment.id),
        },
      ],
      [
        appointment.id,
        originAddress,
        isPickUpDisabled,
        isPickingUp,
        handleOpenContactsDialog,
        handleOpenMapTypesDialog,
        handlePickUpWrapper,
      ],
    );

    return (
      <div
        key={appointment.id}
        style={{
          marginRight: isMobile ? 0 : 16,
          marginBottom: isMobile ? 8 : 0,
        }}
      >
        <Link
          to={routePaths.jobDetails(appointment.id)}
          style={{ textDecoration: "none", cursor: "pointer" }}
        >
          <div
            style={{
              backgroundColor: colors.basic.white,
              border: isMobile ? "none" : `solid 1px ${colors.grey20}`,
              borderRadius: isMobile ? 0 : 8,
              maxWidth: isMobile ? "100%" : 350,
              minWidth: isMobile ? "100%" : 350,
              flex: 1,
              padding: 16,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h5"
                  style={{ color: colors.grey160, minWidth: 55 }}
                >
                  {appointment.id}
                </Typography>

                {isNasa && (
                  <Typography
                    variant="h5"
                    color="secondary"
                    style={{ marginLeft: 24 }}
                  >
                    Nasa
                  </Typography>
                )}
              </div>

              {chipText && (
                <ColorfulBox color={colors.black} marginLeft={24}>
                  {chipText}
                </ColorfulBox>
              )}
            </div>

            {userName && (
              <div style={{ marginTop: 8, color: colors.black }}>
                <Typography variant="subtitle2" color="inherit">
                  {userName}
                </Typography>
              </div>
            )}

            {originAddress && (
              <div style={{ marginTop: 8, color: colors.black }}>
                <Typography variant="caption" color="inherit">
                  {originAddress}
                </Typography>
              </div>
            )}

            <div style={{ marginTop: 8 }}>
              <Grid
                container
                alignItems="center"
                justify="space-between"
                wrap="nowrap"
              >
                <Grid item>
                  <AppointmentTypeCategoryStatusView
                    type={appointment.type}
                    status={
                      appointment.type === "EST"
                        ? appointment.estStatus.name
                        : appointment.status.name
                    }
                    category={appointment.category.name}
                    zone={appointment.zone.name}
                  />
                </Grid>

                <Grid item>
                  <div style={{ marginRight: -8 }}>
                    <Button
                      buttonType="text"
                      color="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleOpenNotesDialog(appointment.id);
                      }}
                      style={{ padding: "4px 8px" }}
                    >
                      <Typography variant="h5" color="primary">
                        Notes
                      </Typography>
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </div>
            {isDiplayProgressBar && (
              <div
                style={{
                  marginTop: 8,
                  width: "100%",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <ProgressBar
                  totalCount={totalStepsNumber}
                  currentCount={currentStepNumber}
                  title={actualStepName ?? ""}
                  nextStep={nextStep}
                  mainAction={mainAction}
                  isMobile={true}
                  classes={{
                    paper: styles.progressBarPaper,
                  }}
                />
              </div>
            )}
            {isMobile ? (
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <div style={{ marginTop: 10 }}>
                  <AppointmentActionList actions={actions} />
                </div>
              </Collapse>
            ) : (
              <div style={{ marginTop: 10 }}>
                <AppointmentActionList actions={actions} />
              </div>
            )}
          </div>
        </Link>
        {isMobile && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: 24,
              backgroundColor: colors.functionals.warningLight,
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleFilterExpand();
            }}
          >
            <IconButton>
              <KeyboardArrowDown
                style={expanded ? { transform: "rotateZ(180deg)" } : {}}
                color="primary"
              />
            </IconButton>
          </div>
        )}
      </div>
    );
  },
);
