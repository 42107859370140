import { useStore } from "effector-react/compat";
import { memo } from "react";

import { $appointmentStore, Notes } from "features/appointment";

export const NotesTab = memo(() => {
  const { appointment } = useStore($appointmentStore);

  return (
    <div style={{ padding: 16 }}>
      {appointment && <Notes appointment={appointment} />}
    </div>
  );
});
