import { createEffect, createStore } from "effector";
import moment from "moment";

import { $authorizedUser } from "features/auth";
import { USADateFormat } from "shared/utils";

import {
  createTeam,
  getTeamsByDate,
  getTeams,
  updateTeamSchedules,
} from "./api";
import { HunkTeam } from "./types";

export const getTeamsFx = createEffect(getTeams);
export const getTeamsByDateFx = createEffect(getTeamsByDate);
export const createTeamFx = createEffect(createTeam);
export const updateTeamSchedulesFx = createEffect(updateTeamSchedules);

export const $teamsStore = createStore<{
  allTeams: HunkTeam[];
  teamsByDate: HunkTeam[];
  userTeamsByDate: HunkTeam[];
  userTeams: HunkTeam[];
  loading: boolean;
}>({
  allTeams: [],
  teamsByDate: [],
  userTeamsByDate: [],
  userTeams: [],
  loading: true,
});

$teamsStore
  .on(getTeamsFx.doneData, (store, response) => {
    const { user } = $authorizedUser.getState();
    const userTeams = user?.id
      ? response.data.teams.filter((team) =>
          team.users.map((u) => u.id).includes(user.id),
        )
      : [];

    return {
      ...store,
      allTeams: response.data.teams,
      userTeams: userTeams,
    };
  })
  .on(getTeamsFx.pending, (store, loading) => ({
    ...store,
    loading,
  }));

$teamsStore
  .on(getTeamsByDateFx.doneData, (store, response) => {
    const { user } = $authorizedUser.getState();
    const userTeams = user?.id
      ? response.data.teams.filter((team) =>
          team.users.map((u) => u.id).includes(user.id),
        )
      : [];

    return {
      ...store,
      teamsByDate: response.data.teams,
      userTeamsByDate: userTeams,
    };
  })
  .on(getTeamsByDateFx.pending, (store, loading) => ({
    ...store,
    loading,
  }));

$teamsStore.on(createTeamFx.doneData, (store, response) => {
  const { user } = $authorizedUser.getState();
  const isUserTeam = user?.id
    ? !!response.data.teams.find((team) =>
        team.users.map((u) => u.id).includes(user.id),
      )
    : false;
  const isSameDate =
    moment().format(USADateFormat) ===
    moment(response.data.teams[0].date).format(USADateFormat);

  return {
    ...store,
    allTeams: [...store.allTeams, ...response.data.teams],
    teamsByDate: isSameDate
      ? [...(store.teamsByDate ?? []), ...response.data.teams]
      : store.teamsByDate,
    userTeamsByDate:
      isUserTeam && isSameDate
        ? [...(store.teamsByDate ?? []), ...response.data.teams]
        : store.teamsByDate,
    userTeams: isUserTeam
      ? [...store.userTeams, ...response.data.teams]
      : store.userTeams,
  };
});

$teamsStore.on(updateTeamSchedulesFx.doneData, (store, response) => ({
  ...store,
  allTeams: store.allTeams.map((team) =>
    team.id === response.data.teams[0].id ? response.data.teams[0] : team,
  ),
  teamsByDate: store.teamsByDate.map((team) =>
    team.id === response.data.teams[0].id ? response.data.teams[0] : team,
  ),
  userTeamsByDate: store.userTeamsByDate.map((team) =>
    team.id === response.data.teams[0].id ? response.data.teams[0] : team,
  ),
  userTeams: store.userTeams.map((team) =>
    team.id === response.data.teams[0].id ? response.data.teams[0] : team,
  ),
}));
