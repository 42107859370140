import { fetcher } from "shared/libs";
import { PaymentLinks } from "../types";

export const sendPaymentLink = ({
  locationId,
  appointmentId,
  payload,
}: PaymentLinks.Create) =>
  fetcher.post<PaymentLinks.Response>(
    `/locations/${locationId}/appointments/${appointmentId}/payments/link`,
    payload,
  );
