import { useEffect, useRef } from "react";

import {
  addBillableTimeTravelledEv,
  addBillableTimeWorkedEv,
  addTimeTravelledEv,
  addTimeWorkedEv,
} from "../store";

// OLD VERSION
export const useTimeTrack = ({
  travelTime,
  billableTravelTime,
  isTravelRunning,
  workTime,
  billableWorkTime,
  isWorkRunning,
}: {
  travelTime: number;
  billableTravelTime: number;
  isTravelRunning: boolean;
  workTime: number;
  billableWorkTime: number;
  isWorkRunning: boolean;
}) => {
  const billableWorkTimer = useRef<ReturnType<typeof setInterval> | null>(null);
  const workTimerRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const billableTravelTimerRef = useRef<ReturnType<typeof setInterval> | null>(
    null,
  );
  const travelTimerRef = useRef<ReturnType<typeof setInterval> | null>(null);

  useEffect(() => {
    if (isWorkRunning) {
      const reminderOfMinute =
        workTime > 60 ? 60 - (workTime % 60) : 60 - workTime;

      if (reminderOfMinute !== 0) {
        workTimerRef.current = setInterval(() => {
          addTimeWorkedEv({ seconds: reminderOfMinute });

          if (workTimerRef.current) {
            clearInterval(workTimerRef.current);
          }

          workTimerRef.current = setInterval(() => {
            addTimeWorkedEv({ seconds: 60 });
          }, 60 * 1000);
        }, reminderOfMinute * 1000);
      } else {
        workTimerRef.current = setInterval(() => {
          addTimeWorkedEv({ seconds: 60 });
        }, 60 * 1000);
      }
    } else {
      if (workTimerRef.current) {
        clearInterval(workTimerRef.current);
      }
    }

    return () => {
      if (workTimerRef.current) {
        clearInterval(workTimerRef.current);
      }
    };
  }, [isWorkRunning]);

  useEffect(() => {
    if (isWorkRunning) {
      const reminderOfMinute =
        billableWorkTime > 60
          ? 60 - (billableWorkTime % 60)
          : 60 - billableWorkTime;

      if (reminderOfMinute !== 0) {
        billableWorkTimer.current = setInterval(() => {
          addBillableTimeWorkedEv({ seconds: reminderOfMinute });

          if (billableWorkTimer.current) {
            clearInterval(billableWorkTimer.current);
          }

          billableWorkTimer.current = setInterval(() => {
            addBillableTimeWorkedEv({ seconds: 60 });
          }, 60 * 1000);
        }, reminderOfMinute * 1000);
      } else {
        billableWorkTimer.current = setInterval(() => {
          addBillableTimeWorkedEv({ seconds: 60 });
        }, 60 * 1000);
      }
    } else {
      if (billableWorkTimer.current) {
        clearInterval(billableWorkTimer.current);
      }
    }

    return () => {
      if (billableWorkTimer.current) {
        clearInterval(billableWorkTimer.current);
      }
    };
  }, [isWorkRunning]);

  useEffect(() => {
    if (isTravelRunning) {
      const reminderOfMinute =
        travelTime > 60 ? 60 - (travelTime % 60) : 60 - travelTime;

      if (reminderOfMinute !== 0) {
        travelTimerRef.current = setInterval(() => {
          addTimeTravelledEv({ seconds: reminderOfMinute });

          if (travelTimerRef.current) {
            clearInterval(travelTimerRef.current);
          }

          travelTimerRef.current = setInterval(() => {
            addTimeTravelledEv({ seconds: 60 });
          }, 60 * 1000);
        }, reminderOfMinute * 1000);
      } else {
        travelTimerRef.current = setInterval(() => {
          addTimeTravelledEv({ seconds: 60 });
        }, 60 * 1000);
      }
    } else {
      if (travelTimerRef.current) {
        clearInterval(travelTimerRef.current);
      }
    }

    return () => {
      if (travelTimerRef.current) {
        clearInterval(travelTimerRef.current);
      }
    };
  }, [isTravelRunning]);

  useEffect(() => {
    if (isTravelRunning) {
      const reminderOfMinute =
        billableTravelTime > 60
          ? 60 - (billableTravelTime % 60)
          : 60 - billableTravelTime;

      if (reminderOfMinute !== 0) {
        billableTravelTimerRef.current = setInterval(() => {
          addBillableTimeTravelledEv({ seconds: reminderOfMinute });

          if (billableTravelTimerRef.current) {
            clearInterval(billableTravelTimerRef.current);
          }

          billableTravelTimerRef.current = setInterval(() => {
            addBillableTimeTravelledEv({ seconds: 60 });
          }, 60 * 1000);
        }, reminderOfMinute * 1000);
      } else {
        billableTravelTimerRef.current = setInterval(() => {
          addBillableTimeTravelledEv({ seconds: 60 });
        }, 60 * 1000);
      }
    } else {
      if (billableTravelTimerRef.current) {
        clearInterval(billableTravelTimerRef.current);
      }
    }

    return () => {
      if (billableTravelTimerRef.current) {
        clearInterval(billableTravelTimerRef.current);
      }
    };
  }, [isTravelRunning]);
};
