import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },
  addMoreContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  addMoreButtonRoot: {
    margin: "-4px -10px",
  },
}));
