import { FC, memo } from "react";
import { useController } from "react-hook-form";
import { FormControl } from "@material-ui/core";
import { IncrementInput } from "@chhjpackages/components";

import { FormIncrementInputProps } from "./types";

export const FormIncrementInput: FC<
  Omit<FormIncrementInputProps, "onChange" | "value">
> = memo(({ control, name, ...rest }) => {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue: "",
  });

  return (
    <FormControl error={!!error} fullWidth>
      <IncrementInput
        {...rest}
        value={value}
        onChange={onChange}
        error={!!error}
      />
    </FormControl>
  );
});
