import { forwardRef, memo } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";

import { PdfViewerProps } from "./types";
import { useStyles } from "./styles";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const PdfViewer = memo(
  forwardRef<HTMLDivElement, PdfViewerProps>(
    (
      { documentProps, pageProps, pagesCount = 1, loading, width, pageContent },
      ref,
    ) => {
      const styles = useStyles();

      return (
        <>
          <Document
            loading=""
            noData=""
            externalLinkRel="_blank"
            externalLinkTarget="_blank"
            options={{ isEvalSupported: true }}
            {...documentProps}
          >
            <div
              className={clsx(
                styles.pagesList,
                loading && styles.pagesListLoading,
              )}
            >
              {Array.from({ length: pagesCount }, (_, i) => i + 1).map(
                (pageNumber, index) => (
                  <div key={pageNumber} ref={ref}>
                    <Page
                      width={width}
                      loading=""
                      pageNumber={index + 1}
                      pageIndex={1}
                      {...pageProps}
                    >
                      {pageContent && pageContent(pageNumber)}
                    </Page>
                  </div>
                ),
              )}
            </div>
          </Document>

          {loading && (
            <div className={styles.pagesList}>
              {Array.from({ length: pagesCount || 1 }, () => 0).map(
                (_, index) => (
                  <Skeleton
                    key={index}
                    variant="rect"
                    className={styles.skeleton}
                  />
                ),
              )}
            </div>
          )}
        </>
      );
    },
  ),
);
