import { SvgIcon } from "@material-ui/core";

export const EmptyBoxIcon = ({
  className,
  fontSize = 138,
}: {
  className?: string;
  fontSize?: number;
}) => {
  return (
    <SvgIcon
      className={className}
      style={{ fontSize }}
      width="138"
      height="166"
      viewBox="0 0 138 166"
      fill="none"
    >
      <svg
        width="138"
        height="166"
        viewBox="0 0 138 166"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M137.6 103.4L127.4 80.2002C127.4 80.1002 127.3 80.1002 127.3 80.0002L127.2 79.9002C127.1 79.8002 127.1 79.8002 127 79.8002L126.9 79.7002C126.8 79.7002 126.7 79.7002 126.7 79.6002C126.6 79.6002 126.6 79.6002 126.5 79.6002C126.4 79.6002 126.2 79.6002 126.1 79.7002L71.5 99.8002L16.9 79.7002C16.8 79.6002 16.6 79.6002 16.5 79.6002C16.4 79.6002 16.4 79.6002 16.3 79.6002C16.2 79.6002 16.1 79.6002 16.1 79.7002C16 79.7002 16 79.8002 15.9 79.8002C15.8 79.8002 15.8 79.9002 15.7 79.9002L15.6 80.0002C15.6 80.1002 15.5 80.1002 15.5 80.2002L5.29998 103.4C5.19998 103.7 5.19998 104 5.29998 104.3C5.39998 104.6 5.59998 104.8 5.89998 104.9L15.4 108.4V144.5C15.4 145 15.7 145.4 16.1 145.5L71.1 165.8C71.2 165.8 71.2 165.8 71.3 165.8H71.4H71.5C71.6 165.8 71.6 165.8 71.7 165.8L126.7 145.5C127.1 145.3 127.4 144.9 127.4 144.5V108.4L136.9 104.9C137.2 104.8 137.4 104.6 137.5 104.3C137.8 104 137.8 103.7 137.6 103.4Z"
          fill="#F0CBA7"
        />
        <path
          d="M12.2 101.9V138.7L67.1999 159V95.2L12.2 74.9"
          stroke="#EA7200"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2 98.1L57 118.4L67.2 95.2L12.2 74.9L2 98.1Z"
          stroke="#EA7200"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M122.1 101.9V138.7L67.1 159V95.2L122.1 74.9"
          stroke="#EA7200"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M132.3 98.1L77.4 118.4L67.1 95.2L122.1 74.9L132.3 98.1Z"
          stroke="#EA7200"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M87.5 37.5C87.5 27.4 79.3 19.2 69.2 19.2C59.1 19.2 50.9 27.4 50.9 37.5H61.3C61.3 33.2 64.8 29.7 69.1 29.7C73.4 29.7 76.9 33.2 76.9 37.5C76.9 41.8 73.4 45.3 69.1 45.3H64.5V48.3V55V61.8H74.4V54.9C82 52.8 87.5 45.8 87.5 37.5Z"
          fill="#E8EFEA"
        />
        <path d="M74.6 68.4H64.5V78.5H74.6V68.4Z" fill="#E8EFEA" />
        <path
          d="M84.5 73.0001C84.5 73.0001 76 43.5 110.5 43.5001"
          stroke="#226937"
          strokeDasharray="2 2"
        />
        <path
          d="M43.5 63.9L12.2 74.9"
          stroke="#EA7200"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M122.1 74.9L90.1 63.7"
          stroke="#EA7200"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M85.5 35.3001C85.5 25.2001 77.3 17.0001 67.2 17.0001C57.1 17.0001 48.9 25.2001 48.9 35.3001H59.3C59.3 31.0001 62.8 27.5001 67.1 27.5001C71.4 27.5001 74.9 31.0001 74.9 35.3001C74.9 39.6001 71.4 43.1001 67.1 43.1001H62.3V46.1001V52.8001V59.6001H72.2V52.7001C79.9 50.6001 85.5 43.6001 85.5 35.3001Z"
          stroke="#226937"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M72.5 66.2H62.4V76.3H72.5V66.2Z"
          stroke="#226937"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="115.325"
          y="39.0001"
          width="1.62342"
          height="4.37123"
          rx="0.811712"
          transform="rotate(49.5225 115.325 39.0001)"
          fill="#226937"
        />
        <path
          d="M113.479 38.1469C114.433 38.6769 113.785 40.9872 113.785 40.9872C113.785 40.9872 111.264 41.1837 111.026 40.0404C110.775 38.8353 112.425 37.5616 113.479 38.1469Z"
          stroke="#226937"
        />
        <path
          d="M115.363 43.5029C114.351 43.3098 114.249 41.0486 114.249 41.0486C114.249 41.0486 116.451 40.1278 117.002 41.0818C117.582 42.0875 116.48 43.7161 115.363 43.5029Z"
          stroke="#226937"
        />
        <rect
          x="29.3073"
          y="34.1287"
          width="1.62342"
          height="4.37123"
          rx="0.811712"
          transform="rotate(-47.6944 29.3073 34.1287)"
          fill="#226937"
        />
        <path
          d="M28.6929 36.0676C29.0988 35.0544 31.4721 35.4066 31.4721 35.4066C31.4721 35.4066 31.9838 37.8836 30.8796 38.2634C29.7155 38.6637 28.2445 37.1866 28.6929 36.0676Z"
          stroke="#226937"
        />
        <path
          d="M33.7697 33.5255C33.7052 34.5532 31.4747 34.9385 31.4747 34.9385C31.4747 34.9385 30.2847 32.8703 31.162 32.204C32.0867 31.5015 33.8409 32.3905 33.7697 33.5255Z"
          stroke="#226937"
        />
        <rect
          x="84.3932"
          y="2.44196"
          width="1.62342"
          height="4.37123"
          rx="0.811712"
          transform="rotate(-3.64534 84.3932 2.44196)"
          fill="#226937"
        />
        <path
          d="M82.6034 3.40839C83.5997 2.96235 85.0606 4.86564 85.0606 4.86564C85.0606 4.86564 83.7062 7.00175 82.6485 6.50692C81.5335 5.98532 81.5032 3.90095 82.6034 3.40839Z"
          stroke="#226937"
        />
        <path
          d="M88.0199 5.111C87.2589 5.80488 85.3879 4.53103 85.3879 4.53103C85.3879 4.53103 85.9706 2.21714 87.0644 2.34811C88.2174 2.48616 88.8602 4.34475 88.0199 5.111Z"
          stroke="#226937"
        />
        <path
          d="M44.4999 76.5001C44.4999 76.5001 65.4999 74.5001 34.9999 37.0001"
          stroke="#226937"
          strokeDasharray="2 2"
        />
        <path
          d="M91.0187 19.0001C91.0187 19.0001 99.9022 18.4431 87 8.00006"
          stroke="#226937"
          strokeDasharray="2 2"
        />
      </svg>
    </SvgIcon>
  );
};
