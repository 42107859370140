import { RiftFont, colors } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  checkbox: {
    "& .MuiFormControlLabel-label": {
      fontSize: 24,
      fontFamily: RiftFont,
      color: colors.secondary.main,
    },
  },
  content: {
    marginTop: 16,
  },
  text: {
    whiteSpace: "pre-line",
  },
}));
