import { ReactNode, memo, useEffect, useMemo, useRef, useState } from "react";
import { Typography } from "@material-ui/core";
import moment from "moment";
import clsx from "clsx";

import { useLocationTimezone } from "shared/hooks";
import { apiDateTimeFormat } from "shared/utils";
import { CompletedActionsJobEnum } from "shared/types";

import { useEtaBannerStyles } from "./styles";
import { EtaBannerProps } from "./types";

export const EtaBanner = memo(
  ({ appointment, classes, onClick }: EtaBannerProps) => {
    const styles = useEtaBannerStyles();

    const { utcToZone } = useLocationTimezone("location");

    const interval = useRef<ReturnType<typeof setInterval>>();

    const [counter, setCount] = useState<number>();

    const buttonText = useMemo(() => {
      let text: ReactNode = <>Job {appointment.id} is now late</>;

      if (counter) {
        const duration = moment.duration(counter, "seconds");

        text = (
          <>
            Job due in{" "}
            <span className={styles.dueTime}>
              {moment.utc(duration.asMilliseconds()).format("mm:ss")}
            </span>
          </>
        );
      }

      const isEtaSent = appointment.completedactions.some(
        (action) => action.type.id === CompletedActionsJobEnum.ETACallCompleted,
      );

      if (!isEtaSent) {
        text = <>{text}. Send client an ETA</>;
      }

      return text;
    }, [appointment.id, appointment.completedactions, counter, styles.dueTime]);

    useEffect(() => {
      const differenceInMinutes = moment(appointment.startDate).diff(
        utcToZone(moment().utc().format(apiDateTimeFormat)),
        "minutes",
      );

      if (differenceInMinutes > 0 && differenceInMinutes <= 30) {
        setCount(differenceInMinutes * 60);

        interval.current = setInterval(() => {
          setCount((prev) => {
            if (prev === 1) {
              clearInterval(interval.current);
              return 0;
            }

            return prev ? prev - 1 : 0;
          });
        }, 1000);
      }

      return () => {
        if (interval.current) {
          setCount(undefined);
          clearInterval(interval.current);
        }
      };
    }, [appointment.startDate]);

    return (
      <div className={clsx(styles.container, classes?.container)}>
        <div className={styles.button} onClick={onClick}>
          <Typography variant="h5" color="inherit">
            {buttonText}
          </Typography>
        </div>
      </div>
    );
  },
);
