export type Discount = {
  type: {
    id: number;
    name: "Percent" | "Dollar";
  };
  amount: number;
};

export enum DiscountTypesEnum {
  Percentage = 1,
  Dollar = 2,
}
