import { Theme, makeStyles } from "@material-ui/core";

type Props = {
  isNavigation?: boolean;
};

export const useStyles = makeStyles<Theme, Props>((theme) => ({
  buttonRoot: {
    padding: "4px 8px",

    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  contentRoot: {
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
  backIcon: {
    marginLeft: -5,
  },
  title: {
    lineHeight: "24px",
    width: ({ isNavigation }) => (isNavigation ? 190 : "auto"),
  },
}));
