import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  sideNavMenuContainer: {
    padding: 16,

    "& .MuiDivider-root": {
      margin: "0 -8px",
    },
  },
});
