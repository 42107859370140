import { createEvent } from "effector";
import { createEffect, createStore } from "effector/compat";

import { Pricing } from "shared/types";
import { getPricings } from "../api";
import { getPricingsFromResponse, setFavoritePricingsId } from "../utils";

export const getPricingsFx = createEffect(getPricings);
export const setFavoritePricingEv = createEvent<{ pricingId: number }>();

export const $pricings = createStore<{
  pricings: Pricing[];
  pricingsAppointmentId?: number;
  loading: boolean;
}>({
  pricings: [],
  pricingsAppointmentId: undefined,
  loading: true,
});

$pricings
  .on(getPricingsFx.doneData, (store, response) => ({
    ...store,
    pricings: getPricingsFromResponse(response.data?.viewpricingbyproduct),
    pricingsAppointmentId: response.appointmentId,
  }))
  .on(getPricingsFx.pending, (store, loading) => ({
    ...store,
    loading: loading,
  }));

$pricings.on(setFavoritePricingEv, (store, event) => ({
  ...store,
  pricings: store.pricings.map((pricing) =>
    pricing.id === event.pricingId
      ? { ...pricing, isFavorite: !pricing.isFavorite }
      : pricing,
  ),
}));

setFavoritePricingEv.watch((event) => setFavoritePricingsId(event.pricingId));
