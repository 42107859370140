import { memo, useCallback, useMemo, useState } from "react";
import { Collapse, Typography } from "@material-ui/core";
import { Button } from "@chhjpackages/components";

import { Timer } from "shared/ui";
import { GeoIcon } from "shared/assets/icons";
import { createAddressString } from "shared/utils";
import { StepStatusesEnum } from "shared/types";

import { ProcessTravelProps } from "./model";
import { useStyles } from "./assets";

export const ProcessTravel = memo(
  ({
    appointment,
    status,
    time,
    onStartTravel,
    onEndTravel,
    onEditTime,
    handleNavigate,
  }: ProcessTravelProps) => {
    const styles = useStyles();

    const [isStartTravelLoading, setIsStartTravelLoading] = useState(false);
    const [isEndTravelLoading, setIsEndTravelLoading] = useState(false);

    const originAddress = useMemo(
      () =>
        createAddressString(
          appointment?.origin.address || "",
          appointment?.origin.address2 || "",
          appointment?.origin.city || "",
          appointment?.origin.state || "",
          appointment?.origin.postal || "",
        ),
      [appointment?.origin],
    );

    const handleStartTravel = useCallback(async () => {
      setIsStartTravelLoading(true);

      await onStartTravel();

      setIsStartTravelLoading(false);
    }, [onStartTravel]);

    const endTravel = useCallback(async () => {
      setIsEndTravelLoading(true);

      await onEndTravel();

      setIsEndTravelLoading(false);
    }, [onEndTravel]);

    return (
      <div>
        <Timer initialTime={time} onEdit={onEditTime} />

        <Collapse in={status === StepStatusesEnum.InProgress}>
          <div className={styles.addressInfoNavigateContainer}>
            <div className={styles.addressInfoContainer}>
              <GeoIcon fontSize={22} />

              <Typography variant="body1">{originAddress}</Typography>
            </div>

            <div className={styles.navigateButtonWrapper}>
              <Button
                color="primary"
                buttonType="text"
                size="medium"
                classes={{
                  root: styles.buttonRoot,
                }}
                onClick={() => handleNavigate(originAddress)}
              >
                Navigate
              </Button>
            </div>
          </div>
        </Collapse>

        {status === StepStatusesEnum.InProgress ? (
          <div className={styles.startEndButtonWrapper}>
            <Button
              buttonType="twoTone"
              size="medium"
              fullWidth
              onClick={endTravel}
              isLoading={isEndTravelLoading}
            >
              End travel
            </Button>
          </div>
        ) : (
          status !== StepStatusesEnum.Done && (
            <div className={styles.startEndButtonWrapper}>
              <Button
                buttonType="twoTone"
                size="medium"
                fullWidth
                onClick={handleStartTravel}
                isLoading={isStartTravelLoading}
              >
                Start travel
              </Button>
            </div>
          )
        )}
      </div>
    );
  },
);
