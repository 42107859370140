import { colors } from "@chhjpackages/components";
import { FormHelperText, makeStyles, withStyles } from "@material-ui/core";

export const HelperTxt = withStyles(() => ({
  root: {
    fontSize: 12,
    lineHeight: 2,
    marginLeft: 2,
    marginRight: 2,
    color: colors.grey140,
  },
}))(FormHelperText);

export const useFormCheckboxGroupStyles = makeStyles(() => ({
  group: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },
  controlLabel: {
    margin: 0,
    width: "fit-content",
  },
}));
