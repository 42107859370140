export const numberToCurrency = (
  num: number | string,
  currency: string = "USD",
) => {
  const number = typeof num === "string" ? Number(num.replace(/,/g, "")) : num;

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  }).format(number);
};
