export const roundTime = (
  hours: number,
  minutes: number,
  minutesToRound = 15,
  roundType: "ceil" | "round" = "ceil",
) => {
  const time = hours * 60 + minutes;

  const rounded = Math[roundType](time / minutesToRound) * minutesToRound;

  const roundedHours = Math.floor(rounded / 60);
  const roundedMinutes = rounded % 60;

  return { hours: roundedHours, minutes: roundedMinutes };
};
