import { colors } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  addButton: {
    height: "100%",
  },
  addButtonRoot: {
    height: "100%",
    borderRadius: 0,
  },
  avatar: {
    width: "48px!important",
    height: "48px!important",
    "& .MuiTypography-root": {
      fontSize: 32,
      color: colors.white,
    },
  },
  avatarNotUser: {
    backgroundColor: "#C4C4C4!important",
  },
});
