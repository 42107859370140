import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

export const PaymentFormSkeleton = () => {
  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <Skeleton
          variant="rect"
          width="100%"
          height={55}
          style={{ borderRadius: 4 }}
        />
      </Grid>
      <Grid item>
        <Skeleton
          variant="rect"
          width="100%"
          height={55}
          style={{ borderRadius: 4 }}
        />
      </Grid>
      <Grid item>
        <Skeleton
          variant="rect"
          width="100%"
          height={55}
          style={{ borderRadius: 4 }}
        />
      </Grid>
      <Grid item>
        <Skeleton variant="rect" width="100%" height={41} />
      </Grid>
    </Grid>
  );
};
