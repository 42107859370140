import { memo } from "react";

import { useStyles } from "./styles";
import { ListProps } from "./types";

export const List = memo(({ children }: ListProps) => {
  const styles = useStyles();

  return <div className={styles.root}>{children}</div>;
});
