import { useMediaQuery, useTheme } from "@material-ui/core";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { InfoOutlined } from "@material-ui/icons";
import { useStore } from "effector-react/compat";
import { useAlert } from "@chhjpackages/components";

import { ActionsFooter, BackTitle } from "shared/ui";
import { fileToBase64, getFileExtension, routePaths } from "shared/utils";
import { useSideNavDispatch } from "features/sidenav";
import {
  $appointmentStore,
  getAppointmentFx,
  useNotesDialog,
} from "features/appointment";
import {
  PreExistingDamageFormValues,
  usePreExistingDamageForm,
} from "features/pre-existing-damage";
import { $auth } from "features/auth";
import {
  $damages,
  addDamageFx,
  deleteDamageFx,
  getDamagesFx,
  updateDamageFx,
} from "entities/pre-existing-damage";

import { usePreExistingDamageStyles as useStyles } from "./assets";

export const PreExistingDamage = memo(() => {
  const styles = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), { noSsr: true });

  const { appointmentId } = useParams();
  const navigate = useNavigate();

  const { locationId } = useStore($auth);
  const { appointment, loading: appointmentLoading } =
    useStore($appointmentStore);
  const {
    damages,
    appointmentId: damagesAppointmentId,
    loading: damagesLoading,
  } = useStore($damages);

  const {
    setPageName,
    setShowGoBackButton,
    setGoToBackUrl,
    setActionButton,
    clearActionButton,
    setElevation,
  } = useSideNavDispatch();

  const { showAlert } = useAlert();
  const { handleNotesDialogOpen } = useNotesDialog({});

  const isLoading = useMemo(() => damagesLoading, [damagesLoading]);

  const {
    form: {
      formState: { isSubmitting, isValid },
      handleSubmit,
    },
    render: renderPreExistingDamageForm,
  } = usePreExistingDamageForm({
    initialValues: {
      damages: damages,
    },
    isLoading: isLoading,
  });

  const backPath = useMemo(
    () => routePaths.jobDetails(Number(appointmentId)),
    [appointmentId],
  );

  const disableContinueToSignature = useMemo(() => !isValid, [isValid]);

  const showFooter = useMemo(
    () => !isLoading && !disableContinueToSignature,
    [isLoading, disableContinueToSignature],
  );

  const handleBack = useCallback(
    () => navigate(backPath),
    [backPath, navigate],
  );

  const continueToSignature = useCallback(
    () =>
      navigate(
        routePaths.jobDetailsPreExistingDamageSignature(Number(appointmentId)),
      ),
    [appointmentId, navigate],
  );

  const handleSubmitForm = useCallback(
    async (data: PreExistingDamageFormValues) => {
      if (!locationId || !Number(appointmentId)) {
        showAlert("Error! Something went wrong. Try again later.", {
          variant: "error",
        });

        return;
      }

      const damagesData = data.damages;
      const requestsArray: Promise<unknown>[] = [];

      const newDamages = damagesData.filter((damage) => !damage.damageId);

      for (const newDamage of newDamages) {
        requestsArray.push(
          addDamageFx({
            locationId,
            appointmentId: Number(appointmentId),
            payload: {
              description: newDamage.description,
              ...(newDamage.file && {
                image: await fileToBase64(newDamage.file),
                extension: getFileExtension(newDamage.file.name),
              }),
            },
          }).catch(() => {
            showAlert("Error! Failed to add damage.", { variant: "error" });
          }),
        );
      }

      const updatedDamages = damagesData.filter((damageData) =>
        damages.find(
          (damage) =>
            Number(damage.id) === damageData.damageId &&
            (damage.description !== damageData.description ||
              !!damageData.file),
        ),
      );

      for (const updatedDamage of updatedDamages) {
        requestsArray.push(
          updateDamageFx({
            locationId,
            appointmentId: Number(appointmentId),
            damageId: updatedDamage.damageId!,
            payload: {
              description: updatedDamage.description,
              ...(updatedDamage.file && {
                image: await fileToBase64(updatedDamage.file),
                extension: getFileExtension(updatedDamage.file.name),
              }),
            },
          }).catch(() => {
            showAlert("Error! Failed to update damage.", { variant: "error" });
          }),
        );
      }

      const deletedDamages = damages.filter(
        (damage) =>
          !damagesData.find(
            (damageData) => damageData.damageId === Number(damage.id),
          ),
      );

      for (const deletedDamage of deletedDamages) {
        requestsArray.push(
          deleteDamageFx({
            locationId,
            appointmentId: Number(appointmentId),
            damageId: Number(deletedDamage.id),
          }).catch(() => {
            showAlert("Error! Failed to delete damage.", { variant: "error" });
          }),
        );
      }

      await Promise.all(requestsArray);

      continueToSignature();
    },
    [appointmentId, locationId, damages, showAlert, continueToSignature],
  );

  useEffect(() => {
    if (
      locationId &&
      Number(appointmentId) &&
      Number(appointmentId) !== damagesAppointmentId
    ) {
      getDamagesFx({ locationId, appointmentId: Number(appointmentId) }).catch(
        () => {
          showAlert("Error! Failed to get damages. Try again later.", {
            variant: "error",
          });
        },
      );
    }
  }, [locationId, appointmentId, damagesAppointmentId]);

  useEffect(() => {
    if (
      locationId &&
      Number(appointmentId) &&
      Number(appointmentId) !== appointment?.id
    ) {
      getAppointmentFx({
        locationId,
        appointmentId: Number(appointmentId),
      }).catch(() => {
        showAlert("Error! Failed to get appointment. Try again later.", {
          variant: "error",
        });
      });
    }
  }, [locationId, appointmentId, appointment?.id]);

  useEffect(() => {
    setPageName("Pre-existing damage");
    setShowGoBackButton();
    setGoToBackUrl(backPath);
    setActionButton({
      name: "Notes",
      icon: <InfoOutlined color="inherit" fontSize="small" />,
      disabled: appointmentLoading,
      onClick: () => handleNotesDialogOpen(),
    });
    setElevation(16);

    return () => {
      setPageName("");
      setGoToBackUrl("");
      setShowGoBackButton();
      clearActionButton();
      setElevation(0);
    };
  }, [
    backPath,
    appointmentLoading,
    setPageName,
    setShowGoBackButton,
    setGoToBackUrl,
    setActionButton,
    clearActionButton,
    handleNotesDialogOpen,
    setElevation,
  ]);

  return (
    <div className={styles.root}>
      {!isMobile && (
        <BackTitle
          title="Pre-existing damage"
          elevation={16}
          actionButtons={[
            {
              name: "Notes",
              icon: <InfoOutlined color="inherit" fontSize="small" />,
              disabled: appointmentLoading,
              onClick: handleNotesDialogOpen,
            },
          ]}
          onBack={handleBack}
        />
      )}

      <div className={styles.content}>{renderPreExistingDamageForm()}</div>

      <ActionsFooter
        show={showFooter}
        actions={[
          {
            label: "Continue to customer signature",
            buttonType: "filled",
            disabled: disableContinueToSignature,
            isLoading: isSubmitting,
            onClick: handleSubmit(handleSubmitForm),
          },
        ]}
      />
    </div>
  );
});
