import { SvgIcon } from "@material-ui/core";
import { colors, SvgIconProps } from "@chhjpackages/components";

export const CheckIcon = ({
  className,
  color = colors.primary.main,
  fontSize = 18,
}: SvgIconProps) => (
  <SvgIcon
    className={className}
    style={{ fontSize, color: "#fff" }}
    width="13"
    height="10"
    viewBox="0 0 13 10"
    fill="none"
  >
    <path
      d="M0.670661 6.78557C0.194741 6.30965 0.194741 5.52778 0.670661 5.05186C1.14658 4.57594 1.92845 4.57594 2.40437 5.05186L4.30806 6.95554L10.2231 1.04053C10.699 0.564614 11.4809 0.564614 11.9568 1.04053C12.4327 1.51645 12.4327 2.29833 11.9568 2.77425L5.15792 9.57311C4.98794 9.74308 4.74998 9.87906 4.51202 9.91305C4.44403 9.91305 4.34205 9.94705 4.27406 9.94705C3.96811 9.94705 3.62817 9.81107 3.39021 9.57311L0.670661 6.78557Z"
      fill={color}
    />
  </SvgIcon>
);
