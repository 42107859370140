export const guideMoveListWithTitle = [
  {
    category: "Travel & Arrival",
    titles: [
      { title: "Pre-Arrival", id: 0 },
      { title: "Arrival", id: 1 },
      { title: "The Approach", id: 2 },
      { title: "At the door", id: 3 },
      { title: "Build value", id: 4 },
      { title: "The Walktrough", id: 5 },
    ],
  },
  {
    category: "WORK",
    titles: [
      { title: "Setup & inspection", id: 6 },
      { title: "Pre-load", id: 7 },
      { title: "Load", id: 8 },
      { title: "Unload", id: 9 },
      { title: "Complete the wow", id: 10 },
    ],
  },
  {
    category: "CLOSING THE JOB",
    titles: [
      { title: "Close the job", id: 11 },
      { title: "Reviews, Pictures and Sign", id: 12 },
      { title: "Goodbye", id: 13 },
    ],
  },
];

export const guideMoveParagraph =
  "In this guide you will learn 14 tips that can help you provide the best experience to our customers. There goes the second sentence of this paragraph.";
