import { memo, useCallback, useMemo, useState } from "react";
import { Box, Typography, Divider, Chip, Grid } from "@material-ui/core";
import { Button, colors } from "@chhjpackages/components";
import moment from "moment";

import { UserIcon, CarIcon, GeoIcon } from "shared/assets/icons";
import { generateRoute } from "features/appointment";
import { createAddressString } from "shared/utils";
import { AppointmentStatus } from "shared/types/appointment";

import { GeneralDetailsProps } from "./model";
import { ButtonStyled } from "./assets";
import { GeneralDetailsItem } from "./ui";

export const GeneralDetails = memo(
  ({
    appointment,
    onMarkAsLost,
    handleOpenContactsDialog,
    handleOpenNotesDialog,
    handleNavigate,
  }: GeneralDetailsProps) => {
    const [isLoadingMarkAppointmentAsLost, setIsLoadingMarkAppointmentAsLost] =
      useState(false);

    const { originString, destinationString, stopsString } = useMemo(
      () => generateRoute(appointment),
      [appointment],
    );

    const isNasa = useMemo(
      () => !!appointment.subpartner.id,
      [appointment.subpartner.id],
    );

    const userName = useMemo(
      () => `${appointment.account.firstName} ${appointment.account.lastName}`,
      [appointment.account],
    );

    const originAddress = useMemo(
      () =>
        createAddressString(
          appointment.origin.address,
          appointment.origin.address2,
          appointment.origin.city,
          appointment.origin.state,
          appointment.origin.postal,
        ),
      [appointment.origin],
    );

    const stops = useMemo(
      () =>
        appointment.stops.map((stop) =>
          createAddressString(
            stop.address,
            stop.address2,
            stop.city,
            stop.state,
            stop.postal,
          ),
        ),
      [appointment.stops],
    );

    const destinationAddress = useMemo(
      () =>
        createAddressString(
          appointment.destination.address,
          appointment.destination.address2,
          appointment.destination.city,
          appointment.destination.state,
          appointment.destination.postal,
        ),
      [appointment.destination],
    );

    const schedules = useMemo(
      () => appointment.schedules,
      [appointment.schedules],
    );

    const disabledMarkAsLost = useMemo(
      () =>
        appointment.type === "JOB" &&
        appointment.status.id === AppointmentStatus.Job.Closed
          ? true
          : appointment.type === "EST"
          ? appointment.estStatus.id === AppointmentStatus.Estimate.Lost
          : appointment.status.id === AppointmentStatus.Job.Lost,
      [appointment.estStatus.id, appointment.status.id, appointment.type],
    );

    const handleMarkAppointmentAsLost = useCallback(async () => {
      setIsLoadingMarkAppointmentAsLost(true);

      await onMarkAsLost();

      setIsLoadingMarkAppointmentAsLost(false);
    }, [onMarkAsLost]);

    const addressesContent = useMemo(
      () => (
        <>
          <GeneralDetailsItem
            icon={<GeoIcon fontSize={22} />}
            title={originAddress || "No address"}
            action={
              <Box mr={-1}>
                <ButtonStyled
                  buttonType="text"
                  color="primary"
                  size="medium"
                  onClick={() => handleNavigate(originAddress)}
                >
                  Navigate
                </ButtonStyled>
              </Box>
            }
          />
          <Divider />
          {stops.map((stop, i) => (
            <div key={i}>
              <GeneralDetailsItem
                icon={<GeoIcon fontSize={22} />}
                title={stop}
                action={
                  <Box mr={-1}>
                    <ButtonStyled
                      buttonType="text"
                      color="primary"
                      size="medium"
                      onClick={() => handleNavigate(stop)}
                    >
                      Navigate
                    </ButtonStyled>
                  </Box>
                }
              />
              <Divider />
            </div>
          ))}
          {destinationAddress && (
            <>
              <GeneralDetailsItem
                icon={<GeoIcon fontSize={22} />}
                title={destinationAddress}
                action={
                  <Box mr={-1}>
                    <ButtonStyled
                      buttonType="text"
                      color="primary"
                      size="medium"
                      onClick={() => handleNavigate(destinationAddress)}
                    >
                      Navigate
                    </ButtonStyled>
                  </Box>
                }
              />
              <Divider />
            </>
          )}
        </>
      ),
      [originAddress, destinationAddress, stops, handleNavigate],
    );

    const schedulesContent = useMemo(
      () =>
        schedules.map((schedule) => (
          <div key={schedule.id}>
            <GeneralDetailsItem
              icon={<CarIcon fontSize={22} />}
              title={
                <>
                  <Typography variant="body1">
                    {`${moment(schedule.startDate).format(
                      "hh:mm A",
                    )} - ${moment(schedule.endDate).format("hh:mm A")}`}
                  </Typography>

                  <Chip size="small" label={schedule.resource.name} />
                </>
              }
            />
            <Divider />
          </div>
        )),
      [schedules],
    );

    return (
      <Box pt={originString || !isNasa ? 1 : 0}>
        {!isNasa && (
          <Box px={2} color={colors.grey190}>
            <Typography variant="h4" color="inherit">
              Job Details
            </Typography>
          </Box>
        )}

        {originString && (
          <Box px={2} mt={isNasa ? 1 : 2}>
            {originString && (
              <iframe
                title="job-marker-map"
                width="100%"
                height="100%"
                style={{ border: 0 }}
                frameBorder={0}
                loading="lazy"
                allowFullScreen
                src={`https://www.google.com/maps/embed/v1/${
                  destinationString ? "directions" : "place"
                }?key=${process.env.REACT_APP_GOOGLE_API_KEY}&${
                  destinationString
                    ? `origin=${originString}&destination=${destinationString}${
                        stopsString ? "&waypoints=" + stopsString : ""
                      }`
                    : `q=${originString}`
                }`}
              />
            )}
          </Box>
        )}

        {userName && !isNasa && (
          <>
            <GeneralDetailsItem
              icon={<UserIcon fontSize={22} />}
              title={
                <Typography variant="body1">
                  <b>{userName}</b>
                </Typography>
              }
              action={
                <Box mr={-1}>
                  <ButtonStyled
                    buttonType="text"
                    color="primary"
                    size="medium"
                    onClick={() => handleOpenContactsDialog(appointment.id)}
                  >
                    Contact
                  </ButtonStyled>
                </Box>
              }
            />
            <Divider />
          </>
        )}

        {isNasa ? (
          <>
            {schedulesContent}
            {addressesContent}
          </>
        ) : (
          <>
            {addressesContent}
            {schedulesContent}
          </>
        )}

        <Grid container wrap="nowrap">
          <Grid
            item
            xs={isNasa ? 6 : 12}
            style={{ color: colors.functionals.alert }}
          >
            <Button
              fullWidth
              buttonType="text"
              color="inherit"
              disabled={disabledMarkAsLost}
              isLoading={isLoadingMarkAppointmentAsLost}
              onClick={handleMarkAppointmentAsLost}
            >
              Mark as lost
            </Button>
          </Grid>
          {isNasa && (
            <>
              <Grid item>
                <div style={{ padding: "8px 0px", height: "100%" }}>
                  <Divider orientation="vertical" />
                </div>
              </Grid>
              <Grid item xs={6}>
                <Button
                  buttonType="text"
                  color="primary"
                  fullWidth
                  onClick={() => handleOpenNotesDialog(appointment.id)}
                >
                  Notes
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    );
  },
);
