import { colors } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  errorMessage: {
    fontSize: "12px",
    lineHeight: "24px",
    color: colors.functionals.alert,
  },
  clockTimePickerButtonRoot: {
    border: `1px solid ${colors.grey30}`,
    borderRadius: 4,
  },
});
