import { makeStyles, createStyles } from "@material-ui/core";
import { colors } from "@chhjpackages/components";

export const useStyles = makeStyles(() =>
  createStyles({
    listTypography: {
      lineHeight: "25px",
      fontSize: "14px",
    },
    title: {
      color: colors.grey120,
      fontWeight: "bold",
    },
    price: {
      color: "#000000",
    },
  }),
);
