import { memo } from "react";
import { Skeleton } from "@material-ui/lab";
import { Typography } from "@material-ui/core";

import { DamageCardSkeleton } from "../damage-card";

import { useStyles } from "./styles";

export const DamageFormSkeleton = memo(() => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <div className={styles.addMoreContainer}>
        <Typography variant="body1">
          <Skeleton variant="text" width={47} />
        </Typography>
      </div>

      <DamageCardSkeleton />
      <DamageCardSkeleton />
    </div>
  );
});
