import { colors } from "@chhjpackages/components";
import { SvgIcon } from "@material-ui/core";

export const QuoteIcon = ({
  className,
  color = colors.secondary.main,
  fontSize = 13,
}: {
  className?: string;
  color?: string;
  fontSize?: number;
}) => (
  <SvgIcon
    className={className}
    style={{ fontSize }}
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
  >
    <path
      d="M1.92 12.2126H3.552C4.8 12.2126 5.472 11.5406 5.472 10.2926V7.89257C5.472 6.64457 4.8 5.97257 3.552 5.97257H2.928C3.072 4.86857 3.552 3.57257 4.176 2.42057C4.752 1.26857 4.8 0.500572 4.128 0.16457C3.408 -0.219428 2.736 0.0685716 2.064 1.02857C0.912 2.80457 0 5.06057 0 7.41257V10.2926C0 11.5406 0.672 12.2126 1.92 12.2126ZM9.12 12.2126H10.752C12 12.2126 12.672 11.5406 12.672 10.2926V7.89257C12.672 6.64457 12 5.97257 10.752 5.97257H10.128C10.272 4.86857 10.752 3.57257 11.376 2.42057C11.952 1.26857 12 0.500572 11.328 0.16457C10.608 -0.219428 9.936 0.0685716 9.264 1.02857C8.112 2.80457 7.2 5.06057 7.2 7.41257V10.2926C7.2 11.5406 7.872 12.2126 9.12 12.2126Z"
      fill={color}
    />
  </SvgIcon>
);
