import { colors } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  mainBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: colors.basic.white,
    padding: "0px 16px",
  },
  teamName: {
    fontFamily: "Noah",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "25px",
    letterSpacing: "0em",
    color: "black",
  },
  headerChips: {
    marginBottom: "12px",
  },
  headerChip: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "17px",
  },
}));
