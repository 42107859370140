import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100%",
  },

  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 270,
    width: "100%",
  },
}));
