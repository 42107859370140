import { useContext } from "react";

import { SidebarDispatchContext, SidebarStateContext } from "../context";

export const useSidebarState = () => {
  const context = useContext(SidebarStateContext);

  if (context === undefined) {
    throw new Error(
      "useSidebarState must be used within a SidebarContextController",
    );
  }

  return context;
};

export const useSidebarDispatch = () => {
  const context = useContext(SidebarDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useSidebarDispatch must be used within a SidebarContextController",
    );
  }

  return context;
};
