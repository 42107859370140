import { useStore } from "effector-react/compat";
import { useMemo } from "react";

import { $addendumProcessData } from "../store";
import { $appointmentStore } from "features/appointment";
import { $productItems } from "features/products";
import { AddendumUpdatedProduct } from "../types";

export const useAddendumProcessData = () => {
  const { appointment } = useStore($appointmentStore);
  const { initialAppointment, initialProductItems: initialProducts } =
    useStore($addendumProcessData);
  const { productItems: currentProducts } = useStore($productItems);

  const isSameAppointment = useMemo(
    () =>
      !!appointment?.id &&
      !!initialAppointment?.id &&
      appointment?.id === initialAppointment?.id,
    [appointment?.id, initialAppointment?.id],
  );

  const originalEstimate = useMemo(
    () => initialAppointment?.subTotal ?? 0,
    [initialAppointment?.subTotal],
  );

  const newEstimate = useMemo(
    () => appointment?.subTotal ?? 0,
    [appointment?.subTotal],
  );

  const additionalCost = useMemo(
    () => (isSameAppointment ? newEstimate - originalEstimate : 0),
    [isSameAppointment, newEstimate, originalEstimate],
  );

  const newAddendumProducts = useMemo<AddendumUpdatedProduct[]>(
    () =>
      currentProducts
        .filter(
          (currentProduct) =>
            !initialProducts.find(
              (initialProduct) =>
                initialProduct.productName === currentProduct.productName,
            ),
        )
        .map((product) => ({
          productId: product.productId,
          productName: product.productName,
          productSubcategory: product.productSubcategoryName,
          oldPrice: product.actualPrice,
          newPrice: product.actualPrice,
          oldQuantity: 0,
          newQuantity: product.quantity,
          oldTotal: 0,
          newTotal: product.total,
        })),
    [initialProducts, currentProducts],
  );

  const updatedAddendumProducts = useMemo<AddendumUpdatedProduct[]>(
    () =>
      initialProducts
        .map((initialProduct) => {
          const updatedProduct = currentProducts.find((currentProduct) => {
            const isSameProduct =
              currentProduct.productId === initialProduct.productId &&
              currentProduct.productName === initialProduct.productName;

            if (!isSameProduct) {
              return false;
            }

            const isPriceUpdated =
              currentProduct.actualPrice !== initialProduct.actualPrice;
            const isQuantityUpdated =
              currentProduct.quantity !== initialProduct.quantity;
            const isTotalUpdated =
              currentProduct.total !== initialProduct.total;

            return isPriceUpdated || isQuantityUpdated || isTotalUpdated;
          });

          if (!updatedProduct) {
            return null;
          }

          return {
            productId: updatedProduct.productId,
            productName: updatedProduct.productName,
            productSubcategory: updatedProduct.productSubcategoryName,
            oldPrice: initialProduct.actualPrice,
            newPrice: updatedProduct.actualPrice,
            oldQuantity: initialProduct.quantity,
            newQuantity: updatedProduct.quantity,
            oldTotal: initialProduct.total,
            newTotal: updatedProduct.total,
          };
        })
        .filter((item) => item) as AddendumUpdatedProduct[],
    [initialProducts, currentProducts],
  );

  const deletedAddendumProducts = useMemo<AddendumUpdatedProduct[]>(
    () =>
      initialProducts
        .filter(
          (initialProduct) =>
            !currentProducts.find(
              (currentProduct) =>
                currentProduct.productName === initialProduct.productName,
            ),
        )
        .map((product) => ({
          productId: product.productId,
          productName: product.productName,
          productSubcategory: product.productSubcategoryName,
          oldPrice: product.actualPrice,
          newPrice: product.actualPrice,
          oldQuantity: product.quantity,
          newQuantity: 0,
          oldTotal: product.total,
          newTotal: 0,
        })),
    [initialProducts, currentProducts],
  );

  const addendumUpdatedProducts = useMemo(
    () => [
      ...newAddendumProducts,
      ...updatedAddendumProducts,
      ...deletedAddendumProducts,
    ],
    [newAddendumProducts, updatedAddendumProducts, deletedAddendumProducts],
  );

  return {
    isSameAppointment,
    originalEstimate,
    newEstimate,
    additionalCost,
    addendumUpdatedProducts,
  };
};
