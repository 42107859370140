import { useState, useEffect, memo } from "react";
import { Typography } from "@material-ui/core";

import { convertTime } from "shared/utils";
import { calculateMinutesAndHours } from "shared/utils";
import { BoldPencilIcon } from "shared/assets";

import { TimerProps } from "./types";
import { ButtonStyled, InputStyled, useStyles } from "./styles";

export const Timer = memo(({ initialTime, onEdit }: TimerProps) => {
  const styles = useStyles();

  const [time, setTime] = useState({
    hours: "00",
    minutes: "00",
  });

  useEffect(() => {
    if (!initialTime) {
      return;
    }

    const { h, m } = calculateMinutesAndHours(initialTime, "number") as {
      h: number;
      m: number;
    };

    setTime({
      hours: convertTime(h),
      minutes: convertTime(m),
    });
  }, [initialTime]);

  return (
    <div className={styles.root}>
      <div className={styles.contentRoot}>
        <div className={styles.timeElement}>
          <InputStyled
            size="medium"
            variant="outlined"
            value={time.hours}
            disabled={true}
          />

          <Typography variant="h5" color="inherit">
            H
          </Typography>
        </div>

        <div className={styles.timeElement}>
          <InputStyled
            size="medium"
            variant="outlined"
            value={time.minutes}
            disabled={true}
          />

          <Typography variant="h5" color="inherit">
            MIN
          </Typography>
        </div>
      </div>

      <ButtonStyled variant="text" color="primary" onClick={onEdit}>
        <div className={styles.editButtonContent}>
          <BoldPencilIcon fontSize={16} />
          Edit
        </div>
      </ButtonStyled>
    </div>
  );
});
