import { colors, NoahFont } from "@chhjpackages/components";
import { Box, BoxProps } from "@material-ui/core";
import { memo, ReactNode } from "react";

export const ColorfulText = memo(
  ({
    color = colors.grey140,
    fontFamily = NoahFont,
    fontSize = 18,
    lineHeight = "140%",
    children,
    ...props
  }: {
    color?: string;
    fontFamily?: string;
    fontSize?: number;
    lineHeight?: string;
    children: ReactNode;
  } & BoxProps) => {
    return (
      <Box
        color={color}
        fontFamily={fontFamily}
        fontSize={fontSize}
        lineHeight={lineHeight}
        {...props}
      >
        {children}
      </Box>
    );
  },
);
