export enum StepStatusesEnum {
  ToBeDone = "To be done",
  InProgress = "In progress",
  Done = "Done",
}

export enum StepNamesEnum {
  Travel = "travel",
  Work = "work",
  Billing = "billing",
  Complete = "complete",
}
