import { colors, useAlert } from "@chhjpackages/components";
import { Box, Divider, useMediaQuery, useTheme } from "@material-ui/core";
import { useStore } from "effector-react/compat";
import {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { AxiosError } from "axios";

import { $coupons, getCouponsFx } from "features/coupons";
import { routePaths } from "shared/utils";
import {
  $appointmentStore,
  getAppointmentFx,
  updateAppointmentFx,
} from "features/appointment";
import { addOfflineRequestEv } from "features/job-offline-changes";
import { TabsStyled, TabStyled, BackTitle, ActionsFooter } from "shared/ui";
import { useSideNavDispatch } from "features/sidenav";
import { DiscountFormValues, useDiscountForm } from "features/discount";
import { CouponsAndDiscountsTabsEnum } from "features/coupons-and-discounts";
import { $auth, $authorizedUser } from "features/auth";
import { DiscountTypesEnum } from "shared/types";

export const CouponsAndDiscounts = memo(() => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: true,
  });

  const { pathname } = useLocation();
  const { appointmentId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    setPageName,
    setShowGoBackButton,
    setGoToBackUrl,
    setGoToBackOptions,
  } = useSideNavDispatch();
  const { showAlert } = useAlert();

  const { locationId } = useStore($auth);
  const {
    appointment,
    loading: appointmentLoading,
    updating: appointmentUpdating,
  } = useStore($appointmentStore);
  const { coupons, couponsLocationId } = useStore($coupons);
  const { user } = useStore($authorizedUser);

  const [tab, setTab] = useState<number>(
    pathname === routePaths.jobDetailsCoupons(Number(appointmentId))
      ? CouponsAndDiscountsTabsEnum.Coupons
      : CouponsAndDiscountsTabsEnum.Discounts,
  );
  const [selectedCouponId, setSelectedCouponId] = useState<number | null>(
    appointment?.coupon.id ?? null,
  );

  const locationState = useMemo<
    { couponsAndDiscountsBack?: string } | undefined
  >(() => location.state, [location.state]);

  const goToBackUrl = useMemo(
    () =>
      locationState?.couponsAndDiscountsBack ??
      routePaths.jobDetailsCart(Number(appointmentId)),
    [locationState?.couponsAndDiscountsBack, appointmentId],
  );

  const changeTab = useCallback((e: ChangeEvent<{}>, value: number) => {
    setTab(value);
  }, []);

  const handleClickCouponsTab = useCallback(
    () =>
      navigate(routePaths.jobDetailsCoupons(Number(appointmentId)), {
        replace: true,
        state: locationState,
      }),
    [appointmentId, locationState, navigate],
  );

  const handleClickDiscountsTab = useCallback(
    () =>
      navigate(routePaths.jobDetailsDiscounts(Number(appointmentId)), {
        replace: true,
        state: locationState,
      }),
    [appointmentId, locationState, navigate],
  );

  const handleClickBack = useCallback(() => {
    navigate(goToBackUrl, { state: locationState });
  }, [goToBackUrl, locationState, navigate]);

  const handleSubmitDiscountsForm = useCallback(
    async (data: DiscountFormValues) => {
      if (!Number(appointmentId) || !locationId || !appointment) {
        return;
      }

      if (Number(appointmentId) && locationId) {
        try {
          await updateAppointmentFx({
            locationId: locationId,
            appointmentId: Number(appointmentId),
            payload: {
              discount: {
                type: {
                  id: data.type,
                },
                amount: data.discount,
              },
            },
          });

          showAlert("Success! Discount has been updated.", {
            variant: "success",
          });
        } catch (err) {
          const error = err as AxiosError;
          const prevDiscount = appointment.discount;

          addOfflineRequestEv({
            status: error.response?.status,
            request: {
              config: error.config,
              id: `${appointmentId}`,
              description: "Applied discount",
              startState:
                prevDiscount.type.id === DiscountTypesEnum.Dollar
                  ? `-$${Number(prevDiscount.amount).toFixed(2)}`
                  : `-${Number(prevDiscount.amount).toFixed(2)}%`,
              endState:
                data.type === DiscountTypesEnum.Dollar
                  ? `-$${Number(data.discount).toFixed(2)}`
                  : `-${Number(data.discount).toFixed(2)}%`,
              replace: true,
              userId: user?.id,
            },
          });

          showAlert("Error! Failed to update discount.", { variant: "error" });
        }
      }
    },
    [appointment, appointmentId, locationId, user?.id, showAlert],
  );

  const handleSubmitCoupons = useCallback(async () => {
    if (
      !locationId ||
      !Number(appointmentId) ||
      !appointment ||
      typeof selectedCouponId !== "number"
    ) {
      return;
    }

    const selectedCoupon = coupons.find(
      (coupon) => coupon.id === selectedCouponId,
    );

    try {
      await updateAppointmentFx({
        locationId: locationId,
        appointmentId: Number(appointmentId),
        payload: {
          coupon: {
            id: selectedCouponId,
          },
        },
      });

      showAlert("Success! Coupon has been updated.", { variant: "success" });
    } catch (err) {
      const error = err as AxiosError;
      const prevCoupon = appointment.coupon;

      addOfflineRequestEv({
        status: error.response?.status,
        request: {
          config: error.config,
          id: `${appointmentId}`,
          description: "Applied coupon",
          startState:
            prevCoupon.id === 0
              ? "No coupon"
              : `${prevCoupon.name} - $${prevCoupon.amount},
                )} OFF`,
          endState: selectedCoupon
            ? `${selectedCoupon.name} - $${selectedCoupon.amount},
              )} OFF`
            : "No coupon",
          replace: true,
          userId: user?.id,
        },
      });

      showAlert("Error! Failed to update coupon.", { variant: "error" });
    }
  }, [
    appointment,
    coupons,
    appointmentId,
    locationId,
    selectedCouponId,
    user?.id,
    showAlert,
  ]);

  const {
    control: discountControl,
    formState: discountFormState,
    setValue: discountSetValue,
    watch: discountWatch,
    handleSubmit: discountHandleSubmit,
  } = useDiscountForm({
    initialValues: appointment?.discount,
  });

  const showApply = useMemo(() => {
    if (appointmentLoading) {
      return false;
    }

    if (tab === CouponsAndDiscountsTabsEnum.Discounts) {
      return discountFormState.isDirty && discountFormState.isValid;
    } else if (tab === CouponsAndDiscountsTabsEnum.Coupons) {
      return selectedCouponId !== appointment?.coupon.id;
    }

    return false;
  }, [
    appointmentLoading,
    tab,
    discountFormState,
    selectedCouponId,
    appointment?.coupon.id,
  ]);

  const handleApply = useMemo(() => {
    if (tab === CouponsAndDiscountsTabsEnum.Discounts) {
      return { onSubmit: discountHandleSubmit(handleSubmitDiscountsForm) };
    } else if (tab === CouponsAndDiscountsTabsEnum.Coupons) {
      return { onSubmit: handleSubmitCoupons };
    }
  }, [
    tab,
    discountHandleSubmit,
    handleSubmitDiscountsForm,
    handleSubmitCoupons,
  ]);

  const handleUpdateCoupon = useCallback(
    async (couponId: number) => {
      if (!appointment) {
        return;
      }
      setSelectedCouponId((prevValue) =>
        prevValue === couponId ? 0 : couponId,
      );
    },
    [appointment],
  );

  useEffect(() => {
    if (!appointment) {
      return;
    }

    setSelectedCouponId(appointment.coupon.id);
  }, [appointment]);

  useEffect(() => {
    setPageName("Coupons & Discounts");
    setShowGoBackButton();
    setGoToBackUrl(goToBackUrl);
    setGoToBackOptions({ state: locationState });

    return () => {
      setPageName("");
      setGoToBackUrl("");
      setShowGoBackButton();
      setGoToBackOptions(undefined);
    };
  }, [goToBackUrl, locationState]);

  useEffect(() => {
    if (locationId && locationId !== couponsLocationId) {
      getCouponsFx({
        locationId: locationId,
      });
    }
  }, [locationId, couponsLocationId]);

  useEffect(() => {
    if (appointment?.id !== Number(appointmentId) && locationId) {
      getAppointmentFx({
        locationId: locationId,
        appointmentId: Number(appointmentId),
      });
    }
  }, [appointment?.id, appointmentId, locationId]);

  return (
    <Box flex={1} display="flex" flexDirection="column">
      {!isMobile && (
        <BackTitle onBack={handleClickBack} title="Coupons & Discounts" />
      )}
      <Box bgcolor={colors.white}>
        {isMobile && <Divider />}
        <TabsStyled variant="fullWidth" value={tab} onChange={changeTab}>
          <TabStyled
            label="Coupons"
            value={CouponsAndDiscountsTabsEnum.Coupons}
            onClick={handleClickCouponsTab}
          />
          <TabStyled
            label="Discounts"
            value={CouponsAndDiscountsTabsEnum.Discounts}
            onClick={handleClickDiscountsTab}
          />
        </TabsStyled>
      </Box>
      <Box flex={1}>
        <Outlet
          context={{
            control: discountControl,
            setValue: discountSetValue,
            watch: discountWatch,
            selectedCouponId,
            onChangeSelectedCouponId: handleUpdateCoupon,
          }}
        />
      </Box>
      <ActionsFooter
        show={showApply}
        actions={[
          {
            label: "Apply",
            buttonType: "filled",
            isLoading: discountFormState.isSubmitting || appointmentUpdating,
            onClick: handleApply?.onSubmit,
          },
        ]}
      />
    </Box>
  );
});
