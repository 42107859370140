import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useCallback } from "react";

import { liabilityWaiverFormValidation } from "../utils";
import { LiabilityWaiverForm } from "../../ui";
import { LiablityWaiverFormValues } from "../types";

export const useLiabilityWaiverForm = () => {
  const form = useForm<LiablityWaiverFormValues>({
    mode: "all",
    resolver: yupResolver(liabilityWaiverFormValidation),
  });

  const render = useCallback(() => <LiabilityWaiverForm form={form} />, [form]);

  return { form, render };
};
