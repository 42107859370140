import { useCallback, useMemo } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

export const useLoginNavigate = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const typeParam = useMemo(() => searchParams.get("type"), [searchParams]);

  const isExpiredType = useMemo(() => typeParam === "expired", [typeParam]);

  const loginNavigate = useCallback(
    (path: string, options?: { ignoreSearch?: boolean; state?: unknown }) => {
      navigate(
        {
          pathname: path,
          search: !options?.ignoreSearch ? location.search : "",
        },
        { state: options?.state },
      );
    },
    [location.search, navigate],
  );

  return { isExpiredType, loginNavigate };
};
