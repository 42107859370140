import { fetcher } from "shared/libs";

import { GetCoupons } from "./types";

export const getCoupons = async ({ locationId }: GetCoupons.Params) => {
  const response = await fetcher.get<GetCoupons.Response>(
    `locations/${locationId}/coupons`,
  );

  return {
    ...response,
    locationId,
  };
};
