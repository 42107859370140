import {
  Button,
  colors,
  Paper,
  useEllipsis,
  PlusIcon,
  UserIdentifier,
} from "@chhjpackages/components";
import { Divider, Typography } from "@material-ui/core";
import clsx from "clsx";
import { memo } from "react";

import { CheckIcon } from "shared/assets";

import { SelectionCardProps } from "./types";
import { useStyles } from "./styles";

export const SelectionCard = memo(
  ({
    title,
    imageUrl,
    subTitle,
    selected,
    disabled,
    hideImage,
    isUser,
    onToggleSelect,
  }: SelectionCardProps) => {
    const styles = useStyles();

    const { renderEllipsisText: renderName } = useEllipsis({
      text: (
        <Typography
          variant="subtitle1"
          color={disabled ? "inherit" : "initial"}
        >
          {title}
        </Typography>
      ),
    });

    return (
      <Paper elevation={disabled ? 0 : 16}>
        <div
          style={{
            display: "flex",
            outline: disabled ? `1px solid ${colors.grey40}` : "none",
            backgroundColor: disabled ? colors.grey10 : colors.white,
            minHeight: 80,
          }}
        >
          <div
            style={{
              flex: 1,
              padding: 16,
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
            }}
          >
            {!hideImage && (
              <div
                style={{
                  marginRight: 16,
                }}
              >
                <UserIdentifier
                  name={title}
                  imageUrl={imageUrl}
                  size="large"
                  isDisplayName={false}
                  classes={{
                    avatar: clsx(
                      styles.avatar,
                      !isUser && styles.avatarNotUser,
                    ),
                  }}
                />
              </div>
            )}
            <div style={{ color: colors.grey100, overflow: "hidden" }}>
              {renderName()}
              {subTitle && (
                <Typography variant="subtitle1" color="inherit">
                  {subTitle}
                </Typography>
              )}
            </div>
          </div>
          {!selected && (
            <div>
              <Divider orientation="vertical" />
            </div>
          )}
          <div
            style={{
              width: 40,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: colors.white,
            }}
          >
            <Button
              buttonType={selected ? "filled" : "text"}
              disabled={disabled}
              className={styles.addButton}
              classes={{ root: styles.addButtonRoot }}
              onClick={onToggleSelect}
            >
              {selected ? (
                <CheckIcon color={colors.white} fontSize={12} />
              ) : (
                <PlusIcon
                  fontSize={12}
                  color={disabled ? colors.grey100 : colors.primary.main}
                />
              )}
            </Button>
          </div>
        </div>
      </Paper>
    );
  },
);
