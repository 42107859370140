import { Box, Typography } from "@material-ui/core";
import { Button, colors } from "@chhjpackages/components";
import { memo } from "react";

import { ColorfulText } from "shared/ui";
import { Navigate } from "shared/types";

export const PasswordChanged = memo(({ handleNavigateTo }: Navigate) => {
  return (
    <div>
      <Box color={colors.grey180} textAlign={{ xs: "left", sm: "center" }}>
        <Typography variant="h3" color="inherit">
          Password changed.
        </Typography>
      </Box>

      <ColorfulText
        mt={{ xs: 1, sm: 3 }}
        textAlign={{ xs: "left", sm: "center" }}
      >
        Your password has been successfully changed. Now you can log in using
        your new password.
      </ColorfulText>

      <Box mt={{ xs: 4, sm: 5.5 }}>
        <Button
          fullWidth
          buttonType="twoTone"
          size="large"
          onClick={() => handleNavigateTo()}
        >
          Back to login page
        </Button>
      </Box>
    </div>
  );
});
