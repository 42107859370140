import { memo, useCallback, useEffect, useMemo } from "react";
import { useAlert, useDialogDispatch } from "@chhjpackages/components";
import { useNavigate } from "react-router-dom";
import { useStore } from "effector-react";
import { useParams } from "react-router";

import {
  $attachmentsStore,
  getAttachmentsFx,
  AttachmentsForm,
  addAttachments,
  deleteAttachment,
} from "features/attachments";
import { $auth } from "features/auth";
import {
  fileToBase64,
  getFileExtension,
  getFilenameFromUrl,
} from "shared/utils";
import { AttachmentsFormValues } from "features/attachments/features/attachments-form/model";
import { DialogContentWrapper } from "shared/ui";

export const Attachments = memo(() => {
  const navigate = useNavigate();
  const { appointmentId } = useParams();

  const setDialog = useDialogDispatch();
  const { showAlert } = useAlert();

  const { locationId } = useStore($auth);
  const { attachments, loading: attachmentsLoading } =
    useStore($attachmentsStore);

  const attachmentsForDisplay = useMemo(
    () =>
      attachments.map((attachment) => ({
        ...attachment,
        name: attachment.name ?? getFilenameFromUrl(attachment.url),
      })),
    [attachments],
  );

  const onCloseAttachmentsDialog = useCallback(() => navigate(-1), [navigate]);

  const handleSubmitAttachmentsForm = useCallback(
    async (data: AttachmentsFormValues) => {
      if (!locationId || !appointmentId) {
        return;
      }

      const newAttachments = data.attachments.filter(
        (attachment) =>
          !attachments.some(
            (initialAttachment) => initialAttachment.id === attachment.id,
          ),
      );

      const deletedAttachemnts = attachments.filter(
        (initialAttachment) =>
          !data.attachments.some(
            (attachment) => attachment.id === initialAttachment.id,
          ),
      );

      const requestsArray: Promise<unknown>[] = [];
      let hasError = false;

      if (newAttachments.length > 0) {
        for (const newAttachment of newAttachments) {
          if (!newAttachment.file) {
            return;
          }

          const content = await fileToBase64(newAttachment.file);
          const extension = getFileExtension(newAttachment.file.name);

          /* eslint-disable */
          requestsArray.push(
            addAttachments({
              locationId: locationId,
              appointmentId: Number(appointmentId),
              description: newAttachment.description,
              extension: extension,
              content: content,
            }).catch(() => {
              hasError = true;

              showAlert(
                `Error! Failed to add an attachment with name ${newAttachment.file?.name}.`,
                {
                  variant: "error",
                },
              );
            }),
          );
          /* eslint-enable */
        }
      }

      if (deletedAttachemnts.length > 0) {
        for (const deletedAttachemnt of deletedAttachemnts) {
          /* eslint-disable */
          requestsArray.push(
            deleteAttachment({
              locationId: locationId,
              appointmentId: Number(appointmentId),
              attachmentId: deletedAttachemnt.id,
            }).catch(() => {
              hasError = true;

              showAlert(
                `Error! Failed to remove an attachment with name ${getFilenameFromUrl(
                  deletedAttachemnt.url,
                )} (ID: ${deletedAttachemnt.id}).`,
                {
                  variant: "error",
                },
              );
            }),
          );
          /* eslint-enable */
        }
      }

      if (requestsArray.length) {
        await Promise.all(requestsArray);

        await getAttachmentsFx({
          locationId: locationId,
          appointmentId: Number(appointmentId),
        });
      }

      if (!hasError) {
        showAlert("Success! Changes have been saved.", {
          variant: "success",
        });
      }
    },
    [attachments, appointmentId, locationId, showAlert],
  );

  useEffect(() => {
    setDialog({
      open: true,
      variant: "basic",
      title: "Attachments",
      disableBackdropClick: true,
      dialogContent: (
        <DialogContentWrapper maxWidth={488}>
          <AttachmentsForm
            attachments={attachmentsForDisplay}
            loading={attachmentsLoading}
            onSubmit={handleSubmitAttachmentsForm}
            onClose={onCloseAttachmentsDialog}
          />
        </DialogContentWrapper>
      ),
      onClose: onCloseAttachmentsDialog,
    });

    return () => setDialog({ open: false });
  }, [
    attachmentsForDisplay,
    attachmentsLoading,
    setDialog,

    onCloseAttachmentsDialog,
    handleSubmitAttachmentsForm,
  ]);

  return null;
});
