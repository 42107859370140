import { roundTime } from "./round-time";
import { roundToNearest } from "./round-to-nearest";

export const calculateMinutesAndHours = (
  seconds: number,
  format: "number" | "string",
  type: "basic" | "billable" = "basic",
) => {
  if (seconds === 0) {
    if (format === "number") {
      return { h: 0, m: 0 };
    }

    if (type === "basic") {
      return "0 hrs 00 mins";
    } else if (type === "billable") {
      return "0.00 hours";
    } else {
      return "";
    }
  } else {
    let m = Math.floor(seconds / 60);
    let h = Math.floor(m / 60);
    m = m % 60;

    if (format === "number") {
      return { h, m };
    }

    if (type === "basic") {
      let mStr = String(m);
      if (m < 10) {
        mStr = "0" + mStr;
      }

      return `${h} hrs ${mStr} mins`;
    } else if (type === "billable") {
      const roundedTime = roundTime(h, m);
      const roundedTimeToNearest = roundToNearest(
        roundedTime.hours + 1 / (60 / roundedTime.minutes),
        0.25,
      );

      return `${roundedTimeToNearest.toFixed(2)} hours`;
    } else {
      return "";
    }
  }
};
