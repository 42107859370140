import { createEffect, createStore } from "effector/compat";

import { getTerminals } from "../api";
import { Terminal } from "../types";

export const $terminals = createStore<{
  terminals: Terminal[];
  locationId: number | null;
  loading: boolean;
}>({
  terminals: [],
  locationId: null,
  loading: true,
});

export const getTerminalsFx = createEffect(getTerminals);

$terminals
  .on(getTerminalsFx.done, (store, response) => ({
    ...store,
    terminals: response.result.data.terminals,
    locationId: response.params.locationId,
  }))
  .on(getTerminalsFx.fail, (store) => ({
    ...store,
    terminals: [],
    locationId: null,
  }))
  .on(getTerminalsFx.pending, (store, loading) => ({
    ...store,
    loading,
  }));
