import { colors } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/core";

export const useCartStyles = makeStyles(() => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  content: {
    padding: 16,
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },
  bolText: {
    color: colors.complementary.blue.shade01,
  },
  totalTimeContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    backgroundColor: colors.white,
    padding: 16,
    paddingTop: 8,
    color: colors.grey190,
  },
  productsListWrapper: {
    flex: 1,
  },
  productsListNoProductsWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
