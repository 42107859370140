import * as yup from "yup";

export const preExistingDamageFormValidation = yup.object({
  damages: yup
    .array()
    .of(
      yup.object({
        description: yup
          .string()
          .trim()
          .required("Description of damage is required"),
      }),
    )
    .min(1),
});
