import { Loader } from "@chhjpackages/components";
import { Backdrop } from "@material-ui/core";
import clsx from "clsx";
import { memo } from "react";

import { useStyles } from "./styles";
import { OverlayLoaderProps } from "./types";

export const OverlayLoader = memo(
  ({ open, className, fullscreen = true }: OverlayLoaderProps) => {
    const classes = useStyles();

    return (
      <Backdrop
        open={open}
        classes={{ root: classes.root }}
        className={clsx([className], {
          [classes.containedBackdrop]: !fullscreen,
        })}
      >
        <Loader />
      </Backdrop>
    );
  },
);
