import { Typography } from "@material-ui/core";
import { memo } from "react";

import { GeneralDetailsItemProps } from "./types";
import { useStyles } from "./styles";

export const GeneralDetailsItem = memo(
  ({ icon, title, action }: GeneralDetailsItemProps) => {
    const styles = useStyles();

    return (
      <div className={styles.root}>
        <div className={styles.content}>
          {icon}

          {typeof title === "string" ? (
            <Typography variant="body1">{title}</Typography>
          ) : (
            title
          )}
        </div>

        {action && action}
      </div>
    );
  },
);
