export enum SendPaymentLinkMethodsEnum {
  email = "EMAIL",
  phone = "SMS",
}

export type SendPaymentLinkFormValues = {
  method: SendPaymentLinkMethodsEnum;
  phone: string;
  email: string;
};

export type SendPaymentLinkFormProps = {
  initialValues: Partial<SendPaymentLinkFormValues>;
  onSubmit: (data: SendPaymentLinkFormValues) => void;
  onClose: () => void;
};
