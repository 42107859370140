import { memo, useCallback } from "react";
import { Button, Image, useFilePicker } from "@chhjpackages/components";
import { Collapse, useMediaQuery, useTheme } from "@material-ui/core";
import clsx from "clsx";

import { FormTextArea } from "shared/ui";
import { attachmentMaxSize } from "shared/config";

import { getPreviewImageStyle, previewStyle, useStyles } from "./styles";
import { DamageCardProps } from "./types";

export const DamageCard = memo(
  ({
    control,
    index,
    imageUrl,
    permissions,
    onChangePhoto,
    onDelete,
  }: DamageCardProps) => {
    const styles = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery("(max-width: 400px)", { noSsr: true });
    const isTablet = useMediaQuery(theme.breakpoints.down("sm"), {
      noSsr: true,
    });

    const handleFilesSelected = useCallback(
      (files: File[]) => {
        const file = files[0];

        if (file) {
          onChangePhoto(file);
        }
      },
      [onChangePhoto],
    );

    const { openFilePicker } = useFilePicker({
      inputProps: {
        accept: ["image/*"],
      },
      fileSizeValidation: {
        maxFileSizeKb: attachmentMaxSize,
        showAlert: true,
      },
      onFilesSelected: handleFilesSelected,
    });

    return (
      <div className={styles.root}>
        <FormTextArea
          control={control}
          name={`damages.${index}.description`}
          label="Description of damage"
          rows={4}
        />

        {!!imageUrl && (
          <Image
            src={imageUrl}
            style={previewStyle}
            imageStyle={getPreviewImageStyle(
              isMobile ? "mobile" : isTablet ? "tablet" : "desktop",
            )}
          />
        )}

        <Button buttonType="twoTone" fullWidth onClick={openFilePicker}>
          {!!imageUrl ? "Change" : "Upload"} photo
        </Button>

        <Collapse in={!permissions?.hideDelete}>
          <Button
            buttonType="text"
            fullWidth
            className={clsx(permissions?.hideDelete && "pointerEventsNone")}
            classes={{ root: styles.deleteButtonRoot }}
            onClick={onDelete}
          >
            Delete
          </Button>
        </Collapse>
      </div>
    );
  },
);
