import { colors } from "@chhjpackages/components";
import { SvgIcon } from "@material-ui/core";

export const CarIcon = ({
  className,
  color = colors.grey80,
  fontSize = 24,
}: {
  className?: string;
  color?: string;
  fontSize?: number;
}) => (
  <SvgIcon
    className={className}
    style={{ fontSize }}
    width="22"
    height="16"
    viewBox="0 0 22 16"
    fill="none"
  >
    <path
      d="M8.29218 13.287V4.04492H3.40455C1.9827 4.13379 0.916307 5.37791 1.00517 6.79976V12.3983C1.00517 12.8426 1.36064 13.287 1.80497 13.3758H3.67115"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M5.00413 14.7087C5.71506 14.7087 6.33712 14.0866 6.33712 13.3757C6.33712 12.6648 5.71506 12.0427 5.00413 12.0427C4.2932 12.0427 3.67114 12.6648 3.67114 13.3757C3.67114 14.0866 4.20434 14.7087 5.00413 14.7087Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M15.6681 14.7087C16.379 14.7087 17.0011 14.0866 17.0011 13.3757C17.0011 12.6648 16.379 12.0427 15.6681 12.0427C14.9571 12.0427 14.3351 12.6648 14.3351 13.3757C14.3351 14.0866 14.8683 14.7087 15.6681 14.7087Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M1.00525 7.95502H4.91535C5.27081 8.04389 5.62628 7.68842 5.62628 7.24409V4.04492"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M6.33716 13.2869H14.3351"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M1.00525 10.6208H2.78257"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M20.9999 9.37684H8.29211V2.62303C8.29211 1.9121 8.91418 1.29004 9.6251 1.29004H19.5781C20.289 1.29004 20.9111 1.9121 20.9111 2.62303V9.37684H20.9999Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M17.0011 13.2871H19.6671C20.378 13.2871 21.0001 12.665 21.0001 11.9541V9.28809"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
