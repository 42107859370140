import { Typography } from "@material-ui/core";
import clsx from "clsx";
import { memo } from "react";

import { FilterPickerProps } from "./types";
import { useStyles } from "./styles";

export const FilterPicker = memo(
  ({ title, isActive, disabled, onClick }: FilterPickerProps) => {
    const styles = useStyles();

    return (
      <div
        className={clsx(
          styles.root,
          isActive && styles.active,
          disabled && styles.disabled,
        )}
        onClick={() => {
          if (!disabled && onClick) {
            onClick();
          }
        }}
      >
        <Typography
          variant="body1"
          className={clsx(
            styles.title,
            isActive && styles.titleActive,
            disabled && styles.titleDisabled,
          )}
        >
          {title}
        </Typography>
      </div>
    );
  },
);
