import { useEffect, useRef } from "react";

export const useTimeTrack = ({
  time,
  isRunning,
  onChangeTime,
}: {
  time: number;
  isRunning: boolean;
  onChangeTime: (seconds: number) => void;
}) => {
  const timerRef = useRef<ReturnType<typeof setInterval> | null>(null);

  useEffect(() => {
    if (isRunning) {
      const reminderOfMinute = time > 60 ? 60 - (time % 60) : 60 - time;

      if (reminderOfMinute !== 0) {
        timerRef.current = setInterval(() => {
          onChangeTime(reminderOfMinute);

          if (timerRef.current) {
            clearInterval(timerRef.current);
          }

          timerRef.current = setInterval(() => {
            onChangeTime(60);
          }, 60 * 1000);
        }, reminderOfMinute * 1000);
      } else {
        timerRef.current = setInterval(() => {
          onChangeTime(60);
        }, 60 * 1000);
      }
    } else {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [isRunning, onChangeTime]);
};
