import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  mainImage: {
    width: "15vw",
    height: "15vh",
    marginTop: "8rem",
    marginBottom: "2rem",
  },
  thankYouText: {
    fontFamily: "Rift Soft",
    fontStyle: "normal",
    color: "#226937",
    fontWeight: 700,
    fontSize: "50px",
    lineHeight: "70px",
  },
  presentationText: {
    fontFamily: "Rift Soft",
    fontStyle: "normal",
    color: "#0E0F16",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "10px",
  },
  buttonBox: {
    border: "2px solid #EA7200",
    boxShadow: "7px 7px 0px #F0CBA7, inset 7px 7px 0px #FFFFFF",
    boxSizing: "border-box",
    padding: "13px 19px",
    gap: "10px",
    width: "181px",
    height: "51px",
    alignItems: "center",
    margin: "auto",
    marginTop: "18px",
    background: "#F0CBA7",
  },
  buttonText: {
    fontFamily: "Rift Soft",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "22px",
    color: "#026937",
  },
}));
