import { memo, useCallback, useEffect } from "react";
import { useAlert, useDialogDispatch } from "@chhjpackages/components";
import { useNavigate } from "react-router-dom";
import { useStore } from "effector-react";
import { useParams } from "react-router";
import { AxiosError } from "axios";

import { routePaths } from "shared/utils";
import {
  $appointmentStore,
  sendAppointmentInvoiceFx,
  SendInvoiceForm,
  SendInvoiceFormValues,
} from "features/appointment";
import { addOfflineRequestEv } from "features/job-offline-changes";
import { $authorizedUser } from "features/auth";
import { DialogContentWrapper } from "shared/ui";

export const SendInvoice = memo(() => {
  const navigate = useNavigate();
  const { appointmentId } = useParams();

  const { appointment } = useStore($appointmentStore);
  const { user } = useStore($authorizedUser);

  const setDialog = useDialogDispatch();

  const { showAlert } = useAlert();

  const handleCloseSendInvoice = useCallback(
    () =>
      navigate(routePaths.jobDetails(Number(appointmentId)), { replace: true }),
    [appointmentId, navigate],
  );

  const handleSubmitSendInvoice = useCallback(
    async (data: SendInvoiceFormValues) => {
      if (appointment?.id) {
        try {
          await sendAppointmentInvoiceFx({
            locationId: appointment.location.id,
            appointmentId: appointment.id,
            payload: {
              emailTo: data.email,
            },
          });

          handleCloseSendInvoice();

          showAlert("Success! Itemized receipt has been sent.", {
            variant: "success",
          });
        } catch (err) {
          const error = err as AxiosError;
          showAlert("Error! Failed to send itemized receipt.", {
            variant: "error",
          });

          addOfflineRequestEv({
            status: error.response?.status,
            request: {
              config: error.config,
              id: `${appointment.id}`,
              description: "Sended invoice",
              startState: "",
              endState: data.email,
              replace: true,
              userId: user?.id,
            },
          });
        }
      }
    },
    [
      appointment?.id,
      user?.id,
      appointment?.location.id,
      handleCloseSendInvoice,
      showAlert,
    ],
  );

  useEffect(() => {
    if (appointment) {
      setDialog({
        open: true,
        variant: "basic",
        title: "Send Invoice",
        disableBackdropClick: true,
        dialogContent: (
          <DialogContentWrapper maxWidth={488}>
            <SendInvoiceForm
              initialValues={{
                email: appointment.account.email,
              }}
              onClose={handleCloseSendInvoice}
              onSubmit={handleSubmitSendInvoice}
            />
          </DialogContentWrapper>
        ),
        onClose: handleCloseSendInvoice,
      });
    }

    return () => setDialog({ open: false });
  }, [appointment, setDialog, handleCloseSendInvoice, handleSubmitSendInvoice]);

  return null;
});
