import { HunkTeam } from "features/teams";

export const getTeamMembers = (team: HunkTeam) => {
  return team.users.reduce(
    (result, teamUser, i) =>
      result +
      `${teamUser?.firstName}${teamUser?.lastName}` +
      `${i + 1 === team.users.length ? "." : ", "}`,
    "",
  );
};
