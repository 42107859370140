import * as yup from "yup";

import { AddendumChangeOptionIdsEnum } from "../types";

export const descriptionOfChangesFormValidation = yup.object({
  changeOptions: yup.array().of(yup.number()).min(1, " "),
  additionalNotes: yup.string().trim(),
  descriptionOfSituation: yup
    .string()
    .trim()
    .when("changeOptions", (changeOptions, schema) => {
      if (
        changeOptions?.includes(
          AddendumChangeOptionIdsEnum.UnforeseeableCircumstances,
        )
      ) {
        return schema.required("Description of the situation is required");
      }
    }),
});
