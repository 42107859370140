import { memo } from "react";

import { FormInput } from "shared/ui";

import { FormIncrementInputStyled, useStyles } from "./assets";
import { ItemCard, List } from "./ui";
import { BillOfLadingSectionProps } from "./model";

export const MovingSection = memo(
  ({ control }: Pick<BillOfLadingSectionProps, "control">) => {
    const styles = useStyles();

    return (
      <List>
        <ItemCard
          title="# of HUNKS"
          controlElement={
            <FormIncrementInputStyled
              control={control}
              name="numOfHunksMoving"
              min={0}
            />
          }
        />
        <ItemCard
          title="# of hours"
          controlElement={
            <FormIncrementInputStyled
              control={control}
              name="numOfHoursMoving"
              mask="positive-two-decimal"
              min={0}
            />
          }
        />
        <ItemCard
          title="Hourly rate"
          controlElement={
            <div style={{ width: 100 }}>
              <FormInput
                control={control}
                name="hourlyRateMoving"
                variant="outlined"
                mask="positive-two-decimal"
                classes={{
                  input: styles.input,
                }}
                fullWidth
              />
            </div>
          }
        />
        <ItemCard
          title="Truck & travel fee"
          controlElement={
            <div style={{ width: 100 }}>
              <FormInput
                control={control}
                name="truckAndTravelFee"
                variant="outlined"
                mask="positive-two-decimal"
                classes={{
                  input: styles.input,
                }}
                fullWidth
              />
            </div>
          }
        />
      </List>
    );
  },
);
