import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Button, UploadAttachment } from "@chhjpackages/components";
import { Box } from "@material-ui/core";
import { useForm } from "react-hook-form";

import { openInNewTab } from "shared/utils";
import { attachmentMaxSize } from "shared/config";

import { AttachmentsFormProps, AttachmentsFormValues } from "./model";

export const AttachmentsForm = memo(
  ({ attachments, loading, onSubmit, onClose }: AttachmentsFormProps) => {
    const { formState, handleSubmit, setValue, watch } =
      useForm<AttachmentsFormValues>({ mode: "all" });

    const [description, setDescription] = useState<string>("");
    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const attachmentWatch = watch("attachments");

    const isDirty = useMemo(
      () => JSON.stringify(attachments) !== JSON.stringify(attachmentWatch),
      [attachments, attachmentWatch],
    );

    const handlePreviewAttachment = useCallback(
      (url: string) => openInNewTab(url),
      [],
    );

    const handleDeleteAttachment = useCallback(
      (id: number) =>
        setValue(
          "attachments",
          attachmentWatch.filter((attachment) => attachment.id !== id),
        ),
      [attachmentWatch],
    );

    useEffect(() => {
      setValue("attachments", attachments);
    }, [attachments]);

    useEffect(() => {
      if (selectedFile) {
        setValue("attachments", [
          ...attachmentWatch,
          {
            id: new Date().getTime(),
            name: selectedFile.name,
            url: URL.createObjectURL(selectedFile),
            description: description,
            file: selectedFile,
          },
        ]);

        setSelectedFile(null);
        setDescription("");
      }
    }, [selectedFile]);

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <UploadAttachment
          selectedFile={null}
          description={description}
          acceptFormats="image/*, video/*, .pdf"
          attachments={attachmentWatch}
          fileSizeValidation={{
            maxFileSizeKb: attachmentMaxSize,
            showAlert: true,
          }}
          loading={loading}
          changeDescription={setDescription}
          changeSelectedFile={setSelectedFile}
          onPreviewAttachment={handlePreviewAttachment}
          onDeleteAttachment={handleDeleteAttachment}
        />
        <Box mt={4} display="flex" alignItems="center" justifyContent="end">
          <Button buttonType="text" color="primary" onClick={onClose}>
            Cancel
          </Button>
          <Box ml={1} width={136}>
            <Button
              buttonType="twoTone"
              fullWidth
              type="submit"
              disabled={!isDirty}
              isLoading={formState.isSubmitting}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </form>
    );
  },
);
