import { Theme, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    minHeight: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },

  container: {
    minHeight: "100%",
    display: "flex",
    alignItems: "center",
    maxWidth: 800,

    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      minHeight: "auto",
      padding: 24,
    },
  },

  leftSide: {
    padding: 24,
    flex: 3,
    display: "flex",
    justifyContent: "center",

    [theme.breakpoints.down("xs")]: {
      marginBottom: 16,
    },
  },

  mainText: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    maxWidth: 340,
  },

  rightSide: {
    padding: 16,
    flex: 2,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",

    [theme.breakpoints.down("xs")]: {
      flexDirection: "initial",
      flexWrap: "wrap",
    },
  },

  card: {
    display: "flex",
    justifyContent: "center",
    margin: "32px 0",

    [theme.breakpoints.down("xs")]: {
      margin: 16,
    },
  },
}));
