import { memo, useCallback } from "react";
import { applyPhoneMask, colors, useAlert } from "@chhjpackages/components";
import { Divider, IconButton } from "@material-ui/core";

import { Appointments } from "features/appointment";
import { ClipboardIcon, GeoIcon, PersonIcon, PhoneIcon } from "shared/assets";
import { ListViewItem } from "shared/ui";
import { createAddressString } from "shared/utils";

import { useEtaBannerStyles } from "./styles";

export const AccountInfo = memo(
  ({ appointment }: { appointment: Appointments }) => {
    const styles = useEtaBannerStyles();

    const { showAlert } = useAlert();

    const handleCopyToClipBoard = useCallback(
      (text: string) => {
        navigator.clipboard.writeText(text);
        showAlert("Success! Copied to clipboard.", {
          variant: "success",
        });
      },
      [showAlert],
    );

    return (
      <div>
        <div className={styles.row}>
          <ListViewItem
            icon={<PersonIcon fontSize={24} color={colors.grey80} />}
            label="Client name"
            text={
              appointment.account.firstName + " " + appointment.account.lastName
            }
          />
        </div>

        {appointment.account.phone && (
          <>
            <Divider />
            <div className={styles.row}>
              <ListViewItem
                icon={<PhoneIcon color={colors.grey80} />}
                label="Primary phone"
                text={
                  <div className={styles.textWithIconContainer}>
                    {applyPhoneMask(appointment.account.phone)}

                    <IconButton
                      size="small"
                      className="iconButtonSmall"
                      onClick={() =>
                        handleCopyToClipBoard(appointment.account.phone)
                      }
                    >
                      <ClipboardIcon fontSize={12} />
                    </IconButton>
                  </div>
                }
                actions={[
                  {
                    title: "Call",
                    onClick: () =>
                      window.open(`tel:${appointment.account.phone}`),
                  },
                  {
                    title: "Text",
                    onClick: () =>
                      window.open(`sms:${appointment.account.phone}`),
                  },
                ]}
              />
            </div>
          </>
        )}

        {appointment.account.cell && (
          <>
            <Divider />
            <div className={styles.row}>
              <ListViewItem
                icon={<PhoneIcon color={colors.grey80} />}
                label="Secondary phone"
                text={
                  <div className={styles.textWithIconContainer}>
                    {applyPhoneMask(appointment.account.cell)}

                    <IconButton
                      size="small"
                      className="iconButtonSmall"
                      onClick={() =>
                        handleCopyToClipBoard(appointment.account.cell)
                      }
                    >
                      <ClipboardIcon fontSize={12} />
                    </IconButton>
                  </div>
                }
                actions={[
                  {
                    title: "Call",
                    onClick: () =>
                      window.open(`tel:${appointment.account.cell}`),
                  },
                  {
                    title: "Text",
                    onClick: () =>
                      window.open(`sms:${appointment.account.cell}`),
                  },
                ]}
              />
            </div>
          </>
        )}

        <Divider />
        <div className={styles.row}>
          <ListViewItem
            icon={<GeoIcon color={colors.grey80} fontSize={22} />}
            label="Service address"
            text={createAddressString(
              appointment.account.address,
              appointment.account.address2,
              appointment.account.city,
              appointment.account.state,
              appointment.account.postal,
            )}
          />
        </div>
      </div>
    );
  },
);
