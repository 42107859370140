import { Grid, Typography } from "@material-ui/core";
import { memo } from "react";

import { ItemCardProps } from "./types";
import { useStyles } from "./styles";

export const ItemCard = memo(({ title, controlElement }: ItemCardProps) => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Grid container spacing={1} alignItems="center" justify="space-between">
        <Grid item>
          <Typography variant="caption" className={styles.title}>
            {title}
          </Typography>
        </Grid>
        {controlElement && <Grid item>{controlElement}</Grid>}
      </Grid>
    </div>
  );
});
