import { colors } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  root: {
    "& > div": {
      border: `1px solid ${colors.grey30}`,
      borderBottom: "none",
      backgroundColor: colors.white,
      "&:last-child": {
        borderBottom: `1px solid ${colors.grey30}`,
      },
      "&:nth-child(even)": {
        backgroundColor: colors.grey10,
      },
    },
  },
});
