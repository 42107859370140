import { memo, useMemo } from "react";
import { Typography } from "@material-ui/core";
import { useStore } from "effector-react";

import { $appointmentStore } from "features/appointment";
import { ReactComponent as PricingLess18 } from "../../assets/images/pricings/<1-8.svg";
import { ReactComponent as Pricing18 } from "../../assets/images/pricings/1-8.svg";
import { ReactComponent as Pricing16 } from "../../assets/images/pricings/1-6.svg";
import { ReactComponent as Pricing14 } from "../../assets/images/pricings/1-4.svg";
import { ReactComponent as Pricing13 } from "../../assets/images/pricings/1-3.svg";
import { ReactComponent as Pricing38 } from "../../assets/images/pricings/3-8.svg";
import { ReactComponent as Pricing12 } from "../../assets/images/pricings/1-2.svg";
import { ReactComponent as Pricing58 } from "../../assets/images/pricings/5-8.svg";
import { ReactComponent as Pricing34 } from "../../assets/images/pricings/3-4.svg";
import { ReactComponent as Pricing23 } from "../../assets/images/pricings/2-3.svg";
import { ReactComponent as Pricing56 } from "../../assets/images/pricings/5-6.svg";
import { ReactComponent as Pricing78 } from "../../assets/images/pricings/7-8.svg";
import { ReactComponent as PricingFull } from "../../assets/images/pricings/full.svg";
import { $pricings } from "features/pricings";
import { Pricing } from "shared/types";

import { useStyles } from "./styles";

const loads = [
  { key: "less than 1/8", icon: <PricingLess18 /> },
  { key: "1/8", icon: <Pricing18 /> },
  { key: "1/6", icon: <Pricing16 /> },
  { key: "1/4", icon: <Pricing14 /> },
  { key: "1/3", icon: <Pricing13 /> },
  { key: "3/8", icon: <Pricing38 /> },
  { key: "1/2", icon: <Pricing12 /> },
  { key: "5/8", icon: <Pricing58 /> },
  { key: "2/3", icon: <Pricing23 /> },
  { key: "3/4", icon: <Pricing34 /> },
  { key: "5/6", icon: <Pricing56 /> },
  { key: "7/8", icon: <Pricing78 /> },
  { key: "full truckload", icon: <PricingFull /> },
];

export const Page11 = memo(() => {
  const styles = useStyles();
  const { appointment } = useStore($appointmentStore);
  const { pricings } = useStore($pricings);

  const { surcharges, truckLoads } = useMemo(() => {
    return pricings.reduce<{
      surcharges: Pricing[];
      truckLoads: { [keys: string]: number };
    }>(
      (list, curr) => {
        if (curr.subcategory.name.toLowerCase().includes("surcharges")) {
          list.surcharges.push(curr);
        }

        loads.forEach((el) => {
          if (
            curr.category.id === appointment?.category.id &&
            curr.subcategory.name.toLowerCase().includes("basic") &&
            curr.name.toLowerCase().includes(el.key)
          ) {
            list.truckLoads[el.key] = curr.price;
          }
        });

        return list;
      },
      {
        surcharges: [],
        truckLoads: {},
      },
    );
  }, [appointment?.category.id, pricings]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.mainText}>
          <Typography variant="h2" component="div" color="primary">
            10% bigger
          </Typography>
          <Typography variant="h4" component="div">
            than our competition
          </Typography>
        </div>

        <div className={styles.surcharges}>
          <Typography
            variant="h4"
            color="secondary"
            component="div"
            className={styles.surchargesTitle}
          >
            Surcharges / Fees
          </Typography>

          <div className={styles.surchargesList}>
            {surcharges.length ? (
              <>
                {surcharges.map((el) => (
                  <div className={styles.surchargesListItem}>
                    <Typography variant="subtitle2">
                      {el.name.replace(" Fee", "")}
                    </Typography>
                    <Typography variant="body2">${el.price}</Typography>
                  </div>
                ))}
              </>
            ) : (
              <>
                <div className={styles.surchargesListItem}>
                  <Typography
                    variant="subtitle2"
                    className={styles.surchargeName}
                  >
                    {" "}
                  </Typography>
                  <Typography variant="body2"> </Typography>
                </div>
                <div className={styles.surchargesListItem}>
                  <Typography
                    variant="subtitle2"
                    className={styles.surchargeName}
                  >
                    {" "}
                  </Typography>
                  <Typography variant="body2"> </Typography>
                </div>
                <div className={styles.surchargesListItem}>
                  <Typography
                    variant="subtitle2"
                    className={styles.surchargeName}
                  >
                    {" "}
                  </Typography>
                  <Typography variant="body2"> </Typography>
                </div>
                <div className={styles.surchargesListItem}>
                  <Typography
                    variant="subtitle2"
                    className={styles.surchargeName}
                  >
                    {" "}
                  </Typography>
                  <Typography variant="body2"> </Typography>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div className={styles.body}></div>

      <div className={styles.footer}>
        <Typography variant="h4" component="div">
          Our pricelist
        </Typography>

        <div className={styles.priceList}>
          {loads.map((item) =>
            truckLoads[item.key] ? (
              <div className={styles.priceCard}>
                <Typography
                  variant="h5"
                  color="primary"
                  component="div"
                  align="center"
                >
                  {item.icon}
                </Typography>
                <Typography
                  component="div"
                  className={styles.price}
                  align="center"
                >
                  ${truckLoads[item.key]}
                </Typography>
              </div>
            ) : (
              <></>
            ),
          )}
        </div>
      </div>
    </div>
  );
});
