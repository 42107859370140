import { Box } from "@material-ui/core";
import { memo } from "react";

import { SelectionCard, SelectionCardSkeleton } from "shared/ui";

import { CouponsListProps } from "./types";

export const CouponsList = memo(
  ({
    coupons,
    selectedCouponId,
    loading,
    disabled,
    onToggleSelect,
  }: CouponsListProps) => {
    return (
      <>
        {loading &&
          [...Array(3)].map((_, i) => (
            <Box key={i} mt={i !== 0 ? 2 : 0}>
              <SelectionCardSkeleton />
            </Box>
          ))}
        {!loading &&
          coupons.length > 0 &&
          coupons.map(({ id, name, amount }, i) => (
            <Box key={i} mt={i !== 0 ? 2 : 0}>
              <SelectionCard
                title={name}
                subTitle={`$${amount} OFF`}
                selected={id === selectedCouponId}
                disabled={disabled}
                onToggleSelect={() => onToggleSelect(id)}
              />
            </Box>
          ))}
      </>
    );
  },
);
