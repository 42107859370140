import { Theme, makeStyles } from "@material-ui/core";
import { colors } from "@chhjpackages/components";

import USmap from "../../assets/images/us-map.svg";

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    position: "relative",
    width: "100%",
    height: "100%",

    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      height: "initial",
      minHeight: "initial",
      overflow: "initial",
    },

    "&:after": {
      content: "''",
      display: "block",
      width: "calc(100% - 48px)",
      maxWidth: 800,
      height: "calc(100% - 48px)",
      position: "absolute",
      top: 24,
      left: "45%",
      transform: "translateX(-55%)",
      backgroundImage: `url(${USmap})`,
      backgroundSize: "100% auto",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center left",

      "@media (orientation: portrait)": {
        [theme.breakpoints.down("md")]: {
          left: "50%",
          top: 128,
          transform: "translateX(-50%)",
        },
      },

      [theme.breakpoints.down("xs")]: {
        position: "fixed",
        top: 0,
        height: "65%",
      },
    },

    "&:before": {
      content: "''",
      display: "block",
      width: "100%",
      height: "30vh",
      position: "absolute",
      bottom: 0,
      left: 0,
      backgroundColor: colors.grey20,

      [theme.breakpoints.down("xs")]: {
        position: "fixed",
      },
    },
  },

  leftSide: {
    flex: 2,
    display: "flex",
    justifyContent: "flex-end",
    height: "100vh",
    position: "sticky",
    top: 0,
    zIndex: 1,

    "@media (orientation: portrait)": {
      [theme.breakpoints.down("md")]: {
        flex: 1,
      },
    },

    [theme.breakpoints.down("xs")]: {
      position: "static",
      padding: "64px 0 32px",
      justifyContent: "center",
      flex: "initial",
      height: "initial",
    },
  },

  mainText: {
    marginTop: 64,
    marginRight: 128,

    "@media (orientation: portrait)": {
      [theme.breakpoints.down("md")]: {
        marginRight: 64,
      },
    },

    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
      marginRight: 0,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },

  rightSide: {
    padding: 16,
    flex: 1,
    display: "flex",
    flexDirection: "column",
    position: "relative",
    zIndex: 1,
    maxHeight: "100%",
    overflow: "auto",

    "@media (orientation: portrait)": {
      [theme.breakpoints.down("md")]: {
        alignItems: "center",
      },
    },

    [theme.breakpoints.down("xs")]: {
      flexDirection: "initial",
      flexWrap: "wrap",
      flex: "initial",
      maxHeight: "initial",
      overflow: "initial",
      justifyContent: "center",
    },
  },

  card: {
    display: "flex",
    padding: 12,
    gap: 10,
    background: colors.basic.white,
    boxShadow: "10px 10px 0px 0px #DADFE8, -1px -1px 10px 0 #DADFE8",
    flexWrap: "wrap",
    width: 280,
    margin: "16px 0",

    [theme.breakpoints.down("xs")]: {
      "& img": {
        maxWidth: "100%",
      },
    },
  },

  quoteMark: {
    marginRight: 8,
  },

  reviewText: {
    flex: 1,
    fontSize: 16,
  },

  reviewAuthor: {
    width: "100%",
    color: colors.grey100,
    marginTop: 8,
  },
}));
