import { colors } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 24,
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    gap: 24,
  },
  title: {
    color: colors.grey140,
    fontWeight: "bold",
  },
}));
