import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  addressInfoNavigateContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 16,
    gap: 8,
  },
  addressInfoContainer: {
    display: "flex",
    alignItems: "center",
    gap: 16,
  },
  navigateButtonWrapper: {
    marginRight: -8,
  },
  startEndButtonWrapper: {
    marginTop: 24,
  },
  buttonRoot: {
    padding: 8,
  },
}));
