import { Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useStore } from "effector-react";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Outlet, useNavigate } from "react-router";
import { useAlert, useConfirmationDialog } from "@chhjpackages/components";
import moment from "moment";
import clsx from "clsx";

import {
  $appointmentsStore,
  appointmentFilters,
  AppointmentList,
  AppointmentListSkeleton,
  Appointments,
  AppointmentType,
  getAppointmentsFx,
} from "features/appointment";
import { useFilters, useSearch } from "widgets/filters-bar/model";
import { useSidebarDispatch } from "features/sidebar/model";
import {
  $appointmentCategoriesStore,
  getAppointmentCategoriesFx,
} from "features/categories";
import { $zonesStore, getZonesFx } from "features/zones";
import {
  $dashboardStore,
  setIsGridView,
  TeamButton,
  TeamButtonSkeleton,
} from "features/dashboard";
import {
  $estimateStatusesStore,
  $jobStatusesStore,
  getEstimateStatusesFx,
  getJobStatusesFx,
} from "features/statuses";
import {
  allFilter,
  apiDateFormat,
  apiDateTimeFormat,
  assignments,
  createAddressString,
  groupByTime,
  jobTypes,
  routePaths,
  throttle,
  USADateFormatShort,
} from "shared/utils";
import { useSideNavDispatch } from "features/sidenav";
import { BackToTopButton } from "shared/ui";
import { ChangesQueue } from "shared/ui/changes-queue";
import { $auth } from "features/auth";
import {
  $teamsStore,
  getTeamsByDateFx,
  updateTeamSchedulesFx,
} from "features/teams";
import { AppointmentStatus, DataItem } from "shared/types";
import { NoAppointment } from "features/appointment/ui/no-appointments-screen";
import {
  addCommunicationFx,
  CommunicationStatuses,
  CommunicationTypesEnum,
  DataChange,
} from "features/communications";
import { useLocationTimezone } from "shared/hooks";
import { EtaBanner, useMarkEtaComplete, FiltersBar } from "widgets";
import { $offlineRequestsStore } from "features/job-offline-changes";

import { useDashboardStyles } from "./assets";

const CANCELED_STATUS_ID = -2;
const LOST_STATUS_ID = -3;

export const Dashboard = memo(() => {
  const styles = useDashboardStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: true,
  });

  const { appointments } = useStore($appointmentsStore);
  const { categories, loading: isAppointmentCategoriesLoading } = useStore(
    $appointmentCategoriesStore,
  );
  const { statuses: jobStatuses, loading: isJobStatusesLoading } =
    useStore($jobStatusesStore);
  const { statuses: estimateStatuses, loading: isEstimateStatusesLoading } =
    useStore($estimateStatusesStore);
  const { zones, loading: isZonesLoading } = useStore($zonesStore);
  const { isGridView } = useStore($dashboardStore);
  const { locationId } = useStore($auth);
  const { userTeamsByDate, loading: teamsLoading } = useStore($teamsStore);
  const { offlineRequests } = useStore($offlineRequestsStore);

  const navigate = useNavigate();
  const { setPageName, setPageNameAddition } = useSideNavDispatch();
  const { setSidebarContent, clearSidebarContent, hideSidebar, showSidebar } =
    useSidebarDispatch();
  const { openConfirmation, closeConfirmation } = useConfirmationDialog({
    title: "Create team",
    message: "Would you like to create a team?",
    confirmButtonText: "Create team",
    cancelButtonText: "Close",
  });
  const { showAlert } = useAlert();
  const { utcToZone } = useLocationTimezone("location");
  const { openMarkEtaComplete, nextAppointment } = useMarkEtaComplete();

  const [isScrollTop, setIsScrollTop] = useState(false);
  const [isFirstLoading, setFirstLoading] = useState(true);
  const [isAppointmentsLoading, setIsAppointmentsLoading] = useState(true);

  const appointmentTimes = useMemo(() => {
    const groupedAppointmentsByTime = groupByTime(
      appointments,
      (appointment) => moment(appointment.startDate).format(apiDateTimeFormat),
      "hh:mm A",
    );

    return groupedAppointmentsByTime.map((item, index) => ({
      id: index,
      name: item.time,
    }));
  }, [appointments]);

  const appointmentsForFilter = useMemo(
    () =>
      appointments.map((item) => ({
        ...item,
        type: jobTypes.find((jobType) => jobType.name === item.type) || "",
        status:
          item.type === "EST"
            ? {
                ...item.estStatus,
                id:
                  item.estStatus.id === AppointmentStatus.Estimate.Lost
                    ? LOST_STATUS_ID
                    : item.estStatus.id === AppointmentStatus.Estimate.Canceled
                    ? CANCELED_STATUS_ID
                    : item.estStatus.id - 100,
              }
            : {
                ...item.status,
                id:
                  item.status.id === AppointmentStatus.Job.Canceled
                    ? CANCELED_STATUS_ID
                    : item.status.id === AppointmentStatus.Job.Lost
                    ? LOST_STATUS_ID
                    : item.status.id,
              },
      })),
    [appointments],
  );

  const statusesForFilter = useMemo(() => {
    const jobStatusesTemp = jobStatuses.filter(
      (status) =>
        status.id !== AppointmentStatus.Job.Canceled &&
        status.id !== AppointmentStatus.Job.Lost,
    );
    const estimateStatusesTemp = estimateStatuses
      .filter(
        (status) =>
          status.id !== AppointmentStatus.Estimate.Canceled &&
          status.id !== AppointmentStatus.Estimate.Lost,
      )
      .map((status) => ({ ...status, id: status.id - 100 }));

    return [
      ...jobStatusesTemp,
      ...estimateStatusesTemp,
      { id: CANCELED_STATUS_ID, name: "Canceled" },
      { id: LOST_STATUS_ID, name: "Lost" },
      allFilter,
    ];
  }, [jobStatuses, estimateStatuses]);

  const {
    filteredList: primaryFilteredList,
    onFilter,
    usedFilters,
  } = useFilters<Omit<Appointments, "type"> & { type: DataItem | string }>(
    appointmentsForFilter,
    appointmentFilters({
      appointmentTypes: jobTypes,
      appointmentStatuses: statusesForFilter,
      appointmentTimes: [...appointmentTimes, allFilter],
      categories: [...categories, allFilter],
      zones: [...zones, allFilter],
      assignments: assignments,
    }),
    userTeamsByDate,
    (filterState) => {
      localStorage.setItem("crew/filters", JSON.stringify(filterState));
    },
  );

  const filteredAppointments = useMemo(
    () =>
      primaryFilteredList.map((item) => ({
        ...item,
        type: (item.type as DataItem).name as AppointmentType,
      })),
    [primaryFilteredList],
  );

  const { searchedList, onSearch } = useSearch<Appointments>(
    filteredAppointments,
    (appointment) => ({
      id: appointment.id,
      type: appointment.type,
      category: appointment.category.name,
      status: appointment.status.name,
      name: `${appointment.account.firstName} ${appointment.account.lastName}`,
      address: createAddressString(
        appointment.origin.address,
        appointment.origin.address2,
        appointment.origin.city,
        appointment.origin.state,
        appointment.origin.postal,
      ),
      address2: createAddressString(
        appointment.destination.address,
        appointment.destination.address2,
        appointment.destination.city,
        appointment.destination.state,
        appointment.destination.postal,
      ),
      city: appointment.destination.city,
      postal: appointment.destination.postal,
      state: appointment.destination.state,
      captain: appointment.team.captain.name,
    }),
  );

  const groupedByTimeSearchedAppointments = useMemo(
    () =>
      groupByTime(
        searchedList,
        (appointment) =>
          moment(appointment.startDate).format(apiDateTimeFormat),
        "hh:mm A",
      ),
    [searchedList],
  );

  const noAppointments = useMemo(
    () =>
      groupedByTimeSearchedAppointments.length === 0 && !isAppointmentsLoading,
    [groupedByTimeSearchedAppointments.length, isAppointmentsLoading],
  );

  const showUsedFilters = useMemo(
    () => usedFilters.length > 0 && isMobile,
    [usedFilters.length, isMobile],
  );

  const showBackToTop = useMemo(
    () => isMobile && !isAppointmentsLoading && isScrollTop,
    [isMobile, isAppointmentsLoading, isScrollTop],
  );

  const showEtaBanner = useMemo(
    () => !isAppointmentsLoading && !!nextAppointment,
    [isAppointmentsLoading, nextAppointment],
  );

  const handleSetView = useCallback(() => {
    setIsGridView(!isGridView);
  }, [isGridView]);

  const handleOpenContactsDialog = useCallback(
    (appointmentId: number) => {
      navigate(routePaths.appointmentContacts(appointmentId));
    },
    [navigate],
  );

  const handleOpenNotesDialog = useCallback(
    (appointmentId: number) => {
      navigate(routePaths.appointmentNotes(appointmentId));
    },
    [navigate],
  );

  const handleCreateTeam = useCallback(() => {
    closeConfirmation();
    setTimeout(() => navigate(routePaths.createTeam()), 10);
  }, [navigate, closeConfirmation]);

  const handleOpenMapTypeDialog = useCallback(
    (appointmentId: number, destination: string) => {
      navigate(routePaths.appointmentMapTypes(appointmentId), {
        state: { destination },
      });
    },
    [navigate],
  );

  const handlePickUp = useCallback(
    async (appointmentId: number, scheduleId: number) => {
      if (!locationId) {
        showAlert("Error! Failed to pick up job.", { variant: "error" });
        return;
      }

      if (userTeamsByDate.length !== 0) {
        try {
          const team = userTeamsByDate[userTeamsByDate.length - 1];

          await updateTeamSchedulesFx({
            locationId: locationId,
            teamId: team.id,
            appointmentId: appointmentId,
            payload: {
              schedules: [
                ...team.schedules.map((schedule) => schedule.id),
                scheduleId,
              ],
            },
          });

          try {
            const dataChanges: DataChange[] = [
              {
                field: `Schedule (ID: ${scheduleId})`,
                fieldPrefix: "team",
                oldValue: undefined,
                newValue: `${team.name} (ID: ${team.id})`,
              },
            ];

            await addCommunicationFx({
              locationId: locationId,
              appointmentId: appointmentId,
              payload: {
                dataChanges: JSON.stringify(dataChanges),
                details: `Team members:\n${team.users
                  .map(
                    ({ firstName, lastName }, i) =>
                      `${[firstName, lastName]
                        .filter((item) => !!item)
                        .join(" ")}${i !== team.users.length - 1 ? ",\n" : ""}`,
                  )
                  .join("")}`,
                date: moment().format(`${USADateFormatShort} HH:mm`),

                type: {
                  id: CommunicationTypesEnum.Other,
                },
                status: {
                  id: CommunicationStatuses.OtherStatusesEnum.Successful,
                },
              },
            });
          } catch {}

          await getAppointmentsFx({
            locationId: locationId,
            date: utcToZone(
              moment.utc().format(apiDateTimeFormat),
              apiDateFormat,
            ),
          });

          showAlert("Success! Job has been picked up.", { variant: "success" });
        } catch {
          showAlert("Error! Failed to pick up job.", { variant: "error" });
        }
      } else {
        openConfirmation(handleCreateTeam);
      }
    },
    [
      userTeamsByDate,
      locationId,
      utcToZone,
      openConfirmation,
      handleCreateTeam,
      showAlert,
    ],
  );

  const isLoading = useMemo(
    () =>
      isAppointmentsLoading ||
      isJobStatusesLoading ||
      isEstimateStatusesLoading ||
      isAppointmentCategoriesLoading ||
      isZonesLoading,
    [
      isAppointmentsLoading,
      isJobStatusesLoading,
      isEstimateStatusesLoading,
      isAppointmentCategoriesLoading,
      isZonesLoading,
    ],
  );

  const teamButton = useMemo(
    () =>
      teamsLoading ? (
        <TeamButtonSkeleton />
      ) : (
        <TeamButton
          teams={userTeamsByDate}
          onClick={
            !userTeamsByDate.length
              ? () => openConfirmation(handleCreateTeam)
              : undefined
          }
        />
      ),
    [teamsLoading, userTeamsByDate, openConfirmation, handleCreateTeam],
  );

  useEffect(() => {
    setPageName("Dashboard");
    setPageNameAddition(<div style={{ marginLeft: -8 }}>{teamButton}</div>);

    return () => {
      setPageName("");
      setPageNameAddition(null);
    };
  }, [teamButton, setPageNameAddition, setPageName]);

  useEffect(() => {
    const onScroll = throttle(() => {
      const scrollPosition = document.scrollingElement?.scrollTop ?? 0;

      setIsScrollTop(scrollPosition >= 150);
    }, 25);

    document.addEventListener("scroll", onScroll);

    return () => document.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    showSidebar();
    setSidebarContent(
      <FiltersBar
        isGridView={isGridView}
        isSearch
        isLoading={isLoading}
        filters={appointmentFilters({
          appointmentTypes: jobTypes,
          appointmentStatuses: statusesForFilter,
          appointmentTimes: [...appointmentTimes, allFilter],
          categories: [...categories, allFilter],
          zones: [...zones, allFilter],
          assignments: assignments,
        })}
        onFilter={onFilter}
        onSearch={onSearch}
        handleSetView={handleSetView}
      />,
    );

    return () => {
      hideSidebar();
      clearSidebarContent();
    };
  }, [
    onFilter,
    onSearch,
    hideSidebar,
    showSidebar,
    setSidebarContent,
    clearSidebarContent,
    handleSetView,
    isLoading,
    isGridView,
    statusesForFilter,
    categories,
    zones,
    appointmentTimes,
  ]);

  useEffect(() => {
    if (isAppointmentCategoriesLoading) {
      getAppointmentCategoriesFx();
    }
  }, [isAppointmentCategoriesLoading]);

  useEffect(() => {
    if (isJobStatusesLoading) {
      getJobStatusesFx();
    }
  }, [isJobStatusesLoading]);

  useEffect(() => {
    if (isEstimateStatusesLoading) {
      getEstimateStatusesFx();
    }
  }, [isEstimateStatusesLoading]);

  useEffect(() => {
    const fetchData = async () => {
      if (locationId) {
        setIsAppointmentsLoading(true);
        await getAppointmentsFx({
          locationId: locationId,
          date: utcToZone(
            moment.utc().format(apiDateTimeFormat),
            apiDateFormat,
          ),
        });
        setIsAppointmentsLoading(false);
        setFirstLoading(false);
      }
    };

    fetchData();
  }, [locationId]);

  useEffect(() => {
    if (isZonesLoading && locationId) {
      getZonesFx({ locationId: locationId });
    }
  }, [isZonesLoading, locationId]);

  useEffect(() => {
    if (locationId) {
      getTeamsByDateFx({
        locationId: locationId,
        date: utcToZone(moment.utc().format(apiDateTimeFormat), apiDateFormat),
      });
    }
  }, [locationId]);

  return (
    <div className={styles.root}>
      {!isMobile && (
        <div className={styles.titleContainer}>
          <Typography variant="h3">Dashboard</Typography>

          <div className={styles.teamButtonContainer}>{teamButton}</div>
        </div>
      )}

      {showUsedFilters && (
        <div className={styles.usedFiltersContainer}>
          <Typography variant="body2">
            <b>Filters: </b>

            {usedFilters}
          </Typography>
        </div>
      )}

      <div className={styles.contentRoot}>
        {!!offlineRequests.length && (
          <Link
            to={routePaths.unprocessedChanges()}
            className={styles.changedQueueLink}
          >
            <ChangesQueue
              title={`YOU Have ${offlineRequests.length} changes in queue`}
              body="Tap to manage unprocessed changes"
              isDashboard
            />
          </Link>
        )}

        <div
          className={clsx(
            styles.appoinmentsListContainer,
            noAppointments && styles.appoinmentsListContainerNoAppointments,
          )}
        >
          {noAppointments && <NoAppointment />}

          {isAppointmentsLoading ? (
            <AppointmentListSkeleton isGridView={isGridView} />
          ) : (
            <AppointmentList
              isScroll={isFirstLoading}
              isGridView={isGridView}
              groupedByTimeAppointments={groupedByTimeSearchedAppointments}
              currentTeams={userTeamsByDate}
              handleOpenContactsDialog={handleOpenContactsDialog}
              handleOpenNotesDialog={handleOpenNotesDialog}
              handleOpenMapTypesDialog={handleOpenMapTypeDialog}
              handlePickUp={handlePickUp}
            />
          )}
        </div>

        {showBackToTop && (
          <BackToTopButton
            classes={{
              root: clsx(
                styles.backToTop,
                showEtaBanner && styles.backToTopWithEta,
              ),
            }}
          />
        )}

        {showEtaBanner && (
          <EtaBanner
            appointment={nextAppointment!}
            classes={{ container: styles.etaBanner }}
            onClick={openMarkEtaComplete}
          />
        )}

        <Outlet />
      </div>
    </div>
  );
});
