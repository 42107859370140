import { memo } from "react";
import { Typography } from "@material-ui/core";

import { useStyles } from "./styles";
import { NoteCardProps } from "./types";

export const NoteCard = memo(({ note }: NoteCardProps) => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Typography variant="body1" className={styles.noteText}>
        {note}
      </Typography>
    </div>
  );
});
