import { Button } from "@chhjpackages/components";
import { Box, Typography } from "@material-ui/core";
import moment from "moment";
import { memo, useCallback, useEffect, useMemo, useRef } from "react";
import { useFieldArray, useForm } from "react-hook-form";

import { Development } from "features/developments/model";
import { apiDateTimeFormat } from "shared/utils";

import { useStyles } from "./assets";
import { EditHoursFormProps, EditHoursFormValues } from "./model";
import { EditHoursElement, EditHoursElementSkeleton } from "./ui";

export const EditDevelopmentsHoursForm = memo(
  ({ initialValues, type, loading, onSubmit }: EditHoursFormProps) => {
    const styles = useStyles();

    const { control, formState, reset, handleSubmit } =
      useForm<EditHoursFormValues>({
        defaultValues: initialValues,
      });

    const { fields, update } = useFieldArray({
      control: control,
      name: "developmentPairs",
    });

    const isEndPrevTimeBeforeCurrStart = useMemo(
      () =>
        fields.map((developmentPair, index) => {
          if (index > 0) {
            const prevPair: {
              start: Development;
              end?: Development;
            } = fields[index - 1];

            if (prevPair.end) {
              return moment(prevPair.end.datetime).isAfter(
                developmentPair.start.datetime,
              );
            }
          }

          return false;
        }),
      [fields],
    );

    const isEndTimeBeforeStart = useMemo(
      () =>
        fields.map((developmentPair) => {
          if (developmentPair.end) {
            return moment(developmentPair.end.datetime).isBefore(
              developmentPair.start.datetime,
            );
          }

          return false;
        }),
      [fields],
    );

    const handleConfirmChangeTime = useCallback(
      (id: string, selectedTime: string, handleType: "start" | "end") => {
        const editingPairIndex = fields.findIndex((field) => field.id === id);
        const editingPair = fields.find((field) => field.id === id);

        if (editingPairIndex >= 0 && editingPair) {
          if (handleType === "start") {
            update(editingPairIndex, {
              ...editingPair,
              start: {
                ...editingPair.start,
                datetime: moment(selectedTime).format(apiDateTimeFormat),
              },
            });
          } else if (handleType === "end" && editingPair.end) {
            update(editingPairIndex, {
              ...editingPair,
              end: {
                ...editingPair.end,
                datetime: moment(selectedTime).format(apiDateTimeFormat),
              },
            });
          }
        }
      },
      [fields, update],
    );

    const firstRender = useRef(true);
    useEffect(() => {
      if (!firstRender.current) {
        return;
      }

      if (!loading) {
        reset(initialValues);
        firstRender.current = false;
      }
    }, [initialValues]);

    return (
      <Box flex={1} display="flex" flexDirection="column">
        <Box flex={1}>
          <Typography variant="body1">
            {type === "travel"
              ? "Select the time you left for this job till you arrived on site. We will calculate it into minutes for you."
              : "Type in the hours you’ve worked on this task and we will calculate it into minutes for you."}
          </Typography>

          {loading && (
            <Box mt={3}>
              {[...Array(1)].map((_, i) => (
                <div key={i}>
                  <EditHoursElementSkeleton />
                  <Box mt={1}>
                    <EditHoursElementSkeleton />
                  </Box>
                </div>
              ))}
            </Box>
          )}
          {!loading && fields.length === 0 && (
            <Box mt={3}>
              <Typography variant="body1">
                There are no {type === "travel" ? "travel" : "work"} hours yet.
              </Typography>
            </Box>
          )}
          {!loading &&
            fields.map((developmentPair, i) => (
              <Box mt={3} key={i}>
                <EditHoursElement
                  title={`Start ${type === "travel" ? "travel" : "work"}`}
                  clockTimepickerProps={{
                    value: developmentPair.start.datetime,
                    format: "hh:mm a",
                    placement: "right-end",
                    handleConfirm: (selectedTime) => {
                      handleConfirmChangeTime(
                        developmentPair.id,
                        selectedTime,
                        "start",
                      );
                    },
                  }}
                />
                {developmentPair.end && (
                  <Box mt={1}>
                    <EditHoursElement
                      title={`End ${type === "travel" ? "travel" : "work"}`}
                      clockTimepickerProps={{
                        value: developmentPair.end.datetime,
                        format: "hh:mm a",
                        placement: "right-end",
                        handleConfirm: (selectedTime) => {
                          handleConfirmChangeTime(
                            developmentPair.id,
                            selectedTime,
                            "end",
                          );
                        },
                      }}
                    />
                  </Box>
                )}
                {isEndPrevTimeBeforeCurrStart[i] && (
                  <Typography variant="body1" className={styles.errorMessage}>
                    The selected end time of the previous pair is after the
                    start time of this pair
                  </Typography>
                )}
                {isEndTimeBeforeStart[i] &&
                  !isEndPrevTimeBeforeCurrStart[i] && (
                    <Typography variant="body1" className={styles.errorMessage}>
                      The selected end time is before the start time
                    </Typography>
                  )}
              </Box>
            ))}
        </Box>
        <Box mt={3}>
          <Button
            buttonType="twoTone"
            type="submit"
            size="large"
            fullWidth
            isLoading={formState.isSubmitting}
            disabled={
              loading ||
              isEndTimeBeforeStart.includes(true) ||
              isEndPrevTimeBeforeCurrStart.includes(true) ||
              !formState.isDirty
            }
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </Box>
      </Box>
    );
  },
);
