import { Box, Grid, Typography } from "@material-ui/core";
import { NewSelect, colors } from "@chhjpackages/components";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useStore } from "effector-react/compat";

import {
  $settings,
  PaymentPlatformEnum,
  SettingItemsEnum,
  SettingItemsIdEnum,
  navigationAppOptions,
  paymentPlatformOptions,
  setSettingEv,
  setSettingValue,
  settingsList,
} from "features/settings";
import { useSideNavDispatch } from "features/sidenav";
import { Swither } from "shared/ui";
import { networkCheck } from "shared/utils";
import { useTrainingMode } from "features/training-mode";
import { NewSelectOption } from "shared/types";
import { HelperTxt } from "shared/ui/form-items/styles";
import { $location } from "features/auth";
import { isHunkPayEnabled } from "shared/config";

export interface Statuses {
  [key: string]: boolean;
}

export const Settings = memo(() => {
  const { setPageName } = useSideNavDispatch();
  const { isTrainingModeOn, toggleTrainingMode } = useTrainingMode();

  const {
    preJobInspection,
    postJobInspetion,
    autoExpandJobSteps,
    navigationApp,
    paymentPlatform,
  } = useStore($settings);
  const { location } = useStore($location);

  const settingStatuses = useMemo(
    () => ({
      [SettingItemsEnum.PreJobInspection]: preJobInspection,
      [SettingItemsEnum.PostJobInspection]: postJobInspetion,
      [SettingItemsEnum.AutoExpandJobSteps]: autoExpandJobSteps,
    }),
    [preJobInspection, postJobInspetion, autoExpandJobSteps],
  );

  const disabledPaymentPlatformOptions = useMemo(
    () =>
      paymentPlatformOptions.map((option) => {
        const optionDisabled =
          (option.value === PaymentPlatformEnum.Square &&
            !(
              location?.integrations.square.active &&
              location?.integrations.square.valid
            )) ||
          (option.value === PaymentPlatformEnum.HunkPay &&
            (!location?.integrations.hunkpay.valid || !isHunkPayEnabled));

        if (optionDisabled) {
          return {
            ...option,
            label: `${option.label} - integration not available`,
            isDisabled: true,
          };
        }

        return option;
      }),
    [
      location?.integrations.square.active,
      location?.integrations.square.valid,
      location?.integrations.hunkpay.valid,
    ],
  );

  const setSettings = useCallback(
    (
      settingId: SettingItemsIdEnum,
      settingName: SettingItemsEnum,
      value: unknown,
    ) => {
      setSettingEv({ name: settingId, value: value });
      setSettingValue(settingName, value);
    },
    [],
  );

  useEffect(() => {
    setPageName("Settings");

    return () => {
      setPageName("");
    };
  }, [setPageName]);

  return (
    <Box p={2}>
      <Grid container direction="column" spacing={2}>
        {settingsList.map((setting) => (
          <Grid item key={setting.id}>
            <Typography variant="h4">{setting.name}</Typography>

            <Box mt={1}>
              {setting.settings.map((item) => (
                <Grid
                  key={item.name}
                  container
                  alignItems="center"
                  justify="space-between"
                  spacing={2}
                >
                  <Grid item>
                    <Typography
                      variant="subtitle1"
                      style={{ color: colors.grey140 }}
                    >
                      {item.name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Swither
                      size="medium"
                      checked={(settingStatuses as Statuses)[item.name]}
                      disabled={!networkCheck()}
                      onChange={(_, checked) =>
                        setSettings(item.id, item.name, checked)
                      }
                    />
                  </Grid>
                </Grid>
              ))}
            </Box>
          </Grid>
        ))}

        <Grid item>
          <Typography variant="h4">Training mode</Typography>

          <Box mt={1}>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              spacing={2}
            >
              <Grid item>
                <Box
                  color={colors.grey140}
                  fontWeight={700}
                  lineHeight="24px"
                  letterSpacing={0.15}
                >
                  {isTrainingModeOn ? "On" : "Off"}
                </Box>
              </Grid>
              <Grid item>
                <Swither
                  size="medium"
                  checked={isTrainingModeOn}
                  onChange={toggleTrainingMode}
                  disabled={!networkCheck()}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item>
          <Typography variant="h4">Navigation app</Typography>

          <Box mt={2}>
            <NewSelect
              name="defaultNavMap"
              // @ts-ignore
              value={navigationApp}
              label="Default navigation app"
              virtualize={false}
              options={navigationAppOptions}
              onChange={(newValue: NewSelectOption | null) =>
                setSettings(
                  SettingItemsIdEnum.NavigationApp,
                  SettingItemsEnum.NavigationApp,
                  newValue,
                )
              }
            />
            <HelperTxt>*required</HelperTxt>
          </Box>
        </Grid>

        <Grid item>
          <Typography variant="h4">Payment</Typography>

          <Box mt={2}>
            <NewSelect
              name="paymentPlatform"
              // @ts-ignore
              value={paymentPlatform}
              label="Payment platform"
              virtualize={false}
              options={disabledPaymentPlatformOptions}
              menuPlacement="auto"
              onChange={(newValue: NewSelectOption | null) =>
                setSettings(
                  SettingItemsIdEnum.PaymentPlatform,
                  SettingItemsEnum.PaymentPlatform,
                  newValue,
                )
              }
            />
            <HelperTxt>*required</HelperTxt>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
});
