import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  select: {
    fontSize: "18px!important",
    paddingTop: "4px!important",
    paddingBottom: "4px!important",
    width: "200px",
  },
}));
