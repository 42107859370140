import { colors } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  ul: {
    paddingLeft: 32,
    margin: 0,
  },
  link: {
    color: colors.primary.main,
    textDecorationSkipInk: "none",
    textUnderlineOffset: "2px",
    textDecorationThickness: 1,
  },
});
