export const throttle = (callback: Function, delay: number) => {
  let wait = false;

  return () => {
    if (!wait) {
      callback();

      wait = true;

      setTimeout(() => {
        wait = false;
      }, delay);
    }
  };
};
