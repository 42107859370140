import { memo } from "react";
import { IconButton, Typography } from "@material-ui/core";

import { BoldPencilIcon } from "shared/assets";

import { useStyles } from "./styles";
import { EditingItemProps } from "./types";

export const EditingItem = memo(
  ({ title, value, onEdit }: EditingItemProps) => {
    const styles = useStyles();

    return (
      <div className={styles.root}>
        <div className={styles.titleContainer}>
          <Typography variant="body1" className={styles.title}>
            {title}
          </Typography>

          <IconButton className="iconButtonMedium" onClick={onEdit}>
            <BoldPencilIcon fontSize={14} />
          </IconButton>
        </div>

        <Typography variant="body1">{value}</Typography>
      </div>
    );
  },
);
