import {
  Chip,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
  IconButton,
} from "@material-ui/core";
import { colors, PencilIcon } from "@chhjpackages/components";
import { Skeleton } from "@material-ui/lab";
import { createSearchParams, useNavigate } from "react-router-dom";
import { memo, useCallback } from "react";

import { TabStyled, TabsStyled } from "shared/ui/tabs";
import { AttachmentIcon } from "shared/assets/icons";
import { BackTitle } from "shared/ui";
import { routePaths } from "shared/utils";

import { useStyles } from "./styles";
import { AppointmentHeaderProps } from "./types";

export const AppointmentHeader = memo(
  ({
    id,
    tab,
    teams,
    type,
    category,
    status,
    zone,
    isNasa,
    loading,
    changeTab,
    onBack,
    openAttachment,
  }: AppointmentHeaderProps) => {
    const styles = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"), {
      noSsr: true,
    });

    const navigate = useNavigate();

    const openTeamEditing = useCallback(
      (teamId: number, scheduleId: number) => {
        navigate({
          pathname: routePaths.editTeam(teamId, id),
          search: `${createSearchParams({ scheduleId: String(scheduleId) })}`,
        });
      },
      [id, navigate],
    );

    return (
      <div className={styles.mainBox}>
        <div style={{ margin: "0px -16px" }}>
          <BackTitle
            elevation={0}
            title={String(id)}
            onBack={onBack}
            content={
              isNasa && (
                <div style={{ color: colors.secondary.main }}>
                  <Typography variant="h3" color="inherit">
                    NASA
                  </Typography>
                </div>
              )
            }
            actionButtons={
              !isMobile
                ? [
                    {
                      name: "Attachments",
                      icon: <AttachmentIcon color="primary" fontSize={22} />,
                      onClick: () => openAttachment(),
                    },
                  ]
                : []
            }
          />
        </div>

        {loading && (
          <div style={{ display: "flex", marginBottom: 8 }}>
            <Skeleton
              variant="rect"
              width={150}
              height={24}
              style={{ borderRadius: 16 }}
            />
          </div>
        )}

        {!loading &&
          !!teams.length &&
          teams.map(({ id: teamId, name, time, scheduleId }) => {
            return (
              <div
                key={teamId}
                style={{
                  marginBottom: 8,
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", marginRight: 8 }}>
                  <Typography className={styles.teamName} variant="h6">
                    {teams.length > 1 && <b>{time} - </b>}
                    {name}
                  </Typography>
                </div>
                {scheduleId && (
                  <div style={{ margin: -12 }}>
                    <IconButton
                      onClick={() => openTeamEditing(teamId, scheduleId)}
                    >
                      <PencilIcon fontSize={14} />
                    </IconButton>
                  </div>
                )}
              </div>
            );
          })}

        <div className={styles.headerChips}>
          <Grid item container alignItems="center">
            {loading &&
              [...Array(3)].map((_, i) => (
                <div key={i} style={{ marginRight: 16 }}>
                  <Skeleton
                    variant="rect"
                    width={75}
                    height={24}
                    style={{ borderRadius: 16 }}
                  />
                </div>
              ))}
            {!loading && (
              <Grid container spacing={2}>
                {type && (
                  <Grid item>
                    <Chip
                      className={styles.headerChip}
                      size="small"
                      label={type}
                    />
                  </Grid>
                )}
                {category && (
                  <Grid item>
                    <Chip
                      className={styles.headerChip}
                      size="small"
                      label={category}
                    />
                  </Grid>
                )}
                {status && (
                  <Grid item>
                    <Chip
                      className={styles.headerChip}
                      size="small"
                      label={status}
                    />
                  </Grid>
                )}
                {zone && (
                  <Grid item>
                    <Chip
                      className={styles.headerChip}
                      size="small"
                      label={zone}
                    />
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </div>

        <Grid item style={{ width: "100%" }}>
          <TabsStyled variant="fullWidth" value={tab} onChange={changeTab}>
            <TabStyled label="Process" value={"process"} disabled={loading} />
            <TabStyled label="General" value={"general"} disabled={loading} />
            <TabStyled label="Notes" value={"notes"} disabled={loading} />
          </TabsStyled>
        </Grid>
      </div>
    );
  },
);
