import { colors } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  root: {
    padding: "8px 16px",
    backgroundColor: colors.basic.white,
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    color: colors.basic.black,
  },
  description: {
    color: colors.grey180,
  },
  actionSectionRoot: {
    display: "flex",
    alignItems: "center",
  },
  dividerWrapper: {
    margin: "0px 16px",
    height: "100%",
  },
  iconButtonWrapper: {
    margin: -12,
  },
}));
