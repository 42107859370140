import { colors } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  root: {
    padding: 16,
    border: `1px solid ${colors.grey30}`,
    borderRadius: 4,
    color: colors.basic.black,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  rootSelected: {
    borderColor: colors.secondary.main,
    color: colors.secondary.main,
    backgroundColor: colors.secondary.light,
  },
  radioButtonLabel: {
    color: "inherit",
    fontSize: 14,
  },
}));
