import { colors } from "@chhjpackages/components";
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

export const DiscountFormSkeleton = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), {
    noSsr: true,
  });

  return (
    <Box p={2} pb={3} bgcolor={colors.white}>
      <Typography variant="subtitle1">Discount type</Typography>
      <Box mt={1}>
        <Grid
          container
          spacing={isMobile ? 2 : 4}
          justify={isMobile ? "space-between" : "flex-start"}
        >
          <Grid item>
            <Skeleton
              variant="rect"
              width={143}
              height={41}
              style={{ borderRadius: 100 }}
            />
          </Grid>
          <Grid item>
            <Skeleton
              variant="rect"
              width={143}
              height={41}
              style={{ borderRadius: 100 }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={2}>
        <Skeleton
          variant="rect"
          width="100%"
          height={56}
          style={{ borderRadius: 4 }}
        />
      </Box>
    </Box>
  );
};
