import { createEffect, createStore } from "effector";

import { getAppointmentFx } from "features/appointment";

import { addAttachments, getAttachments, deleteAttachment } from "./api";
import { Attachment } from "./types";

export const getAttachmentsFx = createEffect(getAttachments);
export const addAttachmentsFx = createEffect(addAttachments);
export const deleteAttachmentFx = createEffect(deleteAttachment);

export const $attachmentsStore = createStore<{
  attachmentsAppointmentId?: number;
  attachments: Attachment[];
  loading: boolean;
}>({
  attachmentsAppointmentId: undefined,
  attachments: [],
  loading: false,
});

$attachmentsStore
  .on(getAppointmentFx.done, (store, response) => ({
    ...store,
    attachmentsAppointmentId: response.params.appointmentId,
    attachments: response.result.data.appointments[0].attachments,
  }))
  .on(getAppointmentFx.pending, (store, loading) => ({
    ...store,
    loading,
  }));

$attachmentsStore.on(getAttachmentsFx.done, (store, response) => ({
  ...store,
  attachmentsAppointmentId: response.params.appointmentId,
  attachments: response.result.data.attachments,
}));

$attachmentsStore.on(deleteAttachmentFx.done, (store, response) => ({
  ...store,
  attachments: store.attachments.filter(
    (attachment) => attachment.id !== response.params.attachmentId,
  ),
}));
