import { Button, colors } from "@chhjpackages/components";
import { memo, useCallback } from "react";
import { useNavigate } from "react-router";
import { Typography } from "@material-ui/core";
import { To } from "react-router-dom";

import { ArrowBackIcon } from "shared/assets";

import { BackButtonProps } from "./types";
import { useStyles } from "./styles";

export const BackButton = memo(
  ({
    url,
    name,
    color = colors.primary.main,
    isNavigation,
    onClick,
  }: BackButtonProps) => {
    const styles = useStyles({ isNavigation });

    const navigate = useNavigate();

    const handleOnClick = useCallback(() => {
      if (onClick) {
        onClick();
        return;
      }

      navigate(url || (-1 as To));
    }, [url, onClick, navigate]);

    return (
      <Button
        buttonType="text"
        size="medium"
        style={{ color }}
        classes={{ root: styles.buttonRoot }}
        onClick={handleOnClick}
      >
        <div className={styles.contentRoot}>
          <ArrowBackIcon
            fontSize={13}
            color="inherit"
            className={styles.backIcon}
          />
          <Typography variant="h4" color="inherit" className={styles.title}>
            {name ? name : "Back"}
          </Typography>
        </div>
      </Button>
    );
  },
);
