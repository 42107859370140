import { memo } from "react";
import { Typography } from "@material-ui/core";

import { QuoteIcon } from "shared/assets";

import { useStyles } from "./styles";
import { reviews } from "./utils";

export const Page9 = memo(() => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <div className={styles.leftSide}>
        <div className={styles.mainText}>
          <Typography variant="h1" component="div">
            We love
          </Typography>
          <Typography variant="h3" component="div">
            to hear from you
          </Typography>
        </div>
      </div>

      <div className={styles.rightSide}>
        {reviews.map((review, index) => (
          <div key={index} className={styles.card}>
            <div className={styles.quoteMark}>
              <QuoteIcon fontSize={16} />
            </div>

            <Typography component="div" className={styles.reviewText}>
              {review.text}
            </Typography>

            <div className={styles.reviewAuthor}>
              <Typography variant="subtitle2" color="secondary">
                {review.author}
              </Typography>
              <Typography variant="body2" color="inherit">
                {review.address}
              </Typography>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});
