import { memo } from "react";
import { Typography } from "@material-ui/core";
import { ArrowRight, colors } from "@chhjpackages/components";
import { Link } from "react-router-dom";

import { CheckIcon } from "shared/assets";

import { StepLinkProps } from "./types";
import { useStyles } from "./styles";

export const StepLink = memo(
  ({ path, pathState, name, isChecked }: StepLinkProps) => {
    const styles = useStyles();

    return (
      <Link to={path} state={pathState} className={styles.root}>
        <div className={styles.content}>
          <div className={styles.checkIconWrapper}>
            {isChecked && (
              <CheckIcon color={colors.secondary.main} fontSize={12} />
            )}
          </div>

          <Typography variant="subtitle2" className={styles.title}>
            {name}
          </Typography>
        </div>

        <ArrowRight color={colors.grey80} />
      </Link>
    );
  },
);
