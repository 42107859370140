import { Typography } from "@material-ui/core";
import { memo } from "react";

import { EmptyBoxIcon } from "shared/assets";

import { useStyles } from "./styles";

export const NoAppointment = memo(() => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <EmptyBoxIcon className={styles.emptyBoxIcon} />

      <Typography
        variant="h4"
        align="center"
        color="inherit"
        className={styles.noAppointmentsr}
      >
        No appointments have been found
      </Typography>

      <Typography
        variant="body1"
        align="center"
        color="inherit"
        className={styles.review}
      >
        Please review your dashboard filters, user settings, and that your
        device is connected to the internet.
      </Typography>
    </div>
  );
});
