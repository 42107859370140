import { createEvent, createStore, createEffect } from "effector";
import moment from "moment";

import { apiDateTimeFormat } from "shared/utils";
import { $authorizedUser } from "features/auth";

import { RequestProp } from "./types";
import { repeatOfflineRequest } from "./api";
import { checkDublication, findDuplication } from "./utils";

export const $offlineRequestsStore = createStore<{
  offlineRequests: RequestProp[];
}>({
  offlineRequests: [],
});

export const repeatOfflineRequestFx = createEffect(repeatOfflineRequest);
$offlineRequestsStore.on(repeatOfflineRequestFx.done, (store, { params }) => {
  const offlineRequests = store.offlineRequests.filter(
    (offlineRequest) => offlineRequest.id !== params.id,
  );

  localStorage.setItem("offlineRequests", JSON.stringify(offlineRequests));

  return {
    ...store,
    offlineRequests: offlineRequests,
  };
});

export const setOfflineRequestsEv = createEvent();
$offlineRequestsStore.on(setOfflineRequestsEv, (store) => {
  const user = $authorizedUser.getState().user;

  if (!user) {
    return store;
  }

  return {
    ...store,
    offlineRequests: (
      (JSON.parse(
        localStorage.getItem("offlineRequests") as string,
      ) as RequestProp[]) || []
    ).filter((req) => req.userId === undefined || req.userId === user.id),
  };
});

export const addOfflineRequestEv = createEvent<{
  request: RequestProp;
  status?: number;
}>();
$offlineRequestsStore.on(addOfflineRequestEv, (store, receivedEvent) => {
  if (receivedEvent.status === 400 || receivedEvent.status === 404) {
    return store;
  }

  const event = {
    ...receivedEvent.request,
    time: receivedEvent.request.time || moment().format(apiDateTimeFormat),
  };

  let offlineRequests = store.offlineRequests;

  if (event.replace) {
    const duplicatedRequest = findDuplication(offlineRequests, event);

    if (duplicatedRequest) {
      offlineRequests = [
        ...offlineRequests.filter((offlineRequest) =>
          offlineRequest.id === duplicatedRequest.id
            ? offlineRequest.description !== duplicatedRequest.description
            : true,
        ),
        event,
      ];
    } else {
      offlineRequests.push(event);
    }
  }

  if (!checkDublication(offlineRequests, event)) {
    offlineRequests.push(event);
  }

  localStorage.setItem("offlineRequests", JSON.stringify(offlineRequests));

  return {
    ...store,
    offlineRequests: offlineRequests,
  };
});

export const removeOfflineRequestEv = createEvent<{ id: string }>();
$offlineRequestsStore.on(removeOfflineRequestEv, (store, event) => {
  const offlineRequests = store.offlineRequests.filter(
    (offlineRequest) => offlineRequest.id !== event.id,
  );

  localStorage.setItem("offlineRequests", JSON.stringify(offlineRequests));

  return {
    ...store,
    offlineRequests: offlineRequests,
  };
});
