import { Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import { useStore } from "effector-react/compat";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAlert } from "@chhjpackages/components";

import {
  $appointmentStore,
  getAppointmentFx,
  useNotesDialog,
} from "features/appointment";
import { ActionsFooter, BackTitle } from "shared/ui";
import { routePaths, allFieldsDefined } from "shared/utils";
import { useSideNavDispatch } from "features/sidenav";
import { $auth } from "features/auth";
import {
  DescriptionOfChangesFormValues,
  AddendumChangeOptionIdsEnum,
  useDescriptionOfChangesForm,
} from "features/addendum";
import {
  $completedActions,
  getCompletedAction,
} from "features/completed-actions";
import { CompletedActionsJobEnum } from "shared/types";

import { useAddendumStyles } from "./assets";
import { AddendumFooter } from "./widgets";
import { AddendumLocationState } from "./model";

export const Addendum = memo(() => {
  const styles = useAddendumStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), { noSsr: true });

  const location = useLocation();
  const { appointmentId } = useParams();
  const navigate = useNavigate();

  const { locationId } = useStore($auth);
  const { completedActions } = useStore($completedActions);
  const { appointment, loading: appointmentLoading } =
    useStore($appointmentStore);

  const {
    setPageName,
    setShowGoBackButton,
    setGoToBackUrl,
    setGoToBackOptions,
    setActionButton,
    clearActionButton,
    setElevation,
  } = useSideNavDispatch();

  const { showAlert } = useAlert();
  const { handleNotesDialogOpen } = useNotesDialog({});
  const {
    form: {
      formState: { isValid },
      handleSubmit,
    },
    render: renderDescriptionOfChangesForm,
  } = useDescriptionOfChangesForm();

  const locationState = useMemo<AddendumLocationState | undefined>(
    () => location.state,
    [location.state],
  );

  const isAddendumCompleted = useMemo(() => {
    if (!appointment?.id || appointmentLoading) {
      return null;
    }

    return !!getCompletedAction(
      completedActions,
      CompletedActionsJobEnum.FinalizeAddendum,
    );
  }, [appointment?.id, appointmentLoading, completedActions]);

  const backPath = useMemo(
    () =>
      isAddendumCompleted
        ? routePaths.jobDetails(Number(appointmentId))
        : routePaths.jobDetailsCart(Number(appointmentId)),
    [isAddendumCompleted, appointmentId],
  );

  const backState = useMemo(
    () =>
      isAddendumCompleted ? undefined : { ...locationState, isAddendum: true },
    [isAddendumCompleted, locationState],
  );

  const disableContinueToSignature = useMemo(
    () => !isValid || appointmentLoading,
    [isValid, appointmentLoading],
  );

  const handleBack = useCallback(
    () =>
      navigate(backPath, {
        state: backState,
      }),
    [backPath, backState, navigate],
  );

  const onSubmit = useCallback(
    (data: DescriptionOfChangesFormValues) => {
      navigate(routePaths.jobDetailsAddendumSignature(Number(appointmentId)), {
        state: {
          ...locationState,
          typeOfChange: data.changeOptions[0],
          additionalNotes: data.additionalNotes,
          descriptionOfSituation:
            data.changeOptions[0] ===
            AddendumChangeOptionIdsEnum.UnforeseeableCircumstances
              ? data.descriptionOfSituation
              : "",
        },
      });
    },
    [appointmentId, locationState, navigate],
  );

  useEffect(() => {
    const isAllLocationStateFieldsDefined = allFieldsDefined(locationState, [
      "updateProducts",
      "estimateNew",
      "estimateOld",
      "isAddendum",
    ]);

    if (!isAllLocationStateFieldsDefined && !appointmentLoading) {
      handleBack();
    }
  }, [locationState, appointmentLoading]);

  useEffect(() => {
    if (locationId && appointment?.id !== Number(appointmentId)) {
      getAppointmentFx({
        locationId: locationId,
        appointmentId: Number(appointmentId),
      }).catch(() => {
        showAlert("Error! Failed to get appointment. Try again later.", {
          variant: "error",
        });
      });
    }
  }, [locationId, appointment?.id, appointmentId]);

  useEffect(() => {
    setPageName("Addendum");
    setShowGoBackButton(true);
    setGoToBackUrl(backPath);
    setGoToBackOptions({ state: backState });
    setActionButton({
      name: "Notes",
      icon: <InfoOutlined color="inherit" fontSize="small" />,
      disabled: appointmentLoading,
      onClick: () => handleNotesDialogOpen(),
    });
    setElevation(16);

    return () => {
      setPageName("");
      setShowGoBackButton(false);
      setGoToBackUrl("");
      setGoToBackOptions(undefined);
      clearActionButton();
      setElevation(0);
    };
  }, [
    backPath,
    backState,
    appointmentLoading,
    setPageName,
    setShowGoBackButton,
    setGoToBackUrl,
    setGoToBackOptions,
    setActionButton,
    clearActionButton,
    setElevation,
    handleNotesDialogOpen,
  ]);

  return (
    <div className={styles.root}>
      {!isMobile && (
        <BackTitle
          title="Addendum"
          elevation={16}
          actionButtons={[
            {
              name: "Notes",
              icon: <InfoOutlined color="inherit" fontSize="small" />,
              disabled: appointmentLoading,
              onClick: handleNotesDialogOpen,
            },
          ]}
          onBack={handleBack}
        />
      )}

      <div className={styles.content}>
        <Typography variant="h4" className={styles.title}>
          Description of the changes
        </Typography>

        {renderDescriptionOfChangesForm()}
      </div>

      <ActionsFooter
        show={!appointmentLoading}
        actions={[
          {
            label: "Continue to crew signature",
            buttonType: "filled",
            disabled: disableContinueToSignature,
            onClick: handleSubmit(onSubmit),
          },
        ]}
        topAdditionalContent={
          <AddendumFooter
            originalEstimate={locationState?.estimateOld ?? 0}
            newEstimate={locationState?.estimateNew ?? 0}
          />
        }
      />
    </div>
  );
});
