import {
  Divider,
  InputAdornment,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useStore } from "effector-react";
import { colors, Input, useAlert } from "@chhjpackages/components";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import moment from "moment";
import { useDebounce } from "use-debounce";

import { useSideNavDispatch } from "features/sidenav";
import {
  USADateFormatShort,
  apiDateFormat,
  apiDateTimeFormat,
  getTeamMembers,
  routePaths,
} from "shared/utils";
import {
  filterUsers,
  $teamsStore,
  createTeamFx,
  getTeamsFx,
  getTeamsByDateFx,
  HunkTeam,
  updateTeamSchedulesFx,
  LocationUser,
} from "features/teams";
import { $auth, $authorizedUser } from "features/auth";
import {
  ActionsFooter,
  BackTitle,
  SelectionCard,
  SelectionCardSkeleton,
} from "shared/ui";
import { $usersStore, getUsersFx } from "features/users";
import { SearchIcon } from "shared/assets";
import { $appointmentStore, getAppointmentFx } from "features/appointment";
import {
  CommunicationStatuses,
  CommunicationTypesEnum,
  DataChange,
  addCommunicationFx,
} from "features/communications";
import { useLocationTimezone } from "shared/hooks";

export const CreateTeam = memo(() => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), { noSsr: true });

  const navigate = useNavigate();
  const { search: locationSearch } = useLocation();
  const { teamId, appointmentId } = useParams<"teamId" | "appointmentId">();

  const { locationId } = useStore($auth);
  const { user } = useStore($authorizedUser);
  const { users, loading: usersLoading } = useStore($usersStore);
  const {
    allTeams,
    userTeamsByDate,
    userTeams,
    loading: teamsLoading,
  } = useStore($teamsStore);
  const { appointment, loading: appointmentLoading } =
    useStore($appointmentStore);

  const [searchText, setSearchText] = useState("");
  const [selectedUsersId, setSelectedUsersId] = useState<number[]>([]);
  const [isCreatingUpdatingTeam, setIsCreatingUpdatingTeam] = useState(false);
  const [choosenTeam, setChoosenTeam] = useState<HunkTeam | null>(null);

  const { setGoToBackUrl, setPageName, setShowGoBackButton } =
    useSideNavDispatch();
  const { showAlert } = useAlert();
  const { utcToZone } = useLocationTimezone("location");
  const [searchQuery] = useDebounce(searchText, 200);
  const [searchParams] = useSearchParams();

  const scheduleIdParam = useMemo(() => {
    const result = searchParams.get("scheduleId");
    return result ? Number(result) : null;
  }, [searchParams]);

  const mode: "create" | "edit" = useMemo(
    () => (teamId ? "edit" : "create"),
    [teamId],
  );

  const isLoading = useMemo(
    () =>
      usersLoading || teamsLoading || (mode === "edit" && appointmentLoading),
    [usersLoading, teamsLoading, appointmentLoading, mode],
  );

  const goBackUrl = useMemo(
    () =>
      mode === "create"
        ? routePaths.dashboard()
        : routePaths.jobDetails(Number(appointmentId)),
    [mode, appointmentId],
  );

  const approvedUsers = useMemo(() => users.filter((u) => u.approved), [users]);

  const recentTeams = useMemo(
    () => userTeamsByDate?.filter((team) => team.id !== Number(teamId)),
    [userTeamsByDate, teamId],
  );

  const edittingTeam = useMemo(
    () => allTeams.find((userTeam) => userTeam.id === Number(teamId)),
    [allTeams, teamId],
  );

  const { mostRecentUsers, otherUsers } = useMemo(() => {
    if (user) {
      const filteredUsers = filterUsers(
        approvedUsers,
        userTeams,
        mode === "create",
      );

      const allUsers = {
        mostRecentUsers: filteredUsers.mostRecentUsers.filter(
          (u) => u.id !== user.id,
        ),
        otherUsers: filteredUsers.otherUsers.filter((u) => u.id !== user.id),
      };

      allUsers.otherUsers.unshift(user as LocationUser);

      return allUsers;
    }

    return {
      mostRecentUsers: [],
      otherUsers: [],
    };
  }, [approvedUsers, mode, userTeams, user]);

  const { mostRecentUsersSearched, otherUsersSearched } = useMemo(
    () => ({
      mostRecentUsersSearched: mostRecentUsers.filter((u) =>
        [u.firstName, u.lastName]
          .filter((item) => !!item)
          .join(" ")
          .toLowerCase()
          .includes(searchQuery.toLowerCase()),
      ),
      otherUsersSearched: otherUsers.filter((u) =>
        [u.firstName, u.lastName]
          .filter((item) => !!item)
          .join(" ")
          .toLowerCase()
          .includes(searchQuery.toLowerCase()),
      ),
    }),
    [mostRecentUsers, otherUsers, searchQuery],
  );

  const isShowRecentSection = useMemo(
    () =>
      (mode === "edit" && recentTeams.length > 0) ||
      (mode === "create" && mostRecentUsers?.length >= 0),
    [mode, recentTeams.length, mostRecentUsers],
  );

  const disableSaveButton = useMemo(() => {
    if (isLoading || selectedUsersId.length === 0) {
      return true;
    }

    if (mode === "edit") {
      if (choosenTeam) {
        return false;
      }

      return (
        JSON.stringify(selectedUsersId.sort()) ===
        JSON.stringify(
          edittingTeam?.users.map((teamUser) => teamUser.id).sort(),
        )
      );
    }
  }, [mode, isLoading, choosenTeam, selectedUsersId, edittingTeam?.users]);

  const handleGoBack = useCallback(
    () => navigate(goBackUrl),
    [goBackUrl, navigate],
  );

  const onToggleSelect = useCallback(
    (id: number, isTeam: boolean) => {
      if (isTeam) {
        const selectedTeam = userTeamsByDate.find((team) => team.id === id);

        if (selectedTeam) {
          const isSelectingChoosenTeam = choosenTeam?.id === selectedTeam.id;

          setChoosenTeam(isSelectingChoosenTeam ? null : selectedTeam);
          setSelectedUsersId(
            isSelectingChoosenTeam
              ? edittingTeam?.users.map((teamUser) => teamUser.id) ?? []
              : selectedTeam.users.map((teamUser) => teamUser.id),
          );
        }
      } else {
        setSelectedUsersId((prev) =>
          prev.includes(id) ? prev.filter((el) => el !== id) : [...prev, id],
        );
      }
    },
    [userTeamsByDate, choosenTeam?.id, edittingTeam?.users],
  );

  const onCreateUpdateTeam = useCallback(async () => {
    if (locationId && user) {
      setIsCreatingUpdatingTeam(true);

      try {
        if (mode === "edit") {
          if (!appointment || !scheduleIdParam) {
            return;
          }

          if (choosenTeam) {
            await updateTeamSchedulesFx({
              locationId: locationId,
              teamId: choosenTeam.id,
              appointmentId: Number(appointmentId),
              payload: {
                schedules: [
                  ...choosenTeam.schedules.map((schedule) => schedule.id),
                  scheduleIdParam,
                ],
                date: moment().format(apiDateFormat),
              },
            });

            navigate(
              {
                pathname: routePaths.editTeam(
                  choosenTeam.id,
                  Number(appointmentId),
                ),
                search: locationSearch,
              },
              {
                replace: true,
              },
            );

            try {
              const dataChanges: DataChange[] = [
                {
                  field: `Schedule (ID: ${scheduleIdParam})`,
                  fieldPrefix: "team",
                  oldValue: edittingTeam
                    ? `${edittingTeam.name} (ID: ${edittingTeam.id})`
                    : undefined,
                  newValue: `${choosenTeam.name} (ID: ${choosenTeam.id})`,
                },
              ];

              await addCommunicationFx({
                locationId: locationId,
                appointmentId: Number(appointmentId),
                payload: {
                  dataChanges: JSON.stringify(dataChanges),
                  details: `Team members:\n${choosenTeam.users
                    .map(
                      ({ firstName, lastName }, i) =>
                        `${[firstName, lastName]
                          .filter((item) => !!item)
                          .join(" ")}${
                          i !== choosenTeam.users.length - 1 ? ",\n" : ""
                        }`,
                    )
                    .join("")}`,
                  date: moment().format(`${USADateFormatShort} HH:mm`),
                  type: {
                    id: CommunicationTypesEnum.Other,
                  },
                  status: {
                    id: CommunicationStatuses.OtherStatusesEnum.Successful,
                  },
                },
              });
            } catch {}

            setChoosenTeam(null);
          } else {
            const {
              data: { teams },
            } = await createTeamFx({
              locationId: locationId,
              payload: {
                name: `Team ${[user.firstName, user.lastName]
                  .filter((item) => !!item)
                  .join(" ")}`,
                captain: [user.id],
                users: selectedUsersId,
                date: moment().format(apiDateFormat),
              },
            });

            navigate(
              {
                pathname: routePaths.editTeam(
                  teams[0].id,
                  Number(appointmentId),
                ),
                search: locationSearch,
              },
              {
                replace: true,
              },
            );

            await updateTeamSchedulesFx({
              locationId: locationId,
              teamId: teams[0].id,
              appointmentId: Number(appointmentId),
              payload: {
                schedules: [scheduleIdParam],
                date: moment().format(apiDateFormat),
              },
            });

            try {
              const dataChanges: DataChange[] = [
                {
                  field: `Schedule (ID: ${scheduleIdParam})`,
                  fieldPrefix: "team",
                  oldValue: edittingTeam
                    ? `${edittingTeam.name} (ID: ${edittingTeam.id})`
                    : undefined,
                  newValue: `${teams[0].name} (ID: ${teams[0].id})`,
                },
              ];

              await addCommunicationFx({
                locationId: locationId,
                appointmentId: Number(appointmentId),
                payload: {
                  dataChanges: JSON.stringify(dataChanges),
                  details: `Team members:\n${teams[0].users
                    .map(
                      ({ firstName, lastName }, i) =>
                        `${[firstName, lastName]
                          .filter((item) => !!item)
                          .join(" ")}${
                          i !== teams[0].users.length - 1 ? ",\n" : ""
                        }`,
                    )
                    .join("")}`,
                  date: moment().format(`${USADateFormatShort} HH:mm`),
                  type: {
                    id: CommunicationTypesEnum.Other,
                  },
                  status: {
                    id: CommunicationStatuses.OtherStatusesEnum.Successful,
                  },
                },
              });
            } catch {}
          }

          showAlert("Success! Team has been updated.", {
            variant: "success",
          });
        } else {
          await createTeamFx({
            locationId: locationId,
            payload: {
              name: `Team ${user.firstName} ${user.lastName}`,
              captain: [user.id],
              users: selectedUsersId,
              date: moment().format(apiDateFormat),
            },
          });

          showAlert("Success! Team has been created.", {
            variant: "success",
          });
        }

        handleGoBack();
      } catch {
        showAlert("Error! Failed to create a team.", {
          variant: "error",
        });
      }
      setIsCreatingUpdatingTeam(false);
    }
  }, [
    mode,
    locationId,
    user,
    selectedUsersId,
    appointment,
    appointmentId,
    choosenTeam,
    edittingTeam,
    scheduleIdParam,
    locationSearch,
    handleGoBack,
    showAlert,
    navigate,
  ]);

  useEffect(() => {
    if (mode === "edit" && !scheduleIdParam) {
      navigate(routePaths.dashboard());
    }
  }, [mode, scheduleIdParam]);

  useEffect(() => {
    if (isLoading || !user) {
      return;
    }

    if (mode === "create") {
      setSelectedUsersId([user.id]);
    } else {
      if (edittingTeam) {
        setSelectedUsersId(edittingTeam.users.map((teamUser) => teamUser.id));
      }
    }
  }, [mode, edittingTeam, isLoading, user]);

  useEffect(() => {
    setPageName(`${mode === "create" ? "Create" : "Edit"} team`);
    setGoToBackUrl(goBackUrl);
    setShowGoBackButton();

    return () => {
      setPageName("");
      setGoToBackUrl("");
      setShowGoBackButton();
    };
  }, [mode, goBackUrl, setPageName, setGoToBackUrl, setShowGoBackButton]);

  useEffect(() => {
    if (locationId && usersLoading) {
      getUsersFx({ locationId: locationId });
    }
  }, [locationId, usersLoading]);

  useEffect(() => {
    if (locationId && user) {
      getTeamsFx({
        locationId: locationId,
      });

      getTeamsByDateFx({
        locationId: locationId,
        date: utcToZone(moment.utc().format(apiDateTimeFormat), apiDateFormat),
      });
    }
  }, [locationId, user]);

  useEffect(() => {
    if (
      appointmentId &&
      appointment?.id !== Number(appointmentId) &&
      locationId
    ) {
      getAppointmentFx({
        locationId: locationId,
        appointmentId: Number(appointmentId),
      });
    }
  }, [appointment?.id, appointmentId, locationId]);

  return (
    <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
      {!isMobile && (
        <BackTitle
          title={teamId ? "Edit team" : "Create team"}
          onBack={handleGoBack}
        />
      )}
      {isMobile && <Divider style={{ backgroundColor: colors.grey30 }} />}
      <div style={{ flex: 1 }}>
        <div style={{ padding: "8px 16px", backgroundColor: colors.white }}>
          <Input
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            size="medium"
            variant="outlined"
            placeholder="Search for HUNKs"
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon color={colors.secondary.main} />
              </InputAdornment>
            }
          />
        </div>
        <div style={{ padding: 16, color: colors.grey120 }}>
          {isShowRecentSection && (
            <div style={{ marginBottom: 8 }}>
              <Typography variant="h5" color="inherit">
                {mode === "create"
                  ? "Your most recent team members"
                  : "Choose previous team"}
              </Typography>
            </div>
          )}
          {isLoading &&
            [...Array(3)].map((_, i) => (
              <div key={i} style={{ marginTop: i !== 0 ? 16 : 0 }}>
                <SelectionCardSkeleton hideSubTitle={true} />
              </div>
            ))}
          {!isLoading &&
            (mode === "create" ? (
              mostRecentUsersSearched.length ? (
                mostRecentUsersSearched.map(
                  ({ id, image, firstName, lastName }, i) => (
                    <div key={i} style={{ marginTop: i !== 0 ? 16 : 0 }}>
                      <SelectionCard
                        title={`${firstName} ${lastName}`}
                        imageUrl={image.url}
                        selected={
                          !!selectedUsersId.find(
                            (selectedUserId) => selectedUserId === id,
                          )
                        }
                        onToggleSelect={() => onToggleSelect(id, false)}
                        isUser
                      />
                    </div>
                  ),
                )
              ) : (
                <div style={{ marginTop: 16 }}>
                  <Typography variant="body1">
                    No recent team members.
                  </Typography>
                </div>
              )
            ) : (
              recentTeams.map((team, i) => (
                <div key={i} style={{ marginTop: i !== 0 ? 16 : 0 }}>
                  <SelectionCard
                    title={getTeamMembers(team)}
                    selected={choosenTeam?.id === team.id}
                    onToggleSelect={() => onToggleSelect(team.id, true)}
                    hideImage
                  />
                </div>
              ))
            ))}
          <div
            style={{
              marginTop: recentTeams.length > 0 || isLoading ? 24 : 0,
              color: colors.grey120,
            }}
          >
            <div
              style={{
                marginTop: isShowRecentSection ? 24 : 0,
                marginBottom: 8,
              }}
            >
              <Typography variant="h5" color="inherit">
                {mode === "edit" ? "Choose team members" : "Other hunks"}
              </Typography>
            </div>
            {isLoading &&
              [...Array(3)].map((_, i) => (
                <div key={i} style={{ marginTop: i !== 0 ? 16 : 0 }}>
                  <SelectionCardSkeleton hideSubTitle={true} />
                </div>
              ))}
            {!isLoading &&
              (otherUsersSearched.length ? (
                otherUsersSearched.map(
                  ({ id, image, firstName, lastName }, i) => (
                    <div key={i} style={{ marginTop: i !== 0 ? 16 : 0 }}>
                      <SelectionCard
                        title={`${firstName} ${lastName}`}
                        imageUrl={image.url}
                        selected={
                          !!selectedUsersId.find(
                            (selectedUserId) => selectedUserId === id,
                          )
                        }
                        onToggleSelect={() => onToggleSelect(id, false)}
                        disabled={
                          (mode === "create" && id === user?.id) ||
                          (mode === "edit" && !!choosenTeam)
                        }
                        isUser
                      />
                    </div>
                  ),
                )
              ) : (
                <Typography variant="body1">No other hunks.</Typography>
              ))}
          </div>
        </div>
      </div>
      <ActionsFooter
        actions={[
          {
            label: mode === "edit" ? "Save" : "Confirm team creation",
            buttonType: "filled",
            isLoading: isCreatingUpdatingTeam,
            disabled: disableSaveButton,
            onClick: onCreateUpdateTeam,
          },
        ]}
      />
    </div>
  );
});
