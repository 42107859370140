import { memo, useCallback } from "react";
import { Paper, useAlert } from "@chhjpackages/components";

import {
  ForgotPasswordForm,
  getLinkMethodBy,
  requestResetPasswordLink,
} from "features/auth";
import { routePaths } from "shared/utils";
import { BackButton } from "shared/ui";

import { useLoginNavigate } from "./hooks";
import { useStyles } from "./assets";

export const ResetPasswordLink = memo(() => {
  const styles = useStyles();

  const { showAlert } = useAlert();
  const { isExpiredType, loginNavigate } = useLoginNavigate();

  const handleRequestPasswordLink = useCallback(
    async ({ profileData }, method) => {
      try {
        const payload = getLinkMethodBy(profileData, method);

        await requestResetPasswordLink({ payload });

        localStorage.setItem("crew/resetLink", JSON.stringify(payload));

        showAlert("Success! Reset password link has been sent.", {
          variant: "success",
        });

        loginNavigate(routePaths.resetPasswordLinkSuccess(), {
          state: {
            resendProfileData: profileData,
            method: method,
          },
        });
      } catch {
        showAlert(
          "Error! Failed to send reset password link. \nPlease try again later.",
          {
            variant: "error",
          },
        );
      }
    },
    [loginNavigate, showAlert],
  );

  return (
    <>
      <div className={styles.backButtonContainer}>
        <BackButton
          onClick={() =>
            loginNavigate(routePaths.needHelp(), { ignoreSearch: true })
          }
        />
      </div>

      <div className={styles.root}>
        <div className={styles.rootContent}>
          <Paper elevation={16} className={styles.content}>
            <ForgotPasswordForm
              isPasswordExpired={isExpiredType}
              onSubmit={handleRequestPasswordLink}
            />
          </Paper>
        </div>
      </div>
    </>
  );
});
