import { makeStyles } from "@material-ui/core";
import { colors } from "@chhjpackages/components";

export const useStyles = makeStyles(() => ({
  mainGrid: {
    display: "flex",
    flex: 1,
    padding: "0px 22px",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiInputLabel-root": {
      color: `${colors.secondary.main} !important`,
    },
  },
  biggerText: {
    color: "#EA7200",
    fontWeight: 700,
    fontSize: "42px",
    lineHeight: "35px",
    fontFamily: "Rift Soft",
  },
  competitionText: {
    color: "#0E0F16",
    fontWeight: 700,
    fontSize: "19px",
    textAlign: "center",
    lineHeight: "28px",
    fontFamily: "Rift Soft",
  },
  surchargesText: {
    color: "#226937",
    fontWeight: 700,
    fontSize: "19px",
    lineHeight: "29px",
    padding: "6px",
    border: "1px solid #ddd",
    fontFamily: "Rift Soft",
  },
  binderImageStyle: {
    width: "100%",
    height: "200px",
  },
  ourPricesText: {
    color: "#4A4A4A",
    fontFamily: "Rift Soft",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "29px",
    marginTop: "10px",
  },
  pricesGrid: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    padding: "8px",
    gap: "14px",
    marginBottom: "25px",
  },
  pricesGridMobile: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    padding: "8px",
    gap: "6px",
    marginBottom: "25px",
  },
  lessThanBox: {
    background: "#F6F7F9",
    border: "1px solid #ECEFF3",
    borderRadius: "4px",
    order: 0,
    flexGrow: 1,
    padding: "18px 14px",
  },
  lessThanText: {
    color: "#EA7200",
    fontFamily: "Rift Soft",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "22px",
  },
  eachPriceBox: {
    background: "#F6F7F9",
    border: "1px solid #ECEFF3",
    borderRadius: "4px",
    order: 1,
    flexGrow: 0,
    padding: "18px 14px",
  },
  eachPriceText: {
    color: "#EA7200",
    fontFamily: "Rift Soft",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "21px",
    lineHeight: "26px",
  },
}));
